import { deleteProfileMinerConnectionsConstant } from '../constants/actions'
import service from '../services/configService'


export const deleteProfileMinerConnectionsAction = (body, token, customer) => {
    return async(dispatch) => {
        const result = await service.deleteProfileMinerConnections(body, token, customer)
        dispatch(success(result))
    }
}


const success = (result) => ({
    type: deleteProfileMinerConnectionsConstant,
    payload: result
  })
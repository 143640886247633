import { getTypesContant } from '../constants/actions';
import typeService from '../services/typeService'
import { setErrorMessageAction } from './setErrorMessageAction';

export const getTypesAction = (token, client) => {
  return async(dispatch) => {
    const result = await typeService.getTypes(token, client);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getTypesContant,
  payload: result
})

import {
  updateResponsePropertyConstant,
  responseResultsConstant,
  closeResponsePanelConstant,
  sendCommandConstant
} from '../constants/actions'


const defaultState = {
  ok: false,
  api_version: 1.1,
  msg: '',
  open: false,
  success: 0,
  failure: 0,
  wrong_manufactuer: 0,
  pending: 0,
  notFound: 0,
  total: 0,
  blocked: 0
}

export default(state = defaultState, action) => {
  switch (action.type) {
    case sendCommandConstant:
      console.log(action)
      return {
        ...state,
        blocked: state.blocked + action.payload.blocked,
        pending: state.pending - action.payload.blocked
      }
    case updateResponsePropertyConstant:
      return {
        ...state,
        [action.payload.property]: action.payload.value
      }
    case responseResultsConstant:
      try {
        let data = JSON.parse(action.payload)
        data = JSON.parse(data)
        return {
          ...state,
          success: state.success + data.success,
          failure: state.failure + data.failure,
          wrong_manufactuer: state.wrong_manufactuer + data.wrong_manufactuer,
          pending: state.pending - (data.success + data.failure + data.wrong_manufactuer)
        }
      } catch (e) {
        return state
      }
    case closeResponsePanelConstant:
      return {
        ok: false,
        api_version: 1.1,
        msg: '',
        open: false,
        success: 0,
        failure: 0,
        wrong_manufactuer: 0,
        pending: 0,
        notFound: 0,
        total: 0,
      }
    default:
      return state
  }
}

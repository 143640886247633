import clientService from '../services/clientService';
import { getSubnetsConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getSubnetsAction = (token, client, region_id) => {
  return async (dispatch) => {
    try{
      const result = await clientService.getSubnets(token, client, region_id);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(getSubnetsSuccess(result));
    }
    catch(err){
      console.log(err)
    }
  }
}

const getSubnetsSuccess = ( subnets ) => ({
  type: getSubnetsConstant,
  payload: subnets
})

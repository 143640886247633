import { getMinerFlagSeverityConstant } from '../constants/actions'
import service from '../services/flagService'



export const getMinerFlagSeverityAction = (token, customer) => {
  return async(dispatch) => {
    const result = await service.getMinerFlagSeverity(token, customer)
    dispatch(success(result))
  }
}



const success = (result) =>({
  type: getMinerFlagSeverityConstant,
  payload: result
})

import React, {useContext} from 'react'
import { SocketContext } from '../../context/socket'
import './style.css'
const CommandButtons = ({ onClick, deleted }) => {
  const socket = useContext(SocketContext);
  return(
    <div className="CommandButtons-container">
      <div className="button-container">
        <button className="command-button button-restart" onClick={e => onClick("restart", socket)}>
          <span className="CommandButtons-button-span">Restart</span>
        </button>
      </div>
      {/*
        <div className="button-container">
          <button className="command-button button-update" onClick={e => onClick("update")}>
            <span className="CommandButtons-button-span">Update</span>
          </button>
        </div>
        */}
      <div className="button-container">
        <button className="command-button button-config" onClick={e => onClick("config", socket)}>
          <span className="CommandButtons-button-span">Configure</span>
        </button>
      </div>
      <div className="button-container">
        {deleted === 1?(
          <button className="command-button button-delete" onClick={e => onClick("restore")}>
            <span className="CommandButtons-button-span">Restore Miner</span>
          </button>
        ):(
          <button className="command-button button-delete" onClick={e => onClick("delete")}>
            <span className="CommandButtons-button-span">Mark Deleted</span>
          </button>
        )}
      </div>
      <div className="button-container">
        <button className="command-button button-clear-location" onClick={e => onClick("clearLocation")}>
          <span className="CommandButtons-button-span">Clear Location</span>
        </button>
      </div>
      <div className="button-container">
        <button className="command-button button-clear-location" onClick={e => onClick("editSerialNumber")}>
          <span className="CommandButtons-button-span">Edit SN</span>
        </button>
      </div>
    </div>
  )
}

export default CommandButtons

import React from 'react';
import './style.css';
import Section from '../Section'
import ConfigSectionTable from '../ConfigSectionTable'

const ConfigMinerTypeSection = ({ minerTypeSelectionItem, currentTypes, allTypes,  onAdd, onMinimize, showBody, removeItem, showAdd, onSave, onCancel, onInputChange, privilegesLevel,  insertPrivileges, deletePrivileges}) => {
  return(
    <Section
      icon={<i className="fa fa-tag" aria-hidden="true"/>}
      name="Miner Type Section"
      onMinimize={onMinimize}
      showBody={showBody}
      body={
        <>
          <ConfigSectionTable
            columns={{
              Name: "Type Name",
              Avg_Heat: "Average Heat",
              Asic_Num: "Asic per card",
              Card_Num: "Cards",
              Power_Consumption: "Consumption",
              Speed_Readable: "Speed"
            }}
            items={currentTypes}
            onDelete={removeItem}
            privilegeLevel={privilegesLevel}
            deletePrivileges={deletePrivileges}
            deleteType="Type"
          />
          {showAdd?(
            <div className="adc_configMinerTypeSection-additemOuterDiv">
              <div className="adc_configMinerTypeSection-additemInnerDiv">
                <div>
                  <span>Name</span>
                  <select className="adc_configBuildingSection-input" onChange={e => onInputChange(e)}>
                    {
                      minerTypeSelectionItem == -1?(
                        <option selected>Pick Type</option>
                      ):(
                        <option>Pick Type</option>
                      )
                    }
                    {allTypes.map((item) => (
                      <option>{item.Name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <span>Heat</span>
                  {minerTypeSelectionItem !== -1?(
                    <input value={allTypes[minerTypeSelectionItem].Avg_Heat} className="adc_configBuildingSection-input" disabled/>
                  ):(
                    <input className="adc_configBuildingSection-input" value="Pick Type" disabled/>
                  )}
                </div>
                <div>
                  <span>Asic</span>
                  {minerTypeSelectionItem !== -1?(
                    <input value={allTypes[minerTypeSelectionItem].Asic_Num} className="adc_configBuildingSection-input" disabled/>
                  ):(
                    <input className="adc_configBuildingSection-input" value="Pick Type" disabled/>
                  )}
                </div>
                <div>
                  <span>Cards</span>
                  {minerTypeSelectionItem !== -1?(
                    <input value={allTypes[minerTypeSelectionItem].Card_Num} className="adc_configBuildingSection-input" disabled/>
                  ):(
                    <input className="adc_configBuildingSection-input" value="Pick Type" disabled/>
                  )}
                </div>
                <div>
                  <span>Consumption</span>
                  {minerTypeSelectionItem !== -1?(
                    <input value={allTypes[minerTypeSelectionItem].Power_Consumption} className="adc_configBuildingSection-input" disabled/>
                  ):(
                    <input className="adc_configBuildingSection-input" value="Pick Type" disabled/>
                  )}
                </div>
                <div>
                  <span>Speed</span>
                  {minerTypeSelectionItem !== -1?(
                    <input value={allTypes[minerTypeSelectionItem].Speed_Readable} className="adc_configBuildingSection-input" disabled/>
                  ):(
                    <input value="Pick Type" className="adc_configBuildingSection-input" disabled/>
                  )}
                </div>
              </div>
              <div className="adc_configMinerTypeSection-addItemsSaveDiv">
                <button type="button" className="adc_deviceMapping-button btn-danger" onClick={e => onCancel(e)}>
                  Cancel
                </button>
                <button type="button" className="adc_deviceMapping-button btn-success" onClick={e => onSave(e)}>
                  Save
                </button>
              </div>
            </div>
          ):(
            privilegesLevel >= insertPrivileges?(
              <div>
                <button type="button" className="adc_deviceMapping-button" onClick={e => onAdd(e)}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            ):(<></>)
          )}
        </>
      }
    />
  )
}

export default ConfigMinerTypeSection

import clientService from '../services/clientService';
import { getHousesConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getHousesAction = (token, client, region_id) => {
  return async (dispatch) => {
    try{
      const result = await clientService.getHouses(token, client, region_id);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(getHousesSuccess(result));
    }
    catch(err){
      console.log(err)
    }
  }
}

const getHousesSuccess = ( houses ) => ({
  type: getHousesConstant,
  payload: houses
})

import { deleteDatabaseConstant } from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'


export const deleteDatabaseAction = (token, database) => {
  return async(dispatch) => {
    const result = await service.deleteDatabase(token, database)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result, database))
  }
}



const success = (result, database) => ({
  type: deleteDatabaseConstant,
  payload: {result, database}
})

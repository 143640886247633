import favoriteService from '../services/favoriteService';
import { deleteFavMinersConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';

export const deleteFavMinersAction = (token, miners) => {
  return async(dispatch) => {
    const result = await favoriteService.deleteFavMiners(token, miners);
    if(result.ok){
    }
    else{
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(deleteFavMinersSuccess(result));
  }
}

const deleteFavMinersSuccess = (result) => ({
  type: deleteFavMinersConstant,
  payload: result
})

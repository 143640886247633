import React from 'react';
import './style.css';
import ListPageSizes from '../ListPageSizes'
import Pager from '../Pager'


const MaintenanceSection = ({ maintenanceItems, showBody, onMinimize, spareParts, pageSize, currentPageSize, maxPage, onPageClick, currentPage, changePageSize, orderBy, acending, onSort, addMaintenance }) => {
  return (
    <div className="adc_maintenanceSection-outerContainer">
      <div className="adc_maintenanceSectionHeaderDiv">
        <div className="adc_maintenanceSectionHeader">
          <i className="fa fa-wrench wrench"/>
          <span>Maintenace Section</span>
        </div>
        <div className="adc_maintenanceSectionButtonDiv">
          <button type="button" className="btn btn-box-tool" onClick={e => onMinimize()}>{showBody?(<i className="fa fa-minus"/>):(<i className="fa fa-plus"/>)}</button>
        </div>
      </div>
      {showBody?(
        <div className="adc_maintenanceSection-innerContainer">
          {spareParts?(
            <>
              <h4 style={{textAlign: 'left', padding: '10px 10px 10px 0'}}>In Stock for {spareParts.Miner_Type}</h4>
              <table className="table table-bordered table-striped" cellPadding="0" cellSpacing="0" border="0">
                <tbody>
                  <tr>
                    <td><span>Cables</span></td>
                    <td><span>{spareParts.Cables}</span></td>
                  </tr>
                  <tr>
                    <td><span>Controlboards</span></td>
                    <td><span>{spareParts.Controlboards}</span></td>
                  </tr>
                  <tr>
                    <td><span>Fans</span></td>
                    <td><span>{spareParts.Fans}</span></td>
                  </tr>
                  <tr>
                    <td><span>Hashboards</span></td>
                    <td><span>{spareParts.Hashboards}</span></td>
                  </tr>
                  <tr>
                    <td><span>PSU</span></td>
                    <td><span>{spareParts.PSU}</span></td>
                  </tr>
                </tbody>
              </table>
            </>
          ):(<></>)}
          <h4 style={{textAlign: 'left', padding: '10px 10px 10px 0'}}>Miner Repairs</h4>
          <div className="adc_maintenanceSection-innerDiv">
            <ListPageSizes
              pageSize={pageSize}
              currentPageSize={currentPageSize}
              onPageSizeChange={changePageSize}
            />
            <table
              className="table table-bordered table-striped dataTable no-footer"
              cellSpacing="0"
              cellPadding='0'
              border='0'
              width="100%">
              <thead>
                <tr>
                  <th className="clickable" onClick={e => onSort("User")}>{ orderBy === "User"? (
                    acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
                  ):<i className="fa fa-sort"/>} User</th>
                  <th>Description</th>
                  <th>Other Info</th>
                  <th>Items</th>
                  <th className="clickable" onClick={e => onSort("Log_Date")}>{ orderBy === "Log_Date"? (
                    acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
                  ):<i className="fa fa-sort"/>}Date</th>
                </tr>
              </thead>
              <tbody>
                {maintenanceItems.length > 0?(
                  maintenanceItems.map((item) =>(
                    <tr>
                      <td>{item.Username}</td>
                      <td>{item.Description}</td>
                      <td>{item.Other_Info}</td>
                      <td>
                      {item.logItems.map((item) => `${item.Type}: ${item.Amount}`).join(" - ")}
                      </td>
                      <td>{item.Log_Date}</td>
                    </tr>
                  ))
                ):(
                  <tr>
                    <td valign="top" className="adc_maintenanceSection-empty" colspan={5}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pager
              onPageClick={onPageClick}
              pageSize={pageSize}
              currentPage={currentPage}
              maxPage={maxPage}
              currentPageSize={currentPageSize}
              onPageSizeChange={changePageSize}
            />
            <div className="adc_commentTable-commentDiv">
              <button type="button" className="adc_commentTable-addComment" onClick={e => addMaintenance()}>
                <i className="fa fa-wrench wrench"/>
                Add Repair
              </button>
            </div>
          </div>
        </div>
      ):(<></>)}
    </div>
  )
}

export default MaintenanceSection

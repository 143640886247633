import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { addDatabaseAction } from '../../redux/actions/addDatabaseAction'
import { getCustomersNameAction } from '../../redux/actions/getCustomersNameAction'
import { deleteDatabaseAction } from '../../redux/actions/deleteDatabaseAction'
import { clearCurrentClientAction } from '../../redux/actions/clearCurrentClientAction'
import { adminView } from '../../helper/tabs'
import './style.css'


class DatabasesView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      addModalOpen: false,
      tier: '',
      name: '',
      api_version: ''
    }
  }
  componentDidMount = () => {
    this.props.clearCurrentClientAction()
    this.props.getCustomersNameAction(this.props.userStore.result)
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
  }
  componentWillUnmount = () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  checkDatabaseName = (name) => {
    var format = /[ `!@#$%^&*() +\-=\[\]{};':"\\|,.<>\/?~]/;
    const currentDatabases = this.props.adminStore.clients.map((item) => item.client)
    if(format.test(name)){
      alert("Database name is invalid")
      return false
    }
    if(currentDatabases.indexOf(name) !== -1){
      alert("Database name is taken")
      return false
    }
    const current_databases = this.props.adminStore.clients.filter((item) => item.client === name)
    if(current_databases.length > 0){
      return false
    }
    return true
  }
  onCreate = () => {
    const { tier, name, api_version } = this.state;
    if(name == ''){
      alert("Enter database name")
      return
    }
    if(api_version == ''){
      alert("Select api version")
      return
    }
    const nameTrimed = name.trim()
    if(!this.checkDatabaseName(nameTrimed)){
      alert("Name is either invalid or taken")
      return
    }
    this.props.addDatabaseAction(this.props.userStore.result, {database_name: name, database_version: api_version, tier: tier})
    this.setState({
      name: '',
      api_version: '',
      tier: '',
      addModalOpen: false
    })
  }
  deleteDatabase = (database) => {
    if(window.confirm(`Are you sure you want to delete ${database}. Action cannot be reverted`)){
      this.props.deleteDatabaseAction(this.props.userStore.result, database)
    }
  }
  render(){
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    const databasesColums = [
      {field: 'client', value:  'Database'},
      {field: 'users', value:   'Users', func: 'length'},
      {field: 'regions', value: 'Regions', func: 'length'}]
    const databaseButtons = [
      {class: 'ViewBody-button-delete', onClick: this.deleteDatabase, type: 'Delete', extractKey: 'client'}]
    const addDatabaseFields = [
      {Name: "Name", PropertyName: 'name', Value: this.state.name, Type: "text", Options: []},
      {Name: "Select Tier", PropertyName: 'tier', Value: this.state.tier, Type: "select",
      Options: [{text: "----------", value: ''},
        {text:"Level 0", value: "0"},
        {text:"Level 1", value: "1"},
        {text:"Level 2", value: "2"}]},
      {Name: "API Version", PropertyName: 'api_version', Value: this.state.api_version, Type: "select",
        Options:[{text: "----------", value: ''},
          {text: "1.1", value: "1.1"},
          {text:"1.2", value: "1.2"}
        ]}]
    return(
      <div className="adminView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Server Admin"
          subtitle="Manage scanner, databases & more"
          tabs={adminView}
          active="Databases"
          onClick={this.onClick}
        />
        <div className="AdminView-content">
          <ViewBody
            data={this.props.adminStore.clients}
            columns={databasesColums}
            buttons={databaseButtons}
            createtext="New database"
            list
            onCreate={e => this.setState({addModalOpen: true})}
            onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
            itemClickInfo={{name: 'databases', property: 'client'}}
          />
        </div>
        <Modal
          open={this.state.addModalOpen}
          center
          onClose={() => this.setState({addModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create new database / customer"
            fields={addDatabaseFields}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onCreate}
            onInputChange={e => onInputChange(e, this)}
            action="add_database"
          />
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = (props) => ({
  userStore: props.userReducer,
  adminStore: props.adminReducer,

})
export default connect(mapStateToProps, {
  addDatabaseAction,
  deleteDatabaseAction,
  getCustomersNameAction,
  clearCurrentClientAction
})(DatabasesView)

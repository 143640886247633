import React from 'react';
import './styles.css';

const BootstrapPaging = ({pageSize, currentPage, maxPage, onPageClick, changePageSize}) => {
  return(
    <nav className='page' aria-label="Page navigation example">
      <ul className="pagination">
        <li className="page-item"><input className="page-link" onChange={(e) => changePageSize(e)} value={pageSize}/></li>
        <li className="page-item"><a className="page-link" onClick={e => onPageClick(parseInt(1))}>1</a></li>
        <li className="page-item"><a className="page-link" onClick={e => onPageClick(parseInt(currentPage)-1)}>Prev</a></li>
        {parseInt(currentPage) === maxPage && maxPage > 2? (
          <li className="page-item"><a className="page-link" onClick={e => onPageClick(parseInt(currentPage)-2)}>{parseInt(currentPage)-2}</a></li>
        ): <></>}
        {currentPage != 1? (
          <li className="page-item"><a className="page-link" onClick={e => onPageClick(parseInt(currentPage)-1)}>{parseInt(currentPage-1)}</a></li>
        ): <></>}
        <li className="page-item"><a className="page-link selected" onClick={e => onPageClick(parseInt(currentPage))}>{currentPage}</a></li>
        {parseInt(currentPage)+1 <= maxPage? (
          <li className="page-item"><a className="page-link" onClick={e => onPageClick(parseInt(currentPage)+1)}>{parseInt(currentPage)+1}</a></li>)
          : <></>}
        {parseInt(currentPage)+2 <= maxPage? (
          <li className="page-item"><a className="page-link" onClick={e => onPageClick(parseInt(currentPage)+2)}>{parseInt(currentPage)+2}</a></li>
        ): <></>}
        <li className="page-item"><a className="page-link" onClick={e => onPageClick(parseInt(currentPage)+1)}>Next</a></li>
        <li className="page-item"><a className="page-link" onClick={e => onPageClick(parseInt(maxPage))}>{maxPage}</a></li>
      </ul>
    </nav>
  )
}
export default BootstrapPaging

import React from 'react'
import './style.css'
import "bootstrap/dist/css/bootstrap.min.css";
import { convertFields } from '../../helper/functions'

const ListFilter = ({ fields, onInputChange, clearFilter }) => {
  return(
      <div className="ListFilter_container">
        <div className="ListFiler_title">
          <strong>Filtering</strong>
        </div>
        <div className="ListFiler_content">
          {convertFields(fields, onInputChange, true)}
          <button className="ListFiler-button-clear" onClick={ e => clearFilter()}>Clear</button>
        </div>
      </div>
  )
}
export default ListFilter

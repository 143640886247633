import { filterCustomers } from '../constants/actions'

export const filterCustomersAction = (type, customer) => {
  return dispatch => {
    dispatch({
      type: filterCustomers,
      payload:{
        type,
        customer
      }
    })
  }
}

import userService from '../services/userService';
import { setErrorMessageAction } from './setErrorMessageAction';
import { setSuccessMessageAction } from './setSuccessMessageAction';
import { changePasswordConstant } from '../constants/actions'

export const changePasswordAction = (username, password, result) => {
  return async (dispatch) => {
    try{
      const token = await userService.changepassword(username, password, result);
      if(token.ok){
        dispatch(setSuccessMessageAction(token.success.msg));
        dispatch(success({ok:token.ok, result: token.secret}));
      }
      else{
        dispatch(setErrorMessageAction(token.error.reason));
        dispatch(success({ok: token.ok, result: token.error.reason}));
      }
    }
    catch(err){
      console.log(err)
    }
  }
}

const success = (token) => ({
  type: changePasswordConstant,
  payload: token
})


/*

dispatch({
  type: 'LOGIN_ACTION',
  payload: {username, password}
})
*/

import React from 'react';
import './style.css'
const AccessLevelTable = ({ data, onInputChange, onSubmit }) => {
  return(
    <div className="accessLevelTable-div">
      <table className="table-bordered" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th className="accessLevel-th">Level</th>
            <th className="accessLevel-th">Access</th>
          </tr>
        </thead>
        <tbody>
        {data.map((item) =>(
          <tr>
            <td className="accessLevel-td">
              <select  className="custom-formcontrol" name={`accessLevel-${item.ID}`} value={item.Level} onInput={e => onInputChange(e)}>
                <option>0</option>
                <option>1</option>
                <option>2</option>
              </select>
            </td>
            <td className="accessLevel-td">
              {item.Access}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <button type="button" class="btn btn-success" onClick={() => onSubmit()}>Update</button>
    </div>
  )
}
export default AccessLevelTable

import clientService from '../services/clientService';
import { getMinerConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getMinerAction = (token, client, mac_address) => {
  return async (dispatch) => {
    try{
      const result = await clientService.getMiner(token, client, mac_address);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(getMinerSuccess(result));
    }
    catch(err){
      console.log(err)
    }
  }
}

const getMinerSuccess = ( miner ) => ({
  type: getMinerConstant,
  payload: miner
})

import userService from '../services/userService';
import { addUserActivityConstant } from '../constants/actions'
export const addUserActivityAction = (token, type) => {
  return async(dispatch)=>{
    const result = await userService.addUserActivity(token, type)
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: addUserActivityConstant,
  payload: result
})

import React from 'react'
import './styles.css'
import { connect } from 'react-redux';
const createListItem = (array, onClickHandler) => {
  let retVal = []
  array.map((item, i) => {
    retVal.push(<li className="navigation-li" key={i}> <i className="fa fa-angle-double-right"></i> <a onClick={e => onClickHandler(i, array.length)}>{item.Name}</a></li>)
  })
  return retVal;
}

const NavigationTree = ({historyStore, onClickHandler}) => {
  return(
    <div className="navigation-tree" id="navigation-tree">
      <nav>
        <ul className="pagination navigation-ul" style={{marginBottom: '0'}}>
          {createListItem(historyStore.locations, onClickHandler)}
        </ul>
      </nav>
    </div>
  )
}

const mapStateToProps = (props) => ({
  historyStore: props.historyReducer
})

export default connect(mapStateToProps, null)(NavigationTree)

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { Modal } from "react-responsive-modal";
import { onTabHeaderClick, onInputChange } from '../../functions'
import { configureView } from '../../helper/tabs'
import { getSubnetsAction } from '../../redux/actions/getSubnetsAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { patchCustomerScannerAction } from '../../redux/actions/patchCustomerScannerAction'
import { postCustomerScannerAction } from '../../redux/actions/postCustomerScannerAction'
import './style.css'

class CustomerScannersView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      scannerName: '',
      scannerRange: '',
      scannerStartIP: '',
      editScannerRange: '',
      editScannerStartIP: '',
      editIdNo: '',
      editScannerName: '',
      idNo: '',
      regionID: '',
      modalOpen: false,
      editModalOpen: false,
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    this.props.getSubnetsAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id)
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onDelete = (id) => {
    console.log("onDelete ", id)
  }
  onAdd = () => {
    let { scannerName, scannerRange, scannerStartIP, idNo, regionID } = this.state
    if(scannerName === ''){
      alert("Name can not be empty string")
      return
    }
    const nameExists = this.props.subnetStore.subnets.filter((item) => item.Name === scannerName)[0]
    if(nameExists){
      alert("Scanner Name is already taken")
      return
    }
    if(scannerRange === ''){
      alert("Scan range can not be empty string")
      return
    }
    if(scannerStartIP === ''){
      alert("Scan start ip can not be empty string")
      return
    }
    if(regionID === ''){
      regionID = this.props.match.params.region_id
    }
    this.props.postCustomerScannerAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id, {
      Name: scannerName,
      Scan_Range: scannerRange,
      Scan_IP: scannerStartIP,
      Id_No: idNo,
      Region_ID: regionID
    })
    this.setState({
      scannerName: '',
      scannerRange: '',
      scannerStartIP: '',
      idNo: '',
      regionID: '',
      modalOpen: false
    })
  }
  onEditSubmit = () => {
    const {  editIdNo, editScannerRange, editScannerStartIP, editScannerName } = this.state
    if(editScannerRange === ''){
      alert("Scan range can not be empty string")
      return
    }
    if(editScannerStartIP === ''){
      alert("Scan start ip can not be empty string")
      return
    }
    this.props.patchCustomerScannerAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id, {
      Scan_Range: editScannerRange,
      Scan_IP: editScannerStartIP,
      Id_No: editIdNo,
    }, editScannerName)
    this.setState({
      editIdNo: '',
      editScannerRange: '',
      editScannerStartIP: '',
      editScannerName: '',
      editModalOpen: false
    })
  }
  onEdit = (name) => {
    const item = this.props.subnetStore.subnets.filter((item) => item.Name == name)[0]
    if(item){
        this.setState({
          editIdNo: item.Id_No,
          editScannerRange: item.Scan_Range,
          editScannerStartIP: item.Scan_IP,
          editScannerName: item.Name,
          editModalOpen: true
        })
    }
  }
  render(){
    const { userData } = this.props.userStore
    const columns = [
      {field: 'Name', value: 'Name'},
      {field: 'Scan_IP', value: "Start IP"},
      {field: 'Scan_Range', value: "Subnet Range"},
      {field: 'Id_No', value: "Idientifcation No. / MAC"},
      {field: 'Region_Name', value: "Region"}
    ]
    const buttons = [
      {class: 'ViewBody-button-edit', onClick: this.onEdit, type: 'Edit', extractKey: 'Name'}
    ]
    let addScannerField = ''
    if(this.props.match.params.region_id === "All"){
      let regions = userData != ''? userData.databases.filter((item) => item.Database_Name === this.props.match.params.name):[]
      regions = regions[0]? regions[0].Regions: []
      addScannerField = [
        {Name: 'Name', PropertyName: 'scannerName', Value: this.state.scannerName, Type: 'text', Options: []},
        {Name: 'Start IP', PropertyName: 'scannerStartIP', Value: this.state.scannerStartIP, Type: 'text', Options: []},
        {Name: 'Subnet Range', PropertyName: 'scannerRange', Value: this.state.scannerRange, Type: 'select',
        Options: [{text: "----------", value: ''},
          {text: "20", value: "20"},
          {text: "21", value: "21"},
          {text:"22", value: "22"},
          {text:"23", value: "23"},
          {text:"24", value: "24"}
        ]},
        {Name: 'MAC Address', PropertyName: 'idNo', Value: this.state.idNo, Type: 'text', Options: []},
        {Name: 'Region', PropertyName: 'regionID', Value: this.state.regionID, Type: 'select', Options: [{text: "----------", value: ''}].concat(regions.map((item) => ({text: item.Name, value: item.Region_ID})))},
      ]
    }
    else{
      addScannerField = [
        {Name: 'Name', PropertyName: 'scannerName', Value: this.state.scannerName, Type: 'text', Options: []},
        {Name: 'Start IP', PropertyName: 'scannerStartIP', Value: this.state.scannerStartIP, Type: 'text', Options: []},
        {Name: 'Subnet Range', PropertyName: 'scannerRange', Value: this.state.scannerRange, Type: 'select',
        Options: [{text: "----------", value: ''},
          {text: "20", value: "20"},
          {text: "21", value: "21"},
          {text:"22", value: "22"},
          {text:"23", value: "23"},
          {text:"24", value: "24"}
        ]},
        {Name: 'MAC Address', PropertyName: 'idNo', Value: this.state.idNo, Type: 'text', Options: []},
      ]
    }
    const editScannerFields = [
      {Name: 'Start IP', PropertyName: 'editScannerStartIP', Value: this.state.editScannerStartIP, Type: 'text', Options: []},
      {Name: 'Subnet Range', PropertyName: 'editScannerRange', Value: this.state.editScannerRange, Type: 'select',
      Options: [{text: "----------", value: ''},
        {text: "20", value: "20"},
        {text: "21", value: "21"},
        {text:"22", value: "22"},
        {text:"23", value: "23"},
        {text:"24", value: "24"}
      ]},
      {Name: 'MAC Address', PropertyName: 'editIdNo', Value: this.state.editIdNo, Type: 'text', Options: []},
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="BuildingView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={configureView}
          active="Scanners"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={this.props.subnetStore.subnets}
            columns={columns}
            buttons={buttons}
            createtext="New Scanner"
            list
            onCreate={e => this.setState({modalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create new scanner"
            fields={addScannerField}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAdd}
            onInputChange={e => onInputChange(e, this)}
            action="add_bulding"
          />
        </Modal>
        <Modal
          open={this.state.editModalOpen}
          center
          onClose={() => this.setState({ editModalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Edit scanner"
            fields={editScannerFields}
            addButton={true}
            buttonText="Edit"
            onAddClick={this.onEditSubmit}
            onInputChange={e => onInputChange(e, this)}
            action="edit_scanner"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
    token: props.userReducer.result,
    userStore: props.userReducer,
    subnetStore: props.subnetReducer,
    clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  updateClientAction,
  getSubnetsAction,
  patchCustomerScannerAction,
  postCustomerScannerAction
})(CustomerScannersView)

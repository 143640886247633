import React from 'react';
import ListPageSizes from '../../Components/ListPageSizes'
import ExportData from '../ExportData'
import './style.css'
//import Pager from '../Pager'
import Pager from 'react-pager';

const TableModel = ({currentPageSize, pageSize, changePageSize, Header, Body, onPageClick, currentPage, maxPage, exportList, columns, onColumnToggle, onColumnClick , refreshData, onSaveColumns}) => {
  return(
      <div className="adc_tableModel-outerDiv">
        <div className="adc_tableModel-extraActionDiv">
          <span className="tableModel-white-spans" data-tooltip="Refresh" data-tooltip-position="top" onClick={e => refreshData()}>
            <i className="fas fa-sync"/>
          </span>
          <ExportData
            onClick={exportList}
          />
        </div>
        <ListPageSizes
          pageSize={pageSize}
          currentPageSize={currentPageSize}
          onPageSizeChange={changePageSize}
          dark
        />
        <div className="adc_tableModel-columns">
          <span className="tableModel-white-spans" onClick={e => onColumnToggle(e)}>
              <i className="fa fa-plus"></i>
          </span>
          <div id="columnDropdown" className="adc_table-dropDown-widget adc_table-dropDown-toggleDisplay">
            <h5>Select Columns</h5>
            <h6>Toggle a column that you want to display</h6>
            <div className="adc_table-columList">
              {Object.keys(columns).map((key, index) => {
                // This first if is an fix for the old way without labels
                if (key === "Flag_Status" || key === 'Flag_Type_Name') {
                  return
                }
                if(columns[key]){
                  if(columns[key].hasOwnProperty("Value")){
                    return (
                      <div className="adc_table-columList-label" key={index}>
                        <input type="checkbox" className="adc_table-columnList-box" id={`${columns[key]['Key']}`} name={`${columns[key]['Key']}`} checked={columns[key]['Value']} onChange={e => onColumnClick(e)}/>
                        <label>{columns[key]['Label']}</label>
                      </div>
                    )
                  }
                  else{
                    return (
                      <div className="adc_table-columList-label" key={index}>
                        <input type="checkbox" className="adc_table-columnList-box" id={`${key}`} name={`${key}`} checked onChange={e => onColumnClick(e)}/>
                        <label>{key}</label>
                      </div>
                    )
                  }
                }
                else{
                  return (
                    <div className="adc_table-columList-label" key={index}>
                      <input type="checkbox" className="adc_table-columnList-box" id={`${key}`} name={`${key}`} onChange={e => onColumnClick(e)}/>
                      <label>{key}</label>
                    </div>
                  )
                }
              })}
            </div>
            <div className="columnDropdown-note">
              <button className="columnDropdown-button" onClick={e => onSaveColumns()}>
                <span className="columnDropdown-button-span">Save Columns</span>
              </button>
              <span>Note: that on window size change columns will be cut off in this order. <br/> Pick wisely!</span>
            </div>
          </div>
        </div>
        <div className='tableModel-header' >
          <table className="miner-list-table">
            {Header}
            {Body}
          </table>
        </div>
        <div className="pager-div">
          <Pager
            total={maxPage}
            current={parseInt(currentPage) - 1}
            visiblePages={3}
            onPageChanged={onPageClick}
            />
        </div>
      </div>
  )
}
export default TableModel

import React from 'react'

export const configureView = [
    {name: 'Buildings', avatar: <i className="fa fa-building"/>},
    {name: 'Pods', avatar: <i className="fa fa-th-large"/>},
    {name: 'Scanners', avatar: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M6,13H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V14A1,1,0,0,0,6,13ZM5,21H3V15H5ZM22,9H18a1,1,0,0,0-1,1V22a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V10A1,1,0,0,0,22,9ZM21,21H19V11h2ZM14,1H10A1,1,0,0,0,9,2V22a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V2A1,1,0,0,0,14,1ZM13,21H11V3h2Z"/></svg>},
    {name: 'Pools', avatar: <i className="fa fa-cog" aria-hidden="true"/>},
    {name: 'Profiles', avatar: <i className="fa fa-cog" aria-hidden="true"/>},
    {name: 'Mapping', avatar: <i className="fa fa-map-marker" aria-hidden="true"/>},
    {name: 'Alerts', avatar: <i className="fa fa-bell"/>},
    {name: 'Miner Types', avatar: <i className="fa fa-tag" aria-hidden="true"/>},
    {name: 'Flag Setting', avatar: <i className="fa fa-flag" aria-hidden="true"/>},
    {name: 'Tuning Profiles', avatar: <i className="fa fa-bolt" aria-hidden="true"/>},
]
export const configureViewTier0 = [
    {name: 'Alerts', avatar: <i className="fa fa-bell"/>}
]
export const configureViewTier1 = [
    {name: 'Pools', avatar: <i className="fa fa-cog" aria-hidden="true"/>},
    {name: 'Profiles', avatar: <i className="fa fa-cog" aria-hidden="true"/>},
    {name: 'Alerts', avatar: <i className="fa fa-bell"/>},
    {name: 'Miner Types', avatar: <i className="fa fa-tag" aria-hidden="true"/>},
    {name: 'Flag Setting', avatar: <i className="fa fa-flag" aria-hidden="true"/>}
]

export const minerView = [
  {name: 'Info', avatar: <i className="fa fa-info-circle"></i>},
  {name: 'Comment', avatar: <i className="fa fa-comment"/>},
  {name: 'Maintenance', avatar: <i className="fa fa-wrench"/>},
  {name: 'Flag', avatar: <i className="fa fa-flag"/>},
  {name: 'Activity', avatar: <i className="fa fa-history"/>},
  //{name: 'Error Log', avatar: <i className="fa fa-history"/>},
  //{name: 'System Log', avatar: <i className="fa fa-history"/>}
]

export const minerViewTier0 = [
  {name: 'Info', avatar: <i className="fa fa-info-circle"></i>}
]

export const maintenanceView = [
  {name: 'Inventory', avatar: <i className="fa fa-boxes"/>},
  {name: 'Maintenance Logs', avatar: <i className="fa fa-wrench"/>},
  {name: 'Inventory Logs', avatar: <i className="fa fa-receipt"/>},
]
export const adminView = [
  {name: 'Users', avatar: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/></svg>},
  {name: 'Databases', avatar: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M14,8h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2Zm0,4h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2ZM9,8h1a1,1,0,0,0,0-2H9A1,1,0,0,0,9,8Zm0,4h1a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,8H20V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm-8,0H11V16h2Zm5,0H15V15a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1v5H6V4H18Z"/></svg>},
  {name: 'Scanners', avatar: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M6,13H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V14A1,1,0,0,0,6,13ZM5,21H3V15H5ZM22,9H18a1,1,0,0,0-1,1V22a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V10A1,1,0,0,0,22,9ZM21,21H19V11h2ZM14,1H10A1,1,0,0,0,9,2V22a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V2A1,1,0,0,0,14,1ZM13,21H11V3h2Z"/></svg>},
  {name: 'Miner Types', avatar: (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M20,8.18V3a1,1,0,0,0-2,0V8.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V13.82a3,3,0,0,0,0-5.64ZM19,12a1,1,0,1,1,1-1A1,1,0,0,1,19,12Zm-6,2.18V3a1,1,0,0,0-2,0V14.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V19.82a3,3,0,0,0,0-5.64ZM12,18a1,1,0,1,1,1-1A1,1,0,0,1,12,18ZM6,6.18V3A1,1,0,0,0,4,3V6.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V11.82A3,3,0,0,0,6,6.18ZM5,10A1,1,0,1,1,6,9,1,1,0,0,1,5,10Z"/></svg>)},
  {name: 'Power Cut', avatar: <i className="fa fa-power-off"/>},
  {name: 'Tuning', avatar: <i className="fa fa-bolt" aria-hidden="true"/>}
  //{name: 'Reports', avatar: (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M15.396,2.292H4.604c-0.212,0-0.385,0.174-0.385,0.386v14.646c0,0.212,0.173,0.385,0.385,0.385h10.792c0.211,0,0.385-0.173,0.385-0.385V2.677C15.781,2.465,15.607,2.292,15.396,2.292 M15.01,16.938H4.99v-2.698h1.609c0.156,0.449,0.586,0.771,1.089,0.771c0.638,0,1.156-0.519,1.156-1.156s-0.519-1.156-1.156-1.156c-0.503,0-0.933,0.321-1.089,0.771H4.99v-3.083h1.609c0.156,0.449,0.586,0.771,1.089,0.771c0.638,0,1.156-0.518,1.156-1.156c0-0.638-0.519-1.156-1.156-1.156c-0.503,0-0.933,0.322-1.089,0.771H4.99V6.531h1.609C6.755,6.98,7.185,7.302,7.688,7.302c0.638,0,1.156-0.519,1.156-1.156c0-0.638-0.519-1.156-1.156-1.156c-0.503,0-0.933,0.322-1.089,0.771H4.99V3.062h10.02V16.938z M7.302,13.854c0-0.212,0.173-0.386,0.385-0.386s0.385,0.174,0.385,0.386s-0.173,0.385-0.385,0.385S7.302,14.066,7.302,13.854 M7.302,10c0-0.212,0.173-0.385,0.385-0.385S8.073,9.788,8.073,10s-0.173,0.385-0.385,0.385S7.302,10.212,7.302,10 M7.302,6.146c0-0.212,0.173-0.386,0.385-0.386s0.385,0.174,0.385,0.386S7.899,6.531,7.688,6.531S7.302,6.358,7.302,6.146"/></svg>) }
]

import React from 'react'
import DatePicker from 'react-datepicker'
import './style.css'
import "react-datepicker/dist/react-datepicker.css";
const DateButton = ({ text, onClick, value, currentFrom, usePick }) => {
    if(currentFrom === value && !usePick){
        return(
            <button className="grafana-iframe-button-selected" onClick={e => onClick(value, 'now')}>{text}</button>
        )
    }
    return(
        <button className="grafana-iframe-button" onClick={e => onClick(value, 'now')}>{text}</button>
    )
}


class GrafanaIframe extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            refresh: 0,
            intervalID: ''
        }
    }
    componentDidMount = () => {
        const intervalID = setInterval(this.refreshContent, 60000)
        this.setState({intervalID})
    }
    refreshContent = () => {
        const { refresh } = this.state
        this.setState({refresh: refresh+1})
    }
    componentWillUnmount = () => {
        const { intervalID } = this.state;
        clearInterval(intervalID)
    }
    render(){
        const { url, from, to, orgId, panelId, regionId, width, height, onClick, datePickStart, datePickEnd, setDate, applyDatePick, usePick } = this.props
        const { refresh } = this.state
        return(
            <div className='grafana-iframe-container'>
                <div className='grafana-iframe-button-container'>
                    <DateButton
                        text="Last 2 hour"
                        onClick={onClick}
                        value="now-2h"
                        currentFrom={from}
                        usePick={usePick}
                    />
                    <DateButton
                        text="Last 8 hours"
                        onClick={onClick}
                        value="now-8h"
                        currentFrom={from}
                        usePick={usePick}
                    />
                    <DateButton
                        text="Last 2 days"
                        onClick={onClick}
                        value="now-2d"
                        currentFrom={from}
                        usePick={usePick}
                    />
                    <DateButton
                        text="Last 5 days"
                        onClick={onClick}
                        value="now-5d"
                        currentFrom={from}
                        usePick={usePick}
                    />
                    <DateButton
                        text="Last 10 days"
                        onClick={onClick}
                        value="now-10d"
                        currentFrom={from}
                        usePick={usePick}
                    />
                    <DateButton
                        text="Last 15 days"
                        onClick={onClick}
                        value="now-15d"
                        currentFrom={from}
                        usePick={usePick}
                    />
                </div>
                <iframe key={`iframe1-${refresh}`} src={`${url}?from=${from}&to=${to}&orgId=${orgId}&panelId=${panelId}&var-regionId=${regionId}`} width={width} height={height} frameborder="0"></iframe>
                <div className='grafana-iframe-datepicker-container'>
                    <div className='datepicker-container'>
                        <span>Start Date</span>
                        <DatePicker selected={datePickStart} onChange={(date) => setDate('datePickStart', date)} showTimeSelect />
                    </div>
                    <div className='datepicker-container'>
                        <span>End Date</span>
                        <DatePicker selected={datePickEnd} onChange={(date) => setDate('datePickEnd', date)} showTimeSelect />
                    </div>
                    <button className={usePick? 'grafana-iframe-button-selected': 'grafana-iframe-button'} onClick={e => applyDatePick()}>Apply Range</button>
                </div>
            </div>
        )
    }
}


export default GrafanaIframe

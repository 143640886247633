import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { getTuningProfilesAction } from '../../redux/actions/tuning/getTuningPofilesAction';
import { postTuningProfilesAction } from '../../redux/actions/tuning/postTuningProfilesAction';
import { getFirmwareVersionsAction } from '../../redux/actions/firmware/getFirmwareVersionsAction';
import { getTuningAttributesAction } from '../../redux/actions/tuning/getTuningAttributesAction';
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { deleteTuningProfilesAction } from '../../redux/actions/tuning/deleteTuningProfilesAction';
import NewTuningProfile from '../../Components/NewTuningProfile'
import './style.css'


class TurningProfileView extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            modalOpen: false,
            firmwares: [],
            attributes: [],
            attributesValueList: [],
            minerTypes: [],
            name: ''
        }
    }
    componentDidMount = () => {
        this.props.getTuningProfilesAction(this.props.token, this.props.match.params.name)
        this.props.getFirmwareVersionsAction(this.props.token)
        this.props.getTuningAttributesAction(this.props.token)
        this.props.getTypesAction(this.props.token, this.props.match.params.name)
    }
    onClick = (tab) => {
        onTabHeaderClick(tab, this.props.history)
    }
    onInputChange = (value, type, itemId=null) => {
        let { attributes, attributesValueList } = this.state
        switch(type){
            case 'valueRange':
                attributesValueList = attributesValueList.filter((item) => item.ID !== itemId)
                attributesValueList.push({ID: itemId, Value: value[0]})
                this.setState({
                    attributesValueList
                })
                break
            case 'valueList':
                attributesValueList = attributesValueList.filter((item) => item.ID !== itemId)
                attributesValueList.push({ID: itemId, Value: value.value})
                this.setState({
                    attributesValueList
                })
                break
            case 'attributes':
                let newAttributes = attributes.filter((item) => item.ID !== value.ID)
                if(attributes.length == 0 || newAttributes.length === attributes.length){
                    if(value.Value_Type === 'range'){
                        const split = value.Value_List[0].Value.split('/')
                        const rangeSplit = split[0].split('-')
                        let mean = (parseInt(rangeSplit[0]) + parseInt(rangeSplit[1])) / 2
                        if(split[1] === '1' ){
                            mean = Math.floor(mean)
                        }
                        if(split[1] === '10'){
                            mean = Math.round(mean / 10) * 10
                        }
                        attributesValueList.push({ID: value.ID, Value: mean})
                    }
                    newAttributes.push(value)
                }
                else{
                    attributesValueList = attributesValueList.filter((item) => item.ID !== value.ID)
                }
                this.setState({
                    "attributes": newAttributes,
                    attributesValueList
                })
                break
            case 'name':
                this.setState({
                    [type]: value.target.value
                })
                break
            default:
                this.setState({
                    [type]: value
                })
        }
    }
    onProfileCreate = () => {
        const { name, attributesValueList, minerTypes, firmwares } = this.state
        if(name == ''){
            alert("You need to give the profile a name")
            return
        }
        const body = {
            Name: name,
            Attributes: attributesValueList,
            Firmwares: firmwares.map((item) => ({ID: item.value})),
            Miner_Types: minerTypes.map((item) => ({ID: item.value}))
        }
        this.props.postTuningProfilesAction(body, this.props.token, this.props.match.params.name)
        this.setState({
            modalOpen: false,
            firmwares: [],
            attributes: [],
            attributesValueList: [],
            minerTypes: [],
            name: ''
        })
    }
    onProfileDelete = (id) => {
        if(window.confirm("Are you sure you want to delete this profile ?")){
            const body = {
                Tuning_Profile_ID: id
            }
            this.props.deleteTuningProfilesAction(body, this.props.token, this.props.match.params.name)
        }
    }
    render(){
        const { userData } = this.props
        let   { firmwares, attributes, attributesValueList, minerTypes, name } = this.state
        const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
        const currentDatabase = userData.databases.filter((item) => item.Database_Name === this.props.match.params.name)
        const currentDatabaseAccess = userData.databases.length === 0? 0: currentDatabase[0].Level
        const modalStyle = {
            modal: {
                width: '100%',
                background: '#141619'
            }
        };
        const profileColumns = [
            {field: "ID", value: "ID"},
            {field: "Name", value: "Name"},
            {field: "Attributes", value: "Attributes", func: 'length'},
            {field: "Firmwares", value: "Firmwares", func: 'length'},
            {field: "Subnets", value: "Scanners", func: 'length'},
            {field: "Pods", value: "Pods", func: 'length'},
            {field: "Miner_Types", value: "Miner Types", func: 'length'},
        ]
        const pickFirmwares = this.props.firmwareStore.versions.map((item) => ({label: `${item.Firmware_Vendor}-${item.Version}`, value: item.ID}))
        const firmwareIDs = firmwares.map((item) => item.value)
        let pickAttributes = []
        const profileButtons = currentDatabaseAccess >= 2 ?([{class: 'ViewBody-button-delete', onClick: this.onProfileDelete, type: 'Delete', extractKey: 'ID'}]):([])
        const allAttributes = JSON.parse(JSON.stringify(this.props.tuningStore.attributes))
        if(firmwareIDs.length !== 0){
            pickAttributes = allAttributes.filter((item) => {
                const fw = item.Firmwares.filter((item) => firmwareIDs.indexOf(item.Firmware_Versions_ID) !== -1)
                return fw.length === firmwares.length
            })
            pickAttributes = pickAttributes.map((item) => {
                if(item.Display_Name === 'Preset'){
                    item.Value_List = item.Value_List.filter((i) => minerTypes.length === 1 && i.Miner_Types.map((x) => x.Miner_Types_Name).indexOf(minerTypes[0].label) !== -1)
                }
                item.Picked = attributes.filter((i) => i.ID === item.ID).length === 1
                return item
            })
        }
        const pickMinerTypes = this.props.minerTypesStore.types.map((item) => ({label: item.Name, value: item.ID}))
        return(
            <div className="ProfilesView-container">
              <TabHeader
                logo={
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                    <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                  </svg>
                }
                title="Manage account"
                subtitle="Manage buildings, pods, pools & more"
                tabs={userData.level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
                active="Tuning Profiles"
                onClick={this.onClick}
              />
              <div className="ConfigureView-content">
                <ViewBody
                  data={this.props.tuningStore.profiles}
                  columns={profileColumns}
                  list
                  buttons={profileButtons}
                  createtext="New profile"
                  onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
                  itemClickInfo={{name: 'tuningprofiles', property: 'ID'}}
                  onCreate={e => this.setState({modalOpen: true})}
                />
              </div>
              <Modal
                open={this.state.modalOpen}
                center
                onClose={() => this.setState({ modalOpen: false })}
                styles={modalStyle}
              >
                <NewTuningProfile
                    pickFirmwares={pickFirmwares}
                    pickMinerTypes={pickMinerTypes}
                    firmwares={this.state.firmwares}
                    pickAttributes={pickAttributes}
                    attributes={attributes}
                    attributesValueList={attributesValueList}
                    onInputChange={this.onInputChange}
                    minerTypes={minerTypes}
                    name={name}
                    submitForm={this.onProfileCreate}
                />
              </Modal>
            </div>
          )
    }
}

const mapStateToProps = (props) => ({
    token: props.userReducer.result,
    userData: props.userReducer.userData,
    clientStore: props.clientReducer,
    firmwareStore: props.firmwareReducer,
    tuningStore: props.tuningReducer,
    minerTypesStore: props.typeReducer
  })

  export default connect(mapStateToProps, {
    getTuningProfilesAction,
    getFirmwareVersionsAction,
    getTuningAttributesAction,
    getTypesAction,
    postTuningProfilesAction,
    deleteTuningProfilesAction
  })(TurningProfileView)
  
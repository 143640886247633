import React from 'react';
import './style.css'
const CustomerTable = ({ customers, onInputChange, onSubmit }) => {
  return(
    <div className="customerTable-div">
      <table className="table-bordered" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th className="customer-th">Name</th>
          </tr>
        </thead>
        <tbody>
        {customers.map((item) =>(
          <tr>
            <td className="customer-td">
              <input type="text" className="custom-formcontrol" name={item.client} value={item.client} onInput={e => onInputChange(e)}/>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <button type="button" class="btn btn-success" onClick={() => onSubmit()}>Update</button>
    </div>
  )
}
export default CustomerTable

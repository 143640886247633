// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../data/images/atNorthLogoWhiteFontBIG.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".notFoundView-container{\n  display: flex;\n  flex-grow: 1;\n  justify-content: center;\n  min-height: 100vh;\n  align-items: center;\n  background-color: #aba4a4;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: top; /* Center the image */\n  background-repeat: no-repeat; /* Do not repeat the image */\n}\n.notFoundView-paragraph > strong{\n  font-size: 10em;\n}\n.notFoundView-paragraph > p{\n  font-size: 2em;\n  font-family: Noto Sans;\n}\n", ""]);
// Exports
module.exports = exports;

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange, filterCheck  } from '../../functions'
import { formatBytes } from '../../helper/functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { deleteTypeAction } from '../../redux/actions/deleteTypeAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { updateFlagSettingsByMinerTypeIDAction } from '../../redux/actions/updateFlagSettingsByMinerTypeIDAction'
import { getMinerTypeInfoByIDAction } from '../../redux/actions/getMinerTypeInfoByIDAction'
import { getFlagSettingsByMinerTypeIDAction } from '../../redux/actions/getFlagSettingsByMinerTypeIDAction'
import './style.css'


class MinerTypesDetailsView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      newTypes: [],
      modalOpen: false,
      editId: 0,
      recoveryCycles: '',
      recoveryIntervals: '',
      recoveryThreshold: '',
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    this.props.getMinerTypeInfoByIDAction(this.props.match.params.minertype_id, this.props.token, this.props.match.params.name)
    this.props.getFlagSettingsByMinerTypeIDAction(this.props.match.params.minertype_id, this.props.token, this.props.match.params.name)

  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history, `/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/`)
  }
  onDelete = (id) => {
    window.confirm("Are you sure you wish to delete this type?") &&
      this.props.deleteTypeAction(id, this.props.token, this.props.match.params.name)
  }
  onMinerTypeChange = (list) => {
    this.setState({newTypes: list})
  }
  onBack = () => {
    this.props.history.goBack()
  }
  onAdd = () => {
    let { newTypes } = this.state
    if(newTypes.length > 0){
      const data = this.props.typeStore.allTypes.data.filter((item) => filterCheck(newTypes, item, 'ID'))
      this.props.postTypeAction(data, this.props.token, this.props.match.params.name)
    }
    this.setState({modalOpen: false, newTypes: []})
  }
  onFlagSettingsEdit = (id) => {
    const item = this.props.flagStore.flag_settings.filter((item) => item.ID === id)
    console.log(item)
    this.setState({
      recoveryCycles: item[0].Recovery_Cycles,
      recoveryIntervals: item[0].Recovery_Intervals,
      recoveryThreshold: item[0].Threshold,
      editId: id,
      modalOpen: true
    })
  }
  onEditSettingsInput = (e) => {
    if(e.hasOwnProperty("target")){
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }
  onEditSettings = () => {
    const { recoveryCycles, recoveryIntervals, recoveryThreshold, editId } = this.state
    const body = {
      Threshold: recoveryThreshold,
      Recovery_Intervals: recoveryIntervals,
      Recovery_Cycles: recoveryCycles,
      Miner_Type_ID: this.props.match.params.minertype_id
    }
    this.props.updateFlagSettingsByMinerTypeIDAction(editId, body, this.props.token, this.props.match.params.name)
    this.setState({
      recoveryCycles: '',
      recoveryIntervals: '',
      recoveryThreshold: '',
      editId: 0,
      modalOpen: false
    })
  }
  render(){
    const { level } = this.props.userData
    const databaseTier = this.props.userData.databases.length > 0? this.props.userData.databases[0].Tier: 0
    const pannelColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Value', value: 'Value'}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    let pannelData = this.props.typeStore.types.length > 0?[
      {Name: 'Name', Value: this.props.typeStore.types[0].Name},
      {Name: 'Manufacturer', Value: this.props.typeStore.types[0].Manufacturer},
      {Name: 'Speed', Value: formatBytes(this.props.typeStore.types[0].Total_Speed)},
      {Name: 'Avg Heat', Value: this.props.typeStore.types[0].Avg_Heat},
      {Name: 'Asic Num', Value: this.props.typeStore.types[0].Asic_Num},
      {Name: 'Cards', Value: this.props.typeStore.types[0].Card_Num},
      {Name: 'Consumption', Value: this.props.typeStore.types[0].Power_Consumption}]:
    [{Name: 'Name', Value: 'N/A'},
      {Name: 'Manufacturer', Value: 'N/A'},
      {Name: 'Speed', Value: 'N/A'},
      {Name: 'Avg Heat', Value: 'N/A'},
      {Name: 'Asic Num', Value: 'N/A'},
      {Name: 'Cards', Value: 'N/A'},
      {Name: 'Consumption', Value: 'N/A'}]
    let settingsColumns = [
      {field: "Flag_Type_Name", value: "Flag Type"},
      {field: "Recovery_Cycles", value: "Recovery Cycles"},
      {field: "Recovery_Intervals", value: "Recovery Intervals"},
      {field: "Threshold", value: "Threshold"}
    ]
    let settingsButtons = [
      {class: 'ViewBody-button-edit', onClick: this.onFlagSettingsEdit, type: 'Edit', extractKey: 'ID'}
    ]
    const editColumns = [
      {Name: "Recovery Cycles", PropertyName: "recoveryCycles", Type: "number", Value: this.state.recoveryCycles},
      {Name: "Recovery Intervals", PropertyName: "recoveryIntervals", Type: "number", Value: this.state.recoveryIntervals},
      {Name: "Recovery Threshold", PropertyName: "recoveryThreshold", Type: "number", Value: this.state.recoveryThreshold}
    ]
    return(
      <div className="AlertsView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
          active="Miner Types"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={pannelData}
            columns={pannelColumns}
            pannel
            createtext="Delete"
            onBack={this.onBack}
            onCreate={e => this.onDelete}
          />
          <ViewBody
            data={this.props.flagStore.flag_settings}
            columns={settingsColumns}
            list
            buttons={settingsButtons}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({modalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Edit Flag Type"
            fields={editColumns}
            addButton={true}
            buttonText="Edit"
            onAddClick={this.onEditSettings}
            onInputChange={this.onEditSettingsInput}
            action="edit_miner_type_flag"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  typeStore: props.typeReducer,
  clientStore: props.clientReducer,
  flagStore: props.flagReducer
})

export default connect(mapStateToProps, {
  deleteTypeAction,
  updateClientAction,
  updateFlagSettingsByMinerTypeIDAction,
  getMinerTypeInfoByIDAction,
  getFlagSettingsByMinerTypeIDAction
})(MinerTypesDetailsView)

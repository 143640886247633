import React from 'react'
import './style.css'
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'


const MinerStatusCard = ({ amount, status, onClick, onClickString, classes, indicator, icon, height, width, active, isEdit, onToggleClick, active_string }) => {
  if(typeof amount == 'number'){
    amount = amount.toLocaleString()
  }
  return(
    <div className={`${!active && !isEdit?'card_hidden':''} ${!active && isEdit?'card_hidden_edit':''} card ${onClick?'onClick':''} clientview-statuscard`} onClick={onClick && !isEdit?e => onClick(onClickString):e => {}}>
      {isEdit?(
        !active?(
          <span data-tooltip="Off" data-tooltip-position="left" className="clientView-icon-settings" onClick={e => onToggleClick(active_string, true)}><FontAwesomeIcon icon={faToggleOff} className="clientView-icon-off"/></span>
        ):(
          <span data-tooltip="On" data-tooltip-position="left" className="clientView-icon-settings" onClick={e => onToggleClick(active_string, false)}><FontAwesomeIcon icon={faToggleOn} className="clientView-icon-on"/></span>
        )
      ):(<></>)}
      <div className="card-content">
        <div className="small-numbers">
          <strong>{status}</strong>
          <div className="indicator-container">
            <span className={`indicator-${indicator}`}/>
            <span>{amount}</span>
          </div>
        </div>
      </div>
    </div>
  )
}


export default MinerStatusCard

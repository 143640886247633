import { postMultiMinerFlagChecksConstant } from '../constants/actions'
import service from '../services/flagService'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'



export const postMultiMinerFlagChecksAction = (body, token, client, pageSize, pageNumber, orderBy, acending, filterString, region_id) => {
  return async(dispatch) => {
    const result = await service.postMultiMinerFlagChecks(body, token, client, pageSize, pageNumber, orderBy, acending, filterString, region_id)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: postMultiMinerFlagChecksConstant,
  payload: result
})

import React from 'react'
import { connect } from 'react-redux'
import { getSubnetsAction } from '../../redux/actions/getSubnetsAction'
import { getHousesAction } from '../../redux/actions/getHousesAction'
import { getHashrateAction } from '../../redux/actions/getHashrateAction';
import { getFavItemsAction } from '../../redux/actions/getFavItemsAction';
import { postFavItemsAction } from '../../redux/actions/postFavItemsAction';
import { deleteFavItemsAction } from '../../redux/actions/deleteFavItemsAction';
import { getCustomerAlertsAction } from '../../redux/actions/getCustomerAlertsAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { getAlertDetailsAction } from '../../redux/actions/getAlertDetailsAction'
import { updateAlertAction } from '../../redux/actions/updateAlertAction'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { filterAlertAction } from '../../redux/actions/filterAlertAction'
import { sendTicketAction } from '../../redux/actions/sendTicketAction'
import { clearSubnetsAction } from '../../redux/actions/clearSubnetsAction'
import { toggleCustomerPannelAction } from '../../redux/actions/toggleCustomerPannelAction'
import { clearHousesAction } from '../../redux/actions/clearHousesAction'
import { clearPodsAction } from '../../redux/actions/clearPodsAction';
import { clearHashrateReducerAction } from '../../redux/actions/clearHashrateReducerAction'
import { getMinerStatusOverviewAction } from '../../redux/actions/getMinerStatusOverviewAction'
import { getAverageReportData7DaysAction } from '../../redux/actions/getAverageReportData7DaysAction'
import { getAverageReportData24Action } from '../../redux/actions/getAverageReportData24Action'
import { getDailyRevenueAction } from '../../redux/actions/getDailyRevenueAction'
import { getMonthlyRevenueAction } from '../../redux/actions/getMonthlyRevenueAction'
import { getMinerFlagsOverviewAction } from '../../redux/actions/getMinerFlagsOverviewAction'
import { clearRevenueAction } from '../../redux/actions/clearRevenueAction'
import { getRegionOverviewAction } from '../../redux/actions/getRegionOverviewAction'
import { formatBytes, commaSeparatorFormat } from '../../helper/functions'
import DashMinersTable from '../../Components/DashMinersTable'
import MinerStatusCard from '../../Components/MinerStatusCard'
import Subnet from '../../Components/ClientSubnets';
import HouseListItem from '../../Components/HouseListItem'
import Chartsection from '../../Components/Chartsection'
import HighchartGraph  from '../../Components/HighchartGraph'
import AlertsTable from '../../Components/AlertsTable'
import D3LineGraph from '../../Components/D3LineGraph';
import { Modal } from "react-responsive-modal";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pager from '../../Components/Pager'
import { NavLink } from 'react-router-dom'
import SendTicketModal from '../../Components/SendTicketModal'
import InformationTable from '../../Components/InformationTable'
import AverageReportCard from '../../Components/AverageReportCard'
import RevenueCard from '../../Components/RevenueCard'
import RegionDataContainer from '../../Components/RegionDataContainer'
import FlagOverviewContainer from '../../Components/FlagOverviewContainer'
import GrafanaIframe from '../../Components/GrafanaIframe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons'
import { customerOverviewGraphs } from '../../helper/graphs'
import moment from 'moment'
import './style.css';


class ClientView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      time: "all",
      currentGraphValue: 2,
      intervalID: '',
      showChartSection: true,
      isEdit: false,
      displayRegionScanners: [],
      initLoad: true,
      grafanaFrom: 'now-2h',
      grafanaTo: 'now',
      datePickStart: new Date(Date.now() - 1000*60*60*2),
      datePickEnd: new Date(),
      usePick: false
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    const clientLink = document.getElementById("clientLink");
    if(clientLink){
      clientLink.classList.add("itemSelected");
    }
    this.initalSetup();
    const intervalID = setInterval(this.initalSetup, 60000);
    this.setState({intervalID})
  }
  initalSetup = () => {
    const token = this.props.userStore.result;
    //this.props.getMonthlyRevenueAction(token, this.props.match.params.name, this.props.match.params.region_id)
    //this.props.getDailyRevenueAction(token, this.props.match.params.name, this.props.match.params.region_id)
    this.props.getMinerStatusOverviewAction(token, this.props.match.params.name, this.props.match.params.region_id)
    //this.props.getHashrateAction(token, this.props.match.params.name, this.state.time, this.props.match.params.region_id);
    this.props.getAverageReportData24Action(token, this.props.match.params.name, this.props.match.params.region_id)
    this.props.getAverageReportData7DaysAction(token, this.props.match.params.name, this.props.match.params.region_id)
    this.props.getMinerFlagsOverviewAction(token, '24hour', this.props.match.params.name, this.props.match.params.region_id)
    this.props.getMinerFlagsOverviewAction(token, 'all', this.props.match.params.name, this.props.match.params.region_id)
    this.props.getRegionOverviewAction(token, this.props.match.params.name, this.props.match.params.region_id)
  }
  componentWillUnmount() {
    const element = document.getElementById("clientLink");
    const { intervalID } = this.state;
    if(element){
      element.classList.remove("itemSelected");
    }
    this.props.clearHashrateReducerAction()
    this.props.clearRevenueAction()
    clearInterval(intervalID)
  }
  componentDidUpdate(){
    const clientLink = document.getElementById("clientLink");
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.initalSetup()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.initalSetup()
    }
    if(clientLink){
      clientLink.classList.add("itemSelected");
    }
  }
  onRadioCheck = (e) => {
    this.props.getHashrateAction(this.props.userStore.result, this.props.match.params.name, e.target.value)
    this.setState({time: e.target.value})
  }
  onCardClick = (string) => {
    this.props.history.push(`/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/miners/${string}`)
  }
  onMinerClick = (mac) => {
    this.props.history.push(`/client/${this.props.match.params.name}/miner/${mac}`)
  }
  onFlagCardClick = (string) => {
    const split = string.split("_")
    const capitalizeFirst = split[0].charAt(0).toUpperCase() + split[0].slice(1)
    this.props.history.push(`/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/flags?flagStatus=${capitalizeFirst}&flagDate=${split[1]}`)
  }
  onStatusSettingsClick = (isEdit) => {
    this.setState({isEdit})
  }
  onToggleCardClick = (type, value) => {
    this.props.toggleCustomerPannelAction(type, value)
  }
  onRegionHeadRowClick = (regionID) => {
    let { displayRegionScanners, initLoad } = this.state
    if(initLoad){
      displayRegionScanners.push(this.props.regionStore.regionsData[0].Region_ID)
    }
    if(displayRegionScanners.indexOf(regionID) === -1){
      displayRegionScanners.push(regionID)
    }
    else{
      displayRegionScanners = displayRegionScanners.filter((item) => item !== regionID)
    }
    this.setState({
      initLoad: false,
      displayRegionScanners,
    })
  }
  onFlagOverviewContainer = (status, type, dateType) => {
    const capitalizeFirstStatus = status.charAt(0).toUpperCase() + status.slice(1)
    const capitalizeFirstType = type.charAt(0).toUpperCase() + type.slice(1)
    if(capitalizeFirstType !== '' && capitalizeFirstType !== 'Total'){
      this.props.history.push(`/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/flags?FlagStatus=${capitalizeFirstStatus}&FlagDate=${dateType}&FlagType=${capitalizeFirstType}`)
    }
    else{
      this.props.history.push(`/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/flags?FlagStatus=${capitalizeFirstStatus}&FlagDate=${dateType}`)
    }
  }
  onRegionScannerClick = (scannerName, status) => {
    this.props.history.push(`/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/flags?FlagStatus=${status}&Scanner=${scannerName}`)
  }
  onIframeButtonClick = (value, now) => {
    this.setState({
      grafanaFrom: value,
      grafanaTo: now,
      usePick: false
    })
  }
  applyDatePick = () =>{
    const { datePickEnd, datePickStart } = this.state
    const end = Math.floor(new Date(datePickEnd).getTime())
    const start = Math.floor(new Date(datePickStart).getTime())
    this.setState({
      grafanaFrom: start,
      grafanaTo: end,
      usePick: true
    })
  }
  render() {
    let { isEdit, displayRegionScanners, initLoad, grafanaFrom, grafanaTo, datePickStart, datePickEnd, usePick } = this.state
    const pannelColumns = [
      {field: 'Name', Value: 'Name'},
      {field: 'Value', Value: 'Value'}]
    const avg24Data = [
      {Name: 'Idle & API Down', Value: this.props.reportStore.average24Hours.Idle},
      {Name: 'Online Miners', Value: this.props.reportStore.average24Hours.Online},
      {Name: 'Offline Miners', Value: this.props.reportStore.average24Hours.Offline},
      {Name: 'Total Hashrate', Value: formatBytes(this.props.reportStore.average24Hours.Total_Speed)},
      {Name: 'Performance', Value: this.props.reportStore.average24Hours.Performance? `${this.props.reportStore.average24Hours.Performance.toFixed(2)} %`: `N/A` },
      {Name: 'Total Boards', Value: this.props.reportStore.average24Hours.Cards}
    ]
    const avg7daysData = [
      {Name: 'Idle & API Down Miners', Value: this.props.reportStore.average7Days.Idle},
      {Name: 'Online Miners', Value: this.props.reportStore.average7Days.Online},
      {Name: 'Offline Miners', Value: this.props.reportStore.average7Days.Offline},
      {Name: 'Total Hashrate', Value: formatBytes(this.props.reportStore.average7Days.Total_Speed)},
      {Name: 'Performance', Value: this.props.reportStore.average7Days.Performance? `${this.props.reportStore.average7Days.Performance.toFixed(2)} %`: `N/A`},
      {Name: 'Total Boards', Value: this.props.reportStore.average7Days.Cards}
    ]
    const { minimizedElements, time } = this.state;
    const bg = {
       overlay: {
         background: "#5c5a5a"
       }
     }
    displayRegionScanners = this.props.regionStore.regionsData.length == 1? [this.props.regionStore.regionsData[0].Region_ID]: displayRegionScanners
    displayRegionScanners = initLoad && this.props.regionStore.regionsData.length > 1? [this.props.regionStore.regionsData[0].Region_ID]: displayRegionScanners
    const tablePannelColumns = [
      {field: 'Name', Value: 'Name'},
      {field: 'Value', Value: 'Value'}]
    const detected24List = [
      {Name: "Total", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.detected.total)},
      {Name: "Performance", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.detected.performance)},
      {Name: "Heat" , Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.detected.heat)},
      {Name: "Offline", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.detected.offline)},
      {Name: "Maintenance", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.detected.maintenance)}
    ]
    const pending24List = [
      {Name: "Total", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.pending.total)},
      {Name: "Performance", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.pending.performance)},
      {Name: "Heat" , Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.pending.heat)},
      {Name: "Offline", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.pending.offline)},
      {Name: "Maintenance", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.pending.maintenance)},
      {Name: "Custom", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.pending.custom)}
    ]
    const checked24List = [
      {Name: "Total", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.checked.total)},
      {Name: "Performance", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.checked.performance)},
      {Name: "Heat" , Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.checked.heat)},
      {Name: "Offline", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.checked.offline)},
      {Name: "Maintenance", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.checked.maintenance)},
      {Name: "Custom", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.checked.custom)}
    ]
    const resolved24List = [
      {Name: "Total", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.resolved.total)},
      {Name: "Performance", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.resolved.performance)},
      {Name: "Heat" , Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.resolved.heat)},
      {Name: "Offline", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.resolved.offline)},
      {Name: "Maintenance", Value: commaSeparatorFormat(this.props.flagStore.flag_24_hours.resolved.maintenance)}
    ]

    const detectedTotalList = [
      {Name: "Total", Value: commaSeparatorFormat(this.props.flagStore.flag_total.detected.total)},
      {Name: "Performance", Value: commaSeparatorFormat(this.props.flagStore.flag_total.detected.performance)},
      {Name: "Heat" , Value: commaSeparatorFormat(this.props.flagStore.flag_total.detected.heat)},
      {Name: "Offline", Value: commaSeparatorFormat(this.props.flagStore.flag_total.detected.offline)},
      {Name: "Maintenance", Value: commaSeparatorFormat(this.props.flagStore.flag_total.detected.maintenance)}
    ]
    const pendingTotalList = [
      {Name: "Total", Value: commaSeparatorFormat(this.props.flagStore.flag_total.pending.total)},
      {Name: "Performance", Value: commaSeparatorFormat(this.props.flagStore.flag_total.pending.performance)},
      {Name: "Heat" , Value: commaSeparatorFormat(this.props.flagStore.flag_total.pending.heat)},
      {Name: "Offline", Value: commaSeparatorFormat(this.props.flagStore.flag_total.pending.offline)},
      {Name: "Maintenance", Value: commaSeparatorFormat(this.props.flagStore.flag_total.pending.maintenance)},
      {Name: "Custom", Value: commaSeparatorFormat(this.props.flagStore.flag_total.pending.custom)}
    ]
    const checkedTotalList = [
      {Name: "Total", Value: commaSeparatorFormat(this.props.flagStore.flag_total.checked.total)},
      {Name: "Performance", Value: commaSeparatorFormat(this.props.flagStore.flag_total.checked.performance)},
      {Name: "Heat" , Value: commaSeparatorFormat(this.props.flagStore.flag_total.checked.heat)},
      {Name: "Offline", Value: commaSeparatorFormat(this.props.flagStore.flag_total.checked.offline)},
      {Name: "Maintenance", Value: commaSeparatorFormat(this.props.flagStore.flag_total.checked.maintenance)},
      {Name: "Custom", Value: commaSeparatorFormat(this.props.flagStore.flag_total.checked.custom)}
    ]
    const resolvedTotalList = [
      {Name: "Total", Value: commaSeparatorFormat(this.props.flagStore.flag_total.resolved.total)},
      {Name: "Performance", Value: commaSeparatorFormat(this.props.flagStore.flag_total.resolved.performance)},
      {Name: "Heat" , Value: commaSeparatorFormat(this.props.flagStore.flag_total.resolved.heat)},
      {Name: "Offline", Value: commaSeparatorFormat(this.props.flagStore.flag_total.resolved.offline)},
      {Name: "Maintenance", Value: commaSeparatorFormat(this.props.flagStore.flag_total.resolved.maintenance)}
    ]
    const graph = customerOverviewGraphs[this.props.match.params.name]
    let regionIDs = this.props.regionStore.regionsData.map((item) => item.Region_ID)
    let regionsString = regionIDs.join(',')
    return (
      <div className="clientContentContainer">
          <h1>{this.props.match.params.name.split('_').join(' ')}</h1>
          {/*<div className="clientView-revenueContainer">
            <div className="container-title">Revenue</div>
            <RevenueCard
              value={this.props.revenueStore.monthly}
              text="This Month"
            />
            <RevenueCard
              value={this.props.revenueStore.daily}
              text="To Day"
            />
          </div>*/}
          <div className="clientView-statusContainer">
            <div className="container-title">Status</div>
            {isEdit?(
              <span data-tooltip="Save Pannels" data-tooltip-position="left" className="clientView-status-settings" onClick={e => this.onStatusSettingsClick(false)}>
                <FontAwesomeIcon icon={faCheck}/>
              </span>
            ):(
              <span data-tooltip="Edit Pannels" data-tooltip-position="left" className="clientView-status-settings" onClick={e => this.onStatusSettingsClick(true)}>
                <FontAwesomeIcon icon={faEdit}/>
              </span>
            )}
            <MinerStatusCard
              amount={this.props.overviewReducer.current_hashrate}
              status="Hashrate"
              indicator="good"
              active={this.props.pannelStore.hashrate}
              active_string='hashrate'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.totalMiners}
              status="Total Miners"
              indicator="good"
              active={this.props.pannelStore.total}
              active_string='total'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.onlineMiners}
              status="Online"
              indicator="good"
              onClick={this.onCardClick}
              onClickString="online"
              active={this.props.pannelStore.online}
              active_string='online'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.performance}
              status="Farm Performance"
              indicator="good"
              active={this.props.pannelStore.performance}
              active_string='performance'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.boards}
              status="Current Hashboards"
              indicator="good"
              active={this.props.pannelStore.boards}
              active_string='boards'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.high_performance}
              status="High Performance"
              onClick={this.onCardClick}
              onClickString="high_performance"
              indicator="good"
              active={this.props.pannelStore.high_performance}
              active_string='high_performance'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.low_performance}
              isEdit={isEdit}
              status="Low Performance"
              onClick={this.onCardClick}
              onClickString="low_performance"
              indicator="okay"
              active={this.props.pannelStore.low_performance}
              active_string='low_performance'
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.offline}
              status="Offline"
              onClick={this.onCardClick}
              onClickString="offline"
              indicator="bad"
              active={this.props.pannelStore.offline}
              active_string='offline'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.idle}
              status="Idle & API Down"
              onClick={this.onCardClick}
              onClickString="idle"
              indicator="bad"
              active={this.props.pannelStore.idle}
              active_string='idle'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.sleep}
              status="Sleep"
              onClick={this.onCardClick}
              onClickString="sleep"
              indicator="bad"
              active={this.props.pannelStore.sleep}
              active_string='sleep'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.none_type}
              status="Type Missing"
              onClick={this.onCardClick}
              onClickString="noneType"
              indicator="bad"
              active={this.props.pannelStore.none_type}
              active_string='none_type'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.maintenance}
              status="Maintenance"
              onClick={this.onCardClick}
              onClickString="maintenance"
              indicator="gray"
              active={this.props.pannelStore.maintenance}
              active_string='maintenance'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.deleted}
              status="Tagged Deleted"
              onClick={this.onCardClick}
              onClickString="deleted"
              indicator="gray"
              active={this.props.pannelStore.deleted}
              active_string='deleted'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
            <MinerStatusCard
              amount={this.props.overviewReducer.favoriteMiners}
              status="Favorite Miners"
              onClick={this.onCardClick}
              onClickString="favorite"
              indicator="good"
              active={this.props.pannelStore.favorite}
              active_string='favorite'
              isEdit={isEdit}
              onToggleClick={this.onToggleCardClick}
            />
          </div>
          <div className="clientView-flagContainer">
            <div className="clientView-flagItemContainer">
              <div className="container-title">Flags last 24 hours</div>
              <FlagOverviewContainer
                title={"Detected"}
                columns={tablePannelColumns}
                list={detected24List}
                onClick={this.onFlagOverviewContainer}
                dateType={'24'}
                titleClass={'titleDetected'}
              />
              <FlagOverviewContainer
                title={"Resolved"}
                columns={tablePannelColumns}
                list={resolved24List}
                onClick={this.onFlagOverviewContainer}
                dateType={'24'}
                titleClass={'titleResolved'}
              />
              <FlagOverviewContainer
                title={"Pending"}
                columns={tablePannelColumns}
                list={pending24List}
                onClick={this.onFlagOverviewContainer}
                dateType={'24'}
                titleClass={'titlePending'}
              />
              <FlagOverviewContainer
                title={"Checked"}
                columns={tablePannelColumns}
                list={checked24List}
                onClick={this.onFlagOverviewContainer}
                dateType={'24'}
                titleClass={'titleChecked'}
              />
            </div>
            <div className="clientView-flagItemContainer">
              <div className="container-title">All flags</div>
              <FlagOverviewContainer
                title={"Detected"}
                columns={tablePannelColumns}
                list={detectedTotalList}
                onClick={this.onFlagOverviewContainer}
                dateType={'total'}
                titleClass={'titleDetected'}
              />
              <FlagOverviewContainer
                title={"Resolved"}
                columns={tablePannelColumns}
                list={resolvedTotalList}
                onClick={this.onFlagOverviewContainer}
                dateType={'total'}
                titleClass={'titleResolved'}
              />
              <FlagOverviewContainer
                title={"Pending"}
                columns={tablePannelColumns}
                list={pendingTotalList}
                onClick={this.onFlagOverviewContainer}
                dateType={'total'}
                titleClass={'titlePending'}
              />
              <FlagOverviewContainer
                title={"Checked"}
                columns={tablePannelColumns}
                list={checkedTotalList}
                onClick={this.onFlagOverviewContainer}
                dateType={'total'}
                titleClass={'titleChecked'}
              />
            </div>
          </div>
            <div className="clientview-minerList-container">
              <div className="clientview-regionData-container">
                <h3 className="client_view_title">Regions && scanners data</h3>
                {this.props.regionStore.regionsData.length > 0?(
                  this.props.regionStore.regionsData.map((item, i) => (
                    <RegionDataContainer
                      flagDetected24={item.Flag_Detected_24}
                      flagPending24={item.Flag_Pending_24}
                      flagResolved24={item.Flag_Resolved_24}
                      flagChecked24={item.Flag_Checked_24}
                      flagDetectedTotal={item.Flag_Detected_Total}
                      flagPendingTotal={item.Flag_Pending_Total}
                      flagResolvedTotal={item.Flag_Resolved_Total}
                      flagCheckedTotal={item.Flag_Checked_Total}
                      regionId={item.Region_ID}
                      name={item.Region_Name}
                      online={item.Online}
                      offline={item.Offline}
                      totalMiners={item.Total_Miners}
                      idle={item.Idle}
                      totalSpeed={item.Total_Speed}
                      scanners={item.Subnets}
                      onHeadRowClick={this.onRegionHeadRowClick}
                      displayScanners={displayRegionScanners}
                      singleRegion={this.props.regionStore.regionsData.length === 1}
                      firstRegion={i===0}
                      onItemClick={this.onRegionScannerClick}
                    />
                  ))
                ):(
                  <RegionDataContainer
                    regionID={0}
                    name="N/A"
                    online="N/A"
                    offline="N/A"
                    totalMiners="N/A"
                    idle="N/A"
                    totalSpeed="N/A"
                    scanners={[]}
                    onHeadRowClick={e => console.log("Should not be able to click here")}
                    displayScanners={displayRegionScanners}
                    singleRegion={true}
                    firstRegion={true}
                  />
                )}
              </div>
              <div className="clientview-side-container">
                <AverageReportCard
                  title="Average data last 24 hours"
                  data={avg24Data}
                  columns={pannelColumns}
                />
                <AverageReportCard
                  title="Average data last 7 days"
                  data={avg7daysData}
                  columns={pannelColumns}
                />
              </div>
            </div>
            {regionIDs.length > 0 && graph? (
                <GrafanaIframe
                  url={graph.url}
                  from={grafanaFrom}
                  to={grafanaTo}
                  onClick={this.onIframeButtonClick}
                  orgId={graph.orgId}
                  panelId={graph.panelId}
                  regionId={regionsString}
                  width="90%"
                  height="400"
                  datePickStart={datePickStart}
                  datePickEnd={datePickEnd}
                  setDate={(type, date) => this.setState({[type]: date})}
                  applyDatePick={this.applyDatePick}
                  usePick={usePick}
                />
            ):(<></>)}
            {/* <div className="adc_clientView-chartDiv">
              <Chartsection
                data={this.props.hashrateStore.hashrate}
                hashrate1Min={this.props.hashrateStore.hashrate1Min}
                hashrate15Min={this.props.hashrateStore.hashrate15Min}
                hashrate1h={this.props.hashrateStore.hashrate1h}
                hashrate6h={this.props.hashrateStore.hashrate6h}
                hashrate1d={this.props.hashrateStore.hashrate1d}
                api_version={this.props.hashrateStore.api_version}
                type={time}
                showBody={this.state.showChartSection}
                onMinimize={e => this.setState({ showChartSection: !this.state.showChartSection})}
                onRadioCheck={this.onRadioCheck}
              />
            </div> */}
      </div>
    )
  }
}
const mapStateTopProps = (props) => ({
  userStore: props.userReducer,
  clientStore: props.clientReducer,
  hashrateStore: props.hashrateReducer,
  overviewReducer: props.overviewReducer,
  reportStore: props.reportReducer,
  pannelStore: props.customerPannelReducer,
  revenueStore: props.revenueReducer,
  flagStore: props.flagReducer,
  regionStore: props.regionReducer
})
export default connect(mapStateTopProps, {
  getHashrateAction,
  updateClientAction,
  clearHashrateReducerAction,
  getMinerStatusOverviewAction,
  getAverageReportData7DaysAction,
  getAverageReportData24Action,
  toggleCustomerPannelAction,
  getDailyRevenueAction,
  getMonthlyRevenueAction,
  clearRevenueAction,
  getMinerFlagsOverviewAction,
  getRegionOverviewAction
})(ClientView)

/*
<DashMinersTable
  title="Offline Last 24 Hours"
  miners={this.props.overviewReducer.offlineMiners24Hours}
  onClick={this.onCardClick}
  onClickString="offline24hours"
  onMacClick={this.onMinerClick}
/>
<DashMinersTable
  title="Offline Over 24 Hours"
  miners={this.props.overviewReducer.offlineMiners24HoursPlus}
  onClick={this.onCardClick}
  onClickString="offline24hoursPlus"
  onMacClick={this.onMinerClick}
/>
*/

import { postProfileMinerConnectionsConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'
import service from '../services/configService'


export const postProfileMinerConnectionsAction = (body, token, customer) => {
    return async(dispatch) => {
        const result = await service.postProfileMinerConnections(body, token, customer)
        if(result.ok){
            dispatch(setSuccessMessageAction(`Successfully changed profile on ${(body.list.length - body.filteredList.length)} miners`))
        }
        else{
            dispatch(setErrorMessageAction(`Failed to change profile on ${(body.list.length - body.filteredList.length)} miners`))
        }
        dispatch(success(result))
    }
}

const success = (result) => ({
  type: postProfileMinerConnectionsConstant,
  payload: result
})

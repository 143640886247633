import { updateHeatmapReducer } from '../constants/actions'

export const updateHeatmapReducerAction = (state) => {
  return dispatch => {
    dispatch({
      type: updateHeatmapReducer,
      payload: state
    })
  }
}

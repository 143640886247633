import userService from '../services/userService';
import { setErrorMessageAction } from './setErrorMessageAction';

export const loginMicrosoftAction = (microsoftAcc, token) => {
  return async (dispatch) => {
    try{
      const result = await userService.microsoftAuth(microsoftAcc);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(loginSuccess(result))
    }
    catch(err){
      console.log(err)
    }
  }
}

const loginSuccess = (token) => ({
  type: 'LOGIN_ACTION',
  payload: token
})


/*

dispatch({
  type: 'LOGIN_ACTION',
  payload: {username, password}
})
*/

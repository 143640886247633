import { clearCustomersConstant } from '../constants/actions'

export const clearCustomersAction = () => {
  return (dispatch) => {
    dispatch(success())
  }
}

const success = () => ({
  type: clearCustomersConstant
})

import _ from "lodash";
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import React from "react";
import Highcharts from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
import rawData, { processedData } from "./data";
import { severityHexColors, rgbObjectToHex } from "./heatmapUtils";
import { formatBytes, checkTime, formatTime } from './formatUtils'
import { api_down, idle, sleep } from '../../helper/colors'
import { getColor } from '../../helper/functions'
import "./style.css";

HighchartsHeatmap(Highcharts);


//console.log(JSON.stringify(data));
// this is example data, we itterate though the x and create a new list with these values
// here we would need a array for each shelf row, for example a 1,2,3,4,5
/*
[{"x":0,"y":0,"value":18601,"name":"18,601","color":"#044296"},
{"x":1,"y":0,"value":941,"name":"941","color":"#044597"},
{"x":2,"y":0,"value":30,"name":"30","color":"#044999"},
{"x":3,"y":0,"value":126,"name":"126","color":"#044c9a"},
{"x":4,"y":0,"value":0,"name":"0","color":"transparent"},
{"x":5,"y":0,"value":0,"name":"0","color":"transparent"},
{"x":6,"y":0,"value":0,"name":"0","color":"transparent"},
*/

const Heatmap = ({ data2, shelfs, podName, client, history, openDeviceMapping, heatmapType, width}) => {
  let x = []
  for(var i in shelfs){
    x.push(data2[shelfs[i]])
  }
  //let testXasix = ['rack 1', 'rack 2', 'rack 3', 'rack 4', 'rack 5', 'rack 6', 'rack 7', 'rack 8', 'rack 9']
  const data = x
    .map((l, i) =>
      l.map((c, j) => ({
        x: j,
        y: i,
        value: c.Max_Heat,
        Location: c.Location.toLocaleString(),
        MAC_Address: c.MAC_Address,
        Total_Speed: formatBytes(c.Total_Speed),
        IP_Address: c.IP_Address,
        Heat: c.Max_Heat,
        Last_Update: formatTime(c.Last_Update),
        Performance: c.Performance,
        Status: c.Status,
        Worker_Name: c.Worker_Name,
        Miner_Type: c.Miner_Type,
        Type: c.Type,
        events: {
          click: function(){
            if(c.Type !==  'gap' && c.Type !== 'blank'){
              window.open(`/client/${client}/region/All/miner/${this.MAC_Address}/info`, "_blank", 'noopener')
            }
            else{
              if(c.Type === 'gap'){
                openDeviceMapping(c.Location.toLocaleString())
              }
            }
          }
        },
        opacity: 0,
        color:
          c.Type === 'gap'
            ? "#fff":
            c.Type === 'blank'? '#FFE4C4':
            c.Status == 'Missing_PSU' || c.Status == 'Missing_Fan' || c.Status == 'Missing_CTRL_Board'?('#aaa2ab'):
            c.Status == 'API_DOWN'?api_down:
            c.Status == 'Idle'? idle:
            c.Status == 'Sleep'? sleep:
            checkTime(c.Last_Update)? getColor('N/A', 40, 110, .5):
            heatmapType == 'Heat'?getColor(c.Max_Heat, c.Miner_Type_avgHeat - 30, c.Miner_Type_avgHeat + 30, true, .5):getColor(c.Performance, 1, 100, false, .5)
      }))
    ).flat();
  const chartOptions = {
    chart: {
      type: "heatmap",
      marginTop: 20,
      marginBottom: 80,
      borderWidth: 0,
      backgroundColor: '#0e1012',
      width: width
    },
    credits: {
      enabled: false
    },
    title: {
      text: undefined
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      categories: shelfs,
      title: null,
      endOnTick: false,
      startOnTick: false,
      endOnTick: false,
      labels: {
        style: {
          color: 'white'
        },
        formatter: e => `Shelf ${e.value}`
      }
    },
    boost:{
      enabled: true,
      allowForce:true,
      seriesThreshold: 0
    },
    colorAxis: {
      min: 0,
      max: 110,
      stops: severityHexColors.map((hex, i, arr) => [i / arr.length, hex]),
      reversed: false
    },
    legend: {
      enabled: false,
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280
    },
    plotOptions: {
      heatmap: {
          shadow: false,
          animation: false,
          allowPointSelect: true
      },
      series: {
        turboThreshold:0,
        dataLabels: {
          formatter: function() {
            return ''
          },
          allowOverlap: true,
          shadow: false,
          style: {
            textOutline: null,
            color: 'black'
          }
        }
      }
    },
    tooltip: {
      formatter: function() {
        if(this.point.Type === 'blank'){
          return(
            "<b>" +
            "Location: " + this.point.Location +
             "<br>BLANK" +
            "</b>"
          )
        }
        if(this.point.Type === 'gap'){
          return(
            "<b>" +
            "Location: " + this.point.Location +
             "<br>GAP" +
            "</b>"
          )
        }
        return (
          "<b>" +
          "Location: " + this.point.Location +
          "</b>  <br><b>" +
          "<b>" +
          "MAC Address: " + this.point.MAC_Address +
          "</b>  <br><b>" +
          "Total Speed: "+ this.point.Total_Speed +
          "</b> <br>" +
          "<b>Max Heat: " +
            this.point.Heat +
          "</b><br><b>IP Address " +
            this.point.IP_Address +
          "</b>" +
        "<br><b>Last Update: " +
          this.point.Last_Update +
        "</b>"+
        "<br><b>Performance: " +
          this.point.Performance +
        "</b>"+
        "<br><b>Miner Type: " +
          this.point.Miner_Type +
        "</b>"+
        "<br><b>Worker_Name: " +
          this.point.Worker_Name +
        "</b>"+
        "<br><b>Status: " +
          this.point.Status +
        "</b>"
        );
      }
    },
    series: [
      {
        name: "Heatmap",
        borderWidth: .5,
        borderColor: '#545353',
        data,
        dataLabels: {
          enabled: false,
        }
      }
    ]
  };
  return (
    <div>
      <h3 className="adc_heatmap-title">{podName}</h3>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
}
//className={perRow == 3? "adc_heatmap-containerDiv-3": perRow == 2? "adc_heatmap-containerDiv-2": "adc_heatmap-containerDiv-1"}
export default withRouter(Heatmap)

export const setupPage = (result, pageSize, pageNumber, filter = 'All') => {
  const maxPage = Math.ceil(result.length/pageSize);
  let secondIndex = (pageNumber*pageSize) > result.length? result.length: pageNumber*pageSize;
  let result2 = result.slice((pageNumber-1)*pageSize, secondIndex);
  let returnValue = {}
  returnValue.data = result2;
  returnValue.max_page = maxPage;
  returnValue.current_page = pageNumber
  returnValue.page_size = pageSize;
  returnValue.dataSet = result
  returnValue.filterBy = filter
  return returnValue;
}

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import D3DashPieComponent from '../D3DashPieComponent';
import { PieChart } from 'react-chartkick';
import { connect } from 'react-redux'
import './style.css'

class Client extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentHouseID: 0,
    }
  }
  onTableItemClick = (link) => {
    this.props.history.push(link)
  }
  componentDidMount = () => {
    const podTableId = `${this.props.clientName}-dashPodTable`
    const houseItemClass = `${this.props.clientName}-houseItem`
    const houseElement = document.getElementsByClassName(houseItemClass);
    const podElements = document.getElementsByClassName("dashPod");
    const podTableElement = document.getElementById(podTableId);
    const rowContainers = document.getElementsByClassName("rowContainer")
    if(rowContainers){
      for(let i = 0; i < rowContainers.length; i++){
        let currentElement = rowContainers[i]
        if(currentElement){
          currentElement.addEventListener('mouseleave', e => {
            podTableElement.style.visibility = "hidden";
          })
        }
      }
    }
    if(podTableElement) {
      podTableElement.style.visibility = "hidden";
      podTableElement.addEventListener('mouseleave', e => {
        podTableElement.style.visibility = "hidden"
      });
    }
    for (let i = 0; i < houseElement.length; i++) {
      let currentElement = houseElement[i]
      if(currentElement){
        currentElement.addEventListener('mouseenter', e => {
          podTableElement.style.visibility = "visible";
          if(currentElement){
            currentElement.style.cursor = "pointer";
            currentElement.style.backgroundColor = "lightgrey"
          }
          let re = new RegExp(`id="([0-9]+)"`)
          const regexResult = re.exec(e.target.innerHTML)
          if(regexResult){
            const newID = parseInt(regexResult[1])
            if(newID){
              const { currentHouseID } = this.state;
              if(currentHouseID != newID){
                this.setState({currentHouseID: newID})
              }
            }
          }
        });
      }
      if(currentElement){
        currentElement.addEventListener('mouseleave', e => {
          if(currentElement){
            currentElement.style.backgroundColor = "white";
            currentElement.style.borderLeft = "1px solid rgba(3, 3, 3, 0.1)";
          }
        });
      }
    }
  }
  componentDidUpdate = () => {
    const podTableId = `${this.props.clientName}-dashPodTable`
    const houseItemClass = `${this.props.clientName}-houseItem`
    const houseElement = document.getElementsByClassName(houseItemClass);
    const podElements = document.getElementsByClassName("dashPod");
    const podTableElement = document.getElementById(podTableId);
    if(podTableElement) {
      podTableElement.style.visibility = "hidden";
      podTableElement.addEventListener('mouseleave', e => {
        podTableElement.style.visibility = "hidden"
      });
    }
    for (let i = 0; i < houseElement.length; i++) {
      let currentElement = houseElement[i]
      if(currentElement){
        currentElement.addEventListener('mouseenter', e => {
          podTableElement.style.visibility = "visible";
          if(currentElement){
            currentElement.style.cursor = "pointer";
            currentElement.style.backgroundColor = "lightgrey"
          }
          let re = new RegExp(`id="([0-9]+)"`)

          const regexResult = re.exec(e.target.innerHTML)
          if(regexResult){
            const newID = parseInt(regexResult[1])
            if(newID){
              const { currentHouseID } = this.state;
              if(currentHouseID != newID){
                this.setState({currentHouseID: newID})
              }
            }
          }
        });
      }
      if(currentElement){
        currentElement.addEventListener('mouseleave', e => {
          if(currentElement){
            currentElement.style.backgroundColor = "white";
            currentElement.style.borderLeft = "1px solid rgba(3, 3, 3, 0.1)"
          }
        });
      }
    }
  }
  componentWillUnmount = () => {
    const podTableId = `${this.props.clientName}-dashPodTable`
    const houseItemClass = `${this.props.clientName}-houseItem`
    const houseElement = document.getElementsByClassName(houseItemClass);
    const podElements = document.getElementsByClassName("dashPod");
    const podTableElement = document.getElementById(podTableId);
    const rowContainers = document.getElementsByClassName("rowContainer")
    if(rowContainers){
      for(let i = 0; i < rowContainers.length; i++){
        let currentElement = rowContainers[i]
        if(currentElement){
          currentElement.removeEventListener('mouseleave', e => {
            podTableElement.style.visibility = "hidden";
          })
        }
      }
    }
    if(podTableElement) {
      podTableElement.removeEventListener('mouseleave', e => {
        podTableElement.style.visibility = "hidden"
      });
    }
    for (let i = 0; i < houseElement.length; i++) {
      let currentElement = houseElement[i];
      if(currentElement){
        currentElement.removeEventListener('mouseenter', e => {
          podTableElement.style.visibility = "visible";
          if(currentElement){
            currentElement.style.cursor = "pointer"
            currentElement.style.backgroundColor = "lightgrey"
          }
        });
        currentElement.removeEventListener('mouseleave', e => {
          if(currentElement){
            currentElement.style.backgroundColor = "white";
          }
        });
      }
    }
  }
  render() {
      const { clientName, onMinimize, minimized, element } = this.props;
      let totalMiners = 0, offlineMiners = 0;
      let houses = this.props.clientsStore.clients.filter((item) => item.client == clientName)
      if(houses){
        totalMiners = houses[0].totalMiners
        offlineMiners = houses[0].offlineMiners
        houses = houses[0].houses
      }
      const linkName = `/client/${clientName}`
      const podTableId = `${clientName}-dashPodTable`
      const houseItemClass = `${clientName}-houseItem`
      const { currentHouseID } = this.state;
      let pods = []
      if(houses){
        pods = houses.filter((item) => item.ID == currentHouseID);
        if(pods.length > 0){
          pods = pods[0].Pods;
        }
        else{
          pods = []
        }
      }
      else{
        houses = []
      }
      if(minimized){
        return(
          <div className="rowContainerMinimized" onClick={ e => onMinimize(element)}>
              <div>{clientName}</div>
          </div>
        )
      }
      else{
        return(
          <div className="rowContainer">
            <div className="clientName">
              <i className="fa fa-window-minimize minimize-maxmize" onClick={ e => onMinimize(element)}/>
              <NavLink to={linkName}>
                {clientName.split('_').join(' ')}
              </NavLink>
            </div>
            <div className="clientItems">
              <div className="onlineMinersPie">
                <PieChart
                  data={[
                    [`Online`, (totalMiners - offlineMiners)],
                    [`Offline`, offlineMiners]]}
                  width={'80%'}
                  height={'70%'}
                  colors={['green', 'black', 'orange', 'blue']}
                  legend={true}
                  />
              </div>
              <div className="dashHouseContainer">
                <div>
                  <div className="dashHouseHeader">
                    <table className="dashHouseTable" cellpadding="0" cellspacing="0" border="0">
                      <thead>
                        <tr><th className="houseTableHeader" >Buildings</th></tr>
                      </thead>
                    </table>
                  </div>
                  <div className="dashHouseBody">
                    <table className="dashHouseTable" cellpadding="0" cellspacing="0" border="0">
                      <tbody>
                        {
                          houses.map((house) => {
                          return (
                            <tr className={houseItemClass}>
                              <td className="houseTableChild" id={house.ID} onClick={e => this.onTableItemClick(`client/${clientName}/house/${house.ID}`)}>
                                {house.Name}
                              </td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id={podTableId} className="dashPod">
                  <div className="dashPodHeader">
                    <table className="dashHouseTable" cellpadding="0" cellspacing="0" border="0">
                      <thead>
                        <tr><th className="houseTableHeader" >Pods</th></tr>
                      </thead>
                    </table>
                  </div>
                  <div className="dashPodBody">
                    <table className="dashHouseTable" cellpadding="0" cellspacing="0" border="0">
                      <tbody>
                        {pods.map((item) => <tr className="podTableItem" onClick={e => this.onTableItemClick(`client/${clientName}/house/${currentHouseID}/pod/${item.ID}`)} ><td className="podTableChild">{item.Name}</td></tr>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

  }
}

Client.propTypes = {
  clientName: PropTypes.string.isRequired
}

const mapStateTopProps = (props) => ({
  clientsStore: props.clientReducer,
})

export default connect(mapStateTopProps, null)(withRouter(Client))
 /*<p className="onlineMiners">Miners online: {totalMiners-offlineMiners}/{totalMiners}</p>
<D3DashPieComponent className="onlineMiners"
 data = {data}
/>
*/

export const customerOverviewGraphs = {
    Metdist: {
        url: "https://grafana.hashmon.dev/d-solo/3sd98iPVk/metdist-dashboard",
        orgId: "2",
        panelId: "6"
    },
    HAN: {
        url: "https://grafana.hashmon.dev/d-solo/1DXk3IPVz/han-dashboard",
        orgId: "2",
        panelId: "6"
    },
    HIVE: {
        url: "https://grafana.hashmon.dev/d-solo/G9BRqSEVz/hive-dashboard",
        orgId: "2",
        panelId: "6"
    },
    PEGA_Mining: {
        url: "https://grafana.hashmon.dev/d-solo/GrabCSPVz/pega-mining-dashboard",
        orgId: "2",
        panelId: "12"
    },
    GreenBlocks: {
        url: "https://grafana.hashmon.dev/d-solo/J-VU3mlVz/greenblocks-dashboard",
        orgId: "2",
        panelId: "2"
    }
}
import { postFarmInventoryConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/inventoryService'

export const postFarmInventoryAction = (token, customer, body) => {
  return async(dispatch) => {
    const result = await service.postFarmInventory(token, customer, body)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: postFarmInventoryConstant,
  payload: result
})

import React from 'react'
import './style.css'
const createOptions = (num) => {
  let returnVal = []
  for(var i = 1; i <= num; i++){
    returnVal.push(<option key={i}>{i}</option>)
  }
  return returnVal
}
const FilterMinerListDefaultChildren = ({filter, onFilterInputChange, minerTypes, clearFilter}) => {
  return(
    <>
      <div className="adc_filterSection-itemContainer">
        <p>Status</p>
        <select value={filter.filterStatus} name="filterStatus" className="adc_filterSection-input" onChange={(e) => onFilterInputChange(e)}>
          <option value="">-----</option>
          <option>Offline</option>
          <option>Online</option>
          <option value="API_DOWN">API DOWN</option>
          <option value="Missing_Fan">Missing Fan</option>
          <option value="Missing_PSU">Missing PSU</option>
          <option value="Missing_CTRL_Board">Missing Controlboard</option>
          <option>Unknown</option>
          <option>Recovery</option>
          <option>Idle</option>
        </select>
      </div>
      <div className="adc_filterSection-itemContainer">
        <p>Worker</p>
        <input type="text" className="adc_filterSection-input" name="filterWorker" id='filterWorker' value={filter.filterWorker} onChange={(e) => onFilterInputChange(e)} placeholder="Filter"/>
      </div>
      <div className="adc_filterSection-itemContainer">
        <p>IP Address</p>
        <input type="text" className="adc_filterSection-input" name="filterIp" id='filterIp' value={filter.filterIp} onChange={(e) => onFilterInputChange(e)} placeholder="Filter"/>
      </div>
      <div className="adc_filterSection-itemContainer">
        <p>Rack</p>
        <select value={filter.filterRack} name="filterRack" className="adc_filterSection-input" onChange={(e) => onFilterInputChange(e)}>
          <option value="">-----</option>
          {createOptions(34)}
        </select>
    </div>
    <div className="adc_filterSection-itemContainer">
      <p>Heat</p>
      <input type="number" className="adc_filterSection-input" name="fromHeat" id='filter-from-heat' value={filter.fromHeat} onChange={(e) => onFilterInputChange(e)} placeholder="From"/>
      <input type="number" className="adc_filterSection-input" name="toHeat" id='filter-to-heat' value={filter.toHeat} onChange={(e) => onFilterInputChange(e)}  placeholder="To"/>
    </div>
    <div className="adc_filterSection-itemContainer">
      <p>Current Speed - TH/s</p>
      <input type="number" className="adc_filterSection-input" name="fromCurrentSpeed" id='filter-from-speed' value={filter.fromCurrentSpeed} onChange={(e) => onFilterInputChange(e)} placeholder="From"/>
      <input type="number" className="adc_filterSection-input" name="toCurrentSpeed" id='filter-to-speed' value={filter.toCurrentSpeed} onChange={(e) => onFilterInputChange(e)}  placeholder="To"/>
    </div>
    <div className="adc_filterSection-itemContainer">
      <p>Average Speed - TH/s</p>
      <input type="number" className="adc_filterSection-input" name="fromAvgSpeed" id='filter-from-avgSpeed' value={filter.fromAvgSpeed} onChange={(e) => onFilterInputChange(e)} placeholder="From"/>
      <input type="number" className="adc_filterSection-input" name="toAvgSpeed" id='filter-to-avgSpeed' value={filter.toAvgSpeed} onChange={(e) => onFilterInputChange(e)} placeholder="To"/>
    </div>
    <div className="adc_filterSection-itemContainer">
      <p>Missing Asic</p>
      <input type="number" className="adc_filterSection-input" name="fromMissingAsic" id='filter-from-missing-asic' value={filter.fromMissingAsic} onChange={(e) => onFilterInputChange(e)}  placeholder="From"/>
      <input type="number" className="adc_filterSection-input" name="toMissingAsic" id='filter-to-missing-asic' value={filter.toMissingAsic} onChange={(e) => onFilterInputChange(e)}  placeholder="To"/>
    </div>
    <div className="adc_filterSection-itemContainer">
      <p>Type</p>
      <select value={filter.filterType} name="filterType" className="adc_filterSection-input" onChange={(e) => onFilterInputChange(e)}>
        <option value="">-----</option>
        {minerTypes.map((item, key) => (
          <option key={key}>{item.Name}</option>
        ))}
        <option>none</option>
      </select>
    </div>
    <div className="adc_filterSection-itemContainer">
      <p>Performance - %</p>
      <input type="number" className="adc_filterSection-input" name="fromPerformance" id='filter-from-performance' value={filter.fromPerformance} onChange={(e) => onFilterInputChange(e)} placeholder="From"/>
      <input type="number" className="adc_filterSection-input" name="toPerformance" id='filter-to-performance' value={filter.toPerformance} onChange={(e) => onFilterInputChange(e)} placeholder="To"/>
    </div>
    <a className="adc_filterSection-clearFilter" onClick={e => clearFilter()}>
      <i className="fa fa-edit"></i>
      CLEAR
    </a>
    </>
  )
}

export default FilterMinerListDefaultChildren

import { getFarmInventoryConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction';
import service from '../services/inventoryService'

export const getFarmInventoryAction = (token, customer) => {
  return async(dispatch) => {
    const result = await service.getFarmInventory(token, customer)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}
const success = ( result ) => ({
  type: getFarmInventoryConstant,
  payload: result
})

import React from 'react';
import './style.css';
import Section from '../Section'
import ConfigSectionTable from '../ConfigSectionTable'

const ConfigPoolSection = ({ pools, poolURL, workerName, password, onAdd, onMinimize, showBody, removeItem, showAdd, onSave, onCancel, onInputChange, privilegesLevel,  insertPrivileges, deletePrivileges}) => {
  return(
    <Section
      icon={<i className="fa fa-cog" aria-hidden="true"/>}
      name="Pool Section"
      onMinimize={onMinimize}
      showBody={showBody}
      body={
        <>
          <ConfigSectionTable
            columns={{
              URL: "Pool Url",
              Worker: "Pool Worker",
              Password: "Pool Password"
            }}
            items={pools}
            onDelete={removeItem}
            privilegeLevel={privilegesLevel}
            deletePrivileges={deletePrivileges}
            deleteType="Pool"
          />
          {showAdd?(
            <div className="adc_configPoolSection-additemOuterDiv">
              <div className="adc_configPoolSection-additemInnerDiv">
                <div>
                  <span>URL</span>
                  <input value={poolURL} name="poolURL" className="adc_configBuildingSection-input" placeholder="Enter pool url" onChange={e => onInputChange(e)}/>
                </div>
                <div>
                  <span>Worker Name</span>
                  <input value={workerName} name="workerName" className="adc_configBuildingSection-input" placeholder="Enter pool worker name" onChange={e => onInputChange(e)}/>
                </div>
                <div>
                  <span>Password</span>
                  <input value={password} name="password" className="adc_configBuildingSection-input" placeholder="Enter pool password" onChange={e => onInputChange(e)}/>
                </div>
              </div>
              <div className="adc_configPoolSection-addItemsSaveDiv">
                <button type="button" className="adc_deviceMapping-button btn-danger" onClick={e => onCancel(e)}>
                  Cancel
                </button>
                <button type="button" className="adc_deviceMapping-button btn-success" onClick={e => onSave(e)}>
                  Save
                </button>
              </div>
            </div>
          ):(
            privilegesLevel >= insertPrivileges?(
              <div>
                <button type="button" className="adc_deviceMapping-button" onClick={e => onAdd(e)}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            ):(<></>)
          )}
        </>
      }
    />
  )
}

export default ConfigPoolSection

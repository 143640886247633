import React from 'react'
import { connect } from 'react-redux'
import { getMinersAction } from '../../redux/actions/getMinersAction'
import { sendCommandAction } from '../../redux/actions/sendCommandAction'
import MinerList from '../../Components/MinerList'
import FilterMinerListSection from '../../Components/FilterMinerListSection'
import CustomerInformationCard from '../../Components/CustomerInformationCard'
import CommandSection from '../../Components/CommandSection'
import { Modal } from "react-responsive-modal";
import { getFavMinersAction } from '../../redux/actions/getFavMinersAction';
import { postFavMinersAction } from '../../redux/actions/postFavMinersAction';
import { deleteFavMinersAction } from '../../redux/actions/deleteFavMinersAction';
import { setErrorMessageAction } from '../../redux/actions/setErrorMessageAction';
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { setMinerTypeAction } from '../../redux/actions/setMinerTypeAction';
import { adjustMinersStatusAction } from '../../redux/actions/adjustMinersStatusAction'
import { clearLocationsAction } from '../../redux/actions/clearLocationsAction'
import { markMinersDeletedAction } from '../../redux/actions/markMinersDeletedAction'
import { clearMinersAction } from '../../redux/actions/clearMinersAction'
import { resetMinerDisplayAction } from '../../redux/actions/resetMinerDisplayAction'
import './style.css'
import * as XLSX from 'xlsx';
import history from '../../history'

class SubnetView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: '',
      subnet: 0,
      fetchCounter: 1,
      selectedMiners: [],
      isAllSelected: false,
      isShiftDown: false,
      lastSelectionItem: null,
      orderBy: 'Location',
      acending: true,
      typeModalOpen: false,
      statusModalOpen: false,
      formErrorDetected: false,
      filter: {
        filterWorker: '',
        filterLocation: '',
        filterIp: '',
        filterRack: '',
        fromHeat: '',
        toHeat:'',
        fromCurrentSpeed:'',
        toCurrentSpeed: '',
        fromAvgSpeed: '',
        toAvgSpeed: '',
        fromMissingAsic: '',
        toMissingAsic: '',
        filterType: '',
        fromPerformance: '',
        toPerformance: '',
        status: '',
      },
      intervalID: '',
      subnetInfo: ''
    }
  }
  //This is used to select more than a single miner at a time from the table.
  handleShiftDown = (event) => {
    if (event.key === "Shift") {
      const { isShiftDown } = this.state;
      if (!isShiftDown) {
        this.setState({ isShiftDown: true })
      }
    }
  }
  handleScroll = (e) => {
    const { fetchCounter } = this.state;
    var doc = document.documentElement;
    const scrollTop = e.srcElement.scrollTop
    const height = document.documentElement.offsetHeight;
		if (scrollTop > height*fetchCounter){
      this.setState({fetchCounter: fetchCounter+1})
    }
		return;
	};
  handleShiftUp = (event) => {
    if (event.key === "Shift") {
      const { isShiftDown } = this.state;
      if (isShiftDown) {
        this.setState({ isShiftDown: false })
      }
    }
  }
  handleFilter = (e) => {
    let { filter, formErrorDetected } = this.state
    if((!e.key ||  e.key == 'Enter' || e.key == " ") && !formErrorDetected){
      switch (e.target.name) {
        case 'toMissingAsic':
        case 'toAvgSpeed':
        case 'toPerformance':
        case 'toCurrentSpeed':
        case 'toHeat':
          const subString = e.target.name.substring(2, e.target.name.length)
            if(e.target.value !== '' && filter[`from${subString}`] > e.target.value){
              this.setState({formErrorDetected: true})
              alert('From performance is bigger then to')
            }
            else{
              if(e.target.value == ''){
                filter[e.target.name] = e.target.value;
                const filterArray = this.filterArrayToString(filter)
                this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, filterArray);
              }
              else if(isNaN(e.target.value)){
                alert("enter a number")
              }
              else{
                filter[e.target.name] = parseInt(e.target.value);
                const filterArray = this.filterArrayToString(filter)
                this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, filterArray);
              }
            }
          break;
        case "filterWorker":
        case "filterLocation":
        case "filterIp":
        case "filterType":
          filter[e.target.name] = e.target.value;
          const filterArray = this.filterArrayToString(filter)
          this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, filterArray);
          break;
        case 'fromHeat':
        case 'fromCurrentSpeed':
        case 'fromAvgSpeed':
        case 'fromMissingAsic':
        case 'fromPerformance':
          const subString2 = e.target.name.substring(4, e.target.name.length)
          if(filter[`to${subString2}`] !== '' && filter[`to${subString2}`] < e.target.value){
            this.setState({formErrorDetected: true})
            alert('From is bigger then to')
          }
          else{
            if(e.target.value == ''){
              filter[e.target.name] = e.target.value
              const filterArray = this.filterArrayToString(filter)
              this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, filterArray);
            }
            else if(isNaN(e.target.value)){
              alert("enter a number")
            }
            else{
              filter[e.target.name] = parseInt(e.target.value);
              const filterArray = this.filterArrayToString(filter)
              this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, filterArray);
            }
          }
          break
      }
    }
    else if(formErrorDetected){
      this.setState({formErrorDetected: false})
    }
  }
  componentDidMount = async () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    if(this.props.match.params.name !== this.props.subnetStore.client|| this.props.match.params.subnet !== this.props.subnetStore.subnetID){
      const store = {
        subnetID: this.props.match.params.subnet,
        client: this.props.match.params.client,
        pageSize: 25,
        pageNumber: 1,
        orderBy: 'Location',
        acending: true,
        type: 'subnetList'
      }
      this.props.updatePageInfoAction(store);
    }
    let subnetInfo = this.props.subnets.filter((item) => item.ID == this.props.match.params.subnet)
    subnetInfo = subnetInfo.length > 0?subnetInfo[0]:''
    window.sessionStorage.setItem('location', window.location.pathname)
    //window.addEventListener ? document.addEventListener('scroll', this.handleScroll): document.attachEvent('scroll', this.handleScroll);
    // try and perseve the history
    //const saveHistory = JSON.parse(JSON.stringify(window.history))
    const saveHistory = JSON.stringify(history)
    window.sessionStorage.setItem('history', saveHistory);
    window.addEventListener ? document.addEventListener('keydown', this.handleShiftDown) : document.attachEvent('keydown', this.handleShiftDown);
    window.addEventListener ? document.addEventListener('keyup', this.handleShiftUp) : document.attachEvent('keyup', this.handleShiftUp);
    this.initalSetup()
    this.setState({ client: this.props.match.params.name, subnet: this.props.match.params.subnet, subnetInfo });
    const toHeatElement = document.getElementById("filter-to-heat")
    const toSpeedElement = document.getElementById("filter-to-speed")
    const toAvgSpeedElement = document.getElementById("filter-to-avgSpeed")
    const toMissingAsicElement = document.getElementById("filter-to-missing-asic")
    const toPerformanceElement = document.getElementById("filter-to-performance")
    const fromHeatElement = document.getElementById("filter-from-heat")
    const fromSpeedElement = document.getElementById("filter-from-speed")
    const fromAvgSpeedElement = document.getElementById("filter-from-avgSpeed")
    const fromMissingAsicElement = document.getElementById("filter-from-missing-asic")
    const fromPerformanceElement = document.getElementById("filter-from-performance")
    const filterWorker = document.getElementById("filterWorker")
    const filterLocation = document.getElementById("filterLocation")
    const filterIp = document.getElementById("filterIp")
    const filterType = document.getElementById("filterType")
    if(toHeatElement){
      toHeatElement.addEventListener('blur', this.handleFilter)
      toHeatElement.addEventListener('keydown', this.handleFilter)
    }
    if(toSpeedElement){
      toSpeedElement.addEventListener('blur', this.handleFilter)
      toSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(toAvgSpeedElement){
      toAvgSpeedElement.addEventListener('blur', this.handleFilter)
      toAvgSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(toMissingAsicElement){
      toMissingAsicElement.addEventListener('blur', this.handleFilter)
      toMissingAsicElement.addEventListener('keydown', this.handleFilter)
    }
    if(toPerformanceElement){
      toPerformanceElement.addEventListener('blur', this.handleFilter)
      toPerformanceElement.addEventListener('keydown', this.handleFilter)
    }
    if(filterWorker){
      filterWorker.addEventListener('blur', this.handleFilter)
      filterWorker.addEventListener('keydown', this.handleFilter)
    }
    if(filterLocation){
      filterLocation.addEventListener('blur', this.handleFilter)
      filterLocation.addEventListener('keydown', this.handleFilter)
    }
    if(filterIp){
      filterIp.addEventListener('blur', this.handleFilter)
      filterIp.addEventListener('keydown', this.handleFilter)
    }
    if(filterType){
      filterType.addEventListener('blur', this.handleFilter)
      filterType.addEventListener('keydown', this.handleFilter)
    }
    if(fromHeatElement){
      fromHeatElement.addEventListener('blur', this.handleFilter)
      fromHeatElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromSpeedElement){
      fromSpeedElement.addEventListener('blur', this.handleFilter)
      fromSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromAvgSpeedElement){
      fromAvgSpeedElement.addEventListener('blur', this.handleFilter)
      fromAvgSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromMissingAsicElement){
      fromMissingAsicElement.addEventListener('blur', this.handleFilter)
      fromMissingAsicElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromPerformanceElement){
      fromPerformanceElement.addEventListener('blur', this.handleFilter)
      fromPerformanceElement.addEventListener('keydown', this.handleFilter)
    }
  }
  componentDidUpdate = () => {
    if(this.props.clientStore.refreshData && this.state.intervalID === ''){
      const intervalID = setInterval(this.initalSetup, 60000);
      this.setState({intervalID})
    }
    if(!this.props.clientStore.refreshData && this.state.intervalID !== ''){
      const { intervalID } = this.state;
      clearInterval(intervalID)
      this.setState({intervalID: ''})
    }
  }
  initalSetup = async() => {
    const { filter } = this.state
    const filterArray = this.filterArrayToString(filter)
    this.props.getTypesAction(this.props.userStore.result, this.props.match.params.name)
    await this.props.getFavMinersAction(this.props.userStore.result);
    await this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, filterArray);
  }
  refreshData = () => {
    const { filter } = this.state
    const filterArray = this.filterArrayToString(filter)
    this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, filterArray);
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('location')
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener('scroll', this.handleScroll);
    const toHeatElement = document.getElementById("filter-to-heat")
    const toSpeedElement = document.getElementById("filter-to-speed")
    const toAvgSpeedElement = document.getElementById("filter-to-avgSpeed")
    const toMissingAsicElement = document.getElementById("filter-to-missing-asic")
    const toPerformanceElement = document.getElementById("filter-to-performance")
    const filterWorker = document.getElementById("filterWorker")
    const filterLocation = document.getElementById("filterLocation")
    const filterIp = document.getElementById("filterIp")
    const filterType = document.getElementById("filterType")
    const { intervalID } = this.state;
    this.props.clearMinersAction()
    if(toHeatElement){
      toHeatElement.removeEventListener('blur', this.handleFilter)
      toHeatElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toSpeedElement){
      toSpeedElement.removeEventListener('blur', this.handleFilter)
      toSpeedElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toAvgSpeedElement){
      toAvgSpeedElement.removeEventListener('blur', this.handleFilter)
      toAvgSpeedElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toMissingAsicElement){
      toMissingAsicElement.removeEventListener('blur', this.handleFilter)
      toMissingAsicElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toPerformanceElement){
      toPerformanceElement.removeEventListener('blur', this.handleFilter)
      toPerformanceElement.removeEventListener('keydown', this.handleFilter)
    }
    if(filterWorker){
      filterWorker.removeEventListener('blur', this.handleFilter)
      filterWorker.removeEventListener('keydown', this.handleFilter)
    }
    if(filterLocation){
      filterLocation.removeEventListener('blur', this.handleFilter)
      filterLocation.removeEventListener('keydown', this.handleFilter)
    }
    if(filterIp){
      filterIp.removeEventListener('blur', this.handleFilter)
      filterIp.removeEventListener('keydown', this.handleFilter)
    }
    if(filterType){
      filterType.removeEventListener('blur', this.handleFilter)
      filterType.removeEventListener('keydown', this.handleFilter)
    }
    clearInterval(intervalID)
  }
  //Used to fetch more miners to render in the next page of the table.
  onPageClick = async (pageNumber) => {
    // needs to be != because minerStore.currentPage is string
    const { filter } = this.state;
    if (pageNumber != this.props.minersStore.current_page) {
      if (pageNumber !== 0 && pageNumber <= this.props.minersStore.max_page) {
        //rather then awaiting here could possibly do this in the getMinersAction
        this.props.resetMinerDisplayAction()
        await this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, this.filterArrayToString(filter));
      }
    }
    const store = {
      subnetID: this.props.match.params.subnet,
      client: this.props.match.params.name,
      pageSize: this.props.subnetStore.pageSize,
      pageNumber: pageNumber,
      orderBy: this.props.subnetStore.orderBy,
      acending: this.props.subnetStore.acending,
      type: 'subnetList'
    }
    document.body.scrollTop = 0;
    this.props.updatePageInfoAction(store);
  }
  //This is the function that selects miners
  onItemSelect = (mac, item) => {
    let { selectedMiners, isShiftDown, lastSelectionItem } = this.state;
    if (isShiftDown) {
      let max = Math.max(item, lastSelectionItem);
      let min = Math.min(item, lastSelectionItem) + 1;
      let newArray = this.props.minersStore.miners.slice(min, max)
      const macArray = newArray.map((item) => item.MAC_Address)
      macArray.map((item) => {
        const index = selectedMiners.indexOf(item);
        if (index === -1) {
          selectedMiners.push(item)
        }
        else {
          selectedMiners.splice(index, 1);
        }
      })
    }
    const index = selectedMiners.indexOf(mac);
    if (index === -1) {
      selectedMiners.push(mac);
    }
    else {
      selectedMiners.splice(index, 1);
    }
    this.setState({ selectedMiners, lastSelectionItem: item });
  }
  //This function is used to select all miners from the table.
  onSelectAll = (isAllSelected) => {
    let selectedMiners = [];
    if (isAllSelected) {
      const { miners } = this.state;
      selectedMiners = this.props.minersStore.miners.map((item) => item.MAC_Address);
    }
    this.setState({ isAllSelected, selectedMiners })
  }
  //Function that's called when either Restart of Configure button is pressed.
  onClickCommand = (type) => {
    const { selectedMiners } = this.state;
    if (selectedMiners.length === 0) {
      this.props.setErrorMessageAction("You need to pick atleast one miner");
      return;
    }
    if(type == "delete"){
      this.props.markMinersDeletedAction(this.props.userStore.result, selectedMiners, this.props.match.params.name)
    }
    else{
      this.props.sendCommandAction(this.props.userStore.result, this.props.match.params.name, { Macs: selectedMiners }, type);
    }
    this.setState({ selectedMiners: [] })
  }
  //Sorts the table according to what column was selected.
  onSort = (type) => {
    const { filter } = this.state;
    let acending = this.props.subnetStore.acending ;
    if (this.props.subnetStore.orderBy === type) {
      acending = !acending;
    }
    else {
      this.props.subnetStore.acending = true;
    }
    this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, type, acending, this.filterArrayToString(filter));
    const store = {
      subnetID: this.props.match.params.subnet,
      client: this.props.match.params.name,
      pageSize: this.props.subnetStore.pageSize,
      pageNumber: this.props.subnetStore.pageNumber,
      orderBy: type,
      acending: acending,
      type: 'subnetList'
    }
    this.props.updatePageInfoAction(store);
  }
  //Function used to route us to the minersDetails site when it was clicked from the table or the heatmap.
  onItemClick = async (link) => {
    this.props.history.push(link)
  }
  //Adds miners to fav and delets them from fav
  alterFav = (MAC_Address, ID) => {
    const arrayid = [];
    if(ID === null){
      arrayid[0] = {MAC_Address};
      this.props.postFavMinersAction(arrayid, this.props.userStore.result);
    }
    else {
      arrayid[0] = {ID};
      this.props.deleteFavMinersAction(this.props.userStore.result, arrayid);
    }
  }
  //Adds many miners to the fav table.
  addManyToFav = () => {
    const newObj = this.state.selectedMiners.map((item) => {
      return{
        MAC_Address: item
      }
    })
    this.props.postFavMinersAction(newObj, this.props.userStore.result);
  }
  //Deletes many miners from the fav table.
  deleteManyFromFav = () => {
    let newObj = this.props.favMinersStore.data.filter((item) => this.state.selectedMiners.indexOf(item.MAC_Address) != -1)
    newObj = newObj.map((item) => {return{ID:item.ID}})
    this.props.deleteFavMinersAction(this.props.userStore.result, newObj);
  }
  //Changes the size of each page in the table.
  changePageSize = (e) => {
    //this.setState({ [e.target.name]: e.target.value })
    const value = parseInt(e.target.value)
    const { filter } = this.state;
    let { pageNumber } = this.props.subnetStore
    let items = parseInt(this.props.minersStore.max_page)*parseInt(this.props.minersStore.page_size);
    if(value > items){
      pageNumber = 1;
    }
    if((this.props.subnetStore.pageNumber*value) > items){
      pageNumber = 1;
    }
    this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, value, pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, this.filterArrayToString(filter));
    const store = {
      subnetID: this.props.match.params.subnet,
      client: this.props.match.params.name,
      pageSize: value? value:0,
      pageNumber: pageNumber,
      orderBy: this.props.subnetStore.orderBy,
      acending: this.props.subnetStore.acending,
      type: 'subnetList'
    }
    this.props.updatePageInfoAction(store);
  }
  changeType = async (type) => {
    let { selectedMiners, filter } = this.state;
    selectedMiners = selectedMiners.map((item, i) => {
      return {
        MAC_Address: item,
        Type: type
      }
    })
    await this.props.setMinerTypeAction(selectedMiners, this.props.userStore.result, this.props.match.params.name)
    this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, this.filterArrayToString(filter));
    this.setState({selectedMiners: [], typeModalOpen: false})
  }
  filterArrayToString = (filter) => {
    let filterArray = []
    for(var key in filter) {
        if (filter.hasOwnProperty(key)) {
          if(filter[key] !== ''){
            filterArray.push({[key]: filter[key]})
          }
        }
    }
    return filterArray.map((item) => JSON.stringify(item)).join('&filter[]=')
  }
  onFilterInputChange = (e) => {
    let { filter } = this.state;
    let breakFrom = 0
    if(e.target.value == ' '){
      return
    }
    filter[e.target.name] = e.target.value;
    if(e.target.name === 'filterType' || e.target.name === 'filterRack' || e.target.name === 'filterStatus'){
      const filterArray = this.filterArrayToString(filter)
      this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, filterArray);
    }
    this.setState({filter})
  }
  onAlterStatusClick = (status) => {
    const { selectedMiners } = this.state;
    if(selectedMiners.length === 0){
      this.setState({statusModalOpen: false})
      alert("No miners selected")
      return
    }
    this.props.adjustMinersStatusAction(this.props.userStore.result, {macs:selectedMiners}, this.props.match.params.name, status)
    this.setState({statusModalOpen: false, selectedMiners: []})
  }
  clearFilter = () => {
    this.setState({filter: {
      filterWorker: '',
      filterLocation: '',
      filterIp: '',
      filterRack: '',
      fromHeat: '',
      toHeat:'',
      fromCurrentSpeed:'',
      toCurrentSpeed: '',
      fromAvgSpeed: '',
      toAvgSpeed: '',
      fromMissingAsic: '',
      toMissingAsic: '',
      filterType: '',
      fromPerformance: '',
      toPerformance: '',
      status: ''
    }})
    this.props.getMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.subnet, this.props.subnetStore.pageSize, this.props.subnetStore.pageNumber, this.props.subnetStore.orderBy, this.props.subnetStore.acending, '');
  }
  clearLocations = () => {
    const { selectedMiners } = this.state;
    this.props.clearLocationsAction(this.props.userStore.result, this.props.match.params.name, {macs: selectedMiners})
  }
  exportList = (e) => {
    let { miners } = this.props.minersStore;
    let newMinerList = []
    miners.map((item) => {
      let tmpObj = {}
      Object.keys(this.props.listColumns).map((key, index) =>{
        if(this.props.listColumns[key]){
          tmpObj[key] = item[key]
        }
      })
      newMinerList.push(tmpObj)
    })
    switch (e.target.value) {
      case 'xlsx':
        var wb1 = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(newMinerList);
        XLSX.utils.book_append_sheet(wb1, ws1, 'miner-list')
        const now = new Date()
        const date =  now.getUTCFullYear() +"-"+ (now.getUTCMonth()+1) +"-"+ now.getUTCDate() + "_" + now.getUTCHours() + "-" + now.getUTCMinutes() + "-" + now.getUTCSeconds()
        XLSX.writeFile(wb1, `listExport-${date}-.xlsx`)
        break;
      default:
    }
  }
  render() {
    const modalStyles = {
      modal: {
          width: '400px',
          height: '400px',
      }
    }
    const { subnet, client, selectedMiners, isAllSelected, typeModalOpen, filter, subnetInfo, statusModalOpen} = this.state;
    return (
      <div className="subnetViewDisplay">
        <div className="adc_subnetView-topContainer">
          <div className="adc_subnet-topContainer">
            <div className='adc_subnet-topLeftContainer'>
              <FilterMinerListSection
                filter={filter}
                onFilterInputChange={this.onFilterInputChange}
                minerTypes={this.props.typeStore}
                clearFilter={this.clearFilter}
              />
              <CommandSection
                onClick={this.onClickCommand}
                addManyToFav={this.addManyToFav}
                deleteManyFromFav={this.deleteManyFromFav}
                onChangeTypeClick={() => this.setState({typeModalOpen: true})}
                openAlterStatus={() => this.setState({statusModalOpen: true})}
                clearLocations={this.clearLocations}
              />
            </div>
            <div className='adc_subnet-topRightContainer'>
              <CustomerInformationCard
                type='Scanner'
                name={subnetInfo.Name}
                totalMiners={subnetInfo.totalMiners}
                offlineMiners={subnetInfo.minersOffline}
                totalHashrate={subnetInfo.Hash_Readable}
                customerName={client}
              />
            </div>
          </div>
        </div>
          <MinerList
            exportList={this.exportList}
            miners={this.props.minersStore.miners}
            client={client}
            subnet={subnet}
            selectedMiners={selectedMiners}
            onItemSelect={this.onItemSelect}
            onSelectAll={this.onSelectAll}
            isAllSelected={isAllSelected}
            onPageClick={this.onPageClick}
            currentPage={this.props.minersStore.current_page}
            maxPage={this.props.minersStore.max_page}
            onSort={this.onSort}
            orderBy={this.props.subnetStore.orderBy}
            acending={this.props.subnetStore.acending}
            onClick={this.onItemClick}
            onClickCommand={this.onClickCommand}
            alterFav={this.alterFav}
            pageSize={this.props.subnetStore.pageSize}
            changePageSize={this.changePageSize}
            favoriteMiners={this.props.favMinersStore.data}
            addManyToFav={this.addManyToFav}
            deleteManyFromFav={this.deleteManyFromFav}
            onChangeTypeClick={() => this.setState({typeModalOpen: true})}
            filter={filter}
            onFilterInputChange={this.onFilterInputChange}
            refreshData={this.refreshData}
          />
          <Modal
            open={typeModalOpen}
            onClose={() => this.setState({typeModalOpen: false})}
            classNames={{
              animationIn: 'EnterAnimation',
              animationOut: 'LeaveAnimation',
            }}
            animationDuration={500}
          >
            <h1>Pick a type</h1>
            <ul className="list-group">
              {this.props.typeStore.map((item) => {
                return <li className='list-group-item' onClick={(e) => this.changeType(item.ID)}>{item.Name}</li>
              })}
            </ul>
          </Modal>
          <Modal
            open={statusModalOpen}
            onClose={() => this.setState({statusModalOpen: false})}
            styles={modalStyles}
          >
            <h1>Pick a status</h1>
            <ul className="list-group">
              <li className='list-group-item' onClick={e => this.onAlterStatusClick('Online')}>Online</li>
              <li className='list-group-item' onClick={e => this.onAlterStatusClick('Recovery')}>Recovery</li>
              <li className='list-group-item' onClick={e => this.onAlterStatusClick('API_DOWN')}>API_DOWN</li>
              <li className='list-group-item' onClick={e => this.onAlterStatusClick('Missing_Fan')}>Missing_Fan</li>
              <li className='list-group-item' onClick={e => this.onAlterStatusClick('Missing_PSU')}>Missing_PSU</li>
              <li className='list-group-item' onClick={e => this.onAlterStatusClick('Missing_CTRL_Board')}>Missing_CTRL_Board</li>
            </ul>
          </Modal>
      </div>
    )
  }
}
const mapStateTopProps = (props) => ({
  minersStore: props.minersReducer,
  userStore: props.userReducer,
  commandStore: props.commandReducer,
  favMinersStore: props.favMinerReducer,
  subnetStore: props.pageReducer.subnetList,
  subnets: props.subnetReducer.subnets,
  clientStore: props.clientReducer,
  typeStore: props.typeReducer.types,
  listColumns: props.tableColumnsReducer.minerList
})
export default connect(mapStateTopProps, {
  getFavMinersAction,
  deleteFavMinersAction,
  postFavMinersAction,
  updatePageInfoAction,
  getMinersAction,
  sendCommandAction,
  setErrorMessageAction,
  updateClientAction,
  getTypesAction,
  setMinerTypeAction,
  adjustMinersStatusAction,
  clearLocationsAction,
  markMinersDeletedAction,
  clearMinersAction,
  resetMinerDisplayAction
})(SubnetView)
//{miners.map((item) => <MinerListItem MAC_Address={item.MAC_Address} Max_Heat={item.Max_Heat} Power_Consumption={item.Power_Consumption}/>)}

/*
onFilterInputChange = (e) => {
  let { filter } = this.state;
  let breakFrom = 0
  if(e.target.value == ' '){
    return
  }
  switch (e.target.name) {
    case 'fromHeat':
    case 'fromCurrentSpeed':
    case 'fromAvgSpeed':
    case 'fromMissingAsic':
    case 'fromPerformance':
      const subString2 = e.target.name.substring(4, e.target.name.length)
      if(filter[`to${subString2}`] !== '' && filter[`to${subString2}`] < e.target.value){
        alert('From is bigger then to')
      }
      else{
        if(e.target.value == ''){
          filter[e.target.name] = e.target.value
        }
        else if(isNaN(e.target.value)){
          alert("enter a number")
        }
        else{
          filter[e.target.name] = parseInt(e.target.value);
        }
      }
      break
    default:
      filter[e.target.name] = e.target.value;
  }
  this.setState({filter})
}
*/

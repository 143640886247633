import { getFirmwareVendorsConstant } from '../../constants/actions'
import service from '../../services/firmwareService'




export const getFirmwareVendorsAction = (token) => {
    return async(dispatch) => {
        const result = await service.getFirmwareVendors(token)
        dispatch(success(result))
    }
}
const success = (result) =>({
  type: getFirmwareVendorsConstant,
  payload: result
})

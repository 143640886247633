import React from 'react'
import MaintenanceLogs from '../../Components/MaintenanceLogs';
import InventoryTable from '../../Components/InventoryTable'
import InventoryHistory from '../../Components/InventoryHistory'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { getMaintenanceLogsAction } from '../../redux/actions/getMaintenanceLogsAction'
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { adjustInventoryAction } from '../../redux/actions/adjustInventoryAction'
import { getInventoryAction } from '../../redux/actions/getInventoryAction'
import { getInventoryHistoryAction } from '../../redux/actions/getInventoryHistoryAction'
import { getAccessLevelsAction } from '../../redux/actions/getAccessLevelsAction'
import { clearMaintenanceAction } from '../../redux/actions/clearMaintenanceAction'
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux'
import './style.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class MaintenanceView extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      tabValue: 0,
      fans: 0,
      hashboards: 0,
      controlboards: 0,
      cables: 0,
      psu: 0,
      minerType: '',
      overriding:  false,
      privilegeLevel: 0
    }
  }
  componentDidMount = async() => {
    const element = document.getElementById("maintenanceLink")
    if (element) {
      element.classList.add("itemSelected")
    }
    if(this.props.maintenancePageStore.client !== this.props.match.params.name){
      this.props.updatePageInfoAction({
        client: this.props.match.params.name,
        pageSize: 25,
        pageNumber: 1,
        orderBy: 'Log_Date',
        acending: true
      })
    }
    this.props.getInventoryHistoryAction(
      this.props.token,
      this.props.match.params.name,
      this.props.inventoryHistoryPage.pageSize,
      this.props.inventoryHistoryPage.pageNumber,
      this.props.inventoryHistoryPage.orderBy,
      this.props.inventoryHistoryPage.acending)
    this.props.getMaintenanceLogsAction(
      this.props.token,
      this.props.match.params.name,
      this.props.maintenancePageStore.pageSize,
      this.props.maintenancePageStore.pageNumber,
      this.props.maintenancePageStore.orderBy,
      this.props.maintenancePageStore.acending)
    this.props.getInventoryAction(this.props.token, this.props.match.params.name)
    this.props.getAccessLevelsAction(this.props.token)
    await this.props.getTypesAction(this.props.token, this.props.match.params.name);
    let privilegeLevel = this.props.clientStore.clients.filter((item) => item.client == this.props.match.params.name)
    if(privilegeLevel.length > 0){
      privilegeLevel = privilegeLevel[0].level
    }
    if(this.props.typeStore.types.length > 0){
      this.setState({
        minerType: this.props.typeStore.types[0].Name,
        privilegeLevel
      })
    }
  }
  componentWillUnmount = () => {
    this.props.clearMaintenanceAction()
  }
  handleChange = (event, value) => {
    this.setState({
      tabValue: value
    });
  }
  onMaintenancePageClick = (pageNumber) => {
    const { pageSize, orderBy, acending } = this.props.maintenancePageStore;
    if (pageNumber !== 0 && pageNumber <= this.props.maintenanceStore.max_page) {
      this.props.getMaintenanceLogsAction(this.props.token, this.props.match.params.name, pageSize, pageNumber, orderBy, acending);
    }
    const store = {
      MAC_Address: this.props.match.params.miner,
      client: this.props.maintenancePageStore.client,
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'maintenenceList'
    }
    this.props.updatePageInfoAction(store);
  }
  onMaintenanceSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.maintenancePageStore;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getMaintenanceLogsAction(this.props.token, this.props.match.params.name, pageSize, pageNumber, type, acending);
    const store = {
      client: this.props.maintenancePageStore.client,
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'maintenenceList'
    }
    this.props.updatePageInfoAction(store);
  }
  onMaintenanceChangePageSize = (e) => {
    const { orderBy, acending } = this.props.maintenancePageStore;
    const value = parseInt(e.target.value)
    let pageNum = this.props.maintenancePageStore.pageNumber;
    if(value*parseInt(pageNum) > parseInt(this.props.maintenanceStore.max_page)*parseInt(this.props.maintenanceStore.page_size)){
      pageNum = 1;
    }
    if(value > parseInt(this.props.maintenanceStore.max_page)*parseInt(this.props.maintenanceStore.page_size)){
      pageNum = 1;
    }
    this.props.getMaintenanceLogsAction(this.props.token, this.props.match.params.name, value, pageNum, orderBy, acending);
    const store = {
      MAC_Address: this.props.match.params.miner,
      pageSize: value? value: 0,
      pageNumber: pageNum,
      client: this.props.match.params.name,
      type: this.props.maintenancePageStore.type,
      orderBy: this.props.maintenancePageStore.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onInputChange = (e) => {
    if(e.target.name !== "minerType" && isNaN(e.target.value)){
      alert("enter a number")
    }
    else{
      this.setState({ [e.target.name]: e.target.value })
    }
  }
  onInventorySubmit = (type) => {
    const { fans, hashboards, cables, psu, controlboards, minerType } = this.state;
    let body = []
    if(fans != 0){
      body.push({
        type: "Fans",
        amount: fans
      })
    }
    if(hashboards != 0){
      body.push({
        type: "Hashboards",
        amount: hashboards
      })
    }
    if(cables != 0){
      body.push({
        type: "Cables",
        amount: cables
      })
    }
    if(psu != 0){
      body.push({
        type: "PSU",
        amount: psu
      })
    }
    if(controlboards != 0){
      body.push({
        type: "Controlboards",
        amount: controlboards
      })
    }
    this.props.adjustInventoryAction(this.props.token, this.props.match.params.name, type, {minerType, types: body})
    this.setState({fans: 0, hashboards: 0, cables: 0, psu:0, controlboards:0})
  }
  onOverride = (submit) => {
    const { minerType, fans, hashboards, cables, psu, controlboards } = this.state;
    if(submit){
      const body = {
        Fans: fans,
        Hashboards: hashboards,
        Controlboards: controlboards,
        PSU: psu,
        Cables: cables,
      }
      this.props.adjustInventoryAction(this.props.token, this.props.match.params.name, "override", {minerType, item: body})
      this.setState({fans: '', hashboards: '', cables: '', psu: '', controlboards:'', overriding: false})
    }
    else{
      let item = this.props.inventory.inventory.filter((item) => item.Miner_Type == minerType);
      if(item.length > 0){
        this.setState({
          fans: item[0].Fans,
          hashboards: item[0].Hashboards,
          controlboards: item[0].Controlboards,
          cables: item[0].Cables,
          psu: item[0].PSU,
          overriding: true
        })
      }
    }
  }
  onInventoryHistoryClick = (e) => {
    console.log(e)
  }
  onInventoryHistorySort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.inventoryHistoryPage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getInventoryHistoryAction(this.props.token, this.props.match.params.name, pageSize, pageNumber, type, acending);
    const store = {
      client: this.props.inventoryHistoryPage.client,
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'inventoryHistory'
    }
    this.props.updatePageInfoAction(store);
  }
  onInventoryChangePageSize = (e) => {
    const { orderBy, acending } = this.props.inventoryHistoryPage;
    const value = parseInt(e.target.value)
    let pageNum = this.props.inventoryHistoryPage.pageNumber;
    if(value*parseInt(pageNum) > parseInt(this.props.inventory.logs.max_page)*parseInt(this.props.inventory.logs.page_size)){
      pageNum = 1;
    }
    if(value > parseInt(this.props.inventory.logs.max_page)*parseInt(this.props.inventory.logs.page_size)){
      pageNum = 1;
    }
    this.props.getInventoryHistoryAction(this.props.token, this.props.match.params.name, value, pageNum, orderBy, acending);
    const store = {
      MAC_Address: this.props.match.params.miner,
      pageSize: value? value: 0,
      pageNumber: pageNum,
      client: this.props.match.params.name,
      type: this.props.inventoryHistoryPage.type,
      orderBy: this.props.inventoryHistoryPage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onInventoryHistoryPageClick = (pageNumber) => {
    const { pageSize, orderBy, acending } = this.props.inventoryHistoryPage;
    if (pageNumber !== 0 && pageNumber <= this.props.inventory.logs.max_page) {
      this.props.getInventoryHistoryAction(this.props.token, this.props.match.params.name, pageSize, pageNumber, orderBy, acending);
    }
    const store = {
      MAC_Address: this.props.match.params.miner,
      client: this.props.inventoryHistoryPage.client,
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'inventoryHistory'
    }
    this.props.updatePageInfoAction(store);
  }
  render(){
    const { tabValue, fans, hashboards, controlboards, cables, psu, minerType, overriding, privilegeLevel } = this.state;
    let accessLevelAdd = this.props.accessStore.levels.filter((item) => item.Access === 'Inventory_Add')
    if(accessLevelAdd.length > 0 ){
      accessLevelAdd = accessLevelAdd[0].Level
    }
    else{
      accessLevelAdd = Infinity;
    }
    let accessLevelSubtract = this.props.accessStore.levels.filter((item) => item.Access === 'Inventory_Subtract')
    if(accessLevelSubtract.length > 0 ){
      accessLevelSubtract = accessLevelSubtract[0].Level
    }
    else{
      accessLevelSubtract = Infinity;
    }
    let accessLevelOverride = this.props.accessStore.levels.filter((item) => item.Access === 'Inventory_Override')
    if(accessLevelOverride.length > 0 ){
      accessLevelOverride = accessLevelOverride[0].Level
    }
    else{
      accessLevelOverride = Infinity;
    }
    let displayFooter = privilegeLevel >= Math.min(accessLevelAdd, accessLevelOverride, accessLevelSubtract);
    let numberOfPrivileges;
    if(privilegeLevel >= accessLevelSubtract && privilegeLevel >= accessLevelAdd && privilegeLevel >= accessLevelOverride){
      numberOfPrivileges=3
    }
    else if (privilegeLevel >= accessLevelSubtract && privilegeLevel >= accessLevelAdd || privilegeLevel >= accessLevelSubtract && privilegeLevel >= accessLevelOverride  || privilegeLevel >= accessLevelOverride && privilegeLevel >= accessLevelAdd){
      numberOfPrivileges=2
    }
    else if (privilegeLevel >= accessLevelAdd || privilegeLevel >= accessLevelOverride || privilegeLevel >= accessLevelSubtract){
      numberOfPrivileges=1
    }
    return(
      <div className="maintenanceView-container">
        <Tabs
          className="tabContainer"
          value={tabValue}
          onChange={this.handleChange}
          aria-label='Configure View'
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{ width: '50%', marginLeft: '25%', marginRight: '25%' }}
        >
          <Tab label='Maintenance Logs' {...a11yProps(0)} />
          <Tab label='Inventory' {...a11yProps(1)} />
          <Tab label='Inventory History' {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tabValue} index={0} style={{ width: '90%', marginLeft: '5%' }}>
          <div className="maintenanceView-table">
            <MaintenanceLogs
              items={this.props.maintenanceStore.logs}
              client={this.props.match.params.name}
              currentPage={this.props.maintenanceStore.current_page}
              maxPage={this.props.maintenanceStore.max_page}
              onClick={this.onMaintenanceClick}
              orderBy={this.props.maintenancePageStore.orderBy}
              acending={this.props.maintenancePageStore.acending}
              onSort={this.onMaintenanceSort}
              pageSize={this.props.maintenancePageStore.pageSize}
              changePageSize={this.onMaintenanceChangePageSize}
              onPageClick={this.onMaintenancePageClick}
            />
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1} style={{ width: '90%', marginLeft:'5%' }}>
          <div className="inventory-table-div">
            <InventoryTable
              items={this.props.inventory.inventory}
              types={this.props.typeStore.types}
              fans={fans}
              hashboards={hashboards}
              controlboards={controlboards}
              cables={cables}
              psu={psu}
              minerType={minerType}
              onInputChange={this.onInputChange}
              onSubmit={this.onInventorySubmit}
              overriding={overriding}
              onOverride={this.onOverride}
              displayFooter={displayFooter}
              accessAdd={accessLevelAdd}
              accessSubtract={accessLevelSubtract}
              accessOverride={accessLevelOverride}
              privilegeLevel={privilegeLevel}
              numberOfPrivileges={numberOfPrivileges}
            />
          </div>
        </TabPanel>
      <TabPanel value={tabValue} index={2} style={{ width: '90%', marginLeft: '5%' }}>
        <div className="maintenanceView-table">
          <InventoryHistory
            items={this.props.inventory.logs.data}
            client={this.props.match.params.name}
            currentPage={this.props.inventory.logs.current_page}
            maxPage={this.props.inventory.logs.max_page}
            onClick={this.onInventoryHistoryClick}
            orderBy={this.props.inventoryHistoryPage.orderBy}
            acending={this.props.inventoryHistoryPage.acending}
            onSort={this.onInventoryHistorySort}
            pageSize={this.props.inventoryHistoryPage.pageSize}
            changePageSize={this.onInventoryChangePageSize}
            onPageClick={this.onInventoryHistoryPageClick}
          />
        </div>
      </TabPanel>
      </div>
    )
  }
}
const mapStateToProps = (store) => ({
  token: store.userReducer.result,
  clientStore: store.clientReducer,
  favItemStore: store.favItemReducer,
  maintenanceStore: store.maintenanceReducer,
  maintenancePageStore: store.pageReducer.maintenenceList,
  inventoryHistoryPage: store.pageReducer.inventoryHistory,
  inventory: store.inventoryReducer,
  typeStore: store.typeReducer,
  accessStore: store.accessReducer
})

export default connect(mapStateToProps, {
  updatePageInfoAction,
  getMaintenanceLogsAction,
  getInventoryAction,
  getTypesAction,
  adjustInventoryAction,
  getInventoryHistoryAction,
  getAccessLevelsAction,
  clearMaintenanceAction
})(MaintenanceView)

import {
  getMonthlyRevenueConstant,
  getDailyRevenueConstant,
  clearRevenueConstant
} from '../constants/actions'

const defaultState = {
  ok: false,
  daily: null,
  monthly: null
}

export default (state=defaultState, action) =>{
  switch (action.type) {
    case clearRevenueConstant:
      return{
        ok: false,
        daily: null,
        monthly: null
      }
    case getMonthlyRevenueConstant:
      if(action.payload.ok){
        return{
          ...state,
          ok: true,
          monthly: action.payload.data
        }
      }
      return state
    case getDailyRevenueConstant:
      if(action.payload.ok){
        return{
          ...state,
          ok: true,
          daily: action.payload.data
        }
      }
      return state
    default:
      return state
  }
}

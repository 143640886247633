import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'
import './style.css'
import image from '../../images/advania_gagnaver-7.jpg'
//import SimplePieChart from '../SimplePieChart'
import { PieChart } from 'react-chartkick';
import * as d3 from 'd3'
import {
  Card, CardImg, CardText, CardBody,
  CardTitle,
} from 'reactstrap';

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
const Subnet = ({ subnetId, name, hashrate, power_consumption, size, client, offlineMiners = 1, totalMiners = 1, performance, invalidTypes }) => {
  const linkName = `/client/${client}/subnet/${subnetId}`
  return(
    <div className="subnets-info">
      <Card>
        <NavLink to={linkName}>
          <CardTitle>{name}</CardTitle>
        </NavLink>
        <div className="subnet-chart-div">
          <PieChart
              data={[
                ["Online",(totalMiners-offlineMiners)],
                ["Offline", offlineMiners]]}
              width={'50%'}
              height={'100%'}
              colors={['green','black']}
              radius='4'
              paddingAngle="1"
              totalValue={totalMiners}
              lineWidth="35"
              viewBoxSize={[15, 15]}
              center={[7,7]}
              />
          </div>
        <div>
          <CardText>Hashrate: {hashrate}</CardText>
          {/*
            <CardText>Power Consumption: {power_consumption}</CardText>
            <CardText>Subnet Size: {size}</CardText>
          */}
          {invalidTypes == 0?(
            <CardText>Performance: {`${round(performance)}%`}</CardText>):(
              <CardText>Performance Unavailable, {invalidTypes} invalid types</CardText>
            )}
        </div>
      </Card>
    </div>
  )
}
//<SimplePieChart data={[totalMiners-offlineMiners, totalMiners]}/>
Subnet.propTypes = {
  subnetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  hashrate: PropTypes.string.isRequired,
  power_consumption: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  client: PropTypes.string.isRequired,
}

export default Subnet;

/*
<article className="card">
  <div className="thumb">
  </div>
  <div className="infos">
    <h2 className="title"><span className="flag"/></h2>
    <h3 className="date">november 2 - 4</h3>
    <h3 className="seats">2 seats remaining</h3>
    <p className="txt">
      | Join us for our Live Infinity Session in
      | beautiful New York City. This is a 3 day
      | intensive workshop where you'll learn
      | how to become a better version of...
    </p>
  </div>
</article>





<div className="subnets-info">
  <div className="subnets-info-card">
    <h1>Subnet Info</h1>
    <NavLink to={linkName}>
      <h5>Subnet Name: {name}</h5>
    </NavLink>
    <NavLink to={linkName}>
      <h5>Hashrate: {hashrate}</h5>
    </NavLink>
    <NavLink to={linkName}>
      <h5>Power Consumption: {power_consumption}</h5>
    </NavLink>
    <NavLink to={linkName}>
      <h5>Subnet Size: {size}</h5>
    </NavLink>
  </div>
</div>
*/

import React, {useState, useContext, useCallback, useEffect} from 'react'
import { connect } from 'react-redux'
import CustomerInformationCard from '../../Components/CustomerInformationCard'
import SelectionInformationCard from '../../Components/SelectionInformationCard'
import FilterListViewMiners from '../../Components/FilterListViewMiners'
import SectionHistoryGraph from '../../Components/SectionHistoryGraph'
import ListCommandButtons from '../../Components/ListCommandButtons'
import FormfieldSection from '../../Components/FormfieldSection'
import CommandSection from '../../Components/CommandSection'
import ListFilter from '../../Components/ListFilter'
import { Modal } from "react-responsive-modal";
import MinerList from '../../Components/MinerList'
import PickProfileModal from '../../Components/PickProfileModal'
import PickTuningProfileModal from '../../Components/PickTuningProfileModal'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { sendCommandAction } from '../../redux/actions/sendCommandAction'
import { getFavMinersAction } from '../../redux/actions/getFavMinersAction';
import { postFavMinersAction } from '../../redux/actions/postFavMinersAction';
import { deleteFavMinersAction } from '../../redux/actions/deleteFavMinersAction';
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { setMinerTypeAction } from '../../redux/actions/setMinerTypeAction';
import { adjustMinersStatusAction } from '../../redux/actions/adjustMinersStatusAction'
import { updateAlertAction } from '../../redux/actions/updateAlertAction'
import { clearLocationsAction } from '../../redux/actions/clearLocationsAction'
import { getProfilesAction } from '../../redux/actions/getProfilesAction'
import { markMinersDeletedAction } from '../../redux/actions/markMinersDeletedAction'
import { clearMinersAction } from '../../redux/actions/clearMinersAction'
import { getAllMinersAction } from '../../redux/actions/getAllMinersAction'
import { setErrorMessageAction } from '../../redux/actions/setErrorMessageAction';
import { getHousesAction } from '../../redux/actions/getHousesAction'
import { getPodsLimitedAction } from '../../redux/actions/getPodsLimitedAction'
import { clearPodsAction } from '../../redux/actions/clearPodsAction'
import { getSubnetsAction } from '../../redux/actions/getSubnetsAction'
import { resetMinerDisplayAction } from '../../redux/actions/resetMinerDisplayAction'
import { getMinerTypePowerAction } from '../../redux/actions/getMinerTypePowerAction'
import { clearMinerTypePowerAction } from '../../redux/actions/clearMinerTypePowerAction'
import { updateResponsePropertyAction } from '../../redux/actions/updateResponsePropertyAction'
import { postCustomMinerFlagsAction } from '../../redux/actions/postCustomMinerFlagsAction'
import { postProfileMinerConnectionsAction } from '../../redux/actions/postProfileMinerConnectionsAction'
import { getTuningProfilesAction } from '../../redux/actions/tuning/getTuningPofilesAction' 
import { minersViewQueryList } from '../../helper/queryLists.js'
import './style.css'
import * as XLSX from 'xlsx';
import history from '../../history'
import { formatBytes, updateQueryString, urlQueryHandler } from '../../helper/functions.js'
import flagService from '../../redux/services/flagService'
const jwt = require('jwt-simple');

class ListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: '',
      selectedMiners: [],
      isAllSelected: false,
      isShiftDown: false,
      lastSelectionItem: null,
      orderBy: 'Location',
      acending: true,
      typeModalOpen: false,
      statusModalOpen: false,
      pickModeModalOpen: false,
      formErrorDetected: false,
      tuningModalOpen: false,
      filter: {
        filterMac: '',
        filterWorker: '',
        filterSerialNumber: '',
        filterLocation: '',
        filterIp: '',
        filterPoolOne: '',
        filterPoolTwo: '',
        filterPoolThree: '',
        filterPoolOneEmpty: false,
        filterPoolTwoEmpty: false,
        filterPoolThreeEmpty: false,
        filterRack: [],
        fromRejectionRate: '',
        toRejectionRate:'',
        fromHeat: '',
        toHeat:'',
        fromCurrentSpeed:'',
        toCurrentSpeed: '',
        fromAvgSpeed: '',
        toAvgSpeed: '',
        fromMissingAsic: '',
        toMissingAsic: '',
        filterType: [],
        fromPerformance: '',
        toPerformance: '',
        filterScanner: [],
        filterHouse: [],
        filterPOD: [],
        filterStatus: [],
        filterBoards: [],
        filterFW: '',
        showDuplicateWorkerName: false,
        showDeleted: false,
        filterTuningPreset: '',
        filterTuningStatus: ''
      },
      intervalID: '',
      profileSelected: -1,
      tuningProfileSelected: -1,
      profileModalOpen: false,
      customFlagComment: '',
      customFlagModalOpen: false,
      userData: {
        databases:[]
      },
      socketId: ''
    }
  }
  //This is used to select more than a single miner at a time from the table.
  handleShiftDown = (event) => {
    if (event.key === "Shift") {
      const { isShiftDown } = this.state;
      if (!isShiftDown) {
        this.setState({ isShiftDown: true })
      }
    }
  }
  handleShiftUp = (event) => {
    if (event.key === "Shift") {
      const { isShiftDown } = this.state;
      if (isShiftDown) {
        this.setState({ isShiftDown: false })
      }
    }
  }
  handleFilter = (e) => {
    let { filter, formErrorDetected } = this.state
    if((!e.key ||  e.key == 'Enter' || e.key == " ") && !formErrorDetected){
      switch (e.target.name) {
        case 'toMissingAsic':
        case 'toAvgSpeed':
        case 'toPerformance':
        case 'toCurrentSpeed':
        case 'toRejectionRate':
        case 'toHeat':
          const subString = e.target.name.substring(2, e.target.name.length)
            if(e.target.value !== '' && filter[`from${subString}`] > e.target.value){
              this.setState({formErrorDetected: true})
              alert('From performance is bigger then to')
            }
            else{
              if(e.target.value == ''){
                filter[e.target.name] = e.target.value;
                const filterArray = this.filterArrayToString(filter)
                this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
              }
              else if(isNaN(e.target.value)){
                alert("enter a number")
              }
              else{
                filter[e.target.name] = e.target.value;
                const filterArray = this.filterArrayToString(filter)
                this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
              }
            }
          break;
        case "filterWorker":
        case "filterLocation":
        case "filterIp":
        case "filterType":
        case "filterMac":
        case "filterFW":
        case "filterPoolOne":
        case "filterPoolTwo":
        case "filterPoolThree":
        case "filterTuningStatus":
        case "filterTuningPreset":
        case 'filterSerialNumber':
          filter[e.target.name] = e.target.value;
          const filterArray = this.filterArrayToString(filter)
          this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
          break;
        case 'fromHeat':
        case 'fromCurrentSpeed':
        case 'fromAvgSpeed':
        case 'fromMissingAsic':
        case 'fromPerformance':
        case 'fromRejectionRate':
          const subString2 = e.target.name.substring(4, e.target.name.length)
          if(filter[`to${subString2}`] !== '' && filter[`to${subString2}`] < e.target.value){
            this.setState({formErrorDetected: true})
            alert('From is bigger then to')
          }
          else{
            if(e.target.value == ''){
              filter[e.target.name] = e.target.value
              const filterArray = this.filterArrayToString(filter)
              this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
            }
            else if(isNaN(e.target.value)){
              alert("enter a number")
            }
            else{
              filter[e.target.name] = e.target.value;
              const filterArray = this.filterArrayToString(filter)
              this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
            }
          }
          break
      }
    }
    else if(formErrorDetected){
      this.setState({formErrorDetected: false})
    }
  }
  handleQueryStrings = (filter) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    filter = urlQueryHandler(params, minersViewQueryList, filter)
  }
  componentDidMount = async () => {
    const element = document.getElementById("minersLink")
    const sideBar = document.getElementById("side-menu-inner")
    let { filter } = this.state
    filter = this.handleQueryStrings(filter)
    if(sideBar){
      sideBar.scrollTop = 0;
    }
    if(element){
      element.classList.add("itemSelected")
    }
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    const headerRightContainer = document.getElementById("adc_header_rightContainer")
    if(headerRightContainer){
      headerRightContainer.classList.add("adc_header-rightContainer-additionListActive")
    }
    if(this.props.match.params.name !== this.props.minerListPage.client){
      const store = {
        client: this.props.match.params.client,
        pageSize: 25,
        pageNumber: 1,
        orderBy: 'Location',
        acending: true,
        type: 'minerList'
      }
      this.props.updatePageInfoAction(store);
    }
    window.sessionStorage.setItem('location', window.location.pathname)
    // try and perseve the history
    //const saveHistory = JSON.parse(JSON.stringify(window.history))
    const saveHistory = JSON.stringify(history)
    window.sessionStorage.setItem('history', saveHistory);
    window.addEventListener ? document.addEventListener('keydown', this.handleShiftDown) : document.attachEvent('keydown', this.handleShiftDown);
    window.addEventListener ? document.addEventListener('keyup', this.handleShiftUp) : document.attachEvent('keyup', this.handleShiftUp);
    this.props.getHousesAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.region_id)
    this.props.getSubnetsAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.region_id)
    this.props.getProfilesAction(this.props.userStore.result, this.props.match.params.name)
    this.props.clearPodsAction()
    this.initalSetup()
    const { userData } = this.props.userStore
    this.setState({ client: this.props.match.params.name, userData });
    const toHeatElement = document.getElementById("filter-to-heat")
    const toRejectionRate = document.getElementById("filter-to-rejection")
    const toSpeedElement = document.getElementById("filter-to-speed")
    const toAvgSpeedElement = document.getElementById("filter-to-avgSpeed")
    const toMissingAsicElement = document.getElementById("filter-to-missing-asic")
    const toPerformanceElement = document.getElementById("filter-to-performance")
    const fromHeatElement = document.getElementById("filter-from-heat")
    const fromRejectionRate = document.getElementById("filter-from-rejection")
    const fromSpeedElement = document.getElementById("filter-from-speed")
    const fromAvgSpeedElement = document.getElementById("filter-from-avgSpeed")
    const fromMissingAsicElement = document.getElementById("filter-from-missing-asic")
    const fromPerformanceElement = document.getElementById("filter-from-performance")
    const filterWorker = document.getElementById("filterWorker")
    const filterTuningStatus = document.getElementById("filterTuningStatus")
    const filterTuningPreset = document.getElementById("filterTuningPreset")
    const filterLocation = document.getElementById("filterLocation")
    const filterIp = document.getElementById("filterIp")
    const filterType = document.getElementById("filterType")
    const filterMac = document.getElementById("filterMac")
    const filterSerialNumber = document.getElementById("filterSerialNumber")
    const filterFW = document.getElementById("filterFW")
    const filterPoolOne = document.getElementById("filterPoolOne")
    const filterPoolTwo = document.getElementById("filterPoolTwo")
    const filterPoolThree = document.getElementById("filterPoolThree")
    if(filterMac){
      filterMac.addEventListener('blur', this.handleFilter)
      filterMac.addEventListener('keydown', this.handleFilter)
    }
    if(filterFW){
      filterFW.addEventListener('blur', this.handleFilter)
      filterFW.addEventListener('keydown', this.handleFilter)
    }
    if(toHeatElement){
      toHeatElement.addEventListener('blur', this.handleFilter)
      toHeatElement.addEventListener('keydown', this.handleFilter)
    }
    if(toRejectionRate){
      toRejectionRate.addEventListener('blur', this.handleFilter)
      toRejectionRate.addEventListener('keydown', this.handleFilter)
    }
    if(toSpeedElement){
      toSpeedElement.addEventListener('blur', this.handleFilter)
      toSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(toAvgSpeedElement){
      toAvgSpeedElement.addEventListener('blur', this.handleFilter)
      toAvgSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(toMissingAsicElement){
      toMissingAsicElement.addEventListener('blur', this.handleFilter)
      toMissingAsicElement.addEventListener('keydown', this.handleFilter)
    }
    if(toPerformanceElement){
      toPerformanceElement.addEventListener('blur', this.handleFilter)
      toPerformanceElement.addEventListener('keydown', this.handleFilter)
    }
    if(filterWorker){
      filterWorker.addEventListener('blur', this.handleFilter)
      filterWorker.addEventListener('keydown', this.handleFilter)
    }
    if(filterSerialNumber){
      filterSerialNumber.addEventListener('blur', this.handleFilter)
      filterSerialNumber.addEventListener('keydown', this.handleFilter)
    }
    if(filterTuningStatus){
      filterTuningStatus.addEventListener('blur', this.handleFilter)
      filterTuningStatus.addEventListener('keydown', this.handleFilter)
    }
    if(filterTuningPreset){
      filterTuningPreset.addEventListener('blur', this.handleFilter)
      filterTuningPreset.addEventListener('keydown', this.handleFilter)
    }
    if(filterLocation){
      filterLocation.addEventListener('blur', this.handleFilter)
      filterLocation.addEventListener('keydown', this.handleFilter)
    }
    if(filterIp){
      filterIp.addEventListener('blur', this.handleFilter)
      filterIp.addEventListener('keydown', this.handleFilter)
    }
    if(filterType){
      filterType.addEventListener('blur', this.handleFilter)
      filterType.addEventListener('keydown', this.handleFilter)
    }
    if(filterPoolOne){
      filterPoolOne.addEventListener('blur', this.handleFilter)
      filterPoolOne.addEventListener('keydown', this.handleFilter)
    }
    if(filterPoolTwo){
      filterPoolTwo.addEventListener('blur', this.handleFilter)
      filterPoolTwo.addEventListener('keydown', this.handleFilter)
    }
    if(filterPoolThree){
      filterPoolThree.addEventListener('blur', this.handleFilter)
      filterPoolThree.addEventListener('keydown', this.handleFilter)
    }
    if(fromHeatElement){
      fromHeatElement.addEventListener('blur', this.handleFilter)
      fromHeatElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromRejectionRate){
      fromRejectionRate.addEventListener('blur', this.handleFilter)
      fromRejectionRate.addEventListener('keydown', this.handleFilter)
    }
    if(fromSpeedElement){
      fromSpeedElement.addEventListener('blur', this.handleFilter)
      fromSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromAvgSpeedElement){
      fromAvgSpeedElement.addEventListener('blur', this.handleFilter)
      fromAvgSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromMissingAsicElement){
      fromMissingAsicElement.addEventListener('blur', this.handleFilter)
      fromMissingAsicElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromPerformanceElement){
      fromPerformanceElement.addEventListener('blur', this.handleFilter)
      fromPerformanceElement.addEventListener('keydown', this.handleFilter)
    }
    const toHeatElementExtra = document.getElementById("filter-to-heatExtra")
    const toSpeedElementExtra = document.getElementById("filter-to-speedExtra")
    const toAvgSpeedElementExtra = document.getElementById("filter-to-avgSpeedExtra")
    const toMissingAsicElementExtra = document.getElementById("filter-to-missing-asicExtra")
    const toPerformanceElementExtra = document.getElementById("filter-to-performanceExtra")
    const filterWorkerExtra = document.getElementById("filterWorkerExtra")
    const filterLocationExtra = document.getElementById("filterLocationExtra")
    const filterIpExtra = document.getElementById("filterIpExtra")
    const filterTypeExtra = document.getElementById("filterTypeExtra")
    const filterMacExtra = document.getElementById("filterMacExtra")
    const filterFWExtra = document.getElementById("filterFWExtra")
    const filterPoolOneExtra = document.getElementById("filterPoolOneExtra")
    const filterPoolTwoExtra = document.getElementById("filterPoolTwoExtra")
    const filterPoolThreeExtra = document.getElementById("filterPoolThreeExtra")
    if(filterMacExtra){
      filterMacExtra.addEventListener('blur', this.handleFilter)
      filterMacExtra.addEventListener('keydown', this.handleFilter)
    }
    if(filterFWExtra){
      filterFWExtra.addEventListener('blur', this.handleFilter)
      filterFWExtra.addEventListener('keydown', this.handleFilter)
    }
    if(toHeatElementExtra){
      toHeatElementExtra.addEventListener('blur', this.handleFilter)
      toHeatElementExtra.addEventListener('keydown', this.handleFilter)
    }
    if(toSpeedElementExtra){
      toSpeedElementExtra.addEventListener('blur', this.handleFilter)
      toSpeedElementExtra.addEventListener('keydown', this.handleFilter)
    }
    if(toAvgSpeedElementExtra){
      toAvgSpeedElementExtra.addEventListener('blur', this.handleFilter)
      toAvgSpeedElementExtra.addEventListener('keydown', this.handleFilter)
    }
    if(toMissingAsicElementExtra){
      toMissingAsicElementExtra.addEventListener('blur', this.handleFilter)
      toMissingAsicElementExtra.addEventListener('keydown', this.handleFilter)
    }
    if(toPerformanceElementExtra){
      toPerformanceElementExtra.addEventListener('blur', this.handleFilter)
      toPerformanceElementExtra.addEventListener('keydown', this.handleFilter)
    }
    if(filterWorkerExtra){
      filterWorkerExtra.addEventListener('blur', this.handleFilter)
      filterWorkerExtra.addEventListener('keydown', this.handleFilter)
    }
    if(filterLocationExtra){
      filterLocationExtra.addEventListener('blur', this.handleFilter)
      filterLocationExtra.addEventListener('keydown', this.handleFilter)
    }
    if(filterIpExtra){
      filterIpExtra.addEventListener('blur', this.handleFilter)
      filterIpExtra.addEventListener('keydown', this.handleFilter)
    }
    if(filterTypeExtra){
      filterTypeExtra.addEventListener('blur', this.handleFilter)
      filterTypeExtra.addEventListener('keydown', this.handleFilter)
    }
    if(filterPoolOneExtra){
      filterPoolOneExtra.addEventListener('blur', this.handleFilter)
      filterPoolOneExtra.addEventListener('keydown', this.handleFilter)
    }
    if(filterPoolTwoExtra){
      filterPoolTwoExtra.addEventListener('blur', this.handleFilter)
      filterPoolTwoExtra.addEventListener('keydown', this.handleFilter)
    }
    if(filterPoolThreeExtra){
      filterPoolThreeExtra.addEventListener('blur', this.handleFilter)
      filterPoolThreeExtra.addEventListener('keydown', this.handleFilter)
    }
  }
  initalSetup = async() => {
    const { filter } = this.state
    const filterArray = this.filterArrayToString(filter)
    this.props.getTypesAction(this.props.userStore.result, this.props.match.params.name)
    this.props.getTuningProfilesAction(this.props.userStore.result, this.props.match.params.name)
    this.props.getFavMinersAction(this.props.userStore.result);
    this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
  }
  refreshData = () => {
    const { filter } = this.state
    const filterArray = this.filterArrayToString(filter)
    this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
  }
  componentDidUpdate = () => {
    if(this.props.clientStore.refreshData && this.state.intervalID === ''){
      const intervalID = setInterval(this.initalSetup, 60000);
      this.setState({intervalID})
    }
    if(!this.props.clientStore.refreshData && this.state.intervalID !== ''){
      const { intervalID } = this.state;
      clearInterval(intervalID)
      this.setState({intervalID: ''})
    }
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  componentWillUnmount() {
    const element = document.getElementById("minersLink")
    if(element){
      element.classList.remove("itemSelected")
    }
    const headerRightContainer = document.getElementById("adc_header_rightContainer")
    if(headerRightContainer){
      headerRightContainer.classList.remove("adc_header-rightContainer-additionListActive")
    }
    window.sessionStorage.removeItem('location')
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("keydown", this.handleKeyDown);
    const toHeatElement = document.getElementById("filter-to-heat")
    const toRejectionRate = document.getElementById("filter-to-rejection")
    const toSpeedElement = document.getElementById("filter-to-speed")
    const toAvgSpeedElement = document.getElementById("filter-to-avgSpeed")
    const toMissingAsicElement = document.getElementById("filter-to-missing-asic")
    const toPerformanceElement = document.getElementById("filter-to-performance")
    const filterWorker = document.getElementById("filterWorker")
    const filterLocation = document.getElementById("filterLocation")
    const filterIp = document.getElementById("filterIp")
    const filterType = document.getElementById("filterType")
    const filterMac = document.getElementById("filterMac")
    const filterSerialNumber = document.getElementById("filterSerialNumber")
    const filterFW = document.getElementById("filterFW")
    const filterTuningStatus = document.getElementById("filterTuningStatus")
    const filterTuningPreset = document.getElementById("filterTuningPreset")
    const filterPoolOne = document.getElementById("filterPoolOne")
    const filterPoolTwo = document.getElementById("filterPoolTwo")
    const filterPoolThree = document.getElementById("filterPoolThree")
    const fromRejectionRate = document.getElementById("filter-from-rejection")

    const { intervalID } = this.state;
    this.props.clearMinersAction()
    if(filterMac){
      filterMac.removeEventListener('blur', this.handleFilter)
      filterMac.removeEventListener('keydown', this.handleFilter)
    }
    if(filterFW){
      filterFW.removeEventListener('blur', this.handleFilter)
      filterFW.removeEventListener('keydown', this.handleFilter)
    }
    if(toHeatElement){
      toHeatElement.removeEventListener('blur', this.handleFilter)
      toHeatElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toSpeedElement){
      toSpeedElement.removeEventListener('blur', this.handleFilter)
      toSpeedElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toAvgSpeedElement){
      toAvgSpeedElement.removeEventListener('blur', this.handleFilter)
      toAvgSpeedElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toMissingAsicElement){
      toMissingAsicElement.removeEventListener('blur', this.handleFilter)
      toMissingAsicElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toPerformanceElement){
      toPerformanceElement.removeEventListener('blur', this.handleFilter)
      toPerformanceElement.removeEventListener('keydown', this.handleFilter)
    }
    if(filterWorker){
      filterWorker.removeEventListener('blur', this.handleFilter)
      filterWorker.removeEventListener('keydown', this.handleFilter)
    }
    if(filterSerialNumber){
      filterSerialNumber.removeEventListener('blur', this.handleFilter)
      filterSerialNumber.removeEventListener('keydown', this.handleFilter)
    }
    if(filterLocation){
      filterLocation.removeEventListener('blur', this.handleFilter)
      filterLocation.removeEventListener('keydown', this.handleFilter)
    }
    if(filterIp){
      filterIp.removeEventListener('blur', this.handleFilter)
      filterIp.removeEventListener('keydown', this.handleFilter)
    }
    if(filterTuningStatus){
      filterTuningStatus.removeEventListener('blur', this.handleFilter)
      filterTuningStatus.removeEventListener('keydown', this.handleFilter)
    }
    if(filterTuningPreset){
      filterTuningPreset.removeEventListener('blur', this.handleFilter)
      filterTuningPreset.removeEventListener('keydown', this.handleFilter)
    }
    if(filterType){
      filterType.removeEventListener('blur', this.handleFilter)
      filterType.removeEventListener('keydown', this.handleFilter)
    }
    if(toRejectionRate){
      toRejectionRate.removeEventListener('blur', this.handleFilter)
      toRejectionRate.removeEventListener('keydown', this.handleFilter)
    }
    if(fromRejectionRate){
      fromRejectionRate.removeEventListener('blur', this.handleFilter)
      fromRejectionRate.removeEventListener('keydown', this.handleFilter)
    }
    if(filterPoolOne){
      filterPoolOne.removeEventListener('blur', this.handleFilter)
      filterPoolOne.removeEventListener('keydown', this.handleFilter)
    }
    if(filterPoolTwo){
      filterPoolTwo.removeEventListener('blur', this.handleFilter)
      filterPoolTwo.removeEventListener('keydown', this.handleFilter)
    }
    if(filterPoolThree){
      filterPoolThree.removeEventListener('blur', this.handleFilter)
      filterPoolThree.removeEventListener('keydown', this.handleFilter)
    }
    const toHeatElementExtra = document.getElementById("filter-to-heatExtra")
    const toSpeedElementExtra = document.getElementById("filter-to-speedExtra")
    const toAvgSpeedElementExtra = document.getElementById("filter-to-avgSpeedExtra")
    const toMissingAsicElementExtra = document.getElementById("filter-to-missing-asicExtra")
    const toPerformanceElementExtra = document.getElementById("filter-to-performanceExtra")
    const filterWorkerExtra = document.getElementById("filterWorkerExtra")
    const filterLocationExtra = document.getElementById("filterLocationExtra")
    const filterIpExtra = document.getElementById("filterIpExtra")
    const filterTypeExtra = document.getElementById("filterTypeExtra")
    const filterMacExtra = document.getElementById("filterMacExtra")
    const filterFWExtra = document.getElementById("filterFWExtra")
    if(filterMacExtra){
      filterMacExtra.removeEventListener('blur', this.handleFilter)
      filterMacExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(filterFWExtra){
      filterFWExtra.removeEventListener('blur', this.handleFilter)
      filterFWExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(toHeatElementExtra){
      toHeatElementExtra.removeEventListener('blur', this.handleFilter)
      toHeatElementExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(toSpeedElementExtra){
      toSpeedElementExtra.removeEventListener('blur', this.handleFilter)
      toSpeedElementExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(toAvgSpeedElementExtra){
      toAvgSpeedElementExtra.removeEventListener('blur', this.handleFilter)
      toAvgSpeedElementExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(toMissingAsicElementExtra){
      toMissingAsicElementExtra.removeEventListener('blur', this.handleFilter)
      toMissingAsicElementExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(toPerformanceElementExtra){
      toPerformanceElementExtra.removeEventListener('blur', this.handleFilter)
      toPerformanceElementExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(filterWorkerExtra){
      filterWorkerExtra.removeEventListener('blur', this.handleFilter)
      filterWorkerExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(filterLocationExtra){
      filterLocationExtra.removeEventListener('blur', this.handleFilter)
      filterLocationExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(filterIpExtra){
      filterIpExtra.removeEventListener('blur', this.handleFilter)
      filterIpExtra.removeEventListener('keydown', this.handleFilter)
    }
    if(filterTypeExtra){
      filterTypeExtra.removeEventListener('blur', this.handleFilter)
      filterTypeExtra.removeEventListener('keydown', this.handleFilter)
    }
    clearInterval(intervalID)
  }
  //Used to fetch more miners to render in the next page of the table.
  onPageClick = async (pageNumber) => {
    // needs to be != because minerStore.currentPage is string
    const { filter } = this.state;
    pageNumber = pageNumber + 1
    if (pageNumber != this.props.minersStore.current_page) {
      if (pageNumber !== 0 && pageNumber <= this.props.minersStore.max_page) {
        this.props.resetMinerDisplayAction();
        //rather then awaiting here could possibly do this in the getAllMinersAction
        await this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, this.filterArrayToString(filter), this.props.match.params.region_id);
      }
    }
    const store = {
      client: this.props.match.params.name,
      pageSize: this.props.minerListPage.pageSize,
      pageNumber: pageNumber,
      orderBy: this.props.minerListPage.orderBy,
      acending: this.props.minerListPage.acending,
      type: 'minerList'
    }
    document.body.scrollTop = 0;
    this.props.updatePageInfoAction(store);
  }
  //This is the function that selects miners
  onItemSelect = (mac, item) => {
    let { selectedMiners, isShiftDown, lastSelectionItem } = this.state;
    if (isShiftDown) {
      let max = Math.max(item, lastSelectionItem);
      let min = Math.min(item, lastSelectionItem) + 1;
      let newArray = this.props.minersStore.miners.slice(min, max)
      const macArray = newArray.map((item) => item.MAC_Address)
      macArray.map((item) => {
        const index = selectedMiners.indexOf(item);
        if (index === -1) {
          selectedMiners.push(item)
        }
        else {
          selectedMiners.splice(index, 1);
        }
      })
    }
    const index = selectedMiners.indexOf(mac);
    if (index === -1) {
      selectedMiners.push(mac);
    }
    else {
      selectedMiners.splice(index, 1);
    }
    this.setState({ selectedMiners, lastSelectionItem: item });
  }
  //This function is used to select all miners from the table.
  onSelectAll = (isAllSelected) => {
    let selectedMiners = [];
    if (isAllSelected) {
      const { miners } = this.state;
      selectedMiners = this.props.minersStore.miners.map((item) => item.MAC_Address);
    }
    this.setState({ isAllSelected, selectedMiners })
  }
  //Function that's called when either Restart of Configure button is pressed.
  onClickCommand = (type, socketId) => {
    const { selectedMiners } = this.state;
    if (selectedMiners.length === 0) {
      this.props.setErrorMessageAction("You need to pick atleast one miner");
      return;
    }
    if(!window.confirm(`Are you sure you want to send ${type} to ${selectedMiners.length} miners`)){
      return
    }
    if(type == "delete"){
      this.props.markMinersDeletedAction(this.props.userStore.result, {macs: selectedMiners, delete_type: 1}, this.props.match.params.name)
    }
    else{
      this.props.sendCommandAction(this.props.userStore.result, this.props.match.params.name, { Macs: selectedMiners, CallerID: socketId }, type);
      this.props.updateResponsePropertyAction({property: 'open', value: true})
      this.props.updateResponsePropertyAction({property: 'total', value: this.props.responseStore.total + selectedMiners.length})
      this.props.updateResponsePropertyAction({property: 'pending', value: selectedMiners.length})
    }
    this.setState({ selectedMiners: [] })
  }
  onModeChange = (modeValue) => {
    const { selectedMiners, socketId } = this.state;
    if (selectedMiners.length === 0) {
      this.props.setErrorMessageAction("You need to pick atleast one miner");
      this.setState({
        pickModeModalOpen: false
      })
      return;
    }
    if(!window.confirm(`Are you sure you want change mode on ${selectedMiners.length} miners`)){
      this.setState({
        pickModeModalOpen: false
      })
      return
    }
    this.props.sendCommandAction(this.props.userStore.result, this.props.match.params.name, { Macs: selectedMiners, Data: modeValue, CallerID: socketId }, 'mode');
    this.props.updateResponsePropertyAction({property: 'open', value: true})
    this.props.updateResponsePropertyAction({property: 'total', value: this.props.responseStore.total + selectedMiners.length})
    this.props.updateResponsePropertyAction({property: 'pending', value: selectedMiners.length})
    this.setState({
      pickModeModalOpen: false,
      selectedMiners: []
    })
  }
  //Sorts the table according to what column was selected.
  onSort = (type) => {
    const { filter } = this.state;
    let acending = this.props.minerListPage.acending ;
    if (this.props.minerListPage.orderBy === type) {
      acending = !acending;
    }
    else {
      this.props.minerListPage.acending = true;
    }
    this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, type, acending, this.filterArrayToString(filter), this.props.match.params.region_id);
    const store = {
      client: this.props.match.params.name,
      pageSize: this.props.minerListPage.pageSize,
      pageNumber: this.props.minerListPage.pageNumber,
      orderBy: type,
      acending: acending,
      type: 'minerList'
    }
    this.props.updatePageInfoAction(store);
  }
  //Function used to route us to the minersDetails site when it was clicked from the table or the heatmap.
  onItemClick = async (link) => {
    this.props.history.push(link)
  }
  //Adds miners to fav and delets them from fav
  alterFav = (MAC_Address, ID) => {
    const arrayid = [];
    if(ID === null){
      arrayid[0] = {MAC_Address};
      this.props.postFavMinersAction(arrayid, this.props.userStore.result);
    }
    else {
      arrayid[0] = {ID};
      this.props.deleteFavMinersAction(this.props.userStore.result, arrayid);
    }
  }
  //Adds many miners to the fav table.
  addManyToFav = () => {
    const newObj = this.state.selectedMiners.map((item) => {
      return{
        MAC_Address: item
      }
    })
    this.props.postFavMinersAction(newObj, this.props.userStore.result);
  }
  //Deletes many miners from the fav table.
  deleteManyFromFav = () => {
    let newObj = this.props.favMinersStore.data.filter((item) => this.state.selectedMiners.indexOf(item.MAC_Address) != -1)
    newObj = newObj.map((item) => {return{ID:item.ID}})
    this.props.deleteFavMinersAction(this.props.userStore.result, newObj);
  }
  //Changes the size of each page in the table.
  changePageSize = (e) => {
    //this.setState({ [e.target.name]: e.target.value })
    const value = parseInt(e.target.value)
    const { filter } = this.state;
    let { pageNumber } = this.props.minerListPage
    let items = parseInt(this.props.minersStore.max_page)*parseInt(this.props.minersStore.page_size);
    if(value > items){
      pageNumber = 1;
    }
    if((this.props.minerListPage.pageNumber*value) > items){
      pageNumber = 1;
    }
    this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, value, pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, this.filterArrayToString(filter), this.props.match.params.region_id);
    const store = {
      client: this.props.match.params.name,
      pageSize: value? value:0,
      pageNumber: pageNumber,
      orderBy: this.props.minerListPage.orderBy,
      acending: this.props.minerListPage.acending,
      type: 'minerList'
    }
    this.props.updatePageInfoAction(store);
  }
  changeType = async (type) => {
    let { selectedMiners, filter } = this.state;
    selectedMiners = selectedMiners.map((item, i) => {
      return {
        MAC_Address: item,
        Type: type
      }
    })
    await this.props.setMinerTypeAction(selectedMiners, this.props.userStore.result, this.props.match.params.name)
    this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, this.filterArrayToString(filter), this.props.match.params.region_id);
    this.setState({selectedMiners: [], typeModalOpen: false})
  }
  filterArrayToString = (filter) => {
    let filterArray = []
    let multiFilterArray = []
    let multiFilters = ['filterRack', 'filterType', 'filterScanner', 'filterHouse', 'filterPOD', 'filterStatus', 'filterBoards']
    for(var key in filter) {
        if (filter.hasOwnProperty(key)) {
          if(multiFilters.indexOf(key) !== -1 && filter[key].length > 0){
            const to_ids = filter[key].map((item) => item.value)
            filterArray.push({[key]: to_ids})
          }
          else if(multiFilters.indexOf(key) === -1 && filter[key] !== ''){
            filterArray.push({[key]: filter[key]})
          }
        }
    }
    return filterArray.map((item) => JSON.stringify(item)).join('&filter[]=')
  }
  onFilterInputChange = (e) => {
    let { filter } = this.state;
    let breakFrom = 0
    if(e.target.value == ' '){
      return
    }
    filter[e.target.name] = e.target.value;
    if(e.target.name === 'filterHouse'){
      filter['filterPOD'] = ''
      this.props.clearPodsAction()
      if(e.target.value !== ''){
        this.props.getPodsLimitedAction(this.props.userStore.result, e.target.value, this.props.match.params.name)
      }
    }
    if(e.target.name === 'filterType' || e.target.name === 'filterRack' || e.target.name === 'filterStatus' || e.target.name === 'filterHouse' || e.target.name === 'filterPOD' || e.target.name === 'filterScanner'){
      const filterArray = this.filterArrayToString(filter)
      this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name,this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
    }
    this.setState({filter})
  }
  onAlterStatusClick = (status) => {
    const { selectedMiners } = this.state;
    if(selectedMiners.length === 0){
      this.setState({statusModalOpen: false})
      alert("No miners selected")
      return
    }
    this.props.adjustMinersStatusAction(this.props.userStore.result, {macs:selectedMiners}, this.props.match.params.name, status)
    this.setState({statusModalOpen: false, selectedMiners: []})
  }
  clearFilter = () => {
    this.setState({filter: {
      filterMac: '',
      filterWorker: '',
      filterLocation: '',
      filterIp: '',
      filterRack: [],
      fromHeat: '',
      toHeat:'',
      fromCurrentSpeed:'',
      toCurrentSpeed: '',
      fromAvgSpeed: '',
      toAvgSpeed: '',
      fromMissingAsic: '',
      toMissingAsic: '',
      filterType: [],
      fromPerformance: '',
      toPerformance: '',
      filterScanner: [],
      filterHouse: [],
      filterPOD: [],
      filterStatus: []
    }})
    this.props.clearPodsAction()
    this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, '', this.props.match.params.region_id);
  }
  clearLocations = () => {
    const { selectedMiners } = this.state;
    if(selectedMiners.length == 0){
      alert("No miners selected")
      return
    }
    this.props.clearLocationsAction(this.props.userStore.result, this.props.match.params.name, {macs: selectedMiners})
  }
  exportList = (e) => {
    let { miners } = this.props.minersStore;
    let newMinerList = []
    miners.map((item) => {
      let tmpObj = {}
      Object.keys(this.props.listColumns).map((key, index) =>{
        if(this.props.listColumns[key]){
          tmpObj[key] = item[key]
        }
      })
      newMinerList.push(tmpObj)
    })
    switch (e.target.value) {
      case 'xlsx':
        var wb1 = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(newMinerList);
        XLSX.utils.book_append_sheet(wb1, ws1, 'miner-list')
        const now = new Date()
        const date =  now.getUTCFullYear() +"-"+ (now.getUTCMonth()+1) +"-"+ now.getUTCDate() + "_" + now.getUTCHours() + "-" + now.getUTCMinutes() + "-" + now.getUTCSeconds()
        XLSX.writeFile(wb1, `listExport-${date}-.xlsx`)
        break;
      default:
    }
  }
  onMultiInputChange = (e, property) => {
    let { filter } = this.state;
    let propertyKey   = property
    let propertyValue = e
    if(e.hasOwnProperty("target")){
      if(property === 'showDuplicateWorkerName' || property === 'showDeleted' || ['filterPoolOneEmpty', 'filterPoolTwoEmpty', 'filterPoolThreeEmpty'].indexOf(property) !== -1){
        filter[property] = !filter[property]
        switch (property) {
          case 'filterPoolOneEmpty':
            filter['filterPoolOne'] = ''
            break;
          case 'filterPoolTwoEmpty':
            filter['filterPoolTwo'] = ''
            break;
          case 'filterPoolThreeEmpty':
            filter['filterPoolThree'] = ''
            break;
        }
        if(filter[property]){
          propertyValue = 'yes'
        }
        else{
          propertyValue = 'no'
        }
        const filterArray = this.filterArrayToString(filter)
        console.log(filterArray)
        this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name,this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
      }
      else{
        propertyKey           = e.target.name
        propertyValue         = e.target.value
        filter[e.target.name] = propertyValue
      }
    }
    else{
      //let ids = e.map((item) => item.value)
      filter[property] = propertyValue
    }
    if(property === 'filterHouse'){
      filter['filterPOD'] = []
      this.props.clearPodsAction()
      if(e.length > 0){
        this.props.getPodsLimitedAction(this.props.userStore.result, filter[property].map((item) => item.value), this.props.match.params.name)
      }
    }
    if(property === 'filterType' || property === 'filterRack' || property === 'filterStatus' || property === 'filterHouse' || property === 'filterPOD' || property === 'filterScanner' || property === 'filterBoards'){
      const filterArray = this.filterArrayToString(filter)
      this.props.getAllMinersAction(this.props.userStore.result, this.props.match.params.name,this.props.minerListPage.pageSize, this.props.minerListPage.pageNumber, this.props.minerListPage.orderBy, this.props.minerListPage.acending, filterArray, this.props.match.params.region_id);
    }
    if(property === 'filterType'){
      if(filter.filterType.length === 1){
        this.props.getMinerTypePowerAction(this.props.userStore.result, filter.filterType[0].label)
      }
      else{
        this.props.clearMinerTypePowerAction()
      }
    }
    const newString = propertyKey.includes('filter')? propertyKey.split('filter')[1]: propertyKey
    updateQueryString({key: newString, value: propertyValue}, minersViewQueryList)
    this.setState(filter)
  }
  onButtonsClick = (type, socket) => {
    const { selectedMiners } = this.state
    if(socket && socket.disconnected){
      console.log("Reconnecting")
      socket.connect('wss://hashmon.atnorth.com', {
        query: { token: this.props.userStore.result },
        path:'/sio',
        reconnection: true,
        reconnectionDelay: 5000,
      })
      alert("Socket disconnect exectuing a reconnect")
    }
    switch (type) {
      case 'postCustomFlags':
          this.setState({customFlagModalOpen: true})
        break;
      case 'clearLocations':
          this.clearLocations()
        break;
      case 'alterStatus':
          this.setState({statusModalOpen: true})
        break
      case 'setMinerType':
          this.setState({typeModalOpen: true})
        break
      case 'removeFav':
          this.deleteManyFromFav()
        break
      case 'addFav':
          this.addManyToFav()
        break
      case 'mode':
        if(this.props.powerModes.length === 0){
          alert("Change power mode is not available for miner type selected")
          return
        }
        this.setState({
          pickModeModalOpen: true,
          socketId: socket.id
        })
        break
      case 'tune':
        this.setState({
          tuningModalOpen: true,
          action: type,
          socketId: socket.id
        })
        break
      case 'addConfigProfile':
      case 'config':
        this.setState({
          profileModalOpen: true,
          action: type,
          socketId: socket.id
        })
        break
      default:
        this.onClickCommand(type, socket.id)
    }
  }
  onProfileSelect = (e) => {
    this.setState({
      profileSelected: e.value
    })
  }
  onTuningProfileSelect = (e) => {
    this.setState({
      tuningProfileSelected: e.value
    })
  }
  createCustomFlag = () => {
    const { selectedMiners, customFlagComment } = this.state
    const body = {
      comment: customFlagComment,
      macs: selectedMiners
    }
    this.props.postCustomMinerFlagsAction(body, this.props.userStore.result, this.props.match.params.name)
    this.setState({
      selectedMiners: [],
      customFlagComment: '',
      customFlagModalOpen: false
    })
  }
  onTuningProfileSubmit = () => {
    const { selectedMiners, tuningProfileSelected, socketId, action } = this.state
    if (selectedMiners.length === 0) {
      this.props.setErrorMessageAction("You need to pick atleast one miner");
      this.setState({
        tuningModalOpen: false,
        tuningProfileSelected: -1
      })
      return;
    }
    if(!window.confirm(`Are you sure you want to tune ${selectedMiners.length} miners ?`)){
      this.setState({
        tuningModalOpen: false,
        tuningProfileSelected: -1
      })
      return
    }
    let id = this.props.tuningStore.profiles[tuningProfileSelected]
    if(id){
      id = id.ID
    }
    this.props.sendCommandAction(this.props.userStore.result, this.props.match.params.name, { Macs: selectedMiners, Data: id, CallerID: socketId }, 'tuning');
    this.props.updateResponsePropertyAction({property: 'open', value: true})
    this.props.updateResponsePropertyAction({property: 'total', value: this.props.responseStore.total + selectedMiners.length})
    this.props.updateResponsePropertyAction({property: 'pending', value: selectedMiners.length})
    this.setState({
      tuningModalOpen: false,
      tuningProfileSelected: -1
    })
  }
  onProfileSubmit = () => {
    const { selectedMiners, profileSelected, socketId, action } = this.state
    if (selectedMiners.length === 0) {
      this.props.setErrorMessageAction("You need to pick atleast one miner");
      this.setState({
        profileModalOpen: false,
        profileSelected: -1
      })
      return;
    }
    if(action == 'config'){
      if(!window.confirm(`Are you sure you want to configure ${selectedMiners.length} miners ?`)){
        this.setState({
          profileModalOpen: false,
          profileSelected: -1
        })
        return
      }
      let id = this.props.poolStore.profiles[profileSelected]
      if(id){
        id = id.ID
      }
      this.props.sendCommandAction(this.props.userStore.result, this.props.match.params.name, { Macs: selectedMiners, Data: id, CallerID: socketId }, 'config');
      this.props.updateResponsePropertyAction({property: 'open', value: true})
      this.props.updateResponsePropertyAction({property: 'total', value: this.props.responseStore.total + selectedMiners.length})
      this.props.updateResponsePropertyAction({property: 'pending', value: selectedMiners.length})
    }
    if(action == 'addConfigProfile'){
      if(!window.confirm(`Are you sure you want to change config profile for ${selectedMiners.length} miners ?`)){
        this.setState({
          profileModalOpen: false,
          profileSelected: -1
        })
        return
      }
      let id = this.props.poolStore.profiles[profileSelected]
      if(id){
        id = id.ID
      }
      const filteredMiners = this.props.minersStore.miners.filter((item) => selectedMiners.indexOf(item.MAC_Address) != -1 && item.Config_Profile_Name !== 'none')
      let overrideData = false
      if(filteredMiners.length > 0){
        const testString = filteredMiners.map((item) => `${item.IP_Address} - ${item.Location}`).join('\n')
        overrideData = window.confirm(`DO YOU WANT TO OVERRIDE THE CONFIG PROFILE OFF ${filteredMiners.length} miners ? \nMiner List: \n\n${testString}`)
      }
      const body = {overrideData, filteredList: filteredMiners.map((item) => item.MAC_Address), list: selectedMiners.map((item) => ({MAC_Address: item, Profile_ID: id}))}
      this.props.postProfileMinerConnectionsAction(body, this.props.userStore.result, this.props.match.params.name)
      setTimeout(this.refreshData, 2000)
    }
    this.setState({
      selectedMiners: [],
      profileSelected: -1,
      profileModalOpen: false,
      socketId: ''
    })
  }
  render() {
    const { userData } = this.state
    const userLevel = userData.level? userData.level: 0
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    const modalStyles = {
       modal: {
         width: '400px',
         background: '#141619'
       }
     };
     // filterPoolOne
     // filterPoolTwo
     // filterPoolTwo
    const fields = [
      {Name: "Scanners", PropertyName: 'filterScanner', Value: this.state.filter.filterScanner, Type: 'multi-select',  Options: this.props.subnetStore.subnets.map((item) => ({ label: item.Name, value: item.Name, property: 'filterScanner' }))},
      {Name: "House", PropertyName: 'filterHouse', Value: this.state.filter.filterHouse, Type: 'multi-select',  Options: this.props.houseStore.houses.map((item) => ({ label: item.Name, value: item.Name, property: 'filterHouse' }))},
      {Name: "Pod", PropertyName: 'filterPOD', Value: this.state.filter.filterPOD, Type: 'multi-select',  Options: this.props.podStore.pods.map((item) => ({ label: item.Name, value: item.Name, property: 'filterPOD' }))},
      {Name: "Rack", PropertyName: 'filterRack', Value: this.state.filter.filterRack, Type: 'multi-select',
      Options: [{label: 'None', value: 'None'}].concat(Array.apply(null, {length: 34}).map((item, i) => ({label: i+1, value: i+1, property: 'filterRack'})))},
      {Name: "Mac Address", PropertyName: 'filterMac', Value: this.state.filter.filterMac, Type: 'text-column',  Options: [], Id: 'filterMac'},
      {Name: "Pool One", PropertyName: 'filterPoolOne', Value: this.state.filter.filterPoolOne, Type: 'text-column-empty-check',  Options: {empty: {label: 'filterPoolOneEmpty', value: this.state.filterPoolOneEmpty}}, Id: 'filterPoolOne'},
      {Name: "Pool Two", PropertyName: 'filterPoolTwo', Value: this.state.filter.filterPoolTwo, Type: 'text-column-empty-check',  Options: {empty: {label: 'filterPoolTwoEmpty', value: this.state.filterPoolTwoEmpty}}, Id: 'filterPoolTwo'},
      {Name: "Pool Three", PropertyName: 'filterPoolThree', Value: this.state.filter.filterPoolThree, Type: 'text-column-empty-check',  Options: {empty: {label: 'filterPoolThreeEmpty', value: this.state.filterPoolThreeEmpty}}, Id: 'filterPoolThree'},
      {Name: "Tuning Preset", PropertyName: 'filterTuningPreset', Value: this.state.filter.filterTuningPreset, Id: 'filterTuningPreset', Type: 'text-column',  Options: []},
      {Name: "Tuning Status", PropertyName: 'filterTuningStatus', Value: this.state.filter.filterTuningStatus, Id: 'filterTuningStatus', Type: 'text-column',  Options: []},
      {Name: "Serial Number", PropertyName: 'filterSerialNumber', Value: this.state.filter.filterSerialNumber, Id: 'filterSerialNumber', Type: 'text-column',  Options: []},
      {Name: 'Status', PropertyName: 'filterStatus', Value: this.state.filter.filterStatus, Type: 'multi-select', Options: [
        {label:'Online', value: 'Online', property: 'filterStatus'},
        {label:'Offline', value: 'Offline', property: 'filterStatus'},
        {label:'Idle', value: 'Idle', property: 'filterStatus'},
          {label:'Sleep', value: 'Sleep', property: 'filterStatus'},
        {label:'API Down', value: 'API_DOWN', property: 'filterStatus'},
        {label:'Missing Fan', value: 'Missing_Fan', property: 'filterStatus'},
        {label:'Missing PSU', value: 'Missing_PSU', property: 'filterStatus'},
        {label:'Missing Ctrboard', value: 'Missing_CTRL_Board', property: 'filterStatus'},
        {label:'Unknown', value: 'Unknown', property: 'filterStatus'},
        {label:'Recovery', value: 'Recovery', property: 'filterStatus'},
        {label:'Restarting', value: 'Restarting', property: 'filterStatus'},
        {label:'Config_Change', value: 'Config_Change', property: 'filterStatus'},
        {label:'Mode_Change', value: 'Mode_Change', property: 'filterStatus'}]},
      {Name: 'Missing Hashboards', PropertyName: 'filterBoards', Value: this.state.filter.filterBoards, Type: 'multi-select',  Options: [1,2,3].map((item) => ({ label: item, value: item, property: 'filterBoards' }))},
      {Name: "Worker", PropertyName: 'filterWorker', Value: this.state.filter.filterWorker, Id: 'filterWorker', Type: 'text-column',  Options: []},
      {Name: "IP Address", PropertyName: 'filterIp', Value: this.state.filter.filterIp, Id: 'filterIp', Type: 'text-column',  Options: []},
      {Name: "Miner Type", PropertyName: 'filterType', Value: this.state.filter.filterType, Type: 'multi-select',  Options: [{label: 'None', value: 'NULL'}].concat(this.props.typeStore.map((item) => ({ label: item.Name, value: item.ID, property: 'filterType' })))},
      {Name: "Firmware", PropertyName: 'filterFW', Value: this.state.filter.filterFW, Type: 'text-column',  Options: [], Id: 'filterFW'},
      {Name: "Rejection Rate", PropertyName: 'fromRejectionRate', Value: this.state.filter.fromRejectionRate, PropertyTwoName: "toRejectionRate", PropertyTwoValue: this.state.filter.toRejectionRate, Id: 'filter-from-rejection', IdTwo: 'filter-to-rejection', Type: 'range',  Options:[]},
      {Name: "Heat", PropertyName: 'fromHeat', Value: this.state.filter.fromHeat, PropertyTwoName: "toHeat", PropertyTwoValue: this.state.filter.toHeat, Id: 'filter-from-heat', IdTwo: 'filter-to-heat', Type: 'range',  Options:[]},
      {Name: "Current Speed", PropertyName: 'fromCurrentSpeed', Value: this.state.filter.fromCurrentSpeed, PropertyTwoName: "toCurrentSpeed", PropertyTwoValue: this.state.filter.toCurrentSpeed, Id: 'filter-from-speed', IdTwo: 'filter-to-speed', Type: 'range',  Options:[]},
      {Name: "Average Speed", PropertyName: 'fromAvgSpeed', Value: this.state.filter.fromAvgSpeed, PropertyTwoName: "toAvgSpeed", PropertyTwoValue: this.state.filter.toAvgSpeed, Id: 'filter-from-avgSpeed', IdTwo: 'filter-to-avgSpeed', Type: 'range',  Options:[]},
      {Name: "Missing Asic", PropertyName: 'fromMissingAsic', Value: this.state.filter.fromMissingAsic, PropertyTwoName: "toMissingAsic", PropertyTwoValue: this.state.filter.toMissingAsic, Id: 'filter-from-missing-asic', IdTwo: 'filter-to-missing-asic', Type: 'range',  Options:[]},
      {Name: "Performance", PropertyName: 'fromPerformance', Value: this.state.filter.fromPerformance, PropertyTwoName: "toPerformance", PropertyTwoValue: this.state.filter.toPerformance, Id: 'filter-from-performance', IdTwo: 'filter-to-performance', Type: 'range',  Options:[]},
      {Name: "Show Deleted", PropertyName: 'showDeleted', Value: this.state.filter.showDeleted, Type: 'toggle-switch',  Options: []},
      {Name: "Show Duplicate Worker Name", PropertyName: 'showDuplicateWorkerName', Value: this.state.filter.showDuplicateWorkerName, Type: 'toggle-switch',  Options: []}]

    const extraFilterFields = [
        {Name: "Scanners", PropertyName: 'filterScanner', Value: this.state.filter.filterScanner, Type: 'multi-select',  Options: this.props.subnetStore.subnets.map((item) => ({ label: item.Name, value: item.Name, property: 'filterScanner' }))},
        {Name: "House", PropertyName: 'filterHouse', Value: this.state.filter.filterHouse, Type: 'multi-select',  Options: this.props.houseStore.houses.map((item) => ({ label: item.Name, value: item.Name, property: 'filterHouse' }))},
        {Name: "Pod", PropertyName: 'filterPOD', Value: this.state.filter.filterPOD, Type: 'multi-select',  Options: this.props.podStore.pods.map((item) => ({ label: item.Name, value: item.Name, property: 'filterPOD' }))},
        {Name: "Rack", PropertyName: 'filterRack', Value: this.state.filter.filterRack, Type: 'multi-select',
        Options: [{label: 'None', value: 'None'}].concat(Array.apply(null, {length: 34}).map((item, i) => ({label: i+1, value: i+1, property: 'filterRack'})))},
        {Name: "Mac Address", PropertyName: 'filterMac', Value: this.state.filter.filterMac, Type: 'text-column',  Options: [], Id: 'filterMacExtra'},
        {Name: 'Status', PropertyName: 'filterStatus', Value: this.state.filter.filterStatus, Type: 'multi-select', Options: [
          {label:'Online', value: 'Online', property: 'filterStatus'},
          {label:'Offline', value: 'Offline', property: 'filterStatus'},
          {label:'Idle', value: 'Idle', property: 'filterStatus'},
          {label:'Sleep', value: 'Sleep', property: 'filterStatus'},
          {label:'API Down', value: 'API_DOWN', property: 'filterStatus'},
          {label:'Missing Fan', value: 'Missing_Fan', property: 'filterStatus'},
          {label:'Missing PSU', value: 'Missing_PSU', property: 'filterStatus'},
          {label:'Missing Ctrboard', value: 'Missing_CTRL_Board', property: 'filterStatus'},
          {label:'Unknown', value: 'Unknown', property: 'filterStatus'},
          {label:'Recovery', value: 'Recovery', property: 'filterStatus'},
          {label:'Restarting', value: 'Restarting', property: 'filterStatus'},
          {label:'Config_Change', value: 'Config_Change', property: 'filterStatus'},
          {label:'Mode_Change', value: 'Mode_Change', property: 'filterStatus'}
          ]},
        {Name: 'Missing Hashboards', PropertyName: 'filterBoards', Value: this.state.filter.filterBoards, Type: 'multi-select',  Options: [1,2,3].map((item) => ({ label: item, value: item, property: 'filterBoards' }))},
        {Name: "Worker", PropertyName: 'filterWorker', Value: this.state.filter.filterWorker, Id: 'filterWorkerExtra', Type: 'text-column',  Options: []},
        {Name: "IP Address", PropertyName: 'filterIp', Value: this.state.filter.filterIp, Id: 'filterIpExtra', Type: 'text-column',  Options: []},
        {Name: "Miner Type", PropertyName: 'filterType', Value: this.state.filter.filterType, Type: 'multi-select',  Options: [{label: 'None', value: 'NULL'}].concat(this.props.typeStore.map((item) => ({ label: item.Name, value: item.ID, property: 'filterType' })))},
        {Name: "Firmware", PropertyName: 'filterFW', Value: this.state.filter.filterFW, Type: 'text-column',  Options: [], Id: 'filterFWExtra'},
        {Name: "Heat", PropertyName: 'fromHeat', Value: this.state.filter.fromHeat, PropertyTwoName: "toHeat", PropertyTwoValue: this.state.filter.toHeat, Id: 'filter-from-heatExtra', IdTwo: 'filter-to-heatExtra', Type: 'range',  Options:[]},
        {Name: "Current Speed", PropertyName: 'fromCurrentSpeed', Value: this.state.filter.fromCurrentSpeed, PropertyTwoName: "toCurrentSpeed", PropertyTwoValue: this.state.filter.toCurrentSpeed, Id: 'filter-from-speedExtra', IdTwo: 'filter-to-speedExtra', Type: 'range',  Options:[]},
        {Name: "Average Speed", PropertyName: 'fromAvgSpeed', Value: this.state.filter.fromAvgSpeed, PropertyTwoName: "toAvgSpeed", PropertyTwoValue: this.state.filter.toAvgSpeed, Id: 'filter-from-avgSpeedExtra', IdTwo: 'filter-to-avgSpeedExtra', Type: 'range',  Options:[]},
        {Name: "Missing Asic", PropertyName: 'fromMissingAsic', Value: this.state.filter.fromMissingAsic, PropertyTwoName: "toMissingAsic", PropertyTwoValue: this.state.filter.toMissingAsic, Id: 'filter-from-missing-asicExtra', IdTwo: 'filter-to-missing-asicExtra', Type: 'range',  Options:[]},
        {Name: "Performance", PropertyName: 'fromPerformance', Value: this.state.filter.fromPerformance, PropertyTwoName: "toPerformance", PropertyTwoValue: this.state.filter.toPerformance, Id: 'filter-from-performanceExtra', IdTwo: 'filter-to-performanceExtra', Type: 'range',  Options:[]},
        {Name: "Serial Number", PropertyName: 'filterSerialNumber', Value: this.state.filter.filterSerialNumber, Id: 'filterSerialNumberExtra', Type: 'text-column',  Options: []},
        {Name: "Show Deleted", PropertyName: 'showDeleted', Value: this.state.filter.showDeleted, Type: 'toggle-switch',  Options: []},
        {Name: "Show Duplicate Worker Name", PropertyName: 'showDuplicateWorkerName', Value: this.state.filter.showDuplicateWorkerName, Type: 'toggle-switch',  Options: []}]
    const { client, selectedMiners, isAllSelected, typeModalOpen, filter, statusModalOpen} = this.state;
    const selectionColumns = [
      {field: 'Type', value: 'Type'},
      {field: 'Value', value: 'Value'}
    ]
    let selectionData = [
      {Type: 'Customer', Value: this.props.match.params.name},
      {Type: 'Hashrate', Value:  formatBytes(this.props.minersStore.totalHashrate, 2)},
      {Type: 'Online/Total', Value: `${this.props.minersStore.online? this.props.minersStore.online.toLocaleString(): this.props.minersStore.online} / ${this.props.minersStore.totalData?this.props.minersStore.totalData.toLocaleString():this.props.minersStore.totalData}`},
      {Type: 'Offline Miners', Value: this.props.minersStore.offline},
      {Type: 'Idle Miners', Value: this.props.minersStore.idle},
      {Type: 'Sleep Miners', Value: this.props.minersStore.sleep},
      {Type: 'API Down Miners', Value: this.props.minersStore.apiDown},
      {Type: 'Cards/Boards Count', Value: this.props.minersStore.cardsCount},
      {Type: 'Performance', Value: this.props.minersStore.performance},
      {Type: 'Selected Miners', Value: this.state.selectedMiners.length}]
    const addCommentField = [
      {Name: 'Comment', PropertyName: 'customFlagComment', Value: this.state.customFlagComment, Type: 'textarea', Options: []}
    ]
    return (
      <>
      <div className="listViewDisplay">
        <div className="adc_listView-container">
            <SelectionInformationCard
              selectionData={selectionData}
              selectionColumns={selectionColumns}
            />
          {this.props.minersStore.sectionHistory && this.props.minersStore.sectionHistory.length > 0?(
            <div className="section-history-container">
                <SectionHistoryGraph
                  title="Section history - Last Week"
                  data={this.props.minersStore.sectionHistory}
                />
            </div>
          ):(<></>)}
          <MinerList
            exportList={this.exportList}
            miners={this.props.minersStore.miners}
            client={this.props.match.params.name}
            selectedMiners={selectedMiners}
            onItemSelect={this.onItemSelect}
            onSelectAll={this.onSelectAll}
            isAllSelected={isAllSelected}
            onPageClick={this.onPageClick}
            currentPage={this.props.minersStore.current_page}
            maxPage={this.props.minersStore.max_page}
            onSort={this.onSort}
            orderBy={this.props.minerListPage.orderBy}
            acending={this.props.minerListPage.acending}
            onClick={this.onItemClick}
            onClickCommand={this.onClickCommand}
            alterFav={this.alterFav}
            pageSize={this.props.minerListPage.pageSize}
            changePageSize={this.changePageSize}
            favoriteMiners={this.props.favMinersStore.data}
            addManyToFav={this.addManyToFav}
            deleteManyFromFav={this.deleteManyFromFav}
            onChangeTypeClick={() => this.setState({typeModalOpen: true})}
            filter={filter}
            onFilterInputChange={this.onFilterInputChange}
            refreshData={this.refreshData}
            region_id={this.props.match.params.region_id}
          />
        </div>
        <div className="MinerList-CommandButtons-addition" id="MinerList-CommandButtons-addition">
          {((userLevel === 0 && databaseTier >= 1) || userLevel > 0)?(
            <ListCommandButtons
              onClick={this.onButtonsClick}
              minerTypesAmount={this.state.filter.filterType.length}
              postCustomFlags={true}
            />
          ):(<></>)}
        </div>
        <div className="MinerList-Filtering-addition" id="MinerList-Filtering-addition">
          <ListFilter
            fields={extraFilterFields}
            onInputChange={this.onMultiInputChange}
            clearFilter={this.clearFilter}
          />
        </div>
        <Modal
          open={typeModalOpen}
          center
          onClose={() => this.setState({typeModalOpen: false})}
          styles={modalStyles}
        >
          <div className="modal-container">
            <h1 className="pick-header">Pick a type</h1>
            {this.props.typeStore.map((item, key) => {
              return <button type="button" key={key} className='modal-button' onClick={(e) => this.changeType(item.ID)}>{item.Name}</button>
            })}
          </div>
        </Modal>
        <Modal
          open={statusModalOpen}
          center
          onClose={() => this.setState({statusModalOpen: false})}
          styles={modalStyles}
        >
          <div className="modal-container">
            <h1 className="pick-header">Pick a status</h1>
            <button type="button" className='modal-button' onClick={e => this.onAlterStatusClick('Online')}>Online</button>
            <button type="button" className='modal-button' onClick={e => this.onAlterStatusClick('Recovery')}>Recovery</button>
            <button type="button" className='modal-button' onClick={e => this.onAlterStatusClick('API_DOWN')}>API_DOWN</button>
            <button type="button" className='modal-button' onClick={e => this.onAlterStatusClick('Missing_Fan')}>Missing_Fan</button>
            <button type="button" className='modal-button' onClick={e => this.onAlterStatusClick('Missing_PSU')}>Missing_PSU</button>
            <button type="button" className='modal-button' onClick={e => this.onAlterStatusClick('Missing_CTRL_Board')}>Missing_CTRL_Board</button>
          </div>

        </Modal>
        <Modal
          open={this.state.pickModeModalOpen}
          center
          onClose={() => this.setState({pickModeModalOpen: false})}
          styles={modalStyles}
        >
          <div className="modal-container">
            <h1 className="pick-header">Pick Mode</h1>
            {this.props.powerModes.map((item) => (
              <button type="button" className='modal-button' onClick={e => this.onModeChange(item.Value)}>{item.Name}</button>
            ))}
          </div>
        </Modal>
        <PickProfileModal
          profiles={this.props.poolStore.profiles}
          open={this.state.profileModalOpen}
          indexSelected={this.state.profileSelected}
          onInputChange={this.onProfileSelect}
          onClose={e => this.setState({
            profileModalOpen: false,
            profileSelected: -1
          })}
          onSubmit={this.onProfileSubmit}
        />
        <PickTuningProfileModal
          profiles={this.props.tuningStore.profiles}
          open={this.state.tuningModalOpen}
          indexSelected={this.state.tuningProfileSelected}
          onInputChange={this.onTuningProfileSelect}
          onClose={e => this.setState({
            tuningModalOpen: false,
            tuningProfileSelected: -1
          })}
          onSubmit={this.onTuningProfileSubmit}
        />
        <Modal
          open={this.state.customFlagModalOpen}
          center
          onClose={() => this.setState({ customFlagModalOpen: false })}
          styles={modalStyles}
        >
        <FormfieldSection
          title="Flag Comment"
          fields={addCommentField}
          addButton={true}
          buttonText="Create"
          onAddClick={this.createCustomFlag}
          onInputChange={e => onInputChange(e, this)}
          action="post_flag"
        />
        </Modal>
      </div>
        <aside className="MinerList-side-menu">
            <div className="MinerList-side-menu-inner" id="side-menu-inner">
              {((userLevel === 0 && databaseTier >= 1) || userLevel > 0)?(
                <ListCommandButtons
                  onClick={this.onButtonsClick}
                  minerTypesAmount={this.state.filter.filterType.length}
                  postCustomFlags={true}
                />
              ):(<></>)}
              <ListFilter
                fields={fields}
                onInputChange={this.onMultiInputChange}
                clearFilter={this.clearFilter}
              />
            </div>
        </aside>
      </>
    )
  }
}
const mapStateTopProps = (props) => ({
  minersStore: props.minersReducer,
  userStore: props.userReducer,
  commandStore: props.commandReducer,
  favMinersStore: props.favMinerReducer,
  minerListPage: props.pageReducer.minerList,
  clientStore: props.clientReducer,
  typeStore: props.typeReducer.types,
  powerModes: props.typeReducer.powerModes,
  podStore: props.podsReducer,
  houseStore: props.houseReducer,
  subnetStore: props.subnetReducer,
  listColumns: props.tableColumnsReducer.minerList,
  poolStore: props.poolReducer,
  responseStore: props.responseReducer,
  tuningStore: props.tuningReducer
})
export default connect(mapStateTopProps, {
  getFavMinersAction,
  deleteFavMinersAction,
  postFavMinersAction,
  updatePageInfoAction,
  sendCommandAction,
  setErrorMessageAction,
  updateClientAction,
  getTypesAction,
  setMinerTypeAction,
  adjustMinersStatusAction,
  clearLocationsAction,
  markMinersDeletedAction,
  clearMinersAction,
  getAllMinersAction,
  getHousesAction,
  clearPodsAction,
  getSubnetsAction,
  resetMinerDisplayAction,
  getPodsLimitedAction,
  getProfilesAction,
  getMinerTypePowerAction,
  clearMinerTypePowerAction,
  updateAlertAction,
  updateResponsePropertyAction,
  postCustomMinerFlagsAction,
  postProfileMinerConnectionsAction,
  getTuningProfilesAction
})(ListView)

/*
<CommandSection
  onClick={this.onClickCommand}
  addManyToFav={this.addManyToFav}
  deleteManyFromFav={this.deleteManyFromFav}
  onChangeTypeClick={() => this.setState({typeModalOpen: true})}
  openAlterStatus={() => this.setState({statusModalOpen: true})}
  clearLocations={this.clearLocations}
/>
*/

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { getRacksByPodIDAction } from '../../redux/actions/getRacksByPodIDAction'
import { getRackProfilesAction } from '../../redux/actions/getRackProfilesAction'
import { postRacksToPodAction } from '../../redux/actions/postRacksToPodAction'
import { removeRackFromPodAction } from '../../redux/actions/removeRackFromPodAction'
import { Modal } from "react-responsive-modal";
import './style.css'
const jwt = require('jwt-simple')

class PodDetailsView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      podName: '',
      buildingID: '',
      modalOpen: false,
      userData: {
        databases:[]
      },
      addRacks: '',
      addProfile: ''
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    const userData = jwt.decode(this.props.token, process.env.REACT_APP_DECRYPT_KEY);
    this.props.getRacksByPodIDAction(this.props.token, this.props.match.params.name, this.props.match.params.id)
    this.props.getRackProfilesAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id)
    this.setState({userData})
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history, `/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/`)
  }
  onDelete = (id) => {
    window.confirm("Are you sure you want to remove this rack ?") && this.props.removeRackFromPodAction(this.props.token, this.props.match.params.name, this.props.match.params.id, {Rack_ID: id})
  }
  onBack = () => {
    this.props.history.goBack()
  }
  onAdd = () => {
    let { addRacks, addProfile } = this.state
    if(addRacks == ''){
      alert("Racks is empty")
      return
    }
    if(addProfile == ''){
      alert("Please pick a profile")
      return
    }
    const currentRacks = this.props.rackStore.racks.map((item) => item.Rack_Number)
    let items = []
    let splitRacks = addRacks.split(',')
    for(var x in splitRacks){
      if(splitRacks[x].includes('-')){
        let shelfRe = /\d+-\d+/
        let reExec = shelfRe.exec(splitRacks[x])
        if(reExec){
          let split = reExec[0].split('-')
          let acending = parseInt(split[0]) < parseInt(split[1])
          if(acending){
            for(var i = parseInt(split[0]); i <= parseInt([split[1]]); i++){
              if(currentRacks.indexOf(i) === -1){
                items.push({Rack_Number: i, Rack_Profile_ID: addProfile})
              }
            }
          }
          else{
            for(var i = parseInt(split[1]); i <= parseInt([split[0]]); i++){
              if(currentRacks.indexOf(i) !== -1){
                items.push({Rack_Number: i, Rack_Profile_ID: addProfile})
              }
            }
          }
        }
        else{
          alert("Racks is wrongly formated")
          return
        }
      }
      else{
        try {
          const toInt = parseInt(splitRacks[x])
          if(!isNaN(toInt)){
            if(currentRacks.indexOf(toInt) === -1){
              items.push({Rack_Number: toInt, Rack_Profile_ID: addProfile})
            }
          }
          else{
            alert(`Racks is worngly formated for ${splitRacks[x]}`)
          }
        } catch (e) {
          alert("Racks is wrongly formated")
          return
        }
      }
    }
    if(items.length > 0 ){
      this.props.postRacksToPodAction(this.props.token, this.props.match.params.name, this.props.match.params.id, {racks: items})
      this.setState({
        modalOpen: false,
        addRacks: '',
        addProfile: ''
      })
    }
    else{
      alert("Racks given all exists in this pod")
    }
  }
  render(){
    const { level } = this.props.userData
    const pannelColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Value', value: 'Value'}
    ]
    const pannelData = [
      {Name: 'Name', Value: this.props.rackStore.currentPod.Pod_Name},
      {Name: 'House Name', Value: this.props.rackStore.currentPod.Building},
    ]
    const rackColumns = [
      {field: 'Rack_Number', value: 'Rack Number'},
      {field: 'Rack_Profile', value: 'Rack Profile'},
    ]
    const buttons = level >= 2?([{class: 'ViewBody-button-delete', onClick: this.onDelete, type: 'Delete', extractKey: 'ID'}]):([])
    const pickProfiles = this.props.rackStore.profiles.filter((item) => item.Region_ID === this.props.rackStore.currentPod.Region_ID).map((item) => ({text: item.Name, value: item.ID }))
    const addRacksFields = [
      {Name: 'Racks', PropertyName: 'addRacks' , Value: this.state.addRacks , Type: 'text-range', Options: []},
      {Name: 'Profile', PropertyName: 'addProfile' , Value: this.state.addProfile , Type: 'select', Options: [{text: '----------', value: '----------'}].concat(pickProfiles)}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };

    return(
      <div className="PodDetailsView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={configureView}
          active="Pods"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={pannelData}
            columns={pannelColumns}
            pannel
            onBack={this.onBack}
          />
          <ViewBody
            data={this.props.rackStore.racks}
            columns={rackColumns}
            list
            buttons={buttons}
            createtext="Add racks"
            onCreate={e => this.setState({modalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
        <FormfieldSection
          title="Add Racks"
          fields={addRacksFields}
          addButton={true}
          buttonText="Add"
          onAddClick={this.onAdd}
          onInputChange={e => onInputChange(e, this)}
          action="add_racks"
        />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  rackStore: props.rackReducer,
  buildingStore: props.houseReducer.houses,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  updateClientAction,
  getRacksByPodIDAction,
  getRackProfilesAction,
  postRacksToPodAction,
  getRacksByPodIDAction,
  removeRackFromPodAction
})(PodDetailsView)

import React from 'react'
import './style.css'

const FlagPanelItem = ({ title, titleClass }) => {
  return(
    <div className="flagView-statuscard">
      <div className="card-content">
        <div className="small-numbers">
          <strong>{title}</strong>
          <div className="indicator-container">
            <span className={titleClass}/>
          </div>
        </div>
      </div>
    </div>
  )
}


const FlagPanel = ({ list }) => {
  return(
    <div className="flag_panel_container">
      {list.map((item) =>(
        <FlagPanelItem
          title={item.title}
          titleClass={item.titleClass}
        />
      ))}
    </div>
  )
}


export default FlagPanel

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { adminView } from '../../helper/tabs'
import { clearCurrentClientAction } from '../../redux/actions/clearCurrentClientAction'
import { userGroupSwitch  } from '../../helper/functions'
import { getAllUsersAction } from '../../redux/actions/getAllUsersAction'
import { createNewUserAction } from '../../redux/actions/createNewUserAction'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { deleteUserAction } from '../../redux/actions/deleteUserAction'
import { adjustSystemPrivilegesAction } from '../../redux/actions/adjustSystemPrivilegesAction'
import {  SocketContext } from '../../context/socket'
import './style.css'


class UsersView extends React.Component {
  static contextType = SocketContext;
  constructor(props){
    super(props)
    this.state = {
      addModalOpen: false,
      editModalOpen: false,
      userSearchString: '',
      username: '',
      password: '',
      email: '',
      userGroup: '',
      currentGroup: ''
    }
  }
  componentDidMount = () => {
    const socket = this.context
    const hamburger = document.getElementById('toggleHamburger-span')
    this.props.clearCurrentClientAction()
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
    this.props.getAllUsersAction(
      this.props.userStore.result,
      this.props.userPage.pageSize,
      this.props.userPage.pageNumber,
      this.props.userPage.orderBy,
      this.props.userPage.acending,
      this.state.userSearchString,
    )
  }
  componentWillUnmount = () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onUserPageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber === parseInt(this.props.adminStore.users.current_page)) return
    const { pageSize, orderBy, acending } = this.props.userPage;
    if (pageNumber !== 0 && pageNumber <= this.props.adminStore.users.max_page) {
      this.props.getAllUsersAction(this.props.userStore.result, pageSize, pageNumber, orderBy, acending, this.state.userSearchString);
    }
    const store = {
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'users'
    }
    this.props.updatePageInfoAction(store);
  }
  onUserSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.userPage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getAllUsersAction(this.props.userStore.result, pageSize, pageNumber, type, acending, this.state.userSearchString);
    const store = {
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'users',
    }
    this.props.updatePageInfoAction(store);
  }
  onUserSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.userPage;
    this.props.getAllUsersAction(this.props.userStore.result, pageSize, 1, orderBy, acending, e.target.value);
    this.setState({
      userSearchString: e.target.value
    })
    const store = {
      pageSize: pageSize,
      pageNumber: 1,
      type: 'users',
      orderBy: this.props.userPage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onCreateUser = async (action) => {
    const socket = this.context
    const emailRegex = /^\S+@\S+\.\S+$/
    const { username, password, email, userGroup } = this.state
    if(username == ''){
      alert("Enter an username")
      return
    }
    if(password == ''){
      alert("Enter an password")
      return
    }
    if(email == ''){
      alert("Enter an email")
      return
    }
    if(!emailRegex.exec(email)){
      alert("Enter a valid email")
      return
    }
    if(userGroup == ''){
      alert("Enter an user group")
      return
    }
    await this.props.createNewUserAction(this.props.userStore.result, {username: username, email: email, password: password, level: userGroup})
    this.setState({
      addModalOpen: false,
      username: '',
      password: '',
      email: '',
      userGroup : ''
    })
    if(socket !== null){
      socket.emit("createNewUser", username)
    }
  }
  deleteUser = (username) => {
    if(window.confirm(`Are you sure you want to delete ${username}. Action cannot be reverted`)){
      this.props.deleteUserAction(this.props.userStore.result, username)
      const socket = this.context
      if(socket !== null){
        socket.emit("onUserDelete", username)
      }
    }
  }
  onEdit = (action) => {
    const { username, userGroup } = this.state
    if(username == ''){
      alert("Pick a user")
      return
    }
    if(userGroup == ''){
      alert("Pick a user group")
      return
    }
    this.props.adjustSystemPrivilegesAction(this.props.userStore.result, {username, level: userGroup})
    this.setState({
      editModalOpen: false,
      username: '',
      userGroup: '',
      currentGroup: '---------'
    })
  }
  editUser = (username) => {
    let  userObj = this.props.adminStore.users.data.filter((item) => item.Username == username)
    this.setState({ username: username, currentGroup: userGroupSwitch(userObj[0].Level), editModalOpen: true })
  }
  render(){
    const modalStyle = {
       modal: {
         width: '80vw',
         background: '#141619'
       }
     };
    const systemUsersColums = [
      {field: 'Username', value: 'Username'},
      {field: 'Email', value: 'Email'},
      {field: 'Level', value: 'User Group'}]
    const systemUserButtons = [
      {class: 'ViewBody-button-edit', onClick: this.editUser, type: 'Edit', extractKey: 'Username'},
      {class: 'ViewBody-button-delete', onClick: this.deleteUser, type: 'Delete', extractKey: 'Username'}
    ]
    const addUserFields = [
      {Name: "Username", PropertyName: 'username', Value: this.state.username, Type: "text", Options: []},
      {Name: "Password", PropertyName: 'password', Value: this.state.password, Type: "text", Options: []},
      {Name: "Email", PropertyName: 'email', Value: this.state.email, Type: "email", Options: []},
      {Name: "User Group", PropertyName: 'userGroup', Value: this.state.userGroup, Type: "select",
        Options:
          [{text: "----------", value: ''},
          {text: "Select Users", value: 0},
          {text:"Insert/Update Users", value: 1},
          {text:"Administrator", value: 2}
        ]}
    ]
    const adjustSystemPrivileges = [
      {Name: "User", PropertyName: 'username', Type: 'text-disabled', Value: this.state.username, Options: []},
      {Name: "Current Group", PropertyName: 'currentGroup', Type: 'text-disabled', Value: this.state.currentGroup, Options: []},
      {Name: "User Group", PropertyName: 'userGroup', Type: "select", Value: this.state.userGroup,
        Options:
          [{text: "----------", value: ''},
          {text: "Select Users", value: 0},
          {text:"Insert/Update Users", value: 1},
          {text:"Administrator", value: 2}
        ]}]
    return(
        <div className="adminView-container">
          <TabHeader
            logo={
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
              </svg>
            }
            title="Server Admin"
            subtitle="Manage scanner, databases & more"
            tabs={adminView}
            active="Users"
            onClick={this.onClick}
          />
          <div className="AdminView-content">
            <ViewBody
              data={this.props.adminStore.users.data}
              columns={systemUsersColums}
              buttons={systemUserButtons}
              createtext="New user"
              list
              onCreate={e => this.setState({addModalOpen: true})}
              pager
              sorting
              search
              searchValue={this.state.userSearchString}
              onSearchChange={this.onUserSearch}
              acending={this.props.userPage.acending}
              sortedBy={this.props.userPage.orderBy}
              onSort={this.onUserSort}
              totalPages={this.props.adminStore.users.max_page}
              currentPage={parseInt(this.props.adminStore.users.current_page) - 1}
              onPageChange={this.onUserPageClick}
            />
          </div>
          <Modal
            open={this.state.addModalOpen}
            center
            onClose={() => this.setState({addModalOpen: false})}
            styles={modalStyle}
          >
            <FormfieldSection
              title="Create new user"
              fields={addUserFields}
              addButton={true}
              buttonText="Add"
              onAddClick={this.onCreateUser}
              onInputChange={e => onInputChange(e, this)}
              action="add_user"
            />
          </Modal>
          <Modal
            open={this.state.editModalOpen}
            center
            onClose={() => this.setState({editModalOpen: false, username: '', password: '', email: '', userGroup: ''})}
            styles={modalStyle}
          >
          <FormfieldSection
            title="Adjust user system privileges"
            fields={adjustSystemPrivileges}
            addButton={true}
            buttonText="Edit"
            onAddClick={this.onEdit}
            onInputChange={e => onInputChange(e, this)}
            action="adjust_system_privileges"
          />
          </Modal>
        </div>
    )
  }
}

const mapStateToProps = (props) => ({
  userStore: props.userReducer,
  adminStore: props.adminReducer,
  userPage: props.pageReducer.users,
})

export default connect(mapStateToProps, {
  getAllUsersAction,
  createNewUserAction,
  updatePageInfoAction,
  deleteUserAction,
  adjustSystemPrivilegesAction,
  clearCurrentClientAction
})(UsersView)

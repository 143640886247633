import { exitActionConstant } from '../constants/actions'

export const exitAction = () => {
  return (dispatch) => {
    dispatch(actionSuccess(exitActionConstant))
  }
}

const actionSuccess = () => ({
  type: exitActionConstant
})

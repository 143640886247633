import React from 'react';
import MinerListItem from '../MinerListItem'
import Rangeslider from '../Rangeslider';
import BootstrapPaging from '../BootstrapPaging'
import CommandPanel from '../../Components/CommandPanel'
import ListPageSizes from '../../Components/ListPageSizes'
import TableModel from '../TableModel'
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import './style.css'
import { connect } from 'react-redux'
import { fetchMoreMinersAction } from '../../redux/actions/fetchMoreMinersAction'
import { toggleTableColumnAction } from '../../redux/actions/toggleTableColumnAction'
import { getAlertSettingsAction } from '../../redux/actions/getAlertSettingsAction'
import { updateUsersMinerListColumnsAction } from '../../redux/actions/updateUsersMinerListColumnsAction'
import { getUsersMinerListColumnsAction } from '../../redux/actions/getUsersMinerListColumnsAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortDown, faSortUp  } from '@fortawesome/free-solid-svg-icons'
import Pager from '../Pager'

class MinerList extends React.Component{
  constructor(props){
    super(props)
      this.state = {
        items: [],
        displayedColumns: 11,
      }
  }
  handleScroll = (e) => {
    let { displayInterval } = this.props.minerStore
    var doc = document.documentElement;
    const scrollTop = e.srcElement.scrollTop
    const height = document.documentElement.offsetHeight;
		if (scrollTop > height*displayInterval){
      this.props.fetchMoreMinersAction(displayInterval+1)
    }
		return;
	};
  calculateDisplayedColumns = (width) => {
    const options     = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const lower       = 450
    const top         = window.screen.width - 325
    const maxColumns  = 10
    const columnSize = 150
    const index       = width <= 450? 0: width >= top? 9: Math.floor((width-lower)/columnSize)
    return options.length > index? options[index]: 13
  }
  handleScreenChange = (e) => {
    const { displayedColumns } = this.state
    const width = document.documentElement.offsetWidth;
    const newDisplayedColumns =  this.calculateDisplayedColumns(width)
    if(newDisplayedColumns !== displayedColumns){
      this.setState({displayedColumns: newDisplayedColumns})
    }
  }
  componentDidMount = () => {
    const width = document.documentElement.offsetWidth;
    const newDisplayedColumns =  this.calculateDisplayedColumns(width)
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleScreenChange, true)
    this.props.getAlertSettingsAction(this.props.token, this.props.client)
    this.props.getUsersMinerListColumnsAction(this.props.token)
    this.setState({displayedColumns: newDisplayedColumns})
  }
  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll, true);
    window.removeEventListener('resize', this.handleScreenChange, true);
  }
  onColumnToggle = (e) => {
    const element = document.getElementById('columnDropdown')
    if(element){
      element.classList.toggle('adc_table-dropDown-toggleDisplay')
    }
  }
  onColumnClick = (e) => {
    this.props.toggleTableColumnAction(e.target.name, 'minerList')
  }
  onSaveColumns = () => {
    let { columns, userID } = this.props
    let obj = {}
    for(var i in columns){
      obj[i] = + columns[i].Value
    }
    this.props.updateUsersMinerListColumnsAction(userID, obj, this.props.token)
  }
  render(){
    const { client, subnet, selectedMiners, onItemSelect, onSelectAll, isAllSelected, onPageClick, maxPage, currentPage, onSort, orderBy, onClick, acending,onClickCommand, alterFav, favoriteMiners, changePageSize, pageSize, addManyToFav, deleteManyFromFav, pod, onChangeTypeClick, filter, onFilterInputChange, exportList, refreshData, pickFlag } = this.props;
    let offlineTime = this.props.alertStore.settings.filter((item) => item.Type == 'Offline Time')
    offlineTime = offlineTime.length > 0?(offlineTime[0].Threshold):(15)
    const { displayedColumns } = this.state
    let columns = Object.keys(this.props.columns).filter((item) => {
      if(!this.props.columns[item].hasOwnProperty('Value')){
        return true
      }
      else{
        return this.props.columns[item]['Value']
      }
    })
    let allColumns = this.props.columns
    columns = columns.filter((item) => this.props.columns[item] ).slice(0, displayedColumns)
    if(this.props.displayFlagColumns){
      allColumns['Flag_Status'] = {Key: 'Flag_Status', Label: 'Flag Status', Value: true}
      allColumns['Flag_Type_Name'] = {Key: 'Flag_Type_Name', Label: 'Flag Type', Value: true}
      columns = ["Flag_Status", "Flag_Type_Name"].concat(columns)
      columns = columns.slice(0, displayedColumns)
    }
    return(
      <div>
      <TableModel
        exportList={exportList}
        currentPageSize={this.props.minerStore.miners.length}
        pageSize={pageSize}
        changePageSize={changePageSize}
        columns={allColumns}
        onColumnToggle={this.onColumnToggle}
        onColumnClick={this.onColumnClick}
        refreshData={refreshData}
        onSaveColumns={this.onSaveColumns}
        Header={
          <thead>
            <tr>
              <th className='minerList'
                key={-2}
                style={orderBy == "Favorites"? ( acending? (({color: 'yellow', width:10})):({color: 'orange', width:10})):({color: "black", width:10})}
                onClick={e => onSort("Favorites")} >
                <i className='fa fa-star'></i>
              </th>
              <th key={-1} className='minerList' style={{width: 10}}><input type="checkbox" id="testId" name="select" value={isAllSelected}defaultChecked={isAllSelected} onClick={(e) => onSelectAll(!isAllSelected) }/></th>
              {columns.map((key, index) => (
                this.props.columns[key]?(
                  key == 'Comments'?(
                    <th key={index} className="minerList th-sm">{key.split('_').join(' ')}</th>
                  ):(
                    !allColumns[key].hasOwnProperty('Value')?(
                      <th key={index} className="minerList th-sm" onClick={e => onSort(key)}>{ orderBy === key? (
                          acending? <FontAwesomeIcon icon={faSortDown} size="lg"/>:<FontAwesomeIcon icon={faSortUp} size="lg"/>
                        ):<FontAwesomeIcon icon={faSort} size="lg"/>}{key.split('_').join(' ')}</th>
                    ):(
                      allColumns[key]['Value']?(
                        <th key={index} className="minerList th-sm" onClick={e => onSort(key)}>{ orderBy === key? (
                            acending? <FontAwesomeIcon icon={faSortDown} size="lg"/>:<FontAwesomeIcon icon={faSortUp} size="lg"/>
                          ):<FontAwesomeIcon icon={faSort} size="lg"/>}{allColumns[key]['Label']}</th>
                      ):(<React.Fragment key={index}></React.Fragment>)
                    )
                  )
                ):(<React.Fragment key={index}></React.Fragment>)
              ))}
            </tr>
          </thead>
        }
        Body={
          <tbody>
            {this.props.minerStore.displayedMiners.map((item, i) => {
              if(item !== null){
                return  (
                    <MinerListItem
                            key={i}
                            props={item}
                            update_time={item.Update_Time}
                            clientName={client}
                            subnetId={String(subnet)}
                            onSelect={onItemSelect}
                            selectedMiners={selectedMiners}
                            itemId={i}
                            onClick={onClick}
                            alterFav={alterFav}
                            favoriteMiners={favoriteMiners}
                            pod={pod}
                            displayedColumns={columns}
                            columns={this.props.columns}
                            offlineTime={offlineTime}
                            region_id={this.props.region_id}
                            pickFlag={pickFlag}
                            colorBy={this.props.colorBy}
                          />)
              }})
            }
            </tbody>
        }
        onPageClick={onPageClick}
        currentPage={currentPage}
        maxPage={maxPage}
      />
    </div>
    )
  }
}

const mapStateToProps = (store) =>({
  minerStore: store.minersReducer,
  token: store.userReducer.result,
  columns: store.tableColumnsReducer.minerList,
  userID: store.tableColumnsReducer.userID,
  alertStore: store.alertReducer
})
export default connect(mapStateToProps, {
  fetchMoreMinersAction,
  toggleTableColumnAction,
  getAlertSettingsAction,
  updateUsersMinerListColumnsAction,
  getUsersMinerListColumnsAction
})(MinerList);

/*

{
    if(this.props.columns[key]){
      if(key === 'Comments'){
        return <th key={index} className="minerList th-sm">{key.split('_').join(' ')}</th>
      }
      else{
        return(
          <th key={index} className="minerList th-sm" onClick={e => onSort(key)}>{ orderBy === key? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>}{key.split('_').join(' ')}</th>
        )
      }
    }
  }


this.props.columns[key]?(
  key == 'Comments'?(
    <th key={index} className="minerList th-sm">{key.split('_').join(' ')}</th>
  ):(
    <th key={index} className="minerList th-sm" onClick={e => onSort(key)}>{ orderBy === key? (
        acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
      ):<i className="fa fa-sort"/>}{key.split('_').join(' ')}</th>
  )
):(<></>)
*/




/*
const MinerList = ({miners, client, subnet, selectedMiners, onItemSelect, onSelectAll, isAllSelected, onPageClick, maxPage, currentPage, onSort, orderBy, onClick, acending,onClickCommand, alterFav, favoriteMiners, changePageSize, pageSize, addManyToFav, deleteManyFromFav, pod, onChangeTypeClick, filter, onFilterInputChange, exportList}) => {
  return(
    <TableModel
      exportList={exportList}
      currentPageSize={miners.length}
      pageSize={pageSize}
      changePageSize={changePageSize}
      Header={
        <thead>
          <tr>
            <th className='minerList'
              style={orderBy == "Favorites"? ( acending? (({color: 'yellow', width:10})):({color: 'orange', width:10})):({color: "black", width:10})}
              onClick={e => onSort("Favorites")} >
              <i className='fa fa-star'></i>
            </th>
            <th className='minerList' style={{width: 10}}><input type="checkbox" id="testId" name="select" value={isAllSelected}defaultChecked={isAllSelected} onClick={(e) => onSelectAll(!isAllSelected) }/></th>
            <th className="minerList th-sm" onClick={e => onSort("Worker_Name")}>{ orderBy === "Worker_Name"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Worker</th>
            <th className="minerList th-sm" onClick={e => onSort("Status")}>{ orderBy === "Status"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Status</th>
            <th className="minerList th-sm" onClick={e => onSort("Location")}>{ orderBy === "Location"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Location</th>
            <th className="minerList th-sm" onClick={e => onSort("IP_Address")}>{ orderBy === "IP_Address"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} IP Address</th>
            <th className="minerList th-sm" onClick={e => onSort("Max_Heat")}>{ orderBy === "Max_Heat"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Heat</th>
            <th className="minerList th-sm" onClick={e => onSort("Total_Speed")} >{ orderBy === "Total_Speed"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Current Speed</th>
            <th className="minerList th-sm" onClick={e => onSort("Avg_Speed")} >{ orderBy === "Avg_Speed"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Average Speed</th>
            <th className="minerList th-sm" onClick={e => onSort("Missing_Asic")} >{ orderBy === "Missing_Asic"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Missing Asic</th>
            <th className="minerList th-sm" onClick={e => onSort("Miner_Type")} >{ orderBy === "Miner_Type"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Type</th>
            <th className="minerList th-sm" onClick={e => onSort("Performance")} >{ orderBy === "Performance"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Performance</th>

            <th className="minerList th-sm" onClick={e => onSort("Update_Time")}>{ orderBy === "Update_Time"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Last Update</th>
          </tr>
        </thead>
      }
      Body={
        <tbody>
          {miners.map((item, i) => {
            if(item !== null){
              return  (
                  <MinerListItem
                          key={i}
                          props={item}
                          update_time={item.Update_Time}
                          clientName={client}
                          subnetId={String(subnet)}
                          onSelect={onItemSelect}
                          selectedMiners={selectedMiners}
                          itemId={i}
                          onClick={onClick}
                          alterFav={alterFav}
                          favoriteMiners={favoriteMiners}
                          pod={pod}
                        />)
            }})
          }
          </tbody>
      }
      onPageClick={onPageClick}
      currentPage={currentPage}
      maxPage={maxPage}
    />
  )
}
export default MinerList;
/*
<div className="minerList-div">
  <ListPageSizes
    pageSize={pageSize}
    currentPageSize={miners.length}
    onPageSizeChange={changePageSize}
  />
  <div className='tbl-header'>
    <table
    className="minerTable"
    cellSpacing="0"
    cellPadding='0'
    border='0'
    width="100%">
      <thead>
        <tr>
          <th className='minerList'
            style={orderBy == "Favorites"? ( acending? (({color: 'yellow', width:10})):({color: 'orange', width:10})):({color: "black", width:10})}
            onClick={e => onSort("Favorites")} >
            <i className='fa fa-star'></i>
          </th>
          <th className='minerList' style={{width: 10}}><input type="checkbox" id="testId" name="select" value={isAllSelected}defaultChecked={isAllSelected} onClick={(e) => onSelectAll(!isAllSelected) }/></th>
          <th className="minerList th-sm" onClick={e => onSort("Worker_Name")}>{ orderBy === "Worker_Name"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Worker</th>
          <th className="minerList th-sm" onClick={e => onSort("Status")}>{ orderBy === "Status"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Status</th>
          <th className="minerList th-sm" onClick={e => onSort("Location")}>{ orderBy === "Location"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Location</th>
          <th className="minerList th-sm" onClick={e => onSort("IP_Address")}>{ orderBy === "IP_Address"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} IP Address</th>
          <th className="minerList th-sm" onClick={e => onSort("Max_Heat")}>{ orderBy === "Max_Heat"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Heat</th>
          <th className="minerList th-sm" onClick={e => onSort("Total_Speed")} >{ orderBy === "Total_Speed"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Current Speed</th>
          <th className="minerList th-sm" onClick={e => onSort("Avg_Speed")} >{ orderBy === "Avg_Speed"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Average Speed</th>
          <th className="minerList th-sm" onClick={e => onSort("Missing_Asic")} >{ orderBy === "Missing_Asic"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Missing Asic</th>
          <th className="minerList th-sm" onClick={e => onSort("Miner_Type")} >{ orderBy === "Miner_Type"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Type</th>
          <th className="minerList th-sm" onClick={e => onSort("Performance")} >{ orderBy === "Performance"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Performance</th>

          <th className="minerList th-sm" onClick={e => onSort("Update_Time")}>{ orderBy === "Update_Time"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Last Update</th>
        </tr>
      </thead>
    <tbody>
    {miners.map((item, i) => {
      if(item !== null){
        return  <MinerListItem
                  key={i}
                  props={item}
                  update_time={item.Update_Time}
                  clientName={client}
                  subnetId={String(subnet)}
                  onSelect={onItemSelect}
                  selectedMiners={selectedMiners}
                  itemId={i}
                  onClick={onClick}
                  alterFav={alterFav}
                  favoriteMiners={favoriteMiners}
                  pod={pod}
                />
      }})
    }
    </tbody>
    <thead>
      <tr>
        <th className='minerList'
          style={orderBy == "Favorites"? ( acending? (({color: 'yellow', width:10})):({color: 'orange', width:10})):({color: "black", width:10})}
          onClick={e => onSort("Favorites")} >
          <i className='fa fa-star'></i>
        </th>
        <th className='minerList' style={{width: 10}}><input type="checkbox" id="testId" name="select" value={isAllSelected}defaultChecked={isAllSelected} onClick={(e) => onSelectAll(!isAllSelected) }/></th>
        <th className="minerList th-sm" onClick={e => onSort("Worker_Name")}>{ orderBy === "Worker_Name"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Worker</th>
        <th className="minerList th-sm" onClick={e => onSort("Status")}>{ orderBy === "Status"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Status</th>
        <th className="minerList th-sm" onClick={e => onSort("Location")}>{ orderBy === "Location"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Location</th>
        <th className="minerList th-sm" onClick={e => onSort("IP_Address")}>{ orderBy === "IP_Address"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} IP Address</th>
        <th className="minerList th-sm" onClick={e => onSort("Max_Heat")}>{ orderBy === "Max_Heat"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Heat</th>
        <th className="minerList th-sm" onClick={e => onSort("Total_Speed")} >{ orderBy === "Total_Speed"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Current Speed</th>
        <th className="minerList th-sm" onClick={e => onSort("Avg_Speed")} >{ orderBy === "Avg_Speed"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Average Speed</th>
        <th className="minerList th-sm" onClick={e => onSort("Missing_Asic")} >{ orderBy === "Missing_Asic"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Missing Asic</th>
        <th className="minerList th-sm" onClick={e => onSort("Miner_Type")} >{ orderBy === "Miner_Type"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Type</th>
        <th className="minerList th-sm" onClick={e => onSort("Performance")} >{ orderBy === "Performance"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Performance</th>
        <th className="minerList th-sm" onClick={e => onSort("Update_Time")}>{ orderBy === "Update_Time"? (
            acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
          ):<i className="fa fa-sort"/>} Last Update</th>
      </tr>
    </thead>
    </table>
  </div>
  <Pager
    onPageClick={onPageClick}
    pageSize={pageSize}
    currentPage={currentPage}
    maxPage={maxPage}
    currentPageSize={miners.length}
    onPageSizeChange={changePageSize}
  />
</div>
*/

import logService from '../services/logService';
import { getUserLogsByMacConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
//import { setSuccessMessageAction } from './setSuccessMessageAction';

export const getUserLogsByMacAction = (mac, token, client, pageSize, pageNumber, orderBy, acending, searchString) => {
  return async(dispatch) => {
    const result = await logService.getUserLogsByMac(mac, token, client, pageSize, pageNumber, orderBy, acending, searchString);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(success(result))
  }
}

const success = (logs) => ({
  type: getUserLogsByMacConstant,
  payload: logs
})

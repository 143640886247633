import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { Modal } from "react-responsive-modal";
import { onTabHeaderClick, onInputChange } from '../../functions'
import { configureView } from '../../helper/tabs'
import { getHousesAction } from '../../redux/actions/getHousesAction';
import { deleteHouseAction } from '../../redux/actions/deleteHouseAction';
import { postNewHouseAction } from '../../redux/actions/postHouseAction';
import { updateClientAction } from '../../redux/actions/updateClientAction'
import './style.css'
const jwt = require('jwt-simple');

class BuildingView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      buildingName: '',
      regionID: '',
      modalOpen: false,
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    this.props.getHousesAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id);
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onDelete = (id) => {
    this.props.deleteHouseAction(this.props.token, this.props.match.params.name, id, this.props.match.params.region_id)
  }
  onAdd = () => {
    let { buildingName, regionID } = this.state
    if (buildingName.length > 12) {
      alert("The name you picked is too long, max length is 12");
      return;
    }
    if(this.props.match.params.region_id === 'All'){
      if(regionID === ''){
        alert("You need to pick a region")
        return;
      }
    }
    else{
      regionID = this.props.match.params.region_id
    }
    if (buildingName == '') {
      alert("The house name is empty");
      return;
    }
    this.props.postNewHouseAction({ Name: buildingName, Region_ID: regionID, Current_Region: this.props.match.params.region_id }, this.props.token, this.props.match.params.name)
    this.setState({ buildingName: '', modalOpen: false })
  }
  render(){
    const { userData } = this.props
    const columns = [
      {field: 'Name', value: 'Name'},
      {field: 'Region_Name', value: "Region"}
    ]
    const buttons = userData.level >= 2?([{class: 'ViewBody-button-delete', onClick: this.onDelete, type: 'Delete', extractKey: 'ID'}]):([])
    let addBuildingFields = ''
    if(this.props.match.params.region_id === "All"){
      let regions = userData != ''? userData.databases.filter((item) => item.Database_Name === this.props.match.params.name):[]
      regions = regions[0]? regions[0].Regions: []
      addBuildingFields = [
        {Name: 'Name', PropertyName: 'buildingName', Value: this.state.buildingName, Type: 'text', Options: []},
        {Name: 'Region', PropertyName: 'regionID', Value: this.state.regionID, Type: 'select', Options: [{text: "----------", value: ''}].concat(regions.map((item) => ({text: item.Name, value: item.Region_ID})))},
      ]
    }
    else{
      addBuildingFields = [
        {Name: 'Name', PropertyName: 'buildingName', Value: this.state.buildingName, Type: 'text', Options: []},
      ]
    }
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="BuildingView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={configureView}
          active="Buildings"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={this.props.buildingStore.houses}
            columns={columns}
            buttons={buttons}
            createtext="New building"
            list
            onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
            itemClickInfo={{name: 'buildings', property: 'ID'}}
            onCreate={e => this.setState({modalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create new building"
            fields={addBuildingFields}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAdd}
            onInputChange={e => onInputChange(e, this)}
            action="add_bulding"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
    token: props.userReducer.result,
    userData: props.userReducer.userData,
    buildingStore: props.houseReducer,
    clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  getHousesAction,
  deleteHouseAction,
  postNewHouseAction,
  updateClientAction,
})(BuildingView)

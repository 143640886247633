import moment from "moment"
export const formatBytes = (bytes, decimals = 2)  => {
  if (bytes === 0) return '0 Hash';
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  let sizes = ['Hash', 'KH', 'MH', 'GH', 'TH', 'PH', 'EH', 'ZH', 'YH'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export const checkTime = (date) => {
  const time = moment(date)
  const now = moment()
  return time < now.subtract(15, 'minutes')
}
export const formatTime = (date) => {
  const time = moment(date)
  return time.format('DD/MM/YYYY hh:mm:ss')
}

import { toggleTableColumnConstant } from '../constants/actions'

export const toggleTableColumnAction = (column, tableType) => {
  return dispatch => {
    dispatch({
      type: toggleTableColumnConstant,
      payload: {column, tableType}
    })
  }
}

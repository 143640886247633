import React from 'react'
import './style.css'
import "bootstrap/dist/css/bootstrap.min.css";
import { convertFields } from './functions'
const FormfeildSection = ({ fields, title, addButton, onAddClick, onInputChange, action, buttonText, extraButton=false, extraButtonText, extraAction }) => {
  return(
    <div className="formfieldSection-card">
      {title? (
         <div className="card-header">
         <strong>{title}</strong>
       </div>
      ):(<></>)}
      <div className="card-body">
          {convertFields(fields, onInputChange)}
      </div>
      {addButton?(
        <div className="text-right formfieldSection-button-div">
          <button type="submit" className="ViewBody-button-create" onClick={ e => onAddClick(action)}>{buttonText}</button>
          {extraButton?(
            <button type="submit" className="ViewBody-button-create" onClick={ e => onAddClick(extraAction)}>{extraButtonText}</button>
          ):(<></>)}
        </div>
      ):(<></>)}
    </div>
  )
}

export default FormfeildSection

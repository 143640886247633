import React from 'react';
import './style.css';

/*
columns: {key: "displayName"}
*/

const ConfigSectionTable = ({ columns, items, onDelete, privilegeLevel, deletePrivileges, deleteType }) => {
  const keys = Object.keys(columns)
  return(
    <table
      className="adc_configSectionTable-table"
      cellSpacing="0"
      cellPadding="0"
      >
      <thead>
        <tr>
          {keys.map((item, key) => (
            <th key={key}><span>{columns[item]}</span></th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((poolItem, key) => (
          <tr key={key}>
            {keys.map((item, i) => (
              <td key={i}>
                <div className="adc_ConfigSectionTable-td-div">
                  <input value={poolItem[item]} className="adc_configBuildingSection-input" disabled/>
                  {i === keys.length - 1 && privilegeLevel >= deletePrivileges?(
                    <button type="button" className="adc_deviceMapping-button" onClick={e => onDelete(deleteType == "Connection"?{House_ID: poolItem.House_ID, Subnet_ID: poolItem.Subnet_ID}:poolItem.ID, deleteType)}>
                      <i className="fa fa-close"></i>
                    </button>
                  ):(<></>)}
                </div>
              </td>

            ))}
          </tr>
        ))}

      </tbody>
    </table>
  )
}

export default ConfigSectionTable

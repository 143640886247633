import React from 'react';
import './style.css';
import Section from '../Section'


const ConfigAlertsSection = ({ alertSettings, onSave, onInputChange, privilegeLevel, insertPrivileges, onMinimize, showBody }) => {
  return(
    <Section
      icon={<i className="fa fa-bell"/>}
      name="Alert Section"
      onMinimize={onMinimize}
      showBody={showBody}
      body={
        <div className="adc_configAlertSectionTable-container">
          <table
            className="adc_configAlertSectionTable-table"
            cellspacing="0"
            cellpadding="0">
            <thead>
              <tr>
                <th><span>Type</span></th>
                <th><span>Threshold</span></th>
              </tr>
            </thead>
            <tbody>
              {alertSettings.map((item, key) => (
                <tr key={key}>
                  <td><span>{item.Type}: </span></td>
                  {privilegeLevel >= insertPrivileges?(
                    <td><input className="adc_configBuildingSection-input" type="number" name={`${item.Type}-${key}`} value={item.Threshold} onChange={e => onInputChange(e)}/></td>
                  ):(
                    <td><input className="adc_configBuildingSection-input" type="number" name={`${item.Type}-${key}`} value={item.Threshold} disabled/></td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {privilegeLevel >= insertPrivileges?(
            <div className="adc_configAlertSectionTable-buttonDiv">
              <button type="button" className="adc_deviceMapping-button btn-success" onClick={e => onSave(e)}>
                Update
              </button>
            </div>
          ):(<></>)}
        </div>
      }
    />
  )
}

export default ConfigAlertsSection

import {
  clearHeatmapReducer,
  updateHeatmapReducer,
  getHeatmapMinersConstant,
  setId_getHeatmapMinersConstant
}
from '../constants/actions'
const defaultState = {
  ok: false,
  msg: '',
  api_version: 1.1,
  client: '',
  house: '',
  houseID: '',
  currentPod: 'None',
  currentPodName: '',
  pods: [],
  heatmaps: [],
  get_heatmap_miners_actionId: null
}

export default(state = defaultState, action) => {
  switch (action.type) {
    case setId_getHeatmapMinersConstant:
      return{
        ...state,
        get_heatmap_miners_actionId: action.payload
      }
    case clearHeatmapReducer:
      return {
        ok: false,
        msg: '',
        client: '',
        house: '',
        houseID: '',
        currentPod: 'None',
        currentPodName: '',
        pods: [],
        heatmaps: [],
        get_heatmap_miners_actionId: null
      }
    case updateHeatmapReducer:
      return {
        ...action.payload,
        get_heatmap_miners_actionId: state.get_heatmap_miners_actionId
      }
    case getHeatmapMinersConstant:
      if(action.actionId !== state.get_heatmap_miners_actionId) return state
      if(action.payload.ok){
        return {
          ok: true,
          msg: action.payload.msg,
          client: action.payload.client,
          currentPod: action.payload.currentPod,
          currentPodName: action.payload.currentPodName,
          pods: state.pods,
          heatmaps: action.payload.heatmaps,
          house: state.house,
          houseID: state.houseID,
          get_heatmap_miners_actionId: null
        }
      }
      else{
        return {
          ok: false,
          msg: action.payload.msg,
          client: state.client,
          house: state.house,
          currentPod: state.currentPod,
          currentPodName: state.currentPodName,
          pods: state.pods,
          heatmaps: state.heatmaps,
          houseID: state.houseID,
          get_heatmap_miners_actionId: null
        }
      }
    default:
      return state
  }
}

import React from 'react'
export const userGroupSwitch = (level) => {
  switch (level) {
    default:
    case 0:
      return "Select Users"
    case 1:
      return "Insert/Update Users"
    case 2:
      return "Administrator"
  }
}

export const functionSwitch = (item, func) => {
  switch (func) {
    default:
    case 'length':
      return item.length
  }
}

const matchMac = (id_no) => {
  const macRegex = /\b[0-9a-f]{1,2}([\.:-])(?:[0-9a-f]{1,2}\1){4}[0-9a-f]{1,2}\b/
  return !!macRegex.exec(id_no)
}

export const buttonSwitch = (item, button, tr, key) => {
  switch (button.type) {
    case 'Upgrade_Scanner_Agent':
        const idIsMac = matchMac(item.Id_No)
        if(idIsMac){
          return <button type="button" className={`btn ${button.class}`} onClick={e => button.onClick(item[button.extractKey])} key={`${tr}-${key}`}>Update</button>
        }
      break;
    case 'Edit-Power-Cut-Order':
      if(item.Status !== 'Closed'){
        return <button type="button" className={`btn ${button.class}`} onClick={e => button.onClick(item[button.extractKey])} key={`${tr}-${key}`}>Edit</button>
      }
      break;
    case 'Delete':
      return(
        <button type="button" className={`${button.class}`} onClick={e => button.onClick(item[button.extractKey])} key={`${tr}-${key}`}>
          <span className="AdminViewBody-button-span">
            <div className="css-1cvxpvr">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr">
                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
              </svg>
            </div>
          </span>
        </button>
      )
      break
    case 'Edit':
      return(
        <button type="button" className={`${button.class}`} onClick={e => button.onClick(item[button.extractKey])} key={`${tr}-${key}`}>
          <span className="AdminViewBody-button-span">
            <div className="css-1cvxpvr">
              <i className="fas fa-edit" aria-hidden="true"></i>
            </div>
          </span>
        </button>
      )
    default:
      return <button type="button" className={`btn ${button.class}`} onClick={e => button.onClick(item[button.extractKey])} key={`${tr}-${key}`}>{button.type}</button>
  }
}

import { setSuccessMessage } from '../constants/actions';
export const setSuccessMessageAction = (message) => {
  return dispatch =>{
    dispatch(sendSuccessMessageSuccess(message))
    setTimeout(() => {
        dispatch(sendSuccessMessageSuccess(""))
    }, 5000)
  }
}

const sendSuccessMessageSuccess = (message) => ({
  type: setSuccessMessage,
  payload: message
})

import { postNewPodConstant } from '../constants/actions'
import service from '../services/clientService';
import { setErrorMessageAction } from './setErrorMessageAction';

export const postNewPodAction  = (body, token, client, region_id) =>{
  return async(dispatch) =>{
    try{
      const result = await service.postNewPod(body, token, client, region_id);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(postNewPodSuccess(result))
    }
    catch(e){
      console.log(e)
    }
  }
}

const postNewPodSuccess = (result) => ({
  type: postNewPodConstant,
  payload: result
})

import React from 'react'
import InfoTables from '../InfoTables'
import "bootstrap/dist/css/bootstrap.min.css";



const AdminDatabasesSection = ({ databases, columns, buttons  }) => {
  return(
    <div className="card">
      <div className="card-header">
        <strong>System Databases</strong>
      </div>
      <div className="card-body">
        <InfoTables
          data={databases}
          columns={columns}
          buttons={buttons}
        />
      </div>
    </div>
  )
}

export default AdminDatabasesSection

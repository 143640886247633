export default {
  delivery: [
    {
      severity: 0,
      count: 144
    },
    {
      severity: 1,
      count: 78
    },
    {
      severity: 2,
      count: 9
    },
    {
      severity: 3,
      count: 6
    },
    {
      severity: 4,
      count: 2
    },
    {
      severity: 5,
      count: 0
    },
    {
      severity: 6,
      count: 0
    },
    {
      severity: 7,
      count: 0
    },
    {
      severity: 8,
      count: 0
    },
    {
      severity: 9,
      count: 0
    }
  ],
  action: [
    {
      severity: 0,
      count: 0
    },
    {
      severity: 1,
      count: 0
    },
    {
      severity: 2,
      count: 1
    },
    {
      severity: 3,
      count: 8
    },
    {
      severity: 4,
      count: 2
    },
    {
      severity: 5,
      count: 16
    },
    {
      severity: 6,
      count: 7
    },
    {
      severity: 7,
      count: 16
    },
    {
      severity: 8,
      count: 2
    },
    {
      severity: 9,
      count: 5
    }
  ],
  cnc: [
    {
      severity: 0,
      count: 4
    },
    {
      severity: 1,
      count: 576
    },
    {
      severity: 2,
      count: 166
    },
    {
      severity: 3,
      count: 507
    },
    {
      severity: 4,
      count: 129
    },
    {
      severity: 5,
      count: 18
    },
    {
      severity: 6,
      count: 17
    },
    {
      severity: 7,
      count: 16
    },
    {
      severity: 8,
      count: 3
    },
    {
      severity: 9,
      count: 5
    }
  ],
  installation: [
    {
      severity: 0,
      count: 0
    },
    {
      severity: 1,
      count: 5062
    },
    {
      severity: 2,
      count: 561
    },
    {
      severity: 3,
      count: 474
    },
    {
      severity: 4,
      count: 163
    },
    {
      severity: 5,
      count: 9
    },
    {
      severity: 6,
      count: 17
    },
    {
      severity: 7,
      count: 16
    },
    {
      severity: 8,
      count: 3
    },
    {
      severity: 9,
      count: 5
    }
  ],
  none: [
    {
      severity: 0,
      count: 18601
    },
    {
      severity: 1,
      count: 941
    },
    {
      severity: 2,
      count: 30
    },
    {
      severity: 3,
      count: 126
    },
    {
      severity: 4,
      count: 0
    },
    {
      severity: 5,
      count: 0
    },
    {
      severity: 6,
      count: 0
    },
    {
      severity: 7,
      count: 0
    },
    {
      severity: 8,
      count: 0
    },
    {
      severity: 9,
      count: 0
    }
  ]
};

import React from 'react';
import './style.css'


const ExportData = ({ onClick }) => {
    return(
      <div className="adc_exportData-div">
        <select onClick={e => onClick(e)}>
          <option>Export data</option>
          <option value="xlsx">As xlsx</option>
        </select>
      </div>
    )
}

export default ExportData

import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { highchartOptions } from './functions'



const SectionHistoryGraph = ({data, title}) => {
  const options = highchartOptions(data, title)
  return(
      <HighchartsReact
        containerProps={{id: 'data-chart'}}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
  );
}


export default SectionHistoryGraph

import React from 'react';
import './style.css';
import ListPageSizes from '../../Components/ListPageSizes'
import Pager from '../Pager'


const MinerCommentSection = ({ comments, pageSize, currentPageSize, maxPage, onPageClick, currentPage, changePageSize,  showBody, onMinimize, addComment, orderBy, acending, onOrderClick }) => {
  return(
    <div className="adc_mapCommentSection-outerContainer">
      <div className="adc_minerCommandsHeaderDiv">
          <div className="adc_minerCommandsHeader">
            <i className="fa fa-comment"/>
            <span>Comment Section</span>
          </div>
          <div className="adc_minerCommandsButtonDiv">
            <button type="button" className="btn btn-box-tool"  onClick={e => onMinimize()}>
              {showBody?(<i className="fa fa-minus"/>):
                (<i className="fa fa-plus"/>)}
            </button>
          </div>
        </div>
        {showBody?(
          <div className="adc_commentSection-innerDiv">
            <ListPageSizes
              pageSize={pageSize}
              currentPageSize={currentPageSize}
              onPageSizeChange={changePageSize}
            />
            <table
              className="table table-bordered table-striped dataTable no-footer"
              cellSpacing="0"
              cellPadding='0'
              border='0'
              width="100%">
              <thead>
                <tr>
                  <th>Comment</th>
                  <th className="clickable" onClick={e => onOrderClick('Username')}>
                  {orderBy == 'Username'?(acending?(<i className="fa fa-sort-down"/>):(<i className="fa fa-sort-up"/>)):(<i className="fa fa-sort"/>)}Username</th>
                  <th className="clickable" onClick={e => onOrderClick('Date')}>{orderBy == 'Date'?(acending?(<i className="fa fa-sort-down"/>):(<i className="fa fa-sort-up"/>)):(<i className="fa fa-sort"/>)}Date</th>
                </tr>
              </thead>
              <tbody>
                {comments.length == 0?(
                  <tr>
                    <td valign="top" className="adc_commentTable-empty" colSpan={3}>
                      No data available
                    </td>
                  </tr>
                ):(
                  comments.map((item) => (
                    <tr>
                      <td>{item.Comment}</td>
                      <td>{item.Username}</td>
                      <td>{item.Date}</td>
                    </tr>
                  )))
                }
              </tbody>
            </table>
            <Pager
              onPageClick={onPageClick}
              pageSize={pageSize}
              currentPage={currentPage}
              maxPage={maxPage}
              currentPageSize={currentPageSize}
              onPageSizeChange={changePageSize}
            />
            <div className="adc_commentTable-commentDiv">
              <button type="button" className="adc_commentTable-addComment" onClick={e => addComment()}>
                <i className="fa fa-comment"/>
                Add comment
              </button>
            </div>
          </div>
        ):(<></>)}

    </div>
  )
}
export default MinerCommentSection

import { getHashrateRoute, rootRoute } from '../constants/routes'
const hashrateService = () => {
  const getHashrate = (token, customer, time, region_id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${getHashrateRoute}/${customer}/region/${region_id}?type=${time}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          type: time,
          data: data.success.data,
          api_version: data.api_version,
          customer: customer,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      hashrate: [],
    }));
  }
  const getMinerHistory = (token, customer, mac_address) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${getHashrateRoute}/miner/${mac_address}/${customer}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      hashrate: [],
    }));
  }
  const getSectionHistory = (token, customer, macs) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({macs: macs})
    }
    return fetch(`${getHashrateRoute}/section/${customer}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      hashrate: [],
    }));
  }
  const getDailyRevenue  = (token, customer, region_id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/revenue/day/${customer}/region/${region_id}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      hashrate: [],
    }));
  }
  const getMonthlyRevenue  = (token, customer, region_id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/revenue/month/${customer}/region/${region_id}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      hashrate: [],
    }));
  }
  return {
    getHashrate,
    getMinerHistory,
    getMinerHistory,
    getDailyRevenue,
    getMonthlyRevenue
  }
}

export default hashrateService();

import service from '../services/rackService';
import { removeRackFromPodConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const removeRackFromPodAction = (token, customer, id, body) => {
  return async(dispatch) => {
    try{
      const result = await service.removeRackFromPod(token, customer, id, body);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(success(result))
    }
    catch(err){
      console.log(err)
    }
  }
}
const success = (result) => ({
  type: removeRackFromPodConstant,
  payload: result
})

import React from 'react'
import './style.css'
import { coloring_switch, format_value } from './functions'
const InfoPannel = ({data, columns}) => {
  return(
    <div className="InfoPannel-container">
      <table className="list-table">
        <tbody>
        {data.map((item, i) => (
          <tr key={i}>
            {columns.map((col, y) => (
              item.hasOwnProperty('is_link')?(
                col.field === 'Value'?(
                  <td onClick={e => window.open(`http://${item[col.field]}`, "_blank", 'noopener')} key={y}>
                    <span data-tooltip="Link To Miner" data-tooltip-position="top" style={{padding: 0, cursor: 'pointer'}}>
                      {item[col.field]}
                    </span>
                  </td>
                ):(
                  item.hasOwnProperty("Dynamic_Coloring")?(
                    coloring_switch(item[col.field], y)
                  ):(
                    <td key={y}>
                      {format_value(item[col.field])}
                    </td>
                  )
                )
              ):(
                item.hasOwnProperty("Dynamic_Coloring")?(
                  coloring_switch(item[col.field], y)
                ):(
                  <td key={y}>
                    {format_value(item[col.field])}
                  </td>
                )
              )
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}

export default InfoPannel

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { minerView } from '../../helper/tabs'
import { clearErrorLogsAction } from '../../redux/actions/clearErrorLogsAction'
import { getErrorLogsAction } from '../../redux/actions/getErrorLogsAction'

import './style.css'

class MinerErrorLogView extends React.Component {
  componentDidMount = () => {
    if(this.props.errorLogs.status !== 'pending'){
      this.props.getErrorLogsAction(this.props.token, this.props.match.params.name, this.props.match.params.miner)
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  render(){
    const columns = [
      {field: 'errorCode', value: 'Error Code'},
      {field: 'cause', value: 'Error Cause'},
      {field: 'time', value: 'Error Time'}
    ]
    return(
      <div className="MinerView-container">
        <TabHeader
          logo={
            <i class="fab fa-btc fa-5x" ></i>
          }
          title="Miner Details"
          subtitle="Detailed miner info, user comments, repairs & more"
          tabs={minerView}
          active="Error Log"
          onClick={this.onClick}
        />
        <div className="MinerView-content">
          <ViewBody
            data={this.props.errorLogs.data}
            columns={columns}
            list
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  errorLogs: props.errorLogsReducer

})

export default connect(mapStateToProps, {
  clearErrorLogsAction,
  getErrorLogsAction
})(MinerErrorLogView)

import {
  getHouseSubnetConstant,
  postHouseSubnetConstant,
  deleteHouseSubConstant,
  clearHouseSubnetConstant
} from '../constants/actions'

const defaultState = {
  ok: false,
  msg: '',
  api_version: 1.1,
  house: '',
  connections: []
}
export default(state = defaultState, action ) => {
  switch (action.type) {
    case clearHouseSubnetConstant:
      return {
        ok: false,
        msg: '',
        api_version: 1.1,
        house: '',
        connections: []
      }
    case getHouseSubnetConstant:
      if(action.payload.ok){
        return action.payload
      }
      else{
        const s1 = JSON.parse(JSON.stringify(state));
        s1.ok = false;
        s1.msg = action.payload.msg
        return s1;
      }
    case postHouseSubnetConstant:
      if(action.payload.ok){
        return action.payload;
      }
      else{
        const s2 = JSON.parse(JSON.stringify(state));
        s2.ok = false;
        s2.msg = action.payload.msg;
        return s2;
      }
    case deleteHouseSubConstant:
      if(action.payload.ok){
        return action.payload
      }
      else{
        const s3 = JSON.parse(JSON.stringify(state))
        s3.ok = false;
        s3.msg = action.payload.msg;
        return s3;
      }
    default:
      return state;
  }
}

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { minerView } from '../../helper/tabs'
import { getMinerCommentsAction } from '../../redux/actions/getMinerCommentsAction'
import { postCommentAction } from '../../redux/actions/postCommentAction'
import { clearCommentsAction } from '../../redux/actions/clearCommentsAction'

import './style.css'

class MinerCommentView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      modalOpen: false,
      comment: ''
    }
  }
  onAdd = () => {
    const { comment } = this.state
    if(comment !== ''){
        this.props.postCommentAction(this.props.token, {comment}, this.props.match.params.miner, this.props.match.params.name, 'Miner_Details')
    }
    this.setState({
      modalOpen: false,
      comment: ''
    })
  }
  componentDidMount = () => {
    this.props.getMinerCommentsAction(this.props.token, this.props.match.params.miner, this.props.match.params.name, 1, this.props.comments.page_size, this.props.comments.orderBy, this.props.comments.acending)
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  render(){
    const columns = [
      {field: 'Username', value: 'Username'},
      {field: 'Comment', value: 'Comment'},
      {field: 'Date', value: 'Date'}]

    const addCommentField = [
      {Name: 'Comment', PropertyName: 'comment', Value: this.state.comment, Type: 'textarea', Options: []}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="MinerView-container">
        <TabHeader
          logo={
            <img className="tab-view-image" src={require("../../images/MinerDetailsIcon.png")}/>
          }
          title="Miner Details"
          subtitle="Detailed miner info, user comments, repairs & more"
          tabs={minerView}
          active="Comment"
          onClick={this.onClick}
        />
        <div className="MinerView-content">
          <ViewBody
            data={this.props.comments.data}
            columns={columns}
            createtext="New comment"
            list
            onCreate={e => this.setState({modalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
        <FormfieldSection
          title="Add comment"
          fields={addCommentField}
          addButton={true}
          buttonText="Add"
          onAddClick={this.onAdd}
          onInputChange={e => onInputChange(e, this)}
          action="edit_alters"
        />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  comments: props.minerCommentsReducer,
  minerStore: props.minerReducer,
})

export default connect(mapStateToProps, {
  getMinerCommentsAction,
  postCommentAction,
  clearCommentsAction
})(MinerCommentView)

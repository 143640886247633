import {
  getMinerStatusOverviewConstant
} from '../constants/actions'

const defaultState = {
  ok: false,
  api_version: 1.1,
  msg: '',
  offline: 0,
  onlineMiners: 0,
  totalMiners: 0,
  favoriteMiners: 0,
  current_hashrate: 0,
  high_performance: 0,
  none_type: 0,
  low_performance: 0,
  offlineMiners24Hours: [],
  offlineMiners24HoursPlus: [],
  idle: 0,
  sleep: 0,
  maintenance: 0,
  deleted: 0,
}


export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case getMinerStatusOverviewConstant:
      if(action.payload.ok){
        return action.payload
      }
      return newState
    default:
      return state
  }
}

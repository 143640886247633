import { closeResponsePanelConstant } from '../constants/actions'


export const closeResponsePanelAction = () => {
  return dispatch => {
    dispatch(success())
  }
}

const success = () => ({
  type: closeResponsePanelConstant
})

import React from 'react'
import { connect } from 'react-redux'
import { getPodsAction } from '../../redux/actions/getPodsAction'
import PodListItem from '../../Components/PodsListItem'
import { getFavItemsAction } from '../../redux/actions/getFavItemsAction';
import { getHousesAction } from '../../redux/actions/getHousesAction';
import { postFavItemsAction } from '../../redux/actions/postFavItemsAction';
import { deleteFavItemsAction } from '../../redux/actions/deleteFavItemsAction';
import { updateClientAction } from '../../redux/actions/updateClientAction'
import './style.css';

class HouseView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      client:'',
      subnet:'',
      house:'',
      pods: [],
      houseName: '',
      minimizedElements: []
    }
  }
  componentDidMount = async () =>{
    if(this.props.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name);
    }
    this.props.getHousesAction(this.props.userStore.result, this.props.match.params.name);

    this.setState({client: this.props.match.params.name, subnet: this.props.match.params.subnet, house: this.props.match.params.house})
    const token = this.props.userStore.result;
    await this.props.getPodsAction(token,this.props.match.params.house, this.props.match.params.name);
    this.setState({pods: this.props.podStore.pods})
  }
  componentDidUpdate = () => {
      const { house } = this.state;
      if(house !== this.props.match.params.house){
        this.componentDidMount();
      }
  }
  //Add/Delete pods to and from the favorite database.
  addPodToFav = (podName, linkname, fav, ID) => {
      let houseName = this.props.houseStore.houses.filter((item) => item.ID == this.props.match.params.house)
      if(ID === null){
        var newObj = {
          Linkname: `${podName} - ${houseName[0].Name}`,
          Link: linkname,
          Type: 'Pod'
        }
        this.props.postFavItemsAction(this.props.userStore.result, newObj);
      }
      else{
        this.props.deleteFavItemsAction(this.props.userStore.result, ID);
      }
  }
  toggleMiniMaxi = (element) => {
    let { minimizedElements } = this.state;
    if(minimizedElements.indexOf(element) == -1){
      minimizedElements.push(element)
    }
    else{
      minimizedElements = minimizedElements.filter((item) => item !== element);
    }
    this.setState({minimizedElements})
  }
  render() {
    const {pods, client, subnet, house, houseName, minimizedElements} = this.state;
    return (
        <div className="houseViewContainer">
          <h1>{houseName}</h1>
        {this.props.podStore.pods.map((item, key) => {
          if(item !== null){
            let hash = item.Hash_Readable !== "NaN undefined"? item.Hash_Readable: 0;
              return <PodListItem
                        key={key}
                        podId={item.ID}
                        podName={item.Name}
                        totalMiners={item.totalMiners}
                        offlineMiners={item.offlineMiners}
                        performance={item.performance}
                        invalidTypes={item.invalidTypes}
                        hashrate={hash}
                        clientName={client}
                        subnetId={subnet}
                        houseId={house}
                        favItems={this.props.favItemStore}
                        addPodToFav={this.addPodToFav}
                        zeroHashMiners={item.zeroHashMiners}
                        overHeatMiners={item.overHeatMiners}
                        onMinimize={this.toggleMiniMaxi}
                        minimized={minimizedElements.indexOf(key) != -1}
                        element={key}
                      />
            }})
        }
        </div>
    )
  }
}
const mapStateTopProps = (props) => ({
  userStore: props.userReducer,
  podStore: props.podsReducer,
  subnetStore: props.subnetReducer,
  houseStore: props.houseReducer,
  favItemStore: props.favItemReducer
})
export default connect(mapStateTopProps, {
  getFavItemsAction,
  deleteFavItemsAction,
  postFavItemsAction,
  getPodsAction,
  updateClientAction,
  getHousesAction
})(HouseView)

//

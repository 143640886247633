import React from 'react'
import FilterSectionModel from '../FilterSectionModel'
import FilterMinerListDefaultChildren from '../FilterMinerListDefaultChildren'
import './style.css'

const FilterMinerListSection = ({filter, onFilterInputChange, minerTypes, clearFilter}) => {
  return(
    <FilterSectionModel
      children={
        <FilterMinerListDefaultChildren
          filter={filter}
          onFilterInputChange={onFilterInputChange}
          minerTypes={minerTypes}
          clearFilter={clearFilter}
        />
      }
    />

  )
}
export default FilterMinerListSection

import {
  createNewDatabaseConstant
} from '../constants/actions'
import service from '../services//adminService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const addDatabaseAction = (token, body) => {
  return async(dispatch) => {
    const result = await service.addDatabase(token, body)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result, body.database_name))
  }
}
const success = (result, database) => ({
  type: createNewDatabaseConstant,
  payload: { result, database }
})

import React from 'react'
import './style.css'


const FilterSectionModel = ({children}) => {
  return(
    <div className="adc_filterSection-outerContainer">
      <p>Filter Section</p>
      <div className="adc_filterSection-innerContainer">
        {children}
      </div>
    </div>
  )
}
export default FilterSectionModel

export const flagViewQueryList = [
  {getKey: 'FlagStatus',       filterValue: 'filterFlagStatus', valueType: 'list'},
  {getKey: 'FlagType',         filterValue: 'filterFlagType',   valueType: 'list'},
  {getKey: 'FlagDate',         filterValue: 'filterFlagDate',   valueType: 'bool', boolObj: {24: true, total: false}},
  {getKey: 'Scanner',          filterValue: 'filterScanner',    valueType: 'list'},
  {getKey: 'Rack',             filterValue: 'filterRack',       valueType: 'list'},
  {getKey: 'House',            filterValue: 'filterHouse',      valueType: 'list'},
  {getKey: 'POD',              filterValue: 'filterPOD',        valueType: 'list'},
  {getKey: 'Boards',           filterValue: 'filterBoards',     valueType: 'list'},
  {getKey: 'Status',           filterValue: 'filterStatus',     valueType: 'list'},
  {getKey: 'Type',             filterValue: 'filterType',       valueType: 'list'},
  {getKey: 'Mac',              filterValue: 'filterMac',        valueType: 'text'},
  {getKey: 'Worker',           filterValue: 'filterWorker',     valueType: 'text'},
  {getKey: 'Ip',               filterValue: 'filterIp',         valueType: 'text'},
  {getKey: 'FW',               filterValue: 'filterFW',         valueType: 'text'},
  {getKey: 'fromHeat',         filterValue: 'fromHeat',         valueType: 'number'},
  {getKey: 'toHeat',           filterValue: 'toHeat',           valueType: 'number'},
  {getKey: 'fromCurrentSpeed', filterValue: 'fromCurrentSpeed', valueType: 'number'},
  {getKey: 'toCurrentSpeed',   filterValue: 'toCurrentSpeed',   valueType: 'number'},
  {getKey: 'fromAvgSpeed',     filterValue: 'fromAvgSpeed',     valueType: 'number'},
  {getKey: 'toAvgSpeed',       filterValue: 'toAvgSpeed',       valueType: 'number'},
  {getKey: 'fromMissingAsic',  filterValue: 'fromMissingAsic',  valueType: 'number'},
  {getKey: 'toMissingAsic',    filterValue: 'toMissingAsic',    valueType: 'number'},
  {getKey: 'fromPerformance',  filterValue: 'fromPerformance',  valueType: 'number'},
  {getKey: 'toPerformance',    filterValue: 'toPerformance',    valueType: 'number'},
  {getKey: 'showDeleted',      filterValue: 'showDeleted',      valueType: 'bool', boolObj: {yes: true, no: false}},
]
export const minersViewQueryList = [
  {getKey: 'Scanner',          filterValue: 'filterScanner',      valueType: 'list'},
  {getKey: 'Rack',             filterValue: 'filterRack',         valueType: 'list'},
  {getKey: 'House',            filterValue: 'filterHouse',        valueType: 'list'},
  {getKey: 'POD',              filterValue: 'filterPOD',          valueType: 'list'},
  {getKey: 'Boards',           filterValue: 'filterBoards',       valueType: 'list'},
  {getKey: 'Status',           filterValue: 'filterStatus',       valueType: 'list'},
  {getKey: 'Type',             filterValue: 'filterType',         valueType: 'list'},
  {getKey: 'Mac',              filterValue: 'filterMac',          valueType: 'text'},
  {getKey: 'TuningPreset',     filterValue: 'filterTuningPreset', valueType: 'text'},
  {getKey: 'TuningStatus',     filterValue: 'filterTuningStatus', valueType: 'text'},
  {getKey: 'Worker',           filterValue: 'filterWorker',       valueType: 'text'},
  {getKey: 'Ip',               filterValue: 'filterIp',           valueType: 'text'},
  {getKey: 'FW',               filterValue: 'filterFW',           valueType: 'text'},
  {getKey: 'SerialNumber',               filterValue: 'filterSerialNumber', valueType: 'text'},
  {getKey: 'fromHeat',         filterValue: 'fromHeat',           valueType: 'number'},
  {getKey: 'toHeat',           filterValue: 'toHeat',             valueType: 'number'},
  {getKey: 'fromCurrentSpeed', filterValue: 'fromCurrentSpeed',   valueType: 'number'},
  {getKey: 'toCurrentSpeed',   filterValue: 'toCurrentSpeed',     valueType: 'number'},
  {getKey: 'fromAvgSpeed',     filterValue: 'fromAvgSpeed',       valueType: 'number'},
  {getKey: 'toAvgSpeed',       filterValue: 'toAvgSpeed',         valueType: 'number'},
  {getKey: 'fromMissingAsic',  filterValue: 'fromMissingAsic',    valueType: 'number'},
  {getKey: 'toMissingAsic',    filterValue: 'toMissingAsic',      valueType: 'number'},
  {getKey: 'fromPerformance',  filterValue: 'fromPerformance',    valueType: 'number'},
  {getKey: 'toPerformance',    filterValue: 'toPerformance',      valueType: 'number'},
  {getKey: 'showDeleted',      filterValue: 'showDeleted',        valueType: 'bool', boolObj: {yes: true, no: false}},
]

import service from '../services/rackService'
import { getRackProfileByIDConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'

export const getRackProfileByIDAction = (token, customer, id) => {
  return async(dispatch) => {
    try{
      const result = await service.getRackProfileByID(token, customer, id);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(success(result))
    }
    catch(err){
      console.log(err)
    }
  }
}
const success = (result) => ({
  type: getRackProfileByIDConstant,
  payload: result
})

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import TuningAttributesTable from '../../Components/TuningAttributesTable';
import { onTabHeaderClick } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { getTuningProfilesByIDAction } from '../../redux/actions/tuning/getTuningProfilesByIDAction';
import { postTuningProfilesFirmwareConnectionsAction } from '../../redux/actions/tuning/postTuningProfilesFirmwareConnectionsAction';
import { postTuningProfilesAttributesConnetionsAction } from '../../redux/actions/tuning/postTuningProfilesAttributesConnetionsAction';
import { postTuningProfilesMinerTypesConnectionsAction } from '../../redux/actions/tuning/postTuningProfilesMinerTypesAction';
import { getFirmwareVersionsAction } from '../../redux/actions/firmware/getFirmwareVersionsAction';
import { getTuningAttributesAction } from '../../redux/actions/tuning/getTuningAttributesAction';
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { deleteTuningProfilesAttributesConnetionsAction } from '../../redux/actions/tuning/deleteTuningProfilesAttributesConnetionsActions';
import { deleteTuningProfilesFirmwaresConnectionsAction } from '../../redux/actions/tuning/deleteTuningProfilesFirmwaresAction';
import { deleteTuningProfilesMinerTypesConnectionsAction } from '../../redux/actions/tuning/deleteTuningProfilesMinerTypesConnectionsAction';
import './style.css'

/**
 * getTuningProfilesByID                 -> /api/tuning/profiles/:id/:customer
 * postTuningProfileFirmwareConnections  -> /api/tuning/profiles/firmwares/:customer
 * postTuningProfileAttributesConnetions -> /api/tuning/profiles/attributes/:customer
 * postTuningProfileMinerTypeConnections -> /api/tuning/profiles/minertypes/:customer
 * postTuningProfileSubnetsConnections   -> /api/tuning/profiles/subnet/:customer
 * postTuningProfilePodConnections       -> /api/tuning/profiles/pods/:customer
 * getFirmwareVersions                   -> already implemented  getFirmwareVersionsAction
 * getTuningAttributes                   -> already implemented  getTuningAttributesAction
 * 
 * 
 * ToDo:
 * I've created a new component called TuningAttributesTable
 * Now we need to alter that component so we can reuse it between views 
 * Use that component in the addAttributeModal, if no attribute is pickable give an explanation
 * saying that there are no more attributes to be picked for the given firmwares
 */

class TurningProfileDetailView extends React.Component{
    constructor(props){
        super(props)
        this.state = {
          addFirmwaresModalOpen: false,
          addAttributesModalOpen: false,
          addMinerTypesModalOpen: false,
          firmwares: [],
          minertypes: [],
          attributes: [],
          attributesValueList: []
        }
    }
    componentDidMount = () => {
      this.props.getTuningProfilesByIDAction(this.props.token, this.props.match.params.id, this.props.match.params.name)
      this.props.getFirmwareVersionsAction(this.props.token)
      this.props.getTypesAction(this.props.token, this.props.match.params.name)
      this.props.getTuningAttributesAction(this.props.token)
    }
    onClick = (tab) => {
        onTabHeaderClick(tab, this.props.history)
    }
    onAddFirmwares = () => {
      const { firmwares } = this.state
      if(firmwares.length === 0){
        alert("Pick atleast 1 item")
        return
      }
      const body = {
        List: firmwares.map((item) => ({Tuning_Profiles_ID: this.props.match.params.id, Firmware_Versions_ID: item.value}))
      }
      this.props.postTuningProfilesFirmwareConnectionsAction(body, this.props.token, "detail_view", this.props.match.params.name)
      this.setState({
        firmwares: [],
        addFirmwaresModalOpen: false
      })
    }
    onAddMinerTypes = () => {
      const { minertypes } = this.state
      if(minertypes.length === 0){
        alert("Pick atleast 1 item")
        return
      }
      const body = {
        List: minertypes.map((item) => ({Tuning_Profiles_ID: this.props.match.params.id, Miner_Types_ID: item.value}))
      }
      this.props.postTuningProfilesMinerTypesConnectionsAction(body, this.props.token, "detail_view", this.props.match.params.name)
      this.setState({
        minertypes: [],
        addMinerTypesModalOpen: false
      })
    }
    onAddAttributes = () => {
      const { attributes, attributesValueList } = this.state
      if(attributes.length === 0){
        alert("Pick atleast 1 item")
        return
      }
      //{"List": [{"Tuning_Profiles_ID": 3, "Tuning_Attributes_ID": 2}, {"Tuning_Profiles_ID": 6, "Tuning_Attributes_ID": 3}]}
      const body = {
        List: attributesValueList.map((item) => ({Tuning_Profiles_ID: this.props.match.params.id, Tuning_Attributes_ID: item.ID, Value: item.Value}))
      }
      this.props.postTuningProfilesAttributesConnetionsAction(body, this.props.token, "detail_view", this.props.match.params.name)
      this.setState({
        attributes: [],
        attributesValueList: [],
        addAttributesModalOpen: false
      })
    }
    onPickChange = (list) => {
      if(list.length > 0 && list[0].property){
        this.setState({
          [list[0].property]: list
        })
      }
    }
    onBack = () => {
      this.props.history.goBack()
    }
    onInputChange = (value, type, itemId=null) => {
      let { attributes, attributesValueList } = this.state
      switch(type){
          case 'valueRange':
              attributesValueList = attributesValueList.filter((item) => item.ID !== itemId)
              attributesValueList.push({ID: itemId, Value: value[0]})
              this.setState({
                  attributesValueList
              })
              break
          case 'valueList':
              attributesValueList = attributesValueList.filter((item) => item.ID !== itemId)
              attributesValueList.push({ID: itemId, Value: value.value})
              this.setState({
                  attributesValueList
              })
              break
          case 'attributes':
              console.log(value)
              let newAttributes = attributes.filter((item) => item.ID !== value.ID)
              if(attributes.length == 0 || newAttributes.length === attributes.length){
                  if(value.Value_Type === 'range'){
                      const split = value.Value_List[0].Value.split('/')
                      const rangeSplit = split[0].split('-')
                      let mean = (parseInt(rangeSplit[0]) + parseInt(rangeSplit[1])) / 2
                      if(split[1] === '1' ){
                          mean = Math.floor(mean)
                      }
                      if(split[1] === '10'){
                          mean = Math.round(mean / 10) * 10
                      }
                      attributesValueList.push({ID: value.ID, Value: mean})
                  }
                  newAttributes.push(value)
              }
              else{
                  attributesValueList = attributesValueList.filter((item) => item.ID !== value.ID)
              }
              this.setState({
                  "attributes": newAttributes,
                  attributesValueList
              })
              break
          case 'name':
              this.setState({
                  [type]: value.target.value
              })
              break
          default:
              this.setState({
                  [type]: value
              })
      }
  }
  onDeleteFirmware = (id) => {
    if(window.confirm(`Are you sure you want to delete this firmware ?`)){
      const body = {
        Tuning_Profiles_ID: this.props.match.params.id,
        Firmware_Versions_ID: id
      }
      this.props.deleteTuningProfilesFirmwaresConnectionsAction(body, this.props.token, this.props.match.params.name)
    }
  }
  onDeleteAttribute = (id) => {
    if(window.confirm(`Are you sure you want to delete this attribute ?`)){
      const body = {
        Tuning_Profiles_ID: this.props.match.params.id,
        Tuning_Attributes_ID: id
      }
      this.props.deleteTuningProfilesAttributesConnetionsAction(body, this.props.token, this.props.match.params.name)
    }
  }
  onDeleteMinerType = (id) => {
    if(window.confirm(`Are you sure you want to delete this minertype ?`)){
      const body = {
        Tuning_Profiles_ID: this.props.match.params.id,
        Miner_Types_ID: id
      }
      this.props.deleteTuningProfilesMinerTypesConnectionsAction(body, this.props.token, this.props.match.params.name)
    }
  }
    render(){
        const { userData } = this.props
        const { attributesValueList, attributes } = this.state
        const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
        const currentDatabase = userData.databases.filter((item) => item.Database_Name === this.props.match.params.name)
        const currentDatabaseAccess = userData.databases.length === 0? 0: currentDatabase[0].Level
        const modalStyle = {
            modal: {
                width: '100%',
                background: '#141619'
            }
        }
        const pannelColumns = [
          {field: 'Name', value: 'Name'},
          {field: 'Value', value: 'Value'}
        ]
        let pannelData = this.props.tuningStore.profiles.length > 0?[
          {Name: "Name", Value: this.props.tuningStore.profiles[0].Name},
          {Name: 'Attributes', Value: this.props.tuningStore.profileAttributes.length},
          {Name: 'Firmwares', Value: this.props.tuningStore.profileFirmwares.length},
          {Name: 'Miner Types', Value: this.props.tuningStore.profileMinertypes.length},
        ]:([])
        const attributesColumns = [
          {field: "Display_Name", value: "Name"},
          {field: "Main_Group", value: "Main Group"},
          {field: "Sub_Group", value: "Sub Group"},
          {field: "Value", value: "Value"}
        ]
        const firmwaresColumns = [
          {field: 'Firmware_Vendor', value: 'Vendor'},
          {field: 'Firmware_Version', value: 'Version'}]
        const minerTypesColumns = [
          {field: 'Manufacturer', value: 'Manufacturer'},
          {field: 'Name', value: 'Name'}]
        const currentFirmwaresIDs = this.props.tuningStore.profileFirmwares.map((item) => item.ID)
        const pickFirmwares = this.props.firmwareStore.versions.filter((item) => currentFirmwaresIDs.indexOf(item.ID) == -1)
        const currentMinerTypesIDs = this.props.tuningStore.profileMinertypes.map((item) => item.ID)
        const pickMinertypes = this.props.minerTypesStore.types.filter((item) => currentMinerTypesIDs.indexOf(item.ID) == -1)
        const currentAttributesID = this.props.tuningStore.profileAttributes.map((item) => item.ID)
        let pickAttributes = this.props.tuningStore.attributes.filter((item) => currentAttributesID.indexOf(item.ID) == -1)
        const addFirmwaresField = [
          {Name: 'Pick Firmwares', PropertyName: 'firmwares', Value: this.state.firmwares, Type: 'multi-select', Options: pickFirmwares.map((item ,i) => ({label: `${item.Firmware_Vendor} - ${item.Version}`, value: item.ID, property: "firmwares"}))}
        ]
        const addMinerTypesField = [
          {Name: 'Pick Miner Types', PropertyName: 'minertypes', Value: this.state.minertypes, Type: 'multi-select', Options: pickMinertypes.map((item ,i) => ({label: item.Name, value: item.ID, property: "minertypes"}))}
        ]
        const firmwareButtons = currentDatabaseAccess >= 2 ?([{class: 'ViewBody-button-delete', onClick: this.onDeleteFirmware, type: 'Delete', extractKey: 'ID'}]):([])
        const attributesButtons = currentDatabaseAccess >= 2 ?([{class: 'ViewBody-button-delete', onClick: this.onDeleteAttribute, type: 'Delete', extractKey: 'ID'}]):([])
        const minerTypesButtons = currentDatabaseAccess >= 2 ?([{class: 'ViewBody-button-delete', onClick: this.onDeleteMinerType, type: 'Delete', extractKey: 'ID'}]):([])
        if(currentFirmwaresIDs.length !== 0){
          pickAttributes = pickAttributes.filter((item) => {
              const fw = item.Firmwares.filter((item) => currentFirmwaresIDs.indexOf(item.Firmware_Versions_ID) !== -1)
              return fw.length === currentFirmwaresIDs.length
          })
           pickAttributes = pickAttributes.map((item) => {
              if(item.Display_Name === 'Preset'){
                item.Value_List = item.Value_List.filter((i) => currentMinerTypesIDs.length === 1 && i.Miner_Types.map((x) => x.Miner_Types_Name).indexOf(this.props.tuningStore.profileMinertypes[0].Name) !== -1)
              }
              item.Picked = attributes.filter((i) => i.ID === item.ID).length === 1
              return item
          })
          pickAttributes = pickAttributes.map((item) => {
              item.Picked = attributes.filter((i) => i.ID === item.ID).length === 1
              return item
          })
      }
        return(
            <div className="ProfilesView-container">
              <TabHeader
                logo={
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                    <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                  </svg>
                }
                title="Manage account"
                subtitle="Manage buildings, pods, pools & more"
                tabs={userData.level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
                active="Tuning Profiles"
                onClick={this.onClick}
              />
              <div className="ConfigureView-content">
                <ViewBody
                  data={pannelData}
                  onBack={this.onBack}
                  columns={pannelColumns}
                  pannel
                />
                 <ViewBody
                  data={this.props.tuningStore.profileAttributes}
                  columns={attributesColumns}
                  createtext="Add Attributes"
                  list
                  buttons={attributesButtons}
                  onCreate={e => this.setState({addAttributesModalOpen: true})}
                />
                <ViewBody
                  data={this.props.tuningStore.profileFirmwares}
                  columns={firmwaresColumns}
                  createtext="Add Firmwares"
                  list
                  buttons={firmwareButtons}
                  onCreate={e => this.setState({addFirmwaresModalOpen: true})}
                />
                <ViewBody
                  data={this.props.tuningStore.profileMinertypes}
                  columns={minerTypesColumns}
                  createtext="Add Miner Type"
                  list
                  buttons={minerTypesButtons}
                  onCreate={e => this.setState({addMinerTypesModalOpen: true})}
                />
              </div>
              <Modal
                open={this.state.addFirmwaresModalOpen}
                center
                onClose={() => this.setState({ addFirmwaresModalOpen: false })}
                styles={modalStyle}
              >
                <FormfieldSection
                  title="Add Firmware"
                  fields={addFirmwaresField}
                  addButton={true}
                  buttonText="Add"
                  onAddClick={this.onAddFirmwares}
                  onInputChange={this.onPickChange}
                  action="add_profile"
                />
              </Modal>
              <Modal
                open={this.state.addMinerTypesModalOpen}
                center
                onClose={() => this.setState({ addMinerTypesModalOpen: false })}
                styles={modalStyle}
              >
                <FormfieldSection
                  title="Add Miner Types"
                  fields={addMinerTypesField}
                  addButton={true}
                  buttonText="Add"
                  onAddClick={this.onAddMinerTypes}
                  onInputChange={this.onPickChange}
                  action="add_profile"
                />
              </Modal>
              <Modal
                open={this.state.addAttributesModalOpen}
                center
                onClose={() => this.setState({ addAttributesModalOpen: false })}
                styles={modalStyle}
              >
                <TuningAttributesTable
                    pickAttributes={pickAttributes}
                    attributesValueList={attributesValueList}
                    onInputChange={this.onInputChange}
                />
                 <button type="submit" className="ViewBody-button-create" onClick={this.onAddAttributes}>Add Attributes</button>
              </Modal>
            </div>
          )
    }
}

const mapStateToProps = (props) => ({
    token: props.userReducer.result,
    userData: props.userReducer.userData,
    clientStore: props.clientReducer,
    firmwareStore: props.firmwareReducer,
    tuningStore: props.tuningReducer,
    minerTypesStore: props.typeReducer
  })

  export default connect(mapStateToProps, {
    getTuningProfilesByIDAction,
    postTuningProfilesFirmwareConnectionsAction,
    postTuningProfilesAttributesConnetionsAction,
    postTuningProfilesMinerTypesConnectionsAction,
    getFirmwareVersionsAction,
    getTuningAttributesAction,
    getTypesAction,
    deleteTuningProfilesAttributesConnetionsAction,
    deleteTuningProfilesFirmwaresConnectionsAction,
    deleteTuningProfilesMinerTypesConnectionsAction,
  })(TurningProfileDetailView)
  
import React from 'react';
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../FormfieldSection'
import ViewBody from '../ViewBody'
import React_Select from 'react-select'
import {  blueGray, lightGray, darkBoarder } from '../../helper/colors.js'
import './style.css'

const colourStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: blueGray,
    borderColor: darkBoarder,
    color: lightGray,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: lightGray,
      cursor: isDisabled ? 'not-allowed' : 'default'
    }
  }
};

const PickProfileModal= ({ profiles, indexSelected, onInputChange, open, onClose, onSubmit}) => {
  const pannelColumns = [
    {field: 'Name', value: 'Name'},
    {field: 'Value', value: 'Value'}
  ]
  let pannelData = indexSelected !== -1?[
    {Name: "Name", Value: profiles[indexSelected].Name},
    {Name: 'Prefix', Value: profiles[indexSelected].Prefix},
    {Name: 'Postfix', Value: profiles[indexSelected].Postfix},
  ]: []
  const poolsColumns = [
    {field: 'URL', value: 'URL'},
    {field: 'Worker', value: 'Worker'},
    {field: 'Password', value: 'Password'}]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       },
       closeButton: {
         color: '#fff'
       }
     };
     const pickProfile = profiles.map((item, i) => ({
       label: item.Name, value: i
     }))
    return(
      <Modal
        open={open}
        center
        onClose={e => onClose()}
        styles={modalStyle}
      >
      <div className="pickProfileModal-container">
        <span>Select Profile</span>
        <React_Select
          options={pickProfile}
          onChange={onInputChange}
          isSearchable
          autoFocus
          styles={colourStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
            ...theme.colors,
              primary25: 'black',
              neutral0: blueGray,
              primary: 'black',
              neutral10: 'black', // selected value background
              neutral20: lightGray, // arrows
              neutral50: lightGray, // Select... color
              neutral80: lightGray, // color in selected values
            },
          })}
        />
        {indexSelected !== -1?(
          <>
            <ViewBody
              data={pannelData}
              columns={pannelColumns}
              pannel
            />
            <ViewBody
              data={profiles[indexSelected].Pools}
              columns={poolsColumns}
              list
            />
            <div className="Modal-button-container">
              <button className="modal-button" onClick={e => onSubmit()}>
                <span className="ListCommandButtons-button-span">Confirm</span>
              </button>
            </div>
          </>
        ):(<></>)}
      </div>
      </Modal>
    )
}

export default PickProfileModal

import {
  toggleTableColumnConstant,
  getUsersMinerListColumnsConstant,
  updateUsersMinerListColumnsConstant
} from '../constants/actions'


const defaultState = {
  api_version: 1.1,
  userID: '',
  minerList: {
    Serial_Number: {
      Key: "Serial_Number",
      Label: "Serial Number",
      Value: false
    },
    MAC_Address: {
      Key: "MAC_Address",
      Label: "MAC",
      Value: true
    },
    Worker_Name: {
      Key: "Worker_Name",
      Label: "Worker Name",
      Value: true
    },
    Config_Profile_Name: {
      Key: "Config_Profile_Name",
      Label: "Config Profile",
      Value: true
    },
    Status: {
      Key: "Status",
      Label: "Status",
      Value: true
    },
    Location: {
      Key: "Location",
      Label: "Location",
      Value: true
    },
    IP_Address: {
      Key: "IP_Address",
      Label: "IP",
      Value: true
    },
    Max_Heat: {
      Key: "Max_Heat",
      Label: "Max Heat",
      Value: true
    },
    Total_Speed: {
      Key: "Total_Speed",
      Label: "Total Speed",
      Value: true
    },
    Avg_Speed: {
      Key: "Avg_Speed",
      Label: "Avg Speed",
      Value: true
    },
    Missing_Asic: {
      Key: "Missing_Asic",
      Label: "Missing Asic",
      Value: true
    },
    Miner_Type: {
      Key: "Miner_Type",
      Label: "Miner Type",
      Value: true
    },
    Firmware: {
      Key: "Firmware",
      Label: "Firmware",
      Value: true
    },
    Performance:  {
      Key: "Performance",
      Label: "Performance",
      Value: true
    },
    Rejection_Rate:  {
      Key: "Rejection_Rate",
      Label: "Rejection Rate",
      Value: true
    },
    Pool_Diff_One: {
      Key: "Pool_Diff_One",
      Label: "Diff One",
      Value: true
    },
    Pool_Diff_Two: {
      Key: "Pool_Diff_Two",
      Label: "Diff Two",
      Value: true
    },
    Pool_Diff_Three: {
      Key: "Pool_Diff_Three",
      Label: "Diff Three",
      Value: true
    },
    Pool_LSDiff_One: {
      Key: "Pool_LSDiff_One",
      Label: "LSDiff One",
      Value: true
    },
    Pool_LSDiff_Two: {
      Key: "Pool_LSDiff_Two",
      Label: "LSDiff Two",
      Value: true
    },
    Pool_LSDiff_Three: {
      Key: "Pool_LSDiff_Three",
      Label: "Diff Three",
      Value: true
    },
    Update_Time: {
      Key: "Update_Time",
      Label: "Last Seen",
      Value: true
    },
    Pool_One: {
      Key: "Pool_One",
      Label: "Pool 1",
      Value: true
    },
    Pool_Two: {
      Key: "Pool_Two",
      Label: "Pool 2",
      Value: true
    },
    Pool_Three: {
      Key: "Pool_Three",
      Label: "Pool 3",
      Value: true
    },
    Heat_One: {
      Key: "Heat_One",
      Label: "Heat 1",
      Value: true
    },
    Heat_Two: {
      Key: "Heat_Two",
      Label: "Heat 2",
      Value: true
    },
    Heat_Three: {
      Key: "Heat_Three",
      Label: "Heat 3",
      Value: true
    },
    Speed_One: {
      Key: "Speed_One",
      Label: "Speed 1",
      Value: true
    },
    Speed_Two: {
      Key: "Speed_Two",
      Label: "Speed 2",
      Value: true
    },
    Speed_Three: {
      Key: "Speed_Three",
      Label: "Speed 3",
      Value: true
    },
    Asic_One: {
      Key: "Asic_One",
      Label: "Asic 1",
      Value: true
    },
    Asic_Two: {
      Key: "Asic_Two",
      Label: "Asic 2",
      Value: true
    },
    Asic_Three: {
      Key: "Asic_Three",
      Label: "Asic 3",
      Value: true
    },
    HW_Error_One: {
      Key: "HW_Error_One",
      Label: "HW Err 1",
      Value: true
    },
    HW_Error_Two: {
      Key: "HW_Error_Two",
      Label: "HW Err 2",
      Value: true
    },
    HW_Error_Three: {
      Key: "HW_Error_Three",
      Label: "HW Err 3",
      Value: true
    },
    Freq_One: {
      Key: "Freq_One",
      Label: "Freq 1",
      Value: true
    },
    Freq_Two: {
      Key: "Freq_Two",
      Label: "Freq 2",
      Value: true
    },
    Freq_Three: {
      Key: "Freq_Three",
      Label: "Freq 3",
      Value: true
    },
    Inlet_Temp_One: {
      Key: "Inlet_Temp_One",
      Label: "Inlet 1",
      Value: true
    },
    Inlet_Temp_Two: {
      Key: "Inlet_Temp_Two",
      Label: "Intel 2",
      Value: true
    },
    Inlet_Temp_Three: {
      Key: "Inlet_Temp_Three",
      Label: "Inlet 3",
      Value: true
    },
    Fan_One: {
      Key: "Fan_One",
      Label: "Fan1",
      Value: true
    },
    Fan_Two: {
      Key: "Fan_Two",
      Label: "Fan2",
      Value: true
    },
    Fan_Three: {
      Key: "Fan_Three",
      Label: "Fan3",
      Value: true
    },
    Fan_Four: {
      Key: "Fan_Four",
      Label: "Fan4",
      Value: true
    },
    Uptime: {
      Key: "Uptime",
      Label: "Uptime",
      Value: true
    },
    Power_Consumption: {
      Key: "Power_Consumption",
      Label: "Wattage",
      Value: true,
    },
    Tuning_Preset: {
      Key: "Tuning_Preset",
      Label: "Tuning Preset",
      Value: false
    },
    Tuning_Status: {
      Key: "Tuning_Status",
      Label: "Tuning Status",
      Value: false
    }
  }
}

export default(state = defaultState, action) => {
  switch (action.type) {
    case getUsersMinerListColumnsConstant:
    case updateUsersMinerListColumnsConstant:
      if(action.payload.ok){
        let loadColumnsState = JSON.parse(JSON.stringify(state))
        let item = action.payload.data.length > 0? action.payload.data[0]: null
        if(item){
          for(var key in item){
            if(key === 'User_ID'){
              loadColumnsState.userID = item[key]
            }
            if(loadColumnsState.minerList.hasOwnProperty(key)){
              loadColumnsState.minerList[key].Value = Boolean(item[key])
            }
          }
        }
        return loadColumnsState
      }
      return state
    case toggleTableColumnConstant:
        let toggleState = JSON.parse(JSON.stringify(state))
        const currentValue = toggleState[`${action.payload.tableType}`][`${action.payload.column}`]
        if(currentValue === true){
          toggleState[`${action.payload.tableType}`][`${action.payload.column}`] = {
            Value: false,
            Key: action.payload.column,
            Label: action.payload.column
          }
        }
        else if(currentValue === false){
          toggleState[`${action.payload.tableType}`][`${action.payload.column}`] = {
            Value: true,
            Key: action.payload.column,
            Label: action.payload.column
          }
        }
        else{
          toggleState[`${action.payload.tableType}`][`${action.payload.column}`]['Value'] = !toggleState[`${action.payload.tableType}`][`${action.payload.column}`]['Value']
        }
      return toggleState;
    default:
      return state
  }
}

import { getReportDataConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/clientService'

export const getReportDataAction = (token, startDate, endDate, customer, properitesString) => {
  return async(dispatch) => {
    const result = await service.getReportData(token, startDate, endDate, customer, properitesString)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch({
      type: getReportDataConstant,
      payload: result
    })
  }
}

import { inventoryRoute } from '../constants/routes'
const service = () => {
  const getInventory = (token, customer, region_id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${inventoryRoute}/${customer}/region/${region_id}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          inventory: data.success.data,
          api_version: data.api_version,
          logs: []
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          inventory: [],
          logs: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      inventory: [],
      logs: []
    }));
  }
  const getFarmInventory = (token, customer) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${inventoryRoute}/farm/${customer}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          farmInventory: data.success.data,
          logs: [],
          api_version: data.api_version
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          farmInventory: [],
          logs: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      farmInventory: [],
      logs: []
    }));
  }
  const postFarmInventory = (token, customer, body) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${inventoryRoute}/farm/${customer}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          farmInventory: data.success.data,
          logs: [],
          api_version: data.api_version
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          farmInventory: [],
          logs: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      farmInventory: [],
      logs: []
    }));
  }
  const putFarmInventory = (token, customer, body) => {
    var requestOptions = {
      method: 'PUT',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${inventoryRoute}/farm/${customer}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          farmInventory: data.success.data,
          logs: [],
          api_version: data.api_version
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          farmInventory: [],
          logs: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      farmInventory: [],
      logs: []
    }));
  }
  const adjustInventory = (token, customer, type, body, region_id) => {
    var requestOptions = {
      method: 'PUT',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${inventoryRoute}/adjust/${customer}/region/${region_id}?type=${type}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          inventory: data.success.data,
          logs: [],
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          inventory: [],
          logs: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      inventory: [],
      logs: []
    }));
  }
  const getInventoryHistory = (token, customer, pageSize, pageNumber, orderBy, acending, searchValue, region_id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${inventoryRoute}/history/${customer}/region/${region_id}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          inventory: [],
          logs: data.success,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          inventory: [],
          logs: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      inventory: [],
      logs: []
    }));
  }
  const getInventoryByType = (token, customer, type) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${inventoryRoute}/type/${type}/${customer}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          inventory: data.success.data,
          logs: [],
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          inventory: [],
          logs: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      inventory: [],
      logs: []
    }));
  }
  return {
    getInventory,
    adjustInventory,
    getInventoryHistory,
    getInventoryByType,
    getFarmInventory,
    postFarmInventory,
    putFarmInventory
  }
}

export default service()

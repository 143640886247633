import React from 'react';
import PropTypes from 'prop-types';
import './style.css'
const ConfigureHouseForm = ({houseName, onInputChange, onFormSubmit}) => {
  return(
    <div className="form-style-5">
      <form action="">
        <fieldset>
          <legend><span className="number">1</span>Building Info</legend>
          <input type="text" id="houseName" name="houseName" placeholder="Bulding Name *" defaultValue={houseName} className="form-control" onInput={e => onInputChange(e)}/>
        </fieldset>
        <input type="button" id="password" value="submit" onClick={e => onFormSubmit(e)}/>
      </form>
    </div>
  )
}

ConfigureHouseForm.propTypes = {
  houseName:PropTypes.string.isRequired,
  onInputChange:PropTypes.func.isRequired,
  onFormSubmit:PropTypes.func.isRequired,
}
export default ConfigureHouseForm;


/*

  <form action="" className="add-froms form from-horizontal">
    <div className="form-group">
        <label htmlFor="houseName">House Name</label>
        <input type="text" id="houseName" name="houseName" value={houseName} className="form-control" onInput={e => onInputChange(e)}/>
    </div>
    <div className="from-group">
        <label htmlFor="subnetName">Subnet Name</label>
        <select className="form-control form-control-sm" name="subnetName" value={subnetName} onInput={e => onInputChange(e)}>
          {createOption(subnets)}
        </select>
    </div>
    <div className="form-group">
        <input type="button" id="password" value="submit" className="btn btn-submit" onClick={e => onFormSubmit(e)}/>
    </div>
  </form>
  */

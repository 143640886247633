import {
  postScannerConstant
} from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'



export const postScannerAction = (token, body, pageSize, pageNumber, orderBy, acending, searchValue) => {
  return async(dispatch) => {
    const result = await service.postScanner(token, body, pageSize, pageNumber, orderBy, acending, searchValue)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}
const success = (result) => ({
  type: postScannerConstant,
  payload: result
})

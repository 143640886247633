import React from 'react';
import './style.css'
import { NavLink } from 'react-router-dom'
const OfflineMinersTable = ({ data, onCommentClick, commentsOpen, writeCommentsOpen, submitComment, onItemClick, onSeenClick, currentFilter, reducerStatus }) => {
  return(
    <div className="offlineMinersTable-div">
      <table className="offlineMinersTable" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th className="offlineMiners-th">Customer</th>
            <th className="offlineMiners-th">House</th>
            <th className="offlineMiners-th">Pod</th>
            <th className="offlineMiners-th">Scanner</th>
            <th className="offlineMiners-th">Location</th>
            <th className="offlineMiners-th">Last Update</th>
            <th className="offlineMiners-th">Actions</th>
          </tr>
        </thead>
        <tbody>
        {data.length > 0?(data.map((item, key) =>(
          <>
            <tr key={key}>
              <td className="offlineMiners-td" onClick={() => onItemClick(key)}>
                <div className="hasComment-div">
                  {item.Comments.length > 0?(
                    <i class="fa fa-comments" aria-hidden="true"></i>
                  ):(<React.Fragment key={`${key}-has-comment`}></React.Fragment>)}
                  <NavLink className="red-color" to={`/client/${item.customer}`}>
                    {item.customer}
                  </NavLink>
                </div>
              </td>
              <td className="offlineMiners-td red-color" onClick={() => onItemClick(key)}>
                <NavLink className="red-color" to={`/client/${item.customer}/house/${item.House_ID}`}>
                  {item.House_Name}
                </NavLink>
              </td>
              <td className="offlineMiners-td red-color" onClick={() => onItemClick(key)}>
                <NavLink className="red-color" to={`/client/${item.customer}/house/${item.House_ID}/pod/${item.Pod_ID}`}>
                  {item.Pod_Name}
                </NavLink>
              </td>
              <td className="offlineMiners-td red-color" onClick={() => onItemClick(key)}>
                <NavLink className="red-color" to={`/client/${item.customer}/subnet/${item.Subnet_ID}`}>
                  {item.Subnet_Name}
                </NavLink>
              </td>
              <td className="offlineMiners-td red-color" onClick={() => onItemClick(key)}>
                <NavLink className="red-color" to={`/client/${item.customer}/subnet/${item.Subnet_ID}/miner/${item.MAC_Address}`}>
                  {item.Location}
                </NavLink>
              </td>
              <td className="offlineMiners-td red-color" onClick={() => onItemClick(key)}>
                {item.Last_Update_Readable}
              </td>
              <td className="offlineMiners-td-actions">
                  {writeCommentsOpen.indexOf(key) !== -1?(
                    <span data-tooltip="Close Comment" data-tooltip-position="bottom">
                      <i className="fa fa-comments" aria-hidden="true" onClick={() => onCommentClick(key)}/>
                    </span>
                  ):(
                    <span data-tooltip="Add Comment" data-tooltip-position="bottom">
                      <i className="fa fa-comments" aria-hidden="true" onClick={() => onCommentClick(key)}/>
                    </span>
                  )}
                  {currentFilter === 'Seen'?(
                    <span data-tooltip="Un Mark Seen" data-tooltip-position="bottom">
                      <i className="fa fa-check" aria-hidden="true" onClick={() => onSeenClick(key)}/>
                    </span>
                  ):(
                    <span data-tooltip="Mark Seen" data-tooltip-position="bottom">
                      <i className="fa fa-check" aria-hidden="true" onClick={() => onSeenClick(key)}/>
                    </span>
                  )}
              </td>
            </tr>
            {commentsOpen.indexOf(key) !== -1?(
              <>
                <tr key={`comment-${key}`}>
                  <td colSpan="5" className="offlineMiners-comment">Comment</td>
                  <td className="offlineMiners-comment">User</td>
                  <td className="offlineMiners-comment">Date</td>
                </tr>
                {item.Comments?(
                  item.Comments.map((item, key2) => (
                    <tr key={`comment-${key}-${key2}`}>
                      <td colSpan="5" className="offlineMiners-comment">
                        {item.Comment}
                      </td>
                      <td className="offlineMiners-comment">{item.Username}</td>
                      <td className="offlineMiners-comment">{item.Date}</td>
                    </tr>
                  ))
                ):(<React.Fragment key={`comment-${key}`}></React.Fragment>)}
              </>
            ):(<React.Fragment key={`comment-open-${key}`}></React.Fragment>)}
            {writeCommentsOpen.indexOf(key) !== -1?(
              <tr key={`textarea-${key}`}>
                <td colSpan="6">
                  <textarea className="commentArea" id={`comment${key}`} rows="2" placeholder="enter a comment"></textarea>
                </td>
                <td>
                  <button className="btn btn-primary" onClick={e => submitComment(key)}>Submit</button>
                </td>
              </tr>
            ):(<React.Fragment key={`textarea-${key}`}></React.Fragment>)}
          </>
        ))):(
          !reducerStatus ?(
            <tr key={`loadingData`}>
              <td rowSpan="2" colSpan="7" className="adc_customerStatusTable-empty-td">
                <div className="adc_customerStatusTable-empty">
                  <label>Loading data</label>
                  <div className="adc_minerView-loader"></div>
                </div>
              </td>
            </tr>
          ):(
            <tr key="no-data-availabe">
              <td rowSpan="2" colSpan="7" className="adc_customerStatusTable-empty-td">
                <div className="adc_customerStatusTable-empty">
                  <label>No data availabe</label>
                </div>
              </td>
            </tr>
          )
        )}
        </tbody>
      </table>
    </div>
  )
}
export default OfflineMinersTable

import { getPowerCutOrderMinersByOrderIDConstant, setPowerCutMinersActionIdConstant } from '../constants/actions'
import service from '../services/powerCutOrderService'



export const getPowerCutOrderMinersByOrderIDAction = (token, orderId, pageNumber, pageSize, orderBy, acending, statusList, databases) => {
  return async(dispatch) => {
    const actionId = Math.random() * (100000000 - 1) + 1;
    dispatch({type: setPowerCutMinersActionIdConstant, payload: actionId})
    const result = await service.getPowerCutOrderMinersByOrderID(token, orderId, pageNumber, pageSize, orderBy, acending, statusList, databases)
    dispatch(success(result, actionId))
  }
}

const success = (result, actionId) =>({
  type: getPowerCutOrderMinersByOrderIDConstant,
  payload: result,
  actionId
})

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import Header from './Components/Header'

import logo from './logo.svg';
import './App.css';

import DashboardView from './Views/DashboardView';
import LoginView from './Views/LoginView';

export class Login extends Component {
  render() {
    return (
      <div className="flexContainer">
        <div className="header">
          <Header/>
        </div>
        <Switch>
          <Route exact path="/login" component={LoginView} />
        </Switch>
      </div>
    )
  }
};

export default Login;

import React from 'react';
import './style.css';
import { formatBytes, commaSeparatorFormat } from '../../helper/functions'


const RegionScannerContainer = ({ id, name, online, offline, totalMiners, idle, totalSpeed, flagDetected24, flagPending24, flagResolved24, flagChecked24, flagDetectedTotal, flagPendingTotal, flagResolvedTotal, flagCheckedTotal, onItemClick  }) => {
  return(
    <tr>
      <td>{name}</td>
      <td style={totalMiners !== 0?({fontWeight: '900', fontSize: '15px'}):({fontWeight: 'normal'})}><span>{commaSeparatorFormat(totalMiners)}</span></td>
      <td style={online !== 0?({fontWeight: '900', fontSize: '15px'}):({fontWeight: 'normal'})}><span>{commaSeparatorFormat(online)}</span></td>
      <td style={idle !== 0?({fontWeight: '900', fontSize: '15px'}):({fontWeight: 'normal'})}><span>{commaSeparatorFormat(idle)}</span></td>
      <td style={offline !== 0?({fontWeight: '900', fontSize: '15px'}):({fontWeight: 'normal'})}><span>{commaSeparatorFormat(offline)}</span></td>
      <td style={totalSpeed !== 0.0?({fontWeight: '900', fontSize: '15px'}):({fontWeight: 'normal'})}><span>{formatBytes(totalSpeed)}</span></td>
      <td style={flagDetectedTotal !== 0?({fontWeight: '900', fontSize: '15px'}):({fontWeight: 'normal'})}><span className="onClick" onClick={e => onItemClick(name, "Detected")}>{commaSeparatorFormat(flagDetectedTotal)}</span></td>
      <td style={flagPendingTotal !== 0?({fontWeight: '900', fontSize: '15px'}):({fontWeight: 'normal'})}> <span  className="onClick" onClick={e => onItemClick(name, "Pending")}>{commaSeparatorFormat(flagPendingTotal)}</span></td>
      <td style={flagResolvedTotal !== 0?({fontWeight: '900', fontSize: '15px'}):({fontWeight: 'normal'})}> <span  className="onClick" onClick={e => onItemClick(name, "Resolved")}>{commaSeparatorFormat(flagResolvedTotal)}</span></td>
      <td style={flagCheckedTotal !== 0?({fontWeight: '900', fontSize: '15px'}):({fontWeight: 'normal'})}><span  className="onClick" onClick={e => onItemClick(name, "Checked")}>{commaSeparatorFormat(flagCheckedTotal)}</span></td>
    </tr>
  )
}

const RegionDataContainer = ({ regionId, name, online, offline, totalMiners, idle, totalSpeed, scanners, displayScanners, onHeadRowClick, singleRegion, flagDetected24, flagPending24, flagResolved24, flagChecked24, flagDetectedTotal, flagPendingTotal, flagResolvedTotal, flagCheckedTotal, onItemClick }) => {
  return(
    <div className="region_data_table_wrapper">
      <table
        className="region_data_table"
      >
        <thead>
          {singleRegion?(
            <tr className={`region_data_table_header_row`}>
              <th className="region_header_normal">Region: {name}</th>
              <th className="region_header_normal">Miners: {commaSeparatorFormat(totalMiners)}</th>
              <th className="region_header_good">Online: {commaSeparatorFormat(online)}</th>
              <th className="region_header_bad">Idle: {commaSeparatorFormat(idle)}</th>
              <th className="region_header_bad">Offline: {commaSeparatorFormat(offline)}</th>
              <th className="region_header_normal">Hashrate: {formatBytes(totalSpeed)}</th>
              <th className="region_header_normal">Detected Flags: {commaSeparatorFormat(flagDetectedTotal)}</th>
              <th className="region_header_normal">Pending Flags: {commaSeparatorFormat(flagPendingTotal)}</th>
              <th className="region_header_normal">Resolved Flags: {commaSeparatorFormat(flagResolvedTotal)}</th>
              <th className="region_header_normal">Checked Flags: {commaSeparatorFormat(flagCheckedTotal)}</th>
            </tr>
          ):(
            <tr className={`region_data_table_header_row region_data_table_header_clickable`} onClick={e => onHeadRowClick(regionId)}>
              <th className="region_header_normal">Region: {name}</th>
              <th className="region_header_normal">Miners: {commaSeparatorFormat(totalMiners)}</th>
              <th className="region_header_good">Online: {commaSeparatorFormat(online)}</th>
              <th className="region_header_bad">Idle: {commaSeparatorFormat(idle)}</th>
              <th className="region_header_bad">Offline: {commaSeparatorFormat(offline)}</th>
              <th className="region_header_normal">Hashrate: {formatBytes(totalSpeed)}</th>
              <th className="region_header_normal">Detected Flags: {commaSeparatorFormat(flagDetectedTotal)}</th>
              <th className="region_header_normal">Pending Flags: {commaSeparatorFormat(flagPendingTotal)}</th>
              <th className="region_header_normal">Resolved Flags: {commaSeparatorFormat(flagResolvedTotal)}</th>
              <th className="region_header_normal">Checked Flags: {commaSeparatorFormat(flagCheckedTotal)}</th>
            </tr>
          )}
        </thead>
        {displayScanners.indexOf(regionId) !== -1?(
          <tbody>
            <tr>
              <th className="region_header_normal">Scanner</th>
              <th className="region_header_normal">Miners</th>
              <th className="region_header_good">Online</th>
              <th className="region_header_bad">Idle</th>
              <th className="region_header_bad">Offline</th>
              <th className="region_header_normal">Hashrate</th>
              <th className="region_header_normal">Detected Flags</th>
              <th className="region_header_normal">Pending Flags</th>
              <th className="region_header_normal">Resolved Flags</th>
              <th className="region_header_normal">Checked Flags</th>
            </tr>
            {scanners.map((item) => (
              <RegionScannerContainer
                flagDetected24={item.Flag_Detected_24}
                flagPending24={item.Flag_Pending_24}
                flagResolved24={item.Flag_Resolved_24}
                flagChecked24={item.Flag_Checked_24}
                flagDetectedTotal={item.Flag_Detected_Total}
                flagPendingTotal={item.Flag_Pending_Total}
                flagResolvedTotal={item.Flag_Resolved_Total}
                flagCheckedTotal={item.Flag_Checked_Total}
                name={item.Name}
                totalMiners={item.Total_Miners}
                online={item.Online_Miners}
                idle={item.Idle_Miners}
                offline={item.Offline_Miners}
                totalSpeed={item.Total_Speed}
                onItemClick={onItemClick}
              />
            ))}
          </tbody>
        ):(<></>)}
      </table>
    </div>
  )
}

export default RegionDataContainer


// <th className="region_header_normal">Detected Flags 24h: {commaSeparatorFormat(flagDetected24)}</th>
// <th className="region_header_normal">Pending Flags 24h: {commaSeparatorFormat(flagPending24)}</th>
// <th className="region_header_normal">Resolved Flags 24h: {commaSeparatorFormat(flagResolved24)}</th>
// <th className="region_header_normal">Checked Flags 24h: {commaSeparatorFormat(flagChecked24)}</th>

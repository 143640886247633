import { deleteTypeConstant } from '../constants/actions'
import typeService from '../services/typeService'
import { setErrorMessageAction } from './setErrorMessageAction';

export const deleteTypeAction = (id, token, client) => {
  return async(dispatch) => {
    const result = await typeService.deleteTypes(id, token, client)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(success(result))
  }
}


const success = (result) => ({
  type: deleteTypeConstant,
  payload: result
})

import React from 'react'
import './style.css'

const RevenueCard = ({ value, text }) => {
  if(value){
    return(
      <div className="clientview-revenue-card">
        <div className="card-content">
          <div className="small-numbers">
            <strong>{text}</strong>
          </div>
          <div className="revenue-total-container">
            <span>{value.toFixed(3)}</span>
            <span className="bitcoin-span">&#8383;</span>
          </div>
        </div>
      </div>
    )
  }
  return(
    <div className="clientview-revenue-card">
      <div className="card-content">
        <div className="small-numbers">
          <strong>{text}</strong>
        </div>
        <div className="revenue-total-container">
          <span>Loading....</span>
        </div>
      </div>
    </div>
  )
}

export default RevenueCard

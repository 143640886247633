import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { adminView } from '../../helper/tabs'
import './style.css'


class ReportsView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      addModalOpen: false
    }
  }
  componentDidMount = () => {

  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  render(){
    return(
      <div className="adminView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Server Admin"
          subtitle="Manage scanner, databases & more"
          tabs={adminView}
          active="Reports"
          onClick={this.onClick}
        />
      </div>
    )
  }
}

export default ReportsView

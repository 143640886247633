import React from 'react';
import './style.css';

const createOption = (startingNum, size, defaultID, data = null) => {
  let returnValue = []
  if(data == null){
    for(; startingNum <= size; startingNum++){
      returnValue.push(<option key={startingNum}>{startingNum}</option>)
    }
  }
  else{
    data.map((item,key) => {
      if(item.hasOwnProperty("pod")){
          returnValue.push(<option key={key} value={`${item.ID}/${item.pod.Name}`}>{item.pod.Name}</option>)
      }
      else{
          returnValue.push(<option key={key} value={`${item.ID}/${item.Name}`}>{item.Name}</option>)
      }
      return
    })
  }
  return returnValue;
}

const MapMinerSection = ({ buildings, defaultBuilding, defaultBuildingName, pods, defaultPod, defaultPodName, submit, rackNum, shelfNum, minerNum, mac, onInputChange, onMapItemInput }) => {
  return(
    <div className="adc_mapMinerSection-outerContainer">
      <div className="adc_mapMinerSection-headerDiv">
        <div className="adc_minerCommandsHeader">
          <i className="fa fa-tasks"/>
          <span>Map location</span>
        </div>
      </div>
        <div className="adc_mapMinerSection-innerContainer">
          <div className="adc_deviceMapping-single-inputDiv">
            <label>MAC</label>
            <input type="text" name="MAC_Address" value={mac} className="adc_deviceMapping-single-input" onInput={e => onMapItemInput(e)} disabled/>
          </div>
          <div className="adc_deviceMapping-single-inputDiv">
            <label>Building</label>
            <select name="houseName" className="adc_deviceMapping-single-input" onChange={e => onInputChange(e)} value={`${defaultBuilding}/${defaultBuildingName}`}>
              {createOption(0, 0, defaultBuilding, buildings)}
            </select>
          </div>
          <div className="adc_deviceMapping-single-inputDiv">
            <label>Pod</label>
            <select name="podName" className="adc_deviceMapping-single-input" onChange={e => onInputChange(e)} value={`${defaultPod}/${defaultPodName}`}>
              {createOption(0, 0, defaultPod, pods)}
            </select>
          </div>
          <div className="adc_deviceMapping-single-inputDiv">
            <label>Rack</label>
            <input type="number" name="rackNum" className="adc_deviceMapping-single-input" value={rackNum} onChange={e => onMapItemInput(e)}/>
          </div>
          <div className="adc_deviceMapping-single-inputDiv">
            <label>Shelf</label>
            <input type="number" name="shelfNum" className="adc_deviceMapping-single-input" value={shelfNum} onChange={e => onMapItemInput(e)}/>
          </div>
          <div className="adc_deviceMapping-single-inputDiv">
            <label>Miner</label>
            <input type="number" name="minerNum" className="adc_deviceMapping-single-input" value={minerNum} onChange={e => onMapItemInput(e)}/>
          </div>
          <div className="adc_deviceMapping-single-inputDiv">
            <button type="button" className="adc_deviceMapping-single-button" onClick={e => submit(e)}>
              <span>Submit</span>
            </button>
          </div>
        </div>
    </div>
  )
}


export default MapMinerSection

import { clearSystemLogsConstant } from '../constants/actions'


export const clearSystemLogsAction = () => {
  return dispatch => {
    dispatch({
      type: clearSystemLogsConstant
    })
  }
}

import React from 'react';
import './style.css'

function changeLabel(label){
  let newLabel = `00${label}`
  if(newLabel.length !== 3){
    return newLabel.substring(newLabel.length-3, newLabel.length);
  }
  return newLabel
}
function returnStatus(status, render) {
  switch (status) {
    case 'Offline':
      return <span className="adc_minerDetailsTable_status-offline">{render}</span>
      break;
    case 'Online':
      return <span className="adc_minerDetailsTable_status-online">{render}</span>
      break;
    case 'Recovery':
      return <span className="adc_minerDetailsTable_status-recovery">{render}</span>
      break;
    case 'API_DOWN':
      return <span className="adc_minerDetailsTable_status-apiDown">{render}</span>
      break;
    case 'Unknown':
    case 'Missing_PSU':
    case 'Missing_Fan':
    case 'Missing_CTRL_Board':
      return <span className="adc_minerDetailsTable_status-unknown">{render}</span>
      break;
    default:
      return <span className="adc_minerDetailsTable_status-online">{render}</span>
  }
}
const formatBytes = (bytes, decimals = 2)  => {
    if (bytes === 0) return '0 H';
    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    let sizes = ['H', 'KH', 'MH', 'GH', 'TH', 'PH', 'EH', 'ZH', 'YH'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
const MinerDetailsTable_v1_2 = ({ MAC_Address, Worker_Name, Status, Total_Speed, Avg_Speed, Front_Fan, Back_Fan, Cards, Last_Update, Location, IP_Address, Label, Deleted }) => {
  let date, time;
  if (Last_Update !== undefined) {
    date = Last_Update.split('T');
    time = date[1].split('.');
  }
  else {
    date = ['', ''];
    time = ['', ''];
  }
  return(
    <div className="table-responive">
      <table id="minerDetails-table" className="table table-bordered table-striped" cellPadding="0" cellSpacing="0" border="0">
        <tbody>
          <tr>
            <td>
              Location
            </td>
            <td><span>{returnStatus(Status, Location)}</span></td>
          </tr>
          <tr>
            <td>Worker Name</td>
            <td><span>{Worker_Name}</span></td>
          </tr>
          <tr>
            <td>Status</td>
            <td><span>{returnStatus(Status, Status)}</span></td>
          </tr>
          <tr>
            <td>MAC Address</td>
            <td>
              <span>{MAC_Address}</span>
            </td>
          </tr>
          <tr>
            <td>IP Address</td>
            <td>
              <span>{IP_Address}</span>
            </td>
          </tr>
          <tr>
            <td>Hashrate</td>
            <td>
              <span>
              Average: {formatBytes(Avg_Speed)} - Current: {formatBytes(Total_Speed)} -
              (per board: {Cards.map((item, i) => {
                if(i !== Cards.length-1) return `${formatBytes(item.Speed)} | `
                return formatBytes(item.Speed)
              })}
              </span>
            </td>
          </tr>
          <tr>
            <td>Temperature</td>
            <td>
              <span>
                {Cards.map((item, i) => {
                  if(i !== Cards.length-1) return `${item.Heat} °C | `
                  return `${item.Heat} °C`
                })}
              </span>
            </td>
          </tr>
          <tr>
            <td>Chip Per Board</td>
            <td>
              <span>
                {Cards.map((item, i) => {
                  if(i !== Cards.length-1) return `${item.Asic_Count} | `
                  return `${item.Asic_Count}`
                })}
              </span>
            </td>
          </tr>
          <tr>
            <td>Fan Speed</td>
            <td>
              <span>
                Front: {Front_Fan} - Back: {Back_Fan}
              </span>
            </td>
          </tr>
          <tr>
            <td>Last Update</td>
            <td>
              <span>
                {date[0]} - {time[0]}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}


export default MinerDetailsTable_v1_2

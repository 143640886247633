import React, { Component } from 'react'
import * as d3 from 'd3'
import './styles.css';
const width = 1000;
const height = 325;
const margin = {
    top: 50,
    right: 150,
    bottom: 50,
    left: 45
};

class D3LineComponent extends Component {
    state = {
        hash: null,
        fill: null,
        overlay: null,
        time: "day",
        xScale: d3.scaleTime().range([margin.left, width - margin.right]),
        yScale: d3.scaleLinear().range([height - margin.bottom, margin.top]),
        lineGenerator: d3.line(),
        line2Generator: d3.line(),
        fillGenerator: d3.area()
    };
    xAxis = d3.axisBottom().scale(this.state.xScale);
    yAxis = d3.axisLeft().scale(this.state.yScale)
        .tickFormat(d => this.formatBytes(d));


    formatBytes = (bytes, decimals = 2)  => {
      if (bytes === 0) return '0 Hash';
      const k = 1000;
      const dm = decimals < 0 ? 0 : decimals;
      let sizes = ['Hash', 'KH', 'MH', 'GH', 'TH', 'PH', 'EH', 'ZH', 'YH'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.data) return null;
        const { data, time} = nextProps;
        const { xScale, yScale, lineGenerator, fillGenerator, line2Generator} = prevState;
        const timeDomain = d3.extent(data, d => d.Date);
        const maxVal = d3.max(data, d => d.Max_Hashrate);
        xScale.domain(timeDomain);
        yScale.domain([0, maxVal]);

        /// get set .curve og þessháttar strax á generatorana
        lineGenerator.x(d => xScale(d.Date));
        lineGenerator.y(d => yScale(d.Min_Hashrate));
        line2Generator.y(d => yScale(d.Max_Hashrate));
        fillGenerator.x(d => xScale(d.Date));
        fillGenerator.y0(height - margin.top);
        fillGenerator.y1(d => yScale(d.Min_Hashrate));
        fillGenerator.y1(d => yScale(d.Max_Hashrate));
        const hash = lineGenerator(data);
        const fill = fillGenerator(data);
        const dataLength = data.length;

        return {hash, dataLength, time, fill};
    }
    componentDidMount(){
      d3.select(".shape").style("display", 'none');
    }
    /// skoða ticks() betur með dynamic data
    componentDidUpdate() {
        if(this.state.time == "day"){
            this.xAxis.tickFormat(d3.timeFormat('%d/%m'));
            this.xAxis.ticks(this.state.dataLength);
        }
        else if(this.state.time == "hour"){
            this.xAxis.tickFormat(d3.timeFormat('%H:%M'));
            this.xAxis.ticks(this.state.dataLength/12);
        }
        else {
            this.xAxis.tickFormat(d3.timeFormat('%H:%M'));
            this.xAxis.ticks();
        }
        const { xScale, yScale } = this.state;
        const data = this.props.data;
        const parseDate = d3.timeFormat('%d/%m-%H:%M');
        const formatBytes = this.formatBytes;
        /// mouse move þarf að vera í function() til þess að geta accessað this.
        d3.select(this.refs.xAxis).call(this.xAxis);
        d3.select(this.refs.yAxis).call(this.yAxis);
        if(data.length > 0){
          d3.select('.overlay')
              .on('mouseout', this.mouseOut)
              .on('mouseover', this.mouseOver)
              .on('mousemove', function(){
                  var mouse = d3.mouse(this);
                  d3.select('.mouse-for-line')
                      .attr("transform", function(d){
                          var xDate = xScale.invert(mouse[0])
                          var bisect = d3.bisector(function(d){ return d.Date;}).right;
                          var idx = bisect(data, xDate, 1, data.length-1);

                          var d0 = data[idx - 1],
                              d1 = data[idx],
                              d = xDate - d0.Date > d1.Date - xDate ? d1 : d0;

                          /// velja texta til að display'a
                          d3.select(this).select(".value-text-min")
                          .text(function() { return `
                            MIN: ${formatBytes(d.Min_Hashrate)}`; } )
                          d3.select(this).select(".value-text-max")
                          .text(function() { return `
                            MAX: ${formatBytes(d.Max_Hashrate)}`; } )
                          d3.select(this).select(".date-text")
                          .text(function() { return parseDate(d.Date); } )
                          /// returna staðsetningu á hring og texta
                          return "translate(" +xScale(d.Date)+","+yScale(d.Max_Hashrate)+")";

                      })
              })
        }
    }

    mouseOut = () => {
        d3.select(".mouse-text").style("display", "none");
        d3.select(".mouse-line").style("display", "none");
        d3.select(".date-text").style("display", "none");
        d3.select(".value-text-min").style("display", "none");
        d3.select(".value-text-max").style("display", "none");
        d3.select(".shape").style("display", 'none');
        d3.select(".path-line").style("stroke-width", 1);
    }

    mouseOver = () =>{
        d3.select(".mouse-text").style("display", null);
        d3.select(".mouse-line").style("display", null);
        d3.select(".date-text").style("display", null);
        d3.select(".value-text-min").style("display", null);
        d3.select(".shape").style("display", null);
        d3.select(".value-text-max").style("display", null);
        d3.select(".path-line").style("stroke-width", 1.5);
        const element = window.document.getElementById('graph-data-line')
        console.log(element)
    }
    render() {
      console.log(this.props)
        return (
            <svg width={"100%"} height={'100%'} className="svg-container">
                <path d={this.state.fill} className="line" fill='lightsteelblue' stroke='none'/>
                <path d={this.state.hash} className="path-line" fill='none' stroke='blue' style={{strokeWidth:1}} id="graph-data-line"/>
                <g>
                    <g ref='xAxis' transform={`translate(0,${height - margin.bottom})`} />
                    <g ref='yAxis' transform={`translate(${margin.left},0)`} />
                </g>
                <g className="mouse-over-effects">
                    <g className="mouse-for-line">
                        {/*<circle className="mouse-circle" r="3" stroke='blue' fill="none" strokeWidth="1px"></circle>*/}
                        <line x1="0" y1="0" x2="0" className="mouse-line" y2="100%" style={{stroke:'#ebebe6',strokeWidth:1}} />
                        <rect className="shape" height="75px" width="175px" transform="translate(-90, 20)"></rect>
                        <text className="value-text-max" transform="translate(-60,60)"/>
                        <text className="value-text-min" transform="translate(-60, 75)"/>
                        <text className="date-text" transform="translate(-60,40)"/>
                    </g>
                    <rect className="overlay" width={"100%"} height={'100%'} fill="none" style={{pointerEvents: "all"}}/>
                </g>
            </svg>
        );
    }
}

export default D3LineComponent;

/*
<g className="focus" style={{display: 'none'}}>
                <circle r="4.5"></circle>
                <text x="9" dy=".35em"></text>
              </g>
              <rect className="overlay" width={width} height={height}
                style={{pointerEvents: "all"}}
                fill="none"
                onMouseOut={this.mouseOut}
                onMouseOver={this.mouseOver}
                //onMouseMove={this.mouseMove}
                /*></rect>*/

/*
                 var xDate = xScale.invert(mouse[0])
                        var bisect = d3.bisector(function(d){ return d.Date;}).right;
                        var idx = bisect(d.value, xDate, 1);
                        var beginning = 0,
                            end = line[0].getTotalLength(),
                            target = null,
                            pos = null;
                        while(true){
                            console.log("line:",line[0].getTotalLength())
                            target = Math.floor((beginning+end)/2)
                            console.log("Target:",target);
                            pos = line[0].getPointAtLength(target);
                            console.log("Position",pos.y);
                            console.log("What is the position here:",pos)
                            if((target ===end || target == beginning) && pos.x !==mouse[0]){
                                break;
                            }
                            if(pos.x > mouse[0]) end = target;
                            else if(pos.x < mouse[0]) beginning = target;
                            else break; // position found
                            }
                        /// velja texta til að display'a
                        d3.select(this).select("text")
                        .text(yScale.invert(pos.y).toFixed(1) + "hz")
                        /// returna staðsetningu á hring og texta
                        return "translate(" +mouse[0]+","+pos.y+")";
    */

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'
import userReducer from './userReducer';
import clientReducer from './clientReducer'
import subnetReducer from './subnetReducer'
import houseReducer from './houseReducer'
import minersReducer from './minersReducer'
import minerReducer from './minerReducer'
import minerLogsReducer from './minerLogsReducer'
import mapMinersReducer from './mapMinersReducer'
import podsReducer from './podsReducer'
import poolReducer from './poolReducer';
import commandReducer from './commandReducer'
import messageReducer from './messageReducer'
import hashrateReducer from './hashrateReducer'
import userLogsReducer from './userLogsReducer';
import historyReducer from './historyReducer';
import favMinerReducer from './favMinerReducer';
import favItemReducer from './favItemReducer';
import typeReducer from './typeReducer';
import pageReducer from './pageReducer'
import maintenanceReducer from './maintenanceReducer'
import houseSubnetReducer from './houseSubnetReducer'
import inventoryReducer from './inventoryReducer'
import accessReducer from './accessReducer'
import alertReducer from './alertReducer'
import reportReducer from './reportReducer'
import heatmapReducer from './heatmapReducer'
import tableColumnsReducer from './tableColumnsReducer'
import minerCommentsReducer from './minerCommentsReducer'
import systemLogsReducer from './systemLogsReducer'
import errorLogsReducer  from './errorLogsReducer'
import adminReducer      from './adminReducer'
import overviewReducer   from './overviewReducer'
import databasesReducer  from './databasesReducer'
import customerPannelReducer from './customerPannelReducer'
import scannerDetailsReducer from './scannerDetailsReducer'
import revenueReducer        from './revenueReducer'
import regionReducer         from './regionReducer'
import rackReducer           from './rackReducer'
import heatmapPersistReducer from './heatmapPersistReducer'
import responseReducer       from './responseReducer'
import flagReducer           from './flagReducer'
import powerCutOrderReducer  from './powerCutOrderReducer';
import firmwareReducer from './firmwareReducer';
import tuningReducer from './tuningReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const historyConfig = {
  key: 'history',
  storage: storage,
  whitelist: ['historyReducer']
}
export default combineReducers({
  userReducer,
  clientReducer,
  subnetReducer,
  houseReducer,
  minersReducer,
  minerReducer,
  minerLogsReducer,
  mapMinersReducer,
  podsReducer,
  poolReducer,
  commandReducer,
  messageReducer,
  hashrateReducer,
  userLogsReducer,
  historyReducer,
  favMinerReducer,
  favItemReducer,
  routing: routerReducer,
  pageReducer,
  houseSubnetReducer,
  typeReducer,
  maintenanceReducer,
  inventoryReducer,
  accessReducer,
  alertReducer,
  reportReducer,
  heatmapReducer,
  tableColumnsReducer,
  minerCommentsReducer,
  systemLogsReducer,
  errorLogsReducer,
  adminReducer,
  overviewReducer,
  databasesReducer,
  scannerDetailsReducer,
  customerPannelReducer,
  revenueReducer,
  regionReducer,
  rackReducer,
  heatmapPersistReducer,
  responseReducer,
  flagReducer,
  powerCutOrderReducer,
  firmwareReducer,
  tuningReducer
});
//historyReducer: persistReducer(historyConfig, historyReducer),

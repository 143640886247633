import React from 'react';
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../FormfieldSection'
import ViewBody from '../ViewBody'
import React_Select from 'react-select'
import {  blueGray, lightGray, darkBoarder } from '../../helper/colors.js'
import './style.css'

const colourStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: blueGray,
    borderColor: darkBoarder,
    color: lightGray,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: lightGray,
      cursor: isDisabled ? 'not-allowed' : 'default'
    }
  }
};

const closeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="28" height="28" viewBox="0 0 36 36" data-testid="close-icon">
    <path 
      d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z">
    </path>
  </svg>
);

const PickTuningProfileModal= ({ profiles, indexSelected, onInputChange, open, onClose, onSubmit}) => {
  const pannelColumns = [
    {field: 'Name', value: 'Name'},
    {field: 'Value', value: 'Value'}
  ]
  let pannelData = indexSelected !== -1?[
    {Name: "Name", Value: profiles[indexSelected].Name},
    {Name: 'Attributes', Value: profiles[indexSelected].Attributes.length}
  ]: []
  const attributesColumns = [
    {field: 'Display_Name', value: 'Name'},
    {field: 'Main_Group', value: 'Main Group'},
    {field: 'Sub_Group', value: 'Sub Group'},
    {field: 'Value', value: "Value"}]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       },
       closeButton: {
         color: '#fff'
       }
     };
     const pickProfile = profiles.map((item, i) => ({
       label: item.Name, value: i
     }))
    return(
      <Modal
        open={open}
        center
        onClose={e => onClose()}
        styles={modalStyle}
        closeIcon={closeIcon}
      >
      <div className="pickProfileModal-container">
        <span>Select Tuning Profile</span>
        <React_Select
          options={pickProfile}
          onChange={onInputChange}
          isSearchable
          autoFocus
          styles={colourStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
            ...theme.colors,
              primary25: 'black',
              neutral0: blueGray,
              primary: 'black',
              neutral10: 'black', // selected value background
              neutral20: lightGray, // arrows
              neutral50: lightGray, // Select... color
              neutral80: lightGray, // color in selected values
            },
          })}
        />
        {indexSelected !== -1?(
          <>
            <ViewBody
              data={pannelData}
              columns={pannelColumns}
              pannel
            />
            <ViewBody
              data={profiles[indexSelected].Attributes}
              columns={attributesColumns}
              list
            />
            <div className="Modal-button-container">
              <button className="modal-button" onClick={e => onSubmit()}>
                <span className="ListCommandButtons-button-span">Confirm</span>
              </button>
            </div>
          </>
        ):(<></>)}
      </div>
      </Modal>
    )
}

export default PickTuningProfileModal

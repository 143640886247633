import { getMinerLogsConstant } from '../constants/actions';
import { getMinerByMacConstant } from '../constants/actions';
import { sortMinerLogsConstant } from '../constants/actions';
const defaultState = {
  ok: false,
  msg: '',
  minersLogs: [],
  max_page: 1,
  api_version: 1.1,
  current_page: 1,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case getMinerLogsConstant:
      const newState = {
        ok: action.payload.ok,
        msg: action.payload.msg,
        minersLogs: action.payload.data,
        max_page: action.payload.max_page,
        current_page: action.payload.current_page,
        page_size: action.payload.page_size,
      }
      return newState;
    case getMinerByMacConstant:
      const newState1 = {
        ok: action.payload.ok,
        msg: action.payload.msg,
        minersLogs: action.payload.data,
        max_page: action.payload.max_page,
        current_page: action.payload.current_page,
        page_size: action.payload.page_size,
      }
      return newState1;
    case sortMinerLogsConstant:
      let returnState = JSON.parse(JSON.stringify(state))
      returnState.minersLogs.sort((a, b) => action.payload.acending?(a[action.payload.property] > b[action.payload.property]) ? 1: -1: (a[action.payload.property] < b[action.payload.property])? 1: -1);
      return returnState;
    default:
        return state;
  }
}

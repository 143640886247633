import { deleteTuningProfilesAttributesConnetionsConstant } from '../../constants/actions'
import service from '../../services/tuningService'




export const deleteTuningProfilesAttributesConnetionsAction = (body, token, customer) => {
    return async(dispatch) => {
        const result = await service.deleteTuningProfilesAttributesConnetions(body, token, customer)
        dispatch(success(result))
    }
}
const success = (result) =>({
  type: deleteTuningProfilesAttributesConnetionsConstant,
  payload: result
})

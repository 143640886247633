import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import NewPowerCutOverview from '../../Components/NewPowerCutOverview'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick } from '../../functions'
import { adminView } from '../../helper/tabs'
import { getPowerCutOrdersAction } from '../../redux/actions/getPowerCutOrdersAction';
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { getHousesAction } from '../../redux/actions/getHousesAction';
import { getAllPodsAction } from '../../redux/actions/getAllPodsAction';
import { clearHousesAction } from '../../redux/actions/clearHousesAction';
import { clearPodsAction } from '../../redux/actions/clearPodsAction';
import { postPowerCutOrderAction } from '../../redux/actions/postPowerCutOrderAction'
import { patchPowerCutOrderStatusAction } from '../../redux/actions/patchPowerCutOrderStatusAction'

class PowerCutOrdersView extends React.Component {
    /** 
     * TODO:
     * Create a list of databases to pick from => lets just hard code this for now
     * Setup new order modal
     *     Displays list of picked databases and relevant information
     *     Displays a dropdown of databases to pick from
     *        When picked we need to get all pods for that database
     *     Let the user pick the unit KW or MW
     *     Let the user pick from pods (optional)
     *     Add database -> after adding the database information should be included in the list above and the user can pick more databases
     * 
     * Setup an edit modal
     *      Here the user can pick from Pending_ON and Close
     *      If status is Pending_OFF/OFF we can pick Pending_ON 
     *      If status is Pending_ON/ON   we can pick Close
     *      IF status is Closed we cannot change the status 
     * **/
    constructor(props){
        super(props)
        this.state = {
            createPowerCutOrderModalOpen: false,
            editPowerCutOrderModalOpen: false,
            editPowerCutOrderID: '',
            editPowerCutStatusOptions: {
                "Pending_OFF": {text: 'Pending ON', value: 'Pending_ON'},
                "OFF":         {text: 'Pending ON', value: 'Pending_ON'},
                "Pending_ON":  {text: 'Closed', value: 'Closed'},
                "ON":          {text: 'Closed', value: 'Closed'},
            },
            editPowerCutOrderStatus: '',
            editPowerCutOrderItem: {
                Current_Power: '',
                Current_Power_Unit: '',
                Cut_Order: '',
                Cut_Order_Unit: '',
                Date: '',
                Description: '',
                ID: '',
                Status: '',
                Username: '',
            },
            databases: ["Metdist", "PEGA_Mining", "HAN"],
            unitPick: ["KW", "MW"],
            newOrderTotalPower: 0,
            newOrderTotalPowerUnit: 'W',
            newOrderDescription: '',
            newOrderDistribution: [],
            newOrderDatabaseName: "",
            newOrderDatabaseCutOrder: 0,
            newOrderDatabaseCutOrderUnit: '',
            newOrderDatabasePods: []
        }
    }_
    componentDidMount = () => {
        const {  result } = this.props.userStore
        const { pageSize, orderBy, acending, searchString, pageNumber } = this.props.powerCutOrderPage;
        this.props.getPowerCutOrdersAction(this.props.userStore.result, pageSize, pageNumber, orderBy, acending, searchString);
    }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
    }
  onPowerCutOrderPageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber === parseInt(this.props.powerCutOrderStore.powerCutOrders.current_page)) return
    const { pageSize, orderBy, acending, searchString } = this.props.powerCutOrderPage;
    if (pageNumber !== 0 && pageNumber <= this.props.powerCutOrderStore.powerCutOrders.max_page) {
      this.props.getPowerCutOrdersAction(this.props.userStore.result, pageSize, pageNumber, orderBy, acending, searchString);
    }
    const store = {
      pageSize,
      pageNumber,
      orderBy,
      acending,
      searchString,
      type: 'powerCutStore'
    }
    this.props.updatePageInfoAction(store);
  }
  editPowerCutOrder = (orderId) =>{
    const order = this.props.powerCutOrderStore.powerCutOrders.data.filter((item) => item.ID == orderId)
    if(order.length > 0){
        this.setState({
            editPowerCutOrderItem: order[0],
            editPowerCutOrderID: orderId,
            editPowerCutOrderModalOpen: true
        })
    }
    else{
        alert("Order ID not found")
    }
  }
  onPowerCutOrderSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber, searchString } = this.props.powerCutOrderPage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getPowerCutOrdersAction(this.props.userStore.result, pageSize, pageNumber, type, acending, searchString);
    const store = {
      searchString,
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'powerCutOrder',
    }
    this.props.updatePageInfoAction(store);
  }
  onPowerCutOrderSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.powerCutOrderPage;
    this.props.getPowerCutOrdersAction(this.props.userStore.result, pageSize, 1, orderBy, acending, e.target.value);
    const store = {
      sarchString: e.target.value,
      pageSize: pageSize,
      pageNumber: 1,
      type: 'powerCutOrder',
      orderBy: orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onInputChange = (e, obj) => {
    if(e.hasOwnProperty("target")){
        switch (e.target.name) {
            case 'newOrderDatabaseName':
                this.props.getHousesAction(this.props.userStore.result, e.target.value, 'All')
                this.props.getAllPodsAction(this.props.userStore.result, e.target.value, 'All')
                break;
            default:
                break;
        }
        obj.setState({[e.target.name]: e.target.value})
    }
    else{
        this.setState({
            newOrderDatabasePods: e
        })
    }    
  }
  onOrderAdd = (action) => {
    let { newOrderDatabaseName, newOrderDatabaseCutOrder, newOrderDatabaseCutOrderUnit, newOrderDatabasePods, newOrderDistribution, newOrderDescription, newOrderTotalPower, newOrderTotalPowerUnit } = this.state
    const { orderBy, acending, pageSize, pageNumber, searchString } = this.props.powerCutOrderPage;
    switch (action) {
        case "add_power_order":
            if(newOrderDistribution.length == 0){
                alert("You need to add atleast on database")
                return
            }
            if(newOrderDescription == '') {
                alert("Please type in a short description for the order")
                return
            }
            const addOrderDistribution = newOrderDistribution.map((item) => {
                item.pods = item.pods.map((item2) => item2.value)
                return item
            })
            const body = {
                total: newOrderTotalPower,
                unit: newOrderTotalPowerUnit,
                description: newOrderDescription,
                distribution: addOrderDistribution
            }
            this.props.postPowerCutOrderAction(this.props.userStore.result, body, pageSize, pageNumber, orderBy, acending, searchString)
            this.setState({
                newOrderDistribution: [],
                newOrderTotalPower: 0,
                newOrderTotalPowerUnit: 'W',
                newOrderDistribution: '',
                createPowerCutOrderModalOpen: false
            })
            this.props.clearHousesAction()
            this.props.clearPodsAction()
            break
        case "add_database_to_order":
            if(newOrderDatabaseName == ''){
                alert("Please pick a database")
                return
            }
            if(newOrderDatabaseCutOrder == 0){
                alert("Cut Amount cannot be 0")
                return
            }
            if(newOrderDatabaseCutOrderUnit == ''){
                alert("Please pick cut unit (KW or MW)")
                return
            }
            const newDatabaseObj = {
                databaseName: newOrderDatabaseName,
                cutOrder: newOrderDatabaseCutOrder,
                cutOrderUnit: newOrderDatabaseCutOrderUnit,
                pods: newOrderDatabasePods
            }
            newOrderDistribution.push(newDatabaseObj)
            this.setState({
                newOrderDistribution: newOrderDistribution,
                newOrderDatabaseName: '',
                newOrderDatabaseCutOrder: 0,
                newOrderDatabaseCutOrderUnit: '',
                newOrderDatabasePods: []
            })
            this.props.clearHousesAction()
            this.props.clearPodsAction()
            
            this.calculateTotalPower(newOrderDatabaseCutOrder, newOrderDatabaseCutOrderUnit, 'positive')
            break;
        default:
            break;
    }
  }
  onPodDeleteClick = (databaseIndex, index) => {
    let { newOrderDistribution } = this.state
    let database = newOrderDistribution[databaseIndex]
    database.pods = database.pods.filter((item, i ) => index !== i)
    newOrderDistribution[databaseIndex] = database
    this.setState({
        newOrderDistribution: newOrderDistribution
    })
  }
  onDatabaseDeleteClick = (index) => {
    let { newOrderDistribution  } = this.state
    let database = newOrderDistribution[index]
    const filtered = newOrderDistribution.filter((item, i) => index !== i)
    this.setState({
        newOrderDistribution: filtered
    })
    this.calculateTotalPower(database.cutOrder, database.cutOrderUnit, 'negative')
  }
  calculateTotalPower = (power, unit, operation) => {
    let newTotal = this.state.newOrderTotalPower
    power = parseFloat(power)
    if(this.state.newOrderTotalPowerUnit == 'MW'){
        newTotal *= 1000
    }
    switch (operation) {
        case 'negative':
            if(unit == 'MW'){
                newTotal -= power * 1000
            }
            else{
                newTotal -= power
            }
            break;
        case 'positive':
            if(unit == 'MW'){
                newTotal += power * 1000
            }
            else{
                newTotal += power
            }
        break;
        default:
            break;
    }
    if(newTotal >= 1000){
        newTotal /= 1000
        this.setState({
            newOrderTotalPowerUnit: 'MW',
            newOrderTotalPower: newTotal
        })
    }
    else{
        this.setState({
            newOrderTotalPowerUnit: 'KW',
            newOrderTotalPower: newTotal
        })
    }
  }
  onCloseEdit = () =>{
    this.setState({
        editPowerCutOrderModalOpen: false,
        editPowerCutOrderID: '',
        editPowerCutOrderStatus: '',
        editPowerCutOrderItem: {
            Current_Power: '',
            Current_Power_Unit: '',
            Cut_Order: '',
            Cut_Order_Unit: '',
            Date: '',
            Description: '',
            ID: '',
            Status: '',
            Username: '',
        }
    })
  }
  onPowerCutOrderEdit = () => {
    const { editPowerCutOrderStatus, editPowerCutOrderID } = this.state
    const { orderBy, acending, pageSize, pageNumber, searchString } = this.props.powerCutOrderPage;
    this.props.patchPowerCutOrderStatusAction(this.props.userStore.result, editPowerCutOrderID, editPowerCutOrderStatus, pageSize, pageNumber, orderBy, acending, searchString, false)
    this.onCloseEdit()
  }
  render(){
    const { newOrderDistribution, newOrderDescription, newOrderTotalPower, newOrderTotalPowerUnit, editPowerCutOrderItem, editPowerCutOrderModalOpen  } = this.state
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
     const powerCutOrderColumns = [
        {field: 'ID', value: 'ID'},
        {field: 'Status', value: 'Status'},
        {field: 'Cut_Order', value: 'Order'},
        {field: 'Cut_Order_Unit', value: 'Unit'},
        {field: 'Description', value: 'Description'},
        {field: 'Username', value: 'Created By'},
        {field: 'Date', value: "Created At"},
        {field: 'Closed_Date', value: "Closed On"},
        {field: 'Closed_Username', value: "Closed By"}]

    const filteredBuildings = this.props.buildingStore.filter((item) => item.Region_Name.includes('mjolnir'))
    const buildingIDs       = filteredBuildings.map((item) => item.ID)
    const filteredPods      = this.props.podStore.filter((item) =>  buildingIDs.indexOf(item.House_ID) != -1)
    const pickPods          = filteredPods.map((item) => ({label: `${item.House_Name}-${item.Name}`, value: item.ID}))

    const addDatabase = [
        {Name: 'Database/Customer', PropertyName: 'newOrderDatabaseName', Value: this.state.newOrderDatabaseName, Type: 'select',
        Options: [{text: "----------", value: ''}].concat(this.state.databases.map((item) => ({text: item, value: item})))
        },
        {Name: 'Cut Amount', PropertyName: 'newOrderDatabaseCutOrder', Value: this.state.newOrderDatabaseCutOrder, Type: 'float', Options: []},
        {Name: 'Cut Unit', PropertyName: 'newOrderDatabaseCutOrderUnit', Value: this.state.newOrderDatabaseCutOrderUnit, Type: 'select', Options: [{text: "----------", value: ''}].concat(this.state.unitPick.map((item) => ({text: item, value: item})))},
        {Name: 'Pods (Optional)', PropertyName: 'newOrderDatabasePods', Value: this.state.newOrderDatabasePods, Type: 'multi-select', Options: pickPods}
    ] 
    let editCutOrderStatusPick = editPowerCutOrderItem.Status !== ''? [this.state.editPowerCutStatusOptions[editPowerCutOrderItem.Status]] : [{text: '', value: ''} ]
    const editPowerCutOrderColumns = [
        {Name: "ID", PropertyName: 'editPowerCutOrderItem.ID', Type: 'text-disabled', Value: editPowerCutOrderItem.ID, Options: []},
        {Name: "Status", PropertyName: 'editPowerCutOrderStatus', Type: 'select', Value: editPowerCutOrderItem.Status, Options: [{text: editPowerCutOrderItem.Status, value: editPowerCutOrderItem.Status}].concat(editCutOrderStatusPick)},
        {Name: "Cut Order", PropertyName: 'editPowerCutOrderItem.Order', Type: 'text-disabled', Value: editPowerCutOrderItem.Cut_Order, Options: []},
        {Name: "Order Unit", PropertyName: 'editPowerCutOrderItem.Order_Unit', Type: 'text-disabled', Value: editPowerCutOrderItem.Cut_Order_Unit, Options: []},
        {Name: "Description", PropertyName: 'editPowerCutOrderItem.Description', Type: 'text-disabled', Value: editPowerCutOrderItem.Description, Options: []},
        {Name: "Username", PropertyName: 'editPowerCutOrderItem.Username', Type: 'text-disabled', Value: editPowerCutOrderItem.Username, Options: []},
        {Name: "Date", PropertyName: 'editPowerCutOrderItem.Date', Type: 'text-disabled', Value: editPowerCutOrderItem.Date, Options: []},
    ]

    const powerCutOrderButtons = [
        /*{class: 'ViewBody-button-edit', onClick: this.rebootScanner, type: 'Reboot', extractKey: 'IP_Address'},*/
        {class: 'ViewBody-button-edit', onClick: this.editPowerCutOrder, type: 'Edit-Power-Cut-Order', extractKey: 'ID'}]
    return (
        <div className="adminView-container">
            <TabHeader
            logo={
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                </svg>
            }
            title="Server Admin"
            subtitle="Manage scanner, databases & more"
            tabs={adminView}
            active="Power Cut"
            onClick={this.onClick}
            />
            <div className="AdminView-content">
                <ViewBody
                    data={this.props.powerCutOrderStore.powerCutOrders.data}
                    columns={powerCutOrderColumns}
                    buttons={powerCutOrderButtons}
                    createtext="New Order"
                    list
                    onCreate={e => this.setState({createPowerCutOrderModalOpen: true})}
                    pager
                    sorting
                    search
                    searchValue={this.props.powerCutOrderPage.searchString}
                    onSearchChange={this.onPowerCutOrderSearch}
                    acending={this.props.powerCutOrderPage.acending}
                    sortedBy={this.props.powerCutOrderPage.orderBy}
                    onSort={this.onPowerCutOrderSort}
                    totalPages={this.props.powerCutOrderStore.powerCutOrders.max_page}
                    currentPage={parseInt(this.props.powerCutOrderStore.powerCutOrders.current_page) - 1}
                    onPageChange={this.onPowerCutOrderPageClick}
                    onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
                    itemClickInfo={{name: 'powercut', property: 'ID'}}
                />
            </div>
            <Modal
                open={this.state.createPowerCutOrderModalOpen}
                center
                onClose={() => this.setState({createPowerCutOrderModalOpen: false})}
                styles={modalStyle}
            > 
                <NewPowerCutOverview
                    description={newOrderDescription}
                    total={newOrderTotalPower}
                    unit={newOrderTotalPowerUnit}
                    databases={newOrderDistribution}
                    onInputChange={e => this.onInputChange(e, this)}
                    onPodDeleteClick={this.onPodDeleteClick}
                    onDatabaseDeleteClick={this.onDatabaseDeleteClick}
                />
                <FormfieldSection
                    fields={addDatabase}
                    addButton={true}
                    buttonText="Create Order"
                    extraButton={true}
                    extraButtonText="Add Database"
                    onAddClick={this.onOrderAdd}
                    onInputChange={e => this.onInputChange(e, this)}
                    action="add_power_order"
                    extraAction="add_database_to_order"
                />
            </Modal>
            <Modal
                open={editPowerCutOrderModalOpen}
                center
                onClose={this.onCloseEdit}
                styles={modalStyle}
            >
                <FormfieldSection
                    title="Edit Order Status"
                    fields={editPowerCutOrderColumns}
                    addButton={true}
                    buttonText="Edit"
                    onAddClick={this.onPowerCutOrderEdit}
                    onInputChange={e => this.onInputChange(e, this)}
                />
            </Modal>
        </div>
    )
  }
}
const mapStateToProps = (props) => ({
    userStore: props.userReducer,
    adminStore: props.adminReducer,
    powerCutOrderPage: props.pageReducer.powerCutOrder,
    powerCutOrderStore: props.powerCutOrderReducer,
    podStore: props.podsReducer.pods,
    buildingStore: props.houseReducer.houses,
  })

export default connect(mapStateToProps, {
    getPowerCutOrdersAction,
    updatePageInfoAction,
    getHousesAction,
    getAllPodsAction,
    clearHousesAction,
    clearPodsAction,
    postPowerCutOrderAction,
    patchPowerCutOrderStatusAction,
})(PowerCutOrdersView)
import React from 'react';
import './style.css'

const InventoryHistoryItem = ({ username, date, action, amount, type }) => {
  return(
    <tr>
      <td className="tableRowChild">
        {username}
      </td>
      <td className="tableRowChild">
        {action}
      </td>
      <td className="tableRowChild">
        {date}
      </td>
      <td className="tableRowChild">
        {amount}
      </td>
      <td className="tableRowChild">
        {type}
      </td>
    </tr>
  )
}
export default InventoryHistoryItem

import { clearAlertsConstant } from '../constants/actions';
export const clearAlertsAction = () => {
  return (dispatch) =>{
    dispatch(success())
  }
}

const success = () => ({
  type: clearAlertsConstant
})

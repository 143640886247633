import { toggleRefreshDataConstant } from '../constants/actions'


export const toggleRefreshDataAction = () => {
  return dispatch => {
    dispatch({
      type: toggleRefreshDataConstant
    })
  }
}

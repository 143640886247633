import { getSystemLogsConstant, setSystemLogsStatus } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/logService'

export const getSystemLogsAction = (token, customer, mac) => {
  return async dispatch => {
    dispatch({type: setSystemLogsStatus, payload: 'pending'})
    const result = await service.getSystemLogs(token, customer, mac)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getSystemLogsConstant,
  payload: result
})

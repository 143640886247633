import React from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import bigLogo from '../../data/images/atNorthLogoWhiteFontBIG.png'
import smallLogo from '../../data/images/atNorthMountainSmaller.png'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faList, faUsers, faSearch, faEdit, faGlobe } from '@fortawesome/free-solid-svg-icons'
import SettingsContainer from '../SettingsContainer'
import ViewsContainer from '../ViewsContainer'
import CustomersContainer from '../CustomersContainer'
import RegionsContainer from '../RegionsContainer'
const Header = ({toggleHamburger, logout, isLoggedIn, username, splash, onRefreshCheck, refreshData, sidebaropen, userLevel, adminLevel, onSettingsClick, onViewClick, onCustomersClick, onActionsClick, onFilterClick, databaseTier, databases, userLogsAccess, regions, onRegionClick }) => {
  return(
    <>
    {isLoggedIn?(
      <>
      <header className="header-container" id="adc_header">
        <div>
          <NavLink to={'/'} id='logo-link' className="adc_header-logoDiv">
            <span className={`adc_header-logo-mini ${sidebaropen?'displayNone':''}`} id="adc_header-miniLogo">
              <img src={`${smallLogo}`} height="25px" width="auto"></img>
            </span>
            <span className={`adc_header-logo-large ${sidebaropen?'':'displayNone'}`} id="adc_header-bigLogo">
              <img src={`${bigLogo}`} height="25px" width="auto"></img>
            </span>
          </NavLink>
          <span onClick={e => toggleHamburger()} className="adc_header-toggle-sidebar-span" id="toggleHamburger-span">
            <i className="ic fa fa-bars adc_header-toggle-sidebar"/>
          </span>
        </div>
        <nav className="navbar navbar-static-top">
          <div className="adc_header-rightContainer" id="adc_header_rightContainer">
            {regions.length > 0?(
              <span data-tooltip="Regions" data-tooltip-position="left" className="adc_header_select_item" onClick={e => onRegionClick(e)}><FontAwesomeIcon icon={faGlobe} size="lg"/></span>
            ):(<></>)}
            {userLevel === 0 && databaseTier === 0?(<></>):(
              <span data-tooltip="Miner Actions" data-tooltip-position="left" className="adc_header_select_item adc_header_addition" onClick={e => onActionsClick(e)}><FontAwesomeIcon icon={faEdit} size="lg"/></span>
            )}
            <span data-tooltip="Miner Filter" data-tooltip-position="left" className="adc_header_select_item adc_header_addition" onClick={e => onFilterClick(e)}><FontAwesomeIcon icon={faSearch} size="lg"/></span>
            {databases.length > 1?(
              <span data-tooltip="Customers" data-tooltip-position="left" className="adc_header_select_item" onClick={e => onCustomersClick(e)}><FontAwesomeIcon icon={faUsers} size="lg"/></span>
            ):(<></>)}
            <span data-tooltip="Views" data-tooltip-position="left" className="adc_header_select_item" onClick={e => onViewClick(e)}><FontAwesomeIcon icon={faList} size="lg"/></span>
            <span data-tooltip="Settings" data-tooltip-position="left" className="adc_header_select_item" onClick={e => onSettingsClick(e)}><FontAwesomeIcon icon={faCaretDown} size="lg"/></span>
          </div>
        </nav>
      </header>
      <div id="adc_header-hidden_container">
        <RegionsContainer
          regions={regions}
        />
        <SettingsContainer
          logout={logout}
          onRefreshCheck={onRefreshCheck}
          refreshData={refreshData}
        />
        <ViewsContainer
          userLevel={userLevel}
          adminLevel={adminLevel}
          databaseTier={databaseTier}
          userLogsAccess={userLogsAccess}
        />
        <CustomersContainer
          databases={databases}
        />
      </div>
      </>
    ): splash?(
      <header className="header-container">
          <span className="adc_header-logo-large" id="adc_header-bigLogo">
            <img src={`${bigLogo}`} height="40px" width="auto"></img>
          </span>
      </header>
    ):(
      <header className="header-container">
        <NavLink to={'/'} id='logo-link'>
          <span className="adc_header-logo-large" id="adc_header-bigLogo">
            <img src={`${bigLogo}`} height="40px" width="auto"></img>
          </span>
        </NavLink>
      </header>
    )}
    </>
  )
}

export default Header
//<NavLink to={'/'} className="adc_header-logo" id='logo-link'></NavLink>
//<NavLink to={'/'} id='logo-link'><img src={`${logo}`}></img></NavLink>
//className="adc_header-logo"


/*

{userLevel >= adminLevel?(
  <span className="adc_header-admin">
    <NavLink to='/admin' id="adminLink" className="adc_sidebar-link">
      <i className="fa fa-user-secret"/>
      <b>Admin</b>
    </NavLink>
  </span>
):(<></>)}
<a className="adc_header-report-a" onClick={e => window.open(`https://atnorth.atlassian.net/servicedesk/customer/portal/19`, "_blank", 'noopener')}>
  <span className="adc_header-reportProblem">
    Report A Problem
    <i className="fa fa-bug"></i>
  </span>
</a>
<span className="adc_header-autoRefresh">Auto Refresh</span>
<input name="refreshData" style={{marginRight: '30px'}} onChange={e => onRefreshCheck()} type="checkbox" checked={refreshData ? true: false}/>
<NavLink to="/changepassword">
  <span className="adc_header-userSpan">{username}</span>
</NavLink>
<span data-tooltip="Logout" data-tooltip-position="left" onClick={e => logout()}>
  <i className="fas fa-sign-out-alt white-point" aria-hidden="true"></i>
</span>

*/

import {
  getAlertSettingsConstant,
  putAlertSettingsConstant,
  getCustomerAlertsConstant,
  getAllAlertsConstant,
  deactivateAlertConstant,
  getAlertDetailsConstant,
  clearAlertsConstant,
  filterAlertConstant,
  getEmailListConstant,
  deleteFromEmailListConstant,
  postToEmailListConstant
}
from '../constants/actions'

const defaultState = {
  ok: false,
  api_version: 1.1,
  msg: '',
  emailList: [],
  settings: [],
  alerts: {
    maxPage: 1,
    currentPage: 1,
    pageSize: 25,
    data: [],
    dataSet: []
  },
  alertDetails: []
}

export default(state=defaultState, action) => {
  switch (action.type) {
    case getAlertSettingsConstant:
    case putAlertSettingsConstant:
      return{
        ok: action.payload.ok,
        msg: action.payload.msg,
        settings: action.payload.settings,
        alerts: state.alerts,
        alertDetails: state.alertDetails,
        emailList: state.emailList
      }
    case getCustomerAlertsConstant:
    case getAllAlertsConstant:
    case deactivateAlertConstant:
      const newAlerts = action.payload.alerts.data?(action.payload.alerts.data.length > 0?{
        maxPage: action.payload.alerts.max_page,
        currentPage: action.payload.alerts.current_page,
        pageSize: action.payload.alerts.page_size,
        data: action.payload.alerts.data,
      }:{maxPage: 1,currentPage: 1,pageSize: 25,data: []}):({maxPage: 1,currentPage: 1,pageSize: 25,data: []})
      return{
        ok: action.payload.ok,
        msg: action.payload.msg,
        settings: state.settings,
        alerts: newAlerts,
        alertDetails: state.alertDetails,
        emailList: state.emailList
      }
    case getAlertDetailsConstant:
      return {
        ok: action.payload.ok,
        msg: action.payload.msg,
        settings: state.settings,
        alerts: state.alerts,
        alertDetails: action.payload.alertDetails,
        emailList: state.emailList
      }
    case clearAlertsConstant:
      return {
        ok: state.ok,
        msg: state.msg,
        settings: state.settings,
        alerts: {
          maxPage: '',
          currentPage: '',
          data: [],
        },
        alertDetails: state.alertDetails,
        emailList: state.emailList
      }
    case filterAlertConstant:
      const alerts = action.payload.ok?action.payload.alerts:state.alerts
      const filterAlerts = {
        data: alerts,
        maxPage: 1,
        currentPage: 1
      }
      return {
        ok: state.ok,
        msg: state.msg,
        settings: state.settings,
        alerts: filterAlerts,
        alertDetails: state.alertDetails,
        emailList: state.emailList
      }
    case getEmailListConstant:
    case deleteFromEmailListConstant:
    case postToEmailListConstant:
      if(action.payload.ok){
        return {
          ok: true,
          msg: action.payload.msg,
          settings: state.settings,
          alerts: state.alerts,
          alertDetails: state.alertDetails,
          emailList: action.payload.data
        }
      }
      return state
    default:
      return state;
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { renameCustomersAction } from '../../redux/actions/renameCustomersAction'
import { getFarmInventoryAction } from '../../redux/actions/getFarmInventoryAction'
import { postFarmInventoryAction } from '../../redux/actions/postFarmInventoryAction'
import { putFarmInventoryAction } from '../../redux/actions/putFarmInventoryAction'
import { getAccessLevelsAction } from '../../redux/actions/getAccessLevelsAction'
import { putAccessLevelsAction } from '../../redux/actions/putAccessLevelsAction'
import { getReportDataAction } from '../../redux/actions/getReportDataAction'
import { getAllUsersAction } from '../../redux/actions/getAllUsersAction'
import { getCustomersNameAction } from '../../redux/actions/getCustomersNameAction'
import { addDatabaseAction } from '../../redux/actions/addDatabaseAction'
import { createNewUserAction } from '../../redux/actions/createNewUserAction'
import { addNewMinerTypeAction } from '../../redux/actions/addNewMinerTypeAction'
import { adjustUsersPrivilegesAction } from '../../redux/actions/adjustUsersPrivilegesAction'
import { adjustSystemPrivilegesAction } from '../../redux/actions/adjustSystemPrivilegesAction'
import { deleteUserAction } from '../../redux/actions/deleteUserAction'
import { deleteDatabaseAction } from '../../redux/actions/deleteDatabaseAction'
import { getAllTypesAction } from '../../redux/actions/getAllTypesAction'
import { deleteMinerTypeAction } from '../../redux/actions/deleteMinerTypeAction'
import { editMinerTypeAction } from '../../redux/actions/editMinerTypeAction'
import { updateDatabaseTierAction } from '../../redux/actions/updateDatabaseTierAction'
import { getAllScannersAction } from '../../redux/actions/getAllScannersAction'
import { postScannerAction } from '../../redux/actions/postScannerAction'
import { rebootScannerAction } from '../../redux/actions/rebootScannerAction'
import { patchScannerAction } from '../../redux/actions/patchScannerAction'
import { deployScannerAction } from '../../redux/actions/deployScannerAction'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { findWithAttr } from './functions'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CustomerTable from '../../Components/CustomerTable'
import FarmInventoryTable from '../../Components/FarmInventoryTable'
import AccessLevelTable from '../../Components/AccessLevelTable'
import FarmReportForm from '../../Components/FarmReportForm'
import FormfieldSection from '../../Components/FormfieldSection'
import AdminInfoSection from '../../Components/AdminInfoSection'
import AdminDatabasesSection from '../../Components/AdminDatabasesSection'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import { Redirect } from 'react-router-dom'
import * as XLSX from 'xlsx';
import './style.css'
const jwt = require('jwt-simple');

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
class AdminView extends React.Component {
  constructor(props){
    super(props)
    this.state ={
      tabValue: 0,
      clients: [],
      newItemType: '',
      newAmount: '',
      newItem: '',
      currentCustomer: '',
      inventory: [],
      level: 2,
      accessLevels: [],
      reportStartDate: null,
      reportEndDate: null,
      reportCustomer: '',
      reportProperties: {
        report_hashrate: false,
        report_online: false,
        report_offline: false,
        report_alerts: false,
        report_maintenance: false,
        report_comments: false
      },
      formFieldObj: {
        database:{
          name: '',
          tier: '',
          api_version: ''
        },
        databaseEdit:{
          name: '',
          oldDatabase: '',
          api_version: '',
          tier: '',
          old_tier: ''
        },
        user:{
          username: '',
          password: '',
          email: '',
          userGroup : ''
        },
        minerType: {
          name: '',
          avgHeat: '',
          cardNumber: '',
          totalSpeed: '',
          powerConsumption: '',
          asicNumber: ''
        },
        minerTypeEdit: {
          name: '',
          avgHeat: '',
          cardNumber: '',
          totalSpeed: '',
          powerConsumption: '',
          asicNumber: ''
        },
        userPrivileges: {
          user: '',
          currentGroup: '---------',
          customer: '',
          userGroup: ''
        },
        systemPrivileges: {
          user: '',
          userGroup: '',
          currentGroup: '---------'
        },
        addScanners:{
          database: '',
          ip_address: '',
          name: '',
          scan_ip: '',
          scan_range: '',
          scan_time_Target: 60,
          threads: 1,
          threads_threshold: 25
        },
        editScanner:{
          oldIP: '',
          Database_Name: '',
          IP_Address: '',
          Name: '',
          Scan_IP: '',
          Scan_Range: '',
          Scan_Time_Target: '',
          Threads: '',
          Threads_Threshold: ''
        }
      },
      databaseModalopen: false,
      scannerEditModalOpen: false,
      createUsersModalOpen:false,
      editUserModalOpen: false,
      createDatabaseModalOpen: false,
      createMinerTypeModalOpen: false,
      createScannerModalOpen: false,
      databaseModalDatabaseIndex: -1,
      userModalopen: false,
      editMinerTypeModalOpen: false,
      minerTypeModalID: -1,
      headerTabActive: 'Users',
      userSearchString: '',
      scannerSearchString: '',
      minerTypeSearchString: ''
    }
  }
  componentDidMount = async() => {
    const userData = jwt.decode(this.props.userStore.result, process.env.REACT_APP_DECRYPT_KEY);
    const level = userData.level? userData.level: 0
    const adminLink = document.getElementById("adminLink");
    if(adminLink){
      adminLink.classList.add("itemSelected");
    }
    const currentCustomer = this.props.clientsStore.clients.length > 0? this.props.clientsStore.clients[0].client: ''
    this.setState({
      clients: this.props.clientsStore.clients.map((item, i) => ({client: item.client, index: i})),
      currentCustomer,
      level
    })
    this.props.getAllUsersAction(
      this.props.userStore.result,
      this.props.userPage.pageSize,
      this.props.userPage.pageNumber,
      this.props.userPage.orderBy,
      this.props.userPage.acending,
      this.state.userSearchString,
    )
    this.props.getCustomersNameAction(this.props.userStore.result)
    this.props.getAllTypesAction(
      this.props.userStore.result,
      this.props.minerTypePage.pageSize,
      this.props.minerTypePage.pageNumber,
      this.props.minerTypePage.orderBy,
      this.props.minerTypePage.acending,
      this.state.minerTypeSearchString
    )
    this.props.getAllScannersAction(
      this.props.userStore.result,
      this.props.scannerPage.pageSize,
      this.props.scannerPage.pageNumber,
      this.props.scannerPage.orderBy,
      this.props.scannerPage.acending,
      this.state.scannerSearchString)

    await this.props.getAccessLevelsAction(this.props.userStore.result)
    this.setState({accessLevels: JSON.parse(JSON.stringify(this.props.accessStore.levels))})
    if(currentCustomer !== ''){
      await this.props.getFarmInventoryAction(this.props.userStore.result, currentCustomer);
      let newInventory = JSON.parse(JSON.stringify(this.props.inventory))
      newInventory = newInventory.map((item, key) => ({
        Amount: item.Amount,
        Item_Type: item.Item_Type,
        Item: item.Item,
        key
      }))
      this.setState({inventory: newInventory})
    }
  }
  handleChange = (event, value) => {
    this.setState({
      tabValue: value
    });
  }
  onInputChange = (e) => {
    let { clients } = this.state;
    clients = clients.map((item) => item.client == e.target.name? ({client: e.target.value, index: item.index}): item)
    this.setState({clients})
  }
  onCustomersUpdate = async() => {
    const { clients } = this.state;
    let currentClients = this.props.clientsStore.clients.map((item) => item.client)
    let newClients = clients.filter((item) => currentClients.indexOf(item.client) === -1)
    const body = newClients.map((item) => ({oldDB: currentClients[item.index], newDB: item.client}))
    if(body.length > 0){
      await this.props.renameCustomersAction(this.props.userStore.result, body)
    }
  }
  onFarmInventoryInputChange = (e, type) => {
    if(type == 'new'){
      this.setState({[e.target.name]: e.target.value})
    }
    if(type == 'update'){
      let { inventory } = this.state
      let index = e.target.name.split('-')
      inventory[parseInt(index[1])][index[0]] = e.target.value
      this.setState({inventory: inventory})
    }
  }
  onFarmInventoryUpdate = async() => {
    let { inventory, currentCustomer } = this.state;
    let newInventory = inventory.filter((item) => {
      var found = false;
      for(var i = 0; i < this.props.inventory.length; i++) {
          if (this.props.inventory[i].Item_Type === item.Item_Type && this.props.inventory[i].Item === item.Item && this.props.inventory[i].Amount === item.Amount) {
            found = true;
            break;
          }
      }
      return !found
    })
    if(newInventory.length == 0){
      alert("No changes have been made")
      return
    }
    const body = newInventory.map((item) => ({
      oldItem_Type: this.props.inventory[item.key].Item_Type,
      oldItem: this.props.inventory[item.key].Item,
      amount: item.Amount,
      newItem_Type: item.Item_Type,
      newItem: item.Item
    }))
    await this.props.putFarmInventoryAction(this.props.userStore.result, currentCustomer, body)
    let tmpInventory = JSON.parse(JSON.stringify(this.props.inventory))
    tmpInventory = tmpInventory.map((item, key) => ({
      Amount: item.Amount,
      Item_Type: item.Item_Type,
      Item: item.Item,
      key
    }))
    this.setState({inventory: tmpInventory})
  }
  onFarmInventoryAdd = async() => {
    const { newItem, newItemType, newAmount, currentCustomer } = this.state
    if(newItem == ''){
      alert("Item is empty")
      return
    }
    if(newItemType == ''){
      alert("Item Type is empty")
      return
    }
    if(newItem == ''){
      alert("Amount is empty")
      return
    }
    await this.props.postFarmInventoryAction(this.props.userStore.result, currentCustomer, {
      amount: newAmount,
      itemType: newItemType,
      item: newItem
    })
    this.setState({newAmount: '', newItem: '', newItemType: '', inventory: JSON.parse(JSON.stringify(this.props.inventory))})
  }
  onCustomerSelect = async(e) => {
    const value = e.target.value;
    await this.props.getFarmInventoryAction(this.props.userStore.result, value)
    let tmpInventory = JSON.parse(JSON.stringify(this.props.inventory))
    tmpInventory = tmpInventory.map((item, key) => ({
      Amount: item.Amount,
      Item_Type: item.Item_Type,
      Item: item.Item,
      key
    }))
    this.setState({currentCustomer: value, inventory: tmpInventory})
  }
  onAccessLevelInputChange = (e) => {
    let { accessLevels } = this.state;
    let split = e.target.name.split('-')
    let id = parseInt(split[1])
    let level = parseInt(e.target.value)
    if(isNaN(level)){
      alert("Enter a number")
      return
    }
    accessLevels = accessLevels.map((item) => item.ID == id? {ID: id, Level: level, Access: item.Access}: item)
    this.setState({accessLevels})
  }
  onAccessLevelUpdate = () => {
    let { accessLevels } = this.state;
    accessLevels = accessLevels.filter((item) => !this.props.accessStore.levels.some((item2) => item2.Level == item.Level && item2.ID == item.ID))
    if(accessLevels.length > 0){
      this.props.putAccessLevelsAction(this.props.userStore.result, accessLevels);
    }
    else{
      alert("No Field was changed")
    }
  }
  onStartDateChange = (date) => {
    this.setState({reportStartDate: date})
  }
  onEndDateChange = (date) => {
    const { reportStartDate } = this.state;
    if(reportStartDate > date){
      alert("The start date must be older then the end date")
      return
    }
    this.setState({reportEndDate: date})
  }
  onChangeDateChange = (dates) => {
    const [start, end] = dates;
    this.setState({
      reportStartDate: start,
      reportEndDate: end
    })
  };
  onCustomerReportSelect = (e) => {
      this.setState({reportCustomer: e.target.value})
  }
  userGroupSwitch = (level) => {
    switch (level) {
      default:
      case 0:
        return "Select Users"
      case 1:
        return "Insert/Update Users"
      case 2:
        return "Administrator"
    }
  }
  deleteUser = (username) => {
    if(window.confirm(`Are you sure you want to delete ${username}. Action cannot be reverted`)){
      this.props.deleteUserAction(this.props.userStore.result, username)
    }
  }
  onFormFieldInputChange = (e) => {
    let split = e.target.name.split('.')
    let string = `this.state.${e.target.name}`
    let obj_copy = JSON.parse(JSON.stringify(this.state[split[0]]))
    obj_copy[split[1]][split[2]] = e.target.value
    if(e.target.name === "formFieldObj.systemPrivileges.user"){
      const user = this.props.adminStore.users.data.filter((item) => item.Username === e.target.value)
      const userGroup = user.length == 0?'----------': this.userGroupSwitch(user[0].Level)
      obj_copy[split[1]].currentGroup = userGroup
    }
    else if(e.target.name === "formFieldObj.userPrivileges.user"){
      const customer = this.props.adminStore.clients.filter((item) => item.client === obj_copy.userPrivileges.customer)
      if(customer.length > 0){
        const user = customer[0].users.filter((item) => item.Username === e.target.value)
        let level = user.length == 0?('-------'):this.userGroupSwitch(user[0].Level)
        obj_copy[split[1]].currentGroup = level
      }
    }
    this.setState({
      [split[0]]: obj_copy
    })
  }
  checkDatabaseName = (name) => {
    var format = /[ `!@#$%^&*() +\-=\[\]{};':"\\|,.<>\/?~]/;
    const currentDatabases = this.props.adminStore.clients.map((item) => item.client)
    if(format.test(name)){
      alert("Database name is invalid")
      return false
    }
    if(currentDatabases.indexOf(name) !== -1){
      alert("Database name is taken")
      return false
    }
    return true
  }
  onFormFieldAddClick = (action) => {
    let token = this.props.userStore.result
    let body = ''
    const emailRegex = /^\S+@\S+\.\S+$/
    const nameRegex = /[ `!@#$%^&*() +=\[\]{};':"\\|,<>\/?~]/;
    //const ipRegex    = /^([0-9]{0,3}\.){3}[0-9]}{0,3}$/
    //const ipRegex    = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/
    const ipRegex = /\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\b/
    switch (action) {
      case 'add_database':
          body = this.state.formFieldObj.database
          if(body.name == ''){
            alert("Enter database name")
            return
          }
          if(body.api_version == ''){
            alert("Select api version")
            return
          }
          const nameTrimed = body.name.trim()
          if(!this.checkDatabaseName(nameTrimed)){
            return
          }
          this.props.addDatabaseAction(token, {database_name: body.name, database_version: body.api_version, tier: body.tier})
          let clearDatabase = JSON.parse(JSON.stringify(this.state.formFieldObj))
          clearDatabase.database = {name: '',api_version: '', tier: ''}
          this.setState({ formFieldObj: clearDatabase, createDatabaseModalOpen: false })
        break;
      case 'add_user':
          body = this.state.formFieldObj.user
          if(body.username == ''){
            alert("Enter an username")
            return
          }
          if(body.password == ''){
            alert("Enter an password")
            return
          }
          if(body.email == ''){
            alert("Enter an email")
            return
          }
          if(!emailRegex.exec(body.email)){
            alert("Enter a valid email")
            return
          }
          if(body.userGroup == ''){
            alert("Enter an user group")
            return
          }
          this.props.createNewUserAction(token, {username: body.username, email: body.email, password: body.password, level: body.userGroup})
          let clearUser = JSON.parse(JSON.stringify(this.state.formFieldObj))
          clearUser.user = {
            username: '',
            password: '',
            email: '',
            userGroup : ''
          }
          this.setState({ formFieldObj: clearUser, createUsersModalOpen: false })
        break
      case 'add_miner_type':
        body = this.state.formFieldObj.minerType
        if(body.name == ''){
          alert("Enter an name")
          return
        }
        if(body.avgHeat == ''){
          alert("Enter an average heat")
          return
        }
        if(body.cardNumber == ''){
          alert("Enter the number of cards within the miner")
          return
        }
        if(body.totalSpeed === ''){
          alert("Enter the total speed of the miner")
          return
        }
        if(body.powerConsumption == ''){
          alert("Enter the total power consumption of the miner")
          return
        }
        if(body.asicNumber == ''){
          alert("Enter the number of asic per card")
          return
        }
        this.props.addNewMinerTypeAction(token, {Name: body.name, Avg_Heat: body.avgHeat, Card_Num: body.cardNumber, Total_Speed: body.totalSpeed, Power_Consumption: body.powerConsumption, Asic_Num: body.asicNumber})
        let newTypeObj = JSON.parse(JSON.stringify(this.state.formFieldObj))
        newTypeObj.minerType = {
          name: '',
          avgHeat: '',
          cardNumber: '',
          totalSpeed: '',
          powerConsumption: '',
          asicNumber: ''
        }
        this.setState({ formFieldObj: newTypeObj, createMinerTypeModalOpen: false})
        break;
      case 'adjust_privileges_another':
      case 'adjust_privileges':
        body = this.state.formFieldObj.userPrivileges
        if(body.user == ''){
          alert("Pick a user")
          return
        }
        if(body.customer == ''){
          alert("Pick a customer")
          return
        }
        if(body.userGroup == ''){
          alert("Pick a user group")
          return
        }
        this.props.adjustUsersPrivilegesAction(token, {user: body.user, database: body.customer, level: body.userGroup })
        let clearAdjustPrivileges = JSON.parse(JSON.stringify(this.state.formFieldObj))
        if(action === "adjust_privileges_another"){
          clearAdjustPrivileges.userPrivileges = {
            user: '',
            customer: clearAdjustPrivileges.userPrivileges.customer,
            userGroup: '',
            currentGroup: '---------',
          }
          this.setState({ formFieldObj: clearAdjustPrivileges})
        }
        else{
          clearAdjustPrivileges.userPrivileges = {
            user: '',
            customer: '',
            userGroup: '',
            currentGroup: '---------',
          }
          this.setState({ formFieldObj: clearAdjustPrivileges, databaseModalopen: false, databaseModalDatabaseIndex: -1 })
        }
        break
      case 'adjust_system_privileges':
        body = this.state.formFieldObj.systemPrivileges
        if(body.user == ''){
          alert("Pick a user")
          return
        }
        if(body.userGroup == ''){
          alert("Pick a user group")
          return
        }
        this.props.adjustSystemPrivilegesAction(token, {username: body.user, level: body.userGroup})
        let clearSystemPrivileges = JSON.parse(JSON.stringify(this.state.formFieldObj))
        clearSystemPrivileges.systemPrivileges = {
          user: '',
          userGroup: '',
          currentGroup: '---------'
        }
        this.setState({ formFieldObj: clearSystemPrivileges, editUserModalOpen: false })
        break;
      case 'edit_miner_type':
        this.props.editMinerTypeAction(token, this.state.minerTypeModalID, this.state.formFieldObj.minerTypeEdit)
        let formFieldObj_CP = JSON.parse(JSON.stringify(this.state.formFieldObj))
        formFieldObj_CP.minerTypeEdit = {
          name: '',
          avgHeat: '',
          cardNumber: '',
          totalSpeed: '',
          powerConsumption: '',
          asicNumber: ''
        }
        this.setState({
          formFieldObj: formFieldObj_CP,
          editMinerTypeModalOpen: false,
          minerTypeModalID: -1
        })
        break;
      case 'edit_database':
        let change = false;
        if(this.state.formFieldObj.databaseEdit.tier !== this.state.formFieldObj.databaseEdit.old_tier){
          this.props.updateDatabaseTierAction(this.props.userStore.result, this.state.formFieldObj.databaseEdit.oldDatabase, this.state.formFieldObj.databaseEdit.tier)
          change = true;
        }
        const newNameTrimed = this.state.formFieldObj.databaseEdit.name.trim()
        if(newNameTrimed !== this.state.formFieldObj.databaseEdit.oldDatabase){
          if(!this.checkDatabaseName(newNameTrimed)){
            return
          }
          this.props.renameCustomersAction(this.props.userStore.result, [{oldDB: this.state.formFieldObj.databaseEdit.oldDatabase, newDB: this.state.formFieldObj.databaseEdit.name}])
          change = true
        }
        if(!change){
          alert("No fields where changed")
          return
        }
        let formFieldObj_rename_CP = JSON.parse(JSON.stringify(this.state.formFieldObj))
        formFieldObj_rename_CP.databaseEdit = {
          name: '',
          oldDatabase: '',
          api_version: ''
        }
        this.setState({
          formFieldObj: formFieldObj_rename_CP,
          databaseModalopen: false,
          databaseModalDatabaseIndex: -1
        })
        break
      case 'add_scanner':
        body = this.state.formFieldObj.addScanners
        if(body.database === ''){
          alert("Pick a database")
          return
        }
        if(body.ip_address === ''){
          alert("Enter a ip address")
          return
        }
        if(!ipRegex.exec(body.ip_address)){
          alert("IP address is invalid")
          return
        }
        if(body.name === ''){
          alert("Enter a scanner name")
          return
        }
        if(nameRegex.test(body.name)){
          alert("Scanner Name is invalid")
          return
        }
        if(body.scan_ip === ''){
          alert("Enter a start IP for scan")
          return
        }
        if(!ipRegex.exec(body.scan_ip)){
          alert("Scan start IP is invalid")
          return
        }
        if(body.scan_range === ''){
          alert("Pick a scan range")
          return
        }
        if(body.scan_time_Target < 60){
          alert("Time between scans cannot be less the 60 seconds")
          return
        }
        if(body.scan_time_Target > 600){
          alert("Time between scans cannot be more then 10 minutes")
          return
        }
        if(body.threads < 1){
          alert("Threads need to be atleast 1")
          return
        }
        if(body.threads > 50){
          alert("Threads cannot be more the 50")
          return
        }
        if(body.threads_threshold < 1){
          alert("Threads threadshold need to be atleast 1")
          return
        }
        if(body.threads_threshold > 50){
          alert("Threads threshold cannot be more the 50")
          return
        }
        if(body.threads > body.threads_threshold){
          alert("Threads cannot be more than threads threshold")
          return
        }
        this.props.postScannerAction(token, {
          IP_Address: body.ip_address,
          Name: body.name,
          Scan_Range: body.scan_range,
          Scan_IP: body.scan_ip,
          Database_Name: body.database,
          Threads: body.threads,
          Threads_Threshold: body.threads_threshold,
          Scan_Time_Target: body.scan_time_Target
        })
        let addScanner = JSON.parse(JSON.stringify(this.state.formFieldObj))
        addScanner.addScanners = {
          database: '',
          ip_address: '',
          name: '',
          scan_ip: '',
          scan_range: '',
          scan_time_Target: 60,
          threads: 1,
          threads_threshold: 25
        }
        this.setState({ formFieldObj: addScanner, createScannerModalOpen: false })
        break
      case 'edit_scanner':
        let newScanner = this.state.formFieldObj.editScanner
        let oldScanner = this.props.adminStore.scanners.data.filter((item) => item.IP_Address == newScanner.oldIP)[0]
        const difference = this.checkDifferentFields(oldScanner, newScanner)
        if(difference.length == 0){
          alert("No changes where made")
          return
        }
        let postObj = {}
        for(let i in difference){
          if(newScanner[difference[i]] == '' || newScanner[difference[i]] == 0){
            alert(`${difference[i]} cannot be empty/0`)
            return
          }
          switch (difference[i]) {
            case 'Scan_IP':
            case 'IP_Address':
              if(!ipRegex.exec(newScanner[difference[i]])){
                alert(`${difference[i]} is not a valid ip address`)
                return
              }
              break;
            case "Threads_Threshold":
            case "Threads":
              if(parseInt(newScanner.Threads) > parseInt(newScanner.Threads_Threshold)){
                alert("Threads cannot be larger then threads threshold")
                return
              }
              if(parseInt(newScanner[difference[i]]) < 1){
                alert(`${difference[i]} must be atleast 1`)
                return
              }
            case "Scan_Time_Target":
              if(parseInt(newScanner.Scan_Time_Target) < 60){
                alert("Time target must me atleast 60 seconds")
                return
              }
          }
          postObj[difference[i]] = newScanner[difference[i]]
        }
        this.props.patchScannerAction(this.props.userStore.result, newScanner.oldIP, postObj)
        let editScanner = JSON.parse(JSON.stringify(this.state.formFieldObj))
        editScanner.editScanner = {
          oldIP: '',
          Database_Name: '',
          IP_Address: '',
          Name: '',
          Scan_IP: '',
          Scan_Range: '',
          Scan_Time_Target: '',
          Threads: '',
          Threads_Threshold: ''
        }
        this.setState({ formFieldObj: editScanner, scannerEditModalOpen: false })
        break
    }
  }
  checkDifferentFields = (oldObj, newObj) => {
    let difference = []
    for(var i in newObj){
      if(newObj.hasOwnProperty(i)){
        if(oldObj.hasOwnProperty(i) && oldObj[i] !== newObj[i]){
            difference.push(i)
        }
      }
    }
    return difference
  }
  onFarmReportSubmit = async() => {
    const { reportStartDate, reportEndDate, reportProperties, reportCustomer } = this.state;
    let properitesString = ''
    if(!reportStartDate || !reportEndDate){
      alert("Start and end data need both to be present")
      return
    }
    if(reportCustomer == ''){
      alert("Need to pick a customer")
      return
    }
    for(var i in reportProperties){
      if(reportProperties.hasOwnProperty(i)&&reportProperties[i]){
        properitesString += `properties[]=${i}&`
      }
    }
    if(properitesString === ''){
      alert("Pick at least one property")
      return
    }
    await this.props.getReportDataAction(this.props.userStore.result, reportStartDate.toString(), reportEndDate.toString(), reportCustomer, properitesString)
    let wsData = []
    for(var i in this.props.reportStore.data){
      if(this.props.reportStore.data.hasOwnProperty(i)){
        if(i.includes('max')){
          let split = i.split('max');
          let obj = this.props.reportStore.data[i].map((item, key) => ({...item, ...this.props.reportStore.data[`min${split[1]}`][key]}))
          const ws = XLSX.utils.json_to_sheet(obj);
          ws['!type'] = 'chart'
          wsData.push({'data': ws, name: split[1]})
        }
        else if(!i.includes('min')){
          const ws = XLSX.utils.json_to_sheet(this.props.reportStore.data[i]);
          wsData.push({'data': ws, name: i})
        }
      }
    }
    var wb = XLSX.utils.book_new();
    wsData.map((item) => {
      XLSX.utils.book_append_sheet(wb, item.data, item.name)
    })
    XLSX.writeFile(wb, `report-${reportCustomer}.xlsx`)
  }
  onReportCheckboxChange = (e) => {
    let { reportProperties } = this.state;
    reportProperties[e.target.name] = e.target.value == 'false'
    this.setState({reportProperties})
  }
  deleteDatabase = (database) => {
    if(window.confirm(`Are you sure you want to delete ${database}. Action cannot be reverted`)){
      this.props.deleteDatabaseAction(this.props.userStore.result, database)
    }
  }
  editDatabase = (database) => {
    let cp = JSON.parse(JSON.stringify(this.state.formFieldObj))
    let client = this.props.adminStore.clients.filter((item) => item.client === database)
    let api_version = client.length > 0?(client[0].version):('')
    let tier = client.length > 0?(client[0].tier):('')
    cp.userPrivileges.customer = database
    cp.databaseEdit.name = database
    cp.databaseEdit.oldDatabase = database
    cp.databaseEdit.api_version = api_version
    cp.databaseEdit.tier = tier
    cp.databaseEdit.old_tier = tier
    const databaseIndex = findWithAttr(this.props.adminStore.clients, 'client', database)
    this.setState({ formFieldObj: cp, databaseModalopen: true, databaseModalDatabaseIndex: databaseIndex })
  }
  editMinerType = (id) => {
    let minerType = this.props.typeStore.allTypes.data.filter((item) => item.ID == id)
    if(minerType.length > 0){
      let formFieldObj_CP = JSON.parse(JSON.stringify(this.state.formFieldObj))
      let minerTypeObj = {
        name: minerType[0].Name,
        avgHeat: minerType[0].Avg_Heat,
        cardNumber: minerType[0].Card_Num,
        totalSpeed: minerType[0].Total_Speed,
        powerConsumption: minerType[0].Power_Consumption,
        asicNumber: minerType[0].Asic_Num
      }
      formFieldObj_CP.minerTypeEdit = minerTypeObj
      this.setState({
        minerTypeModalID: id,
        formFieldObj: formFieldObj_CP,
        editMinerTypeModalOpen: true
      })
    }
  }
  deleteMinerType = (id) => {
    if(window.confirm(`Are you sure you want delete this type ? . Action cannot be reverted`)){
      this.props.deleteMinerTypeAction(this.props.userStore.result, id)
    }
  }
  editScanner = (ip) => {
    let scanner = this.props.adminStore.scanners.data.filter((item) => item.IP_Address == ip)
    let formFieldObj = JSON.parse(JSON.stringify(this.state.formFieldObj))
    formFieldObj.editScanner = {
      oldIP: ip,
      Database_Name: scanner[0].Database_Name,
      IP_Address: ip,
      Name: scanner[0].Name,
      Scan_IP: scanner[0].Scan_IP,
      Scan_Range: scanner[0].Scan_Range,
      Scan_Time_Target: scanner[0].Scan_Time_Target,
      Threads: scanner[0].Threads,
      Threads_Threshold: scanner[0].Threads_Threshold
    }
    this.setState({formFieldObj, scannerEditModalOpen: true})
  }
  rebootScanner = (ip) => {
    if(window.confirm(`Are you sure you want to reboot scanner: ${ip} ?`)){
      this.props.rebootScannerAction(this.props.userStore.result ,ip)
    }
  }
  deployScanner = (ip) => {
    if(window.confirm(`Are you sure you want to deploy scanner agent on: ${ip} ?`)){
      this.props.deployScannerAction(this.props.userStore.result, ip)
    }
  }
  editUser = (username) => {
    let { formFieldObj } = this.state
    let  userObj = this.props.adminStore.users.data.filter((item) => item.Username == username)
    formFieldObj.systemPrivileges.user = username
    formFieldObj.systemPrivileges.currentGroup = this.userGroupSwitch(userObj[0].Level)
    this.setState({ editUserModalOpen: true, currentUser: username , formFieldObj})
  }
  onUserPageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber === parseInt(this.props.adminStore.users.current_page)) return
    const { pageSize, orderBy, acending } = this.props.userPage;
    if (pageNumber !== 0 && pageNumber <= this.props.adminStore.users.max_page) {
      this.props.getAllUsersAction(this.props.userStore.result, pageSize, pageNumber, orderBy, acending, this.state.userSearchString);
    }
    const store = {
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'users'
    }
    this.props.updatePageInfoAction(store);
  }
  onUserSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.userPage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getAllUsersAction(this.props.userStore.result, pageSize, pageNumber, type, acending, this.state.userSearchString);
    const store = {
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'users',
    }
    this.props.updatePageInfoAction(store);
  }
  onUserSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.userPage;
    this.props.getAllUsersAction(this.props.userStore.result, pageSize, 1, orderBy, acending, e.target.value);
    this.setState({
      userSearchString: e.target.value
    })
    const store = {
      pageSize: pageSize,
      pageNumber: 1,
      type: 'users',
      orderBy: this.props.userPage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onScannerPageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber === parseInt(this.props.adminStore.scanners.current_page)) return
    const { pageSize, orderBy, acending } = this.props.scannerPage;
    if (pageNumber !== 0 && pageNumber <= this.props.adminStore.scanners.max_page) {
      this.props.getAllScannersAction(this.props.userStore.result, pageSize, pageNumber, orderBy, acending, this.state.scannerSearchString);
    }
    const store = {
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'scanners'
    }
    this.props.updatePageInfoAction(store);
  }
  onScannerSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.scannerPage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getAllScannersAction(this.props.userStore.result, pageSize, pageNumber, type, acending, this.state.scannerSearchString);
    const store = {
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'scanners',
    }
    this.props.updatePageInfoAction(store);
  }
  onScannerSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.scannerPage;
    this.props.getAllScannersAction(this.props.userStore.result, pageSize, 1, orderBy, acending, e.target.value);
    this.setState({
      scannerSearchString: e.target.value
    })
    const store = {
      pageSize: pageSize,
      pageNumber: 1,
      type: 'scanners',
      orderBy: this.props.scannerPage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onMinerTypePageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber === parseInt(this.props.typeStore.allTypes.current_page)) return
    const { pageSize, orderBy, acending } = this.props.minerTypePage;
    if (pageNumber !== 0 && pageNumber <= this.props.typeStore.allTypes.max_page) {
      this.props.getAllTypesAction(this.props.userStore.result, pageSize, pageNumber, orderBy, acending, this.state.minerTypeSearchString);
    }
    const store = {
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'minerType'
    }
    this.props.updatePageInfoAction(store);
  }
  onMinerTypeSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.minerTypePage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getAllTypesAction(this.props.userStore.result, pageSize, pageNumber, type, acending, this.state.minerTypeSearchString);
    const store = {
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'minerType',
    }
    this.props.updatePageInfoAction(store);
  }
  onMinerTypeSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.minerTypePage;
    this.props.getAllTypesAction(this.props.userStore.result, pageSize, 1, orderBy, acending, e.target.value);
    this.setState({
      minerTypeSearchString: e.target.value
    })
    const store = {
      pageSize: pageSize,
      pageNumber: 1,
      type: 'minerType',
      orderBy: this.props.minerTypePage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  render(){
    const { tabValue,
      clients,
      newItem,
      newItemType,
      newAmount,
      currentCustomer,
      inventory,
      level,
      accessLevels,
      reportEndDate,
      reportStartDate,
      reportProperties,
      databaseModalDatabaseIndex,
      minerTypeModalID
    } = this.state;
    const addDatabaseFields = [
      {Name: "Name", PropertyName: 'formFieldObj.database.name', Value: this.state.formFieldObj.database.name, Type: "text", Options: []},
      {Name: "Select Tier", PropertyName: 'formFieldObj.database.tier', Value: this.state.formFieldObj.database.tier, Type: "select",
      Options: [{text: "----------", value: ''},
        {text:"Level 0", value: "0"},
        {text:"Level 1", value: "1"},
        {text:"Level 2", value: "2"}]},
      {Name: "API Version", PropertyName: 'formFieldObj.database.api_version', Value: this.state.formFieldObj.database.api_version, Type: "select",
        Options:[{text: "----------", value: ''},
          {text: "1.1", value: "1.1"},
          {text:"1.2", value: "1.2"}
        ]}]
    const editDatabaseFields = [
      {Name: "Name", PropertyName: 'formFieldObj.databaseEdit.name', Value: this.state.formFieldObj.databaseEdit.name, Type: "text", Options: []},
      {Name: "Tier", PropertyName: 'formFieldObj.databaseEdit.tier', Value: this.state.formFieldObj.databaseEdit.tier, Type: "select", Options:
        [{text: "----------", value: ''},
        {text:"Level 0", value: 0},
        {text:"Level 1", value: 1},
        {text:"Level 2", value: 2}
      ]},
      {Name: "API Version", PropertyName: 'formFieldObj.databaseEdit.api_version', Value: this.state.formFieldObj.databaseEdit.api_version, Type: "text-disabled", Options:[]}]
    const addUserFields = [
      {Name: "Username", PropertyName: 'formFieldObj.user.username', Value: this.state.formFieldObj.user.username, Type: "text", Options: []},
      {Name: "Password", PropertyName: 'formFieldObj.user.password', Value: this.state.formFieldObj.user.password, Type: "text", Options: []},
      {Name: "Email", PropertyName: 'formFieldObj.user.email', Value: this.state.formFieldObj.user.email, Type: "email", Options: []},
      {Name: "User Group", PropertyName: 'formFieldObj.user.userGroup', Value: this.state.formFieldObj.user.userGroup, Type: "select",
        Options:
          [{text: "----------", value: ''},
          {text: "Select Users", value: 0},
          {text:"Insert/Update Users", value: 1},
          {text:"Administrator", value: 2}
        ]}
    ]
    const addMinerTypeFields = [
      {Name: "Name", PropertyName: 'formFieldObj.minerType.name', Value: this.state.formFieldObj.minerType.name, Type: "text", Options: []},
      {Name: "Avg Heat", PropertyName: 'formFieldObj.minerType.avgHeat', Value: this.state.formFieldObj.minerType.avgHeat, Type: "int", Options: []},
      {Name: "Card Number", PropertyName: 'formFieldObj.minerType.cardNumber', Value: this.state.formFieldObj.minerType.cardNumber, Type: "int", Options: []},
      {Name: "Total Speed", PropertyName: 'formFieldObj.minerType.totalSpeed', Value: this.state.formFieldObj.minerType.totalSpeed, Type: "float", Options: []},
      {Name: "Power Consumption", PropertyName: 'formFieldObj.minerType.powerConsumption', Value: this.state.formFieldObj.minerType.powerConsumption, Type: "float", Options: []},
      {Name: "Asic Number", PropertyName: 'formFieldObj.minerType.asicNumber', Value: this.state.formFieldObj.minerType.asicNumber, Type: "int", Options: []}
    ]
    const editMinerTypeFields = [
      {Name: "Name", PropertyName: 'formFieldObj.minerTypeEdit.name', Value: this.state.formFieldObj.minerTypeEdit.name, Type: "text", Options: []},
      {Name: "Avg Heat", PropertyName: 'formFieldObj.minerTypeEdit.avgHeat', Value: this.state.formFieldObj.minerTypeEdit.avgHeat, Type: "int", Options: []},
      {Name: "Card Number", PropertyName: 'formFieldObj.minerTypeEdit.cardNumber', Value: this.state.formFieldObj.minerTypeEdit.cardNumber, Type: "int", Options: []},
      {Name: "Total Speed", PropertyName: 'formFieldObj.minerTypeEdit.totalSpeed', Value: this.state.formFieldObj.minerTypeEdit.totalSpeed, Type: "float", Options: []},
      {Name: "Power Consumption", PropertyName: 'formFieldObj.minerTypeEdit.powerConsumption', Value: this.state.formFieldObj.minerTypeEdit.powerConsumption, Type: "float", Options: []},
      {Name: "Asic Number", PropertyName: 'formFieldObj.minerTypeEdit.asicNumber', Value: this.state.formFieldObj.minerTypeEdit.asicNumber, Type: "int", Options: []}
    ]
    const adjustUserPrivileges = [
      {Name: "User", PropertyName: 'formFieldObj.userPrivileges.user', Type: 'select', Value: this.state.formFieldObj.userPrivileges.user,
      Options:
      [{text: "----------", value: ''}].concat(this.props.adminStore.users.data.filter((item) => item.Level !== 2).map((item) => ({text: item.Username, value: item.Username})))},
      {Name: "Current Group", PropertyName: 'formFieldObj.userPrivileges.currentGroup',
      Type: 'text-disabled',
      Value: this.state.formFieldObj.userPrivileges.currentGroup,
      Options: []},
      {Name: "Customer", PropertyName: 'formFieldObj.userPrivileges.customer',
      Type: 'text-disabled',
      Value: this.state.formFieldObj.userPrivileges.customer,
      Options: []},
      {Name: "User Group", PropertyName: 'formFieldObj.userPrivileges.userGroup', Type: "select", Value: this.state.formFieldObj.userPrivileges.userGroup,
        Options:
          [{text: "----------", value: ''},
          {text: "Select Users", value: 0},
          {text:"Insert/Update Users", value: 1},
          {text:"Administrator", value: 2}
        ]}
    ]
    const adjustSystemPrivileges = [
      {Name: "User", PropertyName: 'formFieldObj.systemPrivileges.user', Type: 'text-disabled', Value: this.state.formFieldObj.systemPrivileges.user, Options: []},
      {Name: "Current Group", PropertyName: 'formFieldObj.systemPrivileges.currentGroup', Type: 'text-disabled', Value: this.state.formFieldObj.systemPrivileges.currentGroup, Options: []},
      {Name: "User Group", PropertyName: 'formFieldObj.systemPrivileges.userGroup', Type: "select", Value: this.state.formFieldObj.systemPrivileges.userGroup,
        Options:
          [{text: "----------", value: ''},
          {text: "Select Users", value: 0},
          {text:"Insert/Update Users", value: 1},
          {text:"Administrator", value: 2}
        ]}]
    const systemUsersColums = [
      {field: 'Username', value: 'Username'},
      {field: 'Email', value: 'Email'},
      {field: 'Level', value: 'User Group'}]
    const databaseModalUserColumns = [
      {field: 'Username', value: 'Username'},
      {field: 'Level', value: 'User Group'}
    ]
    const minerTypesColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Avg_Heat', value: 'Heat'},
      {field: 'Card_Num', value: 'Cards'},
      {field: 'Power_Consumption', value: 'Power(W)'},
      {field: 'Speed_Readable', value: 'Speed'},
      {field: 'Asic_Num', value: 'Asics'}
    ]
    const minerTypesButtons = [
      {class: 'ViewBody-button-edit', onClick: this.editMinerType, type: 'Edit', extractKey: 'ID'},
      {class: 'ViewBody-button-delete', onClick: this.deleteMinerType, type: 'Delete', extractKey: 'ID'}]
    const databasesColums = [
      {field: 'client', value: 'Database'},
      {field: 'users', value: 'Current Users', func: 'length'},
      {field: 'version', value: 'Database Version'},
      {field: 'tier', value: 'Tier'}]
      /*
      {field: 'Scan_IP', value: 'Scan IP'},
      {field: 'Scan_Range', value: 'Scan Range'},
      */
    const scannersColumns = [
      {field: 'Database_Name', value: 'Database'},
      {field: 'IP_Address', value: 'IP Address'},
      {field: 'Name', value: 'Name'},
      {field: 'Scan_Subnet', value: 'Scan Range'},
      {field: 'Scanner_Agent_Status', value: 'Agent Status'},
      {field: 'Scanner_Agent_Version', value: 'Agent Version'},
      {field: 'Last_Scan', value: 'Last Seen'}]
    const editScannerColumns = [
      {Name: "Database Name", PropertyName: 'formFieldObj.editScanner.Database_Name', Value: this.state.formFieldObj.editScanner.Database_Name, Type: "select",
        Options: [{text: "----------", value: ''}].concat(this.props.adminStore.clients.map((item) => ({text: item.client, value: item.client})))},
      {Name: "IP Address", PropertyName: 'formFieldObj.editScanner.IP_Address', Value: this.state.formFieldObj.editScanner.IP_Address, Type: "text", Options: []},
      {Name: "Scanner Name", PropertyName: 'formFieldObj.editScanner.Name', Value: this.state.formFieldObj.editScanner.Name, Type: "text", Options: []},
      {Name: "Scan Start IP", PropertyName: 'formFieldObj.editScanner.Scan_IP', Value: this.state.formFieldObj.editScanner.Scan_IP, Type: "text", Options: []},
      {Name: "Scan Range", PropertyName: 'formFieldObj.editScanner.Scan_Range', Value: this.state.formFieldObj.editScanner.Scan_Range, Type: "select",
      Options: [{text: "----------", value: ''},
        {text: "21", value: "21"},
        {text:"22", value: "22"},
        {text:"23", value: "23"},
        {text:"24", value: "24"}
      ]},
      {Name: "Time between scans (s)", PropertyName: 'formFieldObj.editScanner.Scan_Time_Target', Value: this.state.formFieldObj.editScanner.Scan_Time_Target, Type: "int", Options: []},
      {Name: "Starting Threads", PropertyName: 'formFieldObj.editScanner.Threads', Value: this.state.formFieldObj.editScanner.Threads, Type: "int", Options: []},
      {Name: "Threads Threshold", PropertyName: 'formFieldObj.editScanner.Threads_Threshold', Value: this.state.formFieldObj.editScanner.Threads_Threshold, Type: "int", Options: []}
    ]
    const addScannerField = [
      {Name: "Database Name", PropertyName: 'formFieldObj.addScanners.database', Value: this.state.formFieldObj.addScanners.database, Type: "select",
        Options: [{text: "----------", value: ''}].concat(this.props.adminStore.clients.map((item) => ({text: item.client, value: item.client})))},
      {Name: "IP Address", PropertyName: 'formFieldObj.addScanners.ip_address', Value: this.state.formFieldObj.addScanners.ip_address, Type: "text", Options: []},
      {Name: "Scanner Name", PropertyName: 'formFieldObj.addScanners.name', Value: this.state.formFieldObj.addScanners.name, Type: "text", Options: []},
      {Name: "Scan Start IP", PropertyName: 'formFieldObj.addScanners.scan_ip', Value: this.state.formFieldObj.addScanners.scan_ip, Type: "text", Options: []},
      {Name: "Scan Range", PropertyName: 'formFieldObj.addScanners.scan_range', Value: this.state.formFieldObj.addScanners.scan_range, Type: "select",
      Options: [{text: "----------", value: ''},
        {text: "21", value: "21"},
        {text:"22", value: "22"},
        {text:"23", value: "23"},
        {text:"24", value: "24"}
      ]},
      {Name: "Time between scans (s)", PropertyName: 'formFieldObj.addScanners.scan_time_Target', Value: this.state.formFieldObj.addScanners.scan_time_Target, Type: "int", Options: []},
      {Name: "Starting Threads", PropertyName: 'formFieldObj.addScanners.threads', Value: this.state.formFieldObj.addScanners.threads, Type: "int", Options: []},
      {Name: "Threads Threshold", PropertyName: 'formFieldObj.addScanners.threads_threshold', Value: this.state.formFieldObj.addScanners.threads_threshold, Type: "int", Options: []}
    ]
    const databaseButtons = [
      {class: 'ViewBody-button-edit', onClick: e => alert("edit database will be added when subviews are implemented for admin"), type: 'Edit', extractKey: 'client'},
      {class: 'ViewBody-button-delete', onClick: this.deleteDatabase, type: 'Delete', extractKey: 'client'}]
    const scannersButtons = [
      {class: 'ViewBody-button-edit', onClick: this.rebootScanner, type: 'Reboot', extractKey: 'IP_Address'},
      {class: 'ViewBody-button-edit', onClick: this.deployScanner, type: 'Deploy_Scanner_Agent', extractKey: 'IP_Address'},
      {class: 'ViewBody-button-edit', onClick: this.editScanner, type: 'Edit', extractKey: 'IP_Address'}]
    const systemUserButtons = [
      {class: 'ViewBody-button-edit', onClick: this.editUser, type: 'Edit', extractKey: 'Username'},
      {class: 'ViewBody-button-delete', onClick: this.deleteUser, type: 'Delete', extractKey: 'Username'}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    const tabs = [
      {name: 'Users', avatar: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/></svg>},
      {name: 'Databases', avatar: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M14,8h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2Zm0,4h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2ZM9,8h1a1,1,0,0,0,0-2H9A1,1,0,0,0,9,8Zm0,4h1a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,8H20V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm-8,0H11V16h2Zm5,0H15V15a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1v5H6V4H18Z"/></svg>},
      {name: 'Scanners', avatar: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M6,13H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V14A1,1,0,0,0,6,13ZM5,21H3V15H5ZM22,9H18a1,1,0,0,0-1,1V22a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V10A1,1,0,0,0,22,9ZM21,21H19V11h2ZM14,1H10A1,1,0,0,0,9,2V22a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V2A1,1,0,0,0,14,1ZM13,21H11V3h2Z"/></svg>},
      {name: 'Miner Type', avatar: (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M20,8.18V3a1,1,0,0,0-2,0V8.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V13.82a3,3,0,0,0,0-5.64ZM19,12a1,1,0,1,1,1-1A1,1,0,0,1,19,12Zm-6,2.18V3a1,1,0,0,0-2,0V14.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V19.82a3,3,0,0,0,0-5.64ZM12,18a1,1,0,1,1,1-1A1,1,0,0,1,12,18ZM6,6.18V3A1,1,0,0,0,4,3V6.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V11.82A3,3,0,0,0,6,6.18ZM5,10A1,1,0,1,1,6,9,1,1,0,0,1,5,10Z"/></svg>)},
      {name: 'Report', avatar: (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr"><path d="M15.396,2.292H4.604c-0.212,0-0.385,0.174-0.385,0.386v14.646c0,0.212,0.173,0.385,0.385,0.385h10.792c0.211,0,0.385-0.173,0.385-0.385V2.677C15.781,2.465,15.607,2.292,15.396,2.292 M15.01,16.938H4.99v-2.698h1.609c0.156,0.449,0.586,0.771,1.089,0.771c0.638,0,1.156-0.519,1.156-1.156s-0.519-1.156-1.156-1.156c-0.503,0-0.933,0.321-1.089,0.771H4.99v-3.083h1.609c0.156,0.449,0.586,0.771,1.089,0.771c0.638,0,1.156-0.518,1.156-1.156c0-0.638-0.519-1.156-1.156-1.156c-0.503,0-0.933,0.322-1.089,0.771H4.99V6.531h1.609C6.755,6.98,7.185,7.302,7.688,7.302c0.638,0,1.156-0.519,1.156-1.156c0-0.638-0.519-1.156-1.156-1.156c-0.503,0-0.933,0.322-1.089,0.771H4.99V3.062h10.02V16.938z M7.302,13.854c0-0.212,0.173-0.386,0.385-0.386s0.385,0.174,0.385,0.386s-0.173,0.385-0.385,0.385S7.302,14.066,7.302,13.854 M7.302,10c0-0.212,0.173-0.385,0.385-0.385S8.073,9.788,8.073,10s-0.173,0.385-0.385,0.385S7.302,10.212,7.302,10 M7.302,6.146c0-0.212,0.173-0.386,0.385-0.386s0.385,0.174,0.385,0.386S7.899,6.531,7.688,6.531S7.302,6.358,7.302,6.146"/></svg>) }
    ]
    if(level !== 2){
      return <Redirect to='/' />
    }
    else{
      switch (this.state.headerTabActive) {
        default:
        case 'Users':
          return(
            <div className="adminView-container">
                {/* create a componenet that will act as a header with dynamic tabs */}
                <TabHeader
                  logo={
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                      <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                    </svg>
                  }
                  title="Server Admin"
                  subtitle="Manage scanner, databases & more"
                  tabs={tabs}
                  active={this.state.headerTabActive}
                  onClick={type => this.setState({ headerTabActive: type })}
                />
                {/* to start of let's switch over this.state.headerTabActive and create a component
                  for each value that will then render the AdminInfoTable and with a add button on top
                  then we might what to go to the additional view for each type (user, database, miner type, scanners ...)
                */}
                <div className="AdminView-content">
                  <ViewBody
                    data={this.props.adminStore.users.data}
                    columns={systemUsersColums}
                    buttons={systemUserButtons}
                    createtext="New user"
                    list
                    onCreate={e => this.setState({createUsersModalOpen: true})}
                    pager
                    sorting
                    search
                    searchValue={this.state.userSearchString}
                    onSearchChange={this.onUserSearch}
                    acending={this.props.userPage.acending}
                    sortedBy={this.props.userPage.orderBy}
                    onSort={this.onUserSort}
                    totalPages={this.props.adminStore.users.max_page}
                    currentPage={parseInt(this.props.adminStore.users.current_page) - 1}
                    onPageChange={this.onUserPageClick}
                  />
                </div>
                <Modal
                  open={this.state.createUsersModalOpen}
                  center
                  onClose={() => this.setState({createUsersModalOpen: false})}
                  styles={modalStyle}
                >
                  <FormfieldSection
                    title="Create new user"
                    fields={addUserFields}
                    addButton={true}
                    buttonText="Add"
                    onAddClick={this.onFormFieldAddClick}
                    onInputChange={this.onFormFieldInputChange}
                    action="add_user"
                  />
                </Modal>
                <Modal
                  open={this.state.editUserModalOpen}
                  center
                  onClose={() => this.setState({editUserModalOpen: false})}
                  styles={modalStyle}
                >
                <FormfieldSection
                  title="Adjust user system privileges"
                  fields={adjustSystemPrivileges}
                  addButton={true}
                  buttonText="Edit"
                  onAddClick={this.onFormFieldAddClick}
                  onInputChange={this.onFormFieldInputChange}
                  action="adjust_system_privileges"
                />
                </Modal>
            </div>
        )
          break;
        case 'Databases':
            return(
              <div className="adminView-container">
                  {/* create a componenet that will act as a header with dynamic tabs */}
                  <TabHeader
                    logo={
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                        <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                      </svg>
                    }
                    title="Server Admin"
                    subtitle="Manage scanner, databases & more"
                    tabs={tabs}
                    active={this.state.headerTabActive}
                    onClick={type => this.setState({ headerTabActive: type })}
                  />
                  <div className="AdminView-content">
                    <ViewBody
                      data={this.props.adminStore.clients}
                      columns={databasesColums}
                      buttons={databaseButtons}
                      createtext="New database"
                      list
                      onCreate={e => this.setState({createDatabaseModalOpen: true})}
                    />
                  </div>
                  <Modal
                    open={this.state.createDatabaseModalOpen}
                    center
                    onClose={() => this.setState({createDatabaseModalOpen: false})}
                    styles={modalStyle}
                  >
                    <FormfieldSection
                      title="Create new database / customer"
                      fields={addDatabaseFields}
                      addButton={true}
                      buttonText="Add"
                      onAddClick={this.onFormFieldAddClick}
                      onInputChange={this.onFormFieldInputChange}
                      action="add_database"
                    />
                  </Modal>
              </div>
          )
          break;
        case 'Miner Type':
          return(
              <div className="adminView-container">
                  {/* create a componenet that will act as a header with dynamic tabs */}
                  <TabHeader
                    logo={
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                        <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                      </svg>
                    }
                    title="Server Admin"
                    subtitle="Manage scanner, databases & more"
                    tabs={tabs}
                    active={this.state.headerTabActive}
                    onClick={type => this.setState({ headerTabActive: type })}
                  />
                  <div className="AdminView-content">
                    <ViewBody
                      data={this.props.typeStore.allTypes.data}
                      columns={minerTypesColumns}
                      buttons={minerTypesButtons}
                      createtext="New miner type"
                      list
                      itemClickInfo={{name: 'minertype', property: 'ID'}}
                      onCreate={e => this.setState({createMinerTypeModalOpen: true})}
                      pager
                      sorting
                      search
                      searchValue={this.state.minerTypeSearchString}
                      onSearchChange={this.onMinerTypeSearch}
                      acending={this.props.minerTypePage.acending}
                      sortedBy={this.props.minerTypePage.orderBy}
                      onSort={this.onMinerTypeSort}
                      totalPages={this.props.typeStore.allTypes.max_page}
                      currentPage={parseInt(this.props.typeStore.allTypes.current_page) - 1}
                      onPageChange={this.onMinerTypePageClick}
                    />
                  </div>
                  <Modal
                    open={this.state.createMinerTypeModalOpen}
                    center
                    onClose={() => this.setState({createMinerTypeModalOpen: false})}
                    styles={modalStyle}
                  >
                    <FormfieldSection
                      title="Create new miner type"
                      fields={addMinerTypeFields}
                      addButton={true}
                      buttonText="Add"
                      onAddClick={this.onFormFieldAddClick}
                      onInputChange={this.onFormFieldInputChange}
                      action="add_miner_type"
                    />
                  </Modal>
                  <Modal
                    open={this.state.editMinerTypeModalOpen}
                    center
                    onClose={() => this.setState({editMinerTypeModalOpen: false})}
                    styles={modalStyle}
                  >
                    <FormfieldSection
                      title="Edit miner type"
                      fields={editMinerTypeFields}
                      addButton={true}
                      buttonText="Edit"
                      onAddClick={this.onFormFieldAddClick}
                      onInputChange={this.onFormFieldInputChange}
                      action="edit_miner_type"
                    />
                  </Modal>
              </div>
            )
          break;
        case 'Scanners':
          return(
            <div className="adminView-container">
                {/* create a componenet that will act as a header with dynamic tabs */}
                <TabHeader
                  logo={
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                      <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                    </svg>
                  }
                  title="Server Admin"
                  subtitle="Manage scanner, databases & more"
                  tabs={tabs}
                  active={this.state.headerTabActive}
                  onClick={type => this.setState({ headerTabActive: type })}
                />
                <div className="AdminView-content">
                  <ViewBody
                    data={this.props.adminStore.scanners.data}
                    columns={scannersColumns}
                    buttons={scannersButtons}
                    createtext="New scanner"
                    list
                    onCreate={e => this.setState({createScannerModalOpen: true})}
                    pager
                    sorting
                    search
                    searchValue={this.state.scannerSearchString}
                    onSearchChange={this.onScannerSearch}
                    acending={this.props.scannerPage.acending}
                    sortedBy={this.props.scannerPage.orderBy}
                    onSort={this.onScannerSort}
                    totalPages={this.props.adminStore.scanners.max_page}
                    currentPage={parseInt(this.props.adminStore.scanners.current_page) - 1}
                    onPageChange={this.onScannerPageClick}
                  />
                </div>
                <Modal
                  open={this.state.createScannerModalOpen}
                  center
                  onClose={() => this.setState({createScannerModalOpen: false})}
                  styles={modalStyle}
                >
                  <FormfieldSection
                    title="Create new scanner"
                    fields={addScannerField}
                    addButton={true}
                    buttonText="Add"
                    onAddClick={this.onFormFieldAddClick}
                    onInputChange={this.onFormFieldInputChange}
                    action="add_scanner"
                  />
                </Modal>
                <Modal
                  open={this.state.scannerEditModalOpen}
                  center
                  onClose={() => this.setState({scannerEditModalOpen: false})}
                  styles={modalStyle}
                >
                  <FormfieldSection
                    title="Edit scanner"
                    fields={editScannerColumns}
                    addButton={true}
                    buttonText="Edit"
                    onAddClick={this.onFormFieldAddClick}
                    onInputChange={this.onFormFieldInputChange}
                    action="edit_scanner"
                  />
                </Modal>
            </div>
          )
          break;
        case 'Report':
          return(
            <div className="adminView-container">
                {/* create a componenet that will act as a header with dynamic tabs */}
                <TabHeader
                  logo={
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                      <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                    </svg>
                  }
                  title="Server Admin"
                  subtitle="Manage scanner, databases & more"
                  tabs={tabs}
                  active={this.state.headerTabActive}
                  onClick={type => this.setState({ headerTabActive: type })}
                />
                <h1 style={{color: 'white'}}>New Farm report coming soon</h1>
            </div>
          )
          break;
      }
  }
  }
}
const mapStateToProps = (props) => ({
  userStore: props.userReducer,
  inventory: props.inventoryReducer.farmInventory,
  typeStore: props.typeReducer,
  clientsStore: props.clientReducer,
  accessStore: props.accessReducer,
  reportStore: props.reportReducer,
  adminStore: props.adminReducer,
  userPage: props.pageReducer.users,
  scannerPage: props.pageReducer.scanners,
  minerTypePage: props.pageReducer.minerType
})
export default connect(mapStateToProps, {
  renameCustomersAction,
  getFarmInventoryAction,
  postFarmInventoryAction,
  putFarmInventoryAction,
  getAccessLevelsAction,
  putAccessLevelsAction,
  getReportDataAction,
  getAllUsersAction,
  getCustomersNameAction,
  addDatabaseAction,
  createNewUserAction,
  addNewMinerTypeAction,
  adjustUsersPrivilegesAction,
  adjustSystemPrivilegesAction,
  deleteUserAction,
  deleteDatabaseAction,
  getAllTypesAction,
  deleteMinerTypeAction,
  editMinerTypeAction,
  updateDatabaseTierAction,
  getAllScannersAction,
  postScannerAction,
  rebootScannerAction,
  patchScannerAction,
  deployScannerAction,
  updatePageInfoAction
})(AdminView)

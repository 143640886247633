import React from 'react'
import PodcardItem from '../PodcardItem';
import './style.css';
const Podcard = ({ pods, houseName, onDeleteClick, privilegeLevel, deletePrivileges }) => {
  return (
    <div className="podCard">
      <table
        id="dtVerticalScrollExample"
        className="table table-striped table-bordered table-sm"
        cellSpacing="0"
        width="100%">
        <thead>
          <tr>
            <th>{houseName}</th>
          </tr>
        </thead>
        <tbody>
          {
            pods !== undefined ?
              (
                pods.map((item, key) => {
                  return (
                    <PodcardItem
                      key={key}
                      pod={item.Name}
                      id={item.ID}
                      onDeleteClick={onDeleteClick}
                      privilegeLevel={privilegeLevel}
                      deletePrivileges={deletePrivileges}
                    />
                  )
                })) : (
                <></>
              )
          }</tbody>
      </table>
    </div>
  )
}
export default Podcard;

import { rootRoute, poolsRoute, postPoolRoute, putPoolRoute, deletePoolRoute } from '../constants/routes';
const poolService = (  ) => {
  const editProfilePoolOrder = (token, client, id, body) => {
    var requestOptions = {
      method: 'PATCH',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/pools/profiles/${id}/order/${client}`, requestOptions)
    .then(res => res.json())
    .then(data => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getPools = (token, client) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    return fetch(`${poolsRoute}/${client}`, requestOptions)
    .then(res => res.json())
    .then(data => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          pools: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          pools: [],
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      pools: [],
    }));
  }
  const postPool = (token, client, body) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${postPoolRoute}/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          pools: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      pools: [],
    }))
  }
  const putPool = (token, client, body, id) => {
    var requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${putPoolRoute}/${id}/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          pools: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to edit item",
      pools: [],
    }))
  }
  const deletePool = (token, client, id) => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    return fetch(`${deletePoolRoute}/${id}/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          pools: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to edit item",
      pools: [],
    }))
  }
  const postProfile = (token, client, body) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${postPoolRoute}/profiles/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  const addToProfile = (token, client, id, body) => {
    var requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${postPoolRoute}/profiles/${id}/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  const removeFromProfile = (token, client, body) => {
    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${postPoolRoute}/profiles/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  const deleteProfile = (token, client, id) => {
    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    return fetch(`${postPoolRoute}/profiles/${id}/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  const getProfiles = (token, client) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    return fetch(`${postPoolRoute}/profiles/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  const getProfileByID = (token, client, id) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    return fetch(`${postPoolRoute}/profiles/${id}/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  const removeScannerFromProfile = (token, client, body) => {
    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/scanner/profiles/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  const removeMinerTypeFromProfile = (token, client, body) => {
    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/minertype/profiles/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  const addScannerToProfile = (token, client, id, body) => {
    var requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/scanner/profiles/${id}/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  const addMinerTypeToProfile = (token, client, id, body) => {
    var requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/minertype/profiles/${id}/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post data to server",
      data: [],
    }))
  }
  return {
    getPools,
    postPool,
    putPool,
    deletePool,
    postProfile,
    addToProfile,
    removeFromProfile,
    deleteProfile,
    getProfiles,
    getProfileByID,
    removeScannerFromProfile,
    removeMinerTypeFromProfile,
    addScannerToProfile,
    addMinerTypeToProfile,
    editProfilePoolOrder
  }
}

export default poolService()

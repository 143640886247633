import favoriteService from '../services/favoriteService';
import { deleteFavItemsConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
import { setSuccessMessageAction } from './setSuccessMessageAction';

export const deleteFavItemsAction = (token, id) => {
  return async(dispatch) => {
    const result = await favoriteService.deleteFavItems(token, id);
    if(result.ok){
    }
    else{
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(deleteFavItemsSuccess(result));
  }
}

const deleteFavItemsSuccess = (result) => ({
  type: deleteFavItemsConstant,
  payload: result
})

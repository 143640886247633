import { popHistoryConstant } from '../constants/actions'

export const popHistoryAction = () => {
  return dispatch => {
    dispatch(pushHistoySuccess())
  }
}

const pushHistoySuccess = () => ({
  type: popHistoryConstant
})

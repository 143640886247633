import { postMaintenanceConstant } from '../constants/actions'
import service from '../services/logService'
import { setErrorMessageAction } from './setErrorMessageAction';


export const postMaintenanceAction = (body, token, customer,  pageSize, pageNumber, orderBy, acending) => {
  return async(dispatch) => {
    try {
      const result = await service.postMaintenance(body, token, customer,  pageSize, pageNumber, orderBy, acending)
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      else{
        dispatch(success(result));
      }
    } catch (e) {
      console.log(e)
    }
  }
}

const success = (result) => ({
  type: postMaintenanceConstant,
  payload: result
})

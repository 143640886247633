import { getAlertSettingsConstant } from '../constants/actions';
import service from '../services/alertService';
import { setErrorMessageAction } from './setErrorMessageAction'

export const getAlertSettingsAction = (token, customer) => {
  return async(dispatch) =>{
    const result = await service.getAlertSettings(token, customer)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getAlertSettingsConstant,
  payload: result
})

import { updateMinerFlagTypeSeverityConstant } from '../constants/actions'
import service from '../services/flagService'



export const updateMinerFlagTypeSeverityAction = (id, body, token, customer) => {
  return async(dispatch) => {
    const result = await service.updateMinerFlagTypeSeverity(id, body, token, customer)
    dispatch(success(result))
  }
}



const success = (result) =>({
  type: updateMinerFlagTypeSeverityConstant,
  payload: result
})

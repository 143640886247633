import React from 'react';
import { connect } from 'react-redux';
import { getUserLogsByTimeAction } from '../../redux/actions/getUserLogByTimeAction';
import { getMinersByUserLogAction } from '../../redux/actions/getMinersByUserLogAction';
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { updateClientAction } from '../../redux/actions/updateClientAction'
import UserLogList from '../../Components/UserLogList'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ViewBody from '../../Components/ViewBody'
import moment from 'moment'
//import Modal from '../../Components/Modal'
//import FlexModalWrapper from 'react-modal-wrapper';
import './style.css';
import './animation.css';

class UserlogView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchString: ''
    }
  }
  componentDidMount() {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.store.client !== this.props.match.params.name){
      const store = {
        pageSize: 25,
        pageNumber: 1,
        client: this.props.match.params.name,
        type: 'userLogs',
        orderBy: 'Time_Stamp',
        acending: false
      }
      this.props.updatePageInfoAction(store)
    }
    let node = document.getElementById("userLogLink")
    // not sure why this is happeing
    // the node is null afer a refresh
    if(node){
     node.classList.add("itemSelected")
    }
    const token = this.props.userStore.result;
    const client = this.props.match.params.name;
    this.props.getUserLogsByTimeAction(token, client, this.props.store.pageSize, this.props.store.pageNumber, this.props.store.orderBy, this.props.store.acending, this.state.searchString, this.props.match.params.region_id);
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  componentWillUnmount() {
    let node = document.getElementById("userLogLink")
    // not sure why this is happeing
    // the node is null afer a refresh
    if(node){
     node.classList.remove("itemSelected")
    }
    window.sessionStorage.removeItem('location')
  }
  //Function rusn when a log has been clicked and the modal pop ups with all information regarding that log.
  onClick = async (time) => {
    await this.props.getMinersByUserLogAction(this.props.userStore.result, this.props.match.params.name, time);
    this.setState({ open: true });
  }
  //Used to fetch more miners to render in the next page of the table.
  onPageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber !== 0 && pageNumber <= this.props.userLogStore.max_page){
      this.props.getUserLogsByTimeAction(this.props.userStore.result, this.props.match.params.name, this.props.store.pageSize, pageNumber, this.props.store.orderBy, this.props.store.acending, this.state.searchString, this.props.match.params.region_id);
    }
    const store = {
      pageSize: this.props.store.pageSize,
      pageNumber: pageNumber,
      client: this.props.match.params.name,
      type: 'userLogs',
      orderBy: this.props.store.orderBy,
      acending: this.props.store.acending
    }
    this.props.updatePageInfoAction(store)
  }
  onRowClick = async (link) => {
    this.props.history.push(link)
}
  //Sets the state of open to true or false. Open is used to display the modal.
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  //Sorts the table according to what column was selected.
  onSort = (type) => {
    let { acending, orderBy } = this.props.store;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getUserLogsByTimeAction(this.props.userStore.result, this.props.match.params.name, this.props.store.pageSize, this.props.store.pageNumber, type, acending, this.state.searchString, this.props.match.params.region_id);
    const store = {
      pageSize: this.props.store.pageSize,
      pageNumber: this.props.store.pageNumber,
      client: this.props.match.params.name,
      type: 'userLogs',
      orderBy: type,
      acending: acending
    }
    this.props.updatePageInfoAction(store);
  }
  //Changes the size of each page in the table.
  changePageSize = (e) => {
    const value = parseInt(e.target.value);
    let pageNum = this.props.store.pageNumber;
    const items = parseInt(this.props.userLogStore.max_page)*parseInt(this.props.userLogStore.page_size)
    if(value > items){
      pageNum = 1;
    }
    if((this.props.store.pageNumber*value) > items){
      pageNum = 1;
    }
    this.props.getUserLogsByTimeAction(this.props.userStore.result, this.props.match.params.name, value, pageNum, this.props.store.orderBy, this.props.store.acending, this.state.searchString, this.props.match.params.region_id);
    const store = {
      pageSize: value? value:0,
      pageNumber: pageNum,
      client: this.props.match.params.name,
      type: 'userLogs',
      orderBy: this.props.store.type,
      acending: this.props.store.acending
    }
    this.props.updatePageInfoAction(store);
  }
  onSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.store
    this.props.getUserLogsByTimeAction(this.props.userStore.result, this.props.match.params.name, pageSize, 1, orderBy, acending, e.target.value, this.props.match.params.region_id);
    this.setState({
      searchString: e.target.value
    })
    const store = {
      pageSize: pageSize,
      pageNumber: 1,
      client: this.props.match.params.name,
      type: 'userLogs',
      orderBy: this.props.store.type,
      acending: this.props.store.acending
    }
    this.props.updatePageInfoAction(store);
  }
  render() {
    const { open} = this.state;
    this.props.userLogStore.userLogs.map((item) => item.Time_Stamp = moment(item.Time_Stamp).format('YYYY-MM-DD hh:mm:ss'))
    const columns = [
      {field: 'Username', value: 'Username'},
      {field: 'Command', value: 'Command'},
      {field: 'Amount', value: 'Amount'},
      {field: 'Time_Stamp', value: 'Timestamp'},
    ]
    return (
      <div className="userLogViewDisplay">
        <ViewBody
          data={this.props.userLogStore.userLogs}
          columns={columns}
          list
          pager
          sorting
          search
          currentPage={parseInt(this.props.userLogStore.current_page) - 1}
          totalPages={this.props.userLogStore.max_page}
          onSort={this.onSort}
          sortedBy={this.props.store.orderBy}
          acending={this.props.store.acending}
          pageSize={this.props.store.pageSize}
          changePageSize={this.changePageSize}
          searchValue={this.state.searchString}
          onSearchChange={this.onSearch}
          onPageChange={this.onPageClick}
        />
        <Modal
          open={open}
          onClose={this.onCloseModal}
          center
          classNames={{
            animationIn: 'EnterAnimation',
            animationOut: 'LeaveAnimation',
          }}
          animationDuration={500}>
          <h2>Affected Miners</h2>
          <div className="modalContainer">
            <div className="modaltbl-header">
              <table className="modalMinerTable" cellpadding="0" cellspacing="0" border="0">
                <thead>
                  <tr>
                    <th className="modalHeader">IP address</th>
                    <th className="modalHeader">MAC address</th>
                    <th className="modalHeader">Worker name</th>
                    <th className="modalHeader">Location</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="modaltbl-content">
              <table className="modalMinerTable" cellpadding="0" cellspacing="0" border="0">
                <tbody>
                  {this.props.userLogStore.miners.map((item) => {
                    const link = `/client/${this.props.match.params.name}/subnet/${item.Subnet_ID}/miner/${item.MAC_Address}`
                    return (
                      <tr className="modallistitems" onClick={e => this.onRowClick(link)}>
                        <td className="modalListRowChild">
                          {item.IP_Address}
                        </td>
                        <td className="modalListRowChild">
                          {item.MAC_Address}
                        </td>
                        <td className="modalListRowChild">
                          {item.Worker_Name}
                        </td>
                        <td className="modalListRowChild">
                          {item.Location}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
        {/*here we sould have a table with all the user logs, first by time
        then onlick we should display all the MAC_Address that are connected to that time */}
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  userLogStore: props.userLogsReducer,
  userStore: props.userReducer,
  store: props.pageReducer.userLogs,
  clientStore: props.clientReducer
})
export default connect(mapStateToProps, { getUserLogsByTimeAction, getMinersByUserLogAction, updatePageInfoAction, updateClientAction})(UserlogView)
/*
  <UserLogList
    onClick={this.onClick}
    onPageClick={this.onPageClick}
    items={this.props.userLogStore.userLogs}
    currentPage={this.props.userLogStore.current_page}
    maxPage={this.props.userLogStore.max_page}
    onSort={this.onSort}
    orderBy={this.props.store.orderBy}
    acending={this.props.store.acending}
    pageSize={this.props.store.pageSize}
    changePageSize={this.changePageSize}
  />
*/

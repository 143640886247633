import axios from 'axios';
import { rootRoute, changePasswordRoute, loginRoute  } from '../constants/routes';
const jwt = require('jwt-simple');

const userService = () => {
  const getUserData = (token) => {
    console.log("CALLING GET USER DATA")
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/user/data`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const updateSystemToken = (token) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/system/token`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getUsersMinerListColumns = (token) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/user/list/columns`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const updateUsersMinerListColumns = (userID, body, token) => {
    var requestOptions = {
      method: 'PATCH',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/user/${userID}/list/columns`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const microsoftAuth = (microsoftAuth) =>{
    const secret = jwt.encode({ldap: microsoftAuth, date: new Date()}, process.env.REACT_APP_ENCRYPT_KEY);
    return fetch(`${loginRoute}`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${secret}`},
    })
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          result: data.secret
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to login no response from server",
    }))
  }
  const login = (username, password) => {
    const secret = jwt.encode({username: username, password: password }, process.env.REACT_APP_ENCRYPT_KEY);
    return fetch(loginRoute, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${secret}`
      },
    })
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          result: data.success.data.token,
          token_id: data.success.data.token_id,
          mf_enabled: data.success.data.mf_enabled,
          username: data.success.data.username,
          mf_verified: data.success.data.mf_verified
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to login no response from server",
    }))
    //return axios.post(loginEndPoint, obj).then(d => d.json()).then(d => d);
  }
  // new idea is to decode the token, add a new property ({ newPassword: password})
  // and then on tha API we would decode the header
  const changepassword =(username, password, token) => {
    let tmpToken = jwt.decode(token, process.env.REACT_APP_DECRYPT_KEY);
    tmpToken.newPassword = password
    tmpToken = jwt.encode(tmpToken, process.env.REACT_APP_DECRYPT_KEY);
    return fetch(changePasswordRoute, {
      method: 'GET',
      headers: {'Content-Type': 'application/json',
                'Authorization': `Bearer ${tmpToken}`}
    })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(error => ({
      ok: false,
      msg: "failed to change password, no respose from server",
    }))
  }
  const postAuthCode = (token, authCode) => {
    return fetch(`${rootRoute}/auth/code`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`},
      body: JSON.stringify({authCode})
    })
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        console.log(data)
        return {
          ok: true,
          msg: "successful",
          result: data.success.data.token,
          token_id: data.success.data.token_id,
          username: data.success.data.username,
          level: data.success.data.level,
          databases: data.success.data.databases,
          mf_verified: data.success.data.mf_verified
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to post auth code, no respose from server",
    }))
  }
  const getQrCode = (token) =>{
    return fetch(`${rootRoute}/mf_qr_code`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`}
    })
    .then((res) => {
      return res.json()
    })
    .then((data) => {
      return data;
    })
    .catch(error => ({
      ok: false,
      msg: "failed to change password, no respose from server",
    }))
  }
  const addUserActivity = (token, type) => {
    return fetch(`${rootRoute}/activity/${type}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to login no response from server",
    }))
  }
  return {
    login,
    microsoftAuth,
    changepassword,
    getQrCode,
    postAuthCode,
    addUserActivity,
    getUsersMinerListColumns,
    updateUsersMinerListColumns,
    updateSystemToken,
    getUserData
  }
}


export default userService();

import React from 'react'
import Login from '../../Components/Login'
import { connect } from 'react-redux';
import { loginAction } from '../../redux/actions/loginAction'
import { loginMicrosoftAction } from '../../redux/actions/loginMicrosoftAction'
import { postAuthCodeAction } from '../../redux/actions/postAuthCodeAction'
import { logOutAction } from '../../redux/actions/logOutAction'
import { getQRCodeAction } from '../../redux/actions/getQRCodeAction'
import { Redirect } from "react-router-dom";
import LoginAuthenticator from '../../Components/LoginAuthenticator'
import './style.css';
import jwt from 'jwt-simple';

class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      token: '',
      gettingQR: false,
      authCode: ''
    }
    this.authHandler = this.authHandler.bind(this);
  }
  handleEnter = (event) => {
    if (event.key === "Enter") {
      this.onFormSubmit()
    }
  }
  decodeToken = (token) => {
    try {
      return jwt.decode(token, process.env.REACT_APP_DECRYPT_KEY);
    }
    catch (err){
      return {}
    }
  }
  pasteListener = (e) => {
    console.log(e)
  }
  componentDidMount() {
    //document.getElementById('root').classList.toggle("applyImage");
    document.addEventListener('cut/copy/paste', this.pasteListener)
    this.setState({ status: this.props.userStore.status });
    //window.addEventListener ? document.addEventListener('keydown', this.handleEnter) : document.attachEvent('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    //document.getElementById('root').classList.toggle("applyImage");
    //document.removeEventListener("keydown", this.handleEnter);
  }
  componentDidUpdate(){
    const { gettingQR } = this.state
    if(this.props.userStore.ok){
      const userData = this.decodeToken(this.props.userStore.result)
      if(!userData.MF_Enabled && !gettingQR){
        //this.getQrCode()
        this.setState({gettingQR: true})
      }
    }
  }
  //If either account or password changes this is run.
  onInput = (e) =>{
    this.setState({ [e.target.name]: e.target.value })
  }
  //Handles the login with account name and password.
  onFormSubmit = async(e) =>{
    const {username, password} = this.state;
    if(username == ''){
      alert("Enter a username")
      return
    }
    if(password == ''){
      alert("Enter a password")
      return
    }
    await this.props.loginAction(username, password);
    if (this.props.userStore.status) {
      this.setState({ token: this.props.userStore.result })
    }
    this.setState({ username: '', password: '' })
  }
  //Handles the login via Microsoft Login.
  authHandler = async(err,data) => {
    if(data !== undefined){
      await this.props.loginMicrosoftAction(data.account.userName);
    }
  }
  getQrCode = () => {
    this.props.getQRCodeAction(this.props.userStore.result)
  }
  submitCode = () => {
    const { authCode } = this.state
    console.log(this.props.userStore.result)
    this.props.postAuthCodeAction(this.props.userStore.result, authCode)
  }
  onAuthCodeChange = (e) => {
    this.setState({authCode: e})
    if(e.length === 6){
      this.props.postAuthCodeAction(this.props.userStore.result, e)
    }
  }
  logout = () => {
    this.props.logOutAction()
  }
  onPaste = async() => {
    const text = await navigator.clipboard.readText()
    this.setState({
      authCode: text
    })
    if(text.length === 6){
      this.props.postAuthCodeAction(this.props.userStore.result, text)
    }
  }
  render() {
    const { username, password } = this.state;
    const { userStore } = this.props
    if (this.props.userStore.ok) {
      const userData = this.decodeToken(userStore.result)
      if(userStore.mf_verified){
        // here we should validate the auth
        return <Redirect to='/' />
      }
      else{
        return (
          <div className="contentViewContainer">
            <div className="loginContainer">
                <LoginAuthenticator
                  token={userStore.result}
                  username={userStore.username}
                  displayQR={!userStore.mf_enabled}
                  code={this.state.authCode}
                  onChange={this.onAuthCodeChange}
                  submit={this.submitCode}
                  onBack={this.logout}
                  onPaste={this.onPaste}
                />
            </div>
          </div>
        )
      }
    }
    return (
      <div className="contentViewContainer">
        <div className="loginContainer">
          <Login
            username={username}
            password={password}
            onInput={this.onInput}
            onFormSubmit={this.onFormSubmit}
            authHandler={this.authHandler}
          />
        </div>
      </div>
    )
  }
}

const mapStateTopProps = (store) => ({ userStore: store.userReducer });
export default connect(mapStateTopProps, {
  loginAction,
  loginMicrosoftAction,
  getQRCodeAction,
  postAuthCodeAction,
  logOutAction
 })(LoginView)

import { getMinersWithFlagsConstant, setId_getMinersWithFlagConstant } from '../constants/actions'
import service from '../services/flagService'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'



export const getMinersWithFlagsAction = (token, client, pageSize, pageNumber, orderBy, acending, filterString, region_id) => {
  return async(dispatch) => {
    const actionId = Math.random() * (100000000 - 1) + 1;
    dispatch({type: setId_getMinersWithFlagConstant, payload: actionId})
    const result = await service.getMinersWithFlag(token, client, pageSize, pageNumber, orderBy, acending, filterString, region_id)
    dispatch(success(result, actionId))
  }
}

const success = (result, actionId) =>({
  type: getMinersWithFlagsConstant,
  payload: result,
  actionId
})

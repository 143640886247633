import { updateFlagSettingsByMinerTypeIDConstant  } from '../constants/actions'
import service from '../services/flagService'

export const updateFlagSettingsByMinerTypeIDAction = (id, body, token, customer) => {
  return async dispatch => {
    const result = await service.updateFlagSettingsByMinerTypeID(id, body, token, customer)
    dispatch(success(result))
  }
}


const success = (result) => ({
  type: updateFlagSettingsByMinerTypeIDConstant,
  payload: result
})

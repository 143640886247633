import React from 'react';
import './style.css'

const createli = (currentPage, maxPage, onClick) => {
  var tmpCurrentPage = currentPage;
  let returnVal = []
  if(currentPage > 1){
    returnVal.push(<span className="siderSpan" onClick={e => onClick(parseInt(currentPage)-1)}>&#60;</span>)
  }
  if(currentPage !== 1){
    returnVal.push(<li className="li-item" onClick={e => onClick(1)}>1</li>)
  }
  if(currentPage > 3){
    returnVal.push(<li>.  .  .</li>)
  }
  if(currentPage > 2 ){
    returnVal.push(<li className="li-item" onClick={e => onClick(currentPage-1)}>{currentPage-1}</li>)
  }
  returnVal.push(<li className="li-item current-page" onClick={e => onClick(currentPage)}>{currentPage}</li>)
  tmpCurrentPage++;
  if(currentPage !== maxPage){
    for(var i = 1; i  < 5; i++){
      if(tmpCurrentPage <= maxPage && (i !== 4) && (tmpCurrentPage !== "1")){
        const newVal = tmpCurrentPage;
        returnVal.push(<li className="li-item" onClick={e => onClick(newVal)}>{tmpCurrentPage}</li>)
      }
      if(i === 3 && tmpCurrentPage+1 < maxPage){
        returnVal.push(<li>.  .  .</li>)
        returnVal.push(<li className="li-item" onClick={e => onClick(maxPage)}>{maxPage}</li>)
      }
      tmpCurrentPage++;
    }
  }
  if(currentPage < maxPage){
    returnVal.push(<span className="siderSpan" onClick={e => onClick(parseInt(currentPage)+1)}>&#62;</span>)
  }
  return returnVal;
}

const Rangeslider = ({currentPage, maxPage, onClick}) => {
  return (
    <ul className="rangeUI">
    { createli(currentPage, maxPage, onClick)
    }
    </ul>
  )
}

export default Rangeslider

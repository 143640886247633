import poolService from '../services/poolService';
import { putPoolConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const putPoolAction = (token, client, body, id) => {
  return async(dispatch) => {
    try{
      const result = await poolService.putPool(token, client, body, id);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(putPoolsSuccess(result))
    }
    catch(err){
      console.log(err)
    }
  }
}
const putPoolsSuccess = (pools) => ({
  type: putPoolConstant,
  payload: pools
})

import { updateClientActionConstant } from '../constants/actions'


export const updateClientAction = (client, region) => {
  return (dispatch) => {
    dispatch(updateSuccess(client, region))
  }
}

const updateSuccess = (client, region) => ({
  type: updateClientActionConstant,
  payload: {currentClient: client, currentRegion: region}
})

import { getFavItemsConstant } from '../constants/actions';
import favoriteService from '../services/favoriteService';
import { setErrorMessageAction } from './setErrorMessageAction';

export const getFavItemsAction = (token) => {
  return async(dispatch) => {
    try{
      const result = await favoriteService.getFavItems(token);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(getFavItemsSuccess(result));
    }
    catch(err){
      console.log(err);
    }
  }
}
const getFavItemsSuccess = (result) => ({
  type: getFavItemsConstant,
  payload: result
})

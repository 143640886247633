import { getMonthlyRevenueConstant } from '../constants/actions'
import service from '../services/hashrateService'
export const getMonthlyRevenueAction = (token, customer, region_id) => {
  return async(dispatch) => {
    const result = await service.getMonthlyRevenue(token, customer, region_id);
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getMonthlyRevenueConstant,
  payload: result
})

import React from 'react'
import './style.css'



const DatabaseDistribution = ({ database, databaseIndex, power, unit, pods, onPodDeleteClick, onDatabaseDeleteClick }) => {
    return(
        <div className='powerCut-distribution-table-div'>
            <table className='powerCut-distribution-table'>
                <tbody>
                    <tr className='powerCut-distribution-table-tr'>
                        <td>Database</td>
                        <td>
                            <span>{database}</span>
                            <button type="button" className='btn powerCut-distribution-delete-button' onClick={e => onDatabaseDeleteClick(databaseIndex)}>
                                    <span>
                                        <div className="css-1cvxpvr">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr">
                                                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                                            </svg>
                                        </div>
                                    </span>
                                </button>
                        </td>
                    </tr>
                    <tr className='powerCut-distribution-table-tr'>
                        <td>Total Power</td>
                        <td>{power} {unit}</td>
                    </tr>
                    <tr className='powerCut-distribution-table-tr-title'>
                        <td colSpan={2}>PODS</td>
                    </tr>
                    
                    {pods.length > 0?(
                        pods.map((item, index) => (
                            <tr className='powerCut-distribution-table-tr-pod'>
                                <td colSpan={2}>
                                    <span>{item.label}</span>
                                    <button type="button" className='btn powerCut-distribution-delete-button' onClick={e => onPodDeleteClick(databaseIndex, index)}>
                                        <span>
                                            <div className="css-1cvxpvr">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 24 24" fill="currentColor" className="css-sr6nr">
                                                    <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                                                </svg>
                                            </div>
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        ))
                    ):(
                        <tr className='powerCut-distribution-table-tr-pod'>
                            <td colSpan={2}>
                                <span>No select - Evently distributed between pods</span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}


const NewPowerCutOverview = ({ description, total, unit, databases, onInputChange, onPodDeleteClick, onDatabaseDeleteClick }) => {
    return(
        <div className="formfieldSection-card">
            <div className="card-header">
                <strong>Create new power cut order</strong>
            </div>
            <div className="card-body">
                <div className="formfieldSection_form-group">
                    <span>Total Power:  {total} {unit}</span>
                </div>
                <div className="formfieldSection_form-group">
                    <label className="">Description</label>
                    <textarea rows="2" value={description} className="powerCut-distribution-textarea" name="newOrderDescription" onChange={e => onInputChange(e)} placeholder="Description"/>
                </div>
                <div className='powerCut-distribution-title-div'>
                    <span className='powerCut-distribution-title'>Database Distribution</span>
                </div>
                {databases.length > 0?(
                    databases.map((item, index) => (
                        <div className="formfieldSection_form-group">
                            <DatabaseDistribution
                                database={item.databaseName}
                                databaseIndex={index}
                                power={item.cutOrder}
                                unit={item.cutOrderUnit}
                                pods={item.pods}
                                onPodDeleteClick={onPodDeleteClick}
                                onDatabaseDeleteClick={onDatabaseDeleteClick}
                            />
                        </div>
                    ))
                ):(
                    <div>
                        <span>No databases selected - Please add atleast one Database/Customer</span>
                    </div>
                )}
                
            </div>
        </div>
    )
}


export default NewPowerCutOverview
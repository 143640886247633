import { patchPowerCutOrderStatusConstant } from '../constants/actions'
import service from '../services/powerCutOrderService'



export const patchPowerCutOrderStatusAction = (token, orderId, status, pageSize, pageNumber, orderBy, acending, searchValue, returnOrderID) => {
  return async(dispatch) => {
    const result = await service.patchPowerCutOrderStatus(token, orderId, status, pageSize, pageNumber, orderBy, acending, searchValue, returnOrderID)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: patchPowerCutOrderStatusConstant,
  payload: result
})

import React from 'react';
import './style.css'
import PropTypes from 'prop-types';
const createOption = (array) => {
  let retVal = []
  array.map((item, key) => {
    retVal.push(<option key={key}>{item.Name}</option>)
  })
  return retVal;
}
const ConfigurePodForm = ({podHouseName, houses, podName, onInputChange, onFormSubmit}) => {
  return(
    <div className="form-style-5">
    <form>
      <fieldset>
        <legend><span className="number">3</span>Pod Info</legend>
          <input type="text" id="podName" name="podName" defaultValue={podName} placeholder="Pod Name *" className="form-control" onInput={e => onInputChange(e)}/>
          <label htmlFor="podHouseName">Bulding Name</label>
          <select className="form-control form-control-sm" name="podHouseName" defaultValue={podHouseName} onInput={e => onInputChange(e)}>
            {createOption(houses)}
          </select>
      </fieldset>
      <div className="form-group">
          <input type="button" id="password" defaultValue="submit" onClick={e => onFormSubmit(e)}/>
      </div>
    </form>
    </div>
  )
}

ConfigurePodForm.propTypes = {
  podHouseName:PropTypes.string.isRequired,
  houses:PropTypes.array.isRequired,
  podName:PropTypes.string.isRequired,
  onInputChange:PropTypes.func.isRequired,
  onFormSubmit:PropTypes.func.isRequired,
}

export default ConfigurePodForm;
/*

  <form action="" className="add-froms form from-horizontal">
    <div className="form-group">
        <label htmlFor="podName">Pod Name</label>
        <input type="text" id="podName" name="podName" value={podName} className="form-control" onInput={e => onInputChange(e)}/>
    </div>
    <div className="from-group">
        <label htmlFor="podHouseName">House Name</label>
        <select className="form-control form-control-sm" name="podHouseName" value={podHouseName} onInput={e => onInputChange(e)}>
          {createOption(houses)}
        </select>
    </div>
    <div className="form-group">
        <input type="button" id="password" value="submit" className="btn btn-submit" onClick={e => onFormSubmit(e)}/>
    </div>
  </form>
  */

import { getSubnetsConnectionsByMinerFlagIDConstant } from '../constants/actions'
import service from '../services/flagService'



export const getSubnetsConnectionsByMinerFlagIDAction = (id, token, customer, region_id) => {
  return async(dispatch) => {
    const result = await service.getSubnetsConnectionsByMinerFlagID(id, token, customer, region_id)
    dispatch(success(result))
  }
}



const success = (result) =>({
  type: getSubnetsConnectionsByMinerFlagIDConstant,
  payload: result
})

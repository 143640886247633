import { setErrorMessage, setSuccessMessage, clearMessagePannelConstant } from '../constants/actions';
const defaultState = {
  errorMessage: '',
  successMessage: ''
}
export default(state = defaultState, action) => {
  switch (action.type) {
    case setSuccessMessage:
        const s1 = {
          errorMessage: '',
          successMessage: action.payload
        }
        return s1;
    case setErrorMessage:
        const s2 = {
          errorMessage: action.payload,
          successMessage: '',
        }
      return s2;
    case clearMessagePannelConstant:
      return {
        errorMessage: '',
        successMessage: ''
      }
    default:
      if(action.payload && action.payload.hasOwnProperty("token_validation") && !action.payload.token_validation.valid){
        if(action.payload.token_validation.disabled || !action.payload.token_validation.token){
          return{
            errorMessage: action.payload.token_validation.message,
            successMessage: ''
          }
        }
      }
      return state;
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { getMinerAction } from '../../redux/actions/getMinerAction';
import { getLogsByMacAction } from '../../redux/actions/getLogsByMacAction';
import { sendCommandAction } from '../../redux/actions/sendCommandAction';
import { setErrorMessageAction } from '../../redux/actions/setErrorMessageAction';
import { setSuccessMessageAction } from '../../redux/actions/setSuccessMessageAction';
import { mapMinersAction } from '../../redux/actions/mapMinersAction';
import { getMaintenanceBySNAction } from '../../redux/actions/getMaintenanceBySNAction'
import { clearMaintenanceAction } from '../../redux/actions/clearMaintenanceAction'
import { getHousesAction } from '../../redux/actions/getHousesAction'
import { getPodsLimitedAction } from '../../redux/actions/getPodsLimitedAction'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { postMaintenanceAction } from '../../redux/actions/postMaintenanceLogAction';
import { getInventoryByTypeAction } from '../../redux/actions/getInventoryByTypeAction';
import { markMinersDeletedAction } from '../../redux/actions/markMinersDeletedAction'
import { clearLocationsAction } from '../../redux/actions/clearLocationsAction'
import { getMinerCommentsAction } from '../../redux/actions/getMinerCommentsAction'
import { postCommentAction } from '../../redux/actions/postCommentAction'
import { clearCommentsAction } from '../../redux/actions/clearCommentsAction'
import { onSystemLogsPageClickConstant, changeSystemLogsPageSizeConstant, onErrorLogsPageClickConstant, changeErrorLogsPageSizeConstant } from '../../redux/constants/actions'
import { changePageSizeAction } from '../../redux/actions/changePageSizeAction'
import { onPageClickAction } from '../../redux/actions/onPageClickAction'
import { clearSystemLogsAction } from '../../redux/actions/clearSystemLogsAction'
import { clearErrorLogsAction } from '../../redux/actions/clearErrorLogsAction'
import { getMinerInternalLogsAction } from '../../redux/actions/getMinerInternalLogsAction'
import { switch_minerDetailsTable } from './functions'
import MapMinerSection from '../../Components/MapMinerSection'
import MinerDetails from '../../Components/MinerDetails';
import MinerLogList from '../../Components/MinerLogList';
import InventoryTable from '../../Components/InventoryTable'
import MinerDetailsTable_v1_2 from '../../Components/MinerDetailsTable_v1_2'
import MinerDetailsTable from '../../Components/MinerDetailsTable'
import MinerCommands from '../../Components/MinerCommands'
import MinerCommentSection from '../../Components/MinerCommentSection'
import MaintenanceSection from '../../Components/MaintenanceSection'
import SystemLogsSection from '../../Components/SystemLogsSection'
import ErrorLogSection   from '../../Components/ErrorLogSection'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import './style.css';

class MinerView extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      value: 0,
      client: '',
      token: '',
      setIndex: 0,
      houseName: '',
      houses: '',
      podName: '',
      pods: '',
      MAC_Address: '',
      Location: '1-1-1',
      rackNum: '',
      shelfNum: '',
      minerNum: '',
      description: '',
      otherInfo: '',
      serialNumber: '',
      items: [{
        type: "Fans",
        amount: 0
      }],
      showMinerCommandBody: false,
      showMapMinerBody: false,
      showMinerCommentBody: true,
      showMinerInfo:true,
      commentModalOpen: false,
      maintenanceModalOpen: false,
      maintenanceSectionOpen: false,
      systemLogsSectionOpen: false,
      errorLogsSectionOpen: true,
      repair_fans: 0,
      repair_controlboards: 0,
      repair_psu: 0,
      repair_cables: 0,
      repair_hashboards: 0,
      repair_description: '',
      repair_otherInfo: '',
      repair_SN: ''
    }
  }

  componentDidMount = async () => {
    // here we clear right away because if we enter a miner and leave it before the requests get back to the website
    // there will be old error from another miner here
    this.props.clearSystemLogsAction()
    this.props.clearErrorLogsAction()
    if(this.props.pageStore.client !== this.props.match.params.name || this.props.pageStore.MAC_Address !== this.props.match.params.miner){
      const store = {
        MAC_Address: this.props.match.params.miner,
        client: this.props.match.params.name,
        pageSize: 25,
        pageNumber: 1,
        orderBy: 'Time_Stamp',
        acending: true,
        type: 'minerLogsByMac'
      }
      this.props.updatePageInfoAction(store);
    }
    if(this.props.maintenanceLogPage.client !== this.props.match.params.name || this.props.maintenanceLogPage.MAC_Address !== this.props.match.params.miner){
      const store = {
        MAC_Address: this.props.match.params.miner,
        client: this.props.match.params.name,
        pageSize: 25,
        pageNumber: 1,
        orderBy: 'Log_Date',
        acending: true,
        type: 'maintenenceListByMAC'
      }
      this.props.updatePageInfoAction(store);
    }
    this.props.getMinerInternalLogsAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.miner)
    //this.props.getErrorLogsAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.miner)
    //setTimeout(this.props.getSystemLogsAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.miner), 10000)
    await this.initalSetup()
    const intervalID = setInterval(this.initalSetup, 60000);
    if(this.props.minerStore.miner.length > 0){
      const locationSplit = this.props.minerStore.miner[0].Location.split('-')
      if(locationSplit.length > 2){
        this.setState({rackNum: locationSplit[locationSplit.length-3], shelfNum: locationSplit[locationSplit.length-2], minerNum: locationSplit[locationSplit.length-1]})
      }
      this.props.getMinerCommentsAction(this.props.userStore.result, this.props.minerStore.miner[0].MAC_Address, this.props.match.params.name, 1, this.props.comments.page_size, this.props.comments.orderBy, this.props.comments.acending)
    }
    this.setState({intervalID})
  }

  initalSetup = async() => {
    const token   = this.props.userStore.result;
    let houseName = ''
    let podName   = ''
    let rackNum   = ''
    let shelfNum  = ''
    let minerNum  = ''
    let location  = ''
    await this.props.getMinerAction(token, this.props.match.params.name, this.props.match.params.miner);
    if(this.props.minerStore.miner.length > 0){
      this.props.getInventoryByTypeAction(token, this.props.match.params.name, this.props.minerStore.miner[0].Miner_Type)
      if(this.props.minerStore.miner[0].Serial_Number !== 'None'){
        this.setState({repair_SN: this.props.minerStore.miner[0].Serial_Number})
        await this.props.getMaintenanceBySNAction(token, this.props.match.params.name, this.props.minerStore.miner[0].Serial_Number, this.props.pageStore.pageSize, this.props.pageStore.pageNumber, this.props.pageStore.orderBy, this.props.pageStore.acending);
      }
    }
    await this.props.getLogsByMacAction(token, this.props.match.params.name, this.props.match.params.miner, this.props.pageStore.pageSize, this.props.pageStore.pageNumber, this.props.pageStore.orderBy, this.props.pageStore.acending);
    await this.props.getHousesAction(this.props.userStore.result, this.props.match.params.name);
    if (this.props.houseStore.houses.length > 0) {
      const house = this.props.houseStore.houses[0];
      await this.props.getPodsLimitedAction(this.props.userStore.result, house.ID, this.props.match.params.name);
      if (this.props.podStore.pods.length > 0) {
        houseName = house.Name
        podName = this.props.podStore.pods[0].Name
      }
    }
    if(this.props.minerStore.miner.length > 0){
      if(this.props.minerStore.miner[0].Location !== "None"){
        let split = this.props.minerStore.miner[0].Location.split('-');
        rackNum = Number(split[0])
        shelfNum = Number(split[1])
        minerNum = Number(split[2])
        location = this.props.minerStore.miner[0].Location
      }
    }
    this.setState({
      client: this.props.match.params.name,
      token: token, MAC_Address:
      this.props.match.params.miner,
      houseName,
      podName,
      rackNum,
      shelfNum,
      minerNum,
      Location: location
    });
  }
  componentWillUnmount = () => {
    const { intervalID } = this.state;
    this.props.clearMaintenanceAction()
    this.props.clearCommentsAction()
    this.props.clearSystemLogsAction()
    this.props.clearErrorLogsAction()
    clearInterval(intervalID)
  }
  onMapItemInput = (e) => {
    this.setState({
      [e.target.name]: Number(e.target.value)
    }, () => {
      let location = `${this.state.rackNum}-${this.state.shelfNum}-${this.state.minerNum}`
      this.setState({ Location: location })
    });
  }
  //Runs when a command is clicked
  onCommandClick = async (mac, type) => {
    if(type == "delete"){
      this.props.markMinersDeletedAction(this.props.userStore.result, [mac], this.props.match.params.name)
    }
    else if(type == "clearLocation"){
      this.props.clearLocationsAction(this.props.userStore.result, this.props.match.params.name, {macs:[mac]}, false)
    }
    else{
      await this.props.sendCommandAction(this.props.userStore.result, this.props.match.params.name, { Macs: [mac] }, type);
      if (this.props.commandStore.ok) {
        this.setState({ successMessage: this.props.commandStore.msg.code })
        this.timeoutId = setTimeout(function () {
          this.setState({ successMessage: '' })
        }.bind(this), 3000);
      }
      else {
        this.setState({ errorMessage: this.props.commandStore.msg.code })
        this.timeoutId = setTimeout(function () {
          this.setState({ errorMessage: '' })
        }.bind(this), 3000);
      }
    }
  }
  //Each time a input is configured this runs
  onInputConfigure = async (e) => {
    if (e.target.name === 'houseName') {
      const { token, client } = this.state;
      this.setState({ houseName: e.target.value })
      const house = this.props.houseStore.houses.filter((item) => item.Name === e.target.value);
      await this.props.getPodsLimitedAction(token, house[0].ID, client);
      if (this.props.podStore.ok) {
        if (this.props.podStore.pods.length > 0) {
          this.setState({ podName: this.props.podStore.pods[0].Name });
        }
      }
    }
    else if (e.target.name === 'podName') {
      this.setState({ podName: e.target.value })
    }

  }
  //Run when the client has made his modification and hits submit button
  onFormSubmit = async (e) => {
    const { token, client } = this.state;
    let podID = JSON.parse(JSON.stringify(this.props.podStore.pods));
    let houseID = JSON.parse(JSON.stringify(this.props.houseStore.houses));
    podID = podID.filter((item) => item.Name === this.state.podName);
    houseID = houseID.filter((item) => item.Name === this.state.houseName)
    const items = [{
      MAC_Address: this.state.MAC_Address,
      Location: `${this.state.rackNum}-${this.state.shelfNum}-${this.state.minerNum}`,
      rackNum: this.state.rackNum,
      shelfNum: this.state.shelfNum,
      minerNum: this.state.minerNum
    }]
    await this.props.mapMinersAction(token, client, { Pod_ID: podID[0].ID, House: houseID[0], items: items });
    if (this.props.mapResult.ok) {
      const { success } = this.props.mapResult;
      if (success.hasOwnProperty("macsNotFound")) {
        const { macsNotFound } = success;
        this.props.setErrorMessageAction(`number of macs not found: ${macsNotFound.length}`)
      }
      if (success.hasOwnProperty("unSuccessfulLocations")) {
        let items = []
        const { unSuccessfulLocations } = success;
        unSuccessfulLocations.map((item) => {
          let split = item.Location.split('-');
          const newObj = {
            MAC_Address: item.MAC_Address,
            Location: item.Location,
            rackNum: split[0],
            shelfNum: split[1],
            minerNum: split[2],
          }
          items.push(newObj);
        })
        this.setState({ items: items });
        this.props.setSuccessMessageAction(`success: ${success.msg}, number of loacations taken: ${unSuccessfulLocations.length}`)
      }
      else {
        const items = [{
          MAC_Address: '',
          Location: '1-1-1',
          rackNum: 1,
          shelfNum: 1,
          minerNum: 1
        }]
        this.props.setSuccessMessageAction(success.msg);
      }
    }
    else {
      /*
      this.setState({errorMessage: this.props.mapResult.msg})
      this.timeoutId = setTimeout(function(){
        this.setState({errorMessage: ''})
      }.bind(this), 3000);
      */
    }
  }
  //Function used for going back via the back button on the browser or the Navigation Tree
  onBack = async () => {
    this.props.history.goBack()
  }

  onClick = async (link) => {
  }
  //Used to fetch more logs to render in the next page of the table.
  onPageClick = (pageNumber) => {
    const { pageSize, orderBy, acending } = this.props.pageStore;
    if (pageNumber !== 0 && pageNumber <= this.props.minerLogsStore.max_page) {
      this.props.getLogsByMacAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.miner, pageSize, pageNumber, orderBy, acending);
    }
    const store = {
      MAC_Address: this.props.match.params.miner,
      client: this.props.pageStore.client,
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'minerLogsByMac'
    }
    this.props.updatePageInfoAction(store);
  }
  onMaintenancePageClick = (pageNumber) => {
    const { pageSize, orderBy, acending } = this.props.maintenanceLogPage;
    if (pageNumber !== 0 && pageNumber <= this.props.maintenanceLog.max_page) {
      if(this.props.minerStore.miner[0].Serial_Number !== 'None'){
        this.props.getMaintenanceBySNAction(this.props.userStore.result, this.props.match.params.name, this.props.minerStore.miner[0].Serial_Number, pageSize, pageNumber, orderBy, acending);
      }
    }
    const store = {
      MAC_Address: this.props.match.params.miner,
      client: this.props.pageStore.client,
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'maintenenceListByMAC'
    }
    this.props.updatePageInfoAction(store);
  }
  onMaintenanceChangePageSize = (e) => {
    const { orderBy, acending } = this.props.maintenanceLogPage;
    const value = parseInt(e.target.value)
    let pageNum = this.props.maintenanceLogPage.pageNumber;
    if(value > parseInt(this.props.maintenanceLog.max_page)*parseInt(this.props.maintenanceLog.page_size)){
      pageNum = 1;
    }
    if(this.props.minerStore.miner[0].Serial_Number !== 'None'){
      this.props.getMaintenanceBySNAction(this.props.userStore.result, this.props.match.params.name, this.props.minerStore.miner[0].Serial_Number, value, pageNum, orderBy, acending);
    }
    const store = {
      MAC_Address: this.props.match.params.miner,
      pageSize: value? value: 0,
      pageNumber: pageNum,
      client: this.props.match.params.name,
      type: 'maintenenceListByMAC',
      orderBy: this.props.maintenanceLogPage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onMaintenanceSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.maintenanceLogPage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    if(this.props.minerStore.miner[0].Serial_Number !== 'None'){
      this.props.getMaintenanceBySNAction(this.props.userStore.result, this.props.match.params.name, this.props.minerStore.miner[0].Serial_Number, pageSize, pageNumber, type, acending);
    }
    const store = {
      MAC_Address: this.props.match.params.miner,
      client: this.props.maintenanceLogPage.client,
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'maintenenceListByMAC'
    }
    this.props.updatePageInfoAction(store);
  }
  //Used to sort the log table.
  onSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.pageStore;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getLogsByMacAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.miner, pageSize, pageNumber, type, acending);
    const store = {
      MAC_Address: this.props.match.params.miner,
      client: this.props.pageStore.client,
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'minerLogsByMac'
    }
    this.props.updatePageInfoAction(store);
  }
  onMaintenanceSubmit = () => {
    const { items, description, otherInfo, serialNumber } = this.state;
    if(items[0].amount == 0){
      alert("You need to pick atleast one item")
      return
    }
    if(description == ''){
      alert("You need to write some description")
      return
    }
    if(serialNumber == ''){
      alert("You need to insert the serial number of the miner")
      return
    }
    this.props.postMaintenanceAction(
      {Description: description, Other_Info: otherInfo, MAC_Address: this.props.match.params.miner, items, Serial_Number: serialNumber},
      this.props.userStore.result,
      this.props.match.params.name,
      this.props.maintenanceLogPage.pageSize,
      this.props.maintenanceLogPage.pageNumber,
      this.props.maintenanceLogPage.orderBy,
      this.props.maintenanceLogPage.acending
    )
    this.setState({items: [{type: 'Fans', amount: 0}], description: '', otherInfo: '', serialNumber: ''})
  }
  //Changes the size of each page in the table
  changePageSize = (e) => {
      const { orderBy, acending } = this.props.pageStore;
      const value = parseInt(e.target.value)
      let pageNum = this.props.pageStore.pageNumber;
      if(value > parseInt(this.props.minerLogsStore.max_page)*parseInt(this.props.minerLogsStore.page_size)){
        pageNum = 1;
      }
      this.props.getLogsByMacAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.miner, value, pageNum, orderBy, acending);
      const store = {
        MAC_Address: this.props.match.params.miner,
        pageSize: value? value: 0,
        pageNumber: this.props.pageStore.pageNumber,
        client: this.props.match.params.name,
        type: this.props.pageStore.type,
        orderBy: this.props.pageStore.orderBy,
        acending: acending
      }
      this.props.updatePageInfoAction(store)
  }
  //Handles the change between Details, Log and Map miner page.
  handleChange = (event, value) => {
    this.setState({
      value: value
    });
  }
  changeCommentPageSize = (e) => {
    this.props.getMinerCommentsAction(this.props.userStore.result, this.props.minerStore.miner[0].MAC_Address, this.props.match.params.name, 1, e.target.value, this.props.comments.orderBy, this.props.comments.acending)
  }
  onCommentPageClick = (pageNumber) => {
    this.props.getMinerCommentsAction(this.props.userStore.result, this.props.minerStore.miner[0].MAC_Address, this.props.match.params.name, pageNumber, this.props.comments.page_size, this.props.comments.orderBy, this.props.comments.acending)
  }
  onCommentOrder = (type) => {
    let { orderBy, acending } = this.props.comments
    if(orderBy == type){
      acending = !acending
    }
    else{
      acending = true
    }
    this.props.getMinerCommentsAction(this.props.userStore.result, this.props.minerStore.miner[0].MAC_Address, this.props.match.params.name, this.props.comments.current_page, this.props.comments.page_size, type, acending)
  }
  postComment = () => {
    const element = document.getElementById('comment-textarea')
    if(element){
      this.props.postCommentAction(this.props.userStore.result, {comment: element.value}, this.props.minerStore.miner[0].MAC_Address, this.props.match.params.name, 'Miner_Details')
    }
    this.setState({ commentModalOpen: false })
  }
  postRepair = () => {
    let items = []
    const { repair_otherInfo, repair_description, repair_SN, repair_psu, repair_fans, repair_cables, repair_hashboards, repair_controlboards } = this.state
    if(repair_SN == ''){
      alert("the serial number is missing")
      return
    }
    if(repair_description == ''){
      alert("the decription is missing")
      return
    }
    if(repair_psu !== 0){
      items.push({type: 'PSU', amount: repair_psu})
    }
    if(repair_fans !== 0){
      items.push({type: 'Fans', amount: repair_fans})
    }
    if(repair_cables !== 0){
      items.push({type: 'Cables', amount: repair_cables})
    }
    if(repair_hashboards !== 0){
      items.push({type: 'Hashboards', amount: repair_hashboards})
    }
    if(repair_controlboards !== 0){
      items.push({type: 'Controlboards', amount: repair_controlboards})
    }
    this.props.postMaintenanceAction(
      {Description: repair_description, Other_Info: repair_otherInfo, MAC_Address: this.props.match.params.miner, items, Serial_Number: repair_SN},
      this.props.userStore.result,
      this.props.match.params.name,
      this.props.maintenanceLogPage.pageSize,
      this.props.maintenanceLogPage.pageNumber,
      this.props.maintenanceLogPage.orderBy,
      this.props.maintenanceLogPage.acending
    )
    this.setState({ repair_otherInfo: '', repair_description: '', repair_SN: '', repair_psu: 0, repair_fans: 0, repair_cables: 0, repair_hashboards: 0, repair_controlboards: 0})
  }
  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  onSystemLogsPageClick = (pageNumber) => {
    if(pageNumber != this.props.systemLogs.currentPage){
      this.props.onPageClickAction(pageNumber, onSystemLogsPageClickConstant)
    }
  }
  onSystemLogsPageSizeChange = (e) => {
    if(e.target.value != this.props.systemLogs.pageSize){
      this.props.changePageSizeAction(e.target.value, changeSystemLogsPageSizeConstant)
    }
  }
  onErrorLogsPageClick = (pageNumber) => {
    if(pageNumber != this.props.errorLogs.currentPage){
      this.props.onPageClickAction(pageNumber, onErrorLogsPageClickConstant)
    }
  }
  onErrorLogsPageSizeChange = (e) => {
    if(e.target.value != this.props.errorLogs.pageSize){
      this.props.changePageSizeAction(e.target.value, changeErrorLogsPageSizeConstant)
    }
  }
  render() {
    const { description, otherInfo, items, serialNumber, showMinerCommandBody, showMapMinerBody, showMinerCommentBody, shelfNum, minerNum, rackNum, showMinerInfo, maintenanceSectionOpen } = this.state
    const miner = this.props.minerStore.miner[0];
    if (miner !== null || miner !== undefined) {
    }
    let value;
    if (this.state === null) {
      this.setState({
        value: 0
      })
    }
    else {
      value = this.state.value;
    }
    const styles = {
       modal: {
         width: '100%'
       }
     };
    return (
      <div className="minerViewDisplay">
            {miner !== undefined ? (
              <div className='minerDetailsDisplay'>
                <MinerCommands
                  onClick={this.onCommandClick}
                  showBody={showMinerCommandBody}
                  onMinimize={e => this.setState({ showMinerCommandBody: !showMinerCommandBody})}
                />
                <div className="minerView_minerDetailsBox">
                  <div className="adc_minerInfoHeaderDiv">
                    <div className="adc_minerInfoHeader">
                      <i className="fa fa-info-circle" style={{fontSize: '22px'}}></i>
                      <span>Infomation Section</span>
                    </div>
                    <div className="adc_minerInfoButtonDiv">
                      <button type="button" className="btn btn-box-tool" onClick={e => this.setState({ showMinerInfo: !showMinerInfo })}>{showMinerInfo?(<i className="fa fa-minus"/>):(<i className="fa fa-plus"/>)}</button>
                    </div>
                  </div>
                  {showMinerInfo?(
                    <div className="minerView_minerDetailsBoxHeader">
                      {switch_minerDetailsTable(this.props.minerStore.api_version, miner)}
                    </div>
                  ):(<></>)}
                </div>
                <MinerCommentSection
                  showBody={showMinerCommentBody}
                  onMinimize={e => this.setState({showMinerCommentBody: !showMinerCommentBody})}
                  comments={this.props.comments.data}
                  pageSize={this.props.comments.page_size}
                  currentPageSize={this.props.comments.data.length}
                  changePageSize={this.changeCommentPageSize}
                  maxPage={this.props.comments.max_page}
                  onPageClick={this.onCommentPageClick}
                  currentPage={this.props.comments.current_page}
                  addComment={e => this.setState({ commentModalOpen: true })}
                  onOrderClick={this.onCommentOrder}
                  orderBy={this.props.comments.orderBy}
                  acending={this.props.comments.acending}
                />
                <ErrorLogSection
                  items={this.props.errorLogs.displayData}
                  onPageClick={this.onErrorLogsPageClick}
                  pageSize={this.props.errorLogs.pageSize}
                  currentPage={this.props.errorLogs.currentPage}
                  maxPage={this.props.errorLogs.maxPage}
                  currentPageSize={this.props.errorLogs.displayData.length}
                  changePageSize={this.onErrorLogsPageSizeChange}
                  showBody={this.state.errorLogsSectionOpen}
                  onMinimize={e => this.setState({errorLogsSectionOpen: !this.state.errorLogsSectionOpen})}
                  status={this.props.errorLogs.status}
                />
                <MaintenanceSection
                  showBody={maintenanceSectionOpen}
                  onMinimize={e => this.setState({maintenanceSectionOpen: !maintenanceSectionOpen})}
                  spareParts={this.props.inventory[0]}
                  maintenanceItems={this.props.maintenanceLog.logs}
                  onPageClick={this.onMaintenancePageClick}
                  client={this.props.match.params.name}
                  currentPage={this.props.maintenanceLog.current_page}
                  maxPage={this.props.maintenanceLog.max_page}
                  onClick={this.onMaintenanceClick}
                  orderBy={this.props.maintenanceLogPage.orderBy}
                  acending={this.props.maintenanceLogPage.acending}
                  onSort={this.onMaintenanceSort}
                  pageSize={this.props.maintenanceLogPage.pageSize}
                  changePageSize={this.onMaintenanceChangePageSize}
                  currentPageSize={this.props.maintenanceLog.logs.length}
                  addMaintenance={e => this.setState({maintenanceModalOpen: true})}
                  onSort={this.onMaintenanceSort}
                />
                <MapMinerSection
                  buildings={this.props.houseStore.houses}
                  buildingName={this.state.houseName}
                  pods={this.props.podStore.pods}
                  podName={this.state.podName}
                  submit={this.onFormSubmit}
                  rackNum={rackNum}
                  shelfNum={shelfNum}
                  minerNum={minerNum}
                  mac={miner.MAC_Address}
                  onMinimize={e => this.setState({showMapMinerBody: !showMapMinerBody})}
                  showBody={showMapMinerBody}
                  onInputChange={this.onInputConfigure}
                  onMapItemInput={this.onMapItemInput}
                />
                <SystemLogsSection
                  items={this.props.systemLogs.displayData}
                  onPageClick={this.onSystemLogsPageClick}
                  pageSize={this.props.systemLogs.pageSize}
                  currentPage={this.props.systemLogs.currentPage}
                  maxPage={this.props.systemLogs.maxPage}
                  currentPageSize={this.props.systemLogs.displayData.length}
                  changePageSize={this.onSystemLogsPageSizeChange}
                  showBody={this.state.systemLogsSectionOpen}
                  onMinimize={e => this.setState({systemLogsSectionOpen: !this.state.systemLogsSectionOpen})}
                  status={this.props.systemLogs.status}
                />
              </div>
            ) : <></>}
            <Modal
              open={this.state.commentModalOpen}
              onClose={() => this.setState({commentModalOpen: false})}
              styles={styles}
              >
              <div className="adc_minerView-commentModal">
                <div className="adc_minerView-commentModalHeader">
                  <h4><i className="fa fa-comment"/>Add comment</h4>
                </div>
                <div className="adc_minerView-commentModalBody">
                  <label for="comment-area">New comment</label>
                  <textarea id="comment-textarea" name="comment-area"></textarea>
                </div>
                <div className="adc_minerView-commentModalFooter">
                  <button type="button" className="adc_commentTable-addComment" onClick={e => this.postComment()}>
                    <i className="fa fa-comment"/>
                    Add comment
                  </button>
                  <button type="button" className="btn btn-danger" onClick={e => this.setState({commentModalOpen: false})}>Close</button>
                </div>
              </div>
            </Modal>
            <Modal
              open={this.state.maintenanceModalOpen}
              onClose={() => this.setState({maintenanceModalOpen: false})}
              styles={styles}
              >
              <div className="adc_minerView-commentModal">
                <div className="adc_minerView-reparModalHeader">
                  <h4><i className="fa fa-wrench wrench"/>Add repair</h4>
                </div>
                <div className="adc_minerView-commentModalBody">
                  <table
                    className="table dataTable no-footer"
                    cellSpacing="0"
                    cellPadding='0'
                    width="100%"
                    >
                    <tbody>
                      <tr>
                        <td><span>Fans</span></td>
                        <td><input name="repair_fans" type="number" className="adc_maintenanceSection-input" value={this.state.repair_fans} onInput={e => this.onInputChange(e)}/></td>
                      </tr>
                      <tr>
                        <td><span>PSU</span></td>
                        <td><input name="repair_psu" type="number" className="adc_maintenanceSection-input" value={this.state.repair_psu} onInput={e => this.onInputChange(e)}/></td>
                      </tr>
                      <tr>
                        <td><span>Cables</span></td>
                        <td><input name="repair_cables" type="number" className="adc_maintenanceSection-input" value={this.state.repair_cables} onInput={e => this.onInputChange(e)}/></td>
                      </tr>
                      <tr>
                        <td><span>Hashboards</span></td>
                        <td><input name="repair_hashboards" type="number" className="adc_maintenanceSection-input" value={this.state.repair_hashboards} onInput={e => this.onInputChange(e)}/></td>
                      </tr>
                      <tr>
                        <td><span>Controlboards</span></td>
                        <td><input name="repair_controlboards" type="number" className="adc_maintenanceSection-input" value={this.state.repair_controlboards} onInput={e => this.onInputChange(e)}/></td>
                      </tr>
                    </tbody>
                  </table>
                  <label for="repair-SN">Serial Number*</label>
                  <input name="repair_SN" type="text" className="adc_maintenanceSection-input" value={this.state.repair_SN} onInput={e => this.onInputChange(e)} placeholder="enter serialNumber"/>
                  <label for="repair_description">Description*</label>
                  <textarea className="adc_maintenanceSection-input" name="repair_description" onInput={e => this.onInputChange(e)}></textarea>
                  <label for="repair_otherInfo">Other Info</label>
                  <textarea className="adc_maintenanceSection-input" name="repair_otherInfo" onInput={e => this.onInputChange(e)}></textarea>
                </div>
                <div className="adc_minerView-commentModalFooter">
                  <button type="button" className="adc_commentTable-addComment" onClick={e => this.postRepair()}>
                    <i className="fa fa-wrench wrench"/>
                    Add repair
                  </button>
                  <button type="button" className="btn btn-danger" onClick={e => this.setState({maintenanceModalOpen: false})}>Close</button>
                </div>
              </div>
            </Modal>
      </div>
    )
  }
}

const mapStateTopProps = (props) => ({
  minerStore: props.minerReducer,
  userStore: props.userReducer,
  commandStore: props.commandReducer,
  minerLogsStore: props.minerLogsReducer,
  mapResult: props.mapMinersReducer,
  houseStore: props.houseReducer,
  podStore: props.podsReducer,
  pageStore: props.pageReducer.minerLogsByMac,
  maintenanceLogPage: props.pageReducer.maintenenceListByMAC,
  maintenanceLog: props.maintenanceReducer,
  inventory: props.inventoryReducer.inventory,
  comments: props.minerCommentsReducer,
  systemLogs: props.systemLogsReducer,
  errorLogs: props.errorLogsReducer
})
export default connect(mapStateTopProps, {
  updatePageInfoAction,
  getMinerAction,
  getLogsByMacAction,
  sendCommandAction,
  mapMinersAction,
  getHousesAction,
  setErrorMessageAction,
  setSuccessMessageAction,
  getMaintenanceBySNAction,
  postMaintenanceAction,
  getInventoryByTypeAction,
  clearMaintenanceAction,
  markMinersDeletedAction,
  clearLocationsAction,
  getMinerCommentsAction,
  postCommentAction,
  clearCommentsAction,
  changePageSizeAction,
  onPageClickAction,
  clearSystemLogsAction,
  clearErrorLogsAction,
  getMinerInternalLogsAction,
  getPodsLimitedAction
 })(MinerView)

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { Modal } from "react-responsive-modal";
import { onTabHeaderClick, onInputChange } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { deletePoolAction } from '../../redux/actions/deletePoolAction';
import { getPoolsAction } from '../../redux/actions/getPoolsAction';
import { postPoolAction } from '../../redux/actions/postPoolAction';
import { putPoolAction } from '../../redux/actions/putPoolAction';
import { updateClientAction } from '../../redux/actions/updateClientAction'
import './style.css'

class PoolsView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      worker: '',
      url: '',
      password: '',
      currentEditId: '',
      editWorker: '',
      editURL: '',
      editPassword: '',
      addModalOpen: false,
      editModalOpen: false
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    this.props.getPoolsAction(this.props.token, this.props.match.params.name)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onDelete = (id) => {
    window.confirm("Are you sure you wish to delete this Pool?") &&
      this.props.deletePoolAction(this.props.token, this.props.match.params.name, id)
  }
  onEditClick = (id) => {
    const item = this.props.poolStore.filter((item) => item.ID == id)
    if(item.length > 0){
      this.setState({
        editWorker: item[0].Worker,
        editURL: item[0].URL,
        editPassword: item[0].Password,
        currentEditId: id,
        editModalOpen: true
      })
    }
  }
  onAdd = () => {
    const { worker, url, password } = this.state
    this.props.postPoolAction(this.props.token, this.props.match.params.name, { URL: url, Worker: worker, Password: password })
    this.setState({ pool: '', worker: '', password: '', addModalOpen: false })
  }
  onEdit = () => {
    const { editWorker, editPassword, editURL, currentEditId } = this.state
    this.props.putPoolAction(this.props.token, this.props.match.params.name, { URL: editURL, Worker: editWorker, Password: editPassword }, currentEditId);
    this.setState({
      editModalOpen: false,
      editWorker: '',
      editURL: '',
      editPassword: '',
      currentEditId: ''
    })
  }
  render(){
    const { userData } = this.props
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    const columns = [
      {field: 'URL', value: 'URL'},
      {field: 'Worker', value: 'User'},
      {field: 'Password', value: 'Password'}]
    const buttons = userData.level >= 2 ?([{class: 'ViewBody-button-edit', onClick: this.onEditClick, type: 'Edit', extractKey: 'ID'},{class: 'ViewBody-button-delete', onClick: this.onDelete, type: 'Delete', extractKey: 'ID'}]):([{class: 'ViewBody-button-edit', onClick: this.onEditClick, type: 'Edit', extractKey: 'ID'}])
    const addPoolFields = [
      {Name: 'URL', PropertyName: 'url', Value: this.state.url, Type: 'text', Options: []},
      {Name: 'User', PropertyName: 'worker', Value: this.state.worker, Type: 'text', Options: []},
      {Name: 'Password', PropertyName: 'password', Value: this.state.password, Type: 'text', Options: []}]
    const editPoolField = [
      {Name: 'URL', PropertyName: 'editURL', Value: this.state.editURL, Type: 'text', Options: []},
      {Name: 'User', PropertyName: 'editWorker', Value: this.state.editWorker, Type: 'text', Options: []},
      {Name: 'Password', PropertyName: 'editPassword', Value: this.state.editPassword, Type: 'text', Options: []}]

    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="PoolsView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={userData.level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
          active="Pools"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={this.props.poolStore}
            columns={columns}
            buttons={buttons}
            createtext="New pool"
            list
            onCreate={e => this.setState({addModalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.addModalOpen}
          center
          onClose={() => this.setState({ addModalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create new pool"
            fields={addPoolFields}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAdd}
            onInputChange={e => onInputChange(e, this)}
            action="add_pool"
          />
        </Modal>
        <Modal
          open={this.state.editModalOpen}
          center
          onClose={() => this.setState({
            editModalOpen: false,
            editWorker: '',
            editURL: '',
            editPassword: '',
            currentEditId: ''
          })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Edit pool"
            fields={editPoolField}
            addButton={true}
            buttonText="Edit"
            onAddClick={this.onEdit}
            onInputChange={e => onInputChange(e, this)}
            action="edit_pool"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  poolStore: props.poolReducer.pools,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  deletePoolAction,
  getPoolsAction,
  postPoolAction,
  putPoolAction,
  updateClientAction
})(PoolsView)

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange, filterCheck  } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { deleteTypeAction } from '../../redux/actions/deleteTypeAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { getMinerFlagTypesAction } from '../../redux/actions/getMinerFlagTypesAction'
import { getMinerFlagSeverityAction } from '../../redux/actions/getMinerFlagSeverityAction'
import { updateMinerFlagTypeSeverityAction } from '../../redux/actions/updateMinerFlagTypeSeverityAction'

import './style.css'

class FlagConfigView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      modalOpen: false,
      editId: 0,
      editSeverityPick: 0
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    this.props.getMinerFlagTypesAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id)
    this.props.getMinerFlagSeverityAction(this.props.token, this.props.match.params.name)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onEdit = (id) => {
    console.log(id)
    this.setState({
      editId: id,
      modalOpen: true
    })
  }
  onEditSubmit = () => {
    const { editId, editSeverityPick } = this.state
    const body = {
      Severity_ID: editSeverityPick
    }
    console.log(editId, editSeverityPick)
    this.props.updateMinerFlagTypeSeverityAction(editId, body, this.props.token, this.props.match.params.name)
    this.setState({
      editId: 0,
      modalOpen: false
    })
  }
  onSeverityPick = (e) => {
    this.setState({
      editSeverityPick: e.ID
    })
  }
  render(){
    const { level } = this.props.userData
    const databaseTier = this.props.userData.databases.length > 0? this.props.userData.databases[0].Tier: 0
    const { editId } = this.state
    const columns = [
      {field: 'Name', value: 'Name'},
      {field: 'Severity_Description', value: 'Severity'},
      {field: 'Scanners', value: 'Scanners'}]
    const buttons = [
        {class: 'ViewBody-button-edit', onClick: this.onEdit, type: 'Edit', extractKey: 'ID'}
    ]
    let placeholder = this.props.flagStore.flag_types.filter((item) => item.ID == editId)
    if(placeholder.length > 0){
      placeholder = placeholder[0].Severity_Description
    }
    else{
      placeholder = ''
    }
    const pickFlagSeverity = [
        {Name: 'Pick Severity', PropertyName: 'none', Value: '----------', Type: 'react-single-select', Placeholder: placeholder, Options: this.props.flagStore.flag_severity.map((item) => ({ label: item.Description, value: item.ID, ID: item.ID }))}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="AlertsView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
          active="Flag Setting"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={this.props.flagStore.flag_types}
            columns={columns}
            buttons={buttons}
            list
            onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
            itemClickInfo={{name: 'flagsetting', property: 'ID'}}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Change Severity"
            fields={pickFlagSeverity}
            addButton={true}
            buttonText="Edit"
            onAddClick={this.onEditSubmit}
            onInputChange={this.onSeverityPick}
            action="add_miner_type"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  clientStore: props.clientReducer,
  flagStore: props.flagReducer
})

export default connect(mapStateToProps, {
  updateClientAction,
  getMinerFlagTypesAction,
  getMinerFlagSeverityAction,
  updateMinerFlagTypeSeverityAction
})(FlagConfigView)

import React from 'react';
import './style.css'
import PropTypes from 'prop-types';
const createOption = (array) => {
  let retVal = []
  array.map((item,key) => {
    retVal.push(<option key={key}>{item.Name}</option>)
  })
  return retVal;
}
const ConfigureHouseSubnet = ({houseConnectionName, subnetConnectionName, houses , subnets, onInputChange, onFormSubmit}) => {
  return(
    <div className="form-style-5">
    <form>
      <fieldset>
        <legend><span className="number">2</span>Scanner/Houses </legend>
          <label htmlFor="subnetConnectionName">Scanner Name</label>
          <select className="form-control form-control-sm" name="subnetConnectionName" defaultValue={subnetConnectionName} onInput={e => onInputChange(e)}>
            {createOption(subnets)}
          </select>
          <label htmlFor="houseConnectionName">Building Name</label>
          <select className="form-control form-control-sm" name="houseConnectionName" defaultValue={houseConnectionName} onInput={e => onInputChange(e)}>
            {createOption(houses)}
          </select>
      </fieldset>
      <div className="form-group">
          <input type="button" id="password" defaultValue="submit" onClick={e => onFormSubmit(e)}/>
      </div>
    </form>
    </div>
  )
}

ConfigureHouseSubnet  .propTypes = {
  houseConnectionName:PropTypes.string.isRequired,
  subnetConnectionName:PropTypes.string.isRequired,
  houses:PropTypes.array.isRequired,
  subnets: PropTypes.array.isRequired,
  onInputChange:PropTypes.func.isRequired,
  onFormSubmit:PropTypes.func.isRequired,
}

export default ConfigureHouseSubnet;
/*

  <form action="" className="add-froms form from-horizontal">
    <div className="form-group">
        <label htmlFor="podName">Pod Name</label>
        <input type="text" id="podName" name="podName" value={podName} className="form-control" onInput={e => onInputChange(e)}/>
    </div>
    <div className="from-group">
        <label htmlFor="podHouseName">House Name</label>
        <select className="form-control form-control-sm" name="podHouseName" value={podHouseName} onInput={e => onInputChange(e)}>
          {createOption(houses)}
        </select>
    </div>
    <div className="form-group">
        <input type="button" id="password" value="submit" className="btn btn-submit" onClick={e => onFormSubmit(e)}/>
    </div>
  </form>
  */

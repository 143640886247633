import { getAccessLevelsConstant } from '../constants/actions'
import service from '../services/accessService';
import { setErrorMessageAction } from './setErrorMessageAction';

export const getAccessLevelsAction = (token) => {
  return async(dispatch) => {
    const result = await service.getAccessLevels(token)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getAccessLevelsConstant,
  payload: result
})

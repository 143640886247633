import clientService from '../services/clientService';
import { getMinerByMacConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';

export const getLogsByMacAction = (token, client, mac, pageSize, pageNumber, orderBy, acending) => {
  return async (dispatch) => {
    try{
      const result = await clientService.getMinerByMac(token, client, mac, pageSize, pageNumber, orderBy, acending);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(getMinerByMacSuccess(result));
    }
    catch(err){
      console.log(err)
    }
  }
}

const getMinerByMacSuccess = ( miners ) => ({
  type: getMinerByMacConstant,
  payload: miners
})

import { postTuningProfilesFirmwareConnectionsConstant } from '../../constants/actions'
import service from '../../services/tuningService'




export const postTuningProfilesFirmwareConnectionsAction = (body, token, mode, customer) => {
    return async(dispatch) => {
        const result = await service.postTuningProfilesFirmwareConnections(body, token, mode, customer)
        dispatch(success(result))
    }
}
const success = (result) =>({
  type: postTuningProfilesFirmwareConnectionsConstant,
  payload: result
})

import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { getSubnetsAction } from '../../redux/actions/getSubnetsAction'
import { getFavItemsAction } from '../../redux/actions/getFavItemsAction'
import LeftBar from '../../Components/LeftBar'
import './style.css';
import LogView from '../LogView';
import MapView from '../MapView';
import ListView from '../ListView'
import MinerView from '../MinerView';
import HeatmapView from '../HeatmapView'
import ConfigureView from '../ConfigureView';
import SubnetView from '../SubnetView';
import ClientView from '../ClientView'
import HouseView from '../HouseView'
import PodView from '../PodView'
import UserlogView from '../UserlogView';
import MaintenanceView from '../MaintenanceView'
import FilteredView from '../FilteredView'
import AdminView from '../AdminView'
import SidebarOne from '../../Components/SidebarOne';

class ClientSwitchView extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      subnets: [],
    }
  }
  componentDidMount = async() => {
    const token = this.props.userStore.result;
    ///await this.props.getSubnetsAction(token, this.props.client);
    this.props.getFavItemsAction(token);
    //this.setState({ subnets: this.props.subnetStore.subnets})
  }
  render() {
    return (
          <Switch>
            <Route exact path="/admin" component={AdminView} />
            <Route exact path="/client/:name/subnet/:subnet/miner/:miner" component={MinerView} />
            <Route exact path="/client/:name/pod/:pod/miner/:miner" component={MinerView} />
            <Route exact path="/client/:name/miner/:miner" component={MinerView} />
            <Route exact path="/client/:name/pod/:pod" component={PodView}/>
            <Route exact path="/client/:name/map" component={MapView} />
            <Route exact path="/client/:name/subnet/:subnet" component={SubnetView} />
            <Route exact path="/client/:name/log" component={LogView} />
            <Route exact path="/client/:name/userLog" component={UserlogView} />
            <Route exact path="/client/:name/miners/:type" component={FilteredView} />
            <Route exact path="/client/:name/miners" component={ListView} />
            <Route exact path="/client/:name/heatmaps" component={HeatmapView} />
            <Route exact path="/client/:name/configure" component={ConfigureView} />
            <Route exact path="/client/:name/maintenance" component={MaintenanceView} />
            <Route exact path="/client/:name" component={ClientView} />
            <Route exact path="/client/:name/house/:house" component={HouseView}/>
            <Route exact path="/client/:name/house/:house/pod/:pod" component={PodView}/>
          </Switch>
    )
  }
}
const mapStateTopProps = (props) => ({
  subnetStore: props.subnetReducer,
  userStore: props.userReducer,
  favItemStore: props.favItemReducer
 })
export default connect(mapStateTopProps, {getFavItemsAction, getSubnetsAction})(ClientSwitchView)

/*

<Route exact path="/client/:name/subnet/:subnet/miner/:miner" component={MinerView} />
<Route exact path="/client/:name/map" component={MapView} />
<Route exact path="/client/:name/subnet/:subnet" component={SubnetView} />
<Route exact path="/client/:name/log" component={LogView} />
<Route exact path="/client/:name/pool" component={PoolView} />
*/

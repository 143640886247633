import {
  rootRoute,
  getDatabases,
  getSubnetsRoute,
  getMinersRoute,
  getMinerRoute,
  getMinerLogsRoute,
  mapMinersRoute,
  getHousesRoute,
  getPodsRoute,
  getPodsLimitedRoute,
  postHousesRoute,
  postPodsRoute,
  getMinersByPodRoute,
  deletePodRoute,
  deleteHouseRoute,
  getLogsByMacRoute,
  reportRoute
} from '../constants/routes';
const clientService = () => {
  const getDbs = (token) => {
    return fetch(getDatabases, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const renameCustomers = (token, body) => {
    return fetch(`${getDatabases}/rename`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const patchCustomerScanner = (token, client, region_id, body, scannerName) => {
    return fetch(`${rootRoute}/subnets/${scannerName}/${client}/region/${region_id}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const postCustomerScanner = (token, client, region_id, body) => {
    return fetch(`${rootRoute}/subnets/${client}/region/${region_id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getSubnets = (token, client, region_id) => {
    return fetch(`${getSubnetsRoute}/${client}/region/${region_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getHouses = (token,client, region_id) => {
    return fetch(`${getHousesRoute}/${client}/region/${region_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          houses: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          houses: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      houses: [],
    }));
  }
  const deleteHouse = (token, client, id, region_id) => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${deleteHouseRoute}/${id}/${client}/region/${region_id}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          houses: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          houses: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getMiners = (token, client, subnet, pageSize, pageNumber, orderBy, acending, filterString) => {
    return fetch(`${getMinersRoute}/${subnet}/${client}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&filter[]=${filterString}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          page_size: data.success.page_size,
          current_page: data.success.current_page,
          max_page: data.success.max_page,
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error =>
      {
        return{
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
        }
      });
  }
  const getMiner = (token, client, miner) => {
    return fetch(`${getMinerRoute}/${miner}/${client}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getMinerLogs = (token, client, pageSize, pageNumber, orderBy, acending) => {
    return fetch(`${getMinerLogsRoute}/${client}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          page_size: data.success.page_size,
          current_page: data.success.current_page,
          max_page: data.success.max_page,
          data: data.success.data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }))
  }
  const getMinerByMac = (token, client, mac, pageSize, pageNumber, orderBy, acending) => {
    return fetch(`${getLogsByMacRoute}${mac}/${client}?pageSize=${pageSize}&&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          page_size: data.success.page_size,
          current_page: data.success.current_page,
          max_page: data.success.max_page,
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }))
  }
  const mapMiners = (token, client, miners) => {
    return fetch(`${mapMinersRoute}/${client}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(miners)
    }).then((data) => {
      if(data.status == 304){
        return {
          ok: false,
          msg: "No MAC Address matched or all locations taken",
          data: [],
        }
      }
      return data.json()
    })
    .then((data) => {
      if(data.hasOwnProperty('success') && data.success.hasOwnProperty("notInSubnetMSG")){
        return{
          ok: false,
          msg: data.success.notInSubnetMSG,
          data: [],
          api_version: data.api_version,
        }
      }
      return data
    })
    .catch(error => ({
      ok: false,
      msg: "failed to map miners, no response from server",
      data: [],
    }));
  }
  const getPods = (token, houseID, client, region_id) =>{
    return fetch(`${getPodsRoute}/${houseID}/${client}/region/${region_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return{
          ok: true,
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getPodsLimited = (token, houseID, client, region_id) =>{
    return fetch(`${getPodsLimitedRoute}/${houseID}/${client}/region/${region_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return{
          ok: true,
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getAllPods = (token, client, region_id) =>{
    return fetch(`${getPodsRoute}/${client}/region/${region_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) =>{
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          data: data.success.data,
          api_version: data.api_version,
        };
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const postNewHouse = (body, token, client)=> {
    return fetch(`${postHousesRoute}/${client}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(data.hasOwnProperty("success")){
        return {
          ok: true,
          msg: data.success.msg,
          houses: data.success.data,
          api_version: data.api_version,
        };
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to post data from server",
      data: [],
    }));
  }
  const postNewPod = (body, token, client, region_id) => {
    return fetch(`${postPodsRoute}/${client}/region/${region_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          pods: data.success.data,
          api_version: data.api_version,
        };
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to post data from server",
      data: [],
    }))

  }
  const deletePod = (token, client, id, region_id) => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    return fetch(`${deletePodRoute}/${id}/${client}/region/${region_id}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          pods: data.success.data,
          api_version: data.api_version,
        };
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to post data from server",
      data: [],
    }))

  }
  const getMinersByPod = (token, client, Pod_ID, pageSize, pageNumber, orderBy, acending, filter) => {
    return fetch(`${getMinersByPodRoute}/${Pod_ID}/${client}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&filter[]=${filter}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          page_size: data.success.page_size,
          current_page: data.success.current_page,
          max_page: data.success.max_page,
          data: data.success.data,
          id: Pod_ID,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getReportData = (token, startDate, endDate, customer, properitesString) => {
    return fetch(`${reportRoute}/${startDate}/${endDate}/${customer}?${properitesString}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getAverageReport = (token, customer, amount, region_id) => {
    return fetch(`${rootRoute}/service/average/report/${customer}/region/${region_id}?hours=${amount}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  return {
    getDbs,
    getSubnets,
    getHouses,
    getMiners,
    getMiner,
    getMinerLogs,
    mapMiners,
    getPods,
    postNewHouse,
    postNewPod,
    getAllPods,
    getMinersByPod,
    deletePod,
    deleteHouse,
    getMinerByMac,
    renameCustomers,
    getReportData,
    getPodsLimited,
    getAverageReport,
    patchCustomerScanner,
    postCustomerScanner
  }
}

export default clientService();

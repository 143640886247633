import adminService from '../services/adminService';
import { getDatabaseInfoConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';


export const getDatabaseInfoAction = (token, database) => {
  return async(dispatch) => {
    try {
      const result = await adminService.getDatabaseInfo(token, database)
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(success(result));
    }
    catch(err) {
      console.log(err)
    }
  }
}

const success = (result) => ({
  type: getDatabaseInfoConstant,
  payload: result
})

import { removeDashActionConstant } from '../constants/actions'

export const removeDashboardAction = () => {
  return (dispatch) => {
    dispatch(onSuccess())
  }
}

const onSuccess = () => ({
    type: removeDashActionConstant
})

import { getUserDataConstant } from '../constants/actions'
import service from '../services/userService'

export const getUserDataAction = (token) => {
  return async(dispatch) => {
    try{
      const result = await service.getUserData(token)
      dispatch(success(result))
    }
    catch(err){
      console.log(err)
    }
  }
}

const success = (result) => ({
  type: getUserDataConstant,
  payload: result
})

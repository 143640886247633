import React from 'react';
import './style.css';
import { convertFields } from '../../helper/functions'






const ViewBodyFilterList = ({ filterList, onInputChange, clearFilter}) => {
    return(
        <div className="ViewBodyFilterList-container">
            {convertFields(filterList, onInputChange, false)}
            <div className='react-select-group'>
                <button className="ViewBodyFilterList-button-clear" onClick={ e => clearFilter()}>Clear</button>
            </div>
        </div>
    )
}

export default ViewBodyFilterList
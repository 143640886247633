import { postNewHouseConstant } from '../constants/actions';
import service from '../services/clientService'
import { setErrorMessageAction } from './setErrorMessageAction';

export const postNewHouseAction = (body, token, client) => {
  return async(dispatch)=>{
    try{
      const result = await service.postNewHouse(body, token, client);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(postNewHouseSuccess(result));
    }
    catch(err){
      console.log(err);
    }
  }
}

const postNewHouseSuccess = (result) =>({
  type: postNewHouseConstant,
  payload: result
})

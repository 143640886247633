import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter, Switch, Redirect, withRouter } from 'react-router-dom'
import { Grid, Row, Col } from 'react-flexbox-grid';
import logo from './logo.svg';
import './App.css';
import ClientSwitchView from './Views/ClientSwitchView';
import Highcharts from 'highcharts/highstock';
import AdminView from './Views/AdminView'
import ClientView from './Views/ClientView';
import DashboardView from './Views/DashboardView';
import LoginView from './Views/LoginView';
import LogView from './Views/LogView';
import MapView from './Views/MapView';
import MinerView from './Views/MinerView';
import ConfigureView from './Views/ConfigureView';
import SubnetView from './Views/SubnetView';
import Header from './Components/Header'
import LeftBar from './Components/LeftBar';
import NavigationTree from './Components/NavigationTree'
import ChangePasswordView from './Views/ChangePasswordView';
import MaintenanceView from './Views/MaintenanceView'
import SidebarOne from './Components/SidebarOne';
import FilteredView from './Views/FilteredView'
import UserlogView from './Views/UserlogView'
import ListView from './Views/ListView'
import HeatmapView from './Views/HeatmapView'
import CustomerSwitch from './Switches/CustomerSwitch'
import AdminSwitch from './Switches/AdminSwitch'
import SystemUserSwitch from './Switches/SystemUserSwitch'
import { updateSidebarAction } from './redux/actions/updateSidebarAction'
import { toggleRefreshDataAction } from './redux/actions/toggleRefreshDataAction'
import { getAccessLevelsAction } from './redux/actions/getAccessLevelsAction'
import { setErrorMessageAction } from './redux/actions/setErrorMessageAction'
import { setSuccessMessageAction } from './redux/actions/setSuccessMessageAction'
import { responseResultsAction } from './redux/actions/responseResultsAction'
import { updateResponsePropertyAction } from './redux/actions/updateResponsePropertyAction'
import { addUserActivityAction } from './redux/actions/addUserActivityAction'
import { setUserDataAction } from './redux/actions/setUserDataAction'
import { updateSystemTokenAction } from './redux/actions/updateSystemTokenAction'
import { getUserDataAction } from './redux/actions/getUserDataAction'
import jwt from 'jwt-simple';
import { SocketContext, getSocket, socket_global, setSocket } from './context/socket'

import { logOutAction } from './redux/actions/logOutAction'

export class LoggedIn extends Component {
  constructor(props){
    super(props)
    this.state = {
      username:'',
    }
  }
  handleTokenUpdate = (token) => {
    console.log(token)
    try{
      const tokenData = jwt.decode(token, process.env.REACT_APP_DECRYPT_KEY)
      let date1     = new Date()
      date1.setDate(date1.getDate() - 1);
      const date2     = new Date(tokenData.issued_at)
      // Update token if it is one day + old
      if(date2 < date1){
        this.props.updateSystemTokenAction(token)
      }
      else{
        console.log(`Current token issued at ${date2}`)
      }
    }
    catch(err){
      this.logout()
    }
    
  }
  componentDidMount(){
    const { result, userData, username } = this.props.store.userReducer;
    //const userData = this.decodeToken(result)
    this.setState({ loggedIn: this.props.store.userReducer.status})
    //this.props.setUserDataAction(userData)
    if(this.props.store.userReducer.status){
      this.props.getAccessLevelsAction(this.props.store.userReducer.result)
    }
    if(this.props.store.userReducer.ok){
      this.props.addUserActivityAction(result, "website_refresh")
    }
    if(socket_global == null){
      setSocket(`${process.env.REACT_APP_SIO_SERVER_URL}`, result)
      if(socket_global){
        socket_global.on("commandResults", payload => {
          this.props.responseResultsAction(payload)
        })
        socket_global.on("connect", msg => {
          socket_global.emit("userConnect", username)
        })
        socket_global.on("scannerNotFound", payload => {
          try {
            const jsonData = JSON.parse(payload)
            this.props.updateResponsePropertyAction({property: 'notFound', value: (this.props.responseStore.notFound + jsonData.miners.length)})
            this.props.updateResponsePropertyAction({property: 'pending', value: (this.props.responseStore.pending - jsonData.miners.length)})
          }
          catch (e) {
            console.log(e)
          }
        })
        socket_global.on("rebootScannerResult", payload => {
          if(payload.result){
            this.props.setSuccessMessageAction(`Successfully rebooted scanner: ${payload.id_no} - Scanner found: ${payload.scanner_found}`)
          }
          else{
            this.props.setErrorMessageAction(`Failed to reboot scanner: ${payload.id_no} - Scanner found: ${payload.scanner_found}`)
          }
        })
        socket_global.on("updateScannerResult", payload => {
          if(payload.result){
            this.props.setSuccessMessageAction(`Successfully updated scanner: ${payload.id_no} - Scanner found: ${payload.scanner_found}`)
          }
          else{
            this.props.setErrorMessageAction(`Failed to update scanner: ${payload.id_no} - Scanner found: ${payload.scanner_found}`)
          }
        })
      }
    }
    this.handleTokenUpdate(result)
    this.props.getUserDataAction(result)
  }
  onNavigationClick = (pos, length) => {
    for(var i = length-1; i > pos; i--){
      this.props.history.goBack()
    }
  }
  logout = () => {
    this.props.logOutAction();
  }
  toggleHamburger = () => {
    const { sidebaropen } = this.props.clientReducer
    document.body.classList.toggle("msb-x");
    const element = document.getElementById('msb')
    const bigIconElement = document.getElementById('adc_header-bigLogo')
    const miniIconElement = document.getElementById('adc_header-miniLogo')
    if(bigIconElement){
      bigIconElement.classList.toggle('displayNone')
    }
    if(miniIconElement){
      miniIconElement.classList.toggle('displayNone')
    }
    this.props.updateSidebarAction(!sidebaropen)
  }
  decodeToken = (token) => {
    try {
      return jwt.decode(token, process.env.REACT_APP_DECRYPT_KEY);
    }
    catch (err){
      return {}
    }
  }
  onFilterClick = e => {
    const headerContainer = document.getElementById("adc_header-hidden_container")
    if(headerContainer){
      headerContainer.className = ''
    }
    const commandButton = document.getElementById("MinerList-CommandButtons-addition")
    if(commandButton){
      commandButton.classList.add('MinerList-CommandButtons-addition')
      commandButton.classList.remove('MinerList-CommandButtons-addition-show')
    }
    const item = document.getElementById("MinerList-Filtering-addition")
    if(item){
      item.classList.toggle("MinerList-Filtering-addition")
      item.classList.toggle('MinerList-Filtering-addition-show')
    }
  }
  onActionsClick = (e) => {
    const headerContainer = document.getElementById("adc_header-hidden_container")
    const filter = document.getElementById("MinerList-Filtering-addition")
    if(filter){
      filter.classList.add('MinerList-Filtering-addition')
      filter.classList.remove('MinerList-Filtering-addition-show')
    }
    if(headerContainer){
      headerContainer.className = ''
    }
    const item = document.getElementById("MinerList-CommandButtons-addition")
    if(item){
      item.classList.toggle("MinerList-CommandButtons-addition")
      item.classList.toggle('MinerList-CommandButtons-addition-show')
    }
  }
  onCustomersClick = e => {
    const item = document.getElementById("adc_header-hidden_container")
    const filter = document.getElementById("MinerList-Filtering-addition")
    if(filter){
      filter.classList.add('MinerList-Filtering-addition')
      filter.classList.remove('MinerList-Filtering-addition-show')
    }
    const commandButton = document.getElementById("MinerList-CommandButtons-addition")
    if(commandButton){
      commandButton.classList.add('MinerList-CommandButtons-addition')
      commandButton.classList.remove('MinerList-CommandButtons-addition-show')
    }
    if(item){
      const classList = item.classList
      if(classList.length > 0 && !classList.contains('show-customers')){
        item.className = "";
      }
      item.classList.toggle('show-customers')
    }
  }
  onViewClick = (e) => {
    const item = document.getElementById("adc_header-hidden_container")
    const commandButton = document.getElementById("MinerList-CommandButtons-addition")
    const filter = document.getElementById("MinerList-Filtering-addition")
    if(filter){
      filter.classList.add('MinerList-Filtering-addition')
      filter.classList.remove('MinerList-Filtering-addition-show')
    }
    if(commandButton){
      commandButton.classList.add('MinerList-CommandButtons-addition')
      commandButton.classList.remove('MinerList-CommandButtons-addition-show')
    }
    if(item){
      const classList = item.classList
      if(classList.length > 0 && !classList.contains('show-views')){
        item.className = "";
      }
      item.classList.toggle('show-views')
    }
  }
  onSettingsClick = (e) =>{
    const item = document.getElementById("adc_header-hidden_container")
    const commandButton = document.getElementById("MinerList-CommandButtons-addition")
    const filter = document.getElementById("MinerList-Filtering-addition")
    if(filter){
      filter.classList.add('MinerList-Filtering-addition')
      filter.classList.remove('MinerList-Filtering-addition-show')
    }
    if(commandButton){
      commandButton.classList.add('MinerList-CommandButtons-addition')
      commandButton.classList.remove('MinerList-CommandButtons-addition-show')
    }
    if(item){
      const classList = item.classList
      if(classList.length > 0 && !classList.contains('show-settings')){
        item.className = "";
      }
      item.classList.toggle('show-settings')
    }
  }
  onClientViewClick = (e) => {
    const item = document.getElementById("adc_header-hidden_container")
    const commandButton = document.getElementById("MinerList-CommandButtons-addition")
    if(commandButton){
      commandButton.classList.add('MinerList-CommandButtons-addition')
      commandButton.classList.remove('MinerList-CommandButtons-addition-show')
    }
    if(item){
      item.className = "";
    }
  }
  validateToken = (userData) => {
    if(userData.hasOwnProperty('tokenExpirationDate')){
      const then = new Date(userData.tokenExpirationDate)
      const now = new Date();
      if(then < now){
        return false
      }
      else{
        return true
      }
    }
    return false
  }
  onRegionClick = () => {
    const item = document.getElementById("adc_header-hidden_container")
    if(item){
      const classList = item.classList
      if(classList.length > 0 && !classList.contains('show-regions')){
        item.className = "";
      }
      item.classList.toggle('show-regions')
    }
  }
  render() {
    if(!this.props.store.userReducer.ok){
      return <Redirect to='/login' />
    }
    const { result, userData } = this.props.store.userReducer;
    if(userData.hasOwnProperty('mf_verified') && !userData['mf_verified']){
      return <Redirect to='/login' />
    }
    const databaseTier = userData.databases[0]?userData.databases[0].Tier: 0
    let userLogsAccess = this.props.accessStore.filter((item) => item.Access === 'User_Logs')
    userLogsAccess = userLogsAccess.length > 0?userLogsAccess[0].Level:2
    /*
    if(!this.validateToken(userData)){
      this.props.setErrorMessageAction("Current access token is either invalid or exipred")
      this.props.logOutAction()
      return <Redirect to='/login' />
    }
    */
    if(userData.databases.length === 1 && window.location.pathname === '/'){
      if(userData.databases[0].Regions.length > 0){
        return <Redirect to={`/client/${userData.databases[0].Database_Name}/region/All`} />
      }
      else{
        return <Redirect to={`/client/${userData.databases[0].Database_Name}`} />
      }
    }
    let customerSplit = window.location.pathname.split('/client/')
    customerSplit = customerSplit.length > 1?(customerSplit[1].split('/')[0]):('')
    if(customerSplit !== ''){
      let customers = userData.databases.map((item) => item.Database_Name)
      if(customers.indexOf(customerSplit) === -1) {
        alert(`You don't have access to this customer`)
        return (
          <Redirect
            to={{
              pathname: '/denied'
            }}
          />
        )
      }
    }
    let regions = []
    if(window.location.pathname.includes('/client/')){
      const customer = userData.databases.filter((item) => item.Database_Name === customerSplit)
      if(customer.length > 0){
        regions = customer[0].Regions
      }
    }
    return(
      <SocketContext.Provider value={socket_global}>
      <div className="flexContainer">
          <Header
            toggleHamburger={this.toggleHamburger}
            isLoggedIn={true}
            logout={this.logout}
            username={userData.user}
            refreshData={this.props.clientReducer.refreshData}
            onRefreshCheck={ e => this.props.toggleRefreshDataAction()}
            userLevel={userData.level}
            databaseTier={databaseTier}
            adminLevel={2}
            sidebaropen={this.props.clientReducer.sidebaropen}
            onSettingsClick={this.onSettingsClick}
            onViewClick={this.onViewClick}
            onCustomersClick={this.onCustomersClick}
            onActionsClick={this.onActionsClick}
            onFilterClick={this.onFilterClick}
            databases={userData.databases}
            userLogsAccess={userLogsAccess}
            regions={regions}
            onRegionClick={this.onRegionClick}
          />
        <div className="clientViewContainer" onClick={e => this.onClientViewClick(e)}>
            <SidebarOne/>
            {userData.level === 2?(
              <AdminSwitch/>
            ):(
              userData.level === 1?(
                <SystemUserSwitch/>
              ):(
                <CustomerSwitch
                  tier={userData.databases[0]? userData.databases[0].Tier: 0}
                  customer={userData.databases[0]? userData.databases[0].Database_Name: ''}
                />
              )
            )}
          </div>
      </div>
      </SocketContext.Provider>
    )
  }
};
const mapStateTopProps = (userReducer) => ({
  store: userReducer,
  clientReducer: userReducer.clientReducer,
  accessStore: userReducer.accessReducer.levels,
  responseStore: userReducer.responseReducer
});
export default connect(mapStateTopProps,{
  logOutAction,
  updateSidebarAction,
  toggleRefreshDataAction,
  setErrorMessageAction,
  setSuccessMessageAction,
  getAccessLevelsAction,
  responseResultsAction,
  updateResponsePropertyAction,
  addUserActivityAction,
  setUserDataAction,
  updateSystemTokenAction,
  getUserDataAction
})(LoggedIn);

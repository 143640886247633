import { getDailyRevenueConstant } from '../constants/actions'
import service from '../services/hashrateService'
export const getDailyRevenueAction = (token, customer, region_id) => {
  return async(dispatch) => {
    const result = await service.getDailyRevenue(token, customer, region_id);
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getDailyRevenueConstant,
  payload: result
})

import service from '../services/rackService';
import { postRackShelfsConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const postRackShelfsAction = (token, customer, body, region_id) => {
  return async(dispatch) => {
    try{
      const result = await service.postRackShelfs(token, customer, body, region_id);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(success(result))
    }
    catch(err){
      console.log(err)
    }
  }
}
const success = (result) => ({
  type: postRackShelfsConstant,
  payload: result
})

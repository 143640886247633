import { getInventoryHistoryConstant } from '../constants/actions'
import service from '../services/inventoryService'
import { setErrorMessageAction } from './setErrorMessageAction';

export const getInventoryHistoryAction = (token, customer, pageSize, pageNumber, orderBy, acending, searchValue, region_id) => {
  return async(dispatch) => {
    const result = await service.getInventoryHistory(token, customer, pageSize, pageNumber, orderBy, acending, searchValue, region_id)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: getInventoryHistoryConstant,
  payload: result
})

import { responseResultsConstant } from '../constants/actions'


export const responseResultsAction = (data) => {
  return dispatch => {
    dispatch(success(data))
  }
}

const success = (data) => ({
  type: responseResultsConstant,
  payload: data
})

import { formatBytes } from '../../helper/functions'
export const highchartOptions = (data, title) => {
  let categories  = []
  //let apiDown     = []
  //let idle        = []
  let hashrate  = []
  //let online      = []
  //let offline     = []
  for (var i in data){
    const category = data[i].Date
    categories.push(category)
    //apiDown.push(data[i].API_Down)
    //idle.push(data[i].Idle)
    //online.push(data[i].Online)
    //offline.push(data[i].Offline)
    hashrate.push(data[i].Hashrate)
  }
  const options = {
    navigator: {
      enabled: false
    },
    title: {
      text: title,
      style:{
        color: '#b8c7ce',
        fontWeight: 'bold'
      },
      margin: 0
    },
    rangeSelector: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      visible: false,
      labels: {
        enabled: false
      }
    },
    series: [
      {
        name: "Hashrate",
        data: hashrate,
        fillColor: 'rgba(124, 181, 236, 0.75)',
        fillOpacity:1,
        type: 'area',
      }
    ],
    tooltip: {
      formatter: function() {
        const date = Number.isInteger(this.points[0].key)? categories[this.points[0].key]: this.points[0].key
        return (
          "Hashrate: "+ "<b>" + formatBytes(this.points[0].y, 2) + "</b><br>" +
          "Time/Date: " + "<b>" + date +
          "</b>"
        );
      }
    },
    chart: {
        height: 250,
        backgroundColor: {
          linearGradient: { x1: 0},
          stops: [
              [0, '#0e1012'],
          ]
        }
    }
  }
  return options
}

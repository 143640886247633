import React, { Component } from 'react';
import './style.css'


const AccessDeniedView = () => {
  return(
    <div className="accessDenied-container">
      <div className="accessDenied-paragraph">
          <strong>401</strong>
          <p>Permission denied</p>
      </div>
    </div>
  )
}
export default AccessDeniedView

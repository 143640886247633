import { updatePageInfoConstant } from '../constants/actions'

export const updatePageInfoAction = (body) => {
  return (dispatch) => {
    dispatch(updatePageInfoSuccess(body))
  }
}

const updatePageInfoSuccess = (body) => ({
  type: updatePageInfoConstant,
  payload: body
})

import React from 'react';
import './style.css'
import { formatBytes } from '../../functions'
import HighchartPie from '../HighchartPie'
import InfoPannel from '../InfoPannel'
const SelectionInformationCard = ({ selectionData, selectionColumns}) => {
  return(
    <div className="adc_selectionInformation-container">
      <div className="adc_selectionInformation-infoPannel">
        <InfoPannel
          data={selectionData}
          columns={selectionColumns}
        />
      </div>
    </div>
  )
}

export default SelectionInformationCard

/*
{!isNaN(sum) && sum !== 0?(
  <HighchartPie
    items={[
      {name: 'offline', y: offlineMiners/sum, value:offlineMiners, color: '#030303'},
      {name: 'online', y: onlineMiners/sum, value:onlineMiners, color: '#0fd122'},
      {name: 'api down', y: apiDown/sum, value: apiDown, color: '#b80fd6'}]}
  />
):(<></>)}
*/

import React from 'react';
import { connect } from 'react-redux';
import { getMinersByPodAction } from '../../redux/actions/getMinersByPodAction';
import { getFavMinersAction } from '../../redux/actions/getFavMinersAction';
import { postFavMinersAction } from '../../redux/actions/postFavMinersAction';
import { deleteFavMinersAction } from '../../redux/actions/deleteFavMinersAction';
import { clearLocationsAction } from '../../redux/actions/clearLocationsAction'
import { Modal } from "react-responsive-modal";
import MinerList from '../../Components/MinerList';
import FilterMinerListSection from '../../Components/FilterMinerListSection'
import CustomerInformationCard from '../../Components/CustomerInformationCard'
import CommandSection from '../../Components/CommandSection'
import { setErrorMessageAction } from '../../redux/actions/setErrorMessageAction';
import { sendCommandAction } from '../../redux/actions/sendCommandAction';
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { setMinerTypeAction } from '../../redux/actions/setMinerTypeAction';
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { markMinersDeletedAction } from '../../redux/actions/markMinersDeletedAction'
import { adjustMinersStatusAction } from '../../redux/actions/adjustMinersStatusAction'
import { clearMinersAction } from '../../redux/actions/clearMinersAction'
import { resetMinerDisplayAction } from '../../redux/actions/resetMinerDisplayAction'
import * as XLSX from 'xlsx';

import './style.css';

class PodView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: 0,
      index: 0,
      setIndex: 0,
      client:'',
      subnet:'',
      house:'',
      pod: '',
      renderDetails: false,
      selectedMiners: [],
      isAllSelected: false,
      maxByShelf: [],
      isShiftDown: false,
      typeModalOpen: false,
      formErrorDetected: false,
      statusModalOpen: false,
      filter: {
        filterWorker: '',
        filterLocation: '',
        filterIp: '',
        fromHeat: '',
        toHeat:'',
        fromCurrentSpeed:'',
        toCurrentSpeed: '',
        fromAvgSpeed: '',
        toAvgSpeed: '',
        fromMissingAsic: '',
        toMissingAsic: '',
        filterType: '',
        fromPerformance: '',
        toPerformance: '',
        status: ''
      },
      intervalID: '',
      podInfo:''
    }
  }
  componentWillUnmount() {
    const { intervalID } = this.state;
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("keydown", this.handleKeyDown);
    this.unlistenToFilter()
    this.props.clearMinersAction()
    clearInterval(intervalID)
  }
  //This is used to select more than a single miner at a time from the table.
  handleShiftDown = (event) => {
    if(event.key === "Shift"){
      const { isShiftDown } = this.state;
      if(!isShiftDown){
        this.setState({isShiftDown: true})
      }
    }
  }
  handleShiftUp = (event) => {
    if(event.key === "Shift"){
      const { isShiftDown } = this.state;
      if(isShiftDown){
        this.setState({isShiftDown: false})
      }
    }
  }
  handleFilter = (e) => {
    const { pageSize, orderBy, acending, pageNumber } = this.props.pageStore;
    let { filter, formErrorDetected } = this.state
    if((!e.key ||  e.key == 'Enter' || e.key == " ") && !formErrorDetected){
      switch (e.target.name) {
        case 'toMissingAsic':
        case 'toAvgSpeed':
        case 'toPerformance':
        case 'toCurrentSpeed':
        case 'toHeat':
          const subString = e.target.name.substring(2, e.target.name.length)
            if(e.target.value !== '' && filter[`from${subString}`] > e.target.value){
              this.setState({formErrorDetected: true})
              alert('From performance is bigger then to')
            }
            else{
              if(e.target.value == ''){
                filter[e.target.name] = e.target.value;
                const filterArray = this.filterArrayToString(filter)
                this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, filterArray);
              }
              else if(isNaN(e.target.value)){
                alert("enter a number")
              }
              else{
                filter[e.target.name] = parseInt(e.target.value);
                const filterArray = this.filterArrayToString(filter)
                this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, filterArray);
              }
            }
          break;
        case "filterWorker":
        case "filterLocation":
        case "filterIp":
        case "filterType":
          filter[e.target.name] = e.target.value;
          const filterArray = this.filterArrayToString(filter)
          this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, filterArray);
          break;
        case 'fromHeat':
        case 'fromCurrentSpeed':
        case 'fromAvgSpeed':
        case 'fromMissingAsic':
        case 'fromPerformance':
          const subString2 = e.target.name.substring(4, e.target.name.length)
          if(filter[`to${subString2}`] !== '' && filter[`to${subString2}`] < e.target.value){
            this.setState({formErrorDetected: true})
            alert('From is bigger then to')
          }
          else{
            if(e.target.value == ''){
              filter[e.target.name] = e.target.value
              const filterArray = this.filterArrayToString(filter)
              this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, filterArray);
            }
            else if(isNaN(e.target.value)){
              alert("enter a number")
            }
            else{
              filter[e.target.name] = parseInt(e.target.value);
              const filterArray = this.filterArrayToString(filter)
              this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, filterArray);
            }
          }
          break
      }
    }
    else if(formErrorDetected){
      this.setState({formErrorDetected: false})
    }
  }
  //Used to fetch more miners to render in the next page of the table.
  onPageClick = async (pageNumber) => {
    const { filter } =  this.state;
    if(pageNumber !== this.props.minerStore.current_page){
      const { pageSize, orderBy, acending } = this.props.pageStore;
      if(pageNumber !== 0 && pageNumber <= this.props.minerStore.max_page){
        this.props.resetMinerDisplayAction()
        await this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, this.filterArrayToString(filter));
      }
      const store = {
        podID: this.props.pageStore.podID,
        client: this.props.pageStore.client,
        pageSize,
        pageNumber,
        orderBy,
        acending,
        type: 'podList'
      }
      document.body.scrollTop = 0;
      this.props.updatePageInfoAction(store);
    }
  }
  componentDidMount = async () =>{
    if(this.props.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name);
    }
    window.sessionStorage.setItem('location', window.location.pathname)
    if(this.props.pageStore.client !== this.props.match.params.name || this.props.pageStore.podID !== this.props.match.params.pod){
      const store = {
          podID: this.props.match.params.pod,
          client: this.props.match.params.name,
          pageSize: 25,
          pageNumber: 1,
          orderBy: 'Location',
          acending: true,
          type: 'podList'
      }
      this.props.updatePageInfoAction(store)
    }
    let podInfo = ''
    for(var house in this.props.houseStore.houses){
      for(var pod in this.props.houseStore.houses[house].pods){
        if(this.props.houseStore.houses[house].pods[pod].ID == this.props.match.params.pod){
          podInfo = this.props.houseStore.houses[house].pods[pod]
        }
      }
    }
    window.addEventListener? document.addEventListener('keydown', this.handleShiftDown) : document.attachEvent('keydown', this.handleShiftDown);
    window.addEventListener? document.addEventListener('keyup', this.handleShiftUp) : document.attachEvent('keyup', this.handleShiftUp);
    this.setState({client: this.props.match.params.name, subnet: this.props.match.params.subnet, house: this.props.match.params.house, pod: this.props.match.params.pod, podInfo})
    this.initalSetup()
  }
  componentDidUpdate = () => {
    if(this.props.clientStore.refreshData && this.state.intervalID === ''){
      const intervalID = setInterval(this.initalSetup, 60000);
      this.setState({intervalID})
    }
    if(!this.props.clientStore.refreshData && this.state.intervalID !== ''){
      const { intervalID } = this.state;
      clearInterval(intervalID)
      this.setState({intervalID: ''})
    }
  }
  initalSetup = async() => {
    const { pageNumber, pageSize, orderBy, acending } = this.props.pageStore;
    const { filter } = this.state
    const filterArray = this.filterArrayToString(filter)
    const token = this.props.userStore.result;
    this.props.getMinersByPodAction(token, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, filterArray);
    this.props.getTypesAction(token, this.props.match.params.name)
    this.props.getFavMinersAction(token);
    this.listenToFilter()
  }
  refreshData = () => {
    const { pageNumber, pageSize, orderBy, acending } = this.props.pageStore;
    const { filter } = this.state
    const filterArray = this.filterArrayToString(filter)
    const token = this.props.userStore.result;
    this.props.getMinersByPodAction(token, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, filterArray);
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  //Function for swapping between heatmap and minertable.
  handleChange = async(event, value2) => {
    const { value } = this.state
    if(value == 0){
      this.unlistenToFilter();
    }
    this.setState({
      value: value2
    });
    await this.sleep(1000)
    if(value2 == 0){
      this.listenToFilter()
    }
  }
  //Used to calculated the color of the miner in the heatmat according to it's heat.
  //This function is used to select all miners from the table.
  onSelectAll = (isAllSelected) => {
    if(isAllSelected === true)
    {
      let selectedMiners = [];
      if(isAllSelected){
        const { miners } = this.props.minerStore;
        selectedMiners = miners.map((item) => item.MAC_Address);
      }
      this.setState({isAllSelected, selectedMiners})
    }
    else{
      this.setState({isAllSelected, selectedMiners: []})
    }
  }
  listenToFilter = () => {
    const toHeatElement = document.getElementById("filter-to-heat")
    const toSpeedElement = document.getElementById("filter-to-speed")
    const toAvgSpeedElement = document.getElementById("filter-to-avgSpeed")
    const toMissingAsicElement = document.getElementById("filter-to-missing-asic")
    const toPerformanceElement = document.getElementById("filter-to-performance")
    const fromHeatElement = document.getElementById("filter-from-heat")
    const fromSpeedElement = document.getElementById("filter-from-speed")
    const fromAvgSpeedElement = document.getElementById("filter-from-avgSpeed")
    const fromMissingAsicElement = document.getElementById("filter-from-missing-asic")
    const fromPerformanceElement = document.getElementById("filter-from-performance")
    const filterWorker = document.getElementById("filterWorker")
    const filterLocation = document.getElementById("filterLocation")
    const filterIp = document.getElementById("filterIp")
    const filterType = document.getElementById("filterType")
    if(toHeatElement){
      toHeatElement.addEventListener('blur', this.handleFilter)
      toHeatElement.addEventListener('keydown', this.handleFilter)
    }
    if(toSpeedElement){
      toSpeedElement.addEventListener('blur', this.handleFilter)
      toSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(toAvgSpeedElement){
      toAvgSpeedElement.addEventListener('blur', this.handleFilter)
      toAvgSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(toMissingAsicElement){
      toMissingAsicElement.addEventListener('blur', this.handleFilter)
      toMissingAsicElement.addEventListener('keydown', this.handleFilter)
    }
    if(toPerformanceElement){
      toPerformanceElement.addEventListener('blur', this.handleFilter)
      toPerformanceElement.addEventListener('keydown', this.handleFilter)
    }
    if(filterWorker){
      filterWorker.addEventListener('blur', this.handleFilter)
      filterWorker.addEventListener('keydown', this.handleFilter)
    }
    if(filterLocation){
      filterLocation.addEventListener('blur', this.handleFilter)
      filterLocation.addEventListener('keydown', this.handleFilter)
    }
    if(filterIp){
      filterIp.addEventListener('blur', this.handleFilter)
      filterIp.addEventListener('keydown', this.handleFilter)
    }
    if(filterType){
      filterType.addEventListener('blur', this.handleFilter)
      filterType.addEventListener('keydown', this.handleFilter)
    }
    if(fromHeatElement){
      fromHeatElement.addEventListener('blur', this.handleFilter)
      fromHeatElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromSpeedElement){
      fromSpeedElement.addEventListener('blur', this.handleFilter)
      fromSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromAvgSpeedElement){
      fromAvgSpeedElement.addEventListener('blur', this.handleFilter)
      fromAvgSpeedElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromMissingAsicElement){
      fromMissingAsicElement.addEventListener('blur', this.handleFilter)
      fromMissingAsicElement.addEventListener('keydown', this.handleFilter)
    }
    if(fromPerformanceElement){
      fromPerformanceElement.addEventListener('blur', this.handleFilter)
      fromPerformanceElement.addEventListener('keydown', this.handleFilter)
    }
  }
  unlistenToFilter = () => {
    const toHeatElement = document.getElementById("filter-to-heat")
    const toSpeedElement = document.getElementById("filter-to-speed")
    const toAvgSpeedElement = document.getElementById("filter-to-avgSpeed")
    const toMissingAsicElement = document.getElementById("filter-to-missing-asic")
    const toPerformanceElement = document.getElementById("filter-to-performance")
    const filterWorker = document.getElementById("filterWorker")
    const filterLocation = document.getElementById("filterLocation")
    const filterIp = document.getElementById("filterIp")
    const filterType = document.getElementById("filterType")
    if(toHeatElement){
      toHeatElement.removeEventListener('blur', this.handleFilter)
      toHeatElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toSpeedElement){
      toSpeedElement.removeEventListener('blur', this.handleFilter)
      toSpeedElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toAvgSpeedElement){
      toAvgSpeedElement.removeEventListener('blur', this.handleFilter)
      toAvgSpeedElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toMissingAsicElement){
      toMissingAsicElement.removeEventListener('blur', this.handleFilter)
      toMissingAsicElement.removeEventListener('keydown', this.handleFilter)
    }
    if(toPerformanceElement){
      toPerformanceElement.removeEventListener('blur', this.handleFilter)
      toPerformanceElement.removeEventListener('keydown', this.handleFilter)
    }
    if(filterWorker){
      filterWorker.removeEventListener('blur', this.handleFilter)
      filterWorker.removeEventListener('keydown', this.handleFilter)
    }
    if(filterLocation){
      filterLocation.removeEventListener('blur', this.handleFilter)
      filterLocation.removeEventListener('keydown', this.handleFilter)
    }
    if(filterIp){
      filterIp.removeEventListener('blur', this.handleFilter)
      filterIp.removeEventListener('keydown', this.handleFilter)
    }
    if(filterType){
      filterType.removeEventListener('blur', this.handleFilter)
      filterType.removeEventListener('keydown', this.handleFilter)
    }
  }
  //This is the function that selects miners
  onItemSelect = (mac, item) => {
    let { selectedMiners, isShiftDown, lastSelectionItem } = this.state;
    let { miners } = this.props.minerStore;
    if(isShiftDown){
      let max = Math.max(item, lastSelectionItem);
      let min = Math.min(item, lastSelectionItem) + 1;
      let newArray = miners.slice(min, max)
      const macArray = newArray.map((item) => item.MAC_Address)
      macArray.map((item) => {
        const index = selectedMiners.indexOf(item);
        if(index === -1){
          selectedMiners.push(item)
        }
        else{
          selectedMiners.splice(index, 1);
        }
      })
    }
    const index = selectedMiners.indexOf(mac);
    if(index === -1){
      selectedMiners.push(mac);
    }
    else{
      selectedMiners.splice(index, 1);
    }
    this.setState({selectedMiners, lastSelectionItem: item});
  }
  //Function that's called when either Restart of Configure button is pressed.
  onClickCommand = async(type) => {
    const { selectedMiners } = this.state;
    if(selectedMiners.length === 0 ){
      this.props.setErrorMessageAction("You need to pick atleast one miner");
      return;
    }
    if(type == "delete"){
      this.props.markMinersDeletedAction(this.props.userStore.result, selectedMiners, this.props.match.params.name)
    }
    else{
      this.props.sendCommandAction(this.props.userStore.result, this.props.match.params.name, {Macs: selectedMiners}, type)
    }

    this.setState({ selectedMiners: []});
  }
  //Sorts the table according to what column was selected.
  onSort = (type) => {
    let { acending, orderBy, pageNumber, pageSize } = this.props.pageStore;
    const { filter } = this.state;
    if(orderBy === type){
      acending = !acending;
    }
    else{
      acending = true;
    }
    this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, type, acending, this.filterArrayToString(filter));
    const store = {
      podID: this.props.pageStore.podID,
      client: this.props.pageStore.client,
      pageSize,
      orderBy: type,
      acending,
      pageNumber,
      type: 'podList'
    }
    this.props.updatePageInfoAction(store);
  }
  //Function used for going back via the back button on the browser or the Navigation Tree
  onBack = async () => {
    this.props.history.goBack()
  }
  //Function used to route us to the minersDetails site when it was clicked from the table or the heatmap.
  onItemClick = async (link) => {
    this.props.history.push(link)
  }
  //Adds miners to fav and delets them from fav
  alterFav = (MAC_Address, ID) => {
    const arrayid = [];
    if(ID === null){
      arrayid[0] = {MAC_Address};
      this.props.postFavMinersAction(arrayid, this.props.userStore.result);
    }
    else {
      arrayid[0] = {ID};
      this.props.deleteFavMinersAction(this.props.userStore.result, arrayid);
    }
  }
  //Adds many miners to the fav table.
  addManyToFav = () => {
    const newObj = this.state.selectedMiners.map((item) => {
      return{
        MAC_Address: item
      }
    })
    this.props.postFavMinersAction(newObj, this.props.userStore.result);
  }
  //Deletes many miners from the fav table.
  deleteManyFromFav = () => {
    let newObj = this.props.favMinersStore.data.filter((item) => this.state.selectedMiners.indexOf(item.MAC_Address) != -1)
    newObj = newObj.map((item) => {return{ID:item.ID}})
    this.props.deleteFavMinersAction(this.props.userStore.result, newObj);
  }
  //Changes the size of each page in the table.
  changePageSize = (e) => {
    //this.setState({ [e.target.name]: e.target.value })
    let {acending, orderBy, pageNumber} = this.props.pageStore;
    const { filter } = this.state;
    const value = parseInt(e.target.value)
    const items = parseInt(this.props.minerStore.max_page)*parseInt(this.props.minerStore.page_size)
    if(value > items){
      pageNumber = 1;
    }
    if((this.props.pageStore.pageNumber*value) > items){
      pageNumber = 1;
    }
    this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, value, pageNumber, orderBy, acending, this.filterArrayToString(filter));
    const store = {
      podID: this.props.pageStore.podID,
      client: this.props.pageStore.client,
      pageSize: value? value: 0,
      orderBy,
      acending,
      pageNumber,
      type: 'podList'
    }
    this.props.updatePageInfoAction(store)
  }
  changeType = async(type) => {
    const { pageNumber, pageSize, orderBy, acending } = this.props.pageStore;
    let { selectedMiners, filter } = this.state;
    selectedMiners = selectedMiners.map((item, i) => {
      return {
        MAC_Address: item,
        Type: type
      }
    })
    await this.props.setMinerTypeAction(selectedMiners, this.props.userStore.result, this.props.match.params.name)
    this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, this.filterArrayToString(filter));
    this.setState({selectedMiners: [], typeModalOpen: false})
  }
  filterArrayToString = (filter) => {
    let filterArray = []
    for(var key in filter) {
        if (filter.hasOwnProperty(key)) {
          if(filter[key] !== ''){
            filterArray.push({[key]: filter[key]})
          }
        }
    }
    return filterArray.map((item) => JSON.stringify(item)).join('&filter[]=')
  }
  onFilterInputChange = (e) => {
    const { pageNumber, pageSize, orderBy, acending } = this.props.pageStore;
    let { filter } = this.state;
    let breakFrom = 0
    if(e.target.value == ' '){
      return
    }
    filter[e.target.name] = e.target.value;

    if(e.target.name === 'filterType' || e.target.name === 'filterRack' || e.target.name === 'filterStatus'){
      const filterArray = this.filterArrayToString(filter)
      this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, filterArray);
    }
    this.setState({filter})
  }
  clearFilter = () => {
    const { pageNumber, pageSize, orderBy, acending } = this.props.pageStore;
    this.setState({filter: {
      filterWorker: '',
      filterLocation: '',
      filterIp: '',
      filterRack: '',
      fromHeat: '',
      toHeat:'',
      fromCurrentSpeed:'',
      toCurrentSpeed: '',
      fromAvgSpeed: '',
      toAvgSpeed: '',
      fromMissingAsic: '',
      toMissingAsic: '',
      filterType: '',
      fromPerformance: '',
      toPerformance: '',
      status: ''
    }})
    this.props.getMinersByPodAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, '');
  }
  onAlterStatusClick = (status) => {
    const { selectedMiners } = this.state;
    if(selectedMiners.length === 0){
      this.setState({statusModalOpen: false})
      alert("No miners selected")
      return
    }
    this.props.adjustMinersStatusAction(this.props.userStore.result, {macs:selectedMiners}, this.props.match.params.name, status)
    this.setState({statusModalOpen: false, selectedMiners: []})
  }
  clearLocations = () => {
    const { selectedMiners } = this.state;
    this.props.clearLocationsAction(this.props.userStore.result, this.props.match.params.name, {macs: selectedMiners})
  }
  exportList = (e) => {
    let { miners } = this.props.minerStore;
    let newMinerList = []
    miners.map((item) => {
      let tmpObj = {}
      Object.keys(this.props.listColumns).map((key, index) =>{
        if(this.props.listColumns[key]){
          tmpObj[key] = item[key]
        }
      })
      newMinerList.push(tmpObj)
    })
    switch (e.target.value) {
      case 'xlsx':
        var wb1 = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(newMinerList);
        XLSX.utils.book_append_sheet(wb1, ws1, 'miner-list')
        const now = new Date()
        const date =  now.getUTCFullYear() +"-"+ (now.getUTCMonth()+1) +"-"+ now.getUTCDate() + "_" + now.getUTCHours() + "-" + now.getUTCMinutes() + "-" + now.getUTCSeconds()
        XLSX.writeFile(wb1, `listExport-${date}-.xlsx`)
        break;
      default:
    }
  }
  refreshData = () => {
      const { pageNumber, pageSize, orderBy, acending } = this.props.pageStore;
      const { filter } = this.state
      const filterArray = this.filterArrayToString(filter)
      const token = this.props.userStore.result;
      this.props.getMinersByPodAction(token, this.props.match.params.name, this.props.match.params.pod, pageSize, pageNumber, orderBy, acending, filterArray);
  }
  render() {
    const modalStyles = {
      modal: {
          width: '400px',
          height: '400px',
      }
    }
    const {value, pod, client, subnet, house, pos, renderDetails, renderDetailInfo, selectedMiners, isAllSelected, typeModalOpen, maxByShelf, filter, podInfo, statusModalOpen} = this.state;
    const { miners } = this.props.minerStore;
    let index;
    if (this.state === null) {
      index = 0;
    }
    else {
      index = this.state.index;
    }
    return (
          <div className="podViewDisplay">
              <div className="adc_pod-listContainer">
                <div className="adc_pod-listTopContainer">
                  <div className="adc_pod-listTopRightContainer">
                    <FilterMinerListSection
                      filter={filter}
                      onFilterInputChange={this.onFilterInputChange}
                      minerTypes={this.props.typeStore}
                      clearFilter={this.clearFilter}
                    />
                    <CommandSection
                      onClick={this.onClickCommand}
                      addManyToFav={this.addManyToFav}
                      deleteManyFromFav={this.deleteManyFromFav}
                      onChangeTypeClick={() => this.setState({typeModalOpen: true})}
                      openAlterStatus={() => this.setState({statusModalOpen: true})}
                      clearLocations={this.clearLocations}
                    />
                  </div>
                  <div className="adc_pod-listTopLeftContainer">
                    <CustomerInformationCard
                      type='Pod'
                      name={podInfo?podInfo.Name:pod}
                      house={podInfo?podInfo.House_Name:'N/A'}
                      totalMiners={podInfo?podInfo.totalMiners:'N/A'}
                      offlineMiners={podInfo?podInfo.minersOffline:'N/A'}
                      totalHashrate={podInfo?podInfo.Hash_Readable:'N/A'}
                      customerName={client}
                    />
                  </div>
                </div>
                <MinerList
                  exportList={this.exportList}
                  miners={miners}
                  client={client}
                  selectedMiners={selectedMiners}
                  onItemSelect={this.onItemSelect}
                  onSelectAll={this.onSelectAll}
                  isAllSelected={isAllSelected}
                  onPageClick={this.onPageClick}
                  currentPage={this.props.minerStore.current_page}
                  maxPage={this.props.minerStore.max_page}
                  onSort={this.onSort}
                  orderBy={this.props.pageStore.orderBy}
                  acending={this.props.pageStore.acending}
                  onClick={this.onItemClick}
                  pageSize={this.props.pageStore.pageSize}
                  changePageSize={this.changePageSize}
                  onClickCommand={this.onClickCommand}
                  alterFav={this.alterFav}
                  favoriteMiners={this.props.favMinersStore.data}
                  addManyToFav={this.addManyToFav}
                  deleteManyFromFav={this.deleteManyFromFav}
                  pod={this.props.match.params.pod}
                  onChangeTypeClick={() => this.setState({typeModalOpen: true})}
                  filter={filter}
                  onFilterInputChange={this.onFilterInputChange}
                  refreshData={this.refreshData}
                />
              </div>
        <Modal
          open={typeModalOpen}
          onClose={() => this.setState({typeModalOpen: false})}
          center
          classNames={{
            animationIn: 'EnterAnimation',
            animationOut: 'LeaveAnimation',
          }}
          animationDuration={500}

        >
          <h1>Pick a type</h1>
          <ul className="list-group">
            {this.props.typeStore.map((item) => {
              return <li className='list-group-item' onClick={(e) => this.changeType(item.ID)}>{item.Name}</li>
            })}
          </ul>
        </Modal>
        <Modal
          open={statusModalOpen}
          onClose={() => this.setState({statusModalOpen: false})}
          styles={modalStyles}>
          <h1>Pick a status</h1>
          <ul className="list-group">
            <li className='list-group-item' onClick={e => this.onAlterStatusClick('Online')}>Online</li>
            <li className='list-group-item' onClick={e => this.onAlterStatusClick('Recovery')}>Recovery</li>
            <li className='list-group-item' onClick={e => this.onAlterStatusClick('API_DOWN')}>API_DOWN</li>
            <li className='list-group-item' onClick={e => this.onAlterStatusClick('Missing_Fan')}>Missing_Fan</li>
            <li className='list-group-item' onClick={e => this.onAlterStatusClick('Missing_PSU')}>Missing_PSU</li>
            <li className='list-group-item' onClick={e => this.onAlterStatusClick('Missing_CTRL_Board')}>Missing_CTRL_Board</li>
          </ul>
        </Modal>
      </div>
    )
  }
}
const mapStateTopProps = (props) => ({
  userStore: props.userReducer,
  minerStore: props.minersReducer,
  commandStore: props.commandReducer,
  favMinersStore: props.favMinerReducer,
  pageStore: props.pageReducer.podList,
  currentClient: props.clientReducer.currentClient,
  typeStore: props.typeReducer.types,
  houseStore: props.houseReducer,
  listColumns: props.tableColumnsReducer.minerList,
  clientStore: props.clientReducer
})
export default connect(mapStateTopProps,
  {
    updatePageInfoAction,
    getMinersByPodAction,
    getFavMinersAction,
    deleteFavMinersAction,
    postFavMinersAction,
    sendCommandAction,
    setErrorMessageAction,
    updateClientAction,
    setMinerTypeAction,
    getTypesAction,
    markMinersDeletedAction,
    adjustMinersStatusAction,
    clearLocationsAction,
    clearMinersAction,
    resetMinerDisplayAction
  })(PodView)

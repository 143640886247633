import {
  getCustomersNamesConstant
} from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const getCustomersNameAction = (token) => {
  return async(dispatch) => {
    const result = await service.getCustomersName(token)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}
const success = (result) => ({
  type: getCustomersNamesConstant,
  payload: result
})

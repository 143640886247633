import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange, filterCheck  } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { deleteTypeAction } from '../../redux/actions/deleteTypeAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { postTypeAction } from '../../redux/actions/postTypeAction';
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { getAllTypesAction } from '../../redux/actions/getAllTypesAction'
import './style.css'

class MinerTypesView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      newTypes: [],
      modalOpen: false,
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    this.props.getTypesAction(this.props.token, this.props.match.params.name)
    this.props.getAllTypesAction(this.props.token, 10000, 1, 'Name', true, '')
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onDelete = (id) => {
    window.confirm("Are you sure you wish to delete this type?") &&
      this.props.deleteTypeAction(id, this.props.token, this.props.match.params.name)
  }
  onMinerTypeChange = (list) => {
    console.log(list)
    this.setState({newTypes: list})
  }
  onAdd = () => {
    let { newTypes } = this.state
    if(newTypes.length > 0){
      const data = this.props.typeStore.allTypes.data.filter((item) => filterCheck(newTypes, item, 'ID'))
      this.props.postTypeAction(data, this.props.token, this.props.match.params.name)
    }
    this.setState({modalOpen: false, newTypes: []})
  }
  render(){
    const { level } = this.props.userData
    const databaseTier = this.props.userData.databases.length > 0? this.props.userData.databases[0].Tier: 0
    const columns = [
      {field: 'Name', value: 'Name'},
      {field: 'Manufacturer', value: 'Manufacturer'},
      {field: 'Speed_Readable', value: 'Speed'},
      {field: 'Avg_Heat', value: 'Avg Heat'},
      {field: 'Asic_Num', value: 'Asic'},
      {field: 'Card_Num', value: 'Cards'},
      {field: 'Power_Consumption', value: 'Consumption'}]
    const buttons = level >= 2?([{class: 'ViewBody-button-delete', onClick: this.onDelete, type: 'Delete', extractKey: 'ID'}]):([])
    const pickMinerType = this.props.typeStore.allTypes.data.length > 0?this.props.typeStore.allTypes.data.filter((item) => !filterCheck(this.props.typeStore.types, item, 'Name')): []
    const addMinerTypeField = [
      {Name: 'Pick Miner Types', PropertyName: 'none', Value: this.state.newTypes, Type: 'multi-select', Options: pickMinerType.map((item) => ({ label: item.Name, value: item.ID, ID: item.ID }))}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="AlertsView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
          active="Miner Types"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={this.props.typeStore.types}
            columns={columns}
            buttons={buttons}
            list
            createtext="New miner types"
            onCreate={e => this.setState({modalOpen: true})}
            onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
            itemClickInfo={{name: 'minertypes', property: 'ID'}}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add miner types"
            fields={addMinerTypeField}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAdd}
            onInputChange={this.onMinerTypeChange}
            action="add_miner_type"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  typeStore: props.typeReducer,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  deleteTypeAction,
  postTypeAction,
  getTypesAction,
  getAllTypesAction,
  updateClientAction
})(MinerTypesView)

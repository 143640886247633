import { changePasswordConstant, postAuthCodeConstant, setUserDataConstant, updateSystemTokenConstant, getUserDataConstant } from '../constants/actions'
const defaultState = {
  ok: false,
  status: false,
  result: 0,
  token_id: 0,
  mf_enabled: false,
  mf_verified: false,
  username: '',
  userData: {
    level: 0,
    databases: []
  }
}
export default (state = defaultState, action) => {
  switch (action.type) {
    case getUserDataConstant:
      if(action.payload.ok){
        return {
          ...state,
          userData: action.payload.data
        }
      }
      return state
    case updateSystemTokenConstant:
      if(action.payload.ok){
          return {
            ...state,
            result: action.payload.data.token,
            token_id: action.payload.data.token_id
          }
      }
      return state
    case setUserDataConstant:
        return {
          ...state,
          userData: action.payload
        }
    case 'LOGOUT_ACTION':
      const newState = Object.assign({}, JSON.parse(JSON.stringify(action.payload)));
      return newState
    case postAuthCodeConstant:
        if(action.payload.ok){
          return {
            ...state,
            ok: true,
            status: true,
            result: action.payload.result,
            token_id: action.payload.token_id,
            mf_enabled: action.payload.mf_enabled,
            userData: {
              databases: action.payload.databases,
              level: action.payload.level,
              username: action.payload.username,
            },
            mf_verified: action.payload.mf_verified
          }
        }
        return state
    case 'LOGIN_ACTION':
      if(action.payload.ok){
        return {
          ...state,
          ok: true,
          status: false,
          mf_verified: false,
          result: action.payload.result,
          token_id: action.payload.token_id,
          mf_enabled: action.payload.mf_enabled,
          username: action.payload.username
        }
      }
      return state
    case changePasswordConstant:
      if(action.payload.ok){
        return {
          ...state,
          result: action.payload.result,
          ok: action.payload.ok
        }
      }
      else{
        const newState = {
          ...state,
          ok: true,
          result: state.result
        }
        return newState
      }
    default:
      if(action.payload && action.payload.key_error){
        const key_error = action.payload.key_error
        if(key_error.key === 'missing_token'){
          return {
            ...state,
            ok:false,
            result: 0,
            userData: {
              level: 0,
              databases: []
            }
          }
        }
        if(key_error.key === "invalid_token"){
          return {
            ...state,
            ok:false,
            result: 0,
            userData: {
              level: 0,
              databases: []
            }
          }
        }
      }
      return state
  }
}

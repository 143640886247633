import { clearMinerTypePowerConstant } from '../constants/actions'

export const clearMinerTypePowerAction = () => {
  return async(dispatch) => {
    dispatch(success())
  }
}


const success = (result) => ({
  type: clearMinerTypePowerConstant,
  payload: result
})

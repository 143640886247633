import {
  rootRoute
}
from '../constants/routes'

const service = () => {
  const getRackShelfs = (token, customer, region_id) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/rack/shelf/${customer}/region/${region_id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const postRackShelfs = (token, customer, body, region_id) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/rack/shelf/${customer}/region/${region_id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const editRackShelf = (token, customer, body, id, region_id) => {
    var requestOptions = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/rack/shelf/${id}/${customer}/region/${region_id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const deleteRackShelf = () => {
    return null
  }
  const getRackProfiles = (token, customer, region_id) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/rack/profile/${customer}/region/${region_id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const getRackProfileByID = (token, customer, id) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/rack/profile/${id}/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const postRackProfile = (token, customer, body, region_id) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/rack/profile/${customer}/region/${region_id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const deleteRackProfile = () => {
    return null
  }
  const editRackProfile = (token, customer, body, id, region_id) => {
    var requestOptions = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/rack/profile/${id}/${customer}/region/${region_id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const addRackProfileShelfsConnection = (token, customer, body) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/rack/profile/shelf/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const deleteRackProfileShelfsConnection = (token, customer, body) => {
    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/rack/profile/shelf/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const getRacksByPodID = (token, customer, id) => {
    return fetch(`${rootRoute}/pods/${id}/racks/${customer}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return{
          ok: true,
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const postRacksToPod = (token, customer, id, body) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/pods/${id}/racks/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  const removeRackFromPod = (token, customer, id, body) => {
    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/pods/${id}/racks/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      data: []
    }))
  }
  return {
    getRackShelfs,
    postRackShelfs,
    editRackShelf,
    deleteRackShelf,
    getRackProfiles,
    getRackProfileByID,
    postRackProfile,
    deleteRackProfile,
    editRackProfile,
    addRackProfileShelfsConnection,
    getRacksByPodID,
    postRacksToPod,
    removeRackFromPod,
    deleteRackProfileShelfsConnection
  }
}


export default service()

import clientService from '../services/clientService';
import { getMinersConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getMinersAction = (token, client, subnet, pageSize, pageNumber, orderBy, acending, filterString) => {
  return async (dispatch) => {
    try{
      const result = await clientService.getMiners(token, client, subnet, pageSize, pageNumber, orderBy, acending, filterString);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(getMinersSuccess(result));
    }
    catch(err){
      console.log(err)
    }
  }
}

const getMinersSuccess = ( miners ) => ({
  type: getMinersConstant,
  payload: miners
})

export const userGroupSwitch = (level) => {
  switch (level) {
    default:
    case 0:
      return "Select Users"
    case 1:
      return "Insert/Update Users"
    case 2:
      return "Administrator"
  }
}

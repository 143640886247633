import { getErrorLogsConstants, getSystemLogsConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/logService'

export const getMinerInternalLogsAction = (token, customer, mac) => {
  return async dispatch => {
    const errorLogsResult = await service.getErrorLogs(token, customer, mac);
    if(!errorLogsResult.ok && errorLogsResult.code !== 403){
      dispatch(setErrorMessageAction(errorLogsResult.msg))
    }
    dispatch(success(errorLogsResult, getErrorLogsConstants))
    const systemLogsResult = await service.getSystemLogs(token, customer, mac)
    if(!systemLogsResult.ok && errorLogsResult.code !== 403){
      dispatch(setErrorMessageAction(systemLogsResult.msg))
    }
    dispatch(success(systemLogsResult, getSystemLogsConstant))
  }
}

const success = (result, type) => ({
  type,
  payload: result
})

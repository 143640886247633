import{
  getAccessLevelsConstant,
  putAccessLevelsConstant,
}
from '../constants/actions'

const defaultState = {
  ok: true,
  api_version: 1.1,
  msg: '',
  levels: []
}

export default(state=defaultState, action) => {
  switch (action.type) {
    case getAccessLevelsConstant:
    case putAccessLevelsConstant:
      if(action.payload.ok){
        return action.payload
      }
      else{
        return{
          ok: false,
          msg: action.payload.msg,
          levels: state.levels
        }
      }
    default:
      return state;
  }
}

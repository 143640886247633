import { getPowerCutOrdersConstant } from '../constants/actions'
import service from '../services/powerCutOrderService'



export const getPowerCutOrdersAction = (token, pageSize, pageNumber, orderBy, acending, searchValue) => {
  return async(dispatch) => {
    const result = await service.getPowerCutOrders(token, pageSize, pageNumber, orderBy, acending, searchValue)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: getPowerCutOrdersConstant,
  payload: result
})

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import './style.css'
import { onTabHeaderClick, onInputChange, filterCheck } from '../../functions'
import { maintenanceView } from '../../helper/tabs'
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { adjustInventoryAction } from '../../redux/actions/adjustInventoryAction'
import { getInventoryAction } from '../../redux/actions/getInventoryAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'

class InventoryView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      addModalOpen: false,
      editMinerType: '',
      newMinerType: '----------',
      editFans: '',
      newFans: '',
      newControlboards: '',
      editControlboards: '',
      regionID: '',
      newHashboards: '',
      editHashboards: '',
      newCables: '',
      editCables: '',
      newPSU:'',
      editPSU:'',
      editModalOpen: false
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    const element = document.getElementById("maintenanceLink")
    if (element) {
      element.classList.add("itemSelected")
    }
    this.props.getInventoryAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id)
    this.props.getTypesAction(this.props.token, this.props.match.params.name)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onEdit = (minerType) => {
    const filterMinerType = this.props.inventory.inventory.filter((item) => item.Miner_Type == minerType)
    if(filterMinerType.length > 0){
      this.setState({
        editMinerType: minerType,
        editFans: filterMinerType[0].Fans,
        editControlboards: filterMinerType[0].Controlboards,
        editHashboards: filterMinerType[0].Hashboards,
        editCables: filterMinerType[0].Cables,
        editPSU: filterMinerType[0].PSU,
        editModalOpen: true
      })
    }
  }
  onInventoryAdd = () => {
    const { newFans, newHashboards, newCables, newPSU, newControlboards, newMinerType, regionID } = this.state;
    let body = []
    if(newMinerType === '' || newMinerType === '----------'){
      alert("Pick a miner type")
      return
    }
    if(newFans != '' && newFans > 0){
      body.push({
        type: "Fans",
        amount: newFans
      })
    }
    if(newHashboards != '' && newHashboards > 0){
      body.push({
        type: "Hashboards",
        amount: newHashboards
      })
    }
    if(newCables != '' && newCables > 0){
      body.push({
        type: "Cables",
        amount: newCables
      })
    }
    if(newPSU != '' && newPSU > 0){
      body.push({
        type: "PSU",
        amount: newPSU
      })
    }
    if(newControlboards != '' && newControlboards > 0){
      body.push({
        type: "Controlboards",
        amount: newControlboards
      })
    }
    this.props.adjustInventoryAction(this.props.token, this.props.match.params.name, 'addition', {minerType: newMinerType, types: body, regionID }, this.props.match.params.region_id)
    this.setState({
        addModalOpen: false,
        newMinerType: '----------',
        newFans: '',
        newControlboards: '',
        newHashboards: '',
        newCables: '',
        newPSU:'',
        regionID: ''
    })
  }
  onInventoryEdit = () => {
    const { editFans, editHashboards, editCables, editPSU, editMinerType, editControlboards, regionID } = this.state
    const item = {
      Fans: editFans,
      Hashboards: editHashboards,
      Controlboards: editControlboards,
      PSU: editPSU,
      Cables: editCables,
    }
    this.props.adjustInventoryAction(this.props.token, this.props.match.params.name, "override", {minerType: editMinerType, item, regionID: this.props.match.params.region_id}, this.props.match.params.region_id)
    this.setState({
      editMinerType: '',
      editFans: '',
      editControlboards: '',
      editHashboards: '',
      editCables: '',
      editPSU: '',
      editModalOpen: false,
      regionID: ''
    })
  }
  render(){
    const { userData } = this.props.userStore
    const columns = [
      {field: 'Miner_Type', value: 'Miner Type'},
      {field: 'Fans', value: 'fans'},
      {field: 'Controlboards', value: 'Controlboards'},
      {field: 'Hashboards', value: 'Hashboards'},
      {field: 'Cables', value: 'Cables'},
      {field: 'PSU', value: 'Power Supplies'},
      {field: 'Region_Name', value: 'Region'},
    ]
    let buttons = []
    if(this.props.match.params.region_id !== 'All'){
      buttons = [
        {class: 'ViewBody-button-edit', onClick: this.onEdit, type: 'Edit', extractKey: 'Miner_Type'}
      ]
    }
    const currentMinerTypes = this.props.inventory.inventory.map((item) => ({Name: item.Miner_Type}))
    const pickMinerTypes = [{Name:'----------'}].concat(this.props.typeStore.types.filter((item) => !filterCheck(currentMinerTypes, item, 'Name')))
    let addInventoryField = ''
    if(this.props.match.params.region_id === 'All'){
      let regions = userData != ''? userData.databases.filter((item) => item.Database_Name === this.props.match.params.name):[]
      regions = regions[0]? regions[0].Regions: []
      addInventoryField = [
        {Name: 'Pick Miner Type', PropertyName: 'newMinerType', Value: this.state.newMinerType, Type: 'select', Options: pickMinerTypes.map((item) => ({text: item.Name, value: item.Name}))},
        {Name: 'Region', PropertyName: 'regionID', Value: this.state.regionID, Type: 'select', Options: [{text: "----------", value: ''}].concat(regions.map((item) => ({text: item.Name, value: item.Region_ID})))},
        {Name: 'Fans', PropertyName: 'newFans', Value: this.state.newFans, Type: 'int', Options: []},
        {Name: 'Controlboards', PropertyName: 'newControlboards', Value: this.state.newControlboards, Type: 'int', Options: []},
        {Name: 'Hashboards', PropertyName: 'newHashboards', Value: this.state.newHashboards, Type: 'int', Options: []},
        {Name: 'Cables', PropertyName: 'newCables', Value: this.state.newCables, Type: 'int', Options: []},
        {Name: 'Power Supplies', PropertyName: 'newPSU', Value: this.state.newPSU, Type: 'int', Options: []}
      ]
    }
    else{
      addInventoryField = [
        {Name: 'Pick Miner Type', PropertyName: 'newMinerType', Value: this.state.newMinerType, Type: 'select', Options: pickMinerTypes.map((item) => ({text: item.Name, value: item.Name}))},
        {Name: 'Fans', PropertyName: 'newFans', Value: this.state.newFans, Type: 'int', Options: []},
        {Name: 'Controlboards', PropertyName: 'newControlboards', Value: this.state.newControlboards, Type: 'int', Options: []},
        {Name: 'Hashboards', PropertyName: 'newHashboards', Value: this.state.newHashboards, Type: 'int', Options: []},
        {Name: 'Cables', PropertyName: 'newCables', Value: this.state.newCables, Type: 'int', Options: []},
        {Name: 'Power Supplies', PropertyName: 'newPSU', Value: this.state.newPSU, Type: 'int', Options: []}
      ]
    }
    const editInventoryField = [
      {Name: 'Miner Type', PropertyName: 'editMinerType', Value: this.state.editMinerType, Type: 'text-disabled', Options: []},
      {Name: 'Fans', PropertyName: 'editFans', Value: this.state.editFans, Type: 'int', Options: []},
      {Name: 'Controlboards', PropertyName: 'editControlboards', Value: this.state.editControlboards, Type: 'int', Options: []},
      {Name: 'Hashboards', PropertyName: 'editHashboards', Value: this.state.editHashboards, Type: 'int', Options: []},
      {Name: 'Cables', PropertyName: 'editCables', Value: this.state.editCables, Type: 'int', Options: []},
      {Name: 'Power Supplies', PropertyName: 'editPSU', Value: this.state.editPSU, Type: 'int', Options: []}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="InventoryView-container">
          <TabHeader
            logo={<i className="fa fa-wrench fa-5x"/>}
            title="Inventory & Maintenance"
            subtitle="Manage your inventory, and look over logs"
            tabs={maintenanceView}
            active="Inventory"
            onClick={this.onClick}
          />
          <ViewBody
            data={this.props.inventory.inventory}
            columns={columns}
            buttons={buttons}
            list
            createtext="Add inventory"
            onCreate={e => this.setState({addModalOpen: true})}
          />
          <Modal
            open={this.state.addModalOpen}
            center
            onClose={() => this.setState({ addModalOpen: false })}
            styles={modalStyle}
          >
            <FormfieldSection
              title="Add inventory"
              fields={addInventoryField}
              addButton={true}
              buttonText="Add"
              onAddClick={this.onInventoryAdd}
              onInputChange={e => onInputChange(e, this)}
              action="add_inventory"
            />
          </Modal>
          <Modal
            open={this.state.editModalOpen}
            center
            onClose={() => this.setState({ editModalOpen: false })}
            styles={modalStyle}
          >
            <FormfieldSection
              title={`Edit invotory for ${this.state.editMinerType}`}
              fields={editInventoryField}
              addButton={true}
              buttonText="Edit"
              onAddClick={this.onInventoryEdit}
              onInputChange={e => onInputChange(e, this)}
              action="edit_inventory"
            />
          </Modal>
      </div>
    )
  }
}


const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userStore: props.userReducer,
  inventory: props.inventoryReducer,
  typeStore: props.typeReducer,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  getTypesAction,
  adjustInventoryAction,
  getInventoryAction,
  updateClientAction
})(InventoryView)

import { getFlagTypeInfoByIDConstant } from '../constants/actions'
import service from '../services/flagService'



export const getFlagTypeInfoByIDAction = (id, token, customer, region_id) => {
  return async(dispatch) => {
    const result = await service.getFlagTypeInfoByID(id, token, customer, region_id)
    dispatch(success(result))
  }
}



const success = (result) =>({
  type: getFlagTypeInfoByIDConstant,
  payload: result
})

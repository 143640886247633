import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import './style.css'
const SendTicketModal = ({ show, handleClose, title, body, otherInfo, submitEmail }) => {
  return(
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label for="emailBody">Email body</label>
        <textarea className="ticket-extraInfo" name="emailBody" rows="4" disabled>{body}</textarea>
        </Modal.Body>
        <Modal.Body>
          <label for="extraInfo">Extra Info</label>
          <textarea className="ticket-extraInfo" name="extraInfo" rows="4" id="ticket-extraInfo"></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitEmail}>
            Send Email
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default SendTicketModal;

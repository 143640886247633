import React from 'react';
import { render } from 'react-dom';
import Highcharts from 'highcharts/highstock';
import { formatBytes, checkTime, formatTime } from './formatUtils'
import HighchartsReact from 'highcharts-react-official';
import { highchartOptions } from './functions'
import './style.css'


const HighchartGraph = ({data, type, api_version, title}) => {
  const options = highchartOptions(data, api_version, type, title)
  return(
    <div className="HighchartsReact-container">
      <HighchartsReact
        containerProps={{id: 'data-chart'}}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
    </div>
  );
}


export default HighchartGraph

import {
  getAllScannersConstant
} from '../constants/actions'
import service from '../services//adminService'
import { setErrorMessageAction } from './setErrorMessageAction'



export const getAllScannersAction = (token, pageSize, pageNumber, orderBy, acending, searchValue) => {
  return async(dispatch) => {
    const result = await service.getAllScanners(token, pageSize, pageNumber, orderBy, acending, searchValue)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}
const success = (result) => ({
  type: getAllScannersConstant,
  payload: result
})

import { getMinerStatusOverviewConstant } from '../constants/actions'
import service from '../services/minerService'
import { setErrorMessageAction } from './setErrorMessageAction'



export const getMinerStatusOverviewAction = (token, customer, region_id) => {
  return async(dispatch) =>{
    const result = await service.getMinerStatusOverview(token, customer, region_id)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}


const success = (result) => ({
  type: getMinerStatusOverviewConstant,
  payload: result
})

import { getFlagCheckoutDataConstant } from '../constants/actions'
import service from '../services/flagService'



export const getFlagCheckoutDataAction = (token, customer, region_id) => {
  return async(dispatch) => {
    const result = await service.getFlagCheckoutData(token, customer, region_id)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: getFlagCheckoutDataConstant,
  payload: result
})

import { getMaintenanceByMACConstant} from '../constants/actions'
import service from '../services/logService'
import { setErrorMessageAction } from './setErrorMessageAction';
import { setSuccessMessageAction } from './setSuccessMessageAction';

export const getMaintenanceBySNAction = (token, customer, mac,  pageSize, pageNumber, orderBy, acending) => {
  return async(dispatch)=> {
    try {
      const result = await service.getMaintenanceByMAC(token, mac, customer, pageSize, pageNumber, orderBy, acending)
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(success(result));
    } catch (e) {
      console.log(e)
    }
  }
}
const success = (result) => ({
  type: getMaintenanceByMACConstant,
  payload: result
})

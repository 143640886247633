import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
const createOption = (startingNum, size, data = null) => {
  let returnValue = []
  if(data == null){
    for(; startingNum <= size; startingNum++){
      returnValue.push(<option key={startingNum}>{startingNum}</option>)
    }
  }
  else{
    data.map((item,key) => {
      if(item.hasOwnProperty("pod")){
          returnValue.push(<option key={key}>{item.pod.Name}</option>)
      }
      else{
        returnValue.push(<option key={key}>{item.Name}</option>)
      }
      return
    })
  }
  return returnValue;
}
const MapConfigureForm = ({pods, houses, houseName, podName, startingRack, startingShelf, startingMiner, shelfNum, minerNum, onInputChange, onMultiClick}) => {
  return(
      <form>
        <fieldset>
          <legend><span className="number">1</span>Configure Info</legend>
          <label htmlFor="houseName">Building Name</label>
          <select name="houseName" defaultValue={houseName} onInput={e => onInputChange(e)}>
            {createOption(0,0,houses)}
          </select>
          <label htmlFor="podName">Pod Name</label>
          <select name="podName" defaultValue={podName} onInput={e => onInputChange(e)}>
            {createOption(0,0,pods)}
          </select>
        </fieldset>
        <fieldset>
          <label htmlFor="mapMulti">Map Multiple Miners</label>
          <input type="checkbox" name="mapMulti" onClick={e => onMultiClick(e)} />
        </fieldset>
        <fieldset id="multi-map-field" className="displayNone">
          <legend><span className="number">2</span>Position Info</legend>
          <label htmlFor="shelfNum">Number Of Shelfs</label>
          <select name="shelfNum" onInput={e => onInputChange(e)}>
            {createOption(1,9)}
          </select>
          <label htmlFor="minerNum">Number of miners per shelf</label>
          <select name="minerNum" onInput={e => onInputChange(e)}>
            {createOption(1,9)}
          </select>
          <label for="gaps-shelf">Custom Shelf</label>
          <div className="gasp-div">
            <select name="gapsShelf" onInput={e => onInputChange(e)}>
              <option>Shelf</option>
              {createOption(1,9)}
            </select>
            <select name="gapsShelf_value" onInput={e => onInputChange(e)}>
              <option>Value</option>
              {createOption(1,9)}
            </select>
          </div>
          <label htmlFor="startingRack">Pick starting rack: {startingRack}</label>
          <input type="range" id="startingRack" name="startingRack" min="1" max="100" defaultValue={startingRack} className="custom-range" onInput={e => onInputChange(e)}/>
          <label htmlFor="startingShelf">Pick starting shelf: {startingShelf}</label>
          <input type="range" id="startingShelf" name="startingShelf" min="1" max={shelfNum} defaultValue={startingShelf} className="custom-range" onInput={e => onInputChange(e)}/>
          <label htmlFor="startingMiner">Pick starting miner: {startingMiner}</label>
          <input type="range" id="startingMiner" name="startingMiner" min="1" max={minerNum} defaultValue={startingMiner} className="custom-range" onInput={e => onInputChange(e)}/>
        </fieldset>
      </form>
  )
}

MapConfigureForm.propTypes = {
  pods: PropTypes.array.isRequired,
  houses: PropTypes.array.isRequired,
  houseName: PropTypes.string.isRequired,
  podName: PropTypes.string.isRequired,
  startingRack: PropTypes.number.isRequired,
  startingShelf: PropTypes.number.isRequired,
  startingMiner: PropTypes.number.isRequired,
  shelfNum: PropTypes.number.isRequired,
  minerNum: PropTypes.number.isRequired,
  onInputChange: PropTypes.func.isRequired
}

export default MapConfigureForm;

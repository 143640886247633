import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import './style.css'
import { onTabHeaderClick, onInputChange, filterCheck } from '../../functions'
import { maintenanceView } from '../../helper/tabs'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { getInventoryHistoryAction } from '../../redux/actions/getInventoryHistoryAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'

class InventoryLogsView extends React.Component{
  constructor(props){
    super(props)
    this.state={
      searchValue: ''
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    const element = document.getElementById("maintenanceLink")
    if (element) {
      element.classList.add("itemSelected")
    }
    this.props.getInventoryHistoryAction(
      this.props.token,
      this.props.match.params.name,
      this.props.inventoryHistoryPage.pageSize,
      this.props.inventoryHistoryPage.pageNumber,
      this.props.inventoryHistoryPage.orderBy,
      this.props.inventoryHistoryPage.acending,
      this.state.searchValue,
      this.props.match.params.region_id)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onHistorySort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.inventoryHistoryPage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getInventoryHistoryAction(this.props.token, this.props.match.params.name, pageSize, pageNumber, type, acending, this.state.searchValue);
    const store = {
      client: this.props.inventoryHistoryPage.client,
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'inventoryHistory'
    }
    this.props.updatePageInfoAction(store);
  }
  onChangePageSize = (e) => {
    const { orderBy, acending } = this.props.inventoryHistoryPage;
    const value = parseInt(e.target.value)
    let pageNum = this.props.inventoryHistoryPage.pageNumber;
    if(value*parseInt(pageNum) > parseInt(this.props.inventory.logs.max_page)*parseInt(this.props.inventory.logs.page_size)){
      pageNum = 1;
    }
    if(value > parseInt(this.props.inventory.logs.max_page)*parseInt(this.props.inventory.logs.page_size)){
      pageNum = 1;
    }
    this.props.getInventoryHistoryAction(this.props.token, this.props.match.params.name, value, pageNum, orderBy, acending, this.state.searchValue);
    const store = {
      MAC_Address: this.props.match.params.miner,
      pageSize: value? value: 0,
      pageNumber: pageNum,
      client: this.props.match.params.name,
      type: this.props.inventoryHistoryPage.type,
      orderBy: this.props.inventoryHistoryPage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onHistoryPageClick = (pageNumber) => {
    const { pageSize, orderBy, acending } = this.props.inventoryHistoryPage;
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber === parseInt(this.props.inventory.logs.current_page)) return
    if (pageNumber !== 0 && pageNumber <= this.props.inventory.logs.max_page) {
      this.props.getInventoryHistoryAction(this.props.token, this.props.match.params.name, pageSize, pageNumber, orderBy, acending, this.state.searchValue);
    }
    const store = {
      MAC_Address: this.props.match.params.miner,
      client: this.props.inventoryHistoryPage.client,
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'inventoryHistory'
    }
    this.props.updatePageInfoAction(store);
  }
  onSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.inventoryHistoryPage;
    this.props.getInventoryHistoryAction(this.props.token, this.props.match.params.name, pageSize, 1, orderBy, acending, e.target.value);
    this.setState({
      searchValue: e.target.value
    })
    const store = {
      MAC_Address: this.props.match.params.miner,
      pageSize: pageSize,
      pageNumber: 1,
      client: this.props.match.params.name,
      type: this.props.inventoryHistoryPage.type,
      orderBy: this.props.inventoryHistoryPage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  render(){
    const columns = [
      {field: 'Miner_Type', value: "Miner Type"},
      {field: 'Username', value: "User"},
      {field: 'Action', value: "Action"},
      {field: 'Amount', value: "Amount"},
      {field: 'Item_Type', value: "Item"},
      {field: 'Action_Date', value: "Date"}
    ]
    return(
      <div className="InventoryLogsView-container">
          <TabHeader
            logo={<i className="fa fa-wrench fa-5x"/>}
            title="Inventory & Maintenance"
            subtitle="Manage your inventory, and look over logs"
            tabs={maintenanceView}
            active="Inventory Logs"
            onClick={this.onClick}
          />
          <ViewBody
            data={this.props.inventory.logs.data? this.props.inventory.logs.data: []}
            columns={columns}
            list
            pager
            sorting
            search
            searchValue={this.state.searchValue}
            onSearchChange={this.onSearch}
            acending={this.props.inventoryHistoryPage.acending}
            sortedBy={this.props.inventoryHistoryPage.orderBy}
            onSort={this.onSort}
            totalPages={this.props.inventory.logs.max_page? this.props.inventory.logs.max_page: 1}
            currentPage={parseInt(this.props.inventory.logs.current_page) - 1}
            onPageChange={this.onHistoryPageClick}
          />
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  inventoryHistoryPage: props.pageReducer.inventoryHistory,
  inventory: props.inventoryReducer,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  updatePageInfoAction,
  getInventoryHistoryAction,
  updateClientAction
})(InventoryLogsView)

import React from 'react'
import { connect } from 'react-redux'
import { getDatabases } from '../../redux/actions/getDatabasesAction'
import { replaceHistoryAction } from '../../redux/actions/replaceHistoryAction';
import { removeDashboardAction } from '../../redux/actions/removeDashboardAction';
import { getAllAlertsAction } from '../../redux/actions/getAllAlertsAction';
import CustomersStatusTable from '../../Components/CustomersStatusTable';
import OfflineMinersTable from '../../Components/OfflineMinersTable';
import PanelList from '../../Components/PanelList'
import { clearAlertsAction } from '../../redux/actions/clearAlertsAction'
import { filterCustomersAction } from '../../redux/actions/filterCustomersAction'
import { postCommentAction } from '../../redux/actions/postCommentAction'
import { seenMinerAction } from '../../redux/actions/seenMinerAction'
import { clearDashInfoAction } from '../../redux/actions/clearDashInfoAction'
import { toggleSeenAction } from '../../redux/actions/toggleSeenAction'
import { clearCurrentClientAction } from '../../redux/actions/clearCurrentClientAction'
import {
  changePageSize24HoursPlus,
  changePageSize24Hours,
  onPageClick24HoursPlus,
  onPageClick24Hours,
  onPageClickCustomerStatus,
  changePageSizeCustomerStatus
} from '../../redux/constants/actions'
import { changePageSizeAction } from '../../redux/actions/changePageSizeAction'
import { onPageClickAction } from '../../redux/actions/onPageClickAction'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import AlertsTable from '../../Components/AlertsTable'
import Client  from '../../Components/Clients'
import Pager from '../../Components/Pager'
import { Modal } from "react-responsive-modal";
import './style.css';
class DashboardView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      minimizedElements: [],
      intervalID: '',
      commentModalOpen: false,
      commentModalItem: '',
      commentsOpen24HoursPlus: [],
      writeCommentsOpen24HoursPlus: [],
      commentsOpen24Hours: [],
      writeCommentsOpen24Hours: [],
      currentFilter24Hours: 'All',
      currentFilter24HoursPlus: 'All',
      toggleSeen24Hours: false,
      toggleSeen24HoursPlus: false,
      windowSize: window.innerWidth
    }
  }
  componentDidMount = async () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
    this.props.clearCurrentClientAction()
    this.props.replaceHistoryAction()
    this.initalSetup(true)
    window.addEventListener('resize', this.setWindowSize);
  }
  setWindowSize = (e) => {
    const { windowSize } = this.state
    if(window.innerWidth <= 700 && windowSize > 700 || windowSize <= 700 && window.innerWidth >= 700){
      this.setState({windowSize: window.innerWidth})
    }
  }
  componentDidUpdate = () => {
    if(this.props.clientsStore.refreshData && this.state.intervalID === ''){
      const intervalID = setInterval(this.initalSetup, 60000);
      this.setState({intervalID})
    }
    if(!this.props.clientsStore.refreshData && this.state.intervalID !== ''){
      const { intervalID } = this.state;
      clearInterval(intervalID)
      this.setState({intervalID: ''})
    }
  }
  initalSetup = async(initalGet = false) => {
    const token = this.props.userStore.result;
    this.props.getDatabases(token, initalGet);
  }
  componentWillUnmount = () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
    const { intervalID } = this.state;
    clearInterval(intervalID)
    window.removeEventListener('resize', this.setWindowSize);
    this.props.clearDashInfoAction();
  }
  toggleMiniMaxi = (element) => {
    let { minimizedElements } = this.state;
    if(minimizedElements.indexOf(element) == -1){
      minimizedElements.push(element)
    }
    else{
      minimizedElements = minimizedElements.filter((item) => item !== element);
    }
    this.setState({minimizedElements})
  }
  onAlertItemClick = (key) => {
    this.props.history.push(`/client/${this.props.alertStore.alerts.data[key].Customer}`)
  }
  onPageClick = (pageNumber) => {
    if(pageNumber == this.props.alertPage.pageNumber){
      return
    }
    if(pageNumber !== 0 && pageNumber <= this.props.alertStore.alerts.maxPage){
      this.props.getAllAlertsAction(this.props.userStore.result, this.props.alertPage.pageSize, pageNumber, this.props.alertPage.orderBy, this.props.alertPage.acending);
    }
    const store = {
      pageSize: this.props.alertPage.pageSize,
      pageNumber: pageNumber,
      type: 'allAlerts',
      orderBy: this.props.alertPage.orderBy,
      acending: this.props.alertPage.acending
    }
    this.props.updatePageInfoAction(store)
  }
  onPageSort = (type) => {
    let { acending, orderBy } = this.props.alertPage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getAllAlertsAction(this.props.userStore.result, this.props.alertPage.pageSize, this.props.alertPage.pageNumber, type, acending);
    const store = {
      pageSize: this.props.alertPage.pageSize,
      pageNumber: this.props.alertPage.pageNumber,
      type: 'allAlerts',
      orderBy: type,
      acending: acending
    }
    this.props.updatePageInfoAction(store);
  }
  onPageSizeChange = (e) => {
    const value = e.target.value;
    let pageNum = this.props.alertPage.pageNumber;
    const items = parseInt(this.props.alertStore.alerts.maxPage)*parseInt(this.props.alertStore.alerts.pageSize)
    if(value > items){
      pageNum = 1;
    }
    if((this.props.alertPage.pageNumber*value) > items){
      pageNum = 1;
    }

    this.props.getAllAlertsAction(this.props.userStore.result, value, pageNum, this.props.alertPage.orderBy, this.props.alertPage.acending);
    const store = {
      pageSize: value? value:0,
      pageNumber: pageNum,
      type: 'allAlerts',
      orderBy: this.props.alertPage.orderBy,
      acending: this.props.alertPage.acending
    }
    this.props.updatePageInfoAction(store);
  }
  onPageClick24HoursPlus = (pageNumber) => {
    if(pageNumber !== this.props.clientsStore.offlineMiners24HoursPlus.current_page){
      this.props.changePageSizeAction(pageNumber, onPageClick24HoursPlus)
    }
  }
  onPageChange24HoursPlus = (e) => {
    if(e.target.value !== this.props.clientsStore.offlineMiners24HoursPlus.page_size){
      this.props.changePageSizeAction(e.target.value, changePageSize24HoursPlus)
    }
  }
  onPageClick24Hours = (pageNumber) => {
    if(pageNumber !== this.props.clientsStore.offlineMiners24Hours.current_page){
      this.props.changePageSizeAction(pageNumber, onPageClick24Hours)
    }
  }
  onPageChange24Hours = (e) => {
    if(e.target.value !== this.props.clientsStore.offlineMiners24Hours.page_size){
      this.props.changePageSizeAction(e.target.value, changePageSize24Hours)
    }
  }
  onPageChangeCustomerStatus = (e) => {
    if(e.target.value !== this.props.clientsStore.customerOverviewInfo.page_size){
      this.props.changePageSizeAction(e.target.value, changePageSizeCustomerStatus)
    }
  }
  onPageClickCustomerStatus = (pageNumber) => {
    if(pageNumber !== this.props.clientsStore.customerOverviewInfo.current_page){
      this.props.changePageSizeAction(pageNumber, onPageClickCustomerStatus)
    }
  }
  filterCustomer = (e) => {
    if(e.target.name == 'filterUnder24'){
      this.setState({currentFilter24Hours: e.target.value, toggleSeen24Hours: false})
    }
    if(e.target.name === 'filterOver24'){
      this.setState({currentFilter24HoursPlus: e.target.value, toggleSeen24HoursPlus: false})
    }
    this.props.filterCustomersAction(e.target.name, e.target.value)
  }
  onCommentClick24HoursPlus = (key) => {
    let { writeCommentsOpen24HoursPlus } = this.state;
    if(writeCommentsOpen24HoursPlus.indexOf(key) !== -1){
      writeCommentsOpen24HoursPlus = writeCommentsOpen24HoursPlus.filter((item) => item !== key)
    }
    else{
      writeCommentsOpen24HoursPlus.push(key)
    }
    this.setState({writeCommentsOpen24HoursPlus})
  }
  onCommentClick24Hours = (key) => {
    let { writeCommentsOpen24Hours } = this.state;
    if(writeCommentsOpen24Hours.indexOf(key) !== -1){
      writeCommentsOpen24Hours = writeCommentsOpen24Hours.filter((item) => item !== key)
    }
    else{
      writeCommentsOpen24Hours.push(key)
    }
    this.setState({writeCommentsOpen24Hours})
  }
  submitComment24HoursPlus = (key) => {
    const element = document.getElementById(`comment${key}`)
    if(element){
      let { writeCommentsOpen24HoursPlus } = this.state;
      const item = this.props.clientsStore.offlineMiners24HoursPlus.data[key]
      this.props.postCommentAction(this.props.userStore.result, {"comment": element.value}, item.MAC_Address, item.customer, '24Hours+')
      writeCommentsOpen24HoursPlus = writeCommentsOpen24HoursPlus.filter((item) => item !== key)
      this.setState({writeCommentsOpen24HoursPlus})
    }
  }
  submitComment24Hours = (key) => {
    const element = document.getElementById(`comment${key}`)
    if(element){
      let { writeCommentsOpen24Hours } = this.state;
      const item = this.props.clientsStore.offlineMiners24Hours.data[key]
      this.props.postCommentAction(this.props.userStore.result, {"comment": element.value}, item.MAC_Address, item.customer, '24Hours')
      writeCommentsOpen24Hours = writeCommentsOpen24Hours.filter((item) => item !== key)
      this.setState({writeCommentsOpen24Hours})
    }
  }
  on24HourItemClick = (key) => {
    let { commentsOpen24Hours } = this.state;
    if(commentsOpen24Hours.indexOf(key) !== -1){
      commentsOpen24Hours = commentsOpen24Hours.filter((item) => item !== key)
    }
    else{
      commentsOpen24Hours.push(key)
    }
    this.setState({commentsOpen24Hours})
  }
  on24HourPlusItemClick = (key) => {
    let { commentsOpen24HoursPlus } = this.state;
    if(commentsOpen24HoursPlus.indexOf(key) !== -1){
      commentsOpen24HoursPlus = commentsOpen24HoursPlus.filter((item) => item !== key)
    }
    else{
      commentsOpen24HoursPlus.push(key)
    }
    this.setState({commentsOpen24HoursPlus})
  }
  seenMinerAction24HourPlus = (key) => {
    const item = this.props.clientsStore.offlineMiners24HoursPlus.data[key]
    this.props.seenMinerAction(this.props.userStore.result, item.MAC_Address, item.customer, '24Hours+')
  }
  seenMinerAction24Hour = (key) => {
    const item = this.props.clientsStore.offlineMiners24Hours.data[key]
    this.props.seenMinerAction(this.props.userStore.result, item.MAC_Address, item.customer, '24Hours')
  }
  toggleMinerSeen = (type, value) => {
    if(type === 'offlineMiners24HoursPlus'){
      this.setState({toggleSeen24HoursPlus: value})
    }
    else{
      this.setState({toggleSeen24Hours: value})
    }
    this.props.toggleSeenAction(value, type)
  }
   render() {
    const { clients } = this.props.clientsStore;
    const {
      minimizedElements,
      commentModalOpen,
      writeCommentsOpen24HoursPlus,
      commentsOpen24HoursPlus,
      writeCommentsOpen24Hours,
      commentsOpen24Hours,
      currentFilter24Hours,
      currentFilter24HoursPlus,
      toggleSeen24HoursPlus,
      toggleSeen24Hours,
      windowSize
    } = this.state;
    const data = this.props.clientsStore.customerOverviewInfo.data.map((item) => {
      const title = item.customer
      const data = [
        {field: 'Online Miners', value: item.onlineMiners},
        {field: 'Offline Miners', value: item.offlineMiners,  classList: ['red-color']},
        {field: 'Idle Miners', value: item.zeroHash},
        {field: 'Total Hashrate', value: item.totalHashrate},
        {field: 'Estimate Power', value: item.estPower},
        {field: 'Performance', value: item.performance}
      ]
      const link = `/client/${title}/region/All`
      return {
        title,
        data,
        link
      }
    })
    return (
        <div className="dashContainer">
          {windowSize > 700?(
            <div className="dashOverview">
                <div className="dashDivPager">
                  <CustomersStatusTable
                    data={this.props.clientsStore.customerOverviewInfo.data}
                    reducerStatus={this.props.clientsStore.ok}
                  />
                </div>
              </div>
          ):(
            <PanelList
              list={data}
            />
          )}
        </div>
    )
  }
}
const mapStateTopProps = (props) => ({
  clientsStore: props.clientReducer,
  userStore: props.userReducer, props,
  alertStore: props.alertReducer,
  alertPage: props.pageReducer.allAlerts
})
export default connect(mapStateTopProps, {
  getDatabases,
  replaceHistoryAction,
  removeDashboardAction,
  getAllAlertsAction,
  clearAlertsAction,
  updatePageInfoAction,
  changePageSizeAction,
  onPageClickAction,
  filterCustomersAction,
  postCommentAction,
  seenMinerAction,
  clearDashInfoAction,
  toggleSeenAction,
  clearCurrentClientAction
})(DashboardView)

import commandService from '../services/commandService';
import { sendCommandConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';

export const sendCommandAction = (token, client, body, command) => {
  return async(dispatch) => {
    try{
      const result = await commandService.sendCommand(token, client, body, command);
      console.log(result)
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(sendCommandSuccess(result))
    }
    catch(err){
      console.log(err)
    }
  }
}
const sendCommandSuccess = (result) => {
  return{
    type: sendCommandConstant,
    payload: result
  }
}

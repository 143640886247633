import { getAverageReportData7daysConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/clientService'

export const getAverageReportData7DaysAction = (token, customer, region_id) => {
  return async(dispatch) => {
    const result = await service.getAverageReport(token, customer, 24*7, region_id)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch({
      type: getAverageReportData7daysConstant,
      payload: result
    })
  }
}

import { deleteMinerTypeConstant } from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'



export const deleteMinerTypeAction = (token, id) => {
  return async(dispatch) => {
    const result = await service.deleteMinerType(token, id);
    //alert("getting all alerts returned")
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result, id))
  }
}


const success = (result, id) => ({
  type: deleteMinerTypeConstant,
  payload: {result, id}
})

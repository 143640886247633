import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { minerView } from '../../helper/tabs'
import { getMaintenanceBySNAction } from '../../redux/actions/getMaintenanceBySNAction'
import { clearMaintenanceAction } from '../../redux/actions/clearMaintenanceAction'
import { postMaintenanceAction } from '../../redux/actions/postMaintenanceLogAction';
import { getMinerAction } from '../../redux/actions/getMinerAction'
import './style.css'

class MinerMaintenanceView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      fans: 0,
      psu: 0,
      cables: 0,
      hashboards: 0,
      controlboards: 0,
      description: '',
      other_info: '',
      sn:'',
      modalOpen: false
    }
  }
  componentDidMount = async() => {
    await this.props.getMinerAction(this.props.token, this.props.match.params.name, this.props.match.params.miner)
    const { pageSize, orderBy, acending, pageNumber } = this.props.maintenanceLogPage;
    if(this.props.minerStore.miner[0].Serial_Number !== 'None'){
      this.props.getMaintenanceBySNAction(this.props.token, this.props.match.params.name, this.props.minerStore.miner[0].Serial_Number, pageSize, pageNumber, orderBy, acending);
      this.setState({sn: this.props.minerStore.miner[0].Serial_Number})
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onAdd = () => {
    let items = []
    const { fans, psu, cables, hashboards, controlboards, description, other_info, sn} = this.state
    if(sn === ''){
      alert("the serial number is missing")
      return
    }
    if(description === ''){
      alert("the decription is missing")
      return
    }
    if(fans !== 0){
      items.push({type: 'Fans', amount: fans})
    }
    if(psu !== 0){
      items.push({type: 'PSU', amount: psu})
    }
    if(hashboards !== 0){
      items.push({type: 'Hashboards', amount: hashboards})
    }
    if(cables !== 0){
      items.push({type: 'Cables', amount: cables})
    }
    if(controlboards !== 0){
      items.push({type: 'Controlboards', amount: controlboards})
    }
    this.props.postMaintenanceAction(
      {Description: description, Other_Info: other_info, MAC_Address: this.props.match.params.miner, items, Serial_Number: sn},
      this.props.token,
      this.props.match.params.name,
      this.props.maintenanceLogPage.pageSize,
      this.props.maintenanceLogPage.pageNumber,
      this.props.maintenanceLogPage.orderBy,
      this.props.maintenanceLogPage.acending
    )
    this.setState({
      fans: 0,
      psu: 0,
      cables: 0,
      hashboards: 0,
      controlboards: 0,
      description: '',
      other_info: '',
      sn:'',
      modalOpen: false
    })
  }
  render(){
    /*
      Next we display the data from the logs
      also need to figure out a good way to display the items within the repair
    */
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    const columns = [
      {field: 'Username', value: 'Username'},
      {field: 'Description', value: 'Description'},
      {field: 'logItemString', value: 'Items'},
      {field: 'Log_Date', value: 'Date'},
      {field: 'Other_Info', value: 'Other_Info'},
    ]
    const addReportFields = [
      {Name: 'Fans', PropertyName: 'fans', Value: this.state.fans, Type: 'int', Options: []},
      {Name: 'PSU', PropertyName: 'psu', Value: this.state.psu, Type: 'int', Options: []},
      {Name: 'Cables', PropertyName: 'cables', Value: this.state.cables, Type: 'int', Options: []},
      {Name: 'Hashboards', PropertyName: 'hashboards', Value: this.state.hashboards, Type: 'int', Options: []},
      {Name: 'Controlboards', PropertyName: 'controlboards', Value: this.state.controlboards, Type: 'int', Options: []},
      {Name: 'Serial Number*', PropertyName: 'sn', Value: this.state.sn, Type: 'text', Options: []},
      {Name: 'Description', PropertyName: 'description', Value: this.state.description, Type: 'textarea', Options: []},
      {Name: 'Other Info', PropertyName: 'other_info', Value: this.state.other_info, Type: 'textarea', Options: []},

    ]
    return(
      <div className="MinerView-container">
        <TabHeader
          logo={
            <img className="tab-view-image" src={require("../../images/MinerDetailsIcon.png")}/>
          }
          title="Miner Details"
          subtitle="Detailed miner info, user comments, repairs & more"
          tabs={minerView}
          active="Maintenance"
          onClick={this.onClick}
        />
        <div className="MinerView-content">
          <ViewBody
            data={this.props.maintenanceLog.logs}
            columns={columns}
            list
            createtext="New report"
            onCreate={e => this.setState({modalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add repair"
            fields={addReportFields}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAdd}
            onInputChange={e => onInputChange(e, this)}
            action="add_repair"
          />
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  maintenanceLogPage: props.pageReducer.maintenenceListByMAC,
  maintenanceLog: props.maintenanceReducer,
  minerStore: props.minerReducer
})

export default connect(mapStateToProps, {
  getMaintenanceBySNAction,
  clearMaintenanceAction,
  postMaintenanceAction,
  getMinerAction
})(MinerMaintenanceView)

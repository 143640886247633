import React from 'react';
import Rangeslider from '../Rangeslider';
import './style.css'
import UserLogListItem from '../UserLogListItem';
import BootstrapPaging from '../BootstrapPaging'
const UserLogList = ({items, onClick, currentPage, maxPage, onPageClick, orderBy, acending, onSort, pageSize, changePageSize }) => {
  return(
    <div className="logContainer">
      <div className="tbl-header">
        <table className="uLogTable table-bordered" cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th className="uLogHeader" onClick={e => onSort("Time_Stamp")}>{ orderBy === "Time_Stamp"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Timestamp</th>
              <th className="uLogHeader" onClick={e => onSort("Username")}>{ orderBy === "Username"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} User</th>
              <th className="uLogHeader" onClick={e => onSort("Command")}>{ orderBy === "Command"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Command</th>
              <th className="uLogHeader" onClick={e => onSort("Amount")}>{ orderBy === "Amount"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Amount</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="tbl-content">
        <table className="uLogTable" cellPadding="0" cellSpacing="0" border="0">
          <tbody>
          {items.map((item, i) => {
            return <UserLogListItem
                      key={i}
                      amount={item.Amount}
                      timestamp={item.Time_Stamp}
                      command={item.Command}
                      user={item.Username}
                      onClick={onClick}
                    />
          })}
          </tbody>
        </table>
      </div>
      <BootstrapPaging
        currentPage={currentPage}
        maxPage={maxPage}
        onPageClick={onPageClick}
        pageSize={pageSize}
        changePageSize={changePageSize}
      />

    </div>
  )
}
export default UserLogList;

/*
<tfoot>
  <tr>
    <th colspan="4">
      <Rangeslider
        currentPage={currentPage}
        maxPage={maxPage}
        onClick={onPageClick}
      />
    </th>
  </tr>
</tfoot>
*/

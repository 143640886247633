import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter, Switch, Redirect, withRouter } from 'react-router-dom'
import Highcharts from 'highcharts/highstock';
import AdminView from '../Views/AdminView'
import ClientView from '../Views/ClientView';
import DashboardView from '../Views/DashboardView';
import LoginView from '../Views/LoginView';
import LogView from '../Views/LogView';
import MapView from '../Views/MapView';
import MinerView from '../Views/MinerView';
import ConfigureView from '../Views/ConfigureView';
import ChangePasswordView from '../Views/ChangePasswordView';
import MaintenanceView from '../Views/MaintenanceView'
import FilteredView from '../Views/FilteredView'
import UserlogView from '../Views/UserlogView'
import ListView from '../Views/ListView'
import NotFoundView from '../Views/NotFoundView'
import HeatmapView from '../Views/HeatmapView'
import BuildingsView from '../Views/BuildingsView'
import PodsView from '../Views/PodsView'
import PoolsView from '../Views/PoolsView'
import ProfilesView from '../Views/ProfilesView'
import MinerTypesView from '../Views/MinerTypesView'
import ProfileDetailView from '../Views/ProfileDetailView'
import AlertsView from '../Views/AlertsView'
import BuildingDetailsView from '../Views/BuildingDetailsView'
import MinerInfoView from '../Views/MinerInfoView'
import MinerCommentView from '../Views/MinerCommentView'
import MinerErrorLogView from '../Views/MinerErrorLogView'
import MinerMaintenanceView from '../Views/MinerMaintenanceView'
import MinerMapView from '../Views/MinerMapView'
import MinerSystemLogView from '../Views/MinerSystemLogView'
import MaintenanceLogsView from '../Views/MaintenanceLogsView'
import InventoryView from '../Views/InventoryView'
import InventoryLogsView from '../Views/InventoryLogsView'
import UsersView from '../Views/UsersView'
import UsersDetailsView from '../Views/UsersDetailsView'
import DatabasesView from '../Views/DatabasesView'
import DatabasesDetailsView from '../Views/DatabasesDetailsView'
import ScannersView from '../Views/ScannersView'
import ScannersDetailsView from '../Views/ScannersDetailsView'
import AdminMinerTypesView from '../Views/AdminMinerTypesView'
import AdminMinerTypesDetailsView from '../Views/AdminMinerTypesDetailsView'
import ReportView from '../Views/ReportsView'
import CustomerScannersView from '../Views/CustomerScannersView'
import RegionView from '../Views/RegionView'
import MappingConfigureView from '../Views/MappingConfigureView'
import RackProfileView from '../Views/RackProfileView'
import PodDetailsView from '../Views/PodDetailsView'
import FlagView  from '../Views/FlagView'
import MinerFlagView from '../Views/MinerFlagView'
import MinerFlagDetailView from '../Views/MinerFlagDetailView'
import MinerActivityView from '../Views/MinerActivityView'
import MinerTypesDetailsView from '../Views/MinerTypesDetailsView'
import FlagConfigView from '../Views/FlagConfigView'
import FlagConfigDetailView from '../Views/FlagConfigDetailView'
import PowerCutOrdersView from '../Views/PowerCutOrdersView'
import PowerCutOrderDetailView from '../Views/PowerCutOrderDetailView';
import TuningProfileView from '../Views/TuningProfileView'
import TuningProfileDetailView from '../Views/TuningProfileDetailView'
import TuningView from '../Views/TuningView';

const AdminSwitch = () => {
  return(
    <Switch>
      <Route exact path="/client/:name/region/:region_id/miner/:miner/info" component={MinerInfoView} />
      <Route exact path="/client/:name/region/:region_id/miner/:miner/comment" component={MinerCommentView} />
      <Route exact path="/client/:name/region/:region_id/miner/:miner/errorlog" component={MinerErrorLogView} />
      <Route exact path="/client/:name/region/:region_id/miner/:miner/maintenance" component={MinerMaintenanceView} />
      <Route exact path="/client/:name/region/:region_id/miner/:miner/map" component={MinerMapView} />
      <Route exact path="/client/:name/region/:region_id/miner/:miner/systemlog" component={MinerSystemLogView} />
      <Route exact path="/client/:name/region/:region_id/miner/:miner/flag" component={MinerFlagView} />
      <Route exact path="/client/:name/region/:region_id/miner/:miner/flag/:flag_id" component={MinerFlagDetailView} />
      <Route exact path="/client/:name/region/:region_id/miner/:miner/activity" component={MinerActivityView} />
      <Route exact path="/client/:name/miner/:miner" component={MinerView} />
      <Route exact path="/client/:name/region/:region_id/map" component={MapView} />
      <Route exact path="/client/:name/log" component={LogView} />
      <Route exact path="/client/:name/region/:region_id/userLog" component={UserlogView} />
      <Route exact path="/client/:name/region/:region_id/miners/:type" component={FilteredView} />
      <Route exact path="/client/:name/region/:region_id/flags" component={FlagView} />
      <Route exact path="/client/:name/region/:region_id/flagsetting" component={FlagConfigView} />
      <Route exact path="/client/:name/region/:region_id/flagsetting/:flag_id" component={FlagConfigDetailView} />
      <Route exact path="/client/:name/miners/:type" component={FilteredView} />
      <Route exact path="/client/:name/region/:region_id/miners" component={ListView} />
      <Route exact path="/client/:name/miners" component={ListView} />
      <Route exact path="/client/:name/region/:region_id/heatmaps" component={HeatmapView} />
      <Route exact path="/client/:name/configure" component={ConfigureView} />
      <Route exact path="/client/:name/region/:region_id/buildings" component={BuildingsView} />
      <Route exact path="/client/:name/region/:region_id/buildings/:id" component={BuildingDetailsView} />
      <Route exact path="/client/:name/region/:region_id/pods" component={PodsView} />
      <Route exact path="/client/:name/region/:region_id/pods/:id" component={PodDetailsView} />
      <Route exact path="/client/:name/region/:region_id/scanners" component={CustomerScannersView} />
      <Route exact path="/client/:name/region/:region_id/pools" component={PoolsView} />
      <Route exact path="/client/:name/region/:region_id/profiles" component={ProfilesView} />
      <Route exact path="/client/:name/region/:region_id/profiles/:id" component={ProfileDetailView} />
      <Route exact path="/client/:name/region/:region_id/tuningprofiles" component={TuningProfileView} />
      <Route exact path="/client/:name/region/:region_id/tuningprofiles/:id" component={TuningProfileDetailView} />
      <Route exact path="/client/:name/region/:region_id/mapping" component={MappingConfigureView} />
      <Route exact path="/client/:name/region/:region_id/rackprofile/:id" component={RackProfileView} />
      <Route exact path="/client/:name/region/:region_id/minertypes" component={MinerTypesView} />
      <Route exact path="/client/:name/region/:region_id/minertypes/:minertype_id" component={MinerTypesDetailsView} />
      <Route exact path="/client/:name/region/:region_id/alerts" component={AlertsView} />
      <Route exact path="/client/:name/region/:region_id/maintenance" component={MaintenanceView} />
      <Route exact path="/client/:name/region/:region_id/maintenancelogs" component={MaintenanceLogsView} />
      <Route exact path="/client/:name/region/:region_id/inventory" component={InventoryView} />
      <Route exact path="/client/:name/region/:region_id/inventorylogs" component={InventoryLogsView} />
      <Route exact path="/client/:name/region/:region_id" component={ClientView} />
      <Route exact path="/client/:name" component={ClientView} />
      <Route exact path="/changepassword" component={ChangePasswordView} />
      <Route exact path="/admin/powercut/:powerOrderId" component={PowerCutOrderDetailView} />
      <Route exact path="/admin/powercut" component={PowerCutOrdersView} />
      <Route exact path="/admin/reports" component={ReportView} />
      <Route exact path="/admin/minertypes/:id" component={AdminMinerTypesDetailsView} />
      <Route exact path="/admin/minertypes" component={AdminMinerTypesView} />
      <Route exact path="/admin/scanners/:ip" component={ScannersDetailsView} />
      <Route exact path="/admin/scanners" component={ScannersView} />
      <Route exact path="/admin/databases/:id/region/:region_id" component={RegionView} />
      <Route exact path="/admin/databases/:id" component={DatabasesDetailsView} />
      <Route exact path="/admin/databases" component={DatabasesView} />
      <Route exact path="/admin/users/:id" component={UsersDetailsView} />
      <Route exact path="/admin/users" component={UsersView} />
      <Route exact path="/admin/tuning" component={TuningView} />
      <Route exact path="/admin" component={AdminView} />
      <Route exact path="/" component={DashboardView} />
      <Route render={(props) => (
        <NotFoundView
          noAccess={['']}
        />
      )} />
    </Switch>
  )
}

export default AdminSwitch

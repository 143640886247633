import { patchScannerConstant } from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const patchScannerAction = (token, ip, body) => {
  return async(dispatch) => {
    const result = await service.patchScanner(token, ip, body)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: patchScannerConstant,
  payload: result
})

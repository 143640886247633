import React from 'react'
import socketio from "socket.io-client";
import { setSuccessMessageAction } from '../redux/actions/setSuccessMessageAction'
import { useDispatch } from 'react-redux';
import { setSuccessMessage } from '../redux/constants/actions';
//import { SOCKET_URL } from "";

//export const socket = socketio.connect("http://localhost:55556");
export let socket_global = null
export const SocketContext = React.createContext();
export const getSocket = (uri, token) => {
  return socketio.connect(uri, {query: { token }});
}
export const setSocket = (uri, token) => {
  socket_global = socketio.connect(uri, {
    query: { token },
    path: process.env.REACT_APP_SIO_PATH,
    reconnection: true,
    reconnectionDelay: 60000,
  });
}

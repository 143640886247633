import clientService from '../services/clientService';
import { deletePodConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';

export const deletePodAction = (token, client, id, region_id) => {
  return async(dispatch) => {
    const result = await clientService.deletePod(token, client, id, region_id);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }

    dispatch(deletePodSuccess(result));
  }
}

const deletePodSuccess = (pods) => ({
  type: deletePodConstant,
  payload: pods
})

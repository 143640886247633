import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { minerView } from '../../helper/tabs'

import './style.css'

class MinerMapView extends React.Component {
  constructor(props){
    super(props)
  }
  componentDidMount = () => {

  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  render(){
    return(
      <div className="MinerView-container">
        <TabHeader
          logo={
            <i class="fa fa-bitcoin fa-5x" ></i>
          }
          title="Miner Details"
          subtitle="Detailed miner info, user comments, repairs & more"
          tabs={minerView}
          active="Map"
          onClick={this.onClick}
        />
      </div>
    )
  }
}

export default MinerMapView

import React from 'react';
import './style.css'

const CommandPanel = ({onClick, addManyToFav, deleteManyFromFav, onChangeTypeClick}) => {
  return(
    <div>
      <div className='command-div'>
        <div className='page-item'>
          <button className='' onClick={(e) => onClick("update")}>
          update
          </button>
        </div>
        <div className='page-item'>
          <button className='' onClick={(e) => onClick("restart")}>
            restart
          </button></div>
        <div className='page-item'>
          <button className='' onClick={(e) => onClick("config")}>
            Configure
          </button>
        </div>
        <div className='page-item'>
          <button className='' onClick={(e) => onClick("delete")}>
            Delete
          </button>
        </div>
        <div className='page-item'>
          <button className='' onClick={(e) => addManyToFav()}>
            Add to Favorite
          </button>
        </div>
        <div className='page-item'>
          <button className='' onClick={(e) => deleteManyFromFav()}>
            Remove from favorite
          </button>
          </div>
        <div className='page-item'>
          <button className='' onClick={(e) => onChangeTypeClick()}>
            Set Miner Type
          </button>
        </div>
      </div>
    </div>
  )
}

export default CommandPanel;
/*
<nav className="panelDiv">
  <ul className='pagination'>
    <li className='page-item'><a className='page-link' onClick={(e) => onClick("update")}>update</a></li>
    <li className='page-item'><a className='page-link' onClick={(e) => onClick("restart")}>restart</a></li>
    <li className='page-item'><a className='page-link' onClick={(e) => onClick("config")}>Configure</a></li>
    <li className='page-item'><a className='page-link' onClick={(e) => onClick("delete")}>Delete</a></li>
    <li className='page-item'><a className='page-link' onClick={(e) => addManyToFav()}>Add to Favorite</a></li>
    <li className='page-item'><a className='page-link' onClick={(e) => deleteManyFromFav()}>Remove from favorite</a></li>
    <li className='page-item'><a className='page-link' onClick={(e) => onChangeTypeClick()}>Set Miner Type</a></li>
  </ul>
</nav>
*/

import React from 'react';
import Rangeslider from '../Rangeslider';
import BootstrapPaging from '../BootstrapPaging'
import MaintenanceLogItem from '../MaintenanceLogItem'
const MaintenanceLogs = ({items,  currentPage, maxPage, onPageClick, orderBy, acending, onSort, pageSize, changePageSize}) => {
  return(
    <div className="logContainer">
      <div className="tbl-header">
        <table className="uLogTable table-bordered" cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th className="uLogHeader" onClick={e => onSort("Location")}>{ orderBy === "Location"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Location</th>
              <th className="uLogHeader" onClick={e => onSort("User")}>{ orderBy === "User"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} User</th>
              <th className="uLogHeader" onClick={e => onSort("Log_Date")}>{ orderBy === "Log_Date"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Date</th>
              <th className="uLogHeader">Description</th>
              <th className="uLogHeader"> Other Info</th>
              <th className="uLogHeader">Items</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="tbl-content">
        <table className="uLogTable" cellPadding="0" cellSpacing="0" border="0">
          <tbody>
          {items.map((item, i) => {
            return <MaintenanceLogItem
                      key={i}
                      house={item.House_Name}
                      pod={item.Pod_Name}
                      timestamp={item.Log_Date}
                      otherInfo={item.Other_Info}
                      user={item.Username}
                      location={item.Location}
                      description={item.Description}
                      items={item.logItems}
                    />
          })}
          </tbody>
        </table>
      </div>
      <BootstrapPaging
        currentPage={currentPage}
        maxPage={maxPage}
        onPageClick={onPageClick}
        pageSize={pageSize}
        changePageSize={changePageSize}
      />

    </div>
  )
}

export default MaintenanceLogs

import React from 'react'
import { getMinerLogsAction } from '../../redux/actions/getMinerLogsAction'
import { connect } from 'react-redux'
import MinerLogList from '../../Components/MinerLogList'
import './style.css';
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';

class LogView extends React.Component {
    componentDidMount = async () => {
        const element = document.getElementById("logLink");
        if(element){
          element.classList.add("itemSelected")
        }
        const token = this.props.userStore.result;
        if(this.props.match.params.name !== this.props.store.client){
          const store = {
            pageSize: 25,
            pageNumber: 1,
            client: this.props.match.params.name,
            type: 'minerLogs',
            orderBy: "Time_Stamp",
            acending: true
          }
          this.props.updatePageInfoAction(store)
        }
        // getMinerLogsAction(token, client, pageSize, pageNumber)
        // let the user pick pageSize and pageNumber
        await this.props.getMinerLogsAction(token, this.props.match.params.name, this.props.store.pageSize, this.props.store.pageNumber , this.props.store.orderBy, this.props.store.acending)
    }
    componentWillUnmount() {
      const element = document.getElementById("logLink");
      if(element){
        element.classList.remove("itemSelected");
      }
    }
    //Function used to route us to the minersDetails site when it was clicked from the table.
    onClick = async (link) => {
        this.props.history.push(link)
    }
    //Sorts the table according to what column was selected.
    onSort = (type) => {
        let { acending, orderBy} = this.props.store;
        if (orderBy === type) {
          acending = !acending;
        }
        else {
          acending = true;
        }
        this.props.getMinerLogsAction(this.props.userStore.result, this.props.match.params.name, this.props.store.pageSize, this.props.store.pageNumber, type, acending);
        const store = {
          pageSize: this.props.store.pageSize,
          pageNumber: this.props.store.pageNumber,
          client: this.props.match.params.name,
          type: this.props.store.type,
          orderBy: type,
          acending: acending
        }
        this.props.updatePageInfoAction(store)
      }
    //Used to fetch more logs to render in the next page of the table.
    onPageClick = (pageNumber) => {
        const { orderBy, acending } = this.props.store;
        if(pageNumber !== 0 && pageNumber <= this.props.minerLogsStore.max_page){
            this.props.getMinerLogsAction(this.props.userStore.result, this.props.match.params.name, this.props.store.pageSize, pageNumber, orderBy, acending);
        }
        const store = {
          pageSize: this.props.store.pageSize,
          pageNumber: pageNumber,
          client: this.props.match.params.name,
          type: this.props.store.type,
          orderBy: this.props.store.orderBy,
          acending: acending
        }
        this.props.updatePageInfoAction(store)
    }
    //Changes the size of each page in the table.
    changePageSize = (e) => {
        const { orderBy, acending } = this.props.store;
        const value = parseInt(e.target.value)
        let pageNum = this.props.store.pageNumber;
        const items = parseInt(this.props.minerLogsStore.max_page)*parseInt(this.props.minerLogsStore.page_size)
        if(value > items){
          pageNum = 1;
        }
        if((this.props.store.pageNumber*value) > items){
          pageNum = 1;
        }
        this.props.getMinerLogsAction(this.props.userStore.result, this.props.match.params.name, value, pageNum, orderBy, acending);
        const store = {
          pageSize: value? value: 0,
          pageNumber: pageNum,
          client: this.props.match.params.name,
          type: this.props.store.type,
          orderBy: this.props.store.orderBy,
          acending: acending
        }
        this.props.updatePageInfoAction(store)
    }
    render() {
        return (
            <div className="minerLogViewDisplay">
                <MinerLogList
                    onPageClick={this.onPageClick}
                    client={this.props.match.params.name}
                    items={this.props.minerLogsStore.minersLogs}
                    currentPage={this.props.minerLogsStore.current_page}
                    maxPage={this.props.minerLogsStore.max_page}
                    onClick={this.onClick}
                    orderBy={this.props.store.orderBy}
                    acending={this.props.store.acending}
                    onSort={this.onSort}
                    pageSize={this.props.store.pageSize}
                    changePageSize={this.changePageSize}
                />
            </div>
        )
    }
}
const mapStateTopProps = (props) => ({
  userStore: props.userReducer,
  minerLogsStore: props.minerLogsReducer,
  store: props.pageReducer.minerLogs
})
export default connect(mapStateTopProps, { getMinerLogsAction, updatePageInfoAction })(LogView)

import React from 'react';
import './style.css'
import PropTypes from 'prop-types';
const ConfigurePoolForm = ({url, workerName, password, onInputChange, onFormSubmit}) => {
  return(
    <div className="form-style-5">
      <form>
      <fieldset>
        <legend><span className="number">4</span>Pool Info</legend>
            <input type="text" id="poolURL" name="poolURL" defaultValue={url} placeholder="Pool URL *" onInput={e => onInputChange(e)}/>
            <input type="text" id="workerName" name="workerName" placeholder="Worker Name *" defaultValue={workerName} onInput={e => onInputChange(e)}/>
            <input type="text" id="password" name="password" defaultValue={password} placeholder="Password *" onInput={e => onInputChange(e)}/>
        </fieldset>
          <input type="button" id="password" defaultValue="submit" onClick={e => onFormSubmit(e)}/>
      </form>
    </div>
  )
}

ConfigurePoolForm.propTypes = {
  url:PropTypes.string.isRequired,
  workerName:PropTypes.string.isRequired,
  password:PropTypes.string.isRequired,
  onInputChange:PropTypes.func.isRequired,
  onFormSubmit:PropTypes.func.isRequired,
}

export default ConfigurePoolForm;
/*

  <form action="" className="add-froms form from-horizontal">
    <div className="form-group">
        <label htmlFor="poolURL">URL</label>
        <input type="text" id="poolURL" name="poolURL" value={url} className="form-control" onInput={e => onInputChange(e)}/>
    </div>
    <div className="from-group">
        <label htmlFor="workerName">Worker Name</label>
        <input type="text" id="workerName" name="workerName" value={workerName} className="form-control" onInput={e => onInputChange(e)}/>
    </div>
    <div className="from-group">
        <label htmlFor="password">Password</label>
        <input type="text" id="password" name="password" value={password} className="form-control" onInput={e => onInputChange(e)}/>
    </div>
    <div className="form-group">
        <input type="button" id="password" value="submit" className="btn btn-submit" onClick={e => onFormSubmit(e)}/>
    </div>
  </form>
  */

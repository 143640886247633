import { putAlertSettingsConstant } from '../constants/actions';
import service from '../services/alertService';
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'

export const putAlertSettingsAction = (token, body, customer) => {
  return async(dispatch) =>{
    const result = await service.putAlertSettings(token, body, customer)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    else{
      dispatch(setSuccessMessageAction("Successfully change a resource"))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: putAlertSettingsConstant,
  payload: result
})

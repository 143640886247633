import React from 'react';
import './styles.css';
import ListPageSizes from '../ListPageSizes'
const Pager = ({pageSize, currentPage, maxPage, onPageClick, currentPageSize, onPageSizeChange, middleText, displayPageSize, dark=false}) => {
  return(
    <div className="pager-outer-container">
      <div className="pager-container">
        <div className={dark?('pager-item-pages-dark'):('pager-item-pages')}>
          Pages
        </div>
        {
          parseInt(currentPage)-1 > 1?(
          <div className="pager-item not-selected" onClick={e => onPageClick(1)}>
            First
          </div>
          ):(<></>)
        }
        {
          parseInt(currentPage) !==1?(
            <div className="pager-item not-selected" onClick={e => onPageClick(parseInt(currentPage)-1)}>
              {parseInt(currentPage)-1}
            </div>
          ):(<></>)
        }
        <div className={dark?("pager-item-dark"):("pager-item")} onClick={e => onPageClick(currentPage)}>
          {currentPage}
        </div>
        {
          currentPage < maxPage?(
            <div className="pager-item not-selected" onClick={e => onPageClick(parseInt(currentPage)+1)}>
              {parseInt(currentPage)+1}
            </div>
          ):(<></>)
        }
        {
          parseInt(currentPage)+1 < maxPage?(
            <div className="pager-item not-selected" onClick={e => onPageClick(parseInt(maxPage))}>
              Last
            </div>
          ):(<></>)
        }
      </div>
      {middleText?(
        <h5 className="pager-middle-text">{middleText}</h5>
      ):(<></>)}
      {displayPageSize?(
        <ListPageSizes
          currentPageSize={currentPageSize}
          onPageSizeChange={onPageSizeChange}
          pageSize={pageSize}
          dark={dark}
        />
      ):(<></>)}
    </div>
  )
}

export default Pager

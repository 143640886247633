import { getInventoryConstant } from '../constants/actions'
import service from '../services/inventoryService'
import { setErrorMessageAction } from './setErrorMessageAction';
export const getInventoryAction = (token, customer, region_id) => {
  return async(dispatch) => {
    try {
      const result = await service.getInventory(token, customer, region_id);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(success(result))
    } catch (e) {
        console.log(e)
    }
  }
}


const success = (result) => ({
  type: getInventoryConstant,
  payload: result
})

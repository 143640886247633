import React from 'react'
import './style.css'
import "bootstrap/dist/css/bootstrap.min.css";
import { items_to_display } from './functions'


const DashMinersTable = ({ title, miners, onClick, onClickString, onMacClick }) => {
  return(
    <div className="dashminers-container">
      <strong>{title} : {miners.length}</strong>
      <i className="external-link"/>
      <table className="list-table">
        <thead>
          <tr>
            <th scope="col" className="sort">#</th>
            <th className="sort">Location</th>
            <th className="sort">Down Time</th>
          </tr>
        </thead>
        <tbody>
          {items_to_display(miners, onMacClick)}
        </tbody>
        <tfoot className="dashminers-onClick" onClick={ e => onClick(onClickString)}>
          <tr>
            <th colSpan="4">
              <strong>
                Open List <i className="fas fa-external-link-alt" aria-hidden="true"/>
              </strong>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default DashMinersTable;

import { createBrowserHistory } from 'history';
import { store } from './redux/store'
import { pushHistoryAction } from './redux/actions/pushHistoryAction'
import { popHistoryAction } from './redux/actions/popHistoryAction'
import { replaceHistoryAction } from './redux/actions/replaceHistoryAction'
const browserHistory = () => {
  let history = createBrowserHistory();
  history.listen((location, action) => {
    if(action == "REPLACE"){
      //use this for the location navigation
      if(location.pathname != "/login"){
        store.dispatch(pushHistoryAction(location))
      }
    }
    if(action == "PUSH"){
      //use this for the location navigation
      store.dispatch(pushHistoryAction(location))
    }
    if(action == "POP"){
      if(location.hasOwnProperty("key")){
        store.dispatch(popHistoryAction())
      }
    }
  })
  return history;
}


export default browserHistory()

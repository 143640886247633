import {
  patchScannerConstant,
  getScannerInfoConstant,
} from '../constants/actions'
import moment from 'moment'

const defaultState = {
  ok: false,
  api_version: 1.1,
  Name: '',
  Scan_Range: '',
  Scan_IP: '',
  Database_Name: '',
  Threads: '',
  Threads_Threshold: '',
  Scan_Time_Target: '',
  Last_Scan: '',
  Scan_Subnet: '',
  Scanner_Agent_Status: '',
  Scanner_Agent_Version: '',
  Meraki_Network: '',
  Meraki_Vlan: '',
  Region_Name: '',
  Id_No: ''
}


export default(state=defaultState, action) => {
  switch (action.type) {
    case patchScannerConstant:
    case getScannerInfoConstant:
      if(action.payload.ok && action.payload.data.length > 0){
        let item = action.payload.data[0]
        if(item.Last_Scan){
          item.Last_Scan = moment(item.Last_Scan).format('YYYY-MM-DD HH:mm:ss');
        }
        else{
          item.Last_Scan = 'Never scanned'
        }
        return{
          ok: true,
          api_version: action.payload.api_version,
          Name: item.Name,
          Scan_Range: item.Scan_Range,
          Scan_IP: item.Scan_IP,
          Database_Name: item.Database_Name,
          Threads: item.Threads,
          Threads_Threshold: item.Threads_Threshold,
          Scan_Time_Target: item.Scan_Time_Target,
          Last_Scan: item.Last_Scan,
          Scan_Subnet: item.Scan_Subnet,
          Scanner_Agent_Status: item.Scanner_Agent_Status,
          Scanner_Agent_Version: item.Scanner_Agent_Version,
          Meraki_Network: item.Meraki_Network,
          Meraki_Vlan: item.Meraki_Vlan,
          Region_Name: item.Region_Name,
          Id_No: item.Id_No
        }
      }
      return state
      break;
    default:
      return state
  }
}

import { deleteUserConstant } from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'


export const deleteUserAction = (token, username) => {
  return async(dispatch) => {
    const result = await service.deleteUser(token, username)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    else{
      dispatch(setSuccessMessageAction(result.msg))
    }
    dispatch(success(result, username))
  }
}


const success = (result, username) => ({
  type: deleteUserConstant,
  payload: {result, username}
})

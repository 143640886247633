import React from 'react'
import './style.css'

const FlagTypeContainer = ({ columns, item, onClick, title, dateType }) => {
  return(
    <div className="flag_overview_item_container" onClick={e => onClick(title, item['Name'].toLowerCase(), dateType)}>
      {columns.map((i) =>(
        <span className="flag_overview_item">{item[i['field']]}</span>
      ))}
    </div>
  )
}


const FlagOverviewContainer = ({ title, list, columns, onClick, dateType, titleClass}) => {
  return(
    <div className="flag_overview_container">
      <div className="flag_overview_item_header">
        <span className={`flag_overview_title ${titleClass}`} onClick={e => onClick(title, '', dateType)}>{title}</span>
      </div>
      {list.map((item) =>(
        <FlagTypeContainer
          columns={columns}
          item={item}
          onClick={onClick}
          title={title}
          dateType={dateType}
        />
      ))}
    </div>
  )
}


export default FlagOverviewContainer

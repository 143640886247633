import {
  getHousesConstant,
  postNewHouseConstant,
  deleteHouseConstant,
  clearHousesConstant
} from '../constants/actions'
const defaultState = {
  ok: true,
  msg: '',
  api_version: 1.1,
  houses: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case clearHousesConstant:
      return{
        ok: true,
        msg: '',
        houses: []
      }
    case getHousesConstant:
        if(action.payload.ok){
          return {
            ok: true,
            msg: '',
            api_version: action.payload.api_version,
            houses: action.payload.houses
          }
        }
        return state
    case postNewHouseConstant:
      if(!action.payload.ok){
        const s1 = JSON.parse(JSON.stringify(state));
        s1.msg = action.payload.msg;
        s1.ok = action.payload.ok;
        return s1;
      }
      else{
        const newState = {
          houses: action.payload.houses,
          msg: action.payload.msg,
          ok: action.payload.ok
        }
        return newState
      }
      case deleteHouseConstant:
          if(action.payload.ok){
            return action.payload
          }
          else{
            const s2 = {
              ok: false,
              houses: state.houses,
              msg: action.payload.msg
            }
            return s2;
          }
    default:
      return state;
  }
}

import { getAllMinersConstant,  setId_getAllMinersConstant} from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/minerService'

export const getAllMinersAction = (token, client, pageSize, pageNumber, orderBy, acending, filterString, region_id) => {
  return async (dispatch) => {
    const actionId = Math.random() * (100000000 - 1) + 1;
    dispatch({type: setId_getAllMinersConstant, payload: actionId})
    const result = await service.getAllMiners(token, client, pageSize, pageNumber, orderBy, acending, filterString, region_id)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result, actionId))
  }
}
const success = (result, actionId) => ({
  type: getAllMinersConstant,
  payload: result,
  actionId
})

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import PickFlagCheckOptionModal from '../../Components/PickFlagCheckOptionModal'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { getMinerFlagRecoveryLogByFlagIDAction } from '../../redux/actions/getMinerFlagRecoveryLogByFlagIDAction'
import { getMinerFlagActivityLogByFlagIDAction } from '../../redux/actions/getMinerFlagActivityLogByFlagIDAction'
import { getMinerFlagCheckOptionsAction } from '../../redux/actions/getMinerFlagCheckOptionsAction'
import { postSingleMinerFlagCheckAction } from '../../redux/actions/postSingleMinerFlagCheckAction'
import { getMinerFlagByIDAction } from '../../redux/actions/getMinerFlagByIDAction'
import { getFlagCommentsByIdAction } from '../../redux/actions/getFlagCommentsByIdAction'
import { postFlagCommentAction } from '../../redux/actions/postFlagCommentAction'
import { minerView } from '../../helper/tabs'
import moment from 'moment'

import './style.css'

class MinerFlagDetailView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      flagOptionModalOpen: false,
      flagCommentModalOpen: false,
      flagOptionSelected: -1,
      customFlagComment: ''
    }
  }
  componentDidMount = () => {
    this.props.getMinerFlagRecoveryLogByFlagIDAction(this.props.match.params.flag_id, this.props.token, this.props.match.params.name)
    this.props.getMinerFlagActivityLogByFlagIDAction(this.props.match.params.flag_id, this.props.token, this.props.match.params.name)
    this.props.getMinerFlagCheckOptionsAction(this.props.token, this.props.match.params.name)
    this.props.getMinerFlagByIDAction(this.props.match.params.flag_id, this.props.token, this.props.match.params.name)
    this.props.getFlagCommentsByIdAction(this.props.match.params.flag_id, this.props.token, this.props.match.params.name)
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history, `/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/miner/${this.props.match.params.miner}/`)
  }
  onBack = () => {
    this.props.history.goBack()
  }
  openCheckModal = () => {
    this.setState({
      flagOptionModalOpen: true
    })
  }
  onFlagOptionSelect = (e) => {
    console.log(e.value)
    this.setState({
      flagOptionSelected: e.value
    })
  }
  onFlagCheckSubmit = () => {
    const { flagOptionSelected } = this.state
    let id = this.props.flagStore.miner_flag_check_options[flagOptionSelected]
    if(id){
      id = id.ID
      const body = {
        Miner_Flag_Check_Option_ID: id
      }
      this.props.postSingleMinerFlagCheckAction(body, this.props.match.params.flag_id, this.props.token, this.props.match.params.name)
      this.setState({
        flagOptionModalOpen: false,
        flagOptionSelected: -1
      })
    }
    else{
      alert("Unexcpected error occurred, can't find flag option by id")
    }
  }
  addFlagComment = () => {
    const { customFlagComment } = this.state
    const body = {
      comment: customFlagComment
    }
    this.props.postFlagCommentAction(this.props.match.params.flag_id, body, this.props.token, this.props.match.params.name)
    this.setState({
      customFlagComment: '',
      flagCommentModalOpen: false
    })
  }
  render(){
    this.props.flagStore.flags_recovery_log.map((item) => item.Date = moment(item.Date).format('YYYY-MM-DD hh:mm:ss'))
    this.props.flagStore.flags_activity_log.map((item) => item.Date = moment(item.Date).format('YYYY-MM-DD hh:mm:ss'))
    this.props.flagStore.flag_comments.map((item) => item.Date = moment(item.Date).format('YYYY-MM-DD hh:mm:ss'))
    const modalStyle = {
       modal: {
         width: '400px',
         background: '#141619'
       }
     };
    const pannelColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Value', value: 'Value'}
    ]
    const recoveryColumns = [
      {field: 'Miner_Flag_ID', value: 'ID'},
      {field: 'Date', value: 'Date'},
      {field: 'Result', value: 'Recovery Result'}
    ]
    const minerLogsColumns = [
        {field: 'Date', value: 'Date'},
        {field: 'Status', value: 'Flag Status'},
        {field: 'Miner_Flag_Check_Option_Type', value: 'Check Option'},
        {field: 'Miner_Flag_Check_Option_Amount', value: 'Check Amount'},
    ]
    const commentsColumns = [
      {field: 'ID', value: 'ID'},
      {field: 'Username', value: 'Username'},
      {field: 'Comment', value: 'Comment'},
      {field: 'Date', value: 'Date'},
    ]
    let pannelData = this.props.flagStore.flags.length > 0?[
        {Name: "Name", Value: this.props.flagStore.flags[0].Flag_Type_Name},
        {Name: "Status", Value: this.props.flagStore.flags[0].Status},
        {Name: "Activity Log", Value: this.props.flagStore.flags[0].Activity_Logs},
        {Name: "Recovery Logs", Value: this.props.flagStore.flags[0].Recovery_Logs},
    ]: [
          {Name: "Name", Value: 'N/A'},
          {Name: "Status", Value: 'N/A'},
          {Name: "Activity Log", Value: 'N/A'},
          {Name: "Recovery Logs", Value: 'N/A'}]
    const addCommentField = [
      {Name: 'Comment', PropertyName: 'customFlagComment', Value: this.state.customFlagComment, Type: 'textarea', Options: []}
    ]
    return(
      <div className="MinerView-container">
        <TabHeader
          logo={
            <img className="tab-view-image" src={require("../../images/MinerDetailsIcon.png")}/>
          }
          title="Miner Details"
          subtitle="Detailed miner info, user comments, repairs & more"
          tabs={minerView}
          active="Flag"
          onClick={this.onClick}
        />
        <div className="MinerView-content">
          <ViewBody
            data={pannelData}
            onBack={this.onBack}
            columns={pannelColumns}
            pannel
          />
          <ViewBody
            data={this.props.flagStore.flags_activity_log}
            createtext="Checkout Flag"
            columns={minerLogsColumns}
            onCreate={this.openCheckModal}
            buttonDisabled={this.props.flagStore.flags.length > 0?  !['Pending', 'Custom'].includes(this.props.flagStore.flags[0].Status): false}
            title={"Flag Activity Log"}
            list
            buttonDisabledText={"Checkout only enabled on pending"}
          />
          <ViewBody
            data={this.props.flagStore.flags_recovery_log}
            columns={recoveryColumns}
            list
            title={"Flag Recovery Log"}
          />
          <ViewBody
            data={this.props.flagStore.flag_comments}
            columns={commentsColumns}
            list
            title={"Flag Comments"}
            onCreate={e => this.setState({flagCommentModalOpen: true})}
            createtext="Add comment"
          />
          <PickFlagCheckOptionModal
            checkOptions={this.props.flagStore.miner_flag_check_options}
            open={this.state.flagOptionModalOpen}
            indexSelected={this.state.flagOptionSelected}
            onInputChange={this.onFlagOptionSelect}
            onClose={e => this.setState({
              flagOptionModalOpen: false,
              flagOptionSelected: -1
            })}
            onSubmit={this.onFlagCheckSubmit}
          />
          <Modal
            open={this.state.flagCommentModalOpen}
            center
            onClose={() => this.setState({ flagCommentModalOpen: false })}
            styles={modalStyle}
          >
          <FormfieldSection
            title="Flag Comment"
            fields={addCommentField}
            addButton={true}
            buttonText="Create"
            onAddClick={this.addFlagComment}
            onInputChange={e => onInputChange(e, this)}
            action="post_flag"
          />
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  flagStore: props.flagReducer
})

export default connect(mapStateToProps, {
  getMinerFlagRecoveryLogByFlagIDAction,
  getMinerFlagActivityLogByFlagIDAction,
  getMinerFlagCheckOptionsAction,
  postSingleMinerFlagCheckAction,
  getMinerFlagByIDAction,
  getFlagCommentsByIdAction,
  postFlagCommentAction,
})(MinerFlagDetailView)

import { seenMinerConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
import service from '../services/minerService'

export const seenMinerAction = (token, mac, client, type) => {
  return async(dispatch) => {
    const result = await service.seenMiner(token, mac, client);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch({
      type: seenMinerConstant,
      payload: {type,result}
    })
  }
}

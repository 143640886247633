import service from '../services/rackService';
import { deleteRackProfileShelfsConnectionConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const deleteRackProfileShelfsConnectionAction = (token, customer, body) => {
  return async(dispatch) => {
    try{
      const result = await service.deleteRackProfileShelfsConnection(token, customer, body);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(success(result))
    }
    catch(err){
      console.log(err)
    }
  }
}
const success = (result) => ({
  type: deleteRackProfileShelfsConnectionConstant,
  payload: result
})

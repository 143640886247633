import { getMinerTypeInfoByIDConstant  } from '../constants/actions'
import service from '../services/typeService'

export const getMinerTypeInfoByIDAction = (id, token, customer) => {
  return async dispatch => {
    const result = await service.getMinerTypeInfoByID(id, token, customer)
    dispatch(success(result))
  }
}


const success = (result) => ({
  type: getMinerTypeInfoByIDConstant,
  payload: result
})

import React from 'react'
import './style.css'

const FarmInventoryTable = ({ items, newAmount, newItemType, newItem, onInputChange }) => {
  return(
    <div className="farmInvenotryTable-div">
      <table className="table-bordered" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th className="farmInvenotryTable-th">Amount</th>
            <th className="farmInvenotryTable-th">Item Type</th>
            <th className="farmInvenotryTable-th">Item</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((item, key) => (
              <tr>
                <td className="farmInvenotryTable-td">
                  <input type="text" className="custom-formcontrol" name={`Amount-${key}`} value={item.Amount} onInput={e => onInputChange(e, 'update')}/>
                </td>
                <td className="farmInvenotryTable-td">
                  <input type="text" className="custom-formcontrol" name={`Item_Type-${key}`} value={item.Item_Type} onInput={e => onInputChange(e, 'update')}/>
                </td>
                <td className="farmInvenotryTable-td">
                  <input type="text" className="custom-formcontrol" name={`Item-${key}`}  value={item.Item} onInput={e => onInputChange(e, 'update')}/>
                </td>
              </tr>
            ))
          }
        </tbody>
        <tfoot>
          <tr>
            <td className="farmInvenotryTable-td">
              <input type="text" className="custom-formcontrol" name="newAmount" placeHolder="Enter Amount" value={newAmount} onInput={e => onInputChange(e, 'new')}/>
            </td>
            <td className="farmInvenotryTable-td">
              <input type="text" className="custom-formcontrol" name="newItemType" placeHolder="Enter Item Type" value={newItemType} onInput={e => onInputChange(e, 'new')}/>
            </td>
            <td className="farmInvenotryTable-td">
              <input type="text" className="custom-formcontrol" name="newItem" placeHolder="Enter Item" value={newItem} onInput={e => onInputChange(e, 'new')}/>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default FarmInventoryTable

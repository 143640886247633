import {
  rootRoute,
  alertsRoute,
  clearAlertRoute,
  alertRoute,
  ticketRoute
}
from '../constants/routes';

const service = () => {
  const getAlertSettings = (token, customer) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${alertsRoute}/settings/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            settings: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            settings: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getEmailList = (token, customer, region_id) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/emails/${customer}/region/${region_id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            settings: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const deleteFromEmailList = (token, id, customer) => {
    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/emails/${id}/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            settings: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const postToEmailList = (token, body, customer) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/emails/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            settings: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const putAlertSettings = (token, body, customer) => {
    var requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${alertsRoute}/settings/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: data.success.msg,
            settings: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            settings: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getCustomerAlerts = (token, customer, pageSize, pageNumber, orderBy, acending) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    return fetch(`${alertsRoute}/${customer}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            alerts: data.success,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            alerts: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      alerts: []
    }))
  }
  const getAllAlerts = (token, pageSize, pageNumber, orderBy, acending) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    return fetch(`${alertsRoute}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            alerts: data.success,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            alerts: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      alerts: []
    }))
  }
  const deactivateAlert = (token, customer, alertID, body, pageSize, pageNumber, orderBy, acending) => {
    var requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${clearAlertRoute}/${alertID}/${customer}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: data.success.msg,
            alerts: data.success,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            alerts: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      alerts: []
    }))
  }
  const getAlertDetails = (token, customer, alertID) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    return fetch(`${alertsRoute}/${alertID}/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            alertDetails: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            alertDetails: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      alertDetails: []
    }))
  }
  const getAlertByID = (token, customer, alertID) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    return fetch(`${alertRoute}/${alertID}/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            alerts: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            alerts: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      alerts: []
    }))
  }
  const sendTicket = (token, body) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${ticketRoute}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: data.success.msg,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
    }))
  }
  return {
    getAlertSettings,
    putAlertSettings,
    getCustomerAlerts,
    getAllAlerts,
    deactivateAlert,
    getAlertDetails,
    getAlertByID,
    sendTicket,
    getEmailList,
    deleteFromEmailList,
    postToEmailList
  }
}

export default service()

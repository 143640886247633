import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import MicrosoftLogin from "react-microsoft-login";
import './style.css';
const Login = ({ password, username, onInput, onFormSubmit, authHandler}) => {
  return (
    <div className="login-container">
        <span>Welcome To HashMon</span>
        <div className="form-column-login">
          <label className="login-labels">Username</label>
          <input placeholder='Username' type="text" id="usename" name="username" value={username} className="form-column-item-login" onChange={e => onInput(e)} />
        </div>
        <div className="form-column-login">
          <label className="login-labels">Password</label>
          <input placeholder='Password' type="password" id="password" name="password" value={password} className="form-column-item-login" onChange={e => onInput(e)} />
        </div>
        <input type="button" value="Login" className="login-submit" onClick={e => onFormSubmit(e)} />
        <MicrosoftLogin
          clientId={'95f878bd-0ed1-4826-9bef-e5c102fdb60b'}
          buttonTheme={'dark'}
          redirectUri={`${window.location.href}`}
          authCallback={authHandler}
        />
    </div>
  )
}

Login.propTypes = {
  password: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
}
export default Login
//redirectUri={`http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/dashboard`}
//redirectUri={`https://172.17.0.2/login`}
/*
<div>
  <form action="" className="login-form" >
    <div className="form-group">
      <input placeholder='Username' type="text" id="usename" name="username" value={username} className="form-control" onChange={e => onInput(e)} />
    </div>
    <div className="form-group">
      <input placeholder='Password' type="password" id="password" name="password" value={password} className="form-control" onChange={e => onInput(e)} />
    </div>
    <div className="form-group">
      <input type="button" value="submit" className="lf--submit" onClick={e => onFormSubmit(e)} />
    </div>
    <MicrosoftLogin
      clientId={'95f878bd-0ed1-4826-9bef-e5c102fdb60b'}
      buttonTheme={'dark'}
      redirectUri={`${window.location.href}`}
      authCallback={authHandler}
    />
  </form>
</div>
*/

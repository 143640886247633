//import { changePageSize24HoursPlus } from '../constants/actions'

export const changePageSizeAction = (pageSize, type) => {
  return (dispatch) => {
    dispatch(success(pageSize, type))
  }
}
const success = (pageSize, type) => ({
  type: type,
  payload: pageSize
})

import { addToRegionEmailListConstant } from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'



export const addToRegionEmailListAction = (token, database, region_id, body) => {
  return async(dispatch) => {
    try {
      const result = await service.addToRegionEmailList(token, database, region_id, body)
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(success(result))
    }
    catch(err){
      console.log(err)
    }
  }
}


const success = (result) => ({
  type: addToRegionEmailListConstant,
  payload: result
})

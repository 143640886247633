import clientService from '../services/clientService';
import { patchCustomerScannerConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const patchCustomerScannerAction = (token, client, region_id, body, scannerName) => {
  return async (dispatch) => {
    try{
      const result = await clientService.patchCustomerScanner(token, client, region_id, body, scannerName);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(onSuccess(result));
    }
    catch(err){
      console.log(err)
    }
  }
}

const onSuccess = ( subnets ) => ({
  type: patchCustomerScannerConstant,
  payload: subnets
})

import clientService from '../services/clientService';
import { getDatabasesAction } from '../constants/actions';
//import { setSuccessMessageAction } from './setSuccessMessageAction';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getDatabases = (token, initalGet = false) => {
  return async (dispatch) => {
    try{
      const result = await clientService.getDbs(token);
      console.log(result)
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(getDatabasesSuccess(result, initalGet));
    }
    catch(err){
      console.log(err)
    }
  }
}

const getDatabasesSuccess = ( dbs, initalGet) => ({
  type: getDatabasesAction,
  payload: dbs,
  initalGet
})

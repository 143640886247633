import { getHistoryConstant } from '../constants/actions'

export const getHistoryAction = () => {
  return dispatch => {
    dispatch(getHistoySuccess())
  }
}

const getHistoySuccess = () => ({
  type: getHistoryConstant
})

import React from 'react'
import './style.css'
import { NavLink } from 'react-router-dom'
const PanelListItem = ({ title, data, link }) => {
  return(
    <div className="panelListItem-container">
      <table className="panelList-table" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th colSpan="2" className="panelList-table-th">
              <NavLink to={link}>
                {title}
              </NavLink>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, key) => (
            <tr key={key}>
              <td className="panelList-table-td">{item.field}</td>
              <td className={`panelList-table-td ${item.classList? item.classList.join(' '): ''}`}>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PanelListItem

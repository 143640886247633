import {
  getSystemLogsConstant,
  changeSystemLogsPageSizeConstant,
  onSystemLogsPageClickConstant,
  clearSystemLogsConstant
} from '../constants/actions'

const defaultState = {
  status: 0,
  api_version: 1.1,
  data: [],
  displayData: [],
  currentPage: 1,
  pageSize: 25,
  maxPage: ''
}

export default(state = defaultState, action) => {
  switch (action.type) {
    case getSystemLogsConstant:
      const dataReversed = action.payload.data.reverse()
      return {
        status: action.payload.status,
        data: dataReversed,
        pageSize: state.pageSize,
        currentPage: state.currentPage,
        displayData: dataReversed.slice(0, state.pageSize),
        maxPage: Math.ceil(action.payload.data.length/state.pageSize)
      }
      break;
    case changeSystemLogsPageSizeConstant:
      const newPageSize = parseInt(action.payload)
      let pageSizeChangeState = JSON.parse(JSON.stringify(state))
      pageSizeChangeState.pageSize = newPageSize
      pageSizeChangeState.displayData = state.data.slice(0, newPageSize)
      return pageSizeChangeState
    case onSystemLogsPageClickConstant:
      const newPageNumber = parseInt(action.payload)
      let pageNumberChangeState = JSON.parse(JSON.stringify(state))
      pageNumberChangeState.currentPage = newPageNumber
      pageNumberChangeState.displayData = state.data.slice(state.pageSize*(newPageNumber-1), newPageNumber*state.pageSize)
      return pageNumberChangeState
    case clearSystemLogsConstant:
      return{
        status: 0,
        data: [],
        displayData: [],
        currentPage: 1,
        pageSize: 25,
        maxPage: ''
      }
    default:
      return state
  }
}

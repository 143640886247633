import { updateSideBarConstant } from '../constants/actions'

export const updateSidebarAction = (value) => {
  return dispatch => {
    dispatch({
      type: updateSideBarConstant,
      payload: value
    })
  }
}

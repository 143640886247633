import {
  getPodsConstant,
  getAllPodsConstant,
  postNewPodConstant,
  deletePodConstant,
  clearPodsConstant,
  getPodsLimitedConstant,
} from '../constants/actions';
const defaultState = {
  ok: true,
  api_version: 1.1,
  msg: '',
  pods: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case clearPodsConstant:
      return{
        ok: true,
        msg: '',
        pods: [],
        currentPod: {
          Name: '',
          House_Name: '',
          Racks: []
        }
      }
    case getPodsConstant:
      if(!action.payload.ok){
        let s1 = JSON.parse(JSON.stringify(state));
        s1.msg = action.payload.msg;
        s1.ok = action.payload.ok;
        return s1;
      }
      else{
        let s1 = {
          ok: action.payload.ok,
          msg: "successful",
          pods: action.payload.data,
          currentPod: state.currentPod
        }
        return s1;
      }
      break;
    case getPodsLimitedConstant:
    case getAllPodsConstant:
      if(!action.payload.ok){
        let s1 = JSON.parse(JSON.stringify(state));
        s1.msg = action.payload.msg;
        s1.ok = action.payload.ok;
        return s1;
      }
      else{
        let s1 = {
          ok: action.payload.ok,
          msg: "successful",
          pods: action.payload.data,
          currentPod: state.currentPod
        }
        return s1;
      }
      break;
    case postNewPodConstant:
      if(!action.payload.ok){
        let s1 = JSON.parse(JSON.stringify(state));
        s1.msg = action.payload.msg;
        s1.ok = action.payload.ok;
        return s1;
      }
      else{
        let s1 = {
          ok: action.payload.ok,
          msg: "successful",
          pods: action.payload.pods,
          currentPod: state.currentPod
        }
        return s1;
      }
      break;
    case deletePodConstant:
      if(action.payload.ok){
        return action.payload;
      }
      else{
        const s3 = {
          ok: action.payload.ok,
          pods: state.pods,
          msg: action.payload.msg,
          currentPod: state.currentPod
        }
        return s3;
      }
    default:
      return state;
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter, Switch, Redirect, withRouter } from 'react-router-dom'
import Highcharts from 'highcharts/highstock';
import AdminView from '../Views/AdminView'
import ClientView from '../Views/ClientView';
import DashboardView from '../Views/DashboardView';
import LoginView from '../Views/LoginView';
import LogView from '../Views/LogView';
import MapView from '../Views/MapView';
import MinerView from '../Views/MinerView';
import ConfigureView from '../Views/ConfigureView';
import ChangePasswordView from '../Views/ChangePasswordView';
import MaintenanceView from '../Views/MaintenanceView'
import FilteredView from '../Views/FilteredView'
import UserlogView from '../Views/UserlogView'
import ListView from '../Views/ListView'
import NotFoundView from '../Views/NotFoundView'
import HeatmapView from '../Views/HeatmapView'
import MinerInfoView from '../Views/MinerInfoView'
import AlertsView from '../Views/AlertsView'
import PoolsView from '../Views/PoolsView'
import ProfileDetailView from '../Views/ProfileDetailView'
import ProfilesView from '../Views/ProfilesView'
import FlagView  from '../Views/FlagView'
import MinerFlagView from '../Views/MinerFlagView'
import MinerCommentView from '../Views/MinerCommentView'
import MinerMaintenanceView from '../Views/MinerMaintenanceView'
import MinerFlagDetailView from '../Views/MinerFlagDetailView'
import MinerActivityView from '../Views/MinerActivityView'
import MinerTypesView from '../Views/MinerTypesView'
import MinerTypesDetailsView from '../Views/MinerTypesDetailsView'
import FlagConfigView from '../Views/FlagConfigView'
import FlagConfigDetailView from '../Views/FlagConfigDetailView'
import TuningProfileView from '../Views/TuningProfileView'
import TuningProfileDetailView from '../Views/TuningProfileDetailView'

const CustomerSwitch = ({ tier, customer }) => {
  switch (tier) {
    default:
    case 0:
      return(
        <Switch>
          <Route exact path="/client/:name/region/:region_id/miner/:miner/info" component={MinerInfoView} />
          <Route exact path="/client/:name/region/:region_id/map" component={MapView} />
          <Route exact path="/client/:name/region/:region_id/miners/:type" component={FilteredView} />
          <Route exact path="/client/:name/region/:region_id/flags" component={FlagView} />
          <Route exact path="/client/:name/miners/:type" component={FilteredView} />
          <Route exact path="/client/:name/region/:region_id/miners" component={ListView} />
          <Route exact path="/client/:name/miners" component={ListView} />
          <Route exact path="/client/:name/region/:region_id/alerts" component={AlertsView} />
          <Route exact path="/client/:name/region/:region_id/heatmaps" component={HeatmapView} />
          <Route exact path="/client/:name/region/:region_id" component={ClientView} />
          <Route exact path="/client/:name" component={ClientView} />
          <Route exact path="/changepassword" component={ChangePasswordView} />
          <Route render={(props) => (
            <NotFoundView
              noAccess={['comment', 'maintenance', 'userLog', 'inventory']}
            />
          )} />
        </Switch>
      )
      break;
    case 1:
      return(
        <Switch>
          <Route exact path="/client/:name/region/:region_id/miner/:miner/comment" component={MinerCommentView} />
          <Route exact path="/client/:name/region/:region_id/miner/:miner/maintenance" component={MinerMaintenanceView} />
          <Route exact path="/client/:name/region/:region_id/miner/:miner/flag" component={MinerFlagView} />
          <Route exact path="/client/:name/region/:region_id/miner/:miner/flag/:flag_id" component={MinerFlagDetailView} />
          <Route exact path="/client/:name/region/:region_id/miner/:miner/activity" component={MinerActivityView} />
          <Route exact path="/client/:name/region/:region_id/miner/:miner/info" component={MinerInfoView} />
          <Route exact path="/client/:name/region/:region_id/map" component={MapView} />
          <Route exact path="/client/:name/log" component={LogView} />
          <Route exact path="/client/:name/region/:region_id/userLog" component={UserlogView} />
          <Route exact path="/client/:name/region/:region_id/miners/:type" component={FilteredView} />
          <Route exact path="/client/:name/region/:region_id/flags" component={FlagView} />
          <Route exact path="/client/:name/region/:region_id/flagsetting" component={FlagConfigView} />
          <Route exact path="/client/:name/region/:region_id/flagsetting/:flag_id" component={FlagConfigDetailView} />
          <Route exact path="/client/:name/miners/:type" component={FilteredView} />
          <Route exact path="/client/:name/region/:region_id/miners" component={ListView} />
          <Route exact path="/client/:name/miners" component={ListView} />
          <Route exact path="/client/:name/region/:region_id/heatmaps" component={HeatmapView} />
          <Route exact path="/client/:name/region/:region_id/pools" component={PoolsView} />
          <Route exact path="/client/:name/region/:region_id/profiles" component={ProfilesView} />
          <Route exact path="/client/:name/region/:region_id/profiles/:id" component={ProfileDetailView} />
          <Route exact path="/client/:name/region/:region_id/alerts" component={AlertsView} />
          <Route exact path="/client/:name/region/:region_id/minertypes" component={MinerTypesView} />
          <Route exact path="/client/:name/region/:region_id/minertypes/:minertype_id" component={MinerTypesDetailsView} />
          <Route exact path="/client/:name/region/:region_id/tuningprofiles" component={TuningProfileView} />
          <Route exact path="/client/:name/region/:region_id/tuningprofiles/:id" component={TuningProfileDetailView} />
          <Route exact path="/client/:name/maintenance" component={MaintenanceView} />
          <Route exact path="/client/:name/region/:region_id" component={ClientView} />
          <Route exact path="/client/:name" component={ClientView} />
          <Route exact path="/changepassword" component={ChangePasswordView} />
          <Route render={(props) => (
            <NotFoundView
              noAccess={['comment', 'maintenance', 'userLog', 'inventory']}
            />
          )} />
        </Switch>
      )
  }
}

export default CustomerSwitch

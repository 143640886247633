import React from 'react'
import { connect } from 'react-redux'
import HeatmapSelection from '../../Components/HeatmapSelection'
import Heatmap from '../../Components/Heatmap'
import { getPodsLimitedAction } from '../../redux/actions/getPodsLimitedAction'
import { getHousesAction } from '../../redux/actions/getHousesAction';
import { clearHeatmapReducerAction } from '../../redux/actions/clearHeatmapReducerAction'
import { updateHeatmapReducerAction } from '../../redux/actions/updateHeatmapReducerAction'
import { getHeatmapMinersAction } from '../../redux/actions/getHeatmapMinersAction'
import { getHouseSubnetConAction } from '../../redux/actions/getHouseSubnetConAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { mapMinersAction } from '../../redux/actions/mapMinersAction';
import { clearHouseSubnetConnectionsAction } from '../../redux/actions/clearHouseSubnetConnectionsAction'
import { updateHeatmapPersistAction } from '../../redux/actions/updateHeatmapPersistAction'
import { addUserActivityAction } from '../../redux/actions/addUserActivityAction'
import "react-responsive-modal/styles.css";
import socketIOClient from "socket.io-client";
import { Modal } from "react-responsive-modal";
import './style.css';

const jwt = require('jwt-simple')

class HeatmapView extends React.Component {
  constructor(props){
    super(props);
    this._isMounted = false
    this.state = {
      deviceMappingModalOpen: false,
      deviceMappingLocation: '',
      deviceMappingMAC: '',
      socket: null,
      heatmapWidth: this.calculateWidthInital(),
      intervalID: '',
      heartBeatInterval : '',
    }
  }
  componentDidMount = async() => {
    const heatmapLink = document.getElementById("heatmapLink");
    let { method, type, rack, perRow, building, pod } = this.props.heatmapPersist
    if(building.hasOwnProperty(this.props.match.params.name)){
      building = building[this.props.match.params.name]
    }
    else{
      building = building['default']
    }
    if(pod.hasOwnProperty(this.props.match.params.name)){
      pod = pod[this.props.match.params.name]
    }
    else{
      pod = pod['default']
    }
    window.addEventListener("resize", this.calculateWidth);
    this._isMounted = true
    this._isMounted && await this.props.getHousesAction(this.props.userStore.result, this.props.match.params.name, this.props.match.params.region_id);
    let house = building['name']
    let houseID = building['id']
    let pods = []
    this.props.getPodsLimitedAction(this.props.userStore.result, house, this.props.match.params.name, this.props.match.params.region_id);
    let newHeatmapState = JSON.parse(JSON.stringify(this.props.heatmapStore))
    newHeatmapState.currentPod     = pod['id']
    newHeatmapState.currentPodName = pod['name']
    newHeatmapState.house = house;
    //newHeatmapState.pods = pods need to do this dynamically
    newHeatmapState.client = this.props.match.params.name;
    if(this._isMounted && house !== 'All'){
      this.props.getHouseSubnetConAction(this.props.userStore.result, this.props.match.params.name, houseID)
    }
    newHeatmapState.houseID = houseID
    this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, houseID, newHeatmapState.currentPod, rack, newHeatmapState.currentPodName, method, this.props.match.params.region_id)
    this.props.updateHeatmapReducerAction(newHeatmapState)
    //this.initalSetup()
    if(heatmapLink){
      heatmapLink.classList.add("itemSelected");
    }
    //const socket = socketIOClient.connect(`localhost:4001`);
    if(this._isMounted){
      const socket = process.env.REACT_APP_REPORT_PATH ? socketIOClient.connect(`wss://${process.env.REACT_APP_REPORT_SERVER_URL}`, {path: process.env.REACT_APP_REPORT_PATH}): socketIOClient.connect(`wss://${process.env.REACT_APP_REPORT_SERVER_URL}`)
      socket.on('connect', () => {
        const scanners = this.props.houseSubnetConnections.connections.map((item) => item.Subnet_Name)
        socket.emit('userConnect', JSON.stringify({scanners}))
      })
      socket.on('reconnect_attempt', (attemptNumber) => {
        if(attemptNumber == 2){
          //alert("Did not manage to connect to IP report proxy")
          socket.disconnect()
        }
      });
      socket.on("IpReport", data => {
        const { deviceMappingMAC } = this.state;
        if(deviceMappingMAC !== data) this.setState({ deviceMappingMAC: data })
      });
      // the reason for this extra isMounted check is because the user could
      // already navigated to another view since we created the socket on line 66
      if(this._isMounted){
        this.setState({socket})
      }
      else{
        socket.disconnect()
      }
    }
    this.props.addUserActivityAction(this.props.userStore.result, "heatmap_heartbeat")
    const heartBeatInterval = setInterval(this.sendHeartBeat, 300000)
    this.setState({heartBeatInterval})
  }
  sendHeartBeat = () => {
    this.props.addUserActivityAction(this.props.userStore.result, "heatmap_heartbeat")
  }
  calculateWidthInital = () => {
    if(this.props.clientStore.sidebaropen){
      return (window.innerWidth - (200 + 200)) / this.props.heatmapPersist.perRow
    }
    else{
      return (window.innerWidth - (200 + 50)) / this.props.heatmapPersist.perRow
    }
  }
  calculateWidth = () => {
    const { perRow } = this.props.heatmapPersist;
    let width = 0
    if(this.props.clientStore.sidebaropen){
      width = (window.innerWidth - (200 + 200)) / perRow
    }
    else{
      width = (window.innerWidth - (200 + 50)) / perRow
    }
    this.setState({heatmapWidth: width})
  }
  calculateWidthOnClick = (value) => {
    let width = 0
    if(this.props.clientStore.sidebaropen){
      width = (window.innerWidth - (200 + 200)) / value
    }
    else{
      width = (window.innerWidth - (200 + 50)) / value
    }
    this.setState({heatmapWidth: width})
  }
  initalSetup = () => {
    this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.heatmapStore.houseID, this.props.heatmapStore.currentPod, this.props.heatmapPersist.rack, this.props.heatmapStore.currentPodName, this.props.heatmapPersist.method, this.props.match.params.region_id)
  }
  componentWillUnmount = () => {
    this._isMounted = false
    window.removeEventListener("resize", this.calculateWidth);
    const { socket, intervalID, heartBeatInterval } = this.state
    if(socket){
      socket.disconnect()
    }
    this.props.clearHeatmapReducerAction()
    clearInterval(intervalID)
    clearInterval(heartBeatInterval)
  }
  componentDidUpdate(){
    if(this.props.clientStore.refreshData && this.state.intervalID === ''){
      const intervalID = setInterval(this.initalSetup, 60000);
      this.setState({intervalID})
    }
    if(!this.props.clientStore.refreshData && this.state.intervalID !== ''){
      const { intervalID } = this.state;
      clearInterval(intervalID)
      this.setState({intervalID: ''})
    }
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onReactFilterSelect = async(e) => {
    const { socket } = this.state
    switch (e.type) {
      case 'heatmapPod':
        if(e.value === 'All'){
          this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.heatmapStore.houseID, 'All', this.props.heatmapPersist.rack, 'All', this.props.heatmapPersist.method, this.props.match.params.region_id)
        }
        else{
          this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.heatmapStore.houseID, e.value, this.props.heatmapPersist.rack, e.label, this.props.heatmapPersist.method, this.props.match.params.region_id)
        }
        let heatmapPod = this.props.heatmapPersist.pod
        heatmapPod[this.props.match.params.name] = {
          name: e.label,
          id: e.value
        }
        this.props.updateHeatmapPersistAction('pod', heatmapPod)
        break;
      case 'heatmapHouse':
        let newHeatmapState = JSON.parse(JSON.stringify(this.props.heatmapStore))
        if(e.value == "All"){
            this.props.clearHouseSubnetConnectionsAction()
            newHeatmapState.houseID = e.value
        }
        else{
          newHeatmapState.houseID = e.value.id
          await this.props.getHouseSubnetConAction(this.props.userStore.result, this.props.match.params.name, e.value.id)
        }
        newHeatmapState.house = e.label
        const scanners = this.props.houseSubnetConnections.connections.map((item) => item.Subnet_Name)
        if(socket){
          socket.emit('changeHouse', JSON.stringify({scanners}))
        }
        this.props.getPodsLimitedAction(this.props.userStore.result, newHeatmapState.house, this.props.match.params.name, this.props.match.params.region_id)
        // need to send a request to get all the miners in this house (for all pods)
        newHeatmapState.currentPod = 'All'
        newHeatmapState.currentPodName = 'All'
        let heatmapBuilding = this.props.heatmapPersist.building
        heatmapBuilding[this.props.match.params.name] = {
          name: e.label,
          id: e.value == 'All'? 'All': e.value.id
        }
        this.props.updateHeatmapPersistAction('building', heatmapBuilding)
        // FIX THIS SO WE CAN UPDATE THE BUILDING
        this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, newHeatmapState.houseID, newHeatmapState.currentPod, this.props.heatmapPersist.rack, newHeatmapState.currentPodName, this.props.heatmapPersist.method, this.props.match.params.region_id)
        this.props.updateHeatmapReducerAction(newHeatmapState)
        
        break;
      case 'heatmapRack':
        this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.heatmapStore.houseID, this.props.heatmapStore.currentPod, e.value, this.props.heatmapStore.currentPodName, this.props.heatmapPersist.method, this.props.match.params.region_id)
        this.setState({
            rack: e.value
        })
        this.props.updateHeatmapPersistAction("rack", e.value)
        break;
      case 'mappingMethod':
          this.props.updateHeatmapPersistAction('method', e.value)
          this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.heatmapStore.houseID, this.props.heatmapStore.currentPod, this.props.heatmapPersist.rack, this.props.heatmapStore.currentPodName, e.value, this.props.match.params.region_id)
        break
      case 'perRow':
        this.setState({allowUpdate: true})
        this.calculateWidthOnClick(e.value)
        this.props.updateHeatmapPersistAction("perRow", e.value)
        break
      case 'heatmapType':
        this.props.updateHeatmapPersistAction("type", e.value)
      default:
        this.setState({ [e.type]: e.value })
    }
  }
  onFilterSelect = async(e) => {
    const { socket, rack } = this.state
    const split = e.target.value.split('-')
    switch (e.target.name) {
      case 'heatmapPod':
        if(e.target.value === 'All'){
          this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.heatmapStore.houseID, 'All', this.state.rack, this.props.match.params.region_id)
        }
        else{
          this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.heatmapStore.houseID, split[2], this.state.rack, this.props.match.params.region_id)
        }
        break;
      case 'heatmapHouse':
        const houseName = split[0]
        const houseID = split[1]
        await this.props.getHouseSubnetConAction(this.props.userStore.result, this.props.match.params.name, houseID)
        const scanners = this.props.houseSubnetConnections.connections.map((item) => item.Subnet_Name)
        socket.emit('changeHouse', JSON.stringify({scanners}))
        await this.props.getPodsLimitedAction(this.props.userStore.result, houseName, this.props.match.params.name)
        // need to send a request to get all the miners in this house (for all pods)
        let newHeatmapState = JSON.parse(JSON.stringify(this.props.heatmapStore))
        newHeatmapState.house = houseName
        newHeatmapState.pods = this.props.podStore.pods.map((item) => ({ID: item.ID, Name: item.Name}))
        if(newHeatmapState.pods.length > 0){
          newHeatmapState.currentPod = newHeatmapState.pods[0].ID
        }
        else{
          newHeatmapState.currentPod = 'All'
        }
        newHeatmapState.houseID = houseID
        this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, houseID, newHeatmapState.currentPod, this.state.rack, this.props.heatmapPersist.method, this.props.match.params.region_id)
        this.props.updateHeatmapReducerAction(newHeatmapState)
        break;
      case 'heatmapRack':
        this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.heatmapStore.houseID, this.props.heatmapStore.currentPod, e.target.value, this.props.heatmapPersist.method, this.props.match.params.region_id)
        this.setState({
            rack: e.target.value
        })
        break;
      case 'heatmapType':
        this.setState({ heatmapType: e.target.value })
        break;
      default:
        console.log(e.target.value)
    }
  }
  openDeviceMapping = (location) => {
    this.setState({deviceMappingLocation: location, deviceMappingModalOpen: true})
  }
  mapMiner = async(e) => {
    const { deviceMappingLocation } = this.state;
    const element = document.getElementById("deviceMappingMAC")
    if(element){
      if(element.value == ''){
        alert("MAC Address field is empty ")
        return
      }
      const splitLocation = deviceMappingLocation.split('-')
      if(splitLocation.length > 2){
        const items = [{
          MAC_Address: element.value,
          Location: deviceMappingLocation,
          rackNum: splitLocation[0],
          shelfNum: splitLocation[1],
          minerNum: splitLocation[2]
        }]
        await this.props.mapMinersAction(this.props.userStore.result, this.props.match.params.name, { Pod_ID: this.props.heatmapStore.currentPod, House_ID: this.props.heatmapStore.houseID, items: items });
      }
    }
    this.props.getHeatmapMinersAction(this.props.userStore.result, this.props.match.params.name, this.props.heatmapStore.houseID, this.props.heatmapStore.currentPod, this.state.rack, this.props.match.params.region_id)
    this.setState({deviceMappingMAC: '',deviceMappingLocation: '', deviceMappingModalOpen: false})
  }
  render(){
    const { deviceMappingMAC, deviceMappingLocation, heatmapType, rack, heatmapWidth } = this.state;
    const { userData } = this.props.userStore
    const userLevel = userData.level? userData.level: 0
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    const styles = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="adc_heatmapView-container">
        <h3 className="adc_heatmapView-h1">Heatmaps - {this.props.match.params.name}</h3>
        <HeatmapSelection
          houses={this.props.houseStore.houses}
          houseSelected={this.props.heatmapStore.house}
          pods={this.props.podStore.pods.map((item) => ({ID: item.ID, Name: item.Name}))}
          currentPod={this.props.heatmapStore.currentPodName}
          onItemSelect={this.onReactFilterSelect}
          heatmapType={this.props.heatmapPersist.type}
          rack={this.props.heatmapPersist.rack}
          perRow={this.props.heatmapPersist.perRow}
          userLevel={userLevel}
          mappingMethod={this.props.heatmapPersist.method}
        />
        <div className="adc_heatmapView-heatmaps-container">
          {this.props.heatmapStore.heatmaps.map((item, i) => {
            const key = Object.keys(item)
            const keys = Object.keys(item[key])
            return(
              <Heatmap
                key={i}
                data2={item[key[0]]}
                shelfs={keys}
                podName={key[0]}
                client={this.props.match.params.name}
                openDeviceMapping={this.openDeviceMapping}
                heatmapType={heatmapType}
                width={heatmapWidth}
              />
            )
          })}
        </div>
        <Modal
          open={this.state.deviceMappingModalOpen}
          onClose={() => this.setState({deviceMappingModalOpen: false, deviceMappingMAC: ''})}
          styles={styles}
          center
          >
          <div className="adc_heatmapView-deviceModalContainer">
            <div className="adc_heatmapView-deviceModalHeader">
              <h4 className="adc_heatmapView-modal-title"><i className="fa fa-map-marker"/>Map miner</h4>
            </div>
            <div className="adc_heatmapView-deviceModalBody">
              {this.state.deviceMappingMAC !== ''?(
                <div className="adc_deviceMapping-inputDiv">
                  <label className="adc_heatmapView-modal-title" for="deviceMappingMAC">MAC</label>
                  <input type="text" id="deviceMappingMAC" value={this.state.deviceMappingMAC} disabled name="deviceMappingMAC" className="adc_heatmapView-modal-input adc_deviceMapping-input"/>
                </div>
              ):(
                <div className="adc_deviceMapping-inputDiv">
                  <label className="adc_heatmapView-modal-title" for="deviceMappingMAC">MAC</label>
                  <input type="text" id="deviceMappingMAC" name="deviceMappingMAC" className="adc_deviceMapping-input"/>
                </div>
              )}
              <div className="adc_deviceMapping-inputDiv">
                <label className="adc_heatmapView-modal-title" for="deviceMappingBuiding">Building</label>
                <input type="text" name="deviceMappingBuiding" value={this.props.heatmapStore.house} className="adc_heatmapView-modal-input adc_deviceMapping-input" disabled/>
              </div>
              <div className="adc_deviceMapping-inputDiv">
                <label className="adc_heatmapView-modal-title" for="deviceMappingPod">Pod</label>
                <input type="text" name="deviceMappingPod" value={this.props.heatmapStore.currentPodName} className="adc_heatmapView-modal-input adc_deviceMapping-input" disabled/>
              </div>
              <div className="adc_deviceMapping-inputDiv">
                <label className="adc_heatmapView-modal-title" for="deviceMappingLocation">Location</label>
                <input type="text" name="deviceMappingLocation" value={deviceMappingLocation} className="adc_heatmapView-modal-input adc_deviceMapping-input" disabled/>
              </div>
              <div className="adc_deviceMapping-inputDiv">
                <button type="button" className="adc_deviceMapping-button btn-success adc_heatmapView-button" onClick={e => this.mapMiner(e)}>
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
const mapStateTopProps = (props) => ({
  userStore: props.userReducer,
  heatmapStore: props.heatmapReducer,
  houseStore: props.houseReducer,
  clientStore: props.clientReducer,
  podStore: props.podsReducer,
  houseSubnetConnections: props.houseSubnetReducer,
  heatmapPersist: props.heatmapPersistReducer
})

export default connect(mapStateTopProps, {
  getPodsLimitedAction,
  getHousesAction,
  clearHeatmapReducerAction,
  updateHeatmapReducerAction,
  getHeatmapMinersAction,
  mapMinersAction,
  getHouseSubnetConAction,
  updateClientAction,
  clearHouseSubnetConnectionsAction,
  updateHeatmapPersistAction,
  addUserActivityAction
})(HeatmapView)

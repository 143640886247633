import service from '../services/hashrateService';
import { getMinerHistoryConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'

export const getMinerHistoryAction = (token, client, mac_address) => {
  return async (dispatch) => {
    try{
      const result = await service.getMinerHistory(token, client, mac_address);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(success(result));
    }
    catch(err){
      console.log(err)
    }
  }
}


const success = (result) => ({
  type: getMinerHistoryConstant,
  payload: result
})

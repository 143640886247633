import React from 'react'
import deleteIcon from '../../data/Icons/delete-black-18dp.svg'
import './style.css';
const PodcardItem = ({pod, id, onDeleteClick, privilegeLevel, deletePrivileges}) =>{
  return (
    <tr>
      <td>
      <span className="cubeContainer">
        <i className="fa fa-cubes"/>
      </span>
      {pod}
      {privilegeLevel >= deletePrivileges? (
        <span className="deleteContainer">
          <img src={deleteIcon} alt="delete" onClick={e => onDeleteClick(id, "Pod")}/>
        </span>
      ): <></>}
      </td>
    </tr>
  )
}
export default PodcardItem;

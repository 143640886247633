import { getMaintenanceConstant } from '../constants/actions'
import service from '../services/logService';
import { setErrorMessageAction } from './setErrorMessageAction';

export const getMaintenanceLogsAction = (token, customer,  pageSize, pageNumber, orderBy, acending, searchValue, region_id) => {
  return async(dispatch) => {
    try {
      const result = await service.getMaintenanceLogs(token, customer,  pageSize, pageNumber, orderBy, acending, searchValue, region_id);
      if(!result){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(success(result))
    } catch (e) {
      console.log(e)
    }
  }
}

const success = (result) => ({
  type: getMaintenanceConstant,
  payload: result
})

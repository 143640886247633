import { getAllTypesConstant  } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/typeService'

export const getAllTypesAction = (token, pageSize, pageNumber, orderBy, acending, searchValue) => {
  return async dispatch => {
    const result = await service.getAllTypes(token, pageSize, pageNumber, orderBy, acending, searchValue)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch({
      type: getAllTypesConstant,
      payload: result
    })
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
const createOption = (startingNum, size) => {
  let returnValue = []
  for(; startingNum <= size; startingNum++){
    returnValue.push(<option key={startingNum}>{startingNum}</option>)
  }
  return returnValue;
}

const MapInput = ({MAC_Address, Location, onInput, inputNumber, startingRack, shelfNum, minerNum, rackValue, shelfValue, minerValue}) => {
  return(
      <form action="" className="form form-inline mapForm">
        <div className="mapInputFormDiv">
            <input
              type="text"
              id="MAC_Address"
              placeholder="enter MAC_Address"
              name="MAC_Address"
              defaultValue={MAC_Address}
              onInput={e => onInput(e, inputNumber)}
            />
        </div>
        <div className="mapInputFormDiv">
            <select data-width="10%" name="rackNum" value={rackValue} onInput={e => onInput(e, inputNumber)}>
              {createOption(startingRack, 34)}
            </select>
        </div>
        <div className="mapInputFormDiv">
            <select data-width="10%" name="shelfNum" value={shelfValue} onInput={e => onInput(e, inputNumber)}>
              {createOption(1,shelfNum)}
            </select>
        </div>
        <div className="mapInputFormDiv">
            <select data-width="10%" name="minerNum" value={minerValue} onInput={e => onInput(e, inputNumber)}>
              {createOption(1,minerNum)}
            </select>
        </div>
      </form>
  )
}

export default MapInput;

MapInput.propTypes = {
  MAC_Address: PropTypes.string.isRequired,
  Location: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
  inputNumber: PropTypes.number.isRequired,
  startingRack: PropTypes.number.isRequired,
  shelfNum: PropTypes.number.isRequired,
  minerNum: PropTypes.number.isRequired,
  rackValue: PropTypes.number.isRequired,
  shelfValue: PropTypes.number.isRequired,
  minerValue: PropTypes.number.isRequired
}
//<input type="text" id="Location" placeholder="enter Location" name="Location" value={Location} className="form-control" onInput={e => onInput(e, inputNumber)}/>

import React from 'react';
import './style.css';
import deleteIcon from '../../data/Icons/delete-black-18dp.svg'
import editIcon from '../../data/Icons/edit-24px.svg'
const Poolcard = ({id, url, worker, password, onEditOpenClick, onDeleteClick, privilegeLevel, numberOfPools, isEditOpen, deletePrivileges, insertPrivileges }) => {
  return(
    <div className="poolInfo-container">
      <table
        id="dtVerticalScrollExample"
        className="poolInfoTable"
        cellPadding="0"
        cellSpacing="0"
        width={numberOfPools !== 3 || isEditOpen ? "75%": "35%"}>
          <tbody>
        <tr>
          <td className="poolInfoRowChild">URL: </td>
          <td className="poolInfoRowChild">
          {url}
          {privilegeLevel >= insertPrivileges? (
            <span className="material-icons-edit">
              <img src={editIcon} alt="edit" onClick={e => onEditOpenClick(id)}/>
            </span>
          ): <></>}
          {privilegeLevel >= deletePrivileges? (
            <span className="material-icons-delete">
              <img src={deleteIcon} alt="delete" onClick={e => onDeleteClick(id, "Pool")} />
            </span>
          ):<></>}
          </td>
        </tr>
        <tr>
          <td className="poolInfoRowChild">Worker: </td>
          <td className="poolInfoRowChild">{worker}</td>
        </tr>
          <tr>
            <td className="poolInfoRowChild">Password: </td>
            <td className="poolInfoRowChild">{password}</td>
          </tr>
          </tbody>
      </table>
    </div>
  )
}

export default Poolcard

/*


  <button type="button" class="btn btn-primary" onClick={e => onEditOpenClick(id)}> edit </button>
  <p>URL: {url}</p>
  <p>Worker: {worker}</p>
  <p>Password: {password}</p>
*/

import React from 'react';
import './style.css'
const MaintenanceLogItem = ({user, timestamp, otherInfo, description, location, items, house, pod}) => {
  return (
    <tr>
      <td className="tableRowChild">
        {`${house} / ${pod} / ${location}`}
      </td>
      <td className="tableRowChild">
        {user}
      </td>
      <td className="tableRowChild">
        {timestamp}
      </td>
      <td className="tableRowChild">
        {description}
      </td>
      <td className="tableRowChild">
        {otherInfo}
      </td>
      <td className="tableRowChild">
        {items.map((item) => `${item.Type}: ${item.Amount}`).join(" - ")}
      </td>
    </tr>
  )
}
export default MaintenanceLogItem;
/*{date[0]}, {time[0]}*/

import {
  getErrorLogsConstants,
  clearErrorLogsConstants,
  changeErrorLogsPageSizeConstant,
  onErrorLogsPageClickConstant,
  setErrorLogsStatus
} from '../constants/actions'

const defaultState = {
  status: 0,
  data: [],
  api_version: 1.1,
  displayData: [],
  currentPage: 1,
  pageSize: 25,
  maxPage: ''
}


export default(state = defaultState, action) => {
  switch (action.type) {
    case setErrorLogsStatus:
      return {
        status: action.payload,
        data: state.data,
        api_version: state.api_version,
        displayData: state.displayData,
        currentPage: state.currentPage,
        pageSize: state.pageSize,
        maxPage: state.maxPage
      }
    case getErrorLogsConstants:
      const dataReversed = action.payload.data.reverse()
      return {
        status: action.payload.status,
        data: dataReversed,
        pageSize: state.pageSize,
        currentPage: state.currentPage,
        displayData: dataReversed.slice(0, state.pageSize),
        maxPage: Math.ceil(action.payload.data.length/state.pageSize)
      }
    case clearErrorLogsConstants:
      return {
        status: 0,
        data: [],
        displayData: [],
        currentPage: 1,
        pageSize: 25,
        maxPage: ''
      }
    case changeErrorLogsPageSizeConstant:
      const newPageSize = parseInt(action.payload)
      let pageSizeChangeState = JSON.parse(JSON.stringify(state))
      pageSizeChangeState.pageSize = newPageSize
      pageSizeChangeState.displayData = state.data.slice(0, newPageSize)
      return pageSizeChangeState
    case onErrorLogsPageClickConstant:
      const newPageNumber = parseInt(action.payload)
      let pageNumberChangeState = JSON.parse(JSON.stringify(state))
      pageNumberChangeState.currentPage = newPageNumber
      pageNumberChangeState.displayData = state.data.slice(state.pageSize*(newPageNumber-1), newPageNumber*state.pageSize)
      return pageNumberChangeState
    default:
      return state
  }
}

import { formatBytes, checkTime, formatTime } from './formatUtils'
export const highchartOptions = (data, api_version, type, title) => {
  switch (api_version) {
    default:
    case 1.1:
      let online    = []
      let offline   = []
      let idlev1      = []
      let hashrate  = []
      let perform   = []
      let cards     = []
      let categories = []
      let minHashrate = Infinity
      for( var i in data){
        const category = data[i].Date
        categories.push(category)
        idlev1.push(data[i].Idle)
        offline.push(data[i].Offline)
        online.push(data[i].Online)
        hashrate.push(data[i].Total_Speed)
        if(data[i].Total_Speed < minHashrate){
          minHashrate = data[i].Total_Speed
        }
        perform.push(data[i].Performance)
        cards.push(data[i].Cards)
      }
      const options = {
        navigator: {
          enabled: false
        },
        title: {
          text: title,
          style:{
            color: '#b8c7ce',
            fontWeight: 'bold'
          },
          margin: 0
        },
        rangeSelector: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        yAxis: {
          offset: 20,
          tickPixelInterval: 35,
          min: Math.floor(minHashrate*0.80)
        },
        xAxis: {
          categories: categories,
          visible: false,
          labels: {
            enabled: false
          }
        },
        series: [
          {
            name: "Hashrate",
            data: hashrate,
            fillColor: 'rgba(124, 181, 236, 0.75)',
            fillOpacity:1,
            type: 'area',
          },
          {
            name: "Online",
            data: online,
            lineWidth: 0
          },
          {
            name: "Offline",
            data: offline,
            lineWidth: 0
          },
          {
            name: 'Idle',
            data: idlev1,
            lineWidth: 0
          },
          {
            name: 'Performance',
            data: perform,
            lineWidth: 0
          },
          {
            name: 'Cards',
            data: cards,
            lineWidth: 0
          }
        ],
        tooltip: {
          formatter: function() {
            return (
              "Hashrate: "+ "<b>" + formatBytes(this.points[0].y, 2) + "</b><br>" +
              "Online: " + "<b>" + this.points[1].y + "</b><br>" +
              "Offline: "+ "<b>" + this.points[2].y + "</b><br>" +
              "Idle & API Down: "+ "<b>" + this.points[3].y + "</b><br>" +
              "Performance: "+ "<b>" + `${this.points[4].y.toFixed(2)} %` + "</b><br>" +
              "Cards/Boards: "+ "<b>" + this.points[5].y + "</b><br>" +
              "Time/Date: " + "<b>" + this.points[0].key +
              "</b>"
            );
          }
        },
        chart: {
            height: 250,
            backgroundColor: {
              linearGradient: { x1: 0},
              stops: [
                  [0, '#0e1012'],
              ]
            }
        }
      }
      return options
    case 1.2:
      let avgHashrate = []
      let avgPowerConsumption = []
      let avgCardNumber = []
      let online2 = []
      let offline2 = []
      let idle = []
      let api_down = []
      let categories2 = []
      for(var i in data){
        const dataSplit = data[i].Date.split(' ')
        const category = dataSplit.length > 0 && type !== '6h'? type == '1d'? dataSplit[0]: dataSplit[1]: data[i].Date
        categories2.push(category)
        avgHashrate.push(data[i].Avg_Speed)
        avgPowerConsumption.push(data[i].Avg_Power_Consumption)
        avgCardNumber.push(data[i].Avg_Card_Num)
        online2.push(data[i].Status.Online)
        offline2.push(data[i].Status.Offline)
        idle.push(data[i].Status.Idle)
        api_down.push(data[i].Status.API_Down)
      }
      const options2 = {
        navigator: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: categories2
        },
        series: [
          {
            name: "Hashrate",
            data: avgHashrate,
            fillColor: 'rgba(124, 181, 236, 0.75)',
            fillOpacity:1,
            type: 'area'
          },
          {
            name: "Power Consumption",
            data: avgPowerConsumption
          },
          {
            name: "Card Number",
            data: avgCardNumber
          },
          {
            name: "Online",
            data: online2
          },
          {
            name: "Offline",
            data: offline2
          },
          {
            name: "Idle",
            data: idle
          },
          {
            name: "API Down",
            data: api_down
          }
        ],
        tooltip: {
          formatter: function() {
            return (
              "Hashrate: "+ "<b>" + formatBytes(this.points[0].y, 2) + "</b><br>" +
              "Power Consumption: " + "<b>" + this.points[1].y + "</b><br>" +
              "Cards: "+ "<b>" + this.points[2].y + "</b><br>" +
              "Online: "+ "<b>" + this.points[3].y + "</b><br>" +
              "Offline: "+ "<b>" + this.points[4].y + "</b><br>" +
              "Idle: "+ "<b>" + this.points[5].y + "</b><br>" +
              "API Down: "+ "<b>" + this.points[6].y + "</b><br>" +
              "Time/Date: " + "<b>" + this.points[0].key +
              "</b>"
            );
          }
        },
        chart: {
            reflow: false,
            height: 250
        }
      }
      return options2
      break;
  }
}

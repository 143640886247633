import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { getAlertSettingsAction } from '../../redux/actions/getAlertSettingsAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { putAlertSettingsAction } from '../../redux/actions/putAlertSettingsAction'
import { postToEmailListAction } from '../../redux/actions/postToEmailListAction'
import { deleteFromEmailListAction } from '../../redux/actions/deleteFromEmailListAction'
import { getEmailListAction } from '../../redux/actions/getEmailListAction'
import './style.css'

class AlertsView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      idle: '',
      offline: '',
      offline_time: '',
      new_performance: '',
      newEmail: '',
      modalOpen: false,
      addEmailModalOpen: false,
      editItemModalOpen: false,
      editName: '',
      threshold: '',
      interval: '',
      status: '',
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    this.props.getAlertSettingsAction(this.props.token, this.props.match.params.name)
    this.props.getEmailListAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onEdit = () => {
    const { new_performance, offline, offline_time, idle } = this.state
    const body = [
      {Type: 'Idle', Threshold: parseInt(idle)},
      {Type: 'Offline', Threshold: parseInt(offline)},
      {Type: 'Offline Time', Threshold: parseInt(offline_time)},
      {Type: 'Performance', Threshold: parseInt(new_performance)}]
    this.props.putAlertSettingsAction(this.props.token, body, this.props.match.params.name)
    this.setState({
      modalOpen: false,
      new_performance: '',
      offline: '',
      offline_time: '',
      idle: ''
    })
  }
  onEditClick = () => {
    let new_performance = ''
    let offline = ''
    let offline_time = ''
    let idle = ''
    for(var i in this.props.alertStore){
      const item = this.props.alertStore[i]
      switch (item.Type) {
        case 'Idle':
          idle = item.Threshold
          break;
        case 'Offline':
          offline = item.Threshold
          break;
        case 'Offline Time':
          offline_time = item.Threshold
          break;
        case 'Performance':
          new_performance = item.Threshold
          break;
        default:

      }
    }
    this.setState({
      modalOpen: true,
      new_performance,
      offline,
      offline_time,
      idle
    })
  }
  onEmailRemove = (email) => {
    if(window.confirm(`Are you sure you want to remove ${email} ?`)){
      this.props.deleteFromEmailListAction(this.props.token, email, this.props.match.params.name)
    }
  }
  onEmailAdd = () => {
    const emailRegex = /^\S+@\S+\.\S+$/
    const { newEmail } = this.state
    if(newEmail == ''){
      alert("Email field s empty")
      return
    }
    if(!emailRegex.exec(newEmail)){
      alert("Enter a valid email")
      return
    }
    this.props.postToEmailListAction(this.props.token, {Email: newEmail}, this.props.match.params.name)
    this.setState({
      addEmailModalOpen: false,
      newEmail: ''
    })
  }
  onEditItemClick = (type) => {
    const item = this.props.alertStore.filter((item) => item.Type == type)
    if(item.length > 0){
      this.setState({
        editName: item[0].Type,
        threshold: item[0].Threshold,
        interval: item[0].Send_Alert_Interval,
        status: item[0].Active_Value,
        editItemModalOpen: true
      })
    }
  }
  onItemEdit = () => {
    const { editName, threshold, interval, status } = this.state
    if(threshold == '' || threshold === 0){
      alert("Threshold cannot be empty")
      return
    }
    if(threshold < 0){
      alert("Threshold cannot be less then 0")
      return
    }
    if(editName !== 'Offline Time' && interval < 1){
      alert("Interval cannot be less then 1 hour")
      return
    }
    if(editName === 'Offline Time'){
      this.props.putAlertSettingsAction(this.props.token, {Type: editName, Threshold: threshold}, this.props.match.params.name)
    }
    else{
      this.props.putAlertSettingsAction(this.props.token, {Type: editName, Threshold: threshold, Active: status, Interval: interval}, this.props.match.params.name)
    }
    this.setState({
      editItemModalOpen: false,
      editName: '',
      threshold: '',
      interval: '',
      status: '',
    })
  }
  render(){
    const { userData } = this.props
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    let newData = JSON.parse(JSON.stringify(this.props.alertStore))
    newData = newData.map((item) => {
      if(['Idle', 'Offline'].indexOf(item.Type) !== -1){
          item.Threshold = `${item.Threshold} Miners`
      }
      if(item.Type === 'Offline Time'){
          item.Threshold = `${item.Threshold} Minutes`
      }
      if(item.Type === 'Performance'){
          item.Threshold = `${item.Threshold} %`
      }
      if(item.Send_Alert_Interval !== 'N/A'){
        if(item.Type === 'Status Report'){
          item.Send_Alert_Interval = `${item.Send_Alert_Interval} Days`
        }
        else{
          item.Send_Alert_Interval = `${item.Send_Alert_Interval} Hours`
        }
      }
      return item
    })
    const columns = [
      {field: 'Type', value: 'Type'},
      {field: 'Threshold', value: 'Threshold'},
      {field: 'Send_Alert_Interval', value: 'Send Email Interval'},
      {field: 'Active', value: 'Status'}
    ]
    const editItemsFields = [
      {Name: "Name", PropertyName: 'editName', Value: this.state.editName, Type: "text-disabled", Options: []},
      {Name: "Threshold", PropertyName: 'threshold', Value: this.state.threshold, Type: "int", Options: []},
      {Name: "Interval", PropertyName: 'interval', Value: this.state.interval, Type: this.state.interval == 'N/A'? "text-disabled":"int", Options: []},
      {Name: "Status", PropertyName: 'status', Value: this.state.status, Type: this.state.status == null? "text-disabled":"select", Options: [{text: 'Active', value: 1},{text: 'Not Active', value: 0}]}
    ]
    const emailColumns = [
      {field: 'Email', value: 'Email'},
      {field: 'Region_Name', value: 'Region'},
    ]
    const addEmailFields = [
      {Name: "Email Address", PropertyName: 'newEmail', Value: this.state.newEmail, Type: 'text', Options: []}
    ]
    const emailButtons = userData.level >= 2? ([{class: 'ViewBody-button-delete', onClick: this.onEmailRemove, type: 'Delete', extractKey: 'Email'}]):([])
    const alertSettingButtons = [{class: 'ViewBody-button-edit', onClick: this.onEditItemClick, type: 'Edit', extractKey: 'Type'}]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="AlertsView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={userData.level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
          active="Alerts"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={newData}
            columns={columns}
            buttons={alertSettingButtons}
            list
          />
          <ViewBody
            data={this.props.emailList}
            columns={emailColumns}
            buttons={emailButtons}
            list
            createtext="Add email"
            onCreate={e => this.setState({addEmailModalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.addEmailModalOpen}
          center
          onClose={e => this.setState({addEmailModalOpen: false})}
          styles={modalStyle}
        >
        <FormfieldSection
          title="Add Email"
          fields={addEmailFields}
          addButton={true}
          buttonText="Add"
          onAddClick={this.onEmailAdd}
          onInputChange={e => onInputChange(e, this)}
        />
        </Modal>
        <Modal
          open={this.state.editItemModalOpen}
          center
          onClose={() => this.setState({ editItemModalOpen: false })}
          styles={modalStyle}
        >
        <FormfieldSection
          title="Edit item"
          fields={editItemsFields}
          addButton={true}
          buttonText="Edit"
          onAddClick={this.onItemEdit}
          onInputChange={e => onInputChange(e, this)}
        />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  alertStore: props.alertReducer.settings,
  emailList: props.alertReducer.emailList,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  getAlertSettingsAction,
  putAlertSettingsAction,
  updateClientAction,
  getEmailListAction,
  postToEmailListAction,
  deleteFromEmailListAction
})(AlertsView)

/*

<Modal
  open={this.state.modalOpen}
  center
  onClose={() => this.setState({ modalOpen: false })}
  styles={modalStyle}
>
<FormfieldSection
  title="Edit Thresholds"
  fields={editFields}
  addButton={true}
  buttonText="Edit"
  onAddClick={this.onEdit}
  onInputChange={e => onInputChange(e, this)}
  action="edit_alters"
/>
</Modal>
*/

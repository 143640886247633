import { getFirmwareVersionsConstant } from '../../constants/actions'
import service from '../../services/firmwareService'




export const getFirmwareVersionsAction = (token) => {
    return async(dispatch) => {
        const result = await service.getFirmwareVersions(token)
        dispatch(success(result))
    }
}
const success = (result) =>({
  type: getFirmwareVersionsConstant,
  payload: result
})

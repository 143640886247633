import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import './style.css'

const onLinkClick = (client, region) => {
  const path = window.location.pathname
  if(region == -1){
    return '/'
  }
  if(path == '/'){
    return `/client/${client}/region/${region}`
  }
  let postFixSplit = window.location.pathname.split('/')
  postFixSplit.splice(0,5)
  let postfix = ''
  if(postFixSplit.length > 0){
    if(postFixSplit[0] === 'miner'){
      postfix = '/miners'
    }
    else if (postFixSplit.length > 1 && postFixSplit[0] === 'miners') {
      postfix = `/${postFixSplit.join('/')}`
    }
    else{
      postfix = `/${postFixSplit[0]}`
    }
  }
  return `/client/${client}/region/${region}${postfix}`
}
const click = (customer, region) => {
  if(region == -1){
    alert(`You do not have access to any regions for ${customer} .. redirecting to /`)
  }
}
const CustomersContainer = ({ databases }) => {
  return(
    <div className="customerscontainer-outercontainer">
      <strong>Customers</strong>
      <div className="customerscontainer-innerContainer">
          {databases.map((item, key) => (
            <div className="customerscontainer-item" key={key}>
              <NavLink to={e => onLinkClick(item.Database_Name, 'All')} onClick={e => click(item.Database_Name, 'All')}>
                <span>{item.Database_Name}</span>
                <i className="fa fa-user"/>
              </NavLink>
            </div>
          ))}
      </div>
    </div>
  )
}


export default CustomersContainer

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'
import './style.css';
import { getColor, getColorForPercentage, formatBytes, formatTime } from '../../helper/functions.js'
import { api_down, idle, lightGray, sleep } from '../../helper/colors.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
function returnStatus(status) {
  switch (status) {
    case 'Offline':
      return <p className="adc_minerListItem_status-offline">{status}</p>
      break;
    case 'Online':
      return <p className="adc_minerListItem_status-online">{status}</p>
      break;
    case 'Recovery':
      return <p className="adc_minerListItem_status-recovery">{status}</p>
      break;
    case 'API_DOWN':
      return <p className="adc_minerListItem_status-apiDown">{status}</p>
      break;
    case 'Unknown':
    case 'Missing_PSU':
    case 'Missing_Fan':
    case 'Missing_CTRL_Board':
      return <p className="adc_minerListItem_status-unknown">{status}</p>
      break;
    default:
      return <p className="adc_minerListItem_status-online">{status}</p>
  }
}
const getFlagTypeColor = (props) => {
  switch (props.Flag_Status) {
    case 'Detected':
        return ['#ebdc10', 'black']
    case 'Pending':
        return ['#b3901d', 'black']
    case 'Checked':
        return ['#0459e0', 'white']
    case 'Resolved':
        return ['#2cb31d', 'black']
    case 'Custom':
        return ['#15d4ba', 'black']
    default:
        return ['#2cb31d', 'black']
  }
}
const MinerListItem = ({props, update_time, clientName, subnetId, pod, onSelect, selectedMiners,favoriteMiners, itemId, onClick, alterFav, columns, offlineTime, displayedColumns, region_id, pickFlag=false, colorBy }) => {

  let linkName = pickFlag?`/client/${clientName}/region/${region_id}/miner/${props.MAC_Address}/flag/${props.Miner_Flag_ID}`:`/client/${clientName}/region/${region_id}/miner/${props.MAC_Address}/info`
  const hrefSplit = window.location.href.split('/client')
  var status = update_time > (offlineTime*60)? "offline": "online";
  let fav = false;
  let favId = null;
  for(var i = 0; i < favoriteMiners.length;i++){
    if(favoriteMiners[i].MAC_Address === props.MAC_Address)
    {
      fav = true;
      favId = favoriteMiners[i].ID;
    }
  }
  let star = '';
  if(fav === false){
    star = 'white';
  }
  else{
    star = "orange";
  }
  let backgroundColor = ''
  let color = 'white'
  if(colorBy == 'flagType'){
    let flagTypeColor = getFlagTypeColor(props)
    backgroundColor = flagTypeColor[0]
    color = flagTypeColor[1]
  }
  else{
    if(props.Status === 'API_DOWN'){
      backgroundColor = api_down
    }
    else if (props.Status === 'Idle') {
      backgroundColor = idle
    }
    else if(props.Status === 'Sleep'){
      backgroundColor = sleep
   }
    else if (props.Deleted === 1){
      backgroundColor = lightGray
      //props['Worker_Name'] = 'DELETED MINER'
      //props['Location']    = 'DELETED MINER'
      props['Max_Heat']    = 'DELETED MINER'
    }
    else{
      backgroundColor = getColor(props.Performance, 1, 100, false, .2)
    }
  }
  //<i className='fa fa-star' style={{color: star}}></i>
  return(
    <tr className={"minerList listitems "} style={{backgroundColor: `${backgroundColor}`, color: 'black'}}>
      <td className='minerList' id='star'  onClick={e => alterFav(props.MAC_Address, favId)}>
        <FontAwesomeIcon icon={faStar} color={`${star}`}/>
      </td>
        <td className='minerList'>
          <input type="checkbox" id={itemId} name="select" value={pickFlag? props.Miner_Flag_ID: props.MAC_Address} checked={selectedMiners.indexOf(pickFlag? props.Miner_Flag_ID: props.MAC_Address) !== -1} onChange={(e) => onSelect(pickFlag? props.Miner_Flag_ID: props.MAC_Address, itemId)} />
        </td>
      {displayedColumns.map((key, index) => {
        if(!columns[key])return
        switch (key) {
          case 'Worker_Name':
            return(
              <td key={index} style={{wordWrap: "break-word"}} className="minerList" onClick={e => window.open(`${hrefSplit[0]}${linkName}`, "_blank", 'noopener')}>
                  {!isNaN(props.Maintenance_Count) && props.Maintenance_Count > 0?(
                    <div className="inline-wrench">
                      <i className="fa fa-wrench wrench"></i>
                      <span style={{color: color}}>{props[key]}</span>
                    </div>
                  ):(<span style={{color: color}}>{props[key]}</span>)}
              </td>
            )
          case 'Status':
            return(
              <td key={index} className="minerList" onClick={e => window.open(`${hrefSplit[0]}${linkName}`, "_blank", 'noopener')}>
                  <span style={{color: color}}>{props[key]}</span>
              </td>
            )
          case 'Location':
            return(
              <td key={index} className="minerList" onClick={e => window.open(`${hrefSplit[0]}${linkName}`, "_blank", 'noopener')}>
                  <span style={{color: color}}>{props[key]}</span>
              </td>
            )
          case 'IP_Address':
            return(
              <td key={index} className="minerList" onClick={e => window.open(`http://${props.IP_Address}`, "_blank", 'noopener')}>
                  <span data-tooltip="Link To Miner" data-tooltip-position="top">
                    <span style={{color: color}}>{props[key]}</span>
                  </span>
              </td>
            )
          case 'Missing_Asic':
            return(
              <td key={index} className="minerList" onClick={e => window.open(`${hrefSplit[0]}${linkName}`, "_blank", 'noopener')}>
                  <span style={{color: color}}>{props.Missing_Asic}</span>
              </td>
            )
          case 'Performance':
            return(
              <td key={index} className="minerList" onClick={e => window.open(`${hrefSplit[0]}${linkName}`, "_blank", 'noopener')}>
                <span style={{color: color}}>{round(props[key], 2)}%</span>
              </td>
            )
          case 'Update_Time':
            return(
              <td key={index} className="minerList" onClick={e => window.open(`${hrefSplit[0]}${linkName}`, "_blank", 'noopener')}>
                <span style={{color: color}}>{props.Update_Time_Unit}</span>
              </td>
            )
          case "Uptime":
            return(
              <td key={index} className={key == "Comments"?("minerList adc_minerList-comments"):("minerList") } onClick={e => window.open(`${hrefSplit[0]}${linkName}`, "_blank", 'noopener')}>
                <span style={{color: color}}>{formatTime(props[key])}</span>
              </td>
            )
          case "Speed_One":
          case "Speed_Two":
          case "Speed_Three":
            return(
              <td key={index} className={key == "Comments"?("minerList adc_minerList-comments"):("minerList") } onClick={e => window.open(`${hrefSplit[0]}${linkName}`, "_blank", 'noopener')}>
                <span style={{color: color}}>{formatBytes(props[key])}</span>
              </td>
            )
          default:
            return(
              <td key={index} className={key == "Comments"?("minerList adc_minerList-comments"):("minerList") } onClick={e => window.open(`${hrefSplit[0]}${linkName}`, "_blank", 'noopener')}>
                  {key === 'Avg_Speed'?(
                    <span style={{color: color}}>{props['Avg_Speed_Readable']}</span>
                  ):key==='Total_Speed'?(
                    <span style={{color: color}}>{props['Hash_Readable']}</span>
                  ):(<span style={{color: color}}>{props[key]}</span>)}
              </td>
            )
        }
      })}
    </tr>
  )
}

//<a href={`http://${props.IP_Address}`} target="_blank" >
export default MinerListItem;

MinerListItem.propTypes = {
  props: PropTypes.object.isRequired,
  clientName: PropTypes.string.isRequired,
  subnetId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedMiners: PropTypes.array
}

import React from 'react';
import Rangeslider from '../Rangeslider';
import BootstrapPaging from '../BootstrapPaging'
import InventoryHistoryItem from '../InventoryHistoryItem';

const InvetoryHistory = ({items, currentPage, maxPage, onPageClick, orderBy, acending, onSort, pageSize, changePageSize }) => {
  return(
    <div className="logContainer">
      <div className="tbl-header">
        <table className="uLogTable table-bordered" cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th className="uLogHeader" onClick={e => onSort("Username")}>{ orderBy === "Username"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Username</th>
              <th className="uLogHeader" onClick={e => onSort("Action")}>{ orderBy === "Action"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Action</th>
              <th className="uLogHeader" onClick={e => onSort("Action_Date")}>{ orderBy === "Action_Date"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Date</th>
              <th className="uLogHeader" onClick={e => onSort("Amount")}>{ orderBy === "Amount"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Amount</th>
              <th className="uLogHeader" onClick={e => onSort("Item_Type")}>{ orderBy === "Item_Type"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Type</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="tbl-content">
        <table className="uLogTable" cellPadding="0" cellSpacing="0" border="0">
          <tbody>
          {items.map((item, i) => {
            return <InventoryHistoryItem
                      username={item.Username}
                      date={item.Action_Date}
                      action={item.Action}
                      amount={item.Amount}
                      type={item.Item_Type}
              />
          })}
          </tbody>
        </table>
      </div>
      <BootstrapPaging
        currentPage={currentPage}
        maxPage={maxPage}
        onPageClick={onPageClick}
        pageSize={pageSize}
        changePageSize={changePageSize}
      />

    </div>
  )
}

export default InvetoryHistory

import { getHistoryConstant,
  pushHistoryConstant,
  popHistoryConstant,
  replaceHistoryConstant,
  removeDashActionConstant
} from '../constants/actions'

const locationNameHelper = (path) => {
  if(path.includes("miner")){
    return "Miner"
  }
  if(path.includes("subnet")){
    return "Subnet"
  }
  if(path.includes("pod")){
    return "Pod"
  }
  if(path.includes("house")){
    return "Building"
  }
  if(path.includes("log")){
    return "MinerLog"
  }
  if(path.includes("userLog")){
    return "UserLog"
  }
  if(path.includes("configure")){
    return "Configure"
  }
  if(path.includes("maintenance")){
    return "Maintenance"
  }
  if(path.includes("map")){
    return "Map"
  }
  if(path.includes("client")){
    return "Client"
  }
  if(path.includes("changepassword")){
    return "Profile"
  }
  if(path.includes("admin")){
    return "Admin"
  }
  return "Dashboard"

}

const reducer = (state = {locations: []}, action) => {
  switch (action.type) {
    case getHistoryConstant:
      return state
    case pushHistoryConstant:
      let pushState = JSON.parse(JSON.stringify(state));
      const length = pushState.locations.length;
      if(length > 3){
        pushState.locations = pushState.locations.slice(1, length);
      }
      if(length == 0 || pushState.locations[pushState.locations.length-1].link != action.payload.pathname){
        pushState.locations.push({Name: locationNameHelper(action.payload.pathname), link: action.payload.pathname})
      }
      return pushState;
    case popHistoryConstant:
      let popState = JSON.parse(JSON.stringify(state));
      if(popState.locations.length != 0){
        popState.locations = popState.locations.slice(0, popState.locations.length-1);
      }
      return popState
    case replaceHistoryConstant:
      let replaceState = JSON.parse(JSON.stringify(state));
      //replaceState.refresh = true;
      replaceState.locations = [{Name: 'Dashboard', link: '/'}]
      return replaceState;
    case removeDashActionConstant:
      let removeDashState = JSON.parse(JSON.stringify(state));
      removeDashState.locations = []
      return removeDashState;
    default:
      return state
  }
}

export default reducer;

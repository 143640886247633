import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { Modal } from "react-responsive-modal";
import { onTabHeaderClick, onInputChange } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { getRackProfilesAction } from '../../redux/actions/getRackProfilesAction'
import { postRackProfileAction } from '../../redux/actions/postRackProfileAction'
import { getRackShelfsAction } from '../../redux/actions/getRackShelfsAction'
import { postRackShelfsAction } from '../../redux/actions/postRackShelfsAction'
import { editRackShelfAction } from '../../redux/actions/editRackShelfAction'
import { editRackProfileAction } from '../../redux/actions/editRackProfileAction'
import './style.css'

class MappingConfigureView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      shelfsName: '',
      shelfsNumbers: '',
      shelfsValue: '',
      editShelfName: '',
      editShelfNumber: '',
      editShelfValue: '',
      editProflieName: '',
      editProflieUseShelf: '',
      editProflieLeftToRight: '',
      editProflieBottomToTop: '',
      editProfileID: '',
      profileName: '',
      useShelf: 1,
      leftToRight: 1,
      bottomToTop: 1,
      profileRacks: [],
      regionID: '',
      createShelfModal: false,
      createProfileModal: false,
      editShelfModal: false,
      editProfileModal: false,
      editShelfID: '',
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    this.props.getRackShelfsAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id)
    this.props.getRackProfilesAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id)
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onDelete = (id) => {
    console.log("onDelete ", id)
  }
  onAddShelf = () => {
    let { shelfsName, shelfsNumbers, shelfsValue, regionID } = this.state
    let items = []
    if(this.props.match.params.region_id !== 'All'){
      regionID = this.props.match.params.region_id
    }
    if(shelfsName == ''){
      alert("Name can not be empty")
      return
    }
    if(shelfsNumbers == ''){
      alert("Shelfs can not be empty")
      return
    }
    if(shelfsValue == ''){
      alert("Miners can not be empty")
      return
    }
    else{
      shelfsValue = parseInt(shelfsValue)
    }
    let shelfsSplit = shelfsNumbers.split(',')
    for(var x in shelfsSplit){
      if(shelfsSplit[x].includes('-')){
        let shelfRe = /\d+-\d+/
        let reExec = shelfRe.exec(shelfsSplit[x])
        if(reExec){
          let split = reExec[0].split('-')
          let acending = parseInt(split[0]) < parseInt(split[1])
          if(acending){
            for(var i = parseInt(split[0]); i <= parseInt([split[1]]); i++){
              items.push({Name: `${shelfsName}-${i}`, Shelf_Number: i, Miner_Count: shelfsValue})
            }
          }
          else{
            for(var i = parseInt(split[1]); i <= parseInt([split[0]]); i++){
              items.push({Name: `${shelfsName}-${i}`, Shelf_Number: i, Miner_Count: shelfsValue})
            }
          }
        }
        else{
          alert("Shelfs nubmers is wrongly formated")
          return
        }
      }
      else{
        try {
          const toInt = parseInt(shelfsSplit[x])
          if(!isNaN(toInt)){
            items.push({Name: `${shelfsName}-${toInt}`, Shelf_Number: toInt, Miner_Count: shelfsValue})
          }
          else{
            alert(`Shelfs nubmers is wrongly formated for ${shelfsSplit[x]}`)
          }
        } catch (e) {
          alert("Shelfs nubmers is wrongly formated")
          return
        }
      }
    }
    this.props.postRackShelfsAction(this.props.token, this.props.match.params.name, {Shelfs: items, Region_ID: parseInt(regionID)}, this.props.match.params.region_id)
    this.setState({
      shelfsNumbers: '',
      shelfsValue: '',
      regionID: '',
      createShelfModal: false
    })
  }
  onEditShelfSubmit = () => {
    let { editShelfName, editShelfValue, editShelfNumber, editShelfID } = this.state
    editShelfValue  = parseInt(editShelfValue)
    editShelfNumber = parseInt(editShelfNumber)
    if(editShelfName == ''){
      alert("Name is empty")
      return
    }
    if(Number.isNaN(editShelfValue)){
      alert("Miners is empty")
      return
    }
    if(Number.isNaN(editShelfNumber)){
      alert("Shelf is empty")
      return
    }
    this.props.editRackShelfAction(this.props.token, this.props.match.params.name, {Name: editShelfName, Shelf_Number: editShelfNumber, Miner_Count: editShelfValue}, editShelfID, this.props.match.params.region_id)
    this.setState({
      editShelfName: '',
      editShelfValue: '',
      editShelfNumber: '',
      editShelfID: '',
      editShelfModal: false
    })
  }
  onProfileCreate = () => {
    let { profileName, useShelf, leftToRight, bottomToTop, profileRacks, regionID } = this.state
    if(profileName == ''){
      alert("Name is empty")
      return
    }
    regionID = this.props.match.params.region_id !== 'All'? this.props.match.params.region_id: regionID
    const connections = profileRacks.map((item) => item.value)
    this.props.postRackProfileAction(this.props.token, this.props.match.params.name, {connections, Name: profileName, Use_Shelf: useShelf, Left_To_Right: leftToRight, Bottom_To_Top: bottomToTop, Region_ID: regionID}, this.props.match.params.region_id)
    this.setState({
      profileName: '',
      useShelf: 1,
      leftToRight: 1,
      bottomToTop: 1,
      profileRacks: [],
      regionID: '',
      createProfileModal: false,
    })
  }
  onProfileChange = (e) => {
    if(e.hasOwnProperty('target')){
      this.setState({ [e.target.name]: e.target.value} )
    }
    else{
      this.setState({ profileRacks: e })
    }
  }
  onEditShelf = (id) => {
    const item = this.props.rackStore.shelfs.filter((item) => item.ID == id)
    if(item.length > 0){
      this.setState({
        editShelfName: item[0].Name,
        editShelfNumber: item[0].Shelf_Number,
        editShelfValue: item[0].Miner_Count,
        editShelfModal: true,
        editShelfID: id
      })
    }
  }
  onEditProfile = (id) => {
      const item = this.props.rackStore.profiles.filter((item) => item.ID === id)
      if(item.length > 0){
        this.setState({
          editProfileName: item[0].Name,
          editProflieUseShelf: +(item[0].Use_Shelf === 'true'),
          editProflieLeftToRight: +(item[0].Left_To_Right === 'true'),
          editProflieBottomToTop: +(item[0].Bottom_To_Top === 'true'),
          editProfileModal: true,
          editProfileID: id
        })
      }
  }
  onEditProfileSubmit = () => {
    const { editProfileName, editProflieUseShelf, editProflieBottomToTop, editProflieLeftToRight, editProfileID } = this.state
    if(editProfileName === ''){
      alert("Name is empty")
      return
    }
    this.props.editRackProfileAction(this.props.token, this.props.match.params.name, {Name: editProfileName, Use_Shelf: editProflieUseShelf, Bottom_To_Top: editProflieBottomToTop, Left_To_Right: editProflieLeftToRight}, editProfileID, this.props.match.params.region_id)
    this.setState({
      editProflieName: '',
      editProflieUseShelf: '',
      editProflieLeftToRight: '',
      editProflieBottomToTop: '',
      editProfileID: '',
      editProfileModal: false
    })
  }
  render(){
    const { regionID } = this.state
    const { userData } = this.props.userStore
    const userLevel = userData.level? userData.level: 0
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    const shelfColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Shelf_Number', value: "Shelf Number"},
      {field: 'Miner_Count', value: "Miners in shelf"}
    ]
    const profileColumns = [
      {field: "Name", value: "Name"},
      {field: "Use_Shelf", value: "Use shelfs"},
      {field: "Left_To_Right", value: "Left to right"},
      {field: "Bottom_To_Top", value: "Bottom to top"},
      {field: "Shelfs", value: "Shelfs", func: 'length'}
    ]
    const buttons = [
       {class: 'ViewBody-button-edit', onClick: this.onEditShelf, type: 'Edit', extractKey: 'ID'}
     ]
    const profileButtons = [
      {class: 'ViewBody-button-edit', onClick: this.onEditProfile, type: 'Edit', extractKey: 'ID'}
    ]
    let addRacksFields = []
    let createProfileFields = []
    if(this.props.match.params.region_id === "All"){
      let pickShelfs = this.props.rackStore.shelfs.filter((item) => item.Region_ID == regionID)
      let regions = userData != ''? userData.databases.filter((item) => item.Database_Name === this.props.match.params.name):[]
      regions = regions[0]? regions[0].Regions: []
      addRacksFields = [
        {Name: 'Name', PropertyName: 'shelfsName' , Value: this.state.shelfsName , Type: 'text', Options: []},
        {Name: 'Shelfs', PropertyName: 'shelfsNumbers' , Value: this.state.shelfsNumbers , Type: 'text-range', Options: []},
        {Name: 'Miners', PropertyName: 'shelfsValue' , Value: this.state.shelfsValue , Type: 'int', Options: []},
        {Name: 'Region', PropertyName: 'regionID', Value: this.state.regionID, Type: 'select', Options: [{text: "----------", value: ''}].concat(regions.map((item) => ({text: item.Name, value: item.Region_ID})))},]
      createProfileFields = [
        {Name: 'Name', PropertyName: 'profileName', Value: this.state.profileName, Type: 'text', Options: []},
        {Name: 'Use Shelf', PropertyName: 'useShelf', Value: this.state.useShelf, Type: 'select', Options: [{text: "false", value: 0},{text: "true", value: 1}]},
        {Name: 'Left To right', PropertyName: 'leftToRight', Value: this.state.leftToRight, Type: 'select', Options: [{text: "false", value: 0},{text: "true", value: 1}]},
        {Name: 'Bottom To Top', PropertyName: 'bottomToTop', Value: this.state.bottomToTop, Type: 'select', Options: [{text: "false", value: 0},{text: "true", value: 1}]},
        {Name: 'Region', PropertyName: 'regionID', Value: this.state.regionID, Type: 'select', Options: [{text: "----------", value: ''}].concat(regions.map((item) => ({text: item.Name, value: item.Region_ID})))}
      ]
      if(regionID !== ''){
        createProfileFields.push({Name: 'Racks', PropertyName: 'none', Value: '----------', Type: 'multi-select', Options: pickShelfs.map((item) => ({ label: item.Name, value: item.ID }))})
      }
    }
    else{
      let pickShelfs = this.props.rackStore.shelfs.filter((item) => item.Region_ID == this.props.match.params.region_id)
      addRacksFields = [
        {Name: 'Name', PropertyName: 'shelfsName' , Value: this.state.shelfsName , Type: 'text', Options: []},
        {Name: 'Shelfs', PropertyName: 'shelfsNumbers' , Value: this.state.shelfsNumbers , Type: 'text-range', Options: []},
        {Name: 'Miners', PropertyName: 'shelfsValue' , Value: this.state.shelfsValue , Type: 'int', Options: []}]
      createProfileFields = [
        {Name: 'Name', PropertyName: 'profileName', Value: this.state.profileName, Type: 'text', Options: []},
        {Name: 'Use Shelf', PropertyName: 'useShelf', Value: this.state.useShelf, Type: 'select', Options: [{text: "False", value: 0},{text: "True", value: 1}]},
        {Name: 'Left To right', PropertyName: 'leftToRight', Value: this.state.leftToRight, Type: 'select', Options: [{text: "False", value: 0},{text: "True", value: 1}]},
        {Name: 'Bottom To Top', PropertyName: 'bottomToTop', Value: this.state.bottomToTop, Type: 'select', Options: [{text: "False", value: 0},{text: "True", value: 1}]},
        {Name: 'Racks', PropertyName: 'none', Value: '----------', Type: 'multi-select', Options: pickShelfs.map((item) => ({ label: item.Name, value: item.ID }))}
      ]
    }
    const editShelfFields = [
      {Name: 'Name', PropertyName: 'editShelfName', Value: this.state.editShelfName, Type: 'text', Options: []},
      {Name: 'Shelf', PropertyName: 'editShelfNumber', Value: this.state.editShelfNumber, Type: 'int', Options: []},
      {Name: 'Miners', PropertyName: 'editShelfValue', Value: this.state.editShelfValue, Type: 'int', Options: []}
    ]
    const editProfileFields = [
      {Name: 'Name', PropertyName: 'editProfileName', Value: this.state.editProfileName, Type: 'text', Options: []},
      {Name: 'Use Shelf', PropertyName: 'editProflieUseShelf', Value: this.state.editProflieUseShelf, Type: 'select', Options: [{text: "False", value: 0},{text: "True", value: 1}]},
      {Name: 'Left To Right', PropertyName: 'editProflieLeftToRight', Value: this.state.editProflieLeftToRight, Type: 'select', Options: [{text: "False", value: 0},{text: "True", value: 1}]},
      {Name: 'Bottom To Top', PropertyName: 'editProflieBottomToTop', Value: this.state.editProflieBottomToTop, Type: 'select', Options: [{text: "False", value: 0},{text: "True", value: 1}]}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="MappingConfigureView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={userLevel === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
          active="Mapping"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={this.props.rackStore.shelfs}
            columns={shelfColumns}
            buttons={buttons}
            createtext="New shelfs"
            list
            onCreate={e => this.setState({createShelfModal: true})}
          />
          <ViewBody
            data={this.props.rackStore.profiles}
            columns={profileColumns}
            buttons={profileButtons}
            createtext="New profile"
            list
            onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
            itemClickInfo={{name: 'rackprofile', property: 'ID'}}
            onCreate={e => this.setState({createProfileModal: true})}
          />
        </div>
        <Modal
          open={this.state.createShelfModal}
          center
          onClose={() => this.setState({ createShelfModal: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create new shelfs"
            fields={addRacksFields}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAddShelf}
            onInputChange={e => onInputChange(e, this)}
            action="add_shelf"
          />
        </Modal>
        <Modal
          open={this.state.editShelfModal}
          center
          onClose={() => this.setState({ editShelfModal: false })}
          styles={modalStyle}>
            <FormfieldSection
              title="Edit shelf"
              fields={editShelfFields}
              addButton={true}
              buttonText="Edit"
              onAddClick={this.onEditShelfSubmit}
              onInputChange={e => onInputChange(e, this)}
              action="edit_shelf"
            />
        </Modal>
        <Modal
          open={this.state.createProfileModal}
          center
          onClose={() => this.setState({ createProfileModal: false })}
          styles={modalStyle}>
            <FormfieldSection
              title="Edit shelf"
              fields={createProfileFields}
              addButton={true}
              buttonText="Create"
              onAddClick={this.onProfileCreate}
              onInputChange={this.onProfileChange}
              action="create_profile"
            />
        </Modal>
        <Modal
          open={this.state.editProfileModal}
          center
          onClose={() => this.setState({ editProfileModal: false })}
          styles={modalStyle}>
            <FormfieldSection
              title="Edit shelf"
              fields={editProfileFields}
              addButton={true}
              buttonText="Edit"
              onAddClick={this.onEditProfileSubmit}
              onInputChange={e => onInputChange(e, this)}
              action="edit_profile"
            />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
    token: props.userReducer.result,
    userStore: props.userReducer,
    clientStore: props.clientReducer,
    rackStore: props.rackReducer
})

export default connect(mapStateToProps, {
  updateClientAction,
  getRackShelfsAction,
  postRackShelfsAction,
  editRackShelfAction,
  getRackProfilesAction,
  postRackProfileAction,
  editRackProfileAction
})(MappingConfigureView)

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import './style.css'
import { onTabHeaderClick, onInputChange, filterCheck } from '../../functions'
import { getHouseSubnetConAction } from '../../redux/actions/getHouseSubnetConAction'
import { deleteHouseSubnetConAction } from '../../redux/actions/deleteHouseSubnetConAction';
import { postHouseSubnetConAction } from '../../redux/actions/postHouseSubnetConAction'
import { getSubnetsAction } from '../../redux/actions/getSubnetsAction';
import { configureView } from '../../helper/tabs'

class BuildingDetailsView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      newScanners: [],
      modalOpen: false
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history, `/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/`)
  }
  onBack = () => {
    this.props.history.goBack()
  }
  componentDidMount = () => {
    this.props.getHouseSubnetConAction(this.props.token, this.props.match.params.name, this.props.match.params.id)
    this.props.getSubnetsAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id)
  }
  onDelete = (id) => {
    const body = {House_ID: this.props.match.params.id, Subnet_ID: id}
    window.confirm("Are you sure you want to remove this scanner ?") &&
    this.props.deleteHouseSubnetConAction(body, this.props.token, this.props.match.params.name)
  }
  onScannerAdd = () => {
    let { newScanners } = this.state
    newScanners = newScanners.map((item) => ({Subnet_ID: item.value}))
    this.props.postHouseSubnetConAction(newScanners, this.props.match.params.id, this.props.token, this.props.match.params.name)
    this.setState({
      newScanners: [],
      modalOpen: false
    })
  }
  onAddScannersChange = (list) => {
    this.setState({newScanners: list})
  }
  render(){
    const { level } = this.props.userData
    const columns = [{field: "Subnet_Name", value: "Scanner Name"}]
    const buttons = level >= 2?([{class: 'ViewBody-button-delete', onClick: this.onDelete, type: 'Delete', extractKey: 'ID'}]):([])
    const scannerPick = this.props.scannerStore.subnets.filter((item) => !filterCheck(this.props.connections.connections, item, 'ID'))
    const addScannersField = [
      {Name: 'Pick Pools', PropertyName: 'none', Value: '----------', Type: 'multi-select', Options: scannerPick.map((item, i) => ({label: item.Name, value: item.ID}))}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    const pannelColumns = [
      {field: 'Name', Value: 'Name'},
      {field: 'Value', Value: 'Value'}]
    let pannelData = [
      {Name: 'Building', Value: this.props.connections.house},
      {Name: 'Scanners', Value: this.props.connections.connections.length}
    ]
    return(
      <div className="BuildingDetailsView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={configureView}
          active="Buildings"
          onClick={this.onClick}
        />
        <ViewBody
          data={pannelData}
          columns={pannelColumns}
          pannel
          onBack={this.onBack}
        />
        <ViewBody
          data={this.props.connections.connections}
          columns={columns}
          buttons={buttons}
          createtext="Add scanner"
          list
          onCreate={e => this.setState({modalOpen: true})}
        />
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
        <FormfieldSection
          title="Add scanners"
          fields={addScannersField}
          addButton={true}
          buttonText="Add"
          onAddClick={this.onScannerAdd}
          onInputChange={this.onAddScannersChange}
          action="add_scanners"
        />
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  connections: props.houseSubnetReducer,
  scannerStore: props.subnetReducer
})

export default connect(mapStateToProps, {
  getHouseSubnetConAction,
  deleteHouseSubnetConAction,
  getSubnetsAction,
  postHouseSubnetConAction
})(BuildingDetailsView)

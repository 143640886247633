import {
  getSubnetsConstant,
  clearSubnetsConstant,
  postCustomerScannerConstant,
  patchCustomerScannerConstant
 } from '../constants/actions'
const defaultState = {
  ok: false,
  api_version: 1.1,
  msg: '',
  subnets: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case clearSubnetsConstant: {
      return {
        ok: false,
        msg: '',
        subnets: []
      }
    }
    case postCustomerScannerConstant:
    case patchCustomerScannerConstant:
    case getSubnetsConstant:
        const newState = {
          ok: action.payload.ok,
          msg: action.payload.ok,
          subnets: action.payload.data
        }
        return newState;
      break;
    default:
      return state;
  }
}

import React from 'react';
import './style.css';
const MinerLogListItem = ({ description, timestamp, type, location, client, item, onClick }) => {
  const link = `/client/${client}/subnet/${item.Subnet_ID}/miner/${item.MAC_Address}`
  let date, time;
  if (timestamp !== undefined) {
    date = timestamp.split('T');
    time = date[1].split('.');
  }
  else {
    date = ['', ''];
    time = ['', ''];
  }
  return (

    <tr className="listitems" onClick={e => onClick(link)}>
      <td className="minerListRowChild">
        {date[0]}, {time[0]}
      </td>
      <td className="minerListRowChild">
        {location}
      </td>
      <td className="minerListRowChild">
        {type}
      </td>
      <td className="minerListRowChild">
        {description}
      </td>
    </tr>

  )
}
export default MinerLogListItem;
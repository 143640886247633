import { updateResponsePropertyConstant } from '../constants/actions'

export const updateResponsePropertyAction = (data) => {
  return dispatch => {
    dispatch(success(data))
  }
}



const success = (data) => ({
  type: updateResponsePropertyConstant,
  payload: data
})

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange, filterCheck  } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { deleteTypeAction } from '../../redux/actions/deleteTypeAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { addSubnetFlagTypeConnectionAction } from '../../redux/actions/addSubnetFlagTypeConnectionAction'
import { deleteSubnetFlagTypeConnectionAction } from '../../redux/actions/deleteSubnetFlagTypeConnectionAction'
import { getFlagTypeInfoByIDAction } from '../../redux/actions/getFlagTypeInfoByIDAction'
import { getSubnetsConnectionsByMinerFlagIDAction } from '../../redux/actions/getSubnetsConnectionsByMinerFlagIDAction'
import { getSubnetsAction } from '../../redux/actions/getSubnetsAction';
import './style.css'

class FlagConfigDetailView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      modalOpen: false,
      addScanners: [],
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
      this.props.getFlagTypeInfoByIDAction(this.props.match.params.flag_id, this.props.token, this.props.match.params.name, this.props.match.params.region_id)
      this.props.getSubnetsAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id);
      this.props.getSubnetsConnectionsByMinerFlagIDAction(this.props.match.params.flag_id, this.props.token, this.props.match.params.name, this.props.match.params.region_id)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history, `/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/`)
  }
  onAdd = () => {
    this.setState({
      modalOpen: true
    })
  }
  onBack = () => {
    this.props.history.goBack()
  }
  onAddScannersChange = (list) => {
    this.setState({
      addScanners: list
    })
  }
  onScannerAdd = () => {
    const { addScanners } = this.state
    const newList = addScanners.map((item) => item.value)
    const body = {
      Subnet_IDs: newList
    }
    this.props.addSubnetFlagTypeConnectionAction(this.props.match.params.flag_id, body, this.props.token, this.props.match.params.name, this.props.match.params.region_id)
    this.setState({
      addScanners: [],
      modalOpen: false
    })
  }
  onDelete = (id) => {
    const body = {
      Subnet_ID: id
    }
    window.confirm("Are you sure you want to remove this scanner ?") &&
    this.props.deleteSubnetFlagTypeConnectionAction(this.props.match.params.flag_id, body, this.props.token, this.props.match.params.name, this.props.match.params.region_id)
  }
  render(){
    const { level } = this.props.userData
    const databaseTier = this.props.userData.databases.length > 0? this.props.userData.databases[0].Tier: 0
    const pannelColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Value', value: 'Value'}
    ]
    const buttons = level >= 2?([{class: 'ViewBody-button-delete', onClick: this.onDelete, type: 'Delete', extractKey: 'ID'}]):([])
    const pannelData = this.props.flagStore.flag_types.length > 0?(
      [{Name: "Name", Value: this.props.flagStore.flag_types[0].Name},
       {Name: "Severity", Value: this.props.flagStore.flag_types[0].Severity_Description},
       {Name: "Scanners", Value: this.props.flagStore.flag_types[0].Scanners}]
    ):(
      [{Name: "Name", Value: 'N/A'},
       {Name: "Severity", Value: 'N/A'},
       {Name: "Scanners", Value: 'N/A'}]
    )
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
     const pickScanners = this.props.scannerStore.subnets.filter((item) => !filterCheck(this.props.flagStore.flag_subnet_connections, item, 'ID'))
     const addScannersField = [
       {Name: 'Pick Scanners', PropertyName: 'none', Value: this.state.addScanners, Type: 'multi-select', Options: pickScanners.map((item ,i) => ({label: item.Name, value: item.ID}))}
     ]
     const scannersColumns = [
       {field: 'ID', value: "ID"},
       {field: 'Name', value: "Name"}
     ]
    return(
      <div className="AlertsView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
          active="Flag Setting"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={pannelData}
            columns={pannelColumns}
            pannel
            onBack={this.onBack}
          />
          <ViewBody
            data={this.props.flagStore.flag_subnet_connections}
            columns={scannersColumns}
            list
            createtext="Add scanners"
            onCreate={this.onAdd}
            buttons={buttons}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add scanners"
            fields={addScannersField}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onScannerAdd}
            onInputChange={this.onAddScannersChange}
            action="add_scanners"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  clientStore: props.clientReducer,
  flagStore: props.flagReducer,
  scannerStore: props.subnetReducer
})

export default connect(mapStateToProps, {
  updateClientAction,
  addSubnetFlagTypeConnectionAction,
  deleteSubnetFlagTypeConnectionAction,
  getFlagTypeInfoByIDAction,
  getSubnetsConnectionsByMinerFlagIDAction,
  getSubnetsAction
})(FlagConfigDetailView)

import React from 'react';
import './style.css'
import { NavLink } from 'react-router-dom'
const InformationTable = ({ data, type, openBuildings, onClick, onPodClick, styles }) => {
  return(
    <div className="informationTable-div" style={styles}>
      <table className="informationTable" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th className="informationTable-th">{type}</th>
            <th className="informationTable-th">Online Miners</th>
            <th className="informationTable-th">Offline Miners</th>
            <th className="informationTable-th">Offline 24 Hours</th>
            <th className="informationTable-th">Offline 24+ Hours</th>
            <th className="informationTable-th">ZeroHash Miners</th>
            <th className="informationTable-th">Total Hashrate</th>
            <th className="informationTable-th">Performance</th>
            <th className="informationTable-th">Estimate Power</th>
          </tr>
        </thead>
        <tbody>
        {data?(data.map((item, key) =>(
          <>
            <tr onClick={() => onClick(key)}>
              <td className="informationTable-td">
                {item.Name}
              </td>
              <td className="informationTable-td">
              {item.totalMiners - item.minersOffline}
              </td>
              {item.minersOffline > 0?(
                <td className="informationTable-td red-color">
                  {item.minersOffline}
                </td>
              ):(
                <td className="informationTable-td">
                  {item.minersOffline}
                </td>
              )}
              {item.offlineMiners24Hours > 0?(
                <td className="informationTable-td red-color">
                  {item.offlineMiners24Hours}
                </td>
              ):(
                <td className="informationTable-td">
                  {item.offlineMiners24Hours}
                </td>
              )}
              {item.offlineMiners24HoursPlus > 0?(
                <td className="informationTable-td red-color">
                  {item.offlineMiners24HoursPlus}
                </td>
              ):(
                <td className="informationTable-td">
                  {item.offlineMiners24HoursPlus}
                </td>
              )}
              <td className="informationTable-td">
              {item.zeroHashMiners}
              </td>
              <td className="informationTable-td">
              {item.Hash_Readable}
              </td>
              <td className="informationTable-td">
              {item.performance}
              </td>
              <td className="informationTable-td">
              {item.estPower}
              </td>
            </tr>
            {type === 'Building' && openBuildings && openBuildings.indexOf(key) !== -1?(
              <>
                {item.pods.map((pod) => (
                  <tr onClick={() => onPodClick(pod.ID)}>
                    <td className="informationTable-pod-td">
                      {pod.Name}
                    </td>
                    <td className="informationTable-pod-td">
                    {pod.totalMiners - pod.minersOffline}
                    </td>
                    {pod.minersOffline > 0?(
                      <td className="informationTable-pod-td red-color">
                        {pod.minersOffline}
                      </td>
                    ):(
                      <td className="informationTable-pod-td">
                        {pod.minersOffline}
                      </td>
                    )}
                    {pod.offlineMiners24Hours > 0?(
                      <td className="informationTable-pod-td red-color">
                        {pod.offlineMiners24Hours}
                      </td>
                    ):(
                      <td className="informationTable-pod-td">
                        {pod.offlineMiners24Hours}
                      </td>
                    )}
                    {item.offlineMiners24HoursPlus > 0?(
                      <td className="informationTable-pod-td red-color">
                        {pod.offlineMiners24HoursPlus}
                      </td>
                    ):(
                      <td className="informationTable-pod-td">
                        {pod.offlineMiners24HoursPlus}
                      </td>
                    )}
                    <td className="informationTable-pod-td">
                    {pod.zeroHashMiners}
                    </td>
                    <td className="informationTable-pod-td">
                    {pod.Hash_Readable}
                    </td>
                    <td className="informationTable-pod-td">
                    {pod.performance}
                    </td>
                    <td className="informationTable-pod-td">
                    {pod.estPower}
                    </td>
                  </tr>
                ))}
              </>
            ):(<></>)}
          </>
        ))):(<></>)}
        </tbody>
      </table>
    </div>
  )
}
export default InformationTable

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import './style.css'
import "react-datepicker/dist/react-datepicker.css";
const FarmReportForm = ({hashrate, online, offline, alerts, maintenance, comments, startDate, endDate, onFormSubmit, handleStartDate, handleEndDate, handleDateChange, customers, onCustomerChange, onCheckChange}) => {
  return(
    <div className="farmReport-div">
        <div className="datePicker-div">
          <DatePicker
              placeholderText="Click to select start date"
              selected={startDate}
              onChange={date => handleStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
            />
            <DatePicker
              placeholderText="Click to select end date"
              selected={endDate}
              onChange={date => handleEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
            />
        </div>
        <div>
          <select className="customerSelect" name="reportCustomer" placeholder="Pick Customer" onInput={e => onCustomerChange(e)}>
            <option value="" selected>select customer</option>
          {
            customers.map((item, key) => <option key={key}>{item.client}</option>)
          }
          </select>
        </div>
        <div className="form-check">
          <input type="checkbox" class="form-check-input" name="report_hashrate" id="report_hashrate" value={hashrate} onClick={e => onCheckChange(e)}/>
          <label className="form-check-label" for="report_hashrate" for="report_hashrate">Hashrate</label>
          <input type="checkbox" class="form-check-input" name="report_online" id="report_online" value={online} onClick={e => onCheckChange(e)}/>
          <label className="form-check-label" for="report_online">Online Miners</label>
          <input type="checkbox" class="form-check-input" name="report_offline" id="report_offline" value={offline} onClick={e => onCheckChange(e)}/>
          <label className="form-check-label" for="report_offline">Offline Miners</label>
          <input type="checkbox" class="form-check-input" name="report_alerts" id="report_alerts" value={alerts} onClick={e => onCheckChange(e)}/>
          <label className="form-check-label" for="report_alerts">Alerts</label>
          <input type="checkbox" class="form-check-input" name="report_maintenance" id="report_maintenance-log" value={maintenance} onClick={e => onCheckChange(e)}/>
          <label className="form-check-label" for="report_maintenance-log">Maintenance Log</label>
          <input type="checkbox" class="form-check-input" name="report_comments" id="report_miner-comment" value={comments} onClick={e => onCheckChange(e)}/>
          <label className="form-check-label" for="report_miner-comment">Miner Comments</label>
        </div>
        <button className="btn btn-primary" onClick={e => onFormSubmit()}>Generate Report</button>
    </div>
  )
}

export default FarmReportForm;
/*

<DatePicker
  placeholderText="Click to select a date range"
  dateFormat="dd/MM/yyyy"
  selected={startDate}
  onChange={handleDateChange}
  startDate={startDate}
  endDate={endDate}
  selectsRange
  monthsShown={2}
  shouldCloseOnSelect={false}
/>
*/

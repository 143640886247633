import {
    getFirmwareVersionsConstant,
    getFirmwareVendorsConstant,
    postFirmwareVendorsConstant,
    postFirmwareVersionsConstant,
    getFirmwareVersionsByVendorsIDConstant,
} from '../constants/actions'


const defaultState = {
    ok: false,
    vendors: [],
    versions: [],
}


export default(state=defaultState, action) => {
    switch(action.type){
        case postFirmwareVersionsConstant:
        case getFirmwareVersionsByVendorsIDConstant:
        case getFirmwareVersionsConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    versions: action.payload.data
                }
            }
        case getFirmwareVendorsConstant:
        case postFirmwareVendorsConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    vendors: action.payload.data
                }
            }
        default: 
            return state
    }
}
import { getUserLogsConstant, getMinersByUserLog, sortUserLogsConstant, getUserLogsByMacConstant } from '../constants/actions';
const defaultState = {
  ok: false,
  msg: '',
  api_version: 1.1,
  max_page: 1,
  current_page: 1,
  page_size: 1,
  userLogs: [],
  miners: []
}

export default(state = defaultState, action) => {
  switch (action.type) {
    case getUserLogsByMacConstant:
    case getUserLogsConstant:
      if(action.payload.ok){
        return action.payload
      }
      else{
        const newState = {
          ok: false,
          msg: action.payload.msg,
          userLogs: state.userLogs,
          miners: [],max_page: 1,
          current_page: 1,
          page_size: 1
        }
        return newState;
      }
    case getMinersByUserLog:
      const newState = {
        ok: action.payload.ok,
        msg: action.payload.msg,
        userLogs: state.userLogs,
        miners: action.payload.miners,
        max_page: state.max_page,
        current_page: state.current_page,
        page_size: state.page_size,
      }
      return newState;
    case sortUserLogsConstant:
      let returnState = JSON.parse(JSON.stringify(state))
      returnState.userLogs.sort((a, b) => action.payload.acending?(a[action.payload.property] > b[action.payload.property]) ? 1: -1: (a[action.payload.property] < b[action.payload.property])? 1: -1);
      return returnState;
    default:
      return state;
  }
}

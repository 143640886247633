import React from 'react';
import './style.css';
import Section from '../Section'
import ProfileCard from '../ProfileCard'

const ConfigProfileSection = ({ profiles, pools, profilePools, profileName, onAdd, removeProfile, onMinimize, showBody, removeItem, addItem, showAdd, onSave, onCancel, onInputChange, privilegesLevel,  insertPrivileges, deletePrivileges, addNewPool, pickNewPool, removeNewPool }) => {
  return(
    <Section
      icon={<i className="fa fa-cog" aria-hidden="true"/>}
      name="Profile Section"
      onMinimize={onMinimize}
      showBody={showBody}
      body={
        <>
          <div className="config_profile_section_body">
            {profiles.map((item, i) => (
              <ProfileCard
                id={item.ID}
                title={item.Name}
                pools={item.pools}
                addItem={addItem}
                removeItem={removeItem}
                removeProfile={removeProfile}
                privilegesLevel={privilegesLevel}
                deletePrivileges={deletePrivileges}
                insertPrivileges={insertPrivileges}
                key={i}
              />
            ))}
          </div>
          {showAdd?(
            <div className="adc_configPodSection-outerDiv">
              <div className="adc_configPodSection-addItemsDiv">
                <span>Name</span>
                <input name="profileName" value={profileName} className="adc_configBuildingSection-input" placeholder="Enter a profile name" onChange={e => onInputChange(e)}/>
              </div>
              {profilePools.map((item, i) => (
                <div className="adc_configPodSection-addItemsDiv">
                  <span>Pool{i+1}</span>
                  <select name="selectNewPool" className="adc_configBuildingSection-input" onChange={e => pickNewPool(e, i)}>
                    {pools.map((pool) => (
                      pool.ID == item?(
                        <option value={pool.ID} selected>{pool.URL}</option>
                      ):(<option value={pool.ID}>{pool.URL}</option>)
                    ))}
                  </select>
                  <button type="button" className="adc_deviceMapping-button" onClick={e => removeNewPool(i)}>
                    <i className="fa fa-close"></i>
                  </button>
                </div>
              ))}
              <button type="button" className="adc_deviceMapping-button btn-success conifg_profile_section_add_pool" onClick={e => addNewPool()}>
                Add Pool
              </button>
              <div className="adc_configPodSection-addItemsSaveDiv">
                <button type="button" className="adc_deviceMapping-button btn-danger" onClick={e => onCancel(e)}>
                  Cancel
                </button>
                <button type="button" className="adc_deviceMapping-button btn-success" onClick={e => onSave(e)}>
                  Save
                </button>
              </div>
            </div>
          ):(
            privilegesLevel >= insertPrivileges?(
              <div>
                <button type="button" className="adc_deviceMapping-button" onClick={e => onAdd(e)}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            ):(<></>)
          )}
        </>
      }
    />
  )
}

export default ConfigProfileSection

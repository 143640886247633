import React from 'react';
import './style.css';
const UserLogListItem = ({ amount, timestamp, command, user, onClick }) => {
  let date, time;
      if(timestamp !== undefined){
        date = timestamp.split('T');
        time = date[1].split('.');
      }
      else{
        date = ['',''];
        time = ['',''];
      }
  return (
    <tr className="listitems" onClick={e => onClick(timestamp)}>
      <td className="minerListRowChild">
        {date[0]}, {time[0]}
      </td>
      <td className="minerListRowChild">
        {user}
      </td>
      <td className="minerListRowChild">
        {command}
      </td>
      <td className="minerListRowChild">
        {amount}
      </td>
    </tr>
  )
}
export default UserLogListItem;

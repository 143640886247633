import { adjustSystemPrivilegesConstant } from '../constants/actions'
import service from '../services//adminService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const adjustSystemPrivilegesAction = (token, body) => {
  return async(dispatch) => {
    const result = await service.adjustSystemPrivileges(token, body)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result, body.username, body.level))
  }
}
const success = (result, user, level) => ({
  type: adjustSystemPrivilegesConstant,
  payload: { result, user, level }
})

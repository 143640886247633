import { rootRoute } from "../constants/routes";

const handleRequest = (url, options, paged=false) => {
    return fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
            if(paged){
                return {
                    ok: true,
                    msg: "successful",
                    data: data.success.data,
                    max_page: data.success.max_page,
                    current_page: data.success.current_page,
                    page_size: data.success.page_size,
                    total_data: data.success.totalData
                }
            }
            return {
                ok: true,
                msg: "successful",
                data: data.success.data
            }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }


  const service = () => {
    const getPowerCutOrders = (token, pageSize=25, pageNumber=1, orderBy="ID", acending=true, searchValue='') => {
        var requestOptions = {
            method: 'GET',
            headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
            }
        }
        const url = `${rootRoute}/powercut/orders?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}`
        return handleRequest(url, requestOptions, true)
    }
    const patchPowerCutOrderStatus = (token, orderId, status,  pageSize=25, pageNumber=1, orderBy="ID", acending=true, searchValue='', returnOrderID=true) => {
      var requestOptions = {
        method: 'PATCH',
        headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
        }
      }
      const url = `${rootRoute}/powercut/order/${orderId}/status/${status}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}&returnOrderId=${returnOrderID}`
      return handleRequest(url, requestOptions, !returnOrderID)
    }
    const postPowerCutOrder = (token, body,  pageSize=25, pageNumber=1, orderBy="ID", acending=true, searchValue='') => { 
      var requestOptions = {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      const url = `${rootRoute}/powercut/order?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}`
      return handleRequest(url, requestOptions)
    }
    const getPowerCutOrderByOrderID = (token, orderId) => {
      ///api/powercut/order/:orderId
      var requestOptions = {
        method: 'GET',
        headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
        } 
      }
      const url = `${rootRoute}/powercut/order/${orderId}`
      return handleRequest(url, requestOptions)
    }
    const getPowerCutOrderDatabasesByOrderID = (token, orderId) => {
      ///api/powercut/order/:orderId/databases
      var requestOptions = {
        method: 'GET',
        headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
        } 
      }
      const url = `${rootRoute}/powercut/order/${orderId}/databases`
      return handleRequest(url, requestOptions)
    }
    const getPowerCutOrderMinersByOrderID = (token, orderId, pageNumber, pageSize, orderBy, acending, statusList, databases) => {
      var requestOptions = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        } 
      }
      const url = `${rootRoute}/powercut/order/${orderId}/miners/?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&acending=${acending}${statusList}${databases}`
      return handleRequest(url, requestOptions, true)
    }
    const getPowerCutOrderActionsByOrderID = (token, orderId) => {
      var requestOptions = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        } 
      }
      const url = `${rootRoute}/powercut/order/${orderId}/actions`
      return handleRequest(url, requestOptions)
    }
    return {
        getPowerCutOrders,
        patchPowerCutOrderStatus,
        postPowerCutOrder,
        getPowerCutOrderByOrderID,
        getPowerCutOrderDatabasesByOrderID,
        getPowerCutOrderMinersByOrderID,
        getPowerCutOrderActionsByOrderID
    }

}

export default service()
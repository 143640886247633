import React from 'react'
import Select from './select'
import React_Select from 'react-select'
import './style.css'
import {  blueGray, lightGray, darkBoarder } from './colors.js'
export const colourStyles = {
  container: styles => ({
    ...styles,
    width: '100%'
  }),
  control: styles => ({
    ...styles,
    backgroundColor: blueGray,
    borderColor: darkBoarder,
    color: lightGray,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: lightGray,
      cursor: isDisabled ? 'not-allowed' : 'default'
    }
  }
};


export const updateQueryString = (keyPair, queryList) => {
  /*
    {key: value}
    {key: status, value: detected} would be filter
    {status: detected} would be add
  */
  const params    = new URLSearchParams(window.location.search)
  const newParams = new URLSearchParams()
  const item      = params.get(keyPair['key'])
  const filteredList = queryList.filter((item) => item.getKey === keyPair.key)
  let newValue = ''
  let shouldRemoveKey = false
  if(filteredList.length > 0){
    const item = filteredList[0]
    switch (item.valueType) {
      case 'list':
        shouldRemoveKey = keyPair['value'].length === 0
        newValue = keyPair['value'].map((item) => item.value).join(',')
        break;
      case 'number':
      case 'text':
        shouldRemoveKey = keyPair['value'] === ""
        newValue        = keyPair['value']
        break;
      case 'bool':
        console.log(keyPair)
      default:
        newValue        = keyPair['value']
    }
    let keyFound  = false
    params.forEach((value, key) => {
      if(key === keyPair['key'] && shouldRemoveKey){
        keyFound = true
      }
      else{
        newParams.set(key, value)
      }
    });
    if(!keyFound){
      newParams.set(keyPair['key'], newValue)
    }
    const newString = newParams.toString()
    window.history.replaceState(null, document.title, `?${newString}`)
  }
}

export const urlQueryHandler = (params, queryList, filter) => {
  for(var i in queryList){
    const query = params.get(queryList[i]['getKey'])
    if(!query){
      continue
    }
    const queryArray = query.split(',')
    for(var x in queryArray){
      const obj = {label: queryArray[x], value: queryArray[x], property: queryList[i]['filterValue']}
      if(query){
        switch (queryList[i]['valueType']) {
          case 'list':
            filter[queryList[i]['filterValue']].push(obj)
            break;
          case 'bool':
            filter[queryList[i]['filterValue']] = queryList[i]['boolObj'][queryArray[x]]
            break
          case 'number':
            filter[queryList[i]['filterValue']] = parseInt(query)
            break
          default:
            filter[queryList[i]['filterValue']] = query
        }
      }
    }

  }
  return filter
}

export const commaSeparatorFormat = (value) => {
  if(typeof value == 'number'){
    value = value.toLocaleString()
  }
  return value
}
export const userGroupSwitch = (level) => {
  switch (level) {
    default:
    case 0:
      return "Select Users"
    case 1:
      return "Insert/Update Users"
    case 2:
      return "Administrator"
  }
}
export const formatTime = (seconds) => {
  seconds = Number(seconds)
  var d = Math.floor(seconds / (3600*24))
  var h = Math.floor(seconds % (3600*24) / 3600)
  var m = Math.floor(seconds % 3600 / 60)
  var s = Math.floor(seconds % 60)

  var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : ""
  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : ""
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : ""
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
  return dDisplay + hDisplay + mDisplay + sDisplay
}
export const formatBytes = (bytes, decimals = 2)  => {
  if (bytes === 0) return '0 H';
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  let sizes = ['H', 'KH', 'MH', 'GH', 'TH', 'PH', 'EH', 'ZH', 'YH'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export const onInputChange = (e, obj) => {
  obj.setState({[e.target.name]: e.target.value})
}
export const onTabHeaderClick = (tab, history, extra = '') => {
    const route = tab.split(" ").join('').toLowerCase()
    history.push(`${extra}${route}`)
}
export const filterCheck = (array, item, property) => {
  for(var i in array){
    if(array[i][property] === item[property])return true
  }
  return false
}
var percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ];
export const getColorForPercentage = (pct) => {
    for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
};
export const getColor = (i, min, max, reverse, brightness = .5) => {
  var ratio = i;
  if (min> 0 || max < 1) {
      if (i < min) {
          ratio = 0;
      } else if (i > max) {
          ratio = 1;
      } else {
          var range = max - min;
          ratio = (i-min) / range;
      }
  }

  // as the function expects a value between 0 and 1, and red = 0° and green = 120°
  // we convert the input to the appropriate hue value\
  ratio = reverse ?1 - ratio: ratio;
  var hue = reverse ?ratio * 1.75 / 3.60: ratio * 1 / 3.6;
   // we convert hsl to rgb (saturation 100%, lightness 50%)
   var rgb = hslToRgb(hue, 1, brightness);
   // we format to css value and return
   return 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
}
export const hslToRgb = (h, s, l) =>{
  var r, g, b;

  if(s === 0){
      r = g = b = l; // achromatic
  }else{
      var hue2rgb = function hue2rgb(p, q, t){
          if(t < 0) t += 1;
          if(t > 1) t -= 1;
          if(t < 1/6) return p + (q - p) * 6 * t;
          if(t < 1/2) return q;
          if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
          return p;
      }

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}
export const convertFields = (fields, onInputChange, reverse=false) => {
  let returnValue = []
  fields.map((item, i) => {
    switch (item.Type) {
      default:
      case 'toggle-switch':
        returnValue.push(
          <div className={`form-column ${reverse?('reverse'):('')}`}>
            <label>{item.Name}</label>
            <label class="switch">
              {item.Value?(
                <input type="checkbox" checked onChange={e => onInputChange(e, item.PropertyName)}/>
              ):(
                <input type="checkbox" onChange={e => onInputChange(e, item.PropertyName)}/>
              )}
              <span class="slider round"></span>
            </label>
          </div>
        )
      break
      case 'text':
          returnValue.push(
            <div className="formfieldSection_form-group" key={i}>
              <label className="">{item.Name}</label>
              <input type="text" value={item.Value} className="formfieldSection_form-group_item" name={`${item.PropertyName}`} onChange={e => onInputChange(e, item.PropertyName)} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'text-column':
          returnValue.push(
            <div className={`form-column ${reverse?('reverse'):('')}`} key={i}>
              <label className="">{item.Name}</label>
              <input type="text" value={item.Value} className="form-column-item" name={`${item.PropertyName}`} id={item.Id?(item.Id):('')} onChange={e => onInputChange(e, item.PropertyName)} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'text-column-empty-check':
          returnValue.push(
            <div className="form-column-flex">
              <div className={`form-column ${reverse?('reverse'):('')}`} key={i}>
                <label className="">{item.Name}</label>
                {item.Options.hasOwnProperty('empty') && item.Options.empty.value?(
                  <input type="text" value={item.Value} className="form-column-item-side-disabled" name={`${item.PropertyName}`} id={item.Id?(item.Id):('')} disabled onChange={e => onInputChange(e, item.PropertyName)} placeholder={item.Name}/>
                ):(
                  <input type="text" value={item.Value} className="form-column-item-side" name={`${item.PropertyName}`} id={item.Id?(item.Id):('')} onChange={e => onInputChange(e, item.PropertyName)} placeholder={item.Name}/>
                )}
              </div>
              <div className={`form-column ${reverse?('reverse'):('')}`}>
                <label>Empty</label>
                <label class="switch">
                  {item.Options.hasOwnProperty('empty') && item.Options.empty.value?(
                    <input type="checkbox" checked onChange={e => onInputChange(e, item.Options.empty.label)}/>
                  ):(
                    <input type="checkbox" onChange={e => onInputChange(e, item.Options.empty.label)}/>
                  )}
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          )
      break;
      case 'range':
          returnValue.push(
            <div className={`form-column ${reverse?('reverse'):('')}`} key={i}>
              <label className="">{item.Name}</label>
              <div className="form-range-div">
                <input type="number" step="0.01" value={item.Value} className="form-range-item" name={`${item.PropertyName}`} id={item.Id?(item.Id):('')} onChange={e => onInputChange(e, item.PropertyName)} placeholder="From"/>
                <input type="number" step="0.01" value={item.PropertyTwoValue} className="form-range-item" name={`${item.PropertyTwoName}`} id={item.IdTwo?(item.IdTwo):('')} onChange={e => onInputChange(e, item.PropertyTwoName)} placeholder="To"/>
              </div>
            </div>
          )
      break;
      case 'textarea':
        returnValue.push(
          <div className="formfieldSection_form-group" key={i}>
            <label className="">{item.Name}</label>
            <textarea rows="3" value={item.Value} className="formfieldSection_form-group_item-textarea" name={`${item.PropertyName}`} onChange={e => onInputChange(e, item.PropertyName)} placeholder={item.Name}/>
          </div>
        )
      break;
      case 'text-disabled':
          returnValue.push(
            <div className="formfieldSection_form-group" key={i}>
              <label className="">{item.Name}</label>
              <input type="text" disabled value={item.Value} className="formfieldSection_form-group_item" name={`${item.PropertyName}`} onChange={e => onInputChange(e, item.PropertyName)} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'email':
          returnValue.push(
            <div className="formfieldSection_form-group" key={i}>
              <label className="">{item.Name}</label>
              <input type="email" value={item.Value} className="formfieldSection_form-group_item" onChange={e => onInputChange(e, item.PropertyName)} name={`${item.PropertyName}`} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'int':
          returnValue.push(
            <div className="formfieldSection_form-group" key={i}>
              <label className="">{item.Name}</label>
              <input type="number" value={item.Value}  className="formfieldSection_form-group_item" name={`${item.PropertyName}`} onChange={e => onInputChange(e, item.PropertyName)} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'float':
          returnValue.push(
            <div className="formfieldSection_form-group" key={i}>
              <label className="">{item.Name}</label>
              <input type="number" value={item.Value}  step="0.01" className="formfieldSection_form-group_item" name={`${item.PropertyName}`} onChange={e => onInputChange(e, item.PropertyName)} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'multi-select':
        returnValue.push(
            <div className={`react-select-group ${reverse?('reverse'):('')}`} key={i}>
              <label className="">{item.Name}</label>
              <React_Select
                options={item.Options}
                value={item.Value}
                onChange={e => onInputChange(e, item.PropertyName)}
                isMulti
                isSearchable={false}
                styles={colourStyles}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                  ...theme.colors,
                    primary25: 'black',
                    neutral0: blueGray,
                    primary: 'black',
                    neutral10: 'black', // selected value background
                    neutral20: lightGray, // arrows
                    neutral50: lightGray, // Select... color
                    neutral80: lightGray, // color in selected values
                  },
                })}
              />
            </div>
        )
      break;
      case 'select':
         returnValue.push(
          <Select
            options={item.Options}
            name={item.Name}
            propertyName={item.PropertyName}
            onInputChange={onInputChange}
            value={item.Value}
            key={i}
          />
         )
        break;
    }
  })
  return returnValue
}

export const dragElement = (elmnt) => {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    // if present, the header is where you move the DIV from:
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else {
    // otherwise, move the DIV from anywhere inside the DIV:
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

export const floatLabel = (() => {
// add active class
const handleFocus = (e) => {
  const target = e.target;
  target.parentNode.classList.add('active');
  target.setAttribute('placeholder', target.getAttribute('data-placeholder'));
};

// remove active class
const handleBlur = (e) => {
  const target = e.target;
  if(!target.value) {
    target.parentNode.classList.remove('active');
  }
  target.removeAttribute('placeholder');
};

// register events
const bindEvents = (element) => {
  const floatField = element.querySelector('input');
  floatField.addEventListener('focus', handleFocus);
  floatField.addEventListener('blur', handleBlur);
};
const unBindEvents = (element) => {
  const floatField = element.querySelector('input');
  floatField.removeEventListener('focus', handleFocus);
  floatField.removeEventListener('blur', handleBlur);
};

// get DOM elements
const init = () => {
  const floatContainers = document.querySelectorAll('.adc_deviceMapping-inputDiv-floating');
  floatContainers.forEach((element) => {
    if (element.querySelector('input').value) {
      element.classList.add('active');
    }
    bindEvents(element);
  });
};
const close = () => {
  const floatContainers = document.querySelectorAll('.adc_deviceMapping-inputDiv-floating');
  floatContainers.forEach((element) => {
    unBindEvents(element);
  });
};
return {
  init: init,
  close: close
};
})();

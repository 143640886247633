import {
  createNewUserConstant
} from '../constants/actions'
import service from '../services//adminService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const createNewUserAction = (token, body) => {
  return async(dispatch) => {
    const result = await service.createNewUser(token, body)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result, body.username, body.level, body.email))
  }
}
const success = (result, user, level, email) => ({
  type: createNewUserConstant,
  payload: { result, user, level, email }
})

import poolService from '../services/poolService';
import { getPoolsConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getPoolsAction = (token, client) => {
  return async(dispatch) => {
    try{
      const result = await poolService.getPools(token, client);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(getPoolsSuccess(result))
    }
    catch(err){
      console.log(err)
    }
  }
}
const getPoolsSuccess = (pools) => ({
  type: getPoolsConstant,
  payload: pools
})

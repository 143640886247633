import React from 'react';
import './style.css';

const ProfileCard = ({ id, pools, title, privilegesLevel, insertPrivileges, deletePrivileges, removeItem, removeProfile, addItem }) => {
  return (
    <div className="card profilecard">
      <div className="profile-card-header">
        <strong>{title}</strong>
        {privilegesLevel >= deletePrivileges?(
          <button type="button" className="adc_deviceMapping-button" onClick={e => removeProfile(id)}>
            <i className="fa fa-close"></i>
          </button>
        ):(<></>)}
      </div>
      <div className="profile_card_body">
      {pools.map((item, i) => (
            <div className="pool_card_container">
                <ul className="list-group list-group-flush">
                    <li className="pool_card_item">
                      <span>URL</span>
                      <input value={item.URL} name="profile_url" className="adc_configBuildingSection-input" disabled/>
                    </li>
                    <li className="pool_card_item">
                      <span>Worker</span>
                      <input value={item.Worker} name="profile_worker" className="adc_configBuildingSection-input" disabled/>
                    </li>
                    <li className="pool_card_item">
                      <span>Password</span>
                      <input value={item.Password} name="profile_password" className="adc_configBuildingSection-input" disabled/>
                    </li>
                    {privilegesLevel >= deletePrivileges?(
                      <button type="button" className="delete-btn" onClick={e => removeItem(id, item.ID)}>
                        <strong>Remove Item</strong>
                      </button>
                    ):(<></>)}
                </ul>
            </div>
      ))}
      </div>
      <div class="card-footer text-muted">
        {privilegesLevel >= insertPrivileges?(
          <button type="button" className="btn btn-primary" onClick={e => addItem(id)}>
            <strong>Add Item</strong>
          </button>
        ):(<></>)}
      </div>
    </div>
  )
}

export default ProfileCard

import {
  rootRoute
}
from '../constants/routes';


const handleRequest = (url, options) => {
  return fetch(url, options)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    })
  .catch(error => ({
    ok: false,
    msg: "failed to send request to server",
    settings: []
  }))
}

const service = () => {
  const postFlagComment = (id, body, token, customer) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = `${rootRoute}/miner/flags/${id}/comments/${customer}`
    return handleRequest(url, requestOptions)
  }
  const getFlagCheckoutData = (token, customer, region_id) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    const url = `${rootRoute}/miner/flags/checkout/${customer}/region/${region_id}`
    return handleRequest(url, requestOptions)
  }
  const getFlagCommentsById = (id, token, customer) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    const url = `${rootRoute}/miner/flags/${id}/comments/${customer}`
    return handleRequest(url, requestOptions)
  }
  const postCustomMinerFlags = (body, token, customer) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = `${rootRoute}/miner/flags/custom/${customer}`
    return handleRequest(url, requestOptions)
  }
  const getFlagTypeInfoByID = (id, token, customer, region_id) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    const url = `${rootRoute}/miner/flags/types/${id}/info/${customer}/region/${region_id}`
    return handleRequest(url, requestOptions)
  }
  const getSubnetsConnectionsByMinerFlagID = (id, token, customer, region_id) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    const url = `${rootRoute}/miner/flags/types/${id}/subnet/connection/${customer}/region/${region_id}`
    return handleRequest(url, requestOptions)
  }
  const addSubnetFlagTypeConnection = (flagTypeID, body, token, customer, region_id) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = `${rootRoute}/miner/flags/types/${flagTypeID}/subnet/connection/${customer}/region/${region_id}`
    return handleRequest(url, requestOptions)
  }
  const deleteSubnetFlagTypeConnection = (flagTypeID, body, token, customer, region_id) => {
    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = `${rootRoute}/miner/flags/types/${flagTypeID}/subnet/connection/${customer}/region/${region_id}`
    return handleRequest(url, requestOptions)
  }
  const updateMinerFlagTypeSeverity = (id, body, token, customer)  => {
    var requestOptions = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/miner/flags/types/${id}/severity/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinerFlagSeverity = (token, customer)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miner/flags/severity/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinerFlagTypes = (token, customer, region_id)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miner/flags/types/info/${customer}/region/${region_id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinersWithFlag = (token, client, pageSize, pageNumber, orderBy, acending, filterString, region_id) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miners/flags/${client}/region/${region_id}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&filter[]=${filterString}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            page_size: data.success.page_size,
            current_page: data.success.current_page,
            max_page: data.success.max_page,
            data: data.success.data,
            history: data.success.history,
            online: data.success.online,
            offline: data.success.offline,
            performance: data.success.performance,
            estPower: data.success.estPower,
            totalHashrate: data.success.totalHashrate,
            totalData: data.success.totalData,
            detectedCount: data.success.detectedCount,
            pendingCount: data.success.pendingCount,
            checkedCount: data.success.checkedCount,
            resolvedCount: data.success.resolvedCount,
            idle: data.success.idle,
            cardsCount: data.success.cardsCount,
            apiDown: data.success.apiDown,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            api_version: data.api_version,
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }))
  }
  const postMultiMinerFlagChecks = (body, token, client, pageSize, pageNumber, orderBy, acending, filterString, region_id) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/miners/flags/check/${client}/region/${region_id}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&filter[]=${filterString}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            page_size: data.success.page_size,
            current_page: data.success.current_page,
            max_page: data.success.max_page,
            data: data.success.data,
            history: data.success.history,
            online: data.success.online,
            offline: data.success.offline,
            performance: data.success.performance,
            estPower: data.success.estPower,
            totalHashrate: data.success.totalHashrate,
            totalData: data.success.totalData,
            idle: data.success.idle,
            cardsCount: data.success.cardsCount,
            apiDown: data.success.apiDown,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            api_version: data.api_version,
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }))
  }
  const postSingleMinerFlagCheck = (body, flagID, token, client) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/miners/flags/${flagID}/check/${client}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }))
  }
  const getFlagSettingsByMinerTypeID = (id, token, customer)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/minertype/${id}/flags/setting/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const updateFlagSettingsByMinerTypeID = (id, body, token, customer)  => {
    var requestOptions = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${rootRoute}/minertype/${id}/flags/setting/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinerFlagsOverview = (token, option, customer, region_id)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miner/flags/overview/${option}/${customer}/region/${region_id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data,
            option: option
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            option: option,
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinerFlagTypeOptions = (token, customer)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miner/flags/types/option/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinerFlagCheckOptions = (token, customer)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miner/flags/check/option/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinerFlagsByMAC = (mac, token, customer)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miner/${mac}/flags/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinerFlagByID = (id, token, customer)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miner/flags/${id}/detail/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinerFlagRecoveryLogByFlagID = (id, token, customer)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miner/flags/${id}/log/recovery/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  const getMinerFlagActivityLogByFlagID = (id, token, customer)  => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${rootRoute}/miner/flags/${id}/log/activity/${customer}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: "successful",
            data: data.success.data
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }
  return {
    getMinerFlagsOverview,
    getMinersWithFlag,
    getMinerFlagTypeOptions,
    getMinerFlagCheckOptions,
    postMultiMinerFlagChecks,
    getMinerFlagsByMAC,
    getMinerFlagRecoveryLogByFlagID,
    getMinerFlagActivityLogByFlagID,
    postSingleMinerFlagCheck,
    getMinerFlagByID,
    getFlagSettingsByMinerTypeID,
    updateFlagSettingsByMinerTypeID,
    updateMinerFlagTypeSeverity,
    getMinerFlagSeverity,
    getMinerFlagTypes,
    getFlagTypeInfoByID,
    getSubnetsConnectionsByMinerFlagID,
    addSubnetFlagTypeConnection,
    deleteSubnetFlagTypeConnection,
    postCustomMinerFlags,
    getFlagCommentsById,
    postFlagComment,
    getFlagCheckoutData
  }
}

export default service()

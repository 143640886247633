import {
  rootRoute,
  getUserLogsByTimeRoute,
  maintenanceRoute,
  getMinerRoute
 } from '../constants/routes';

const logService = () =>{
  const getUserLogs = (token, customer, pageSize, pageNumber, orderBy, acending, searchString, region_id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    return fetch(`${getUserLogsByTimeRoute}/${customer}/region/${region_id}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchString}`, requestOptions)
    .then(res => res.json())
    .then(data => {
      if(!data.hasOwnProperty("error")){
        return {
          max_page: data.success.max_page,
          current_page: data.success.current_page,
          page_size: data.success.page_size,
          ok: true,
          msg: "successful",
          userLogs: data.success.data,
          miners: [],
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          userLogs: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      userLogs: [],
    }));
  }
  //  //'/api/miner/:mac/logs/user/:customer
  const getUserLogsByMac = (mac, token, customer, pageSize, pageNumber, orderBy, acending, searchString) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    return fetch(`${rootRoute}/miner/${mac}/logs/user/${customer}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchString}`, requestOptions)
    .then(res => res.json())
    .then(data => {
      if(!data.hasOwnProperty("error")){
        return {
          max_page: data.success.max_page,
          current_page: data.success.current_page,
          page_size: data.success.page_size,
          ok: true,
          msg: "successful",
          userLogs: data.success.data,
          miners: [],
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          userLogs: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      userLogs: [],
    }));
  }
  const getMinsersByUserLog = (token, client, time) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    return fetch(`${getUserLogsByTimeRoute}/${time}/${client}`, requestOptions)
    .then(res => res.json())
    .then(data => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          miners: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          miners: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      miners: [],
    }));
  }
  const getMaintenanceByMAC = (token, mac, customer, pageSize, pageNumber, orderBy, acending) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    return fetch(`${maintenanceRoute}/${mac}/${customer}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}`, requestOptions)
    .then(res => res.json())
    .then(data => {
      if(!data.hasOwnProperty("error")){
        return {
          max_page: data.success.max_page,
          current_page: data.success.current_page,
          page_size: data.success.page_size,
          ok: true,
          msg: "successful",
          logs: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          logs: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      logs: [],
    }));
  }
  const getMaintenanceLogs = (token, customer, pageSize, pageNumber, orderBy, acending, searchValue, region_id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    return fetch(`${maintenanceRoute}/${customer}/region/${region_id}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}`, requestOptions)
    .then(res => res.json())
    .then(data => {
      if(!data.hasOwnProperty("error")){
        return {
          max_page: data.success.max_page,
          current_page: data.success.current_page,
          page_size: data.success.page_size,
          ok: true,
          msg: "successful",
          logs: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          logs: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      logs: [],
    }));
  }
  const postMaintenance = (body, token, customer, pageSize, pageNumber, orderBy, acending) => {
   var requestOptions = {
     method: 'POST',
     redirect: 'follow',
     headers: {
       'Accept': 'application/json',
       'Authorization': `Bearer ${token}`,
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(body)
   };
   return fetch(`${maintenanceRoute}/${customer}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}`, requestOptions)
   .then(res => res.json())
   .then(data => {
     if(!data.hasOwnProperty("error")){
       return {
         max_page: data.success.max_page,
         current_page: data.success.current_page,
         page_size: data.success.page_size,
         ok: true,
         msg: "successful",
         logs: data.success.data,
         api_version: data.api_version
       }
     }
     else{
       return {
         ok: false,
         msg: data.error.reason,
         logs: [],
         key_error: data.error.key_error,
       }
     }
   })
   .catch(error => ({
     ok: false,
     msg: "failed to fetch data from server",
     logs: [],
   }));
  }
  const getSystemLogs = (token, customer, mac) => {
   var requestOptions = {
     method: 'GET',
     redirect: 'follow',
     headers: {
       'Accept': 'application/json',
       'Authorization': `Bearer ${token}`
     }
   };
   return fetch(`${getMinerRoute}/${mac}/logs/syslogs/${customer}`, requestOptions)
   .then(res => res.json())
   .then(data => {
     if(!data.hasOwnProperty("error")){
       return {
         ok: true,
         data: data.result.data,
         status: data.result.code,
         api_version: data.api_version,
       }
     }
     else{
       return {
         ok:false,
         data: [],
         msg: data.error.reason,
         status: 400,
         code: data.error.code,
         key_error: data.error.key_error,
       }
     }
   })
   .catch(error => ({
     ok: false,
     msg: "failed to fetch data from server",
     data: [],
     status: 400,
   }));
  }
  const getErrorLogs = (token, customer, mac) => {
   var requestOptions = {
     method: 'GET',
     redirect: 'follow',
     headers: {
       'Accept': 'application/json',
       'Authorization': `Bearer ${token}`
     }
   };
   return fetch(`${getMinerRoute}/${mac}/logs/errorlogs/${customer}`, requestOptions)
   .then(res => res.json())
   .then(data => {
     if(!data.hasOwnProperty("error")){
       return {
         ok: true,
         data: data.result.data,
         status: data.result.code,
         api_version: data.api_version,
       }
     }
     else{
       return {
         ok:false,
         data: [],
         msg: data.error.reason,
         status: 400,
         code: data.error.code,
         key_error: data.error.key_error,
       }
     }
   })
   .catch(error => ({
     ok: false,
     msg: "failed to fetch data from server",
     data: [],
     status: 400
   }));
  }

  return {
    getUserLogs,
    getMinsersByUserLog,
    getMaintenanceByMAC,
    postMaintenance,
    getMaintenanceLogs,
    getSystemLogs,
    getErrorLogs,
    getUserLogsByMac
  }
}

export default logService()

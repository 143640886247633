import clientService from '../services/clientService';
import { getMinerLogsConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getMinerLogsAction = (token, client, pageSize, pageNumber, orderBy, acending) => {
  return async (dispatch) => {
    try{
      const result = await clientService.getMinerLogs(token, client, pageSize, pageNumber, orderBy, acending);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(getMinerLogsSuccess(result));
    }
    catch(err){
      console.log(err)
    }
  }
}

const getMinerLogsSuccess = ( miners ) => ({
  type: getMinerLogsConstant,
  payload: miners
})

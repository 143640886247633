import { editSerialNumberConstant } from '../constants/actions'
import service from '../services/minerService'

export const editSerialNumberAction = (token, client, mac, body) => {
  return async (dispatch) => {
    const result = await service.editSerialNumber(token, client, mac, body)
    dispatch(success(result))
  }
}
const success = (result) => ({
  type: editSerialNumberConstant,
  payload: result
})

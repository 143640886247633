import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { getUserLogsByMacAction } from '../../redux/actions/getUserLogsByMacAction'
import { minerView } from '../../helper/tabs'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { updateClientAction } from '../../redux/actions/updateClientAction'
import moment from 'moment'
import './style.css'

class MinerActivityView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: ''
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.store.client !== this.props.match.params.name){
      const store = {
        pageSize: 25,
        pageNumber: 1,
        client: this.props.match.params.name,
        type: 'userLogs',
        orderBy: 'Time_Stamp',
        acending: false
      }
      this.props.updatePageInfoAction(store)
    }
    // Should be getting flags
    //this.props.getUserLogsByMacAction(this.props.match.params.miner, this.props.token, this.props.match.params.name)

    const token = this.props.userStore.result;
    const client = this.props.match.params.name;
    this.props.getUserLogsByMacAction(this.props.match.params.miner, token, client, this.props.store.pageSize, this.props.store.pageNumber, this.props.store.orderBy, this.props.store.acending, this.state.searchString);
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onPageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber !== 0 && pageNumber <= this.props.userLogStore.max_page){
      this.props.getUserLogsByMacAction(this.props.match.params.miner, this.props.userStore.result, this.props.match.params.name, this.props.store.pageSize, pageNumber, this.props.store.orderBy, this.props.store.acending, this.state.searchString);
    }
    const store = {
      pageSize: this.props.store.pageSize,
      pageNumber: pageNumber,
      client: this.props.match.params.name,
      type: 'userLogs',
      orderBy: this.props.store.orderBy,
      acending: this.props.store.acending
    }
    this.props.updatePageInfoAction(store)
  }
  onSort = (type) => {
    let { acending, orderBy } = this.props.store;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getUserLogsByMacAction(this.props.match.params.miner, this.props.userStore.result, this.props.match.params.name, this.props.store.pageSize, this.props.store.pageNumber, type, acending, this.state.searchString);
    const store = {
      pageSize: this.props.store.pageSize,
      pageNumber: this.props.store.pageNumber,
      client: this.props.match.params.name,
      type: 'userLogs',
      orderBy: type,
      acending: acending
    }
    this.props.updatePageInfoAction(store);
  }
  changePageSize = (e) => {
    const value = parseInt(e.target.value);
    let pageNum = this.props.store.pageNumber;
    const items = parseInt(this.props.userLogStore.max_page)*parseInt(this.props.userLogStore.page_size)
    if(value > items){
      pageNum = 1;
    }
    if((this.props.store.pageNumber*value) > items){
      pageNum = 1;
    }
    this.props.getUserLogsByMacAction(this.props.match.params.miner, this.props.userStore.result, this.props.match.params.name, value, pageNum, this.props.store.orderBy, this.props.store.acending, this.state.searchString);
    const store = {
      pageSize: value? value:0,
      pageNumber: pageNum,
      client: this.props.match.params.name,
      type: 'userLogs',
      orderBy: this.props.store.type,
      acending: this.props.store.acending
    }
    this.props.updatePageInfoAction(store);
  }
  onSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.store
    this.props.getUserLogsByMacAction(this.props.match.params.miner, this.props.userStore.result, this.props.match.params.name, pageSize, 1, orderBy, acending, e.target.value);
    this.setState({
      searchString: e.target.value
    })
    const store = {
      pageSize: pageSize,
      pageNumber: 1,
      client: this.props.match.params.name,
      type: 'userLogs',
      orderBy: this.props.store.type,
      acending: this.props.store.acending
    }
    this.props.updatePageInfoAction(store);
  }
  render(){
    this.props.userLogStore.userLogs.map((item) => item.Time_Stamp = moment(item.Time_Stamp).format('YYYY-MM-DD hh:mm:ss'))
    const columns = [
      {field: 'Username', value: 'Username'},
      {field: 'Command', value: 'Command'},
      {field: 'Time_Stamp', value: 'Timestamp'},
    ]
    return(
      <div className="MinerView-container">
        <TabHeader
          logo={
            <img className="tab-view-image" src={require("../../images/MinerDetailsIcon.png")}/>
          }
          title="Miner Details"
          subtitle="Detailed miner info, user comments, repairs & more"
          tabs={minerView}
          active="Activity"
          onClick={this.onClick}
        />
        <ViewBody
          data={this.props.userLogStore.userLogs}
          columns={columns}
          list
          pager
          sorting
          search
          currentPage={parseInt(this.props.userLogStore.current_page) - 1}
          totalPages={this.props.userLogStore.max_page}
          onSort={this.onSort}
          sortedBy={this.props.store.orderBy}
          acending={this.props.store.acending}
          pageSize={this.props.store.pageSize}
          changePageSize={this.changePageSize}
          searchValue={this.state.searchString}
          onSearchChange={this.onSearch}
          onPageChange={this.onPageClick}
        />
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  userStore: props.userReducer,
  userLogStore: props.userLogsReducer,
  store: props.pageReducer.userLogs,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  getUserLogsByMacAction,
  updatePageInfoAction,
  updateClientAction
})(MinerActivityView)

import { updatePageInfoConstant } from '../constants/actions'

const defaultState = {
  api_version: 1.1,
  userLogs: {
    client: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Time_Stamp',
    acending: false
  },
  minerLogsByMac: {
    MAC_Address: '',
    client: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Time_Stamp',
    acending: true
  },
  minerLogs: {
    client: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Time_Stamp',
    acending: true
  },
  podList: {
    podID: 1,
    client: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Location',
    acending: true
  },
  subnetList: {
    subnetID: 1,
    client: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Location',
    acending: true
  },
  minerList: {
    client: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Location',
    acending: true
  },
  maintenenceListByMAC: {
    MAC_Address: '',
    client: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Log_Date',
    acending: true
  },
  maintenenceList: {
    client: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Log_Date',
    acending: true
  },
  inventoryHistory: {
    client: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Action_Date',
    acending: true
  },
  allAlerts: {
    pageSize: 5,
    pageNumber: 1,
    orderBy: 'Active',
    acending: false
  },
  customerAlerts:{
    client: '',
    pageSize: 5,
    pageNumber: 1,
    orderBy: 'Active',
    acending: false
  },
  users: {
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Username',
    acending: true
  },
  scanners: {
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Database_Name',
    acending: true
  },
  minerType: {
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'Name',
    acending: true
  },
  powerCutOrder: {
    searchString: '',
    pageSize: 25,
    pageNumber: 1,
    orderBy: 'ID',
    acending: true
  },
  powerCutOrderMiners: {
    databases: [],
    statusList: [],
    pageSize: 50,
    pageNumber: 1,
    orderBy: 'ID',
    acending: true
  }
}

export default(state = defaultState, action) =>{
  switch (action.type) {
    case updatePageInfoConstant:
        const s1 = JSON.parse(JSON.stringify(state));
        s1[action.payload.type] = action.payload;
        return s1;
    default:
      return state;
  }
}

import { getHashrateConstant, clearHashReducerConstant, setId_getHashrateConstant } from '../constants/actions';

const defaultState = {
  ok: false,
  msg: '',
  hashrate1Min: [],
  hashrate15Min: [],
  hashrate1h: [],
  hashrate6h: [],
  hashrate1d: [],
  hashrate: [],
  customer: '',
  api_version: 1.1,
  get_hashrate_actionId: null
}
export default(state = defaultState, action) => {
  switch (action.type) {
    case clearHashReducerConstant:
      return {
        ok: false,
        msg: '',
        hashrate: [],
        hashrate1Min: [],
        hashrate15Min: [],
        hashrate1h: [],
        hashrate6h: [],
        hashrate1d: [],
        customer: '',
        get_hashrate_actionId: null
      }
    case setId_getHashrateConstant:
      return {
        ...state,
        get_hashrate_actionId: action.payload
      }
    case getHashrateConstant:
        if(action.actionId !== state.get_hashrate_actionId)return state
        if(action.payload.ok){
          if(action.payload.type === 'all'){
            return {
              ok: true,
              msg: action.payload.msg,
              hashrate: state.hashrate,
              hashrate1Min: action.payload.data.hashrate1Min,
              hashrate15Min: action.payload.data.hashrate15Min,
              hashrate1h:  action.payload.data.hashrate1h,
              hashrate6h:  action.payload.data.hashrate6h,
              hashrate1d:  action.payload.data.hashrate1d,
              customer: action.payload.customer,
              get_hashrate_actionId: null
            }
          }
          else{
            return {
              ok: true,
              msg: action.payload.msg,
              hashrate: action.payload.data,
              hashrate1Min: state.hashrate1Min,
              hashrate15Min: state.hashrate15Min,
              hashrate1h: state.hashrate1h,
              hashrate6h: state.hashrate6h,
              hashrate1d: state.hashrate1d,
              customer: action.payload.customer,
              get_hashrate_actionId: null
            }
          }
        }
        else{
          const newState = {
            ok: false,
            msg: action.payload.msg,
            hashrate: [],
            customer: '',
            hashrate1Min: [],
            hashrate15Min: [],
            hashrate1h: [],
            hashrate6h: [],
            hashrate1d: [],
            hashrate: [],
            get_hashrate_actionId: null
          }
          return newState;
        }
    default:
      return state;
  }
}

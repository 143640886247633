import {
  getMinersConstant,
  sortMinersConstant,
  getMinersByPodConstant,
  markMinersDeletedConstant,
  alterStatusConstant,
  clearLocationsConstant,
  clearMinersConstant,
  getAllMinersConstant,
  fetchMoreMinersConstant,
  resetMinerDisplayInterval,
  getOverviewMinersByTypeConstant,
  setId_getAllMinersConstant,
  setID_getOverviewMinersByTypeConstant,
  getSectionHistoryConstant,
  getMinersWithFlagsConstant,
  setId_getMinersWithFlagConstant,
  postMultiMinerFlagChecksConstant
} from '../constants/actions';
const defaultState = {
  ok: false,
  msg:'',
  api_version: 1.1,
  miners: [],
  sectionHistory: [],
  displayInterval : 1,
  displayedMiners: [],
  online: 0,
  offline: 0,
  totalHashrate: 0,
  apiDown: 0,
  cardsCount: 0,
  totalData: 0,
  detectedCount: 0,
  pendingCount: 0,
  checkedCount: 0,
  resolvedCount: 0,
  idle: 0,
  sleep: 0,
  performance: '',
  estPower: '',
  page_size: 25,
  current_page: 1,
  max_page: 1,
  get_all_miners_actionId: null,
  get_miners_with_flags_actionId: null,
  get_overview_miners_actionId: null
}

export default (state = defaultState, action) => {
  const height = document.documentElement.offsetHeight;
  const display = Math.floor(height/20)
  switch (action.type){
    case getSectionHistoryConstant:
      if(action.payload.ok){
        return{
          ...state,
          ok: true,
          sectionHistory: action.payload.data
        }
      }
      return state
    case setId_getMinersWithFlagConstant:
      return {
        ...state,
        get_miners_with_flags_actionId: action.payload
      }
    case setId_getAllMinersConstant:
      return {
        ...state,
        get_all_miners_actionId: action.payload
      }
    case setID_getOverviewMinersByTypeConstant:
      return {
        ...state,
        get_overview_miners_actionId: action.payload
      }
    case resetMinerDisplayInterval:
      return {
          ok: state.ok,
          msg: state.msg,
          miners: state.miners,
          sectionHistory: state.sectionHistory,
          displayInterval : 1,
          displayedMiners: state.displayedMiners,
          online: state.online,
          offline: state.offline,
          performance: state.performance,
          totalHashrate: state.totalHashrate,
          idle: state.idle,
          sleep: state.sleep,
          cardsCount: state.cardsCount,
          totalData: state.totalData,
          apiDown: state.apiDown,
          page_size: state.page_size,
          current_page: state.current_page,
          max_page: state.max_page,
          get_all_miners_actionId: state.get_all_miners_actionId,
          get_overview_miners_actionId: state.get_overview_miners_actionId,
          get_miners_with_flags_actionId: state.get_miners_with_flags_actionId
      }
      break;
    case fetchMoreMinersConstant:
      if(display*action.payload < state.miners.length){
        return{
          ok: state.ok,
          msg: state.msg,
          miners: state.miners,
          sectionHistory: state.sectionHistory,
          displayedMiners: state.miners.slice(0, display*action.payload),
          displayInterval: action.payload,
          online: state.online,
          offline: state.offline,
          performance: state.performance,
          estPower: state.estPower,
          totalHashrate: state.totalHashrate,
          idle: state.idle,
          sleep: state.sleep,
          cardsCount: state.cardsCount,
          totalData: state.totalData,
          apiDown: state.apiDown,
          detectedCount: state.detectedCount,
          pendingCount: state.pendingCount,
          checkedCount: state.checkedCount,
          resolvedCount: state.resolvedCount,
          page_size: state.page_size,
          current_page: state.current_page,
          max_page: state.max_page,
          get_all_miners_actionId: state.get_all_miners_actionId,
          get_overview_miners_actionId: state.get_overview_miners_actionId,
          get_miners_with_flags_actionId: state.get_miners_with_flags_actionId
        }
      }
      else if(state.displayedMiners.length < state.miners.length){
        return{
          ok: state.ok,
          msg: state.msg,
          miners: state.miners,
          sectionHistory: state.sectionHistory,
          displayedMiners: state.miners.slice(0, state.miners.length),
          displayInterval: action.payload,
          online: state.online,
          offline: state.offline,
          performance: state.performance,
          estPower: state.estPower,
          totalHashrate: state.totalHashrate,
          idle: state.idle,
          sleep: state.sleep,
          cardsCount: state.cardsCount,
          totalData: state.totalData,
          apiDown: state.apiDown,
          page_size: state.page_size,
          current_page: state.current_page,
          max_page: state.max_page,
          get_all_miners_actionId: state.get_all_miners_actionId,
          get_overview_miners_actionId: state.get_overview_miners_actionId,
          get_miners_with_flags_actionId: state.get_miners_with_flags_actionId
        }
      }
      let changeIntervalState = JSON.parse(JSON.stringify(state))
      changeIntervalState.displayInterval = action.payload
      return changeIntervalState
    case clearMinersConstant:
      return{
        ok: false,
        msg:'',
        miners: [],
        sectionHistory: [],
        displayedMiners: [],
        displayInterval : 1,
        online: 0,
        offline: 0,
        performance: '',
        estPower: '',
        totalHashrate: 0,
        idle: 0,
        sleep: 0,
        cardsCount: 0,
        apiDown: 0,
        detectedCount: 0,
        pendingCount: 0,
        checkedCount: 0,
        resolvedCount: 0,
        page_size: 25,
        current_page: 1,
        max_page: 1,
        get_all_miners_actionId: null,
        get_overview_miners_actionId: null,
        get_miners_with_flags_actionId: null
      }
    case getAllMinersConstant:
      if(state.get_all_miners_actionId !== action.actionId) return state
      return {
        ok: action.payload.ok,
        msg: action.payload.msg,
        page_size: action.payload.page_size,
        current_page: action.payload.current_page,
        max_page: action.payload.max_page,
        miners: action.payload.data,
        sectionHistory: action.payload.history,
        id: action.payload.ID,
        online: action.payload.online,
        offline: action.payload.offline,
        estPower: action.payload.estPower,
        performance: action.payload.performance,
        totalHashrate: action.payload.totalHashrate,
        idle: action.payload.idle,
        sleep: action.payload.sleep,
        totalData: action.payload.totalData,
        cardsCount: action.payload.cardsCount,
        apiDown: action.payload.apiDown,
        detectedCount: state.detectedCount,
        pendingCount: state.pendingCount,
        checkedCount: state.checkedCount,
        resolvedCount: state.resolvedCount,
        displayedMiners: action.payload.data.slice(0,display*state.displayInterval),
        displayInterval: state.displayInterval,
        get_all_miners_actionId: null,
        get_overview_miners_actionId: state.get_overview_miners_actionId,
        get_miners_with_flags_actionId: state.get_miners_with_flags_actionId
      }
    case postMultiMinerFlagChecksConstant:
      if(action.payload.ok){
        return{
          ok: action.payload.ok,
          msg: action.payload.msg,
          page_size: action.payload.page_size,
          current_page: action.payload.current_page,
          max_page: action.payload.max_page,
          miners: action.payload.data,
          sectionHistory: action.payload.history,
          id: action.payload.ID,
          online: action.payload.online,
          offline: action.payload.offline,
          estPower: action.payload.estPower,
          performance: action.payload.performance,
          totalHashrate: action.payload.totalHashrate,
          idle: action.payload.idle,
          sleep: action.payload.sleep,
          totalData: action.payload.totalData,
          cardsCount: action.payload.cardsCount,
          apiDown: action.payload.apiDown,
          detectedCount: action.payload.detectedCount,
          pendingCount: action.payload.pendingCount,
          checkedCount: action.payload.checkedCount,
          resolvedCount: action.payload.resolvedCount,
          displayedMiners: action.payload.data.slice(0,display*state.displayInterval),
          displayInterval: state.displayInterval,
          get_all_miners_actionId: state.get_all_miners_actionId,
          get_overview_miners_actionId: state.get_overview_miners_actionId,
        }
      }
      return state
    case getMinersWithFlagsConstant:
      if(state.get_miners_with_flags_actionId !== action.actionId) return state
      return {
        ok: action.payload.ok,
        msg: action.payload.msg,
        page_size: action.payload.page_size,
        current_page: action.payload.current_page,
        max_page: action.payload.max_page,
        miners: action.payload.data,
        sectionHistory: action.payload.history,
        id: action.payload.ID,
        online: action.payload.online,
        offline: action.payload.offline,
        estPower: action.payload.estPower,
        performance: action.payload.performance,
        totalHashrate: action.payload.totalHashrate,
        idle: action.payload.idle,
        sleep: action.payload.sleep,
        totalData: action.payload.totalData,
        cardsCount: action.payload.cardsCount,
        apiDown: action.payload.apiDown,
        detectedCount: action.payload.detectedCount,
        pendingCount: action.payload.pendingCount,
        checkedCount: action.payload.checkedCount,
        resolvedCount: action.payload.resolvedCount,
        displayedMiners: action.payload.data.slice(0,display*state.displayInterval),
        displayInterval: state.displayInterval,
        get_all_miners_actionId: state.get_all_miners_actionId,
        get_overview_miners_actionId: state.get_overview_miners_actionId,
        get_miners_with_flags_actionId: null
      }
    case getOverviewMinersByTypeConstant:
      if(state.get_overview_miners_actionId !== action.actionId) return state
      return {
        ok: action.payload.ok,
        msg: action.payload.msg,
        page_size: action.payload.page_size,
        current_page: action.payload.current_page,
        max_page: action.payload.max_page,
        miners: action.payload.data,
        sectionHistory: state.sectionHistory,
        id: action.payload.ID,
        online: action.payload.online,
        offline: action.payload.offline,
        estPower: action.payload.estPower,
        performance: action.payload.performance,
        totalHashrate: action.payload.totalHashrate,
        idle: action.payload.idle,
        sleep: action.payload.sleep,
        totalData: action.payload.totalData,
        cardsCount: action.payload.cardsCount,
        apiDown: action.payload.apiDown,
        detectedCount: state.detectedCount,
        pendingCount: state.pendingCount,
        checkedCount: state.checkedCount,
        resolvedCount: state.resolvedCount,
        displayedMiners: action.payload.data.slice(0,display*state.displayInterval),
        displayInterval: state.displayInterval,
        get_all_miners_actionId: state.get_all_miners_actionId,
        get_overview_miners_actionId: null,
        get_miners_with_flags_actionId: state.get_miners_with_flags_actionId
      }
    case getMinersConstant:
    case getMinersByPodConstant:
        return {
          ok: action.payload.ok,
          msg: action.payload.msg,
          page_size: action.payload.page_size,
          current_page: action.payload.current_page,
          max_page: action.payload.max_page,
          miners: action.payload.data,
          sectionHistory: state.sectionHistory,
          id: action.payload.ID,
          online: action.payload.online,
          offline: action.payload.offline,
          estPower: action.payload.estPower,
          performance: action.payload.performance,
          totalHashrate: action.payload.totalHashrate,
          idle: action.payload.idle,
          sleep: action.payload.sleep,
          totalData: action.payload.totalData,
          cardsCount: action.payload.cardsCount,
          apiDown: action.payload.apiDown,
          detectedCount: state.detectedCount,
          pendingCount: state.pendingCount,
          checkedCount: state.checkedCount,
          resolvedCount: state.resolvedCount,
          displayedMiners: action.payload.data.slice(0,display*state.displayInterval),
          displayInterval: state.displayInterval
        }
    case sortMinersConstant:
      let returnState = JSON.parse(JSON.stringify(state))
      returnState.miners.sort((a, b) => action.payload.acending?(a[action.payload.property] > b[action.payload.property]) ? 1: -1: (a[action.payload.property] < b[action.payload.property])? 1: -1);
      return returnState;
    case markMinersDeletedConstant:
      if(action.payload.ok){
        const newMiners = state.miners.filter((item) => action.payload.macs.indexOf(item.MAC_Address) === -1);
        const newDisplayMiners = state.displayedMiners.filter((item) => action.payload.macs.indexOf(item.MAC_Address) === -1)
        return{
          ok: false,
          msg:action.payload.msg,
          miners: newMiners,
          sectionHistory: state.sectionHistory,
          page_size: state.page_size,
          current_page: state.current_page,
          max_page: state.max_page,
          online: state.online,
          offline: state.offline,
          performance: state.performance,
          estPower: state.estPower,
          totalHashrate: state.totalHashrate,
          idle: state.idle,
          sleep: state.sleep,
          cardsCount: state.cardsCount,
          totalData: state.totalData,
          apiDown: state.apiDown,
          detectedCount: state.detectedCount,
          pendingCount: state.pendingCount,
          checkedCount: state.checkedCount,
          resolvedCount: state.resolvedCount,
          displayedMiners: newDisplayMiners,
          displayInterval: state.displayInterval,
          get_all_miners_actionId: state.get_all_miners_actionId,
          get_overview_miners_actionId: state.get_overview_miners_actionId,
          get_miners_with_flags_actionId: state.get_miners_with_flags_actionId
        }
      }
      else{
        return{
          ok: false,
          msg: action.payload.msg,
          miners: state.miners,
          sectionHistory: state.sectionHistory,
          page_size: state.page_size,
          current_page: state.current_page,
          max_page: state.max_page,
          online: state.online,
          offline: state.offline,
          performance: state.performance,
          estPower: state.estPower,
          totalHashrate: state.totalHashrate,
          idle: state.idle,
          sleep: state.sleep,
          cardsCount: state.cardsCount,
          totalData: state.totalData,
          apiDown: state.apiDown,
          detectedCount: state.detectedCount,
          pendingCount: state.pendingCount,
          checkedCount: state.checkedCount,
          resolvedCount: state.resolvedCount,
          displayedMiners: state.displayedMiners,
          displayInterval: state.displayInterval,
          get_all_miners_actionId: state.get_all_miners_actionId,
          get_overview_miners_actionId: state.get_overview_miners_actionId,
          get_miners_with_flags_actionId: state.get_miners_with_flags_actionId
        }
      }
    case alterStatusConstant:
      if(action.payload.ok){
        const newMiners = state.miners.map((item) => action.payload.macs.indexOf(item.MAC_Address) !== -1?(item.Status = action.payload.status, item):(item))
        return{
          ok: false,
          msg:action.payload.msg,
          miners: newMiners,
          sectionHistory: state.sectionHistory,
          page_size: state.page_size,
          current_page: state.current_page,
          max_page: state.max_page,
          online: state.online,
          offline: state.offline,
          performance: state.performance,
          estPower: state.estPower,
          totalHashrate: state.totalHashrate,
          idle: state.idle,
          sleep: state.sleep,
          cardsCount: state.cardsCount,
          totalData: state.totalData,
          apiDown: state.apiDown,
          detectedCount: state.detectedCount,
          pendingCount: state.pendingCount,
          checkedCount: state.checkedCount,
          resolvedCount: state.resolvedCount,
          displayedMiners: state.displayedMiners,
          displayInterval: state.displayInterval,
          get_all_miners_actionId: state.get_all_miners_actionId,
          get_overview_miners_actionId: state.get_overview_miners_actionId
        }
      }
      return state;
    case clearLocationsConstant:
      if(action.payload.ok && action.payload.list){
        let newMiners = state.miners.filter((item) => action.payload.macs.indexOf(item.MAC_Address) === -1)
        return{
          ok: true,
          msg:action.payload.msg,
          miners: newMiners,
          sectionHistory: state.sectionHistory,
          page_size: state.page_size,
          current_page: state.current_page,
          max_page: state.max_page,
          online: state.online,
          offline: state.offline,
          performance: state.performance,
          estPower: state.estPower,
          totalHashrate: state.totalHashrate,
          idle: state.idle,
          sleep: state.sleep,
          cardsCount: state.cardsCount,
          totalData: state.totalData,
          apiDown: state.apiDown,
          detectedCount: state.detectedCount,
          pendingCount: state.pendingCount,
          checkedCount: state.checkedCount,
          resolvedCount: state.resolvedCount,
          displayedMiners: state.displayedMiners,
          displayInterval: state.displayInterval,
          get_all_miners_actionId: state.get_all_miners_actionId,
          get_overview_miners_actionId: state.get_overview_miners_actionId,
          get_miners_with_flags_actionId: state.get_miners_with_flags_actionId
        }
      }
      return state
    default:
        return state;
  }
}

import React from 'react'
import './style.css'
import React_Select, { components, IndicatorsContainerProps } from 'react-select'
import TuningAttributesTable from '../TuningAttributesTable'
import {  blueGray, lightGray, darkBoarder } from '../../helper/colors.js'
import { Range } from 'react-range';


export const colourStyles = {
    container: styles => ({
      ...styles,
      width: '97.5%'
    }),
    control: styles => ({
      ...styles,
      backgroundColor: blueGray,
      borderColor: darkBoarder,
      color: lightGray,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: lightGray,
        cursor: isDisabled ? 'not-allowed' : 'default'
      }
    }
  };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ display: 'none' }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

const NewTuningSelect = ({ option, value, property, onInputChange }) => {
    return(
        <React_Select
            options={option}
            onChange={e => onInputChange(e, property)}
            isSearchable
            autoFocus
            isMulti
            value={value}
            styles={colourStyles}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'black',
                    neutral0: blueGray,
                    primary: 'black',
                    neutral10: 'black', // selected value background
                    neutral20: lightGray, // arrows
                    neutral50: lightGray, // Select... color
                    neutral80: lightGray, // color in selected values
                }
            })}
        />
    )
}

const AttributeValues = ({ type, valueList, onInputChange, itemID, attributesValueList }) => {
    switch(type){
        case 'boolean':
        case 'list':
            const listData = valueList.map((item) => ({label: item.Display_Name, value: item.Value}))
            return(
                <div>
                    <React_Select
                        options={listData}
                        onChange={e => onInputChange(e, "valueList", itemID)}
                        autoFocus
                        styles={colourStyles}
                        components={{IndicatorsContainer}}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'black',
                                neutral0: blueGray,
                                primary: 'black',
                                neutral10: 'black', // selected value background
                                neutral20: lightGray, // arrows
                                neutral50: lightGray, // Select... color
                                neutral80: lightGray, // color in selected values
                            }
                        })}
                    />
                </div>
            )
        case 'range':
            const split = valueList[0].Value.split('/')
            const rangeSplit = split[0].split('-')
            let value = attributesValueList.filter((item) => item.ID === itemID).map((item) => item.Value)
            let min = ''
            let max = ''
            let step = split[1]
            if( step == '1' || step == '10'){
                min = parseInt(rangeSplit[0])
                max = parseInt(rangeSplit[1])
                step = parseInt(step)
            }
            else{
                min = parseFloat(rangeSplit[0])
                max = parseFloat(rangeSplit[1])
                step = parseFloat(step)
            }
            return(
                <div>
                    {step == 1 || step == 10? (
                        <span>{`${value[0]} ${valueList[0]['Display_Name']} `}</span>
                    ):(
                        <span>{`${value[0].toFixed(1)} ${valueList[0]['Display_Name']} `}</span>
                    )}
                    <Range
                        step={step}
                        min={min}
                        max={max}
                        values={value}
                        onChange={(values) => onInputChange(values, "valueRange", itemID)}
                        renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                            ...props.style,
                            height: '6px',
                            width: '100%',
                            backgroundColor: '#222d32'
                            }}
                        >
                            {children}
                        </div>
                        )}
                        renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                            ...props.style,
                            height: '15px',
                            width: '15px',
                            backgroundColor: lightGray
                            }}
                        />
                        )}
                    />
                </div>
            )
    }
}
const NewTuningProfile = ({ firmwares, attributes,  attributesValueList, pickAttributes, pickFirmwares, onInputChange, minerTypes, pickMinerTypes, name, submitForm }) => {
    return(
        <div className="formfieldSection-card">
            <div className="card-header">
                <strong>Create new tuning profile</strong>
            </div>
            <div className="card-body">
                <div className="form-column">
                    <label className="">Profile Name</label>
                    <input type="text" value={name} className="form-column-item" onChange={e => onInputChange(e, "name")} placeholder="Profile Name"/>
                </div>
                <div className="tuning-profile-pick-container">
                    <div className="formfieldSection_form-group">
                        <strong>Pick Firmwares</strong>
                        <NewTuningSelect
                            option={pickFirmwares}
                            value={firmwares}
                            property={"firmwares"}
                            onInputChange={onInputChange}
                        />
                    </div>
                    <div className="formfieldSection_form-group">
                        <strong>Pick Miner Types</strong>
                        <NewTuningSelect
                            option={pickMinerTypes}
                            value={minerTypes}
                            property={"minerTypes"}
                            onInputChange={onInputChange}
                        />
                    </div>
                </div>
                {pickAttributes.length > 0? (
                    <>
                        <div className="formfieldSection_form-group">
                    <strong>Pick Tuning Attributes</strong>
                </div>
                <TuningAttributesTable
                    pickAttributes={pickAttributes}
                    attributesValueList={attributesValueList}
                    onInputChange={onInputChange}
                />
                    </>
                ):(<></>)}
                <button type="submit" className="ViewBody-button-create" onClick={ e => submitForm()}>Create Profile</button>
            </div>
        </div>
    )
}

export default NewTuningProfile
import {
  getTypesContant,
  postTypesContant,
  deleteTypeConstant,
  setMinerTypeConstant,
  getAllTypesConstant,
  createNewMinerTypeconstant,
  deleteMinerTypeConstant,
  editMinerTypeConstant,
  getMinerTypePowerConstant,
  clearMinerTypePowerConstant,
  getMinerTypeInfoByIDConstant
}
from '../constants/actions';

const defaultState = {
  ok: false,
  msg: '',
  api_version: 1.1,
  types: [],
  allTypes: {
    max_page: 1,
    current_page: 1,
    data: []
  },
  powerModes: []
}

export default(state = defaultState, action) => {
  switch (action.type) {
    case editMinerTypeConstant:
    case createNewMinerTypeconstant:
    case getAllTypesConstant:
      if(action.payload.ok){
        return{
          ok: true,
          msg: action.payload.msg,
          types: state.types,
          allTypes: action.payload.data,
          powerModes: state.powerModes
        }
      }
      else{
        return{
          ok: false,
          msg: action.payload.msg,
          types: state.types,
          allTypes: state.allTypes,
          powerModes: state.powerModes
        }
      }
    case deleteTypeConstant:
    case getMinerTypeInfoByIDConstant:
    case getTypesContant:
      if(action.payload.ok){
        return {
          ok: true,
          msg: action.payload.msg,
          types: action.payload.data,
          allTypes: state.allTypes,
          powerModes: state.powerModes
        }
      }
      else{
        return {
          ok: true,
          msg: action.payload.msg,
          types: state.types,
          allTypes: state.allTypes,
          powerModes: state.powerModes
        }
      }
      break;
    case postTypesContant:
      if(action.payload.ok){
        const newAllTypes = {
          max_page: 1,
          current_page: 1,
          data: state.allTypes
        }
        return{
          ok: true,
          msg: action.payload.msg,
          types: action.payload.data,
          allTypes: newAllTypes,
          powerModes: state.powerModes
        }
      }
      else{
        return{
          ok: false,
          msg: action.payload.msg,
          types: state.types,
          allTypes: state.allTypes,
          powerModes: state.powerModes
        }
      }
      break;
    case setMinerTypeConstant:
      return {
        ok: action.payload.ok,
        msg: action.payload.ok,
        types: state.types,
        allTypes: state.allTypes,
        powerModes: state.powerModes
      }
    case deleteMinerTypeConstant:
      if(action.payload.result.ok){
        const newTypes = state.allTypes.data.filter((item) => item.ID !== action.payload.id)
        const newAllTypes = JSON.parse(JSON.stringify(state.allTypes))
        newAllTypes.data = newTypes
        return {
          ok: action.payload.ok,
          msg: action.payload.ok,
          types: state.types,
          allTypes: newAllTypes,
          powerModes: state.powerModes
        }
      }
      return state
    case getMinerTypePowerConstant:
      if(action.payload.ok){
        return {
          ok: true,
          msg: action.payload.msg,
          api_version: action.payload.api_version,
          types: state.types,
          allTypes: state.allTypes,
          powerModes: action.payload.data
        }
      }
      return state
    case clearMinerTypePowerConstant:
      return {
        ok: state.ok,
        msg: state.msg,
        api_version: state.api_version,
        types: state.types,
        allTypes: state.allTypes,
        powerModes: []
      }
    default:
      return state;
  }
}

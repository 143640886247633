import { postCustomMinerFlagsConstant } from '../constants/actions'
import service from '../services/flagService'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'



export const postCustomMinerFlagsAction = (body, token, customer) => {
  return async(dispatch) => {
    const result = await service.postCustomMinerFlags(body, token, customer)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: postCustomMinerFlagsConstant,
  payload: result
})

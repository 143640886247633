import { formatBytes } from '../../helper/functions'
export const highchartOptions = (data, title) => {
  let categories    = []
  let speedPerBoard = []
  let asicPerBoard  = []
  let heatPerBoard  = []
  let totalSpeed    = []
  let status        = []
  let firmware      = []
  for (var i in data){
    const category = data[i].Date
    categories.push(category)
    speedPerBoard.push({y: parseInt(i) + 1, text: `${formatBytes(data[i].Speed_One)} / ${formatBytes(data[i].Speed_Two)} / ${formatBytes(data[i].Speed_Three)}`})
    asicPerBoard.push({y: parseInt(i) + 1, text: `${data[i].Asic_One} / ${data[i].Asic_Two} / ${data[i].Asic_Three}`})
    heatPerBoard.push({y: parseInt(i) + 1, text: `${data[i].Heat_One} / ${data[i].Heat_Two} / ${data[i].Heat_Three}`})
    totalSpeed.push(data[i].Total_Speed)
    firmware.push({y: parseInt(i) +1, text: data[i].Firmware})
    status.push({y: parseInt(i) + 1, text: data[i].Status})
  }
  const options = {
    navigator: {
      enabled: false
    },
    title: {
      text: title,
      style:{
        color: '#b8c7ce',
        fontWeight: 'bold'
      },
      margin: 0
    },
    rangeSelector: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      visible: false,
      labels: {
        enabled: false
      }
    },
    series: [
      {
        name: "Hashrate",
        data: totalSpeed,
        fillColor: 'rgba(124, 181, 236, 0.75)',
        fillOpacity:1,
        type: 'area',
      },
      {
        name: "Speed",
        data: speedPerBoard,
        lineWidth: 0,
        opacity: 0
      },
      {
        name: "Asic",
        data: asicPerBoard,
        lineWidth: 0,
        opacity: 0
      },
      {
        name: 'Heat',
        data: heatPerBoard,
        lineWidth: 0,
        opacity: 0
      },
      {
        name: 'Status',
        data: status,
        lineWidth: 0,
        opacity: 0
      },
      {
        name: 'Firmware',
        data: firmware,
        lineWidth: 0,
        opacity: 0
      }
    ],
    tooltip: {
      formatter: function() {
        return (
          "Hashrate: "+ "<b>" + formatBytes(this.points[0].y, 2) + "</b><br>" +
          "Speed per board: " + "<b>" + this.points[1].point.text + "</b><br>" +
          "Asic per board: "+ "<b>" + this.points[2].point.text + "</b><br>" +
          "Heat per board: "+ "<b>" + this.points[3].point.text + "</b><br>" +
          "Status: "+ "<b>" + this.points[4].point.text + "</b><br>" +
          "Firmware: "+ "<b>" + this.points[5].point.text + "</b><br>" +
          "Time/Date: " + "<b>" + this.points[0].key +
          "</b>"
        );
      }
    },
    chart: {
        height: 250,
        backgroundColor: {
          linearGradient: { x1: 0},
          stops: [
              [0, '#0e1012'],
          ]
        }
    }
  }
  return options
}

import { setMinerTypeConstant } from '../constants/actions';
import typeService from '../services/typeService'
import { setErrorMessageAction } from './setErrorMessageAction';
import { setSuccessMessageAction } from './setSuccessMessageAction'

export const setMinerTypeAction = (body, token, client) => {
  return async(dispatch) => {
    const result = await typeService.setMinerType(body, token, client);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }
    else{
      dispatch(setSuccessMessageAction(result.msg));
    }
    dispatch(success(result))
  }
}


const success = (result) => ({
  type: setMinerTypeConstant,
  payload: result
})

import clientService from '../services/clientService';
import { deleteHouseConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
import { setSuccessMessageAction } from './setSuccessMessageAction';

export const deleteHouseAction = (token, client, id, region_id) => {
  return async(dispatch) => {
    const result = await clientService.deleteHouse(token, client, id, region_id);
    if(result.ok){
      dispatch(setSuccessMessageAction("Building successfully deleted"))
    }
    else{
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(deleteHouseSuccess(result))
  }
}

const deleteHouseSuccess = (houses) => ({
    type: deleteHouseConstant,
    payload: houses
})

import { setErrorMessage } from '../constants/actions';
export const setErrorMessageAction = (message) => {
  return dispatch =>{
    dispatch(sendErrorMessageSuccess(message))
    setTimeout(() => {
        dispatch(sendErrorMessageSuccess(""))
    }, 7000)
  }
}

const sendErrorMessageSuccess = (message) => ({
  type: setErrorMessage,
  payload: message
})

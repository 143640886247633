import {
  getReportDataConstant,
  getAverageReportData24Constant,
  getAverageReportData7daysConstant
}
from '../constants/actions'

const defaultState = {
  ok: true,
  api_version: 1.1,
  msg: '',
  data: {},
  average24Hours: {
    Idle: 0,
    Online: 0,
    Offline: 0,
    Total_Speed: 0,
    Performance: 0,
    Cards: 0,
  },
  average7Days: {
    Idle: 0,
    Online: 0,
    Offline: 0,
    Total_Speed: 0,
    Performance: 0,
    Cards: 0,
  }
}

export default(state = defaultState, action) =>{
  switch (action.type) {
    case getReportDataConstant:
        if(action.payload.ok){
          return{
            ok: true,
            api_version: action.payload.api_version,
            msg: action.payload.msg,
            data: action.payload.data,
            average24Hours: state.average24Hours,
            average7Days: state.average7Days
          }
        }
        return state
      break;
    case getAverageReportData24Constant:
      if(action.payload.ok && action.payload.data !== null){
        return{
          ok: true,
          api_version: action.payload.api_version,
          msg: action.payload.msg,
          data: state.data,
          average24Hours: action.payload.data,
          average7Days: state.average7Days
        }
      }
      return state
    case getAverageReportData7daysConstant:
      if(action.payload.ok && action.payload.data !== null){
        return{
          ok: true,
          api_version: action.payload.api_version,
          msg: action.payload.msg,
          data: state.data,
          average24Hours: state.average24Hours,
          average7Days:  action.payload.data
        }
      }
      return state
    default:
      return state
  }
}

import React from 'react';
import './style.css';
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import { getFavItemsAction } from '../../redux/actions/getFavItemsAction'
import { getAccessLevelsAction } from '../../redux/actions/getAccessLevelsAction'
const jwt = require('jwt-simple');
class SidebarOne extends React.Component {
  componentDidMount(){
    document.body.classList.remove("msb-x");
    this.props.getFavItemsAction(this.props.userStore.result)
    this.props.getAccessLevelsAction(this.props.userStore.result)
    const elements = document.getElementsByClassName("adc_sidebar-link")
    for(var i = 0; i < elements.length; i++){
      elements[i].addEventListener("mouseover", this.onMinimizedHover)
      elements[i].addEventListener("mouseleave", this.onMinimizedLeave)
    }
    if(!this.props.clientStore.sidebaropen){
      document.body.classList.add('msb-x')
    }
  }
  componentWillUnmount = () => {
    const elements = document.getElementsByClassName("adc_sidebar-link")
    for(var i = 0; i < elements.length; i++){
      elements[i].removeEventListener("mouseover", this.onMinimizedHover)
      elements[i].removeEventListener("mouseleave", this.onMinimizedLeave)
    }
  }
  /*
  componentWillUpdate = () => {
    const elements = document.getElementsByClassName("adc_sidebar-link")
    for(var i = 0; i < elements.length; i++){
      elements[i].removeEventListener("mouseover", this.onMinimizedHover)
      elements[i].removeEventListener("mouseleave", this.onMinimizedLeave)
    }
  }
  */
  componentDidUpdate = () => {
    const elements = document.getElementsByClassName("adc_sidebar-link")
    for(var i = 0; i < elements.length; i++){
      elements[i].addEventListener("mouseover", this.onMinimizedHover)
      elements[i].addEventListener("mouseleave", this.onMinimizedLeave)
    }
  }
  onMinimizedLeave = (e) => {
      const node = e.target
      const parent = node.parentElement
      const test = node.querySelector('b')
      if(test){
        test.classList.remove('adc_sidebar-showLink')
      }
      if(parent){
        parent.classList.add('adc_sidebar-lidefault')
        parent.classList.remove('adc_sidebar-lihover')
      }
  }
  onMinimizedHover = (e) => {
    const node = e.target
    const parent = node.parentElement
    const test = node.querySelector('b')
    if(test){
      test.classList.add('adc_sidebar-showLink')
    }
    if(parent){
      parent.classList.remove('adc_sidebar-lidefault')
      parent.classList.add('adc_sidebar-lihover')
    }
  }
  toggleSideBar = () => {
    document.body.classList.toggle("msb-x");
    //document.getElementById("navigation-tree").classList.toggle('navigation-slide-left');
    document.getElementById("sideBar-wrapper").classList.toggle("widthNone")
    document.getElementById("sidebar-hamburger").classList.toggle("leftZero")
    //document.getElementById("msb").classList.toggle("msb-x");
  }
  toggleHouse = () => {
    document.getElementById("favHouseLinks").classList.toggle("noDisplay")
  }
  togglePods = () => {
    document.getElementById("favPodLinks").classList.toggle("noDisplay")
  }
  toggleFavorite = () => {
    const house = document.getElementById("fav-house-div")
    const pod = document.getElementById("fav-pod-div")
    if(house){
      house.classList.toggle("noDisplay")
    }
    if(pod){
      pod.classList.toggle("noDisplay")
    }
  }
  renderPod = (link) => {

  }
  render() {
    const { result, userData } = this.props.userStore;
    const userLevel = userData.level? userData.level: 0
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    let itemStore, houseStore, podStore = false;
      if(this.props.favItemStore !== undefined){
        if(this.props.favItemStore.data !== undefined){
        itemStore = true;
        if(this.props.favItemStore.data.houses !== undefined){
          houseStore = true;
        }
        if(this.props.favItemStore.data.pods !== undefined){
          podStore = true;
        }
      }
    }
    //const { logLink, poolLink, mapLink, clientLink, userLogLink} = this.state;
    /*
      possible to move this into componentDidMount?, only issue that i see is that because React
      renders one time before the componentDidMount we will get one render were level in not correct
    */
    let level = 0;
    if(this.props.clientStore.currentClient != ''){
      let currentClientInfo = this.props.clientStore.clients.filter((item) => item.client === this.props.clientStore.currentClient);
      if(currentClientInfo.length > 0){
        level = currentClientInfo[0].level;
      }
    }
    let accessLevel = this.props.accessStore.filter((item) => item.Access === 'User_Logs')
    accessLevel = accessLevel.length > 0?accessLevel[0].Level:2
    const link = `/client/${this.props.clientStore.currentClient}/region/${this.props.clientStore.currentRegion}`
    const logLink = `${link}/log`;
    let configureLink = `${link}/buildings`
    if(userLevel === 0 && databaseTier == 0){
      configureLink = `${link}/alerts`
    }
    if(userLevel === 0 && databaseTier == 1){
      configureLink = `${link}/pools`
    }
    const mapLink = `${link}/map`
    const userLogLink = `${link}/userLog`
    let maintenanceLink = `${link}/inventory`
    const adminLink = `/admin`
    const heatmapLink = `${link}/heatmaps`
    const minersLink = `${link}/miners`
    const flagsLink  = `${link}/flags`
    return (
          this.props.clientStore.currentClient !== ''? (
            <aside className="msb">
              <section className="adc_sidebar-bar">
                <ul className="adc_sidebar-menu">
                  <li className="adc_sidebar-header">Maintenance</li>
                  <li className="adc_sidebar-lidefault">
                    <NavLink to={link} id="clientLink" className="adc_sidebar-link">
                      <i className="fa fa-user"/>
                      <b>{this.props.clientStore.currentClient.split('_').join(' ')}</b>
                    </NavLink>
                  </li>
                  <li className="adc_sidebar-lidefault">
                    <NavLink to={minersLink} id="minersLink" className="adc_sidebar-link">
                      <i className="fa fa-desktop"/>
                      <b>Miners</b>
                    </NavLink>
                  </li>
                  <li className="adc_sidebar-lidefault">
                    <NavLink to={flagsLink} id="flagsLink" className="adc_sidebar-link">
                      <i className="fa fa-flag"/>
                      <b>Miner Flags</b>
                    </NavLink>
                  </li>
                  <li className="adc_sidebar-lidefault">
                    <NavLink to={heatmapLink} id="heatmapLink" className="adc_sidebar-link">
                      <i className="fa fa-map"/>
                      <b>Heatmaps</b>
                    </NavLink>
                  </li>
                  <li className="adc_sidebar-header">Configure</li>
                  <li className="adc_sidebar-lidefault">
                    <NavLink to={configureLink} id="configureLink" className="adc_sidebar-link">
                      <i className="fa fa-cogs"/>
                      <b>Configure</b>
                    </NavLink>
                  </li>
                  <li className="adc_sidebar-lidefault">
                    <NavLink to={mapLink} id="mapLink" className="adc_sidebar-link">
                      <i className="fa fa-map-marker"></i>
                      <b>Map Miners</b>
                    </NavLink>
                  </li>
                  {!(userLevel === 0 && databaseTier < 2)?(
                    <li className="adc_sidebar-lidefault">
                      <NavLink to={maintenanceLink} id="maintenanceLink" className="adc_sidebar-link">
                        <i className="fa fa-wrench" aria-hidden="true"/>
                        <b>Maintenance</b>
                      </NavLink>
                    </li>
                  ):(<></>)}
                  {level >= accessLevel ?(
                    !(userLevel === 0 && databaseTier < 1)?(
                      <li className="adc_sidebar-lidefault">
                        <NavLink to={userLogLink} id="userLogLink" className="adc_sidebar-link">
                          <i className="fa fa-history"/>
                          <b>User Log</b>
                        </NavLink>
                      </li>
                    ):(<></>)
                  ): <></>}
                </ul>
              </section>
            </aside>
          ): (
              <></>
          )
    );
  }
}

const mapStateToPops = (props) => ({
  clientStore: props.clientReducer,
  userStore: props.userReducer,
  favItemStore: props.favItemReducer,
  accessStore: props.accessReducer.levels
})
export default connect(mapStateToPops,{
  getFavItemsAction,
  getAccessLevelsAction
})(SidebarOne);
/*
<div className="sidebar-header-div">
  {userData? (
    <NavLink to={'/changepassword'}>
      {userData.user}
    </NavLink>
  ):(<></>)}
</div>



<a id="list-item-favorite" onClick={e => this.toggleFavorite()}><i className="fa fa-star"/>Favorites</a>
<div className="noDisplay" id="fav-house-div">
<a id="msbo" onClick={e => this.toggleHouse()}><i className="fa fa-home"/>Houses</a>
{
  (itemStore) ? (
    <div id='favHouseLinks' className='noDisplay'>
    {(
      (houseStore) ? (
        <div>
        {
          this.props.favItemStore.data.houses.map((item, key) => {
              if(item !== null){
                return <NavLink key={key} to={item.Link} exact> {item.Linkname}<br/></NavLink>
              }
        })
        }
        </div>
      ):
      (<p/>)

    )}
    </div>
  ):
  (<p/>)
}
</div>
<div className="noDisplay" id="fav-pod-div">
<a id="msbo" onClick={this.togglePods}><i className="fa fa-cubes"/>Pods</a>
{
  (itemStore) ? (
    <div id='favPodLinks' className='noDisplay'>
    {(
      (podStore) ? (
        <div>
        {
          this.props.favItemStore.data.pods.map((item, key) => {
              if(item !== null){
                return <NavLink key={key} exact to={item.Link}> {item.Linkname}<br/></NavLink>
              }
        })
        }
        </div>
      ):
      (<p/>)

    )}
    </div>
  ):
  (<p/>)
}
</div>


<a id="list-item-favorite" onClick={e => this.toggleFavorite()}><i className="fa fa-star"/>Favorites</a>
<div className="noDisplay" id="fav-house-div">
  <a onClick={e => this.toggleHouse()}><i className="fa fa-home"/>Buildings</a>
  {
    (itemStore) ? (
      <div id='favHouseLinks' className='noDisplay'>
      {(
        (houseStore) ? (
          <div className="favItemList-div">
          {
            this.props.favItemStore.data.houses.map((item, key) => {
                if(item !== null){
                  return <NavLink key={key} to={item.Link} exact> {item.Linkname}<br/></NavLink>
                }
          })
          }
          </div>
        ):
        (<p/>)

      )}
      </div>
    ):
    (<p/>)
  }
</div>


<div className="noDisplay" id="fav-pod-div">
  <a id="msbo" onClick={this.togglePods}><i className="fa fa-cubes"/>Pods</a>
  {
    (itemStore) ? (
      <div id='favPodLinks' className='noDisplay'>
      {(
        (podStore) ? (
          <div className="favItemList-div">
          {
            this.props.favItemStore.data.pods.map((item, key) => {
                if(item !== null){
                  return <NavLink exact to={item.Link} key={key}> {item.Linkname}<br/></NavLink>
                }
          })
          }
          </div>
        ):
        (<p/>)

      )}
      </div>
    ):
    (<p/>)
  }
</div>



<div className="sidebar-header-div">
  {userData? (
    <NavLink to={'/changepassword'}>
      {userData.user}
    </NavLink>
  ):(<></>)}
</div>




<nav class="mnb navbar navbar-default navbar-fixed-top">
    <div class="container-fluid">
      <div class="navbar-header">
        <div style="padding: 15px 0;">
           <a href="#" id="msbo"><i class="ic fa fa-bars"></i></a>
        </div>
      </div>
    </div>
</nav>




<div className="left leftbar" id="leftbar">
  <NavLink to={clientLink} className="leftbarLink">{client}</NavLink>
  <NavLink to={logLink} className="leftbarLink">Log View</NavLink>
  <NavLink to={poolLink} className="leftbarLink">Configure</NavLink>
  <NavLink to={mapLink} className="leftbarLink">Map Miners</NavLink>
  {level === 2 ?(
    <NavLink to={userLogLink} className="leftbarLink">User Log</NavLink>
  ): <></>}
</div>
*/

import { pushHistoryConstant } from '../constants/actions'

export const pushHistoryAction = (location) => {
  return dispatch => {
    dispatch(pushHistoySuccess(location))
  }
}

const pushHistoySuccess = (location) => ({
  type: pushHistoryConstant,
  payload: location
})

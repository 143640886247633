import { postHouseSubnetConstant } from '../constants/actions';
import service from '../services/houseSubnetConService';
import { setErrorMessageAction } from './setErrorMessageAction'

export const postHouseSubnetConAction = (body, houseid, token, client) => {
  return async(dispatch) => {
    const result = await service.postConnections(body, houseid, token, client);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(postConnectionsSuccess(result))
  }
}


const postConnectionsSuccess = (result) => ({
  type: postHouseSubnetConstant,
  payload: result
})

import poolService from '../services/poolService'
import { deletePoolConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
import { setSuccessMessageAction } from './setSuccessMessageAction';

export const deletePoolAction = (token, client, id) => {
   return async(dispatch) => {
     const result = await poolService.deletePool(token, client, id);
     if(result.ok){
       dispatch(setSuccessMessageAction(result.msg))
     }
     else{
       dispatch(setErrorMessageAction(result.msg));
     }
     dispatch(deletePoolSuccess(result))
   }
}

const deletePoolSuccess = (pools) => ({
  type: deletePoolConstant,
  payload: pools
})

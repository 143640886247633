import { postPowerCutOrderConstant } from '../constants/actions'
import service from '../services/powerCutOrderService'



export const postPowerCutOrderAction = (token, body, pageSize, pageNumber, orderBy, acending, searchValue) => {
  return async(dispatch) => {
    const result = await service.postPowerCutOrder(token, body, pageSize, pageNumber, orderBy, acending, searchValue)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: postPowerCutOrderConstant,
  payload: result
})

import { sendTicketConstant } from '../constants/actions'
import service from '../services/alertService';
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'

export const sendTicketAction = (token, body) => {
  return async(dispatch) => {
    const result = await service.sendTicket(token, body)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    else{
      dispatch(setSuccessMessageAction(result.msg))
    }
    dispatch({
      type: sendTicketConstant,
      payload: result
    })
  }
}

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { adminView } from '../../helper/tabs'
import { userGroupSwitch  } from '../../helper/functions'
import { getDatabaseInfoAction } from '../../redux/actions/getDatabaseInfoAction'
import { adjustUsersPrivilegesAction } from '../../redux/actions/adjustUsersPrivilegesAction'
import { adjustPerformanceDropAction } from '../../redux/actions/adjustPerformanceDropAction'
import { addUsersToDatabaseAction } from '../../redux/actions/addUsersToDatabaseAction'
import { clearCurrentClientAction } from '../../redux/actions/clearCurrentClientAction'
import { getAllUsersAction } from '../../redux/actions/getAllUsersAction'
import { createRegionAction } from '../../redux/actions/createRegionAction'
import './style.css'


class DatabasesDetailsView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      addModalOpen: false,
      addRegionModalOpen: false,
      editUserModalOpen: false,
      editInfoModalOpen: false,
      users: [],
      userGroup: '',
      editUser: '',
      editUserCurrentGroup: '',
      editUserGroup: '',
      editPerformance: '',
      editName: '',
      editTier: '',
      region: ''
    }
  }
  componentDidMount = () => {
    this.props.clearCurrentClientAction()
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
    this.props.getDatabaseInfoAction(this.props.userStore.result, this.props.match.params.id)
    this.props.getAllUsersAction(this.props.userStore.result, 999999, 1, 'Username', false,'')
  }
  componentWillUnmount = () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history, `/admin/`)
  }
  onBack = () => {
    this.props.history.goBack()
  }
  onAddUsers = (action) => {
    const { users, userGroup } = this.state;
    if(userGroup === ''){
      alert("You need to pick a user group")
      return
    }
    if(users.length == 0){
      alert("You need to pick users")
      return
    }
    this.props.addUsersToDatabaseAction(this.props.userStore.result, this.props.match.params.id, {users: users.map((item) => item.value), level: userGroup})
    if(action === 'add_users_another'){
      this.setStat({
        users: [],
        userGroup: ''
      })
    }
    else{
      this.setState({
        users: [],
        userGroup: '',
        addModalOpen: false
      })
    }
  }
  onAddUsersInput = (e) => {
    if(e.hasOwnProperty("target")){
      this.setState({
        [e.target.name]: e.target.value
      })
    }
    else{
      this.setState({
        users: e
      })
    }
  }
  onUserEdit = (username) => {
    const user = this.props.databasestore.Users.filter((item) => item.Username === username)
    if(user.length > 0){
      this.setState({
        editUserModalOpen: true,
        editUser: username,
        editUserCurrentGroup: userGroupSwitch(user[0].Level)
      })
    }
  }
  onEditUser = () => {
    const { editUser, editUserGroup } = this.state;
    if(editUserGroup == ''){
      alert("Pick a user group")
      return
    }
    this.props.adjustUsersPrivilegesAction(this.props.userStore.result, {user: editUser, database: this.props.match.params.id, level: editUserGroup })
    this.setState({
      editUser: '',
      editUserGroup: '',
      editUserCurrentGroup: '',
      editUserModalOpen: false
    })
  }
  onDatabaseEditClick = () => {
    this.setState({
      editPerformance: this.props.databasestore.Performance_Drop_Alerting,
      editName: this.props.match.params.name,
      editTier: this.props.databasestore.Tier,
      editInfoModalOpen: true
    })
  }
  onInfoEdit = () => {
    const { editPerformance, editName, editTier, editInfoModalOpen } = this.state
    if(editPerformance == '' || editPerformance === 0){
      alert("Performance cannot be 0 %")
      return
    }
    if(editPerformance < 1){
      alert("Performance cannot be less then 1 %")
      return
    }
    if(editPerformance > 100){
      alert("Performance cannot be larger than 100%")
      return
    }
    this.props.adjustPerformanceDropAction(this.props.userStore.result, this.props.match.params.id, {Performance_Drop_Alerting: editPerformance})
    this.setState({
      editInfoModalOpen: false,
      editPerformance: '',
      editName: '',
      editTier: ''
    })
  }
  onCreateRegion = () => {
    const { region } = this.state;
    if(region == ''){
      alert("Name is empty")
      return
    }
    const exists = this.props.databasestore.Regions.filter((item) => item.Name.toLowerCase() === region.toLowerCase()).length === 1
    if(exists){
      alert("Name already exists")
      return
    }
    this.props.createRegionAction(this.props.userStore.result, this.props.match.params.id, {Name: region})
    this.setState({
      addRegionModalOpen: false,
      region: ''
    })
  }
  render(){
     const modalStyle = {
       modal: {
         width: '80vw',
         background: '#141619'
       }
     };
     const currentUsers = this.props.databasestore.Users.map((item) => item.Username)
     const userPick = this.props.adminStore.users.data.filter((item) => item.Level !== 2).filter((item) => currentUsers.indexOf(item.Username) == -1)
     const editUserColumns = [
      {Name: "Username", PropertyName: 'editUser', Type: 'text-disabled', Value: this.state.editUser, Options: []},
      {Name: "Current Group", PropertyName: 'editUserCurrentGroup', Type: 'text-disabled', Value: this.state.editUserCurrentGroup, Options: []},
      {Name: "New Group", PropertyName: 'editUserGroup', Type: 'select', Value: this.state.editUserGroup,
      Options: [{text: "----------", value: ''},
        {text: "Select Users", value: 0},
        {text:"Insert/Update Users", value: 1},
        {text:"Administrator", value: 2}
      ]}]
    const createRegionColumns = [{Name: "Name", PropertyName: 'region', Type: 'text', Value: this.state.region, Options: []}]
    const editInfoColumns = [
    {Name: "Name", PropertyName: 'editName', Type: 'text-disabled', Value: this.state.editName, Options: []},
    {Name: "Tier", PropertyName: 'editTier', Type: 'text-disabled', Value: this.state.editTier, Options: []},
    {Name: "Version", PropertyName: '', Type: 'text-disabled', Value: this.props.databasestore.Version, Options: []},
    {Name: "Performance", PropertyName: 'editPerformance', Type: 'int', Value: this.state.editPerformance, Options: []},    ]
    const addUserColumns = [
     {Name: "Users", PropertyName: 'users', Type: 'multi-select', Value: this.state.users,
     Options: userPick.map((item) => ({label: item.Username, value: item.Username}))},
     {Name: "User Group", PropertyName: 'userGroup', Type: "select", Value: this.state.userGroup,
       Options:
         [{text: "----------", value: ''},
         {text: "Select Users", value: 0},
         {text:"Insert/Update Users", value: 1},
         {text:"Administrator", value: 2}
       ]}]
      const usersColumns = [
        {field: 'Username', value: 'Username'},
        {field: 'Level', value: 'Level'},
      ]
      const pannelData = [
       {Name: 'Database Name', Value: this.props.databasestore.Database_Name},
       {Name: 'Database Tier', Value: this.props.databasestore.Tier},
       {Name: 'Database Version', Value: this.props.databasestore.Version},
       {Name: 'Performance Drop Alerting', Value: `${this.props.databasestore.Performance_Drop_Alerting} %`}
      ]
     const pannelColumns = [
       {field: 'Name', Value: 'Name'},
       {field: 'Value', Value: 'Value'}]
    const regionsColumns = [
      {field: "ID", value: "ID"},
      {field: "Name", value: "Name"}
    ]
    const userButtons = [
      {class: 'ViewBody-button-edit', onClick: this.onUserEdit, type: 'Edit', extractKey: 'Username'}
    ]
    return(
      <div className="adminView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Server Admin"
          subtitle="Manage scanner, databases & more"
          tabs={adminView}
          active="Databases"
          onClick={this.onClick}
        />
        <div className="AdminView-content">
          <ViewBody
            data={pannelData}
            columns={pannelColumns}
            pannel
            createtext="Edit info"
            onCreate={this.onDatabaseEditClick}
            onBack={this.onBack}
          />
          <ViewBody
            data={this.props.databasestore.Regions}
            columns={regionsColumns}
            list
            createtext="Add region"
            onCreate={e => this.setState({ addRegionModalOpen: true })}
            onItemClick={(name, string) => this.props.history.push(`${this.props.match.params.id}/${name}/${string}`)}
            itemClickInfo={{name: 'region', property: 'ID'}}
          />
          <ViewBody
            data={this.props.databasestore.Users}
            columns={usersColumns}
            buttons={userButtons}
            list
            createtext="Add users"
            onCreate={e => this.setState({ addModalOpen: true })}
          />
        </div>
        <Modal
          open={this.state.addModalOpen}
          center
          onClose={() => this.setState({addModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add users"
            fields={addUserColumns}
            addButton={true}
            extraButton={true}
            extraButtonText="Add Another"
            buttonText="Add"
            onAddClick={this.onAddUsers}
            onInputChange={this.onAddUsersInput}
            action="add_users"
            extraAction="add_users_another"
          />
        </Modal>
        <Modal
          open={this.state.addRegionModalOpen}
          center
          onClose={() => this.setState({addRegionModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create region"
            fields={createRegionColumns}
            addButton={true}
            buttonText="Create"
            onAddClick={this.onCreateRegion}
            onInputChange={e => onInputChange(e, this)}
            action="create_region"
          />
        </Modal>
        <Modal
          open={this.state.editUserModalOpen}
          center
          onClose={() => this.setState({editUserModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Adjust user privileges"
            fields={editUserColumns}
            addButton={true}
            buttonText="Adjust"
            onAddClick={this.onEditUser}
            onInputChange={e => onInputChange(e, this)}
          />
        </Modal>
        <Modal
          open={this.state.editInfoModalOpen}
          center
          onClose={e => this.setState({editInfoModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Adjust Info"
            fields={editInfoColumns}
            addButton={true}
            buttonText="Edit"
            onAddClick={this.onInfoEdit}
            onInputChange={e => onInputChange(e, this)}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  userStore: props.userReducer,
  databasestore: props.databasesReducer,
  adminStore: props.adminReducer
})

export default connect(mapStateToProps, {
  getDatabaseInfoAction,
  getAllUsersAction,
  addUsersToDatabaseAction,
  adjustUsersPrivilegesAction,
  clearCurrentClientAction,
  adjustPerformanceDropAction,
  createRegionAction
})(DatabasesDetailsView)

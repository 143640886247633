import React from 'react';
import BootstrapPaging from '../BootstrapPaging'
import './style.css'

const createOption = (array) => {
  let retVal = []
  array.map((item, key) => {
    retVal.push(<option key={key}>{item.Name}</option>)
  })
  return retVal;
}
const InventoryTable = ({ items, types, minerType, fans, hashboards, controlboards, cables, psu, onSubmit, onOverride, onInputChange, overriding, displayFooter, accessAdd, accessSubtract, accessOverride,  privilegeLevel, numberOfPrivileges }) => {
    return(
      <table className="table-bordered" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th className="inventory-th">Fans</th>
            <th className="inventory-th">Hashboards</th>
            <th className="inventory-th">Controlboards</th>
            <th className="inventory-th">Cables</th>
            <th className="inventory-th">PSU</th>
            <th className="inventory-th">Miner Type</th>
          </tr>
        </thead>
        <tbody>
        {
          items.map((item) => (
            <tr>
              <td className="inventory-td">{item.Fans}</td>
              <td className="inventory-td">{item.Hashboards}</td>
              <td className="inventory-td">{item.Controlboards}</td>
              <td className="inventory-td">{item.Cables}</td>
              <td className="inventory-td">{item.PSU}</td>
              <td className="inventory-td">{item.Miner_Type}</td>
            </tr>
          ))
        }
        </tbody>
        {displayFooter?(
          <tfoot>
            <tr>
              <td>
                <input type="text" className="custom-formcontrol" placeholder="Enter Fans" name="fans" value={fans !== 0? fans: null} onInput={e => onInputChange(e)}/>
              </td>
              <td>
                <input type="text" className="custom-formcontrol" placeholder="Enter Hashboards" name="hashboards" value={hashboards !== 0? hashboards: null} onInput={e => onInputChange(e)}/>
              </td>
              <td>
                <input type="text" className="custom-formcontrol" placeholder="Enter Controlboards" name="controlboards" value={controlboards !== 0? controlboards: null} onInput={e => onInputChange(e)}/>
              </td>
              <td>
                <input type="text" className="custom-formcontrol" placeholder="Enter Cables" name="cables" value={cables !== 0? cables: null} onInput={e => onInputChange(e)}/>
              </td>
              <td>
                <input type="text" className="custom-formcontrol" placeholder="Enter PSU" name="psu" value={psu !== 0? psu: null} onInput={e => onInputChange(e)}/>
              </td>
              <td>
                <select className="custom-formcontrol" name="minerType" defaultValue={minerType} onInput={e => onInputChange(e)}>
                  {createOption(types)}
                </select>
              </td>
            </tr>
            <tr>
              {privilegeLevel >= accessAdd?(
                <td colspan={`${6/numberOfPrivileges}`}>
                  <button type="button" className="button" style={{marginRight:2}} onClick={(e) => onSubmit("addition")}>Add</button>
                </td>
              ):(<></>)}
              {privilegeLevel >= accessSubtract?(
                <td colspan={`${6/numberOfPrivileges}`}>
                  <button type="button" className="button" style={{marginRight:2}} onClick={(e) => onSubmit("subtract")}>Subtract</button>
                </td>
              ):(<></>)}
              {privilegeLevel >= accessOverride?(
                <td colspan={`${6/numberOfPrivileges}`}>
                  <button type="button" className="button" onClick={(e) => onOverride(overriding)}>{overriding?("Submit"):("Override")}</button>
                </td>
              ):(<></>)}
            </tr>
          </tfoot>
        ):(
          <></>
        )}
      </table>
    )
}
export default InventoryTable;

import { getRegionsConstant } from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const getRegionsAction = (token) => {
  return async(dispatch) => {
    const result = await service.getRegions(token)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}
const success = (result) => ({
  type: getRegionsConstant,
  payload: result
})

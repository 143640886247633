import { getRegionInfoConstant } from '../constants/actions'
import adminService from '../services/adminService';
import { setErrorMessageAction } from './setErrorMessageAction';


export const getRegionInfoAction = (token, database, region_id) => {
  return async(dispatch) => {
    try {
      const result = await adminService.getRegionInfo(token, database, region_id)
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(success(result));
    }
    catch (err) {
      console.log(err)
    }
  }
}
const success = (result) => ({
  type: getRegionInfoConstant,
  payload: result
})

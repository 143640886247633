import React from 'react'
import LeftBar from '../../Components/LeftBar'
import MapInput from '../../Components/MapInput';
import MapConfigureForm from '../../Components/MapConfigureForm';
import DeviceMapping from '../../Components/DeviceMapping'
import { setErrorMessageAction } from '../../redux/actions/setErrorMessageAction';
import { setSuccessMessageAction } from '../../redux/actions/setSuccessMessageAction';
import { mapMinersAction } from '../../redux/actions/mapMinersAction';
import { getHousesAction } from '../../redux/actions/getHousesAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { getPodsLimitedAction } from '../../redux/actions/getPodsLimitedAction'
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { getHouseSubnetConAction } from '../../redux/actions/getHouseSubnetConAction'
import { connect } from 'react-redux'
import socketIOClient from "socket.io-client";
import * as XLSX from 'xlsx';
import './style.css';
class MapView extends React.Component {
  constructor(props){
    super(props);
    this._isMounted = false
    this.state = {
      errorMessage: '',
      successMessage: '',
      token: '',
      client: '',
      multiSelected: false,
      items:[{
        MAC_Address: '',
        Location: '1-1-1',
        rackNum: 1,
        shelfNum: 1,
        minerNum: 1,
        minerType: ''
      }],
      configure:{
        houseName:'',
        podName: '',
        startingRack: 1,
        startingShelf: 1,
        startingMiner: 1,
        shelfNum: 1,
        minerNum: 1,
        gapsShelf: 'none',
        gapsShelf_value: 'none',
        minerType: ''
      },
      extraMinersMapped: 0,
      gapsTriggered: 0,
      selectedFile: null,
      fileData:[],
      socket: null
    }
  }
  componentDidMount = async() => {
    this._isMounted = true
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    const element = document.getElementById("mapLink")
    if(element){
      element.classList.add("itemSelected");
    }
    this.props.getTypesAction(this.props.token, this.props.match.params.name)
    this._isMounted && await this.props.getHousesAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id);
    let house = this.props.houseStore.houses.length > 0?(this.props.houseStore.houses[0].ID):(0)
    this._isMounted && await this.props.getHouseSubnetConAction(this.props.token, this.props.match.params.name, house);
    if(this._isMounted){
      const socket = process.env.REACT_APP_REPORT_PATH ? socketIOClient.connect(`wss://${process.env.REACT_APP_REPORT_SERVER_URL}`, {path: process.env.REACT_APP_REPORT_PATH}): socketIOClient.connect(`wss://${process.env.REACT_APP_REPORT_SERVER_URL}`)

      socket.on('connect', () => {
        const scanners = this.props.houseSubnetConnections.connections.map((item) => item.Subnet_Name)
        socket.emit('userConnect', JSON.stringify({scanners}))
      })
      if(this._isMounted){
        this.setState({client: this.props.match.params.name, token: this.props.token, socket})
      }
      else{
        socket.disconnect()
      }
      if(this.props.houseStore.houses.length > 0){
        let { configure } = this.state;
        const house = this.props.houseStore.houses[0];
        configure.houseName = house.Name;
        this._isMounted && this.setState({configure: configure})
        this._isMounted && await this.props.getPodsLimitedAction(this.props.token, house.Name, this.props.match.params.name, this.props.match.params.region_id);
        if(this.props.podStore.pods.length > 0){
          configure.podName = this.props.podStore.pods[0].Name;
          this._isMounted && this.setState({configure: configure})
        }
      }
      //const socket = socketIOClient.connect(`${"10.15.159.250"}:55555`);
      socket.on('reconnect_attempt', (attemptNumber) => {
        if(attemptNumber == 2){
          alert("Did not manage to connect to IP report proxy")
          socket.disconnect()
        }
      });
      socket.on("IpReport", data => {
        let { items } = this.state
        if(items.length === 1 && items[0].MAC_Address === ''){
            items[0].MAC_Address = data
            this.setState({items})
        }
        else{
          const shouldAdd = items.filter((item) => item.MAC_Address == data).length == 0
          if(shouldAdd) this.onAddInput({mac:data})
        }
      });
    }
  }
  componentWillUnmount(){
    this._isMounted = false
    const { socket } = this.state
    if(socket){
      socket.disconnect()
    }
    const element = document.getElementById("mapLink");
    if(element){
      element.classList.remove("itemSelected");
    }
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
    this.scrollToBottom();
  }
  //Everytime an input is changed in each input in the Input liist this is run to change the Location for that specific miner.
  onInput = (e, objNum) =>{
    let { items } = this.state;
    items[objNum][e.target.name] = e.target.value
    items[objNum]["Location"] = `${items[objNum]['rackNum']}-${items[objNum]['shelfNum']}-${items[objNum]['minerNum']}`
    this.setState({ items: items })
  }
  //This truns when ever an input in the Position Info tab is altered.
  onInputConfigure = async (e) =>{
    let { configure, items, socket } = this.state;
    configure[e.target.name] = e.target.value;
    if(items.length > 0){
      items[0]['rackNum'] = configure['startingRack'];
      items[0]['shelfNum'] = configure['startingShelf'];
      items[0]['minerNum'] = configure['startingMiner'];
      items[0]['Location'] = `${configure['startingRack']}-${configure['startingShelf']}-${configure['startingMiner']}`
    }
    if(e.target.name === "houseName"){
      const { token, client } = this.state;
      const house = this.props.houseStore.houses.filter((item) => item.Name === e.target.value);
      await this.props.getHouseSubnetConAction(this.props.token, this.props.match.params.name, house[0].ID);
      const scanners = this.props.houseSubnetConnections.connections.map((item) => item.Subnet_Name)
      socket.emit('changeHouse', JSON.stringify({scanners}))
      console.log(scanners)
      await this.props.getPodsLimitedAction(token, house[0].Name, client, this.props.match.params.region_id);
      if(this.props.podStore.ok){
        if(this.props.podStore.pods.length > 0){
          configure["podName"] = this.props.podStore.pods[0].Name;
        }
      }
    }
    this.setState({configure, items})
  }

  timeoutError = (error) => {
    this.setState({errorMessage: error})
    this.timeoutId = setTimeout(function(){
      this.setState({errorMessage: ''})
    }.bind(this), 3000);
  }
  //When the Submit button is pressed this function is called to submit all the mapping for the miners.
  onFormSubmit = async(e) => {
    const { configure } = this.state;
    let { token, client, items } = this.state;
    if(items.length === 1 && items[0].MAC_Address === ''){
      this.props.setErrorMessageAction("MAC_Address is missing");
      return;
    }
    if(configure.houseName === ''){
      this.props.setErrorMessageAction("The House Name configure option is required");
      return;
    }
    else if(configure.podName === ''){
      this.props.setErrorMessageAction("the Pod Name configure option is required");
      return;
    }
    else{
      let podID = JSON.parse(JSON.stringify(this.props.podStore.pods));
      let houseID = JSON.parse(JSON.stringify(this.props.houseStore.houses));
      podID = podID.filter((item) => item.Name === configure.podName);
      houseID = houseID.filter((item) => item.Name === configure.houseName)
      items = items.filter((item) => item.MAC_Address && item.MAC_Address.length !== 0)
      await this.props.mapMinersAction(token, client, {Pod_ID: podID[0].ID, House_ID: houseID[0].ID, items: items});
      if(this.props.mapResult.ok){
        const { success } = this.props.mapResult;
        if(success.hasOwnProperty("macsNotFound")){
          const { macsNotFound } = success;
          this.props.setErrorMessageAction(`number of macs not found: ${macsNotFound.length}`)
        }
        if(success.hasOwnProperty("unSuccessfulLocations")){
          let items = []
          const { unSuccessfulLocations } = success;
          unSuccessfulLocations.map((item) => {
            let split = item.Location.split('-');
            const newObj = {
              MAC_Address: item.MAC_Address,
              Location: item.Location,
              rackNum: split[0],
              shelfNum: split[1],
              minerNum: split[2],
              minerType: item.minerType
            }
            items.push(newObj);
          })
          this.setState({items: items});
          this.props.setSuccessMessageAction(`success: ${success.msg}, number of locations taken: ${unSuccessfulLocations.length}`)
        }
        else{
          const items = [{
              MAC_Address: '',
              Location: `${configure.startingRack}-${configure.startingShelf}-${configure.startingMiner}`,
              rackNum: configure.startingRack,
              shelfNum: configure.startingShelf,
              minerNum: configure.startingMiner,
              minerType: configure.minerType
            }]
          this.props.setSuccessMessageAction(success.msg);
          this.setState({items})
        }
      }
    }
  }
  //Adds a new input when the button Add Input is pressed
  onAddInput = (e) =>{
    let { items, configure, extraMinersMapped } = this.state;
    let gapsMiners = 0
    if(configure['gapsShelf'] !== 'none' && configure['gapsShelf_value'] !== 'none' &&  items[items.length-1]['shelfNum'] == configure['gapsShelf']){
      gapsMiners = configure['minerNum'] - configure['gapsShelf_value']
      if(Math.floor((items[items.length-1]['minerNum']) / parseInt(configure["gapsShelf_value"])) == 1){
        extraMinersMapped = extraMinersMapped + gapsMiners
      }
    }
    let minerNum = parseInt(configure.minerNum) == 1 ? 1: ((items.length-1)+parseInt(configure.startingMiner)+extraMinersMapped) % parseInt(configure.minerNum) + 1
    minerNum = configure['gapsShelf'] == items[items.length-1]['shelfNum'] ? ((parseInt(items[items.length-1]['minerNum'])) % parseInt(configure['gapsShelf_value'])) + 1: minerNum
    let shelfNum = 0;
    let rackNum = 0
    if(items.length > 0){
      let newShelfNum = parseInt(items[items.length-1]['shelfNum']) + Math.floor((items[items.length-1]['minerNum']) / (parseInt(configure['gapsShelf']) === parseInt(items[items.length-1]['shelfNum'])? configure.minerNum - gapsMiners: configure.minerNum));
      shelfNum = (newShelfNum-1) % configure.shelfNum + 1
      let doneMapping = parseInt(configure.startingShelf-1)*parseInt(configure.minerNum)
      rackNum = parseInt(configure.minerNum) == 1 && parseInt(configure.shelfNum) == 1 ?
                parseInt(items[items.length-1]['rackNum']) + 1:
                parseInt(configure.startingRack) + Math.floor(((items.length-1)+
                doneMapping+parseInt(configure.startingMiner)) / ((parseInt(configure.minerNum) * parseInt(configure.shelfNum) - extraMinersMapped)));
    }
    else{
      minerNum = configure.startingMiner
      shelfNum = configure.startingShelf
      rackNum = configure.startingRack
    }
    const mac = e.hasOwnProperty('mac')?e.mac:''
    items.push({
      MAC_Address: mac,
      rackNum: rackNum,
      minerNum: minerNum,
      shelfNum: shelfNum,
      minerType: items.length > 0?items[items.length-1]["minerType"]: configure.minerType,
      Location: `${rackNum}-${shelfNum}-${minerNum}`
    })
    this.setState({items: items, extraMinersMapped});

  }
  //Loads a Excel file.
  loadInFile = () => {
    let { configure, fileData, items } = this.state;
    let returnVal = []
    let gapTriggered = 0
    for(var i = 0; i < fileData.length; i++){
      let shelfNum = 0;
      let rackNum = 0;
      let minerNum = 0;
      if(i === 0){
        shelfNum = parseInt(configure.startingShelf);
        rackNum = parseInt(configure.startingRack)
        minerNum = parseInt(configure.startingMiner)
      }
      else{
        let doneMapping = parseInt(configure.startingShelf-1)*parseInt(configure.minerNum);
        let newShelfNum;
        if(configure["gapsShelf"] !== 'none' && configure["gapsShelf_value"] !== 'none'){
          if(returnVal[i-1]['shelfNum'] == configure['gapsShelf']){
            newShelfNum = returnVal[i-1]['shelfNum'] + Math.floor((returnVal[i-1]['minerNum']) / parseInt(configure["gapsShelf_value"]));
            if(Math.floor((returnVal[i-1]['minerNum']) / parseInt(configure["gapsShelf_value"])) == 1){
              gapTriggered += 1;
            }
          }
          else{
            newShelfNum = returnVal[i-1]['shelfNum'] + Math.floor((returnVal[i-1]['minerNum']) / configure.minerNum);
          }
        }
        else{
          newShelfNum = returnVal[i-1]['shelfNum'] + Math.floor((returnVal[i-1]['minerNum']) / configure.minerNum);
        }
        // we have to know how many racks we have mapped in this loop because we need to subtract if there are gaps in shelfs
        //let racksDone = (i+1) > parseInt(configure.minerNum)*parseInt(configure.shelfNum) ? Math.floor(i/(parseInt(configure.minerNum)*parseInt(configure.shelfNum))): 0
        //console.log(`racksDone: ${racksDone}, gapMiners: ${(racksDone*(parseInt(configure["gapsShelf_value"]) - parseInt(configure.minerNum)))}`)
        shelfNum = (newShelfNum-1) % configure.shelfNum + 1
        let gapDiff = configure.gapsShelf_value !== 'none' && configure.gapsShelf !== 'none' && configure.gapsShelf !== ''? parseInt(configure["gapsShelf_value"]) - parseInt(configure.minerNum) : 0
        rackNum = parseInt(configure.minerNum) == 1 && parseInt(configure.shelfNum) == 1 ?
                  parseInt(returnVal[i-1]['rackNum']) + 1:
                  parseInt(configure.startingRack) + Math.floor((i-1+doneMapping+parseInt(configure.startingMiner)) /
                  (parseInt(configure.minerNum) * parseInt(configure.shelfNum) + gapDiff));

        if(gapTriggered !== 0){
          minerNum = parseInt(configure.minerNum) == 1 ? 1: ((i-1+(parseInt(configure.minerNum)-parseInt(configure["gapsShelf_value"]))*gapTriggered)+parseInt(configure.startingMiner)) % parseInt(configure.minerNum) + 1
        }
        else{
          minerNum = parseInt(configure.minerNum) == 1 ? 1: ((i-1)+parseInt(configure.startingMiner)) % parseInt(configure.minerNum) + 1
        }
      }
      let newItem = {
        MAC_Address: fileData[i] || fileData[i] == 0?fileData[i]: '',
        rackNum: rackNum,
        minerNum: minerNum,
        shelfNum: shelfNum,
        Location: `${rackNum}-${shelfNum}-${minerNum}`,
        minerType: configure["minerType"]
      }
      returnVal.push(newItem)

    this.setState({items: returnVal})
  }
}
  //Uploads the excel file to be used to map the miners.
  onUpload = (e) => {
    const { selectedFile } = this.state;
    if(selectedFile){
      const reader = new FileReader();
      reader.onload = (e) => {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        /* Convert array to json*/
        let result = XLSX.utils.sheet_to_json(ws, {header:1});
        let macIndex = result[0].filter((item) => item.includes("MAC") || item.includes("mac"));
        macIndex = result[0].indexOf(macIndex[0])
        if(macIndex || macIndex == 0){
          result = result.map((item) => {
            if(item.length === result[0].length){
              return item[macIndex]
            }
            else{
              return ''
            }
          })
          this.setState({fileData: result.slice(1,result.length+1)})
          this.loadInFile();
        }
        else{
          alert("the first columns row needs to have a MAC/mac field")
        }
      };
      reader.readAsBinaryString(selectedFile)
    }
    this.setState({selectedFile: null})
  }
  //If the file changes chekcs to see if it's the correct type.
  onFileChange = (e) => {
    if(e.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && e.target.files[0].type !== 'text/csv'){
      this.props.setErrorMessageAction("file Wrongly formated");
    }
    else{
      this.setState({selectedFile: e.target.files[0]})
    }
  }
  scrollToBottom = () => {
    const element = document.getElementById("map-view-container")
    if(element){
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }
  //Renders or de-renders the Position Info tab.
  onMultiClick= (e) => {
    let { multiSelected } = this.state;
    multiSelected = !multiSelected
    document.getElementById("multi-map-field").classList.toggle("displayNone");
    document.getElementById("file-div").classList.toggle("visibilityHidden");
    this.setState({multiSelected});
  }
  removeItem = (index) => {
    const { configure } = this.state
    let { items } = this.state
    items.splice(index,1)
    if(items.length === 0){
      items = [{
          MAC_Address: '',
          Location: `${configure.startingRack}-${configure.startingShelf}-${configure.startingMiner}`,
          rackNum: configure.startingRack,
          shelfNum: configure.startingShelf,
          minerNum: configure.startingMiner,
          minerType: configure.minerType
        }]
    }
    this.setState({items: items})
  }
  render() {
    const {client, items, configure, selectedFile, multiSelected} = this.state;
    return (
      <div className="mapViewContent" id="map-view-container">
        <DeviceMapping
          items={items}
          shelfNum={configure.shelfNum}
          minerNum={configure.minerNum}
          houses={this.props.houseStore.houses}
          pods={this.props.podStore.pods}
          houseName={configure.houseName}
          podName={configure.podName}
          startingShelf={configure.startingShelf}
          startingMiner={configure.startingMiner}
          startingRack={configure.startingRack}
          onInputChange={this.onInputConfigure}
          types={this.props.typeStore}
          onMinerItemInput={this.onInput}
          onAddInput={this.onAddInput}
          selectedFile={selectedFile}
          onFileChange={this.onFileChange}
          onUpload={this.onUpload}
          removeItem={this.removeItem}
          gapsShelf={configure.gapsShelf}
          gapsShelf_value={configure.gapsShelf_value}
          onSubmit={this.onFormSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (store) => ({
  token: store.userReducer.result,
  mapResult: store.mapMinersReducer,
  houseStore: store.houseReducer,
  podStore: store.podsReducer,
  typeStore: store.typeReducer.types,
  houseSubnetConnections: store.houseSubnetReducer,
  clientStore: store.clientReducer
});
export default connect(mapStateToProps, {
    mapMinersAction,
    getHousesAction,
    setErrorMessageAction,
    setSuccessMessageAction,
    getTypesAction,
    getHouseSubnetConAction,
    getPodsLimitedAction,
    updateClientAction
  })(MapView)

/*
<div id="file-div" class="input-group visibilityHidden">
  <div className="input-group-prepend">
    <span className="input-group-text" id="inputGroupFileAddon01" onClick={e => this.onUpload(e)}>Upload</span>
  </div>
  <div className="custom-file">
    <input type="file" className="custom-file-input fileBtn" id="inputGroupFile01"
      aria-describedby="inputGroupFileAddon01" onChange={e => this.onFileChange(e)}/>
    <label className="custom-file-label" for="inputGroupFile01">{selectedFile == null ? "Choose File" :selectedFile.name}</label>
  </div>
</div>
</div>
*/


/*
<div className="leftOfContent configureWrapper">

  <div className="configureDiv">
    <div className="configure-form">
      <MapConfigureForm
        houses={this.props.houseStore.houses}
        pods={this.props.podStore.pods}
        houseName={configure.houseName}
        podName={configure.podName}
        shelfNum={Number(configure.shelfNum)}
        minerNum={Number(configure.minerNum)}
        onInputChange={this.onInputConfigure}
        startingRack={Number(configure.startingRack)}
        startingShelf={Number(configure.startingShelf)}
        startingMiner={Number(configure.startingMiner)}
        onMultiClick={this.onMultiClick}
      />
      <div id="file-div" className="input-group visibilityHidden">
        {
          selectedFile == null? (
            <div className="custom-file">
              <input type="file" className="custom-file-input fileBtn" id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01" onChange={e => this.onFileChange(e)}/>
              <label className="custom-file-label" htmlFor="inputGroupFile01">{selectedFile == null ? "Choose File" :selectedFile.name}</label>
            </div>
          ):(
            <div className="input-group-prepend">
              <label className="custom-file-label" id="upload-custom" onClick={e => this.onUpload(e)}>{selectedFile.name}</label>
              <span className="input-group-text" id="inputGroupFileAddon01" onClick={e => this.onUpload(e)}>Upload</span>
            </div>
          )
        }
      </div>
    </div>
  </div>
</div>
<div className="mapViewDisplay">
  <div className="input-from-div">
  <div className="inputHeader-wrapper">
      <div className="input-fixed-header">
        <div className="map-input-card">
          <input type="button" id="password" value="Add Input" className="btn btn-secondary" onClick={e => this.onAddInput(e)}/>
          <input type="button" value="Submit" className="btn btn-primary" onClick={e => this.onFormSubmit(e)}/>
        </div>
        <div className="label-div">
          <div className="mac-label">
            <p>MAC</p>
          </div>
          <div className="info-label">
            <p>Rack</p>
            <p>Shelf</p>
            <p>Miner</p>
          </div>
        </div>
      </div>
    </div>
    <div className="inputList">
      {items.map((item, key ) => {
        if(multiSelected){
          return <MapInput
            key={key}
            MAC_Address={item.MAC_Address}
            Location={item.Location}
            onInput={this.onInput}
            inputNumber={items.indexOf(item)}
            shelfValue={Number(item.shelfNum)}
            rackValue={Number(item.rackNum)}
            minerValue={Number(item.minerNum)}
            shelfNum={Number(configure.shelfNum)}
            minerNum={Number(configure.minerNum)}
            startingRack={Number(configure.startingRack)}
          />
        }
        else{
          return <MapInput
            key={key}
            MAC_Address={item.MAC_Address}
            Location={item.Location}
            onInput={this.onInput}
            inputNumber={items.indexOf(item)}
            shelfValue={item.shelfNum}
            rackValue={item.rackNum}
            minerValue={item.minerNum}
            shelfNum={9}
            minerNum={9}
            startingRack={1}
          />
        }
      })}
      </div>
    </div>
</div>
*/

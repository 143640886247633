import { updateHeatmapPersistConstant } from '../constants/actions'

export const updateHeatmapPersistAction = (property, data) => {
  return dispatch => {
    dispatch(success(property, data))
  }
}

const success = (property, data) => ({
  type: updateHeatmapPersistConstant,
  payload: {
    property: property,
    data: data
  }
})

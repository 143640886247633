import { toggleCustomerPannelConstant } from '../constants/actions'



export const toggleCustomerPannelAction = (type, value) => {
  return dispatch => {
    dispatch(success({type, value}))
  }
}

const success = (payload) => ({
  type: toggleCustomerPannelConstant,
  payload:payload
})

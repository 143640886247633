import { getProfileByIdConstant } from '../constants/actions'
import service from '../services/poolService'
import { setErrorMessageAction } from './setErrorMessageAction'


export const getProfileByIdAction = (token, client, id) => {
  return async(dispatch) =>{
    const result = await service.getProfileByID(token, client, id)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getProfileByIdConstant,
  payload: result
})

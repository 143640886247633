import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { addNewMinerTypeAction } from '../../redux/actions/addNewMinerTypeAction'
import { deleteMinerTypeAction } from '../../redux/actions/deleteMinerTypeAction'
import { editMinerTypeAction } from '../../redux/actions/editMinerTypeAction'
import { getAllTypesAction } from '../../redux/actions/getAllTypesAction'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { clearCurrentClientAction } from '../../redux/actions/clearCurrentClientAction'
import { adminView } from '../../helper/tabs'
import './style.css'


class AdminMinerTypesView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      addModalOpen: false,
      editModalOpen: false,
      minerTypeModalID: '',
      name: '',
      avgHeat: '',
      cardNumber: '',
      totalSpeed: '',
      powerConsumption: '',
      asicNumber: '',
      minerTypeSearchString: ''
    }
  }
  componentDidMount = () => {
    this.props.clearCurrentClientAction()
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
    this.props.getAllTypesAction(
      this.props.userStore.result,
      this.props.minerTypePage.pageSize,
      this.props.minerTypePage.pageNumber,
      this.props.minerTypePage.orderBy,
      this.props.minerTypePage.acending,
      this.state.minerTypeSearchString
    )
  }
  componentWillUnmount = () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  editMinerType = (id) => {
    let minerType = this.props.typeStore.allTypes.data.filter((item) => item.ID == id)
    if(minerType.length > 0){
      this.setState({
        minerTypeModalID: id,
        editModalOpen: true,
        name: minerType[0].Name,
        avgHeat: minerType[0].Avg_Heat,
        cardNumber: minerType[0].Card_Num,
        totalSpeed: minerType[0].Total_Speed,
        powerConsumption: minerType[0].Power_Consumption,
        asicNumber: minerType[0].Asic_Num,
        manufacturer: minerType[0].Manufacturer
      })
    }
  }
  deleteMinerType = (id) => {
    if(window.confirm(`Are you sure you want delete this type ? . Action cannot be reverted`)){
      this.props.deleteMinerTypeAction(this.props.userStore.result, id)
    }
  }
  onMinerTypeSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.minerTypePage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getAllTypesAction(this.props.userStore.result, pageSize, pageNumber, type, acending, this.state.minerTypeSearchString);
    const store = {
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'minerType',
    }
    this.props.updatePageInfoAction(store);
  }
  onMinerTypeSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.minerTypePage;
    this.props.getAllTypesAction(this.props.userStore.result, pageSize, 1, orderBy, acending, e.target.value);
    this.setState({
      minerTypeSearchString: e.target.value
    })
    const store = {
      pageSize: pageSize,
      pageNumber: 1,
      type: 'minerType',
      orderBy: this.props.minerTypePage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onMinerTypePageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber === parseInt(this.props.typeStore.allTypes.current_page)) return
    const { pageSize, orderBy, acending } = this.props.minerTypePage;
    if (pageNumber !== 0 && pageNumber <= this.props.typeStore.allTypes.max_page) {
      this.props.getAllTypesAction(this.props.userStore.result, pageSize, pageNumber, orderBy, acending, this.state.minerTypeSearchString);
    }
    const store = {
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'minerType'
    }
    this.props.updatePageInfoAction(store);
  }
  onMinerTypeAdd = () => {
    const { name, avgHeat, cardNumber, totalSpeed, powerConsumption, asicNumber, manufacturer } = this.state
    if(name == ''){
      alert("Enter an name")
      return
    }
    if(manufacturer == ''){
      alert("Pick a manufacturer")
      return
    }
    if(avgHeat == ''){
      alert("Enter an average heat")
      return
    }
    if(cardNumber == ''){
      alert("Enter the number of cards within the miner")
      return
    }
    if(totalSpeed === ''){
      alert("Enter the total speed of the miner")
      return
    }
    if(powerConsumption == ''){
      alert("Enter the total power consumption of the miner")
      return
    }
    if(asicNumber == ''){
      alert("Enter the number of asic per card")
      return
    }
    this.props.addNewMinerTypeAction(
      this.props.userStore.result,
      {Name: name, Avg_Heat: avgHeat, Card_Num: cardNumber, Total_Speed: totalSpeed, Power_Consumption: powerConsumption, Asic_Num: asicNumber, Manufacturer: manufacturer},
      this.props.minerTypePage.pageSize,
      this.props.minerTypePage.pageNumber,
      this.props.minerTypePage.orderBy,
      this.props.minerTypePage.acending,
      this.state.minerTypeSearchString)
    this.setState({
      addModalOpen: false,
      name: '',
      avgHeat: '',
      cardNumber: '',
      totalSpeed: '',
      powerConsumption: '',
      asicNumber: ''
    })
  }
  onMinerTypeEdit = () => {
    const { name, avgHeat, cardNumber, totalSpeed, powerConsumption, asicNumber, minerTypeModalID, manufacturer } = this.state
    this.props.editMinerTypeAction(
      this.props.userStore.result,
      minerTypeModalID,
      {name, avgHeat, cardNumber, totalSpeed, powerConsumption, asicNumber, manufacturer},
      this.props.minerTypePage.pageSize,
      this.props.minerTypePage.pageNumber,
      this.props.minerTypePage.orderBy,
      this.props.minerTypePage.acending,
      this.state.minerTypeSearchString)
    this.setState({
      editModalOpen: false,
      minerTypeModalID: -1,
      name: '',
      avgHeat: '',
      cardNumber: '',
      totalSpeed: '',
      powerConsumption: '',
      asicNumber: ''
    })
  }
  render(){
    const addMinerTypeFields = [
      {Name: "Name", PropertyName: 'name', Value: this.state.name, Type: "text", Options: []},
      {Name: "Avg Heat", PropertyName: 'avgHeat', Value: this.state.avgHeat, Type: "int", Options: []},
      {Name: "Card Number", PropertyName: 'cardNumber', Value: this.state.cardNumber, Type: "int", Options: []},
      {Name: "Total Speed", PropertyName: 'totalSpeed', Value: this.state.totalSpeed, Type: "float", Options: []},
      {Name: "Power Consumption", PropertyName: 'powerConsumption', Value: this.state.powerConsumption, Type: "float", Options: []},
      {Name: "Asic Number", PropertyName: 'asicNumber', Value: this.state.asicNumber, Type: "int", Options: []},
      {Name: 'Manufacturer', PropertyName: 'manufacturer', Value: this.state.manufacturer, Type: 'select', Options: [
        {text: "----------", value: ''},
        {text: 'MicroBT', value: 'MicroBT'},
        {text: 'Bitmain', value: 'Bitmain'},
        {text: 'Innosilicon', value: 'Innosilicon'},
        {text: 'Canaan', value: 'Canaan'},
        {text: 'AMD', value: 'AMD'}
      ]}
    ]
    const editMinerTypeFields = [
      {Name: "Name", PropertyName: 'name', Value: this.state.name, Type: "text", Options: []},
      {Name: "Avg Heat", PropertyName: 'avgHeat', Value: this.state.avgHeat, Type: "int", Options: []},
      {Name: "Card Number", PropertyName: 'cardNumber', Value: this.state.cardNumber, Type: "int", Options: []},
      {Name: "Total Speed", PropertyName: 'totalSpeed', Value: this.state.totalSpeed, Type: "float", Options: []},
      {Name: "Power Consumption", PropertyName: 'powerConsumption', Value: this.state.powerConsumption, Type: "float", Options: []},
      {Name: "Asic Number", PropertyName: 'asicNumber', Value: this.state.asicNumber, Type: "int", Options: []},
      {Name: 'Manufacturer', PropertyName: 'manufacturer', Value: this.state.manufacturer, Type: 'select', Options: [
        {text: 'MicroBT', value: 'MicroBT'},
        {text: 'Bitmain', value: 'Bitmain'},
        {text: 'Innosilicon', value: 'Innosilicon'},
        {text: 'Canaan', value: 'Canaan'},
        {text: 'AMD', value: 'AMD'}
      ]}
    ]
    const minerTypesColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Manufacturer', value: 'Manufacturer'},
      {field: 'Avg_Heat', value: 'Heat'},
      {field: 'Card_Num', value: 'Cards'},
      {field: 'Power_Consumption', value: 'Power(W)'},
      {field: 'Speed_Readable', value: 'Speed'},
      {field: 'Asic_Num', value: 'Asics'}
    ]
    const minerTypesButtons = [
      {class: 'ViewBody-button-edit', onClick: this.editMinerType, type: 'Edit', extractKey: 'ID'},
      {class: 'ViewBody-button-delete', onClick: this.deleteMinerType, type: 'Delete', extractKey: 'ID'}]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="adminView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Server Admin"
          subtitle="Manage scanner, databases & more"
          tabs={adminView}
          active="Miner Types"
          onClick={this.onClick}
        />
        <div className="AdminView-content">
          <ViewBody
            data={this.props.typeStore.allTypes.data}
            columns={minerTypesColumns}
            buttons={minerTypesButtons}
            createtext="New miner type"
            list
            itemClickInfo={{name: 'minertype', property: 'ID'}}
            onCreate={e => this.setState({addModalOpen: true})}
            pager
            sorting
            search
            searchValue={this.state.minerTypeSearchString}
            onSearchChange={this.onMinerTypeSearch}
            acending={this.props.minerTypePage.acending}
            sortedBy={this.props.minerTypePage.orderBy}
            onSort={this.onMinerTypeSort}
            totalPages={this.props.typeStore.allTypes.max_page}
            currentPage={parseInt(this.props.typeStore.allTypes.current_page) - 1}
            onPageChange={this.onMinerTypePageClick}
          />
        </div>
        <Modal
          open={this.state.addModalOpen}
          center
          onClose={() => this.setState({addModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create new miner type"
            fields={addMinerTypeFields}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onMinerTypeAdd}
            onInputChange={e => onInputChange(e, this)}
            action="add_miner_type"
          />
        </Modal>
        <Modal
          open={this.state.editModalOpen}
          center
          onClose={() => this.setState({
            editModalOpen: false,
            name: '',
            avgHeat: '',
            cardNumber: '',
            totalSpeed: '',
            powerConsumption: '',
            asicNumber: '',
            minerTypeModalID: ''
          })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Edit miner type"
            fields={editMinerTypeFields}
            addButton={true}
            buttonText="Edit"
            onAddClick={this.onMinerTypeEdit}
            onInputChange={e => onInputChange(e, this)}
            action="edit_miner_type"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  userStore: props.userReducer,
  typeStore: props.typeReducer,
  minerTypePage: props.pageReducer.minerType
})

export default connect(mapStateToProps, {
  addNewMinerTypeAction,
  deleteMinerTypeAction,
  editMinerTypeAction,
  getAllTypesAction,
  updatePageInfoAction,
  clearCurrentClientAction
})(AdminMinerTypesView)

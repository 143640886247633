import { adjustInventoryConstant } from '../constants/actions'
import service from '../services/inventoryService'
import { setErrorMessageAction } from './setErrorMessageAction'
export const adjustInventoryAction = (token, customer, type, body, region_id) => {
  return async(dispatch) => {
    const result = await service.adjustInventory(token, customer, type, body, region_id);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: adjustInventoryConstant,
  payload: result
})

import React from 'react';
import './style.css'
const AlertsTable = ({ data, isCustomer, onClick, currentPage, maxPage, onPageClick, orderBy, acending, pageSize, onSort, sendTicket, reducerStatus }) => {
  return(
    <div className="alertsTable-div">
      <table className="alertsTable" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            {!isCustomer?(
              <th className="alerts-th" onClick={(e) => onSort('Customer')}>{ orderBy === "Customer"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Customer
              </th>
            ):(
              <th className="alerts-th" onClick={(e) => onSort('ID')}>{ orderBy === "ID"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Alert ID
            </th>
            )}
            <th className="alerts-th" onClick={(e) => onSort('Type')}>{ orderBy === "Type"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Alert Type
            </th>
            <th className="alerts-th" onClick={(e) => onSort('Amount')}>{ orderBy === "Amount"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Amount
            </th>
            <th className="alerts-th" onClick={(e) => onSort('Active')}>{ orderBy === "Active"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Status
            </th>
            <th className="alerts-th" onClick={(e) => onSort('Alert_Date')}>{ orderBy === "Alert_Date"? (
              acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
            ):<i className="fa fa-sort"/>} Date
            </th>
            {isCustomer?(
              <th className="alerts-th">Actions</th>
            ):(<></>)}
          </tr>
        </thead>
        <tbody>
        {data.length > 0 ?(data.map((item, key) =>(
          <tr key={key}>
            {!isCustomer?(
              <td className={`${item.Active === 1?('alerts-td red-color'):('alerts-td')}`} onClick={() => onClick(key)}>{item.Customer}</td>
            ):(
              <td className={`${item.Active === 1?('alerts-td red-color'):('alerts-td')}`} onClick={() => onClick(key)}>{item.ID}</td>
            )}
            <td className={`${item.Active === 1?('alerts-td red-color'):('alerts-td')}`} onClick={() => onClick(key)}>
              {item.Active === 1?(
                <i className="fa fa-exclamation i-danger"></i>
              ):(<></>)}
              {item.Type}
            </td>
            <td className={`${item.Active === 1?('alerts-td red-color'):('alerts-td')}`} onClick={() => onClick(key)}>
              {item.Amount}
            </td>
            <td className={`${item.Active === 1?('alerts-td red-color'):('alerts-td')}`} onClick={() => onClick(key)}>
              {item.Active === 1?(
                'Active'
              ):('Resolved')}
            </td>
            <td className={`${item.Active === 1?('alerts-td red-color'):('alerts-td')}`} onClick={() => onClick(key)}>
              {
                `${item.Alert_Date.split('T')[0]} - ${item.Alert_Date.split('T')[1].split('.')[0]}`
              }
            </td>
            {isCustomer?(
              <td className="alerts-td">
                <span data-tooltip="Send Ticket" data-tooltip-position="bottom">
                  <i class="fa fa-envelope" aria-hidden="true" onClick={() => sendTicket(key)}/>
                </span>
              </td>
            ):(<></>)}
          </tr>
         ))):(
            !reducerStatus?(
              <tr>
                <td rowSpan="2" colSpan="5" className="adc_customerStatusTable-empty-td">
                  <div className="adc_customerStatusTable-empty">
                    <label>Loading data</label>
                    <div className="adc_minerView-loader"></div>
                  </div>
                </td>
              </tr>
            ):(
              <tr>
                <td rowSpan="2" colSpan="5" className="adc_customerStatusTable-empty-td">
                  <div className="adc_customerStatusTable-empty">
                    <label>No data available</label>
                  </div>
                </td>
              </tr>
            )
        )}
        </tbody>
      </table>
    </div>
  )
}
export default AlertsTable

import { getMinerCommentsConstant } from '../constants/actions'
import service from '../services/minerService'
import { setErrorMessageAction } from './setErrorMessageAction'


export const getMinerCommentsAction = (token, mac, client, pageNumber, pageSize, orderBy, acending) => {
  return async dispatch =>{
    const result = await service.getMinerComments(token, mac, client, pageNumber, pageSize, orderBy, acending)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}


const success = (result) => ({
  type: getMinerCommentsConstant,
  payload: result
})

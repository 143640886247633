import { renameCustomersConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/clientService';

export const renameCustomersAction = (token, body) => {
  return async(dispatch) => {
      const result = await service.renameCustomers(token, body)
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(success(result))
  }
}


const success = (result) => ({
  type: renameCustomersConstant,
  payload: result
})

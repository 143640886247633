import { getHashrateConstant, setId_getHashrateConstant } from '../constants/actions';
import hashrateService from '../services/hashrateService';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getHashrateAction = (token, customer, time, region_id) => {
  return async(dispatch) => {
    try{
      //alert("getting hashrate action")
      const actionId = Math.random() * (100000000 - 1) + 1;
      dispatch({type: setId_getHashrateConstant, payload: actionId})
      const result = await hashrateService.getHashrate(token, customer, time, region_id);
      //alert("getting hashrate action returning")
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(getHashrateSuccess(result, actionId));

    }
    catch(err){
      console.log(err)
    }
  }
}

const getHashrateSuccess = (hash, actionId) => ({
  type: getHashrateConstant,
  payload: hash,
  actionId
})

import React from 'react';
import './style.css';
import Section from '../Section'
import ConfigSectionTable from '../ConfigSectionTable'


const ConfigPodSection = ({ pods, buildings, onAdd, onMinimize, showBody, removeItem, showAdd, podName, onSave, onCancel, onInputChange, privilegesLevel, deletePrivileges, insertPrivileges }) => {
  return(
    <Section
      icon={<i className="fa fa-th-large"/>}
      name="Pod Section"
      onMinimize={onMinimize}
      showBody={showBody}
      body={
        <div className="adc_configPodSection-outerDiv">
          <ConfigSectionTable
            columns={{
              House_Name: "House",
              Name: "POD",
            }}
            items={pods}
            onDelete={removeItem}
            privilegeLevel={privilegesLevel}
            deletePrivileges={deletePrivileges}
            deleteType="Pod"
          />
          {showAdd?(
            <div className="adc_configPodSection-outerDiv">
              <div className="adc_configPodSection-addItemsDiv">
                <div>
                  <span>Pick Building</span>
                  <select name="podBuildingName" className="adc_configBuildingSection-input" onChange={e => onInputChange(e)}>
                    {buildings.map((item) => (
                      <option>{item.Name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <span>Name</span>
                  <input name="podName" value={podName} className="adc_configBuildingSection-input" placeholder="Enter new pod name" onChange={e => onInputChange(e)}/>
                </div>
              </div>
              <div className="adc_configPodSection-addItemsSaveDiv">
                <button type="button" className="adc_deviceMapping-button btn-danger" onClick={e => onCancel(e)}>
                  Cancel
                </button>
                <button type="button" className="adc_deviceMapping-button btn-success" onClick={e => onSave(e)}>
                  Save
                </button>
              </div>
            </div>
          ):(
            privilegesLevel >= insertPrivileges?(
              <div>
                <button type="button" className="adc_deviceMapping-button" onClick={e => onAdd(e)}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            ):(<></>)
          )}
        </div>
      }
    />
  )
}
export default ConfigPodSection

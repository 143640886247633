import {
  getInventoryConstant,
  adjustInventoryConstant,
  getInventoryByMinerType,
  getInventoryHistoryConstant,
  getFarmInventoryConstant,
  postFarmInventoryConstant,
  putFarmIvnentoryConstant,
} from '../constants/actions';

const defaultState = {
  ok: false,
  inventory: [],
  farmInventory: [],
  api_version: 1.1,
  logs: {},
  msg: ''
}


export default(state=defaultState, action) => {
  switch (action.type) {
    case getInventoryConstant:
    case adjustInventoryConstant:
    case getInventoryByMinerType:
      if(action.payload.ok){
        return{
          ok: true,
          inventory: action.payload.inventory,
          logs: state.logs,
          msg: action.payload.msg,
          farmInventory: state.farmInventory
        }
      }
      else{
        return{
          ok: true,
          inventory: state.inventory,
          logs: state.logs,
          msg: action.payload.msg,
          farmInventory: state.farmInventory
        }
      }
    case getInventoryHistoryConstant:
      if(action.payload.ok){
        return{
          ok: true,
          inventory: state.inventory,
          logs: action.payload.logs,
          msg: action.payload.msg,
          farmInventory: state.farmInventory
        }
      }
      return{
        ok: true,
        inventory: state.inventory,
        logs: state.logs,
        msg: action.payload.msg,
        farmInventory: state.farmInventory
      }
    case getFarmInventoryConstant:
    case postFarmInventoryConstant:
    case putFarmIvnentoryConstant:
      if(action.payload.ok){
        return{
          ok: true,
          farmInventory: action.payload.farmInventory,
          inventory: state.inventory,
          logs: state.logs,
          msg: action.payload.msg
        }
      }
      else{
        return{
          ok: false,
          inventory: state.inventory,
          farmInventory: state.farmInventory,
          logs: state.logs,
          msg: action.payload.msg
        }
      }
    default:
      return state
  }
}

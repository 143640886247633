import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { configureView } from '../../helper/tabs'
import { getAllPodsAction } from '../../redux/actions/getAllPodsAction';
import { postNewPodAction } from '../../redux/actions/postPodAction';
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { deletePodAction } from '../../redux/actions/deletePodAction';
import { getHousesAction } from '../../redux/actions/getHousesAction';
import { Modal } from "react-responsive-modal";
import './style.css'

class ConnectionsView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      podName: '',
      buildingID: '',
      modalOpen: false
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    this.props.getAllPodsAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id);
    this.props.getHousesAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id);
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onDelete = (id) => {
    if(window.confirm("are you sure you want to delete this resource")){
      this.props.deletePodAction(this.props.token, this.props.match.params.name, id, this.props.match.params.region_id)
    }
  }
  onAdd = () => {
    const { podName, buildingID } = this.state
    if (podName === '') {
      alert("Name is empty");
      return;
    }
    if (podName.length > 10) {
      alert("The name you picked is too long, max length is 10");
      return;
    }
    if(buildingID === ''){
      alert('Pick a building')
      return;
    }
    this.props.postNewPodAction({Name: podName, House_ID: buildingID}, this.props.token, this.props.match.params.name, this.props.match.params.region_id)
    this.setState({
      podName: '',
      buildingID: '',
      modalOpen: false
    })
  }
  render(){
    const { level } = this.props.userData
    const columns = [
      {field: 'Name', value: 'Name'},
      {field: 'House_Name', value: 'Building Name'}
    ]
    const buttons = level >= 2?([{class: 'ViewBody-button-delete', onClick: this.onDelete, type: 'Delete', extractKey: 'ID'}]):([])
    const addPodFields = [
      {Name: 'Name', PropertyName: 'podName', Value: this.state.podName, Type: 'text', Options: []},
      {Name: 'Building', PropertyName: 'buildingID', Value: this.state.buildingID, Type: 'select',
      Options: [{text: "----------", value: ''}].concat(this.props.buildingStore.map((item) => ({text: item.Name, value: item.ID})))
    }]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="PodsView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={configureView}
          active="Pods"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={this.props.podStore}
            columns={columns}
            buttons={buttons}
            createtext="New pod"
            list
            onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
            itemClickInfo={{name: 'pods', property: 'ID'}}
            onCreate={e => this.setState({modalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create new pod"
            fields={addPodFields}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAdd}
            onInputChange={e => onInputChange(e, this)}
            action="add_pod"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  podStore: props.podsReducer.pods,
  buildingStore: props.houseReducer.houses,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  getAllPodsAction,
  deletePodAction,
  postNewPodAction,
  getHousesAction,
  updateClientAction,
})(ConnectionsView)

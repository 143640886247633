import React from 'react'
import FilterSectionModel from '../FilterSectionModel'
import FilterMinerListDefaultChildren from '../FilterMinerListDefaultChildren'
import './style.css'
const createOptions = (num) => {
  let returnVal = []
  for(var i = 1; i <= num; i++){
    returnVal.push(<option>{i}</option>)
  }
  return returnVal
}
const FilterListViewMiners = ({scanners, houses, pods, filter, onFilterInputChange, minerTypes, clearFilter}) => {
  return(
    <FilterSectionModel
      children={
        <>
        <div className="adc_filterSection-itemContainer">
          <label>Scanner</label>
          <select value={filter.filterScanner} name="filterScanner" className="adc_filterSection-input" onChange={(e) => onFilterInputChange(e)}>
            <option value="">-----</option>
            {scanners.map((item, key) => (
              <option key={key} value={item.ID}>{item.Name}</option>
            ))}
          </select>
        </div>
        <div className="adc_filterSection-itemContainer">
          <p>House</p>
          <select value={filter.filterHouse} name="filterHouse" className="adc_filterSection-input" onChange={(e) => onFilterInputChange(e)}>
            <option value="">-----</option>
            {houses.map((item, key) => (
              <option key={key} value={item.ID}>{item.Name}</option>
            ))}
          </select>
        </div>
        <div className="adc_filterSection-itemContainer">
          <p>PODs</p>
          <select value={filter.filterHouse} name="filterPOD" className="adc_filterSection-input" onChange={(e) => onFilterInputChange(e)}>
              <option value="">-----</option>
              {pods.map((item, key) => (
                <option key={key} value={item.ID}>{item.Name}</option>
              ))}
          </select>
        </div>
        <FilterMinerListDefaultChildren
          filter={filter}
          onFilterInputChange={onFilterInputChange}
          minerTypes={minerTypes}
          clearFilter={clearFilter}
        />
        </>
      }
    />
  )
}
export default FilterListViewMiners

/*
{filter.filterScanner === ''?(
  <option value="" selected>Pick</option>
):(
  <option value="">Pick</option>
)}

*/

import React from 'react';
import HighchartGraph from '../HighchartGraph'
import './style.css';
import 'antd/dist/antd.css';

const Chartsection = ({ data, type, api_version, onMinimize, showBody, onRadioCheck, hashrate1Min, hashrate15Min, hashrate1h, hashrate6h, hashrate1d }) => {
  return(
      <div className="adc_chartSection-containerDiv">
          <HighchartGraph
            data={hashrate1Min}
            type={type}
            api_version={api_version}
            title="Last 40 Minutes"
          />
          <HighchartGraph
            data={hashrate15Min}
            type={type}
            api_version={api_version}
            title="Last 10 Hours"
          />
          <HighchartGraph
            data={hashrate1h}
            type={type}
            api_version={api_version}
            title="Last 40 Hours"
          />
          <HighchartGraph
            data={hashrate6h}
            type={type}
            api_version={api_version}
            title="Last 10 Days"
          />
          <HighchartGraph
            data={hashrate1d}
            type={type}
            api_version={api_version}
            title="Last 40 days"
          />
      </div>
  )
}
export default Chartsection

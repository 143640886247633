import { replaceHistoryConstant } from '../constants/actions';

export const replaceHistoryAction = () => {
  return dispatch => {
    dispatch(replaceHistorySuccess());
  }
}
const replaceHistorySuccess = status => ({
  type: replaceHistoryConstant
})

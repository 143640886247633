import React from 'react'
import './style.css'

const CommandSectionModel = ({ Body, Header }) => {
  return(
    <div className="adc_commandSection-outerContainer">
      {Header}
      <div className="adc_commandSection-innerContainer">
      {Body}
      </div>
    </div>
  )
}
export default CommandSectionModel

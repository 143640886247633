import React from 'react'
import PropTypes from 'prop-types';
import './style.css';
const ChangePasswordComponent = ({username, oldpassword, newpassword, confirmnewpassword, onInput, onFormSubmit}) => {
    return (
      <div className="change-password-div">
      <form action="" className="change-password" onSubmit={e => onFormSubmit(e)}>
        <h2 className="changePassword-labels">Change Password</h2>
        <div className="form-column-change-password">
          <label className="changePassword-labels">Username</label>
            <input type="text" id="usename" name="username" defaultValue={username} className="form-column-item" disabled/>
        </div>
        <div className="form-column-change-password">
          <label className="changePassword-labels">Old password</label>
          <input type="password" id="password" name="oldpassword" defaultValue={oldpassword} className="form-column-item" onInput={e => onInput(e)}/>
        </div>
        <div className="form-column-change-password">
            <label className="changePassword-labels">New password</label>
            <input type="password" id="newpassword" name="newpassword" defaultValue={newpassword} className="form-column-item" onInput={e => onInput(e)}/>
        </div>
        <div className="form-column-change-password">
            <label className="changePassword-labels">Confirm password</label>
            <input type="password" id="confirmpassword" name="confirmnewpassword" defaultValue={confirmnewpassword} className="form-column-item" onInput={e => onInput(e)}/>
        </div>
        <div className="form-column-change-password">
          <input type="button" id="submitpassword" value="submit" className="changePassword-submit" onClick={e => onFormSubmit(e)}/>
        </div>
      </form>
      </div>
    )
}
ChangePasswordComponent.propTypes = {
  username: PropTypes.string.isRequired,
  oldpassword: PropTypes.string.isRequired,
  newpassword: PropTypes.string.isRequired,
  confirmnewpassword: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
}
export default ChangePasswordComponent;
/*
<div class="form-column">
  <label className="">{item.Name}</label>
  <input type="text" value={item.Value} className="form-column-item" name={`${item.PropertyName}`} id={item.Id?(item.Id):('')} onChange={e => onInputChange(e, item.PropertyName)} placeholder={item.Name}/>
</div>
*/

import React from 'react'
import './style.css'
import Header from '../Header'

const LoadingScreen = ({  }) => {
  return(
    <div className="adc_loadingScreen-container">
      <Header
        splash={true}
      />
      <div className="adc_loadingScreen-innerDiv">
        <span>Loading User Data</span>
        <div className="loader"></div>
      </div>
    </div>
  )
}

export default LoadingScreen

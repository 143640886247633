import { clearLocationsConstant } from '../constants/actions'
import service from '../services/minerService'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'

export const clearLocationsAction = (token, client, macs, list = true) => {
  return async (dispatch)=> {
    const result = await service.clearLocations(token, client, macs)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    else{
      dispatch(setSuccessMessageAction(result.msg))
    }
    dispatch({type: clearLocationsConstant, payload: {ok: result.ok, macs: macs.macs, list}})
  }
}

import React from 'react';
import './styles.css';
import InfoTables from '../InfoTables'
import InfoPannel from '../InfoPannel'
import SearchInput from '../SearchInput'
import ViewBodyFilterList  from '../ViewBodyFilterList';

const ViewBody = ({ data, columns, buttons,  createtext, onCreate, onItemClick, itemClickInfo, onBack, list, pannel, pager, totalPages, currentPage, onPageChange, sorting, sortedBy, acending, onSort, search, searchValue, totalData, onSearchChange, title, buttonDisabled, buttonDisabledText, filterList, filterListOnInputChange, clearFilter }) => {
  return(
    <div className="ViewBody-container">
      <div>
        <div className={onBack?('ViewBody-multi-action-container'):"ViewBody-action-container"}>
          {onBack?(
            <div className="ViewBody-button-container">
              <button className="ViewBody-button-create" onClick={e => onBack()}>
                <span className="ViewBody-button-span">Go back</span>
              </button>
            </div>
          ):(<></>)}
          {onCreate?(
            <div className="ViewBody-button-container">
              {buttonDisabled?(
                <button disabled className="ViewBody-button-create" onClick={e => onCreate()}>
                  <span data-tooltip={buttonDisabledText} data-tooltip-position="bottom" className="ViewBody-button-span">{createtext}</span>
                </button>
              ):(
                <button  className="ViewBody-button-create" onClick={e => onCreate()}>
                  <span className="ViewBody-button-span">{createtext}</span>
                </button>
              )}
            </div>
          ):(<></>)}
        </div>
      </div>
      <div className="ViewBody-table-container">
        {title?(
          <h3 className="viewbody-title">{title}</h3>
        ):(<></>)}
        {pannel?(
          <InfoPannel
            data={data}
            columns={columns}
          />
        ):(<></>)}
        {list?(
          <>
            {search?(
              <SearchInput
                value={searchValue}
                onChange={onSearchChange}
              />
            ):(
              <></>
            )}
            {filterList?(
              <ViewBodyFilterList
                filterList={filterList}
                onInputChange={filterListOnInputChange}
                clearFilter={clearFilter}
              />
            ):(<></>)}
            <InfoTables
                data={data}
                columns={columns}
                buttons={buttons}
                onItemClick={onItemClick}
                itemClickInfo={itemClickInfo}
                pager={pager}
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
                sortedBy={sortedBy}
                acending={acending}
                onSort={onSort}
                sorting={sorting}
                totalData={totalData}
              />
          </>
        ):(<></>)}
      </div>
    </div>
  )
}

export default ViewBody

import React from 'react';
import './style.css'
const AlertsSettingsTable = ({ data, onInputChange, onSubmit, privilegeLevel, insertPrivileges }) => {
  return(
    <div className="alertSettingsTable-div">
      <table className="table-bordered" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th className="alertSettings-th">Type</th>
            <th className="alertSettings-th">Threshold</th>
          </tr>
        </thead>
        <tbody>
        {data.map((item, key) =>(
          <tr>
            <td className="alertSettings-td">
              {item.Type}
            </td>
            <td className="alertSettings-td">
              {
                privilegeLevel >= insertPrivileges?(
                  <input type="text" className="custom-formcontrol" name={`${item.Type}-${key}`}  value={item.Threshold} onInput={e => onInputChange(e)}/>
                ):(item.Threshold)
              }
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      {
        privilegeLevel >= insertPrivileges?(
          <button type="button" class="btn btn-success" onClick={() => onSubmit()}>Update</button>
        ):(<></>)
      }
    </div>
  )
}
export default AlertsSettingsTable

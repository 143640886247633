import { getInventoryByMinerType } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction';
import service from '../services/inventoryService'

export const getInventoryByTypeAction = (token, customer, type) => {
  return async(dispatch) => {
    const result = await service.getInventoryByType(token, customer, type)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getInventoryByMinerType,
  payload: result
})

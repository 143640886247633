import { getErrorLogsConstants, setErrorLogsStatus } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/logService'

export const getErrorLogsAction = (token, customer, mac) => {
  return async dispatch => {
    dispatch({type: setErrorLogsStatus, payload: 'pending'})
    const result = await service.getErrorLogs(token, customer, mac);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getErrorLogsConstants,
  payload: result
})

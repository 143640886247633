import { getQrCodeConstant } from '../constants/actions'
import service from '../services/userService'


export const getQRCodeAction = (token) => {
  return async(dispatch) => {
    const result = await service.getQrCode(token)
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getQrCodeConstant,
  payload: result
})

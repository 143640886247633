import { putFarmIvnentoryConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'
import service from '../services/inventoryService'

export const putFarmInventoryAction = (token, customer, body) => {
  return async(dispatch) => {
    const result = await service.putFarmInventory(token, customer, body)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    else{
      dispatch(setSuccessMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: putFarmIvnentoryConstant,
  payload: result
})

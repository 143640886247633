import React from 'react'

export const items_to_display = (list, onMacClick) => {
  let returnList = []
  if(list.length > 10){
    let newList = list.slice(0, 9)
    newList.map((item, i) => {
      returnList.push(
        <tr key={i}>
          <th scope="row">{i+1}</th>
          <td className="dashminers-openminer" onClick={e => onMacClick(item.MAC_Address)}>{item.location}</td>
          <td>{item.down_time}</td>
        </tr>
      )
    })
    returnList.push(
      <tr key={list.length-1}>
        <th scope="row">{list.length}</th>
        <td className="dashminers-openminer" onClick={e => onMacClick(list[list.length-1].MAC_Address)}>{list[list.length-1].location}</td>
        <td>{list[list.length-1].down_time}</td>
      </tr>
    )
  }
  else{
    list.map((item, i ) => {
      returnList.push(
        <tr key={i}>
          <th scope="row">{i+1}</th>
          <td className="dashminers-openminer" onClick={e => onMacClick(item.MAC_Address)}>{item.location}</td>
          <td>{item.down_time}</td>
        </tr>
      )
    })
  }
  return returnList
}

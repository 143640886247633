import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { Modal } from "react-responsive-modal";
import { onTabHeaderClick, onInputChange } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { getRackShelfsAction } from '../../redux/actions/getRackShelfsAction'
import { getRackProfileByIDAction } from '../../redux/actions/getRackProfileByIDAction'
import { addRackProfileShelfsConnectionAction } from '../../redux/actions/addRackProfileShelfsConnectionAction'
import { deleteRackProfileShelfsConnectionAction } from '../../redux/actions/deleteRackProfileShelfsConnectionAction'
import './style.css'


class RackProfileView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      shelfsList: [],
      addModal: false,
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    this.props.getRackProfileByIDAction(this.props.token, this.props.match.params.name, this.props.match.params.id)
    this.props.getRackShelfsAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id)
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history, `/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/`)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onDelete = (id) => {
    window.confirm(`Are you sure you want to delete this shelf ?`) && this.props.deleteRackProfileShelfsConnectionAction(this.props.token, this.props.match.params.name, {Rack_Profile_ID: this.props.rackStore.currentProfile.ID, Rack_Shelf_ID: id})
  }
  onAddShelfs = () => {

  }
  onBack = () => {
    this.props.history.goBack()
  }
  onAddShelfChange = (list) => {
    this.setState({shelfsList: list})
  }
  onAddShelfs = () => {
    let { shelfsList } = this.state;
    if(shelfsList.length > 0 ){
      shelfsList = shelfsList.map((item) => item.value)
      this.props.addRackProfileShelfsConnectionAction(this.props.token, this.props.match.params.name, {Rack_Profile_ID: this.props.match.params.id, Shelfs: shelfsList})
      this.setState({
        addModal: false,
        shelfsList: []
      })
    }
    else {
      alert("Please pick atleas one shelf")
    }
  }
  render(){
    const { regionID } = this.state
    const { userData } = this.props
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    const buttons = userData.level >= 2?([{class: 'ViewBody-button-delete', onClick: this.onDelete, type: 'Delete', extractKey: 'ID'}]):([])
    const shelfColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Shelf_Number', value: "Shelf Number"},
      {field: 'Miner_Count', value: "Miners in shelf"}
    ]
    const pannelColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Value', value: 'Value'}
    ]
    const pannelData = [
      {Name: 'Name', Value: this.props.rackStore.currentProfile.Name},
      {Name: 'Uses shelf', Value: this.props.rackStore.currentProfile.Use_Shelf},
      {Name: 'Left to right', Value: this.props.rackStore.currentProfile.Left_To_Right},
      {Name: 'Bottom To Top', Value: this.props.rackStore.currentProfile.Bottom_To_Top}
    ]
    const shelfsID = this.props.rackStore.currentProfile.Shelfs.map((item) => item.ID)
    const pickShelfs = this.props.rackStore.shelfs.filter((item) => (shelfsID.indexOf(item.ID) === -1 && item.Region_ID === this.props.rackStore.currentProfile.Region_ID))
    const addShelfsColumns = [ {Name: "Pick Shelfs", PropertyName: 'none', Value: this.state.shelfsList, Type: 'multi-select', Options: pickShelfs.map((item) => ({label: item.Name, value: item.ID}))}]
    return(
      <div className="RackProfileView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={userData.level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
          active="Mapping"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            columns={pannelColumns}
            data={pannelData}
            onBack={this.onBack}
            pannel
          />
          <ViewBody
            data={this.props.rackStore.currentProfile.Shelfs}
            columns={shelfColumns}
            buttons={buttons}
            createtext="Add shelf"
            list
            onCreate={e => this.setState({addModal: true})}
          />
        </div>
        <Modal
          open={this.state.addModal}
          center
          onClose={() => this.setState({ addModal: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add shelfs"
            fields={addShelfsColumns}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAddShelfs}
            onInputChange={this.onAddShelfChange}
            action="add_shelfs"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
    token: props.userReducer.result,
    userData: props.userReducer.userData,
    clientStore: props.clientReducer,
    rackStore: props.rackReducer
})

export default connect(mapStateToProps, {
  updateClientAction,
  getRackShelfsAction,
  getRackProfileByIDAction,
  addRackProfileShelfsConnectionAction,
  deleteRackProfileShelfsConnectionAction
})(RackProfileView)

import {
  getScannerInfoConstant
} from '../constants/actions'
import service from '../services//adminService'
import { setErrorMessageAction } from './setErrorMessageAction'



export const getScannerInfoAction = (token, ip) => {
  return async(dispatch) => {
    const result = await service.getScannerInfo(token, ip)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}
const success = (result) => ({
  type: getScannerInfoConstant,
  payload: result
})

import React from 'react'
import './style.css'
import CommandSectionModel from '../CommandSectionModel'

const MinerCommands = ({ onClick, showBody, onMinimize }) => {
  return(
    <div className="">
      <CommandSectionModel
        Header={
          <div className="adc_minerCommandsHeaderDiv">
            <div className="adc_minerCommandsHeader">
              <i className="fa fa-tasks"/>
              <span>Command Section</span>
            </div>
            <div className="adc_minerCommandsButtonDiv">
              <button type="button" className="btn btn-box-tool" onClick={e => onMinimize()}>{showBody?(<i className="fa fa-minus"/>):(<i className="fa fa-plus"/>)}</button>
            </div>
          </div>
        }
        Body={
          showBody? (
            <>
              <a className="adc_commandSection-item" onClick={e => onClick('restart')}>
                <i className="fa fa-refresh" style={{color: 'red'}}></i>
                Restart
              </a>
              <a className="adc_commandSection-item" onClick={e => onClick('update')}>
                <i className="fa fa-refresh" style={{color: 'green'}}></i>
                Update
              </a>
              <a className="adc_commandSection-item" onClick={e => onClick('config')}>
                <i className="fa fa-cog" style={{color: 'orange'}} aria-hidden="true"></i>
                Configure
              </a>
              <a className="adc_commandSection-item" onClick={e => onClick('delete')}>
                <i className="fa fa-trash" style={{color: 'red'}} aria-hidden="true"></i>
                Delete
              </a>
              <a className="adc_commandSection-item" onClick={(e) => onClick('clearLocation')}>
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                Clear Location
              </a>
            </>
          ):(<></>)
        }
      />
    </div>
  )
}
export default MinerCommands

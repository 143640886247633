import React, { Component } from 'react'
import * as d3 from 'd3'

const width = 125;
const height = 125;
const margin = {
    top: 20,
    right: 5,
    bottom: 20,
    left: 60
};
const rad = Math.min(width, height) / 2 - 20 // margin hérna

class D3DashPieComponent extends Component {

    state = {
        pie: null,
    };

    arc = d3
    .arc()
    .innerRadius(0)
    .outerRadius(height/2);

    static getDerivedStateFromProps(nextProps, prevState) {
        
        if (!nextProps.data) return null;
        // data á forminu [ onlineInt, offlineInt]
        const { data } = nextProps;
        let pie = d3.pie()(data);
        /*console.log("hérna er getDerivedState data")
        console.log(data)
        console.log(time)
        const { xScale, yScale, lineGenerator} = prevState;

        const timeDomain = d3.extent(data, d => d.Date);
        const minVal = d3.min(data, d => d.value);
        const maxVal = d3.max(data, d => d.value);
        xScale.domain(timeDomain);
        yScale.domain([minVal, maxVal]);

        lineGenerator.x(d => xScale(d.Date));
        lineGenerator.y(d => yScale(d.value));
        const hash = lineGenerator(data);
        const dataLength = data.length;*/
        return {pie};
        
    }

    componentDidUpdate() {
        /*
        d3.select(this.refs.xAxis).call(this.xAxis);
        d3.select(this.refs.yAxis).call(this.yAxis);
        //console.log("data length: ", this.state.dataLength)
        console.log(this.state.time)
        if(this.state.time == "day"){
            this.xAxis.tickFormat(d3.timeFormat('%m/%d'));
        }
        else{
            this.xAxis.tickFormat(d3.timeFormat('%H:%M'));
        }
        /// vantar tick format á y ás eftir stærð eininga*/
        
    }

    render() {
        return (
            <svg width={width} height={height}>
                <g transform={`translate(${width / 2},${height / 2})`}>
                <path d={this.arc(this.state.pie[0])} fill="green" />
                <path d={this.arc(this.state.pie[1])} fill="red" />
                </g>
            </svg>
        );
    }
}

export default D3DashPieComponent;
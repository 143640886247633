import { markMinersDeletedConstant }from '../constants/actions'
import service from '../services/minerService'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'

export const markMinersDeletedAction = (token, body, customer) => {
  return async(dispatch) => {
    const result = await service.markDeleted(token, body, customer);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    else{
      dispatch(setSuccessMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: markMinersDeletedConstant,
  payload: result
})

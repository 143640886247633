import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import './style.css'
import { onTabHeaderClick, onInputChange, filterCheck } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { getProfileByIdAction } from '../../redux/actions/getProfileByIdAction'
import { getPoolsAction } from '../../redux/actions/getPoolsAction';
import { addToProfileAction } from '../../redux/actions/addToProfileAction'
import { getTypesAction } from '../../redux/actions/getTypesAction'
import { getSubnetsAction } from '../../redux/actions/getSubnetsAction';
import { removeFromProfileAction } from '../../redux/actions/removeFromProfileAction'
import { addScannerToProfileAction } from '../../redux/actions/addScannerToProfileAction'
import { addMinerTypeToProfileAction } from '../../redux/actions/addMinerTypeToProfileAction'
import { removeScannerFromProfileAction } from '../../redux/actions/removeScannerFromProfileAction'
import { removeMinerTypeFromProfileAction } from '../../redux/actions/removeMinerTypeFromProfileAction'
import { deleteProfileMinerConnectionsAction } from '../../redux/actions/deleteProfileMinerConnectionsAction';
import { editProfilePoolOrderAction } from '../../redux/actions/editProfilePoolOrderAction'


class ProfileDetailView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      addPoolModalOpen: false,
      addScannerModalOpen: false,
      addMinerTypeModalOpen: false,
      poolsList: [],
      scannerList: [],
      editPoolProfile: '',
      editPoolModalOpen: false,
      newPoolOrder: '',
      minerTypeList: []
    }
  }
  componentDidMount = () => {
    this.props.getProfileByIdAction(this.props.token, this.props.match.params.name, this.props.match.params.id)
    this.props.getPoolsAction(this.props.token, this.props.match.params.name)
    this.props.getSubnetsAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id);
    this.props.getTypesAction(this.props.token, this.props.match.params.name)
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history, `/client/${this.props.match.params.name}/region/${this.props.match.params.region_id}/`)
  }
  onBack = () => {
    this.props.history.goBack()
  }
  onPoolDelete = (id) => {
    if(window.confirm("Are you sure you wish to remove this pool ?")){
      const findOrder = this.props.poolStore.profiles[0].Pools.filter((item) => item.ID == id)

      this.props.removeFromProfileAction(this.props.token, this.props.match.params.name, {profile_id: this.props.match.params.id, pool_id: id, pool_order: findOrder[0]['Pool_Order']})
    }
  }
  onScannerDelete = (id) => {
    window.confirm("Are you sure you wish to remove this scanner ?") &&
      this.props.removeScannerFromProfileAction(this.props.token, this.props.match.params.name, {profile_id: this.props.match.params.id, scanner_id: id})
  }
  onMinerTypeDelete = (id) => {
    window.confirm("Are you sure you wish to remove this miner type ?") &&
      this.props.removeMinerTypeFromProfileAction(this.props.token, this.props.match.params.name, {profile_id: this.props.match.params.id, minertype_id: id})
  }
  onPoolsAdd = () => {
    let { poolsList } = this.state;
    poolsList = poolsList.map((item, i) => ({Pool: item.value, Order: i + 1 + this.props.poolStore.profiles[0].Pools.length}))
    this.props.addToProfileAction(this.props.token, this.props.match.params.name, this.props.match.params.id, {pools: poolsList})
    this.setState({poolsList: [], addPoolModalOpen: false})
  }
  onScannerAdd = () => {
    let { scannerList } = this.state
    scannerList = scannerList.map((item) => item.value)
    this.props.addScannerToProfileAction(this.props.token, this.props.match.params.name, this.props.match.params.id, {scanners: scannerList})
    this.setState({
      scannerList: [],
      addScannerModalOpen: false
    })
  }
  onMinerTypeAdd = () => {
    let { minerTypeList } = this.state
    minerTypeList = minerTypeList.map((item) => item.value)
    this.props.addMinerTypeToProfileAction(this.props.token, this.props.match.params.name, this.props.match.params.id, {minerTypes: minerTypeList})
    this.setState({
      minerTypeList: [],
      addMinerTypeModalOpen: false
    })
  }
  onAddPoolChange = (list) => {
    this.setState({ poolsList: list })
  }
  onMinersDelete = (mac, multi) => {
    if(window.confirm("Are you sure you wish to remove the following miners")){
      let body = {
        Profile_ID:  this.props.match.params.id
      }
      if(multi){
        body.MACs = mac
      }
      else{
        body.MACs = [mac]
      }
      this.props.deleteProfileMinerConnectionsAction(body, this.props.token, this.props.match.params.name)
    }
  }
  onAddScannersChange = (list) => {
    this.setState({ scannerList: list })
  }
  onPoolEdit = (id) => {
    const profile = this.props.poolStore.profiles[0].Pools.filter((item) => item.ID === id)
    this.setState({
      editPoolProfile: profile[0],
      editPoolModalOpen: true,
      newPoolOrder: profile[0]['Pool_Order']
    })
  }
  onPoolEditSubmit = () => {
    const { newPoolOrder, editPoolProfile } = this.state
    if(parseInt(newPoolOrder) !== editPoolProfile['Pool_Order']){
      const body = {
        pool_id: editPoolProfile['ID'],
        new_pool_order: newPoolOrder,
        old_pool_order: editPoolProfile['Pool_Order']
      }
      this.props.editProfilePoolOrderAction(this.props.token, this.props.match.params.name, this.props.match.params.id, body)
      this.setState({
        editPoolModalOpen: false,
        newPoolOrder: ''
      })
    }
    else{
      alert("You cannot pick the same order")
    }
  }
  onMinerTypeChange = (list) => {
    this.setState({ minerTypeList: list })
  }
  render(){
    const { userData } = this.props
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    const scannerColumns = [
      {field: 'Name', value: 'Name'}
    ]
    const scannerButtons = userData.level >= 2 ?([{class: 'ViewBody-button-delete', onClick: this.onScannerDelete, type: 'Delete', extractKey: 'ID'}]):([])
    const minerTypeColumns = [
      {field: 'Name', value: 'Name'}
    ]
    const minerTypeButtons = userData.level >= 2 ?([{class: 'ViewBody-button-delete', onClick: this.onMinerTypeDelete, type: 'Delete', extractKey: 'ID'}]):([])
    const poolsColumns = [
      {field: 'Pool_Order', value: "Pool Order"},
      {field: 'URL', value: 'URL'},
      {field: 'Worker', value: 'Worker'},
      {field: 'Password', value: 'Password'}]
    const poolsButtons = userData.level >= 2 ?([{class: 'ViewBody-button-delete', onClick: this.onPoolDelete, type: 'Delete', extractKey: 'ID'}, {class: 'ViewBody-button-edit', onClick: this.onPoolEdit, type: 'Edit', extractKey: 'ID'}]):([])
    const poolsPick = this.props.poolStore.profiles.length > 0?this.props.poolStore.pools.filter((item) => !filterCheck(this.props.poolStore.profiles[0].Pools, item, 'ID')): []
    const addPoolsFields = [
      {Name: 'Pick Pools', PropertyName: 'none', Value: this.state.poolsList, Type: 'multi-select', Options: poolsPick.map((item, i) => ({label: `URL: ${item.URL} / Worker: ${item.Worker}`, value: item.ID}))}
    ]
    const pickScanners = this.props.poolStore.profiles.length > 0?this.props.scannerStore.subnets.filter((item) => !filterCheck(this.props.poolStore.profiles[0].Scanners, item, 'ID')): []
    const addScannersField = [
      {Name: 'Pick Scanners', PropertyName: 'none', Value: this.state.scannerList, Type: 'multi-select', Options: pickScanners.map((item ,i) => ({label: item.Name, value: item.ID}))}
    ]
    const pickMinerType = this.props.poolStore.profiles.length > 0?this.props.minerTypeStore.types.filter((item) => !filterCheck(this.props.poolStore.profiles[0].MinerTypes, item, 'ID')): []
    const addMinerTypeField = [
      {Name: 'Pick Miner Types', PropertyName: 'none', Value: this.state.minerTypeList, Type: 'multi-select', Options: pickMinerType.map((item) => ({ label: item.Name, value: item.ID }))}
    ]
    const minersColumns = [
      {field: 'MAC_Address', value: 'MAC'},
      {field: 'IP_Address', value: 'IP'},
      {field: 'Location', value: 'Location'}
    ]
    const minersButtons = userData.level >= 2 ?([{class: 'ViewBody-button-delete', onClick: this.onMinersDelete, type: 'Delete', extractKey: 'MAC_Address'}]):([])
    const pannelColumns = [
      {field: 'Name', value: 'Name'},
      {field: 'Value', value: 'Value'}
    ]
    let pannelData = this.props.poolStore.profiles.length > 0?[
      {Name: "Name", Value: this.props.poolStore.profiles[0].Name},
      {Name: 'Prefix', Value: this.props.poolStore.profiles[0].Prefix},
      {Name: 'Postfix', Value: this.props.poolStore.profiles[0].Postfix},
      {Name: 'Pools', Value: this.props.poolStore.profiles[0].Pools.length},
      {Name: 'Scanners', Value: this.props.poolStore.profiles[0].Scanners.length},
      {Name: 'Miner Types', Value: this.props.poolStore.profiles[0].MinerTypes.length},
    ]: []
    const newPickPools = this.state.editPoolProfile !== ''? this.props.poolStore.profiles[0].Pools.filter((item) => item.ID !== this.state.editPoolProfile.ID): []
    let editPoolFields = this.state.editPoolProfile !== ''?[
      {field: 'Change Pool Order', PropertyName: 'newPoolOrder', Value: this.state.newPoolOrder, Type: 'select', Options: [{text: this.state.editPoolProfile['Pool_Order'], value: this.state.editPoolProfile['Pool_Order']}].concat(newPickPools.map((item) => ({text: item.Pool_Order, value: item.Pool_Order})))}
    ]: []
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="ProfilesDetailsView-container">
      <TabHeader
        logo={
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
            <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
          </svg>
        }
        title="Manage account"
        subtitle="Manage buildings, pods, pools & more"
        tabs={userData.level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
        active="Profiles"
        onClick={this.onClick}
      />
      {this.props.poolStore.profiles.length > 0?(
        <div className="ConfigureView-content">
          <ViewBody
            data={pannelData}
            onBack={this.onBack}
            columns={pannelColumns}
            pannel
          />
          <ViewBody
            data={this.props.poolStore.profiles[0].Pools}
            columns={poolsColumns}
            buttons={poolsButtons}
            createtext="Add pool"
            list
            onCreate={e => this.setState({addPoolModalOpen: true})}
          />
          <ViewBody
            data={this.props.poolStore.profiles[0].Scanners}
            columns={scannerColumns}
            buttons={scannerButtons}
            createtext="Add scanner"
            list
            onCreate={e => this.setState({addScannerModalOpen: true})}
          />
          {this.props.poolStore.profiles[0].Scanners.length > 0?(
            <ViewBody
            data={this.props.poolStore.profiles[0].MinerTypes}
            columns={minerTypeColumns}
            buttons={minerTypeButtons}
            createtext="Add miner type"
            list
            onCreate={e => this.setState({addMinerTypeModalOpen: true})}
          />
          ):(<></>)}
          <ViewBody
            data={this.props.poolStore.profiles[0].Miners}
            columns={minersColumns}
            buttons={minersButtons}
            list
          />
        </div>
      ):(<></>)}
        <Modal
          open={this.state.addPoolModalOpen}
          center
          onClose={() => this.setState({ addPoolModalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add Pools"
            fields={addPoolsFields}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onPoolsAdd}
            onInputChange={this.onAddPoolChange}
            action="add_pools"
          />
        </Modal>
        <Modal
          open={this.state.addScannerModalOpen}
          center
          onClose={() => this.setState({ addScannerModalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add scanners"
            fields={addScannersField}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onScannerAdd}
            onInputChange={this.onAddScannersChange}
            action="add_scanners"
          />
        </Modal>
        <Modal
          open={this.state.editPoolModalOpen}
          center
          onClose={() => this.setState({ editPoolModalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Edit Order"
            fields={editPoolFields}
            addButton={true}
            buttonText="Edit"
            onAddClick={this.onPoolEditSubmit}
            onInputChange={e => onInputChange(e, this)}
            action="add_scanners"
          />
        </Modal>
        <Modal
          open={this.state.addMinerTypeModalOpen}
          center
          onClose={() => this.setState({ addMinerTypeModalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add miner types"
            fields={addMinerTypeField}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onMinerTypeAdd}
            onInputChange={this.onMinerTypeChange}
            action="add_miner_type"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  poolStore: props.poolReducer,
  scannerStore: props.subnetReducer,
  minerTypeStore: props.typeReducer
})

export default connect(mapStateToProps, {
  getProfileByIdAction,
  getPoolsAction,
  addToProfileAction,
  removeFromProfileAction,
  addScannerToProfileAction,
  addMinerTypeToProfileAction,
  addToProfileAction,
  getTypesAction,
  getSubnetsAction,
  removeScannerFromProfileAction,
  removeMinerTypeFromProfileAction,
  deleteProfileMinerConnectionsAction,
  editProfilePoolOrderAction
})(ProfileDetailView)

import {
  getMinerCommentsConstant,
  postCommentConstant,
  clearCommentsConstant
} from '../constants/actions'

const defaultState = {
  msg: '',
  data: [],
  max_page: 1,
  current_page: 1,
  page_size: 25,
  orderBy: 'Date',
  api_version: 1.1,
  acending: true
}

export default(state = defaultState, action) => {
  switch (action.type) {
    case clearCommentsConstant:
      return{
        msg: '',
        data: [],
        max_page: 1,
        current_page: 1,
        page_size: 25,
        orderBy: 'Date',
        acending: true
      }
    case getMinerCommentsConstant:
      if(action.payload.ok){
        return action.payload
      }
      return state
    case postCommentConstant:
        let postState = JSON.parse(JSON.stringify(state))
        postState.data.push({
          Date: action.payload.result.comment[0].Date,
          Username: action.payload.result.comment[0].Username,
          Comment: action.payload.result.comment[0].Comment
        })
        return postState
      break;
    default:
      return state
  }
}

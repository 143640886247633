import React from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import './style.css'
import { connect } from 'react-redux'


const ViewsContainer = ({ userLevel, adminLevel, client, databaseTier, userLogsAccess }) => {
  let regionId = window.location.pathname.split("/region/")
  regionId = regionId.length > 1? regionId[1]: ''
  const link = `/client/${client}/region/${regionId}`
  const logLink = `${link}/log`;
  let configureLink = `${link}/buildings`
  if(userLevel === 0 && databaseTier == 0){
    configureLink = `${link}/alerts`
  }
  if(userLevel === 0 && databaseTier == 1){
    configureLink = `${link}/pools`
  }
  const mapLink = `${link}/map`
  const userLogLink = `${link}/userLog`
  const maintenanceLink = `${link}/inventory`
  const adminLink = `/admin/users`
  const heatmapLink = `${link}/heatmaps`
  const minersLink = `${link}/miners`
  return(
    <div className="viewscontainer-outercontainer">
      <strong>Views</strong>
      <div className="viewscontainer-innerContainer">
        {client?(
          <>
          <div className="viewscontainer-item">
            <NavLink to={link}>
              <span>{client.split('_').join(' ')}</span>
                <i className="fa fa-user"/>
            </NavLink>
          </div>
          <div className="viewscontainer-item">
            <NavLink to={minersLink}>
              <span>Miners</span>
                <i className="fa fa-desktop"/>
            </NavLink>
          </div>
          <div className="viewscontainer-item">
            <NavLink to={heatmapLink}>
              <span>Heatmaps</span>
                <i className="fa fa-map"/>
            </NavLink>
          </div>
          <div className="viewscontainer-item">
            <NavLink to={configureLink}>
              <span>Configure</span>
              <i className="fa fa-cogs"/>
            </NavLink>
          </div>
          <div className="viewscontainer-item">
            <NavLink to={mapLink}>
              <span>Map Miners</span>
              <i className="fa fa-map-marker"></i>
            </NavLink>
          </div>
          {!(userLevel === 0 && databaseTier < 2)?(
            <div className="viewscontainer-item">
              <NavLink to={maintenanceLink}>
                <span>Maintenance</span>
                  <i className="fa fa-wrench" aria-hidden="true"/>
              </NavLink>
            </div>
          ):(<></>)}
          {userLevel >= userLogsAccess?(
            <div className="viewscontainer-item">
              <NavLink to={userLogLink}>
                <span>User Log</span>
                  <i className="fa fa-history"/>
              </NavLink>
            </div>
          ):(<></>)}
          {userLevel >= adminLevel?(
            <div className="viewscontainer-item">
              <NavLink to='/admin/users'>
                <span>Admin</span>
                <i className="fa fa-user-secret"/>
              </NavLink>
            </div>
          ):(<></>)}
          </>
        ):(
          userLevel >= adminLevel?(
            <div className="viewscontainer-item">
              <NavLink to='/admin/users'>
                <span>Admin</span>
                <i className="fa fa-user-secret"/>
              </NavLink>
            </div>
          ):(
            <div className="viewscontainer-item">
              <span>No views availalbe</span>
            </div>
          )
        )}
      </div>
    </div>
  )
}

const mapStateToPops = (props) => ({
  client: props.clientReducer.currentClient
})

export default connect(mapStateToPops, null)(ViewsContainer)

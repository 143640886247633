import React from 'react';
//import { Tabs, Tab } from 'react-tab-view'
import ConfigureHouseForm from '../../Components/ConfigureHouseForm';
import ConfigurePodForm from '../../Components/ConfigurePodForm';
import ConfigurePoolForm from '../../Components/ConfigurePoolForm';
import ConfigureHouseSubnet from '../../Components/ConfigureHouseSubnet';
import ConfigureTypeForm    from '../../Components/ConfigureTypeForm'
import ConfigBuildingSection from '../../Components/ConfigBuildingSection'
import ConfigConnectionSection from '../../Components/ConfigConnectionSection'
import ConfigPoolSection from '../../Components/ConfigPoolSection'
import ConfigProfileSection from '../../Components/ConfigProfileSection'
import AlertsSettingsTable from '../../Components/AlertsSettingsTable'
import ConfigAlertsSection from '../../Components/ConfigAlertsSection'
import ConfigMinerTypeSection from '../../Components/ConfigMinerTypeSection'
import ConfigPodSection from '../../Components/ConfigPodSection'
import PickProfileModal from '../../Components/PickProfileModal'
import Podcard from '../../Components/Podcard';
import deleteIcon from '../../data/Icons/delete-black-18dp.svg'
import { getHousesAction } from '../../redux/actions/getHousesAction';
import './style.css';
import { getSubnetsAction } from '../../redux/actions/getSubnetsAction';
import { getAllTypesAction } from '../../redux/actions/getAllTypesAction'
import { getAllPodsAction } from '../../redux/actions/getAllPodsAction';
import { deletePoolAction } from '../../redux/actions/deletePoolAction';
import { deletePodAction } from '../../redux/actions/deletePodAction';
import { deleteHouseAction } from '../../redux/actions/deleteHouseAction';
import { postNewHouseAction } from '../../redux/actions/postHouseAction';
import { postNewPodAction } from '../../redux/actions/postPodAction';
import { getPoolsAction } from '../../redux/actions/getPoolsAction';
import { postPoolAction } from '../../redux/actions/postPoolAction';
import { putPoolAction } from '../../redux/actions/putPoolAction';
import { deleteFavItemsAction } from '../../redux/actions/deleteFavItemsAction';
import { getHouseSubnetConAction } from '../../redux/actions/getHouseSubnetConAction'
import { postHouseSubnetConAction } from '../../redux/actions/postHouseSubnetConAction'
import { deleteHouseSubnetConAction } from '../../redux/actions/deleteHouseSubnetConAction';
import { getAccessLevelsAction } from '../../redux/actions/getAccessLevelsAction'
import { deleteTypeAction } from '../../redux/actions/deleteTypeAction'
import { postTypeAction } from '../../redux/actions/postTypeAction';
import { getTypesAction } from '../../redux/actions/getTypesAction';
import { getAlertSettingsAction } from '../../redux/actions/getAlertSettingsAction'
import { putAlertSettingsAction } from '../../redux/actions/putAlertSettingsAction'
import { getProfilesAction } from '../../redux/actions/getProfilesAction'
import { removeFromProfileAction } from '../../redux/actions/removeFromProfileAction'
import { addToProfileAction } from '../../redux/actions/addToProfileAction'
import { postProfileAction } from '../../redux/actions/postProfileAction'
import { deleteProfileAction } from '../../redux/actions/deleteProfileAction'
import Poolcard from '../../Components/Poolcard'
import { connect } from 'react-redux'
import { Modal } from "react-responsive-modal";
const jwt = require('jwt-simple');


class ConfigureView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      client: '',
      subnets: [],
      privilegeLevel: 0,
      houseName: '',
      token: '',
      subnetName: '',
      podsByHouse: [],
      profilePools: [],
      podName: '',
      poolURL: '',
      workerName: '',
      profileName: '',
      password: '',
      errorMessage: '',
      isEditOpen: false,
      currentEditId: '',
      houseConnectionName: '',
      subnetConnectionName: '',
      podHouseName: '',
      typeName: 'AntminerS9',
      alertSettings: [],
      showBuildingSection: true,
      showAddBuilding: false,
      newBuildingName: '',
      podBuildingName: '',
      showConnectionSection: true,
      showAddConnection: false,
      showPodSection: true,
      showAddPod: false,
      showPoolSection: false,
      showProfileSection: false,
      showPoolAdd: false,
      showProfileAdd: false,
      showAlertSection: false,
      showMinerTypeSection: false,
      showAddMinerType: false,
      minerTypeSelectionItem: -1,
      addPoolToProfileModalOpen: false,
      poolToProfileIndex: 0,
      currentProfileID: 0,
      userData: ''
    }
  }
  componentDidMount = async () => {
    const element = document.getElementById("configureLink")
    if (element) {
      element.classList.add("itemSelected")
    }
    const userData = jwt.decode(this.props.token, process.env.REACT_APP_DECRYPT_KEY);
    this.props.getTypesAction(this.props.token, this.props.match.params.name);
    this.props.getHouseSubnetConAction(this.props.token, this.props.match.params.name);
    this.props.getAllTypesAction(this.props.token)
    this.props.getProfilesAction(this.props.token, this.props.match.params.name)
    await this.props.getAlertSettingsAction(this.props.token, this.props.match.params.name)
    await this.props.getHousesAction(this.props.token, this.props.match.params.name);
    await this.props.getSubnetsAction(this.props.token, this.props.match.params.name);
    await this.props.getAllPodsAction(this.props.token, this.props.match.params.name);
    await this.props.getPoolsAction(this.props.token, this.props.match.params.name);
    this.props.getAccessLevelsAction(this.props.token)
    let privileges = this.props.clientStore.clients.filter((item) => item.client === this.props.match.params.name)[0].privileges;
    let privilegeLevel = this.props.clientStore.clients.filter((item) => item.client === this.props.match.params.name)[0].level;
    let level = -1;
    privileges.map((item) => {
      if (item === "SELECT" && level < 0) {
        level = 0;
      }
      if (item === "INSERT" && level < 1) {
        level = 1;
      }
      if (item == "DELETE" && level < 2) {
        level = 2;
      }
    });
    let podHouseName = ''
    let podBuildingName = ''
    let houseConnectionName = ''
    if (this.props.houseStore.length > 0) {
      podHouseName = this.props.houseStore[0].Name
      podBuildingName = this.props.houseStore[0].Name
      houseConnectionName = this.props.houseStore[0].Name
    }
    let subnetConnectionName = ''
    let subnetName = ''
    if (this.props.subnets.length > 0) {
      subnetName = this.props.subnets[0].Name;
      subnetConnectionName = this.props.subnets[0].Name;
    }
    this.setState({
      podHouseName: podHouseName,
      subnetName: subnetName,
      token: this.props.token,
      client: this.props.match.params.name,
      privilegeLevel,
      subnetConnectionName,
      houseConnectionName,
      podBuildingName,
      userData,
      alertSettings: JSON.parse(JSON.stringify(this.props.alertStore))
    })
  }
  componentWillUnmount() {
    const element = document.getElementById("configureLink");
    if (element) {
      element.classList.remove("itemSelected");
    }
  }
  //on form submit for when trying to add a new house
  onBuildingSave = async(e) => {
    const { client, token, newBuildingName } = this.state;
    if (newBuildingName.length > 12) {
      alert("The name you picked is too long, max length is 12");
      return;
    }
    if (newBuildingName == '') {
      alert("The house name is empty");
      return;
    }
    await this.props.postNewHouseAction({ Name: newBuildingName }, token, client);
    const newHouse = this.props.houseStore[0].Name;
    this.setState({
      houseConnectionName: newHouse,
      podHouseName: newHouse,
      newBuildingName: '',
      showAddBuilding: false
    })
  }
  onHouseFormSubmit = async (e) => {
    const { client, token, houseName, privilegeLevel } = this.state;
    if (privilegeLevel < 1) {
      alert("you do not have privileges to do that")
      return;
    }
    if (houseName.length > 12) {
      alert("The name you picked is too long, max length is 12");
      return;
    }
    if (houseName == '') {
      alert("The house name is empty");
      return;
    }
    await this.props.postNewHouseAction({ Name: houseName }, token, client);
    const newHouse = this.props.houseStore[0].Name;
    this.setState({
      houseConnectionName: newHouse,
      podHouseName: newHouse
    })
  }
  onTypeFormSubmit = async (e) => {
    const { client, token, typeName, privilegeLevel, minerTypeSelectionItem } = this.state;
    if (privilegeLevel < 1) {
      alert("you do not have privileges to do that")
      return;
    }
    if(minerTypeSelectionItem == -1){
      alert("You need to pick a type");
      return
    }
    if (typeName.length > 20) {
      alert("The name you picked is too long, max length is 20");
      return;
    }
    if (typeName == '') {
      alert("The house name is empty");
      return;
    }

    //this.props.postTypeAction({Name: typeName}, token, client);
    this.props.postTypeAction(this.props.typeStore.allTypes[minerTypeSelectionItem], token, client);
    this.setState({typeName: '',minerTypeSelectionItem: -1})
  }
  //on form submit for when trying to add a new pod
  onPodFormSubmit = async (e) => {
    const { podName, podBuildingName, client, token, privilegeLevel } = this.state;
    if (privilegeLevel < 1) {
      alert("you do not have privileges to do that")
      return;
    }
    if (podName.length > 10) {
      alert("The name you picked is too long, max length is 10");
      return;
    }
    const tmpArray = JSON.parse(JSON.stringify(this.props.houseStore));
    let houseID = tmpArray.filter((item) => item.Name === podBuildingName)
    if (houseID.length > 0) {
      houseID = houseID[0].ID
    }
    else {
      alert("did not find house")
      return
    }
    this.props.postNewPodAction({ Name: podName, House_ID: houseID }, token, client)
    this.setState({ showAddPod: false, podName: '', podBuildingName: this.props.houseStore[0].Name })
  }
  //on form submit for when trying to add a new pool
  onPoolFormSubmit = (e) => {
    const { poolURL, workerName, password, client, token, privilegeLevel } = this.state;
    if (privilegeLevel < 2) {
      alert("you do not have privileges to do that")
      return;
    }
    this.props.postPoolAction(token, client, { URL: poolURL, Worker: workerName, Password: password })
    this.setState({ poolURL: '', workerName: '', password: '', showPoolAdd: false })
  }
  onProfileFormSubmit = (e) => {
    let { profilePools, profileName } = this.state
    if(profileName === ''){
      alert("profile name must can't be empty")
      return
    }
    this.props.postProfileAction(this.props.token, this.props.match.params.name, {name: profileName, pools: profilePools})
    this.setState({profilePools: [], showProfileAdd: false})
  }
  //on form submit for when trying to edit a pool
  onPoolEditFormSubmit = async (e) => {
    const { poolURL, workerName, password, client, token, currentEditId } = this.state;
    await this.props.putPoolAction(token, client, { URL: poolURL, Worker: workerName, Password: password }, currentEditId);
    // maybe create an helper function for this
    if (!this.props.poolStore.ok) {
      this.setState({ errorMessage: this.props.poolStore.msg })
      this.timeoutId = setTimeout(function () {
        this.setState({ errorMessage: '' })
      }.bind(this), 3000);
    }
    else {
      this.setState({ poolURL: '', workerName: '', password: '', isEditOpen: false, currentEditId: '' })
    }
  }
  //Runs when the name of the selected page is changed
  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  //Runs when the edit button is pressed
  onEditOpenClick = (id) => {
    const { privilegeLevel } = this.state;
    if (privilegeLevel < 2) {
      alert("you do not have prilege to do that")
      return;
    }
    let tmpPools = this.props.poolStore.pools;
    tmpPools = tmpPools.filter((item) => item.ID === id);
    this.setState({ isEditOpen: true, poolURL: tmpPools[0].URL, workerName: tmpPools[0].Worker, password: tmpPools[0].Password, currentEditId: id });
  }
  //Handles the function to delete House, Pool, Pod and Connections.
  onDeleteClick = async (id, type) => {
    const { client, privilegeLevel } = this.state;
    if (privilegeLevel < 2) {
      alert("you do not have prilege to do that")
      return;
    }
    switch (type) {
      case "Pool":
        window.confirm("Are you sure you wish to delete this Pool?") &&
          this.props.deletePoolAction(this.props.token, client, id)
        break;
      case "House":
        window.confirm("Are you sure you wish to delete this House?") &&
          this.props.deleteHouseAction(this.props.token, client, id)
        this.props.favItemStore.data.houses.map((item) => {
          var temp = item.Link.split('/');
          if (id == temp[temp.length - 1]) {
            this.props.deleteFavItemsAction(this.props.token, item.ID);
          }
        })
        this.props.favItemStore.data.pods.map((item) => {
          var temp = item.Link.split('/');
          if (id == temp[temp.length - 3]) {
            this.props.deleteFavItemsAction(this.props.token, item.ID);
          }
        })
        // Setti þetta inn afþví að subnet/house connectionin voru ekki að refreshast á milli house delete'a
        this.props.getHouseSubnetConAction(this.props.token, this.props.match.params.name);
        break;
      case "Pod":
        window.confirm("Are you sure you wish to delete this Pod?") &&
          this.props.deletePodAction(this.props.token, client, id)
        this.props.favItemStore.data.pods.map((item) => {
          var temp = item.Link.split('/');
          if (id == temp[temp.length - 1]) {
            this.props.deleteFavItemsAction(this.props.token, item.ID);
          }
        })
        break;
      case "Connection":
        window.confirm("Are you sure you wish to delete this Connection?") &&
          this.props.deleteHouseSubnetConAction(id, this.props.token, client)
        break
      case "Type":
        window.confirm("Are you sure you wish to delete this Connection?") &&
          this.props.deleteTypeAction(id, this.props.token, client)
        break;
      case "Profile":
        console.log("delete profile")
      default:

    }
  }
  //on form submit for when trying to add a new connection between house and subnet
  onHouseSubnetConnectionSubmit = () => {
    const { client, token } = this.state;
    const { houseConnectionName, subnetConnectionName } = this.state;
    let houseID = this.props.houseStore.filter((item) => item.Name == houseConnectionName)
    let subnetID = this.props.subnets.filter((item) => item.Name == subnetConnectionName)
    if (houseID[0]) {
      houseID = houseID[0].ID
    }
    else {
      alert("House was not found, try different house, reload or house field might be empty")
      return;
    }
    if (subnetID) {
      subnetID = subnetID[0].ID
    }
    else {
      alert("Subnet was not found, try different house or reload ")
      return;
    }
    this.props.postHouseSubnetConAction({ Subnet_ID: subnetID, House_ID: houseID }, token, client)
    this.setState({showAddConnection: false})
  }
  //Handles the change when going between pages
  handleChange = (event, value) => {
    this.setState({
      value: value
    });
  }
  onAlertSettingChange = (e) => {
    let { alertSettings } = this.state;
    const split = e.target.name.split('-');
    const value = parseInt(e.target.value)
    if(isNaN(value)){
      alert("Enter a number")
      return
    }
    alertSettings[parseInt(split[1])].Threshold = value
    this.setState({alertSettings})
  }
  onAlertSettingSubmit = () => {
    let { alertSettings } = this.state;
    alertSettings = alertSettings.filter((item) => this.props.alertStore.some((item2) => item2.Type === item.Type && item2.Threshold !== item.Threshold))
    this.props.putAlertSettingsAction(this.props.token, alertSettings, this.props.match.params.name)
  }
  onMinerTypeInputChange = (e) => {
    let index = -1
    this.props.typeStore.allTypes.map((item, key) => {
      if(item.Name === e.target.value){
        index = key
      }
    })
    this.setState({minerTypeSelectionItem: index})
  }
  removeFromProfile = (profileID, poolID) => {
    window.confirm("Are you sure you wish to delete this pool ?") &&
      this.props.removeFromProfileAction(this.props.token, this.props.match.params.name, {profile_id: profileID, pool_id: poolID})
  }
  removeProfile = (id) => {
    window.confirm("Are you sure you wish to delete this profile ?") &&
      this.props.deleteProfileAction(this.props.token, this.props.match.params.name, id)
  }
  addToProfile = () => {
    const poolID = this.props.poolStore.pools[this.state.poolToProfileIndex].ID
    const profileID = this.state.currentProfileID
    this.props.addToProfileAction(this.props.token, this.props.match.params.name, {profile_id: profileID, pool_id: poolID})
    this.setState({poolToProfileIndex: 0, addPoolToProfileModalOpen: false})
  }
  pickNewPool = (e, i) => {
    let { profilePools } = this.state
    profilePools[i] = e.target.value
    this.setState({ profilePools })
  }
  removeNewPool = (i) => {
    let { profilePools } = this.state
    profilePools.splice(i, 1)
    this.setState({profilePools: profilePools})
  }
  addNewPool = () => {
    let { profilePools } = this.state
    profilePools.push(this.props.poolStore.pools[0].ID)
    this.setState({profilePools})
  }
  render() {
    let privilegeLevel = this.props.clientStore.clients.filter((item) => item.client === this.props.match.params.name)[0].level;
    let selectPrivileges = this.props.accessStore.filter((item) => item.Access === 'Select')
    selectPrivileges = selectPrivileges.length > 0?selectPrivileges[0].Level: 0
    let insertPrivileges = this.props.accessStore.filter((item) => item.Access === 'Insert')
    insertPrivileges = insertPrivileges.length > 0?insertPrivileges[0].Level: 1
    // for the moment we have delete with default 3 so we are not aways rendering the delete icon at the start of render
    let deletePrivileges = this.props.accessStore.filter((item) => item.Access === 'Delete')
    deletePrivileges = deletePrivileges.length > 0?deletePrivileges[0].Level: 3
    const {
      houseName,
      typeName,
      podHouseName,
      podName,
      subnetName,
      poolURL,
      workerName,
      password,
      isEditOpen,
      houseConnectionName,
      subnetConnectionName,
      alertSettings,
      showBuildingSection,
      showAddBuilding,
      newBuildingName,
      showAddConnection,
      showConnectionSection,
      showPodSection,
      showAddPod,
      podBuildingName,
      showPoolSection,
      showPoolAdd,
      showAlertSection,
      showMinerTypeSection,
      showAddMinerType,
      minerTypeSelectionItem,
      showProfileSection,
      showProfileAdd
     } = this.state
    let houseNameInPod = '';
    const headers = ['House', 'Connections', 'Pods', 'Pools']
    let value;
    if (this.state === null) {
      this.setState({
        value: 0
      })
    }
    else {
      value = this.state.value;
    }

    return (
      <div className="configureViewContainer">
          <ConfigBuildingSection
            buildings={this.props.houseStore}
            onMinimize={e => this.setState({showBuildingSection: !showBuildingSection})}
            showBody={showBuildingSection}
            showAdd={showAddBuilding}
            onAdd={ e => this.setState({showAddBuilding: !showAddBuilding})}
            buildingName={newBuildingName}
            onCancel={e => this.setState({newBuildingName: '', showAddBuilding: false})}
            onSave={this.onBuildingSave}
            privilegesLevel={privilegeLevel}
            deletePrivileges={deletePrivileges}
            insertPrivileges={insertPrivileges}
            onInputChange={this.onInputChange}
            removeItem={this.onDeleteClick}
          />
          <ConfigConnectionSection
            connections={this.props.houseSubnetConnections.connections}
            onAdd={e => this.setState({showAddConnection: true})}
            onMinimize={ e => this.setState({ showConnectionSection: !showConnectionSection })}
            showBody={showConnectionSection}
            removeItem
            showAdd={this.state.showAddConnection}
            houses={this.props.houseStore}
            scanners={this.props.subnets}
            privilegesLevel={privilegeLevel}
            deletePrivileges={deletePrivileges}
            insertPrivileges={insertPrivileges}
            subnetConnectionName={subnetConnectionName}
            houseConnectionName={houseConnectionName}
            onCancel={e => this.setState({ showAddConnection: false })}
            onSave={this.onHouseSubnetConnectionSubmit}
            onInputChange={this.onInputChange}
            removeItem={this.onDeleteClick}
          />
          <ConfigPodSection
            pods={this.props.podStore.pods}
            buildings={this.props.houseStore}
            onMinimize={e => this.setState({showPodSection: !showPodSection})}
            showBody={showPodSection}
            showAdd={showAddPod}
            onAdd={ e => this.setState({showAddPod: !showAddPod})}
            buildingName={podBuildingName}
            onCancel={e => this.setState({newBuildingName: '', showAddPod: false})}
            onSave={this.onPodFormSubmit}
            privilegesLevel={privilegeLevel}
            deletePrivileges={deletePrivileges}
            insertPrivileges={insertPrivileges}
            onInputChange={this.onInputChange}
            removeItem={this.onDeleteClick}
            podName={podName}
          />
          <ConfigPoolSection
            pools={this.props.poolStore.pools}
            poolURL={poolURL}
            workerName={workerName}
            password={password}
            onAdd={ e => this.setState({ showPoolAdd: true })}
            onMinimize={ e =>  this.setState({ showPoolSection: !showPoolSection })}
            showBody={showPoolSection}
            removeItem={this.onDeleteClick}
            showAdd={showPoolAdd}
            onSave={this.onPoolFormSubmit}
            onCancel={ e => this.setState({ showPoolAdd: false })}
            onInputChange={this.onInputChange}
            privilegesLevel={privilegeLevel}
            insertPrivileges={insertPrivileges}
            deletePrivileges={deletePrivileges}
          />
          <ConfigProfileSection
            profiles={this.props.poolStore.profiles}
            pools={this.props.poolStore.pools}
            profilePools={this.state.profilePools}
            onAdd={ e => this.setState({ showProfileAdd: true })}
            onMinimize={ e =>  this.setState({ showProfileSection: !showProfileSection })}
            showBody={showProfileSection}
            removeItem={this.removeFromProfile}
            removeProfile={this.removeProfile}
            showAdd={showProfileAdd}
            addItem={profileID => this.setState({addPoolToProfileModalOpen: true, currentProfileID: profileID})}
            onSave={this.onProfileFormSubmit}
            profileName={this.state.profileName}
            onCancel={ e => this.setState({ showProfileAdd: false })}
            onInputChange={this.onInputChange}
            addNewPool={this.addNewPool}
            pickNewPool={this.pickNewPool}
            removeNewPool={this.removeNewPool}
            privilegesLevel={privilegeLevel}
            insertPrivileges={insertPrivileges}
            deletePrivileges={deletePrivileges}
          />
          <ConfigAlertsSection
            alertSettings={alertSettings}
            onSave={this.onAlertSettingSubmit}
            onInputChange={this.onAlertSettingChange}
            privilegeLevel={privilegeLevel}
            insertPrivileges={insertPrivileges}
            onMinimize={e => this.setState({showAlertSection: !showAlertSection})}
            showBody={showAlertSection}
          />
          <ConfigMinerTypeSection
            currentTypes={this.props.typeStore.types}
            allTypes={this.props.typeStore.allTypes}
            onAdd={e => this.setState({ showAddMinerType: true })}
            onMinimize={e => this.setState({ showMinerTypeSection: !showMinerTypeSection })}
            showBody={showMinerTypeSection}
            removeItem={this.onDeleteClick}
            showAdd={showAddMinerType}
            onSave={this.onTypeFormSubmit}
            onCancel={e => this.setState({ showAddMinerType: false, minerTypeSelectionItem: -1 })}
            onInputChange={this.onMinerTypeInputChange}
            privilegesLevel={privilegeLevel}
            insertPrivileges={insertPrivileges}
            deletePrivileges={deletePrivileges}
            minerTypeSelectionItem={minerTypeSelectionItem}
          />
          <PickProfileModal
            pools={this.props.poolStore.pools}
            poolToProfileIndex={this.state.poolToProfileIndex}
            addToProfile={this.addToProfile}
            onInputChange={this.onInputChange}
            open={this.state.addPoolToProfileModalOpen}
            onClose={e => this.setState({addPoolToProfileModalOpen: false})}
          />
      </div>
    )
  }
}
const mapStateToProps = (store) => ({
  token: store.userReducer.result,
  clientStore: store.clientReducer,
  houseStore: store.houseReducer.houses,
  subnets: store.subnetReducer.subnets,
  podStore: store.podsReducer,
  poolStore: store.poolReducer,
  favItemStore: store.favItemReducer,
  houseSubnetConnections: store.houseSubnetReducer,
  typeStore: store.typeReducer,
  accessStore: store.accessReducer.levels,
  alertStore: store.alertReducer.settings
})
export default connect(
  mapStateToProps,
  {
    getHousesAction,
    getSubnetsAction,
    getAllPodsAction,
    postNewHouseAction,
    postNewPodAction,
    getPoolsAction,
    postPoolAction,
    putPoolAction,
    deletePoolAction,
    deletePodAction,
    deleteHouseAction,
    deleteFavItemsAction,
    getHouseSubnetConAction,
    postHouseSubnetConAction,
    deleteHouseSubnetConAction,
    getTypesAction,
    postTypeAction,
    deleteTypeAction,
    getAccessLevelsAction,
    getAlertSettingsAction,
    putAlertSettingsAction,
    getAllTypesAction,
    getProfilesAction,
    removeFromProfileAction,
    addToProfileAction,
    postProfileAction,
    deleteProfileAction
  })(ConfigureView)

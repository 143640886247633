import { updateSystemTokenConstant } from '../constants/actions'
import service from '../services/userService'
import { setErrorMessageAction } from './setErrorMessageAction'


export const updateSystemTokenAction = (token) => {
  return async(dispatch) => {
    console.log("CALLING SERVICE.updateSystemToken")
    const result = await service.updateSystemToken(token);
    console.log(result)
    //alert("getting all alerts returned")
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: updateSystemTokenConstant,
  payload: result
})

import React from 'react'
import Select from './select'
import React_Select from 'react-select'
import './style.css'
const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? 'red' : 'white',
      color: 'black',
      cursor: isDisabled ? 'not-allowed' : 'default'
    }
  }
};
export const convertFields = (fields, onInputChange) => {
  let returnValue = []
  fields.map((item) => {
    switch (item.Type) {
      default:
      case 'text':
          returnValue.push(
            <div class="formfieldSection_form-group">
              <label className="">{item.Name}</label>
              <input type="text" value={item.Value} className="formfieldSection_form-group_item" name={`${item.PropertyName}`} onChange={e => onInputChange(e)} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'text-range':
          returnValue.push(
            <div class="formfieldSection_form-group">
              <label className="">{item.Name}</label>
              <input type="text" value={item.Value} className="formfieldSection_form-group_item" name={`${item.PropertyName}`} onChange={e => onInputChange(e)} placeholder={`Excepts range of ${item.Name.toLowerCase()} => 1-4, 1,2-5,6 or 1`}/>
            </div>
          )
      break;
      case 'textarea':
        returnValue.push(
          <div class="formfieldSection_form-group">
            <label className="">{item.Name}</label>
            <textarea rows="3" value={item.Value} className="formfieldSection_form-group_item-textarea" name={`${item.PropertyName}`} onChange={e => onInputChange(e)} placeholder={item.Name}/>
          </div>
        )
      break;
      case 'text-disabled':
          returnValue.push(
            <div class="formfieldSection_form-group">
              <label className="">{item.Name}</label>
              <input type="text" disabled value={item.Value} className="formfieldSection_form-group_item" name={`${item.PropertyName}`} onChange={e => onInputChange(e)} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'email':
          returnValue.push(
            <div class="formfieldSection_form-group">
              <label className="">{item.Name}</label>
              <input type="email" value={item.Value} className="formfieldSection_form-group_item" onChange={e => onInputChange(e)} name={`${item.PropertyName}`} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'int':
          returnValue.push(
            <div class="formfieldSection_form-group">
              <label className="">{item.Name}</label>
              <input type="number" value={item.Value}  className="formfieldSection_form-group_item" name={`${item.PropertyName}`} onChange={e => onInputChange(e)} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'float':
          returnValue.push(
            <div class="formfieldSection_form-group">
              <label className="">{item.Name}</label>
              <input type="number" value={item.Value}  step="0.01" className="formfieldSection_form-group_item" name={`${item.PropertyName}`} onChange={e => onInputChange(e)} placeholder={item.Name}/>
            </div>
          )
      break;
      case 'multi-select':
        returnValue.push(
            <div className="react-select-group">
              <label className="">{item.Name}</label>
              <React_Select
                options={item.Options}
                onChange={onInputChange}
                isMulti
                isSearchable
                autoFocus
                value={item.Value}
                styles={colourStyles}
              />
            </div>
        )
      break;
      case 'react-single-select':
        if(item.hasOwnProperty("Placeholder")){
          returnValue.push(
              <div className="react-select-group">
                <label className="">{item.Name}</label>
                <React_Select
                  options={item.Options}
                  onChange={onInputChange}
                  isSearchable
                  autoFocus
                  styles={colourStyles}
                  placeholder={item.Placeholder}
                />
              </div>
          )
        }
        else{
          returnValue.push(
              <div className="react-select-group">
                <label className="">{item.Name}</label>
                <React_Select
                  options={item.Options}
                  onChange={onInputChange}
                  isSearchable
                  autoFocus
                  styles={colourStyles}
                />
              </div>
          )
        }

      break;
      case 'select':
         returnValue.push(
          <Select
            options={item.Options}
            name={item.Name}
            propertyName={item.PropertyName}
            onInputChange={onInputChange}
            value={item.Value}
          />
         )
        break;
    }
  })
  return returnValue
}

export const getDatabasesAction         = "GET_DATABASES_ACTION";
export const getSubnetsConstant         = "GET_SUBNETS";
export const getHousesConstant          = "GET_HOUSES";
export const getMinersConstant          = "GET_MINERS";
export const getMinerConstant           = "GET_MINER";
export const getMinersByPodConstant     = "GET_MINERS_BY_POD";
export const getMinerLogsConstant       = "GET_MINERLOGS";
export const getMinerByMacConstant      = 'GET_MINER_BY_MAC'
export const mapMinerConstant           = "MAP_MINERS";
export const getPodsConstant            = "GET_PODS";
export const getPodsLimitedConstant     = "GET_PODS_LIMITED";
export const postNewHouseConstant       = "POST_HOUSE";
export const postNewPodConstant         = "POST_POD";
export const getAllPodsConstant         = "GET_ALL_PODS"
export const getPoolsConstant           = "GET_POOLS";
export const postPoolsConstant          = "POST_POOLS";
export const putPoolConstant            = "PUT_POOL";
export const sendCommandConstant        = "RESTART_MINERS";
export const setErrorMessage            = "SET_ERROR_MSG";
export const setSuccessMessage          = "SET_SUCCESS_MSG";
export const deletePodConstant          = "DELETE_POD";
export const deletePoolConstant         = "DELETE_POOL";
export const deleteHouseConstant        = "DELETE_HOUSE";
export const getHashrateConstant        = "GET_HASH";
export const getMinerHistoryConstant    = "GET_MINER_HISTORY"
export const getSectionHistoryConstant  = "GET_SECTION_HISTORY"
export const setId_getHashrateConstant    = "GET_HASH_ID"
export const getUserLogsConstant        = "GET_USER_LOGS_TIME"
export const getMinersByUserLog         = "GET_USER_LOGS_MINERS"
export const sortMinersConstant         = "SORT_MINERS";
export const sortMinerLogsConstant      = "SORT_MINER_LOGS";
export const sortUserLogsConstant       = "SORT_USER_LOGS";
export const getHistoryConstant         = "GET_HISTORY";
export const pushHistoryConstant        = "PUSH_HISTORY";
export const popHistoryConstant         = "POP_HISTORY";
export const getFavMinersConstant       = "GET_FAV_MINERS";
export const getFavItemsConstant        = "GET_FAV_ITEMS";
export const postFavMinersConstant      = "POST_FAV_MINERS";
export const postFavItemsConstant       = "POST_FAV_ITEMS";
export const deleteFavMinersConstant    = "DELETE_FAV_MINERS";
export const deleteFavItemsConstant     = "DELETE_FAV_ITEMS";
export const replaceHistoryConstant     = "REPLACE_HISTORY"
export const updatePageInfoConstant     = "UPDATE_PAGE_INFO"
export const getHouseSubnetConstant     = "GET_HOUSE_SUBNET_CONNECTIONS"
export const postHouseSubnetConstant    = "POST_HOUSE_SUBNET_CONNECTION"
export const deleteHouseSubConstant     = "DELETE_HOUSE_SUBNET_CONNECTION"
export const exitActionConstant         = "EXIT_ACTION"
export const updateClientActionConstant = "UPDATE_CLIENT"
export const removeDashActionConstant   = "REMOVE_DASH";
export const changePasswordConstant     = "CHANGE_PASSWORD"
export const getAllMinersByPodConstant  = "GET_ALL_MINERS_BY_POD"
export const clearCustomersConstant     = "CLEAR_CUSTOMERS"
export const getTypesContant            = "GET_TYPES"
export const postTypesContant           = "POST_TYPE";
export const deleteTypeConstant         = "DELETE_TYPE"
export const setMinerTypeConstant       = "SET_MINER_TYPE"
export const getMaintenanceByMACConstant= "GET_MAINTENANCE_LOG_BY_MAC"
export const getMaintenanceConstant     = "GET_MAINTENANCE"
export const postMaintenanceConstant    = "POST_MAINTENANCE"
export const clearMaintenanceConstant   = "CLEAR_MAINTENANCE"
export const getInventoryConstant       = "GET_INVENTORY";
export const adjustInventoryConstant    = "ADJUST_INVENTORY";
export const getInventoryByMinerType    = "GET_INVENTORY_BY_MINER_TYPE"
export const getInventoryHistoryConstant= "GET_INVENTORY_HISTORY"
export const renameCustomersConstant    = "RENAME_CUSTOMERS"
export const getFarmInventoryConstant   = "GET_FARM_INVENTORY"
export const postFarmInventoryConstant  = "POST_FARM_INVENTORY"
export const putFarmIvnentoryConstant   = "PUT_FARM_INVENTORY"
export const getAccessLevelsConstant    = "GET_ACCESS_LEVELS"
export const putAccessLevelsConstant    = "PUT_ACCESS_LEVELS"
export const getAlertSettingsConstant   = "GET_ALERT_SETTINGS";
export const putAlertSettingsConstant   = "PUT_ALERT_SETTINGS";
export const getCustomerAlertsConstant  = "GET_CUSTOMER_ALERT"
export const getAllAlertsConstant       = "GET_ALL_ALERTS"
export const deactivateAlertConstant    = "DEACTIVATE_ALERT"
export const getAlertDetailsConstant    = "GET_ALERT_DETAILS"
export const clearAlertsConstant        = "CLEAR_ALERTS"
export const markMinersDeletedConstant  = "MARK_DELETED"
export const changePageSize24HoursPlus  = "CHANGE_PAGE_SIZE_24PLUS"
export const changePageSize24Hours      = "CHANGE_PAGE_SIZE_24"
export const onPageClick24HoursPlus     = "ON_PAGE_CLICK_24PLUS"
export const onPageClick24Hours         = "ON_PAGE_CLICK_24"
export const onPageClickCustomerStatus  = "ON_PAGE_CLICK_CUSTOMER_STATUS"
export const changePageSizeCustomerStatus = "CHANGE_PAGE_SIZE_CUSTOMER_STATUS"
export const clearMessagePannelConstant = "CLEAR_MESSAGE_PANNEL"
export const filterCustomers            = "FILTER_CUSTOMERS"
export const postCommentConstant        = "POST_COMMENT"
export const seenMinerConstant          = "SEEN_MINER"
export const clearDashInfo              = "CLEAR_DASH"
export const filterAlertConstant        = "FILTER_ALERTS"
export const sendTicketConstant         = "SEND_TICKET"
export const getReportDataConstant      = "GET_REPORT_DATA"
export const toggleSeenMinersConstant   = "TOGGLE_SEEN"
export const alterStatusConstant        = "ALTER_MINERS_STATUS"
export const clearHeatmapReducer        = "CLEAR_HEATMAP_REDUCER"
export const updateHeatmapReducer       = "UPDATE_HEATMAP_REDUCER"
export const getHeatmapMinersConstant   = "GET_HEATMAP_MINERS"
export const setId_getHeatmapMinersConstant = "GET_HEATMAP_MINERS_ID"
export const clearLocationsConstant     = "CLEAR_LOCATIONS"
export const clearCurrentClientConstant = "CLEAR_CURRENT_CLIENT"
export const clearMinersConstant        = "CLEAR_MINERS_CONSTANTS"
export const clearSubnetsConstant       = "CLEAR_SUBNETS_CONSTANT"
export const clearPodsConstant          = "CLEAR_PODS_CONSTANT"
export const clearHousesConstant        = "CLEAR_HOSES_CONSTANT"
export const getAllMinersConstant       = "GET_ALL_MINERS"
export const setId_getAllMinersConstant   = "GET_ALL_MINERS_ID"
export const fetchMoreMinersConstant    = "FETCH_MORE_MINERS"
export const resetMinerDisplayInterval  = "RESET_MINER_INTERVAL"
export const toggleTableColumnConstant  = "TOGGLE_TABLE_COLUMN"
export const getMinerCommentsConstant   = "GET_MINER_COMMENTS"
export const clearCommentsConstant      = "CLEAR_COMMENTS"
export const getSystemLogsConstant      = "GET_SYSTEM_LOGS"
export const changeSystemLogsPageSizeConstant   = "CHANGE_SYSTEMLOGS_PAGE_SIZE"
export const onSystemLogsPageClickConstant      = "SYSTEM_PAGE_SIZE_CLICK"
export const clearSystemLogsConstant            = "CLEAR_SYSTEM_LOGS"
export const getErrorLogsConstants              = "GET_ERROR_LOGS"
export const clearErrorLogsConstants            = "CLEAR_ERROR_LOGS"
export const changeErrorLogsPageSizeConstant    = "CHANGE_ERRORLOGS_PAGE_SIZE"
export const onErrorLogsPageClickConstant       = "ERROR_PAGE_SIZE_CLICK"
export const getAllTypesConstant                = "GET_ALL_TYPES"
export const clearHashReducerConstant           = "CLEAR_HASH_REDUCER"
export const updateSideBarConstant              = "UPDATE_SIDEBAR"
export const toggleRefreshDataConstant          = "TOGGLE_REFRESH_DATA"
export const getAllUsersConstant                = "GET_ALL_USERS"
export const postNewUserConstant                = "POST_NEW_USER"
export const createNewDatabaseConstant          = "CREATE_NEW_DATABASE"
export const createNewUserConstant              = "CREATE_NEW_USER"
export const createNewMinerTypeconstant         = "CREATE_NEW_MINER_TYPE"
export const adjustUserPrivilegesConstant       = "ADJUST_USER_PRIVILEGES"
export const deleteDatabaseConstant             = "DELETE_DATABASE"
export const deleteUserConstant                 = "DELETE_USER"
export const getCustomersNamesConstant          = "GET_CUSTOMERS_NAME"
export const adjustSystemPrivilegesConstant     = "ADJUST_SYSTEM_PRIVILEGES"
export const deleteMinerTypeConstant            = "DELETE_MINER_TYPE"
export const editMinerTypeConstant              = "EDIT_MINER_TYPE"
export const getMinerStatusOverviewConstant     = "GET_MINER_STATUS_OVERVIEW"
export const getOverviewMinersByTypeConstant    = "GET_OVERVIEW_MINERS"
export const setID_getOverviewMinersByTypeConstant = "GET_OVERVIEW_MINERS_ID"
export const updateDatabaseTierConstant         = "UPDATE_DATABASE_TIER"
export const getAllScannersConstant             = "GET_ALL_SCANNERS"
export const postScannerConstant                = "POST_SCANNER"
export const rebootScannerConstant              = "REBOOT_SCANNER"
export const postCustomerScannerConstant        = "POST_CUSTOMER_SCANNER"
export const patchCustomerScannerConstant       = "PATCH_CUSTOMER_SCANNER"
export const patchScannerConstant               = "PATCH_SCANNER"
export const deployScannerConstant              = "DEPLOY_SCANNER"
export const postProfileConstant                = "POST_PROFILE"
export const addToProfileConstant               = "ADD_TO_PROFLIE"
export const removeFromProfileConstant          = "REMOVE_FROM_PROFLILE"
export const deleteProfileConstant              = "DELETE_PROFILE"
export const getProfilesConstant                = "GET_PROFILES"
export const getProfileByIdConstant             = "GET_PROFILE_BY_ID"
export const putScannerToProfileConstant        = "PUT_SCANNER_TO_PROFILE"
export const putMinerTypeToProfileConstant      = "PUT_MINERTYPE_TO_PROFILE"
export const deleteScannerFromProfileConstant   = "DELETE_SCANNER_FROM_PROFILE"
export const deleteMinerTypeFromProfileConstant = "DELETE_MINERTYPE_FROM_PROFILE"
export const setErrorLogsStatus                 = "SET_ERROR_LOGS_STATUS"
export const setSystemLogsStatus                = "SET_SYSTEM_LOGS_STATUS"
export const updateSingleMinerLocation          = "UPDATE_SINGLE_MINER_LOCATION"
export const restoreMinersConstant              = "RESORE_MINERS"
export const getDatabaseInfoConstant            = "GET_DATABASE_INFO"
export const addUsersToDatabaseConstant         = "ADD_USERS_TO_DATABASE"
export const getScannerInfoConstant             = "GET_SCANNER_INFO"
export const getMinerTypePowerConstant          = "GET_MINER_TYPE_POWER"
export const clearMinerTypePowerConstant        = "CLEAR_MINER_TYPE_POWER"
export const adjustPerfromanceDropConstant      = "ADJUST_PERFORMANCE_DROP"
export const getEmailListConstant               = "GET_EMAIL_LIST"
export const deleteFromEmailListConstant        = "DELETE_FROM_EMAIL_LIST"
export const postToEmailListConstant            = "POST_TO_EMAIL_LIST"
export const getAverageReportData24Constant     = "GET_AVERAGE_REPORT_24"
export const getAverageReportData7daysConstant  = "GET_AVERAGE_REPORT_7days"
export const toggleCustomerPannelConstant       = "TOGGLE_CUSTOMER_PANNELS"
export const getQrCodeConstant                  = "GET_QR_CODE"
export const postAuthCodeConstant               = "POST_AUTH_CODE"
export const getMonthlyRevenueConstant          = "GET_MONTHLY_REVENUE"
export const getDailyRevenueConstant            = "GET_DAILY_REVENUE"
export const clearRevenueConstant               = "CLEAR_REVENUE"
export const createSocket                       = "CREATE_SOCKET"
export const clearSocket                        = "CLEAR_SOCKET"
export const clearHouseSubnetConstant           = "CLEA_HOUSE_SUBNET_CONNECTIONS"
export const getRegionInfoConstant              = "GET_REGION_INFO"
export const createNewRegionConstant            = "CREATE_REGION"
export const addUsersToRegionConstant           = "ADD_USERS_TO_REGION"
export const getRegionsConstant                 = "GET_REGIONS"
export const getRackShelfsConstant              = "GET_RACK_SHELF"
export const postRackShelfsConstant             = "POST_RACK_SHELF"
export const editRackShelfConstant              = "EDIT_RACK_SHELF"
export const deleteRackShelfConstant               = "DELETE_RACK_SHELF"
export const getRackProfilesConstant               = "GET_RACK_PROFILE"
export const getRackProfileByIDConstant            = "GET_RACK_PROFILE_BY_ID"
export const postRackProfileConstant               = "POST_RACK_PROFILE"
export const deleteRackProfileConstant             = "DELETE_RACK_PROFILE"
export const editRackProfileConstant               = "EDIT_RACK_PROFILE"
export const addRackProfileShelfsConnectionConstant = "ADD_RACK_PROFILE_SHELF_CONNECTION"
export const deleteRackProfileShelfsConnectionConstant = "DELETE_RACK_PROFILE_SHELF_CONNECTION"
export const getRacksByPodIDConstant                  = "GET_RACKS_BY_POD_ID"
export const postRacskToPodConstant                   = "POST_RACKS_TO_POD"
export const removeRackFromPodConstant                = "REMOVE_RACK_FROM_POD"
export const getPodByIDConstant                       = "GET_POD_BY_ID"
export const updateHeatmapPersistConstant             = "UPDATE_HEATMAP_PERSIST"
export const updateResponsePropertyConstant           = "UPDATE_RESPONSE_PROPERTY"
export const responseResultsConstant                  = "RESPONSE_RESULTS"
export const closeResponsePanelConstant               = "CLOSE_RESPONSE_PANEL"
export const addToRegionEmailListConstant             = "ADD_TO_REGION_EMAIL_LIST"
export const addUserActivityConstant                  = "ADD_USER_ACTIVITY"

export const getMinerFlagsOverviewConstant            = "GET_MINER_FLAG_OVERVIEW"
export const getMinersWithFlagsConstant               = "GET_MINER_WITH_FLAGS"
export const setId_getMinersWithFlagConstant          = "GET_MINER_WITH_FLAGS_ACTION_ID"
export const getMinerFlagCheckOptionConstant          = "GET_MINER_FLAG_CHECK_OPTION"
export const getMinerFlagTypeOptionConstant           = "GET_MINER_FLAG_TYPE_OPTION"
export const postMultiMinerFlagChecksConstant         = "POST_MULTI_MINER_FLAG_CHECK"
export const getMinerFlagsByMACConstant               = "GET_MINER_FLAGS_BY_MAC"
export const getMinerFlagRecoveryLogByFlagIDConstant  = "GET_MINER_FLAGS_RECOVERY_LOG_BY_FLAG_ID"
export const getMinerFlagActivityLogByFlagIDConstant  = "GET_MINER_FLAGS_ACTIVITY_LOG_BY_FLAG_ID"
export const postSingleMinerFlagCheckConstant         = "POST_SINGLE_MINER_FLAG_CHECK"
export const getMinerFlagByIDConstant                 = "GET_MINER_FLAG_BY_ID"

export const getRegionOverviewConstant                = "GET_REGION_OVERVIEW_CONSTANT"
export const getUserLogsByMacConstant                 = "GET_USER_LOGS_BY_MAC"
export const getUsersMinerListColumnsConstant         = "GET_USER_MINER_LIST_COLUMNS"
export const updateUsersMinerListColumnsConstant      = "UPDATE_USER_MINER_LIST_COLUMNS"

export const getMinerTypeInfoByIDConstant             = "GET_MINER_TYPE_BY_ID"
export const getFlagSettingsByMinerTypeIDConstant     = "GET_FLAG_SETTINGS_BY_MINER_TYPE_ID"
export const updateFlagSettingsByMinerTypeIDConstant  = "UPDATE_FLAG_SETTINGS_BY_MINER_TYPE_ID"

export const updateMinerFlagTypeSeverityConstant      = "UPDATE_MINER_FLAG_TYPE_SEVERITY"
export const getMinerFlagSeverityConstant             = "GET_MINER_FLAG_SEVERITY"
export const getMinerFlagTypesConstant                = "GET_MINER_FLAG_TYPES"


export const getFlagTypeInfoByIDConstant                = "GET_FLAG_TYPE_INFO_BY_ID"
export const getSubnetsConnectionsByMinerFlagIDConstant = "GET_SUBNETS_CONNECTIONS_BY_MINER_FLAG_ID"
export const addSubnetFlagTypeConnectionConstant        = "ADD_SUBNET_FLAG_TYPE_CONNECTION"
export const deleteSubnetFlagTypeConnectionConstant     = "DELETE_SUBNET_FLAG_TYPE_CONNECTION"

export const postCustomMinerFlagsConstant               = "POST_CUSTOM_MINER_FLAGS"
export const getFlagCommentsByIdConstant                = "GET_FLAG_COMMENTS_BY_ID"
export const postFlagCommentConstant                    = "POST_FLAG_COMMENT"
export const getFlagCheckoutDataConstant                = "GET_FLAG_CHECKOUT_DATA"

export const setUserDataConstant                        = "SET_USER_DATA"
export const getUserDataConstant                        = "GET_USER_DATA"
export const updateSystemTokenConstant                  = "UPDATE_SYSTEM_TOKEN"

// actions -> PowerCutOrdersView 
export const getPowerCutOrdersConstant                  = "GET_POWER_CUT_ORDERS"
export const postPowerCutOrderConstant                  = "POST_POWER_CUT_ORDER"
export const patchPowerCutOrderStatusConstant           = "PATCH_POWER_CUT_ORDER_STATUS"

// actions -> PowerCutOrderDetailView
export const getPowerCutOrderByOrderIDConstant          = "GET_POWER_CUT_ORDER_BY_ORDER_ID"
export const getPowerCutOrderDatabasesByOrderIDConstant = "GET_POWER_CUT_ORDER_DATABASES_BY_ORDER_ID"
export const getPowerCutOrderMinersByOrderIDConstant    = "GET_POWER_CUT_ORDER_MINERS_BY_ORDER_ID"
export const getPowerCutOrderActionsByOrderIDConstant   = "GET_POWER_CUT_ORDER_ACTIONS_BY_ORDER_ID"
export const setPowerCutMinersActionIdConstant          = "SET_POWER_CUT_ORDER_MINERS_ACTION_ID"

export const getMinersByConfigProfileConstant           = "GET_MINERS_BY_CONFIG_PROFILE"
export const deleteProfileMinerConnectionsConstant      = "DELETE_PROFILE_MINER_CONNECTIONS"
export const postProfileMinerConnectionsConstant        = "POST_PROFILE_MINER_CONNECTIONS"
export const editProfilePoolOrderConstant               = "EDIT_PROFILE_POOL_ORDER"



export const getTuningProfilesConstant                  = "GET_TUNING_PROFILES"
export const postTuningProfilesConstant                 = "POST_TUNING_PROFILES"
export const getFirmwareVersionsConstant                = "GET_FIRMWARE_VERSIONS"
export const getTuningAttributesConstant                = "GET_TUNING_ATTRIBUTES"
export const getTuningAttributesValuesConstant          = "GET_TUNING_ATTRIBUTES_VALUES"


export const getTuningProfilesByIDConstant                 = "GET_TUNING_PROFILES_BY_ID"
export const postTuningProfilesFirmwareConnectionsConstant  = "POST_TUNING_PROFILES_FIRMWARE_CONNECTIONS"
export const postTuningProfilesAttributesConnetionsConstant = "POST_TUNING_PROFILES_ATTRIBUTES_CONNECTIONS"
export const postTuningProfilesMinerTypesConnectionsConstant = "POST_TUNING_PROFILES_MINER_TYPE_CONNECTIONS"
export const postTuningProfilesSubnetsConnectionsConstant   = "POST_TUNING_PROFILE_SUBNETS_CONNECTIONS"
export const postTuningProfilesPodConnectionsConstant       = "POST_TUNING_PROFILE_POD_CONNECTIONS"

export const getFirmwareVendorsConstant              = "GET_FIRMWARE_VENDORS"
export const postFirmwareVendorsConstant             = "POST_FIRMWARE_VENDORS"
export const postFirmwareVersionsConstant            = "POST_FIRMWARE_VERSIONS"
export const getFirmwareVersionsByVendorsIDConstant  = "GET_FIRMWARE_VERSIONS_BY_VENDOR_ID"
export const deleteTuningProfilesConstant                     = "DELETE_TUNING_PROFILES"
export const deleteTuningProfilesFirmwaresConnectionsConstant  = "DELETE_TUNING_PROFILES_FIRMWARES_CONNECTION"
export const deleteTuningProfilesAttributesConnetionsConstant = "DELETE_TUNING_PROFILES_ATTRIBUTES_CONNECTION"
export const deleteTuningProfilesMinerTypesConnectionsConstant = "DELETE_TUNING_PROFILES_MINER_TYPES_CONNECTION"

export const editSerialNumberConstant = "EDIT_SERIAL_NUMBER"
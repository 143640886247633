import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onInputChange } from '../../functions'
import { onTabHeaderClick } from '../../functions'
import { adminView } from '../../helper/tabs'
import TuningAttributesTable from '../../Components/TuningAttributesTable';


import { getFirmwareVersionsAction } from '../../redux/actions/firmware/getFirmwareVersionsAction';
import { getTuningAttributesAction } from '../../redux/actions/tuning/getTuningAttributesAction';
import { getFirmwareVendorsAction } from '../../redux/actions/firmware/getFirmwareVendorsAction';
import { postFirmwareVendorsAction } from '../../redux/actions/firmware/postFirmwareVendorsAction'
import { postFirmwareVersionsAction } from '../../redux/actions/firmware/postFirmwareVersionsAction'
import './style.css'




class TuningView extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            addFirmwareVendorModalOpen: false,
            tagFirmwareVersionsModalOpen: false,
            vendorName: ''
        }
    }
    componentDidMount = () => {
      this.props.getFirmwareVersionsAction(this.props.token)
      this.props.getFirmwareVendorsAction(this.props.token)
      this.props.getTuningAttributesAction(this.props.token)
    }
    onClick = (tab) => {
        onTabHeaderClick(tab, this.props.history)
    }
    onFirmwareVendorCreate = () => {

    }
    onAttributePick = (item, type) => {
      console.log(item, type)
    }
    render = () => {
        const modalStyle = {
          modal: {
            width: '100%',
            background: '#141619'
          }
        };
        //let   {  } = this.state
        console.log(this.props.firmwareStore)
        const firmwareVendorsColumns = [
            {field: "ID", value: "ID"},
            {field: "Name", value: "Name"},
            {field: "Firmwares", value: "Firmwares", func: 'length'},
        ]
        const addVendorFields = [
          {Name: 'Vendor Name', PropertyName: 'vendorName', Value: this.state.vendorName, Type: 'text', Options: []}
        ]
        return (
            <div className="adminView-container">
             <TabHeader
                logo={
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                    <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                    </svg>
                }
                title="Server Admin"
                subtitle="Manage scanner, databases & more"
                tabs={adminView}
                active="Tuning"
                onClick={this.onClick}
            />
            <div className="ConfigureView-content">
              <h3 className="viewBody-title">Firmware Vendors</h3>
              <ViewBody
                data={this.props.firmwareStore.vendors}
                columns={firmwareVendorsColumns}
                list
                createtext="New Vendor"
                onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
                itemClickInfo={{name: 'tuning/vendor', property: 'ID'}}
                onCreate={e => this.setState({addFirmwareVendorModalOpen: true})}
              />
              <div className="adminView-tuningtable">
                <h3 className="viewBody-title">Attributes List</h3>
                <TuningAttributesTable
                    attributesValueList={[]}
                    pickAttributes={this.props.tuningStore.attributes}
                    onInputChange={this.onAttributePick}
                    displayOnly={true}
                />
              </div>
            </div>
            <Modal
              open={this.state.addFirmwareVendorModalOpen}
              center
              onClose={() => this.setState({ addFirmwareVendorModalOpen: false })}
              styles={modalStyle}
            >
              <FormfieldSection
                title="Create new vendor"
                fields={addVendorFields}
                addButton={true}
                buttonText="Add"
                onAddClick={this.onFirmwareVendorCreate}
                onInputChange={e => onInputChange(e, this)}
                action="add_profile"
              />
            </Modal>
          </div>
        )
    }
}

const mapStateToProps = (props) => ({
    token: props.userReducer.result,
    adminStore: props.adminReducer,
    tuningStore: props.tuningReducer,
    firmwareStore: props.firmwareReducer
  })
  
  export default connect(mapStateToProps, {
    getFirmwareVersionsAction,
    getTuningAttributesAction,
    getFirmwareVendorsAction,
    postFirmwareVendorsAction,
    postFirmwareVersionsAction,
  })(TuningView)
  
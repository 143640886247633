import React from 'react'
import MinerDetailsTable_v1_2 from '../../Components/MinerDetailsTable_v1_2'
import MinerDetailsTable from '../../Components/MinerDetailsTable'


export const switch_minerDetailsTable = (api_version, miner) => {
  switch (api_version) {
    case 1.1:
      return (
        <MinerDetailsTable
          MAC_Address={miner.MAC_Address}
          Worker_Name={miner.Worker_Name}
          Total_Speed={miner.Total_Speed}
          Avg_Speed={miner.Avg_Speed}
          Front_Fan={miner.Front_Fan}
          Back_Fan={miner.Back_Fan}
          Asic_One={miner.Asic_One}
          Asic_Two={miner.Asic_Two}
          Asic_Three={miner.Asic_Three}
          Speed_One={miner.Speed_One}
          Speed_Two={miner.Speed_Two}
          Speed_Three={miner.Speed_Three}
          Heat_One={miner.Heat_One}
          Heat_Two={miner.Heat_Two}
          Heat_Three={miner.Heat_Three}
          Last_Update={miner.Last_Update}
          Location={miner.Location}
          IP_Address={miner.IP_Address}
          Label={miner.Label}
          Deleted={miner.Deleted}
          Status={miner.Status}
        />
      )
    case 1.2:
      return(
        <MinerDetailsTable_v1_2
          MAC_Address={miner.MAC_Address}
          Worker_Name={miner.Worker_Name}
          Total_Speed={miner.Total_Speed}
          Avg_Speed={miner.Avg_Speed}
          Front_Fan={miner.Front_Fan}
          Back_Fan={miner.Back_Fan}
          Cards={miner.Cards}
          Last_Update={miner.Last_Update}
          Location={miner.Location}
          IP_Address={miner.IP_Address}
          Label={miner.Label}
          Deleted={miner.Deleted}
          Status={miner.Status}
        />
      )
    default:
      return (
        <MinerDetailsTable
          MAC_Address={miner.MAC_Address}
          Worker_Name={miner.Worker_Name}
          Total_Speed={miner.Total_Speed}
          Avg_Speed={miner.Avg_Speed}
          Front_Fan={miner.Front_Fan}
          Back_Fan={miner.Back_Fan}
          Asic_One={miner.Asic_One}
          Asic_Two={miner.Asic_Two}
          Asic_Three={miner.Asic_Three}
          Speed_One={miner.Speed_One}
          Speed_Two={miner.Speed_Two}
          Speed_Three={miner.Speed_Three}
          Heat_One={miner.Heat_One}
          Heat_Two={miner.Heat_Two}
          Heat_Three={miner.Heat_Three}
          Last_Update={miner.Last_Update}
          Location={miner.Location}
          IP_Address={miner.IP_Address}
          Label={miner.Label}
          Deleted={miner.Deleted}
          Status={miner.Status}
        />
      )
  }
}

import { postCommentConstant } from '../constants/actions'
import service from '../services/minerService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const postCommentAction = (token, body, mac, client, type) => {
  return async (dispatch) => {
    const result = await service.postComment(token, body, client, mac)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch({
      type: postCommentConstant,
      payload: {type: type, result}
    })
  }
}

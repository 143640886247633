import { adjustPerfromanceDropConstant } from '../constants/actions'
import service from '../services//adminService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const adjustPerformanceDropAction = (token, database, body) => {
  return async(dispatch) => {
    const result = await service.adjustPerfromanceDrop(token, database, body)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}
const success = (result) => ({
  type: adjustPerfromanceDropConstant,
  payload: result
})

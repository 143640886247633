import React, {useContext} from 'react'
import { SocketContext } from '../../context/socket'
import './style.css'
const ListCommandButtons = ({ onClick, minerTypesAmount, checkFlags=false, postCustomFlags=false }) => {
  const socket = useContext(SocketContext);
  return(
    <div className="ListCommandButtons-container">
      <div className="List-command-buttons-title">
        <strong>Miner Actions</strong>
      </div>
      <div className="List-command-buttons-content">
        {postCustomFlags?(
          <div className="List-command-button-container">
            <button className="list-command-button button-flag-check" onClick={e => onClick("postCustomFlags", socket)} >
              <span className="ListCommandButtons-button-span">Create Flags</span>
            </button>
          </div>
        ):(<></>)}
        {checkFlags?(
          <div className="List-command-button-container">
            <button className="list-command-button button-flag-check" onClick={e => onClick("flagCheck", socket)} >
              <span className="ListCommandButtons-button-span">Checkout Flags</span>
            </button>
          </div>
        ):(<></>)}
        <div className="List-command-button-container">
          <button className="list-command-button button-restart" onClick={e => onClick("restart", socket)} >
            <span className="ListCommandButtons-button-span">Restart Miners</span>
          </button>
        </div>
        {minerTypesAmount === 1 ? (
          <div className="List-command-button-container">
            <button className="list-command-button button-update" onClick={e => onClick("mode", socket)}>
              <span className="ListCommandButtons-button-span">Change Mode</span>
            </button>
          </div>
        ) : (<></>)}
        {/*<div className="List-command-button-container">
          <button className="list-command-button button-update" onClick={e => onClick("update")} disabled>
            <span className="ListCommandButtons-button-span">Update Miners</span>
          </button>
        </div>*/}
        <div className="List-command-button-container">
          <button className="list-command-button button-config" onClick={e => onClick("config", socket)}>
            <span className="ListCommandButtons-button-span">Configure Miners</span>
          </button>
        </div>
        <div className="List-command-button-container">
          <button className="list-command-button button-config" onClick={e => onClick("tune", socket)}>
            <span className="ListCommandButtons-button-span">Tune Miners</span>
          </button>
        </div>
        <div className="List-command-button-container">
          <button className="list-command-button button-config" onClick={e => onClick("addConfigProfile", socket)}>
            <span className="ListCommandButtons-button-span">Set Config Profile</span>
          </button>
        </div>
        <div className="List-command-button-container">
          <button className="list-command-button button-update" onClick={e => onClick("addFav")}>
            <span className="ListCommandButtons-button-span">Add To Favorite</span>
          </button>
        </div>
        <div className="List-command-button-container">
          <button className="list-command-button button-update" onClick={e => onClick("removeFav")}>
            <span className="ListCommandButtons-button-span">Remove From Favorite</span>
          </button>
        </div>
        <div className="List-command-button-container">
          <button className="list-command-button button-update" onClick={e => onClick("setMinerType")}>
            <span className="ListCommandButtons-button-span">Set Miner Type</span>
          </button>
        </div>
        <div className="List-command-button-container">
          <button className="list-command-button button-update" onClick={e => onClick("alterStatus")}>
            <span className="ListCommandButtons-button-span">Alter Miner Status</span>
          </button>
        </div>
        <div className="List-command-button-container">
          <button className="list-command-button button-clear-location" onClick={e => onClick("clearLocations")}>
            <span className="ListCommandButtons-button-span">Clear Miners Location</span>
          </button>
        </div>
        <div className="List-command-button-container">
          <button className="list-command-button button-clear-location" onClick={e => onClick("delete", socket)}>
            <span className="ListCommandButtons-button-span">Toggle Delete</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ListCommandButtons

import React from 'react'
import './style.css'
import React_Select, { components, IndicatorsContainerProps } from 'react-select'
import {  blueGray, lightGray, darkBoarder } from '../../helper/colors.js'
import { Range } from 'react-range';


export const colourStyles = {
    container: styles => ({
      ...styles,
      width: '97.5%'
    }),
    control: styles => ({
      ...styles,
      backgroundColor: blueGray,
      borderColor: darkBoarder,
      color: lightGray,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: lightGray,
        cursor: isDisabled ? 'not-allowed' : 'default'
      }
    }
  };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ display: 'none' }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

const AttributeValues = ({ type, valueList, onInputChange, itemID, attributesValueList, displayOnly }) => {
    switch(type){
        case 'boolean':
        case 'list':
            const listData = valueList.map((item) => ({label: item.Display_Name, value: item.Value}))
            if(displayOnly){
                return(
                    <div>
                        <React_Select
                            options={listData}
                            onChange={e => console.log("this item is not changable")}
                            autoFocus
                            styles={colourStyles}
                            value={valueList.length > 0? ({label: valueList[0].Display_Name, value: valueList[0].Value}): []}
                            components={{IndicatorsContainer}}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: 'black',
                                    neutral0: blueGray,
                                    primary: 'black',
                                    neutral10: 'black', // selected value background
                                    neutral20: lightGray, // arrows
                                    neutral50: lightGray, // Select... color
                                    neutral80: lightGray, // color in selected values
                                }
                            })}
                        />
                    </div>
                )
            }
            return(
                <div>
                    <React_Select
                        options={listData}
                        onChange={e => onInputChange(e, "valueList", itemID)}
                        autoFocus
                        styles={colourStyles}
                        components={{IndicatorsContainer}}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'black',
                                neutral0: blueGray,
                                primary: 'black',
                                neutral10: 'black', // selected value background
                                neutral20: lightGray, // arrows
                                neutral50: lightGray, // Select... color
                                neutral80: lightGray, // color in selected values
                            }
                        })}
                    />
                </div>
            )
        case 'range':
            if(displayOnly){
                return(
                    <div>
                        <span>{valueList[0].Value}</span>
                    </div>
                )
            }
            const split = valueList[0].Value.split('/')
            const rangeSplit = split[0].split('-')
            let value = attributesValueList.filter((item) => item.ID === itemID).map((item) => item.Value)
            let min = ''
            let max = ''
            let step = split[1]
            if( step == '1' || step == '10'){
                min = parseInt(rangeSplit[0])
                max = parseInt(rangeSplit[1])
                step = parseInt(step)
            }
            else{
                min = parseFloat(rangeSplit[0])
                max = parseFloat(rangeSplit[1])
                step = parseFloat(step)
            }
            return(
                <div>
                    {step == 1 || step == 10? (
                        <span>{`${value[0]} ${valueList[0]['Display_Name']} `}</span>
                    ):(
                        <span>{`${value[0].toFixed(1)} ${valueList[0]['Display_Name']} `}</span>
                    )}
                    <Range
                        step={step}
                        min={min}
                        max={max}
                        values={value}
                        onChange={(values) => onInputChange(values, "valueRange", itemID)}
                        renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                            ...props.style,
                            height: '6px',
                            width: '100%',
                            backgroundColor: '#222d32'
                            }}
                        >
                            {children}
                        </div>
                        )}
                        renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                            ...props.style,
                            height: '15px',
                            width: '15px',
                            backgroundColor: lightGray
                            }}
                        />
                        )}
                    />
                </div>
            )
    }
}
const TuningAttributesTable = ({ attributesValueList, pickAttributes, onInputChange, displayOnly=false }) => {
    return(
            <table className="tuning-attributes-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Description</th>
                        <th>Main Group</th>
                        <th>Sub Group</th>
                        <th>Firmware Capabilities</th>
                        <th>Values</th>
                    </tr>
                </thead>
                <tbody>
                {pickAttributes.map((item) => (
                    item.Picked || displayOnly?(
                        <tr className="tuning-attributes-picked">
                            <td>
                                {item.Picked?(
                                    <input type="checkbox" id="0" name="select" onChange={e => onInputChange(item, "attributes")} checked/>
                                ):(
                                    <input type="checkbox" id="0" name="select" onChange={e => onInputChange(item, "attributes")}/>
                                )}
                            </td>
                            <td>{item.Display_Name}</td>
                            <td>{item.Main_Group}</td>
                            <td>{item.Sub_Group}</td>
                            <td>
                                <React_Select
                                    options={item.Firmwares.map((i)=> ({label: `${i.Firmware_Vendor}-${i.Firmware_Version}`, value: `${i.Firmware_Vendor}-${i.Firmware_Version}`}))}
                                    onChange={e => console.log("This item is not changable")}
                                    value={item.Firmwares.length > 0? [{label: `${item.Firmwares[0].Firmware_Vendor}-${item.Firmwares[0].Firmware_Version}`, value: `${item.Firmwares[0].Firmware_Vendor}-${item.Firmwares[0].Firmware_Version}`}]: []}
                                    autoFocus
                                    styles={colourStyles}
                                    components={{IndicatorsContainer}}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'black',
                                            neutral0: blueGray,
                                            primary: 'black',
                                            neutral10: 'black', // selected value background
                                            neutral20: lightGray, // arrows
                                            neutral50: lightGray, // Select... color
                                            neutral80: lightGray, // color in selected values
                                        }
                                    })}
                                />
                            </td>
                            <td>
                                <AttributeValues
                                    type={item.Value_Type}
                                    valueList={item.Value_List}
                                    onInputChange={onInputChange}
                                    itemID={item.ID}
                                    attributesValueList={attributesValueList}
                                    displayOnly={displayOnly}
                                />
                            </td>
                        </tr>
                    ):(
                        <tr className="tuning-attributes-unpicked">
                            <td className="wide"><input type="checkbox" id="0" name="select" onChange={e => onInputChange(item, "attributes")}/></td>
                            <td>{item.Display_Name}</td>
                            <td>{item.Main_Group}</td>
                            <td>{item.Sub_Group}</td>
                            <td>list</td>
                            <td>{item.Value_Type}</td>
                        </tr>
                    )
                ))}
                </tbody>
            </table>
    )
}

export default TuningAttributesTable
import React from 'react'
import './style.css'
import "bootstrap/dist/css/bootstrap.min.css";
import Pager from 'react-pager';
import { userGroupSwitch, functionSwitch, buttonSwitch } from './functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
//<i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/

const InfoTables = ({ data, columns, onItemClick, itemClickInfo, pager, totalPages, currentPage, onPageChange, sorting, sortedBy, acending, onSort, buttons = [], totalData}) => {
  return(
    <div>
      <table className={`list-table`}>
        <thead>
          <tr>
            <th scope="col">#</th>
            {columns.map((item, key) => (
              sorting && item.field === sortedBy?(
                acending?(
                  <th onClick={e => onSort(item.field)} className="list-table-sorting" key={key}><FontAwesomeIcon icon={faSortDown} size="lg" /><span>{item.value}</span></th>
                ):(
                  <th onClick={e => onSort(item.field)} className="list-table-sorting" key={key}><FontAwesomeIcon icon={faSortUp} size="lg"/><span>{item.value}</span></th>
                )):(
                sorting?(
                  <th onClick={e => onSort(item.field)} className="list-table-sorting" key={key}><FontAwesomeIcon icon={faSort} size="lg"/><span>{item.value}</span></th>
                ):(
                  <th key={key}><span>{item.value}</span></th>
                ))
            ))}
            {buttons.length !== 0?(
              <th scope="col"/>
            ):(<></>)}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            onItemClick?(
              <tr className="hover" key={i}>
                <td  scope="row" onClick={e => onItemClick(itemClickInfo.name, item[itemClickInfo.property])}>{i+1}</td>
                {columns.map((column, y) => (
                    column.field == 'Level'?(
                      <td onClick={e => onItemClick(itemClickInfo.name, item[itemClickInfo.property])} key={y}>{userGroupSwitch(item.Level)}</td>
                    ):(
                      column.hasOwnProperty("func")?(
                        <td onClick={e => onItemClick(itemClickInfo.name, item[itemClickInfo.property])} key={y}>{functionSwitch(item[column.field], column.func)}</td>
                      ):(
                        <td style={item[column.field] !== null && item[column.field] !== undefined?item[column.field].length > 100?({whiteSpace: 'normal'}):({whiteSpace: 'nowrap'}): ({whiteSpace: 'nowrap'})} onClick={e => onItemClick(itemClickInfo.name, item[itemClickInfo.property])} key={y}>{item[column.field] !== null && item[column.field] !== undefined? item[column.field]: ''}</td>
                      )
                    )))}
                {buttons.length !== 0?(
                  <td className="non-button-cursor text-right">{buttons.map((button, key) => (
                    buttonSwitch(item, button, i, key)
                  ))}</td>
                ):(<></>)}
              </tr>
            ):(
              <tr key={i}>
                <td scope="row">{i+1}</td>
                {columns.map((column, y) => (
                    column.field == 'Level'?(
                      <td key={y}>{userGroupSwitch(item.Level)}</td>
                    ):(
                      column.hasOwnProperty("func")?(
                        <td key={y}>{functionSwitch(item[column.field], column.func)}</td>
                      ):(
                        <td style={item[column.field] !== null && item[column.field] !== undefined ?item[column.field].length > 100?({whiteSpace: 'normal'}):({whiteSpace: 'nowrap'}): ({whiteSpace: 'nowrap'})} key={y}>{item[column.field] !== null && item[column.field] !== undefined? item[column.field]: ''}</td>
                      )
                    )))}
                {buttons.length !== 0?(
                  <td className="text-right">{buttons.map((button, key) => (
                    buttonSwitch(item, button, i, key)
                  ))}</td>
                ):(<></>)}
              </tr>
            )
          ))}
        </tbody>
      </table>
      {pager?(
        <div className={`${totalData? 'pager-div-between': 'pager-div-center'}`}>
          {totalData?(
            <div className='pager-total-data-div'>
              <span className='pager-total-data'>Total: {totalData}</span>
            </div>
          ):(<></>)}
          <Pager
              total={totalPages}
              current={currentPage}
              visiblePages={3}
              onPageChanged={onPageChange}
              totalData={totalData}
            />
        </div>
      ):(<></>)}
    </div>
  )
}

export default InfoTables

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { configureView, configureViewTier0, configureViewTier1 } from '../../helper/tabs'
import { getProfilesAction } from '../../redux/actions/getProfilesAction'
import { removeFromProfileAction } from '../../redux/actions/removeFromProfileAction'
import { addToProfileAction } from '../../redux/actions/addToProfileAction'
import { postProfileAction } from '../../redux/actions/postProfileAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'
import { deleteProfileAction } from '../../redux/actions/deleteProfileAction'
import { getPoolsAction } from '../../redux/actions/getPoolsAction';
import './style.css'

class ProfilesView extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      profileName: '',
      prefix: '',
      postfix: '',
      pools: [],
      scanners: [],
      minerTypes: [],
      modalOpen: false,
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
    }
    this.props.getProfilesAction(this.props.token, this.props.match.params.name)
    this.props.getPoolsAction(this.props.token, this.props.match.params.name)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name)
      this.componentDidMount()
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onDelete = (id) => {
    window.confirm("are you sure you want to delete this profile ?") &&
    this.props.deleteProfileAction(this.props.token, this.props.match.params.name, id)
  }
  onAdd = () => {
    const { profileName, prefix, postfix, pools, scanners, minerTypes } = this.state;
    if(profileName === ''){
      alert("profile name must can't be empty")
      return
    }
    if(prefix.length > 5){
      alert("prefix cannot be larger then 5 characters")
      return
    }
    this.props.postProfileAction(this.props.token, this.props.match.params.name, {
      name: profileName, prefix, postfix, pools, scanners, minerTypes})
    this.setState({
      profileName: '',
      prefix: '',
      postfix: '',
      pools: [],
      scanners: [],
      minerTypes: [],
      modalOpen: false
    })
  }
  render(){
    const { userData } = this.props
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    const columns = [
      {field: 'Name', value: 'Name'},
      {field: 'Pools', value: 'Pools', func: 'length'},
      {field: 'Prefix', value: 'Prefix'},
      {field: 'Postfix', value: 'Postfix'},
      {field: 'Scanners', value: 'Scanners', func: 'length'},
      {field: 'MinerTypes', value: 'Miner Types', func: 'length'}]

    const buttons = userData.level >=2 ?([{class: 'ViewBody-button-delete', onClick: this.onDelete, type: 'Delete', extractKey: 'ID'}]):([])
    const addProfileFields = [
      {Name: 'Name', PropertyName: 'profileName', Value: this.state.profileName, Type: 'text', Options: []},
      {Name: 'Prefix', PropertyName: 'prefix', Value: this.state.prefix, Type: 'text', Options: []},
      {Name: 'Postfix', PropertyName: 'postfix', Value: this.state.postfix, Type: 'select', Options: [
        {text: '---------', value: ''},
        {text: 'last two octets', value: 'last two octets'},
        {text: 'last three octets', value: 'last three octets'},
        {text: 'hostname/location', value: 'hostname'}
      ]}
    ]
    return(
      <div className="ProfilesView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Manage account"
          subtitle="Manage buildings, pods, pools & more"
          tabs={userData.level === 0? databaseTier == 0? configureViewTier0: databaseTier == 1? configureViewTier1: configureView: configureView}
          active="Profiles"
          onClick={this.onClick}
        />
        <div className="ConfigureView-content">
          <ViewBody
            data={this.props.poolStore.profiles}
            columns={columns}
            buttons={buttons}
            list
            createtext="New profile"
            onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
            itemClickInfo={{name: 'profiles', property: 'ID'}}
            onCreate={e => this.setState({modalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
        <FormfieldSection
          title="Create new profile"
          fields={addProfileFields}
          addButton={true}
          buttonText="Add"
          onAddClick={this.onAdd}
          onInputChange={e => onInputChange(e, this)}
          action="add_profile"
        />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userData: props.userReducer.userData,
  poolStore: props.poolReducer,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  getProfilesAction,
  removeFromProfileAction,
  addToProfileAction,
  postProfileAction,
  deleteProfileAction,
  getPoolsAction,
  updateClientAction
})(ProfilesView)

import {
  getDatabasesAction,
  updateClientActionConstant,
  clearCustomersConstant,
  changePageSize24HoursPlus,
  onPageClick24HoursPlus,
  onPageClick24Hours,
  changePageSize24Hours,
  onPageClickCustomerStatus,
  changePageSizeCustomerStatus,
  filterCustomers,
  postCommentConstant,
  seenMinerConstant,
  clearDashInfo,
  toggleSeenMinersConstant,
  clearCurrentClientConstant,
  updateSideBarConstant,
  toggleRefreshDataConstant
} from '../constants/actions';
import { setupPage } from '../helperFunctions'
const defaultState = {
  ok: false,
  api_version: 1.1,
  msg: '',
  clients: [],
  currentClient: '',
  currentRegion: '',
  sidebaropen: true,
  refreshData: true,
  seenMiners: [],
  customerOverviewInfo: {
    data: [],
    max_page: 1,
    current_page: 1,
    page_size: 25,
    dataSet: [],
  },
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case toggleRefreshDataConstant:
      let toggleRefreshDataState = JSON.parse(JSON.stringify(state))
      toggleRefreshDataState.refreshData = !toggleRefreshDataState.refreshData
      return toggleRefreshDataState
    case updateSideBarConstant:
      let updateSideBarState = JSON.parse(JSON.stringify(state))
      updateSideBarState.sidebaropen = action.payload
      return updateSideBarState
    case clearCurrentClientConstant:
      let clearCurrentClientState = JSON.parse(JSON.stringify(state))
      clearCurrentClientState.currentClient = ''
      return clearCurrentClientState
    case getDatabasesAction:
      if(state.currentClient !== ''){
        return state
      }
      let customerOverview = []
      let newData = action.payload.data.clients.map((item) => {
        let level = -1;
        item.privileges.map((innerItem) => {
          if(innerItem === "SELECT" && level < 0){
            level = 0;
          }
          if(innerItem === "INSERT" && level < 1){
            level = 1;
          }
          if(innerItem == "DELETE" && level < 2){
            level = 2;
          }
        });
        customerOverview.push({
          onlineMiners: item.onlineMiners,
          offlineMiners: item.offlineMiners,
          zeroHash: item.zeroHash,
          customer: item.client,
          totalHashrate: item.totalHashrate,
          performance: item.performance,
          estPower: item.estPower,
          regions: item.regionAccess
        })
        return{
          client: item.client,
          privileges: item.privileges,
          level: level,
          totalMiners: item.totalMiners,
          offlineMiners: item.offlineMiners
        }
      })

      customerOverview = setupPage(customerOverview, state.customerOverviewInfo.page_size, state.customerOverviewInfo.current_page)
        const newState = {
          ok: action.payload.ok,
          msg: action.payload.msg,
          clients: newData,
          customerOverviewInfo: customerOverview,
          currentClient: '',
          seenMiners: action.payload.data.seenMiners,
          sidebaropen: state.sidebaropen,
          refreshData: state.refreshData
        }
        return newState;
    case updateClientActionConstant:
      let tmpState = JSON.parse(JSON.stringify(state));
      tmpState.currentClient = action.payload.currentClient
      tmpState.currentRegion = action.payload.currentRegion
      return tmpState
    case clearCustomersConstant:
      return {
        ok: false,
        clients:[],
        currentClient: '',
        msg: '',
        sidebaropen: state.sidebaropen,
        refreshData: state.refreshData
      }
    case onPageClickCustomerStatus:
      let result5 = setupPage(state.customerOverviewInfo.dataSet, state.customerOverviewInfo.page_size, parseInt(action.payload))
      return {
        ok: state.ok,
        msg: state.msg,
        clients: state.clients,
        currentClient: state.currentClient,
        customerOverviewInfo: result5,
        offlineMiners24Hours: state.offlineMiners24Hours,
        offlineMiners24HoursPlus: state.offlineMiners24HoursPlus,
        seenMiners: state.seenMiners,
        sidebaropen: state.sidebaropen,
        refreshData: state.refreshData
      }
    case changePageSizeCustomerStatus:
      let result6 = setupPage(state.customerOverviewInfo.dataSet, parseInt(action.payload), 1)
      return {
        ok: state.ok,
        msg: state.msg,
        clients: state.clients,
        currentClient: state.currentClient,
        customerOverviewInfo: result6,
        offlineMiners24Hours: state.offlineMiners24Hours,
        offlineMiners24HoursPlus: state.offlineMiners24HoursPlus,
        seenMiners: state.seenMiners,
        sidebaropen: state.sidebaropen,
        refreshData: state.refreshData
      }
    case filterCustomers:
      if(action.payload.type == 'filterOver24'){
        const dataSet = JSON.parse(JSON.stringify(state.offlineMiners24HoursPlus.dataSet))
        let result7 = ''
        if(action.payload.customer == 'All'){
          result7 = setupPage(state.offlineMiners24HoursPlus.dataSet.filter((item) => !state.seenMiners.some((item2) => item2.MAC_Address == item.MAC_Address && item2.Customer == item.customer)), state.offlineMiners24HoursPlus.page_size, 1)
        }
        else if(action.payload.customer == 'Seen'){
          result7 = setupPage(state.offlineMiners24HoursPlus.dataSet.filter((item) => state.seenMiners.some((item2) =>item2.MAC_Address == item.MAC_Address && item2.Customer == item.customer)),state.offlineMiners24HoursPlus.page_size, 1)
        }
        else{
          result7 = setupPage(state.offlineMiners24HoursPlus.dataSet.filter((item) => !state.seenMiners.some((item2) => item2.MAC_Address == item.MAC_Address && item2.Customer == item.customer) && item.customer == action.payload.customer), state.offlineMiners24HoursPlus.page_size, 1);
        }
        result7.filterBy = action.payload.customer;
        result7.dataSet = dataSet
        return{
          ok: state.ok,
          msg: state.msg,
          clients: state.clients,
          currentClient: state.currentClient,
          customerOverviewInfo: state.customerOverviewInfo,
          offlineMiners24Hours: state.offlineMiners24Hours,
          offlineMiners24HoursPlus: result7,
          seenMiners: state.seenMiners,
          sidebaropen: state.sidebaropen,
          refreshData: state.refreshData
        }
      }
      if(action.payload.type == 'filterUnder24'){
        const dataSet2 = JSON.parse(JSON.stringify(state.offlineMiners24Hours.dataSet))
        let result8 = ''
        if(action.payload.customer == 'All'){
          result8 = setupPage(state.offlineMiners24Hours.dataSet.filter((item) => !state.seenMiners.some((item2) =>item2.MAC_Address == item.MAC_Address && item2.Customer == item.customer)), state.offlineMiners24Hours.page_size, 1)
        }
        else if(action.payload.customer == 'Seen'){
          result8 = setupPage(state.offlineMiners24Hours.dataSet.filter((item) => state.seenMiners.some((item2) =>item2.MAC_Address == item.MAC_Address && item2.Customer == item.customer)),state.offlineMiners24Hours.page_size, 1)
        }
        else{
          result8 = setupPage(state.offlineMiners24Hours.dataSet.filter((item) => !state.seenMiners.some((item2) => item2.MAC_Address == item.MAC_Address && item2.Customer == item.customer) && item.customer == action.payload.customer), state.offlineMiners24Hours.page_size, 1);
        }
        result8.filterBy = action.payload.customer;
        result8.dataSet = dataSet2
        return{
          ok: state.ok,
          msg: state.msg,
          clients: state.clients,
          currentClient: state.currentClient,
          customerOverviewInfo: state.customerOverviewInfo,
          offlineMiners24Hours: result8,
          offlineMiners24HoursPlus: state.offlineMiners24HoursPlus,
          seenMiners: state.seenMiners,
          sidebaropen: state.sidebaropen,
          refreshData: state.refreshData
        }
      }
    case clearDashInfo:
      return{
        ok: false,
        msg: state.msg,
        clients: state.clients,
        currentClient: state.currentClient,
        seenMiners: [],
        sidebaropen: state.sidebaropen,
        refreshData: state.refreshData,
        customerOverviewInfo: {
          data: [],
          max_page: 1,
          current_page: 1,
          page_size: 25,
          dataSet: [],
        },
        offlineMiners24Hours: {
          data: [],
          max_page: 1,
          current_page: 1,
          page_size: 25,
          dataSet: [],
          filterBy: 'All',
          toggleSeen: false
        },
        offlineMiners24HoursPlus: {
          data: [],
          max_page: 1,
          current_page: 1,
          page_size: 25,
          dataSet: [],
          filterBy: 'All',
          toggleSeen: false
        }
      }
    default:
      return state;
  }
}

import React from 'react'
import { Provider } from 'react-redux';
import { store } from './redux/store'
import history from './history'
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist'
import App from './App';
import LoadingScreen from './Components/LoadingScreen'

export default class AppProvider extends React.Component {
  constructor() {
    super()
    this.state = { rehydrated: false }
  }
  componentDidMount(){
    persistStore(store, {}, () => {
      this.setState({ rehydrated: true })
    })
  }
  render() {
    if(!this.state.rehydrated){
      return (
        <LoadingScreen/>
      )
    }
    return (
      <Provider store={store}>
        <App history={createBrowserHistory()}>
        </App>
      </Provider>
    )
  }
}

/*
<div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100%'}}>
    <span style={{fontSize: '20px'}}>Loading...</span>
</div>
*/

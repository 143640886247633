import React from 'react'
import { api_down, idle, online, offline } from '../../helper/colors'
export const coloring_switch = (value, key) => {
  switch (value) {
    case 'API_DOWN':
      return <td style={{color: api_down}} key={key}>{format_value(value)}</td>
    case "Idle":
      return <td style={{color: idle}} key={key}>{format_value(value)}</td>
    case 'Online':
      return <td style={{color: online}} key={key}>{format_value(value)}</td>
    case 'Restarting':
    case 'Mode_Change':
    case 'Config_Change':
    case 'Offline':
      return <td style={{color: offline}} key={key}>{format_value(value)}</td>
    default:
      return <td key={key}>{format_value(value)}</td>
  }
}
export const format_value = (value) => {
  if(typeof value === 'number'){
    return value.toLocaleString()
  }
  return value
}

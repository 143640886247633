import { getFlagSettingsByMinerTypeIDConstant  } from '../constants/actions'
import service from '../services/flagService'

export const getFlagSettingsByMinerTypeIDAction = (id, token, customer) => {
  return async dispatch => {
    const result = await service.getFlagSettingsByMinerTypeID(id, token, customer)
    dispatch(success(result))
  }
}


const success = (result) => ({
  type: getFlagSettingsByMinerTypeIDConstant,
  payload: result
})

import React, { Component } from "react";


const Select = ({ name, propertyName, value, options, onInputChange }) => {
  return (
    <div className="formfieldSection_form-group">
      <label>{name}</label>
        <select className="formfieldSection_form-group_item" name={`${propertyName}`} onChange={e => onInputChange(e)}>
         {options.map((item) => (
           item.value === value?(
             <option value={item.value} selected>{item.text}</option>
           ):(
             <option value={item.value}>{item.text}</option>
           )
         ))}
        </select>
    </div>
  )
}

export default Select;

import { filterAlertConstant } from '../constants/actions'
import service from '../services/alertService'
import { setErrorMessageAction } from './setErrorMessageAction'
export const filterAlertAction = (token, customer, alertID) => {
  return async(dispatch) => {
    const result = await service.getAlertByID(token, customer, alertID)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch({
      type: filterAlertConstant,
      payload: result
    })
  }
}

import React from 'react'
import './style.css'
import ChangePasswordComponent from '../../Components/ChangePassword';
import { setErrorMessageAction } from '../../redux/actions/setErrorMessageAction';
import { setSuccessMessageAction } from '../../redux/actions/setSuccessMessageAction';
import { changePasswordAction } from '../../redux/actions/changePasswordAction'
import { loginAction } from '../../redux/actions/loginAction';
import { connect } from 'react-redux';
import { SocketContext } from '../../context/socket'
const jwt = require('jwt-simple');

class ChangePasswordView extends React.Component {
  static contextType = SocketContext
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      oldpassword: '',
      confirmnewpassword: '',
      newpassword: '',
      token: ''
    }
    this.onInput = this.onInput.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  componentDidMount() {
    try {
      const { result } = this.props.token.userReducer;
      const temp = jwt.decode(result, process.env.REACT_APP_DECRYPT_KEY);
      this.setState({ username: temp.user });
    }
    catch (e) {
      console.log(e);
    }
  }
  //Runs everytime there is a change in input values
  onInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  //Runs when the form is submitted and tries to change password.
  onFormSubmit = async (e) => {
    const { username, oldpassword, confirmnewpassword, newpassword } = this.state;
    const { result } = this.props.token.userReducer;
    const temp = jwt.decode(result, process.env.REACT_APP_DECRYPT_KEY);
    if (confirmnewpassword === newpassword && oldpassword === temp.password) {
      this.props.changePasswordAction(username, newpassword, result);
      const socket = this.context
      if(socket !== null){
        socket.emit("updatedPassword", username)
      }
      //await this.props.loginAction(username, newpassword);
      this.state.oldpassword = '';
      this.state.confirmnewpassword = '';
      this.state.newpassword = '';
    }
    else {
      this.props.setErrorMessageAction("old password not correct or new password does not match.")
    }
  }
  render() {
    const { username, oldpassword, newpassword, confirmnewpassword } = this.state;
    return (
      <div className="password-view-container">
        <div className="changePasswordContainer">
          <ChangePasswordComponent
            username={username}
            oldpassword={oldpassword}
            newpassword={newpassword}
            confirmnewpassword={confirmnewpassword}
            state={this.state}
            onInput={this.onInput}
            onFormSubmit={this.onFormSubmit} />
        </div>
      </div>
    )
  }
}
const mapStateTopProps = (props) => ({
  token: props
});

export default connect(mapStateTopProps, { changePasswordAction, loginAction, setErrorMessageAction, setSuccessMessageAction })(ChangePasswordView);

import React from 'react';
import './styles.css';

const  TabHeader = ({ active, onClick, title, subtitle, logo, tabs }) => {
  return(
    <div className="ViewTabHeader-container">
      <div className="ViewTabHeader-page-container">
        <div className="ViewTabHeader-page-header">
          <div className="ViewTabHeader-page-header--inner">
            <span className="ViewTabHeader-page-header--logo">
              <div className="css-1cvxpvr">
                {logo}
              </div>
            </span>
            <div>
              <h1 className="ViewTabHeader-page-header-title">{title}</h1>
              <div className="ViewTabHeader-page-header-sub-title">
                {subtitle}
              </div>
            </div>
          </div>
          <nav className="ViewTabHeader_nav">
            <div className="ViewTabHeader-page-tabs">
              <ul className="ViewTabHeader-page-tabs-ul">
                {tabs.map((item, key) => (
                  <li className={active==item.name?"css-ui3sz4-activeTabStyle":"css-zwut70"} aria-label="Tab Users" onClick={ e => onClick(item.name)} key={key}>
                    <div className="css-1cvxpvr">
                      <span data-tooltip={item.name} data-tooltip-position="bottom">{item.avatar}</span>
                    </div>
                    <span className="tab_header_tabSpan">{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default TabHeader

import React from 'react'
import './style.css'
import { connect } from 'react-redux'



const ResponsePanel = ({ store, closePanel }) => {
    return(
      <div className="response-pannel-container" id="response-panel" style={{display: `${store.open?'block': 'none'}`}}>
        <span className="span-title">Command result</span>
        <div className="progress">
          <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width: `${store.success === 0? '0': store.total === 0? '0': `${(store.success/store.total)*100}`}%`, color: 'black'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">{store.success}</div>
          <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{width: `${store.failure === 0? '0': store.total === 0? '0': `${(store.failure/store.total)*100}`}%`, color: 'black'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">{store.failure}</div>
          <div className="progress-bar progress-bar-striped bg-info" role="progressbar" style={{width: `${store.notFound === 0? '0': store.total === 0? '0': `${(store.notFound/store.total)*100}`}%`, color: 'black'}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">{store.notFound}</div>
          <div className="progress-bar progress-bar-striped bg-info" role="progressbar" style={{width: `${store.blocked === 0? '0': store.total === 0? '0': `${(store.blocked/store.total)*100}`}%`, color: 'black'}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">{store.blocked}</div>
          <div className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{width: `${store.wrong_manufactuer === 0? '0': store.total === 0? '0': `${(store.wrong_manufactuer/store.total)*100}`}%`, color: 'black'}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">{store.wrong_manufactuer}</div>
        </div>
        <div className="response-text-container">
          <span className="span-success">Success: {store.success}</span>
          <span className="span-failure">Failure: {store.failure}</span>
          <span className="span-warning">Wrong Manufacturer: {store.wrong_manufactuer}</span>
          <span className="span-info">Not Found: {store.notFound}</span>
          <span className="span-info">Blocked: {store.blocked}</span>
          <span className="span-pending">Pending: {store.pending}</span>
          <span className="span-total">Total: {store.total}</span>
        </div>
        <span className="span-exit" onClick={() => closePanel()}>
          <i className="fa fa-window-close" aria-hidden="true" />
        </span>
      </div>
  )
}


const mapStateToProps = (props) => ({
  store: props.responseReducer
})

export default connect(mapStateToProps)(ResponsePanel)

import {
  getMinerFlagsOverviewConstant,
  getMinerFlagCheckOptionConstant,
  getMinerFlagTypeOptionConstant,
  getMinerFlagsByMACConstant,
  getMinerFlagRecoveryLogByFlagIDConstant,
  getMinerFlagActivityLogByFlagIDConstant,
  postSingleMinerFlagCheckConstant,
  getMinerFlagByIDConstant,
  getFlagSettingsByMinerTypeIDConstant,
  updateFlagSettingsByMinerTypeIDConstant,
  updateMinerFlagTypeSeverityConstant,
  getMinerFlagSeverityConstant,
  getMinerFlagTypesConstant,
  getFlagTypeInfoByIDConstant,
  getSubnetsConnectionsByMinerFlagIDConstant,
  addSubnetFlagTypeConnectionConstant,
  deleteSubnetFlagTypeConnectionConstant,
  postCustomMinerFlagsConstant,
  getFlagCommentsByIdConstant,
  postFlagCommentConstant,
  getFlagCheckoutDataConstant
} from '../constants/actions'



const defaultState = {
    ok: false,
    msg: '',
    api_version: '',
    miner_flag_checkout_data: [],
    miner_flag_check_options: [],
    miner_flag_type_options: [],
    flags: [],
    flags_recovery_log: [],
    flags_activity_log: [],
    flag_subnet_connections: [],
    flag_comments: [],
    flag_settings: [],
    flag_types: [],
    flag_severity: [],
    detected_24: 0,
    pending_24: 0,
    checked_24: 0,
    resolved_24: 0,
    detected_total: 0,
    pending_total: 0,
    checked_total: 0,
    resolved_total: 0,
    flag_24_hours: {
      detected: {
        total: 0,
        performance: 0,
        heat: 0,
        offline: 0,
        maintenance: 0
      },
      pending: {
        total: 0,
        performance: 0,
        heat: 0,
        offline: 0,
        maintenance: 0,
        custom: 0
      },
      checked: {
        total: 0,
        performance: 0,
        heat: 0,
        offline: 0,
        maintenance: 0,
        custom: 0
      },
      resolved: {
        total: 0,
        performance: 0,
        heat: 0,
        offline: 0,
        maintenance: 0
      }
    },
    flag_total: {
      detected: {
        total: 0,
        performance: 0,
        heat: 0,
        offline: 0,
        maintenance: 0
      },
      pending: {
        total: 0,
        performance: 0,
        heat: 0,
        offline: 0,
        maintenance: 0,
        custom: 0
      },
      checked: {
        total: 0,
        performance: 0,
        heat: 0,
        offline: 0,
        maintenance: 0,
        custom: 0
      },
      resolved: {
        total: 0,
        performance: 0,
        heat: 0,
        offline: 0,
        maintenance: 0
      }
    }
}

/*
  getMinerFlagsOverviewConstant now returns:
  Detected: { Total, Performance, Heat, Offline, Maintenance},
  Pending:  { Total, Performance, Heat, Offline, Maintenance},
  Checked:  { Total, Performance, Heat, Offline, Maintenance, Custom},
  Resolved: { Total, Performance, Heat, Offline, Maintenance},
  Custom:   { Total, Custom, Checked}

  Need to create object in the reducer for all these statuses with all values as 0

*/
export default(state=defaultState, action) => {
  switch (action.type) {
    case getFlagCheckoutDataConstant:
      if(action.payload.ok){
        return {
          ...state,
          miner_flag_checkout_data: action.payload.data
        }
      }
      return state
    case postFlagCommentConstant:
    case getFlagCommentsByIdConstant:
      if(action.payload.ok){
        return {
          ...state,
          flag_comments: action.payload.data
        }
      }
      return state
    case getSubnetsConnectionsByMinerFlagIDConstant:
    case addSubnetFlagTypeConnectionConstant:
    case deleteSubnetFlagTypeConnectionConstant:
      if(action.payload.ok){
        return {
          ...state,
          flag_subnet_connections: action.payload.data
        }
      }
      return state
    case getMinerFlagSeverityConstant:
      if(action.payload.ok){
        return {
          ...state,
          flag_severity: action.payload.data
        }
      }
      return state
    case getFlagTypeInfoByIDConstant:
    case updateMinerFlagTypeSeverityConstant:
    case getMinerFlagTypesConstant:
      if(action.payload.ok){
        return {
          ...state,
          flag_types: action.payload.data
        }
      }
      return state
    case getFlagSettingsByMinerTypeIDConstant:
    case updateFlagSettingsByMinerTypeIDConstant:
      if(action.payload.ok){
        return {
          ...state,
          flag_settings: action.payload.data
        }
      }
      return state
    case getMinerFlagCheckOptionConstant:
      if(action.payload.ok){
          return {
           ...state,
           miner_flag_check_options: action.payload.data
         }
      }
      return state
    case getMinerFlagTypeOptionConstant:
        if(action.payload.ok){
            return {
             ...state,
             miner_flag_type_options: action.payload.data
           }
        }
        return state
    case postCustomMinerFlagsConstant:
    case getMinerFlagByIDConstant:
    case getMinerFlagsByMACConstant:
      if(action.payload.ok){
        return {
          ...state,
          flags: action.payload.data
        }
      }
      return state
    case getMinerFlagRecoveryLogByFlagIDConstant:
      if(action.payload.ok){
        return {
          ...state,
          flags_recovery_log: action.payload.data
        }
      }
      return state
    case postSingleMinerFlagCheckConstant:
      if(action.payload.ok){
        return {
          ...state,
          flags: action.payload.data.flags,
          flags_activity_log: action.payload.data.activityLog
        }
      }
      return state
    case getMinerFlagActivityLogByFlagIDConstant:
      if(action.payload.ok){
        return {
          ...state,
          flags_activity_log: action.payload.data
        }
      }
      return state
    case getMinerFlagsOverviewConstant:
      if(action.payload.ok){
        const data = action.payload.data
        if(action.payload.option == '24hour'){
          return {
            ...state,
            flag_24_hours: data
          }
        }
        if(action.payload.option == 'all'){
          return {
            ...state,
            flag_total: data
          }
        }
      }
      return state
    default:
      return state
  }
}

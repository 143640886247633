import { getMinerFlagTypesConstant } from '../constants/actions'
import service from '../services/flagService'



export const getMinerFlagTypesAction = (token, customer, region_id) => {
  return async(dispatch) => {
    const result = await service.getMinerFlagTypes(token, customer, region_id)
    dispatch(success(result))
  }
}



const success = (result) =>({
  type: getMinerFlagTypesConstant,
  payload: result
})

import React from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import './style.css'


const SettingsContainer = ({ logout, onRefreshCheck, refreshData }) => {
  return(
    <div className="settingsContainer-outercontainer">
      <strong>Settings</strong>
      <div className="settingsContainer-innerContainer">
        <div className="settingsContainer-item">
          <span className="adc_header-autoRefresh">Auto Refresh</span>
          <input name="refreshData" onChange={e => onRefreshCheck()} type="checkbox" checked={refreshData ? true: false}/>
        </div>
        <div className="settingsContainer-item" onClick={e => window.open(`https://atnorth.atlassian.net/servicedesk/customer/portal/19`, "_blank", 'noopener')}>
          <span>
            Report problem
          </span>
          <i className="fa fa-bug"/>
        </div>
        <div className="settingsContainer-item">
          <NavLink to="/changepassword">
            <span>Profile</span> <i className="far fa-user"/>
          </NavLink>
        </div>
        <div className="settingsContainer-item" onClick={e => logout()}>
          <span>Sign out</span>
          <i className="fas fa-sign-out-alt" aria-hidden="true"/>
        </div>
      </div>
    </div>
  )
}

export default SettingsContainer

import React from 'react';
import DeviceMappingItem from '../DeviceMappingItem'
import './style.css';

const createOption = (startingNum, size, data = null) => {
  let returnValue = []
  if(data == null){
    for(; startingNum <= size; startingNum++){
      returnValue.push(<option key={startingNum}>{startingNum}</option>)
    }
  }
  else{
    data.map((item,key) => {
      if(item.hasOwnProperty("pod")){
          returnValue.push(<option key={key}>{item.pod.Name}</option>)
      }
      else{
        returnValue.push(<option key={key}>{item.Name}</option>)
      }
      return
    })
  }
  return returnValue;
}

const DeviceMapping = ({ minerNum, shelfNum, startingRack, startingShelf, startingMiner, onSubmit, addInput, items, onConfigureChange, onInputChange, types, onMinerItemInput, onAddInput, houseName, podName, houses, pods, selectedFile, onFileChange, onUpload, removeItem, gapsShelf, gapsShelf_value }) =>{
  return(
    <div className="adc_deviceMapping-outerDiv">
        <div className="adc_deviceMapping-title">
          <h5>Map Miners</h5>
        </div>
        <div className="adc_deviceMapping-configureContainer">
          <div className="adc_deviceMapping-configureExtra">
            <div className="adc_deviceMapping-inputDiv">
              <label>Building Name</label>
              <select id="houseName" name="houseName" defaultValue={houseName} className="adc_deviceMapping-input" onInput={e => onInputChange(e)}>
                {createOption(0,0,houses)}
              </select>
            </div>
            <div className="adc_deviceMapping-inputDiv">
              <label>Pod Name</label>
              <select name="podName" defaultValue={podName} className="adc_deviceMapping-input" onInput={e => onInputChange(e)}>
                {createOption(0,0,pods)}
              </select>
            </div>
            <div className="adc_deviceMapping-inputDiv">
              <label>Upload</label>
              {
                selectedFile == null? (
                  <div className="upload-btn-wrapper">
                    <button className="adc_deviceMapping-upload-button" >Choose</button>
                    <input type="file" onChange={e => onFileChange(e)} name="myfile" />
                  </div>
                ):(
                  <div className="upload-btn-wrapper">
                    <button className="adc_deviceMapping-upload-button" onClick={e => onUpload(e)}>Upload</button>
                  </div>
                )
              }
            </div>
            <div className="adc_deviceMapping-inputDiv" id="custom_shelf-container">
              <label>Custom Shelf</label>
              <input id="custom_shelf" type="number" name="gapsShelf" className="adc_deviceMapping-input-number" value={gapsShelf} onChange={e => onInputChange(e)}/>
            </div>
            {gapsShelf !== 'none' && gapsShelf !== ''?(
              <div className="adc_deviceMapping-inputDiv">
                <label>Value</label>
                <input type="number" id="custom_shelf_value" name="gapsShelf_value" className="adc_deviceMapping-input-number"  value={gapsShelf_value} onChange={e => onInputChange(e)}/>
              </div>
            ):(<></>)}
          </div>
          <div className="adc_deviceMapping-configure">
            <div className="adc_deviceMapping-inputDiv">
              <label>Miner Type</label>
              <select name="minerType" className="adc_deviceMapping-input" onInput={e => onInputChange(e)}>
                <option></option>
                  {types.map((item, key) => (
                      <option key={key}>{item.Name}</option>
                  ))}
              </select>
            </div>
            <div className="adc_deviceMapping-inputDiv">
              <label>Starting rack</label>
              <input type="number" name="startingRack" className="adc_deviceMapping-input-number" value={startingRack} onChange={e => onInputChange(e)}/>
            </div>
            <div className="adc_deviceMapping-inputDiv">
              <label>Starting shelf</label>
              <input type="number" name="startingShelf" className="adc_deviceMapping-input-number" value={startingShelf} onChange={e => onInputChange(e)}/>
            </div>
            <div className="adc_deviceMapping-inputDiv">
              <label>Starting miner</label>
              <input type="number" name="startingMiner" className="adc_deviceMapping-input-number" value={startingMiner} onChange={e => onInputChange(e)}/>
            </div>
            <div className="adc_deviceMapping-inputDiv">
              <label>Shelfs per rack</label>
              <input type="number" name="shelfNum" value={shelfNum} className="adc_deviceMapping-input-number" onChange={e => onInputChange(e)}/>
            </div>
            <div className="adc_deviceMapping-inputDiv">
              <label>Miners per shelf</label>
              <input type="number" name="minerNum" className="adc_deviceMapping-input-number" value={minerNum} onChange={e => onInputChange(e)}/>
            </div>
          </div>
        </div>
        <div className="adc_deviceMapping-itemsContainer">
          <div className="adc_deviceMapping-header">
            <div className="adc_deviceMapping-headerItem">
              <label>Mac</label>
            </div>
            <div className="adc_deviceMapping-headerItem">
              <label>Miner Type</label>
            </div>
            <div className="adc_deviceMapping-headerItem">
              <label>Rack</label>
            </div>
            <div className="adc_deviceMapping-headerItem">
              <label>Shelf</label>
            </div>
            <div className="adc_deviceMapping-headerItem">
              <label>Miner</label>
            </div>
          </div>
          <div className="adc_deviceMapping-DeviceMappingItemContainer">
            {items.map((item, i) =>(
              <DeviceMappingItem
                mac={item.MAC_Address}
                types={types}
                rackNum={item.rackNum}
                shelfNum={item.shelfNum}
                minerNum={item.minerNum}
                onInput={onMinerItemInput}
                inputNumber={i}
                minerType={item.minerType}
                removeItem={removeItem}
                key={i}
              />
            ))}
        </div>
        </div>
        <div className="adc_deviceMapping-buttons">
          <button type="button" className="adc_deviceMapping-button" onClick={e => onAddInput(e)}>
            <i className="fa fa-plus"></i>
          </button>
          <button type="button" className="adc_deviceMapping-button" onClick={e => onSubmit(e)}>
            <span>Submit</span>
          </button>
        </div>
    </div>
  )
}


export default DeviceMapping

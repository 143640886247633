import { getHeatmapMinersConstant, setId_getHeatmapMinersConstant } from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction';
import { setSuccessMessageAction } from './setSuccessMessageAction';
import service from '../services/minerService'

export const getHeatmapMinersAction = (token, client, house, pod, rack, podName, method, region_id) => {
  return async(dispatch) => {
    const actionId = Math.random() * (100000000 - 1) + 1;
    dispatch({type: setId_getHeatmapMinersConstant, payload: actionId})
    const result = await service.getHeatmapMiners(token, client, house, pod, rack, podName, method, region_id)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result, actionId))
  }
}

const success = (result, actionId) => ({
  type: getHeatmapMinersConstant,
  payload: result,
  actionId
})

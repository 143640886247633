import { getPowerCutOrderByOrderIDConstant } from '../constants/actions'
import service from '../services/powerCutOrderService'



export const getPowerCutOrderByOrderIDAction = (token, orderId) => {
  return async(dispatch) => {
    const result = await service.getPowerCutOrderByOrderID(token, orderId)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: getPowerCutOrderByOrderIDConstant,
  payload: result
})

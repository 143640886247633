import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'
import './style.css';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle,
} from 'reactstrap';
import { PieChart } from 'react-chartkick';
import 'chart.js';

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
const PodListItem = ({podId, houseId, podName, clientName, totalMiners, offlineMiners, overHeatMiners, zeroHashMiners, hashrate, favItems, addPodToFav, onMinimize, minimized, element, performance, invalidTypes }) => {
  const linkName = `/client/${clientName}/house/${houseId}/pod/${podId}`
  let fav = false;
  let ID = null;
  if(favItems !== undefined){
    if(favItems.data !== undefined){
      if(favItems.data.pods !== undefined){
        for(var i = 0; i < favItems.data.pods.length;i++){
          if(favItems.data.pods[i].Link === linkName)
          {
            fav = true;
            ID = favItems.data.pods[i].ID;
          }
        }
      }
    }
  }

  let star ='';
  if(fav === true){
    star = 'yellow';
  }
  if(fav === false){
    star = 'black';
  }
  return(
    <>
      {minimized?(
        <div className="rowContainerMinimized">
            <p className="minimize-maxmize" onClick={ e => onMinimize(element)}> {podName}</p>
          { /*
            <i className={`fa fa-plus minimize-maxmize`} onClick={ e => onMinimize(element)}></i>
            <i className="fa fa-plus minimize-maxmize left-255" onClick={ e => onMinimize(element)}></i>
            <Card>
            <NavLink to={{pathname: linkName,  name: podName}}>
              <CardTitle>{podName}</CardTitle>
            </NavLink>
          </Card>*/}
        </div>
      ):(
        <div className="podlist-container">
          <div className="podList-container-information">
          <div className="podName">
            <i className="fa fa-window-minimize minimize-maxmize" onClick={ e => onMinimize(element)}/>
            <NavLink to={linkName}>
              {podName}
            </NavLink>
          </div>
          <div className='pod-card-div'>
          <i className='fa fa-star podListStar' style={{color: star}} onClick={e => addPodToFav(podName, linkName, fav, ID)}></i>
              <Card>
                <CardBody>
                  {invalidTypes == 0?(
                    <CardText>Performance: {`${round(performance)}%`}</CardText>):(
                      <CardText>Performance Unavailable, {invalidTypes} invalid types</CardText>
                    )}
                  <CardText>Online/Total miners: {totalMiners-offlineMiners}/{totalMiners}</CardText>
                  <CardText>hashrate: {hashrate}</CardText>
                    {invalidTypes !== 0 ? (
                      <CardText>Invalid Miner Type: {invalidTypes}</CardText>
                    ): (<></>)}
                </CardBody>
                <CardBody>
                  <PieChart
                  data={[
                    ["Online",(totalMiners-offlineMiners)],
                    ["Offline", offlineMiners],
                    ["OverHeat", overHeatMiners],
                    ["ZeroHash", zeroHashMiners]]}
                    width={'100%'}
                    height={'100%'}
                    legend='left'
                    colors={['green','black', 'orange', 'blue']}/>
                </CardBody>
              </Card>
            </div>
          </div>
          <div>
        </div>
        </div>
      )}
      </>
  )
}

export default PodListItem;

PodListItem.propTypes = {
  podId: PropTypes.number.isRequired,
  houseId: PropTypes.string.isRequired,
  podName: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired
}

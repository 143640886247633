import React from 'react'
import './style.css'

import React_Select from 'react-select'
import { colourStyles } from '../../helper/functions'
import {  blueGray, lightGray, darkBoarder, sleep, idle } from '../../helper/colors.js'

const createOption = (size, type) => {
let returnValue = []
  for(var i = 1; i <= size;i++){
    returnValue.push({label: i, value: i, type})
  }
  return returnValue
}
const renderReactSelect = (options, value, onChange) => (
  <React_Select
    options={options}
    value={{label: value}}
    onChange={e => onChange(e)}
    isSearchable={false}
    styles={colourStyles}
    theme={(theme) => ({
      ...theme,
      borderRadius: 0,
      colors: {
      ...theme.colors,
        primary25: 'black',
        neutral0: blueGray,
        primary: 'black',
        neutral10: 'black', // selected value background
        neutral20: lightGray, // arrows
        neutral50: lightGray, // Select... color
        neutral80: lightGray, // color in selected values
      },
    })}
  />
)
const HeatmapSelection = ({houses, houseSelected, pods, currentPod, heatmapType, onItemSelect, rack, perRow, mappingMethod, userLevel}) => {
  const reactSelectHouses = houses.map((item) => ({label: item.Name, value: {id: item.ID, name: item.Name}, type: 'heatmapHouse'})).concat([{label: 'All', value: 'All', type: 'heatmapHouse'}])
  const reactSelectPods   = pods.map((item) => ({label: item.Name, value: item.ID, type: 'heatmapPod'})).concat([{label: 'All', value: 'All', type: 'heatmapPod'}])
  const reactSelectType = [{label: 'Heat', value: 'Heat', type: 'heatmapType'}, {label: 'Performance', value: 'Performance', type: 'heatmapType'}]
  const reactSelectRack = createOption(34, 'heatmapRack').concat([{label: 'All', value: 'All', type: 'heatmapRack'}])
  const reactSelectPerRow = createOption(4, 'perRow')
  const mappingMethodSelect = [{label: 'default', value: 'default', type: 'mappingMethod'}, {label: 'custom', value: 'custom', type: 'mappingMethod'}]
  return(
    <div className="adc_heatmapSelection-outerDiv">
      <div className="adc_heatmapSelection-itemsOuterDiv">
        <div className="adc_heatmapSelection-itemContainer">
        <label className="">House</label>
          {renderReactSelect(reactSelectHouses, houseSelected, onItemSelect)}
        </div>
        <div className="adc_heatmapSelection-itemContainer">
          <label className="">Pod</label>
          {renderReactSelect(reactSelectPods, currentPod, onItemSelect)}
        </div>
        <div className="adc_heatmapSelection-itemContainer">
          <label>Type</label>
          {renderReactSelect(reactSelectType, heatmapType, onItemSelect)}
        </div>
        <div className="adc_heatmapSelection-itemContainer">
          <label>Rack</label>
          {renderReactSelect(reactSelectRack, rack, onItemSelect)}
        </div>
        <div className="adc_heatmapSelection-itemContainer">
          <label>Heatmaps Per Row</label>
          {renderReactSelect(reactSelectPerRow, perRow, onItemSelect)}
        </div>
        {/*<div className="adc_heatmapSelection-itemContainer">
          <label>Mapping Method</label>
          {renderReactSelect(mappingMethodSelect, mappingMethod, onItemSelect)}
        </div>*/}
      </div>
      <div className="adc_heatmapSelection-colorOuterDiv">
        <div className="adc_heatmapSelection-descriptionContainer">
          <span style={{backgroundColor: "rgb(179, 24, 201)", color: 'white'}}>API DOWN</span>
          <span style={{backgroundColor: "rgb(0, 0, 0)", color: 'white'}}>OFFLINE</span>
          <span style={{backgroundColor: "rgb(170, 162, 171)", color: 'white'}}>MISSING UNIT</span>
          <span style={{backgroundColor: "#fff"}}>GAP</span>
          <span style={{backgroundColor: "rgb(0, 255, 0)", color: 'black'}}>{heatmapType == 'Heat'?('TARGET HEAT'):('100%')}</span>
          <span style={{backgroundColor: "rgb(255, 0, 0)", color: 'white'}}>{heatmapType == 'Heat'?('MAX HEAT'):('1%')}</span>
          {heatmapType == 'Heat'?(<span style={{backgroundColor: "rgb(0, 0, 255)", color: 'white'}}>MIN HEAT</span>):(<></>)}
          {userLevel >= 1?(
            <span style={{backgroundColor: '#FFE4C4'}}>BLANK</span>
          ):(<></>)}
          <span style={{backgroundColor: sleep, color: 'white'}}>SLEEP</span>
          <span style={{backgroundColor: idle}}>IDLE</span>
        </div>
      </div>
    </div>
  )
}
//rgb(170, 162, 171);
export default HeatmapSelection

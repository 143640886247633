import {
    rootRoute
} from '../constants/routes'

const handleRequest = (url, options, paged=false) => {
    return fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
            if(paged){
                return {
                    ok: true,
                    msg: "successful",
                    data: data.success.data,
                    max_page: data.success.max_page,
                    current_page: data.success.current_page,
                    page_size: data.success.page_size,
                    total_data: data.success.totalData
                }
            }
            return {
                ok: true,
                msg: "successful",
                data: data.success.data
            }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }

const service = () => {
  // /api/firmware/vendors
  const getFirmwareVendors = (token) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    const url = `${rootRoute}/firmware/vendors`
    return handleRequest(url, requestOptions)
  }
  // /api/firmware/vendors/:id/versions
  const getFirmwareVersionsByVendorsID = (id, token) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      } 
    }
    const url = `${rootRoute}/firmware/vendors/${id}/versions`
    return handleRequest(url, requestOptions)
  }
  ///api/firmware/vendors
  const postFirmwareVendors = (body, token) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    const url = `${rootRoute}/firmware/vendors`
    return handleRequest(url, requestOptions)
  }
  const getFirmwareVersions = (token) => {
      var requestOptions = {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          } 
        }
      const url = `${rootRoute}/firmware/versions`
      return handleRequest(url, requestOptions)
  }
  const postFirmwareVersions = (body, token) => {
    var requestOptions = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      }
    const url = `${rootRoute}/firmware/versions`
    return handleRequest(url, requestOptions)
  }
  return {
    getFirmwareVersions,
    getFirmwareVendors,
    getFirmwareVersionsByVendorsID,
    postFirmwareVendors,
    postFirmwareVersions
  }
}
export default service()
import React, { Component } from 'react';
import './style.css'


const NotFoundView = ({ noAccess, code  }) => {
  const hrefSplit = window.location.pathname.split('/')
  return(
    <div className="notFoundView-container">
      <div className="notFoundView-paragraph">
        {noAccess.indexOf(hrefSplit[hrefSplit.length - 1]) == -1?(
          <>
            <strong>404</strong>
            <p>The current route was not found</p>
          </>
        ):(
          <>
            <strong>400</strong>
            <p>
              You do not have access to this resource <br/>
              Update your tier for access</p>
          </>
        )}
      </div>
    </div>
  )
}
export default NotFoundView

import React from 'react';
import './style.css'
import MinerLogListItem from '../MinerLogListItem';
import BootstrapPaging from '../BootstrapPaging'
const MinerLogList = ({ items, currentPage, maxPage, onPageClick, onClick, client, onSort, orderBy, acending , pageSize, changePageSize}) => {
  return (
    <div className="logContainer">
      <div className="tbl-header">
        <table className="uLogTable table-bordered" cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th className="uLogHeader" onClick={e => onSort("Time_Stamp")}>{ orderBy === "Time_Stamp"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Timestamp</th>
              <th className="uLogHeader" onClick={e => onSort("Location")}>{ orderBy === "Location"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Location</th>
              <th className="uLogHeader" onClick={e => onSort("Log_Type")}>{ orderBy === "Log_Type"? (
                acending? <i className="fa fa-sort-down"/>:<i className="fa fa-sort-up"/>
              ):<i className="fa fa-sort"/>} Type</th>
              <th className="uLogHeader">Description</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="tbl-content">
        <table className="uLogTable" cellPadding="0" cellSpacing="0" border="0">
          <tbody>
            {items.map((item, i) => {
              return (
                <MinerLogListItem
                  location={item.Location}
                  timestamp={item.Time_Stamp}
                  type={item.Log_Type}
                  description={item.Description}
                  item={item}
                  client={client}
                  onClick={onClick}
                />)
            })}
          </tbody>
        </table>
      </div>
      <BootstrapPaging
        currentPage={currentPage}
        maxPage={maxPage}
        onPageClick={onPageClick}
        pageSize={pageSize}
        changePageSize={changePageSize}
      />
    </div>
  )
}
export default MinerLogList;

import { getCustomerAlertsConstant } from '../constants/actions'
import service from '../services/alertService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const getCustomerAlertsAction = (token, customer, pageSize, pageNumber, orderBy, acending) => {
  return async(dispatch) => {
    const result = await service.getCustomerAlerts(token, customer, pageSize, pageNumber, orderBy, acending)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getCustomerAlertsConstant,
  payload: result
})

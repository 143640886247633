import React from 'react'
import PanelListItem from '../PanelListItem'
import './style.css'


//list: [[{data:{}, title}], [data:{}]]

const PanelList = ({ list }) => {
  return(
    <div className="panelList-container">
      {list.map((item, key) => (
        <PanelListItem
          data={item.data}
          title={item.title}
          link={item.link}
          key={key}
        />
      ))}
    </div>
  )
}


export default PanelList

import { clearCustomersAction } from './clearCustomersAction'

export const logOutAction = () => {
  return (dispatch) => {
    //dispatch(clearCustomersAction())
    dispatch(success())
  }
}


const success = () => ({
  type: 'LOGOUT_ACTION',
  payload: {ok: false, status: false, result: 0}
})

import React from 'react'
import './style.css'
import InfoTables from '../InfoTables'
import "bootstrap/dist/css/bootstrap.min.css";
import { userGroupSwitch } from './functions'



const AdminInfoSection = ({ data, columns, buttons, theme="table-striped table-dark", title}) => {
  return(
    <div className="card">
      <div className="card-header">
        <strong>{title}</strong>
      </div>
      <div className="card-body">
        <InfoTables
          data={data}
          columns={columns}
          buttons={buttons}
          theme={theme}
        />
      </div>
    </div>
  )
}

export default AdminInfoSection

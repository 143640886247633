export const rootRoute = `https://${process.env.REACT_APP_API_URL}/api`
export const getDatabases = `https://${process.env.REACT_APP_API_URL}/api/databases`;
export const overviewRoute = `https://${process.env.REACT_APP_API_URL}/api/overview`;
export const getSubnetsRoute = `https://${process.env.REACT_APP_API_URL}/api/subnets`;
export const getHousesRoute = `https://${process.env.REACT_APP_API_URL}/api/houses`;
export const postHousesRoute = `https://${process.env.REACT_APP_API_URL}/api/houses`;
export const getMinersRoute = `https://${process.env.REACT_APP_API_URL}/api/miners`;
export const getMinerRoute = `https://${process.env.REACT_APP_API_URL}/api/miner`;
export const getMinerLogsRoute = `https://${process.env.REACT_APP_API_URL}/api/logs/miners`;
export const getLogsByMacRoute = `https://${process.env.REACT_APP_API_URL}/api/logs/miner/`;
export const mapMinersRoute = `https://${process.env.REACT_APP_API_URL}/api/map`;
export const changePasswordRoute = `https://${process.env.REACT_APP_API_URL}/api/passwords/change`;
export const loginRoute = `https://${process.env.REACT_APP_API_URL}/api/login`;
export const getPodsRoute = `https://${process.env.REACT_APP_API_URL}/api/pods`
export const getPodsLimitedRoute = `https://${process.env.REACT_APP_API_URL}/api/pods/limited`
export const postPodsRoute = `https://${process.env.REACT_APP_API_URL}/api/pods`
export const getMinersByPodRoute = `https://${process.env.REACT_APP_API_URL}/api/miners/pod`
export const poolsRoute = `https://${process.env.REACT_APP_API_URL}/api/pools`;
export const postPoolRoute = `https://${process.env.REACT_APP_API_URL}/api/pools`
export const putPoolRoute = `https://${process.env.REACT_APP_API_URL}/api/pool`
export const sendCommandRoute = `https://${process.env.REACT_APP_API_URL}/api/command`;
export const deletePoolRoute = `https://${process.env.REACT_APP_API_URL}/api/pool`;
export const deletePodRoute = `https://${process.env.REACT_APP_API_URL}/api/pod`;
export const deleteHouseRoute = `https://${process.env.REACT_APP_API_URL}/api/house`;
export const getHashrateRoute = `https://${process.env.REACT_APP_API_URL}/api/hash`;
export const getUserLogsByTimeRoute = `https://${process.env.REACT_APP_API_URL}/api/logs/user`
export const getFavMinersRoute = `https://${process.env.REACT_APP_API_URL}/api/favorite/miners`;
export const getFavItemsRoute = `https://${process.env.REACT_APP_API_URL}/api/favorite/items`;
export const postFavMinersRoute = `https://${process.env.REACT_APP_API_URL}/api/favorite/miners`;
export const postFavItemsRoute = `https://${process.env.REACT_APP_API_URL}/api/favorite/item`;
export const deleteFavItemRoute = `https://${process.env.REACT_APP_API_URL}/api/favorite/item`;
export const deleteFavMinersRoute = `https://${process.env.REACT_APP_API_URL}/api/favorite/miners`;
export const getHouseSubConRoute = `https://${process.env.REACT_APP_API_URL}/api/subnets/houses`
export const postHouseSubConRoute = `https://${process.env.REACT_APP_API_URL}/api/subnets/houses`
export const deleteHouseSubConRoute = `https://${process.env.REACT_APP_API_URL}/api/subnets/houses/connection`;
export const getTypesRoute = `https://${process.env.REACT_APP_API_URL}/api/types/miners`;
export const getAllTypesRoute = `https://${process.env.REACT_APP_API_URL}/api/types/all`;
export const postTypesRoute = `https://${process.env.REACT_APP_API_URL}/api/types/miner`;
export const deleteTypesRoute = `https://${process.env.REACT_APP_API_URL}/api/types/miner`;
export const setMinerTypeRoute = `https://${process.env.REACT_APP_API_URL}/api/miners/type`;
export const maintenanceRoute = `https://${process.env.REACT_APP_API_URL}/api/logs/maintenance`
export const inventoryRoute   = `https://${process.env.REACT_APP_API_URL}/api/inventory`
export const accessRoute = `https://${process.env.REACT_APP_API_URL}/api/access`
export const alertsRoute  = `https://${process.env.REACT_APP_API_URL}/api/alerts`
export const alertRoute  = `https://${process.env.REACT_APP_API_URL}/api/alert`
export const clearAlertRoute  = `https://${process.env.REACT_APP_API_URL}/api/clear/alert`
export const minersRoute = `https://${process.env.REACT_APP_API_URL}/api/miners`;
export const minerRoute = `https://${process.env.REACT_APP_API_URL}/api/miner`;
export const ticketRoute = `https://${process.env.REACT_APP_API_URL}/api/service/ticket`
export const reportRoute = `https://${process.env.REACT_APP_API_URL}/api/service/report`
export const statusRoute = `https://${process.env.REACT_APP_API_URL}/api/miners/status`
export const getMinerCommentsRoutes = `https://${process.env.REACT_APP_API_URL}/api/comments`
export const adminRoute             = `https://${process.env.REACT_APP_API_URL}/api/admin`
export const socketRoute = `https://${process.env.REACT_APP_SIO_SERVER_URL}`

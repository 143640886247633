import React from 'react'
import './style.css'
import CommandSectionModel from '../CommandSectionModel'

const CommandSection = ({ onClick, addManyToFav, deleteManyFromFav, onChangeTypeClick, openAlterStatus, clearLocations }) => {
  return(
    <div className="adc_commandSection-margin">
      <CommandSectionModel
        Header={
          <span>Command Section</span>
        }
        Body={
          <>
            <a className="adc_commandSection-item" onClick={e => onClick('restart')}>
              <i className="fas fa-sync" style={{color: 'red'}}></i>
              Restart
            </a>
            <a className="adc_commandSection-item" onClick={e => onClick('update')}>
              <i className="fas fa-sync" style={{color: 'green'}}></i>
              Update
            </a>
            <a className="adc_commandSection-item" onClick={e => onClick('config')}>
              <i className="fa fa-cog" style={{color: 'orange'}} aria-hidden="true"></i>
              Configure
            </a>
            <a className="adc_commandSection-item" onClick={e => onClick('delete')}>
              <i className="fa fa-trash" style={{color: 'red'}} aria-hidden="true"></i>
              Delete
            </a>
            <a className="adc_commandSection-item" onClick={(e) => addManyToFav()}>
              <i className="fa fa-star" style={{color: 'green'}} aria-hidden="true"></i>
              Add To Favorite
            </a>
            <a className="adc_commandSection-item" onClick={(e) => deleteManyFromFav()}>
              <i className="fa fa-star" style={{color: 'red'}} aria-hidden="true"></i>
              Remove From Favorite
            </a>
            <a className="adc_commandSection-item" onClick={(e) => onChangeTypeClick()}>
              <i className="fas fa-edit" aria-hidden="true"></i>
              Set Miner Type
            </a>
            <a className="adc_commandSection-item" onClick={(e) => openAlterStatus()}>
              <i className="fas fa-edit" aria-hidden="true"></i>
              Alter Status
            </a>
            <a className="adc_commandSection-item" onClick={(e) => clearLocations()}>
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              Clear Locations
            </a>
          </>
        }
      />
    </div>
  )
}
export default CommandSection

import {
  accessRoute
}
from '../constants/routes'

const service = () => {
  const getAccessLevels = (token) => {
    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${accessRoute}/levels`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          levels: data.success.data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          levels: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      levels: []
    }))
  }
  const putAccessLevels = (token, body) => {
    var requestOptions = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${accessRoute}/levels`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          levels: data.success.data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          levels: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to send command to server",
      levels: []
    }))
  }
  return {
    getAccessLevels,
    putAccessLevels
  }
}

export default service()

import {
  rootRoute,
  minersRoute,
  minerRoute,
  statusRoute,
  getMinerCommentsRoutes,
  overviewRoute
}
from '../constants/routes'

const service = () => {
  const markDeleted = (token, body, client) => {
    return fetch(`${minersRoute}/${client}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          macs: data.success.macs,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.msg,
          macs: null,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      macs: null
    }));
  }
  const restoreMiners = (token, body, client,) => {
    return fetch(`${minersRoute}/restore/${client}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          macs: data.success.macs,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.msg,
          macs: null,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      macs: null
    }));
  }
  const editSerialNumber = (token, client, mac, body) => {
    return fetch(`${rootRoute}/miner/${mac}/serial/${client}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: 'Successful',
          data: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.msg,
          data: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      comment: []
    }));
  }
  const postComment = (token, body, client, mac) => {
    return fetch(`${minerRoute}/${mac}/comment/${client}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: 'Successful',
          comment: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.msg,
          comment: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      comment: []
    }));
  }
  const seenMiner = (token, mac, client) => {
    return fetch(`${minerRoute}/${mac}/seen/${client}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: 'Successful',
          seenMiners: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.msg,
          seenMiners: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      seenMiners: []
    }));
  }
  const alterMinersStatus = (token, body, client, status) => {
    return fetch(`${statusRoute}/${status}/${client}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.msg,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
    }));
  }
  const getHeatmapMiners = (token, client, house, pod, rack, podName, method, region_id) => {
    return fetch(`${minersRoute}/heatmap/${house}/${pod}/${client}/region/${region_id}?rack=${rack}&method=${method}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: 'Successfully',
          client: client,
          house: house,
          currentPod: pod,
          currentPodName: podName,
          heatmaps: data.success.data,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.msg,
          client: '',
          house: house,
          currentPod: pod,
          currentPodName: podName,
          heatmaps: [],
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      client: '',
      house: house,
      currentPod: pod,
      heatmaps: []
    }));
  }
  const clearLocations = (token, client, macs) => {
    return fetch(`${minersRoute}/clear/locations/${client}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(macs)
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
        }
      }
      else{
        return{
          ok: false,
          msg: data.error.msg,
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
    }));
  }
  const getAllMiners = (token, client, pageSize, pageNumber, orderBy, acending, filterString, region_id)=>{
    return fetch(`${minersRoute}/all/${client}/region/${region_id}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&filter[]=${filterString}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          page_size: data.success.page_size,
          current_page: data.success.current_page,
          max_page: data.success.max_page,
          data: data.success.data,
          history: data.success.history,
          online: data.success.online,
          offline: data.success.offline,
          performance: data.success.performance,
          estPower: data.success.estPower,
          totalHashrate: data.success.totalHashrate,
          totalData: data.success.totalData,
          idle: data.success.idle,
          sleep: data.success.sleep,
          cardsCount: data.success.cardsCount,
          apiDown: data.success.apiDown,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    }).catch(error =>
      {
        return{
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
        }
      });
  }
  const getMinerComments = (token, mac, client, pageNumber, pageSize, orderBy, acending) => {
    return fetch(`${getMinerCommentsRoutes}/${mac}/${client}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          page_size: data.success.page_size,
          current_page: data.success.current_page,
          max_page: data.success.max_page,
          data: data.success.data,
          orderBy: data.success.orderBy,
          acending: data.success.acending,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    }).catch(error =>
      {
        return{
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
        }
      });
  }
  ///api/overview/region/:customer/region/:region_id
  ///api/regions/:region_id/overview/:customer
  const getRegionOverview  = (token, customer, region_id) => {
    return fetch(`${rootRoute}/regions/${region_id}/overview/${customer}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
          api_version: data.api_version
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    }).catch(error =>
      {
        return{
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
        }
      });
  }
  const getMinerStatusOverview = (token, customer, region_id) => {
    return fetch(`${overviewRoute}/${customer}/region/${region_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          offline: data.success.data.offline,
          high_performance: data.success.data.highPerformance,
          low_performance: data.success.data.lowPerformance,
          idle: data.success.data.idle,
          sleep: data.success.data.sleep,
          deleted: data.success.data.deleted,
          maintenance: data.success.data.maintenance,
          performance: data.success.data.currentPerformance,
          boards: data.success.data.currentBoards,
          current_hashrate: data.success.data.currentHashrate,
          none_type: data.success.data.noneType,
          offlineMiners24Hours: data.success.data.offlineMiners24Hours,
          offlineMiners24HoursPlus: data.success.data.offlineMiners24HoursPlus,
          onlineMiners: data.success.data.onlineMiners,
          totalMiners: data.success.data.totalMiners,
          favoriteMiners: data.success.data.favoriteMiners,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    }).catch(error =>
      {
        return{
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
        }
      });
  }
  const getOverviewMinersByType = (token, client, type, pageSize, pageNumber, orderBy, acending, filterString, region_id)=>{
    return fetch(`${overviewRoute}/${type}/${client}/region/${region_id}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&filter[]=${filterString}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          page_size: data.success.page_size,
          current_page: data.success.current_page,
          max_page: data.success.max_page,
          data: data.success.data,
          online: data.success.online,
          offline: data.success.offline,
          performance: data.success.performance,
          estPower: data.success.estPower,
          totalHashrate: data.success.totalHashrate,
          totalData: data.success.totalData,
          idle: data.success.idle,
          sleep: data.success.sleep,
          cardsCount: data.success.cardsCount,
          apiDown: data.success.apiDown,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          api_version: data.api_version,
          key_error: data.error.key_error,
        }
      }
    }).catch(error =>
      {
        return{
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
        }
      });
  }
   return {
     markDeleted,
     postComment,
     seenMiner,
     alterMinersStatus,
     getHeatmapMiners,
     clearLocations,
     getAllMiners,
     getMinerComments,
     getMinerStatusOverview,
     getOverviewMinersByType,
     restoreMiners,
     getRegionOverview,
     editSerialNumber
   }
}
export default service();

import { postFirmwareVendorsConstant } from '../../constants/actions'
import service from '../../services/firmwareService'




export const postFirmwareVendorsAction = (body, token) => {
    return async(dispatch) => {
        const result = await service.postFirmwareVendors(body, token)
        dispatch(success(result))
    }
}
const success = (result) =>({
  type: postFirmwareVendorsConstant,
  payload: result
})

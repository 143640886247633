import React from 'react';
import './style.css';
import Section from '../Section'
import ConfigSectionTable from '../ConfigSectionTable'

const ConfigBuildingSection = ({ buildings, onAdd, onMinimize, showBody, removeItem, showAdd, buildingName, onSave, onCancel, onInputChange, privilegesLevel, deletePrivileges, insertPrivileges }) => {
  return(
    <Section
      icon={<i className="fa fa-building"/>}
      name="Building Section"
      onMinimize={onMinimize}
      showBody={showBody}
      body={
        <div className="adc_configBuildingSection-itemOuterDiv">
          <ConfigSectionTable
            columns={{
              ID: "ID",
              Name: "Building Name",
            }}
            items={buildings}
            onDelete={removeItem}
            privilegeLevel={privilegesLevel}
            deletePrivileges={deletePrivileges}
            deleteType="House"
          />
            {showAdd?(
              <div className="adc_configBuildingSection-addDiv">
                <input value={buildingName} name="newBuildingName" className="adc_configBuildingSection-input" placeholder="Enter building name" onChange={e => onInputChange(e)}/>
                <div className="adc_configBuildingSection-addInnerDiv">
                  <button type="button" className="adc_deviceMapping-button btn-danger" onClick={e => onCancel(e)}>
                    Cancel
                  </button>
                  <button type="button" className="adc_deviceMapping-button btn-success" onClick={e => onSave(e)}>
                    Save
                  </button>
                </div>
              </div>
            ):(
              privilegesLevel >= insertPrivileges?(
                <div>
                  <button type="button" className="adc_deviceMapping-button" onClick={e => onAdd(e)}>
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              ):(<></>)
            )}
        </div>
      }
    />
  )
}

export default ConfigBuildingSection

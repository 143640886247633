import React from 'react';
import './style.css';


const Section = ({ icon, name, showBody, onMinimize, body, styles }) => {
  return(
    <div className="adc_Section-outerContainer" style={styles}>
      <div className="adc_SectionHeaderDiv">
          <div className="adc_SectionHeader">
            {icon}
            <span>{name}</span>
          </div>
          <div className="adc_SectionButtonDiv">
            <button type="button" className="btn btn-box-tool"  onClick={e => onMinimize()}>
              {showBody?(<i className="fa fa-minus"/>):
                (<i className="fa fa-plus"/>)}
            </button>
          </div>
      </div>
      {showBody?(
        body
      ):(<></>)}
    </div>
  )
}

export default Section

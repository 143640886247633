import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import FormfieldSection from '../../Components/FormfieldSection'
import { Modal } from "react-responsive-modal";
import { onTabHeaderClick, onInputChange } from '../../functions'
import { getMinerFlagsByMACAction } from '../../redux/actions/getMinerFlagsByMACAction'
import { minerView } from '../../helper/tabs'
import { postCustomMinerFlagsAction } from '../../redux/actions/postCustomMinerFlagsAction'
import moment from 'moment'

import './style.css'

class MinerFlagView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      modalOpen: false,
      customFlagComment: ''
    }
  }
  componentDidMount = () => {
    // Should be getting flags
    this.props.getMinerFlagsByMACAction(this.props.match.params.miner, this.props.token, this.props.match.params.name)
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  createCustomFlag = () => {
    const { customFlagComment } = this.state
    const body = {
      comment: customFlagComment,
      macs: [this.props.match.params.miner]
    }
    this.props.postCustomMinerFlagsAction(body, this.props.token, this.props.match.params.name)
    this.setState({
      customFlagComment: '',
      modalOpen: false
    })
  }
  render(){
    this.props.flagStore.flags.map((item) => item.Creation_Date = moment(item.Creation_Date).format('YYYY-MM-DD hh:mm:ss'))
    const columns = [
      {field: 'Status', value: 'Flag Status'},
      {field: 'Flag_Type_Name', value: 'Flag Type'},
      {field: 'Activity_Logs', value: 'Activity Logs'},
      {field: 'Recovery_Logs', value: 'Recovery Logs'},
      {field: 'Creation_Date', value: 'Created at'},
    ]
    const addCommentField = [
      {Name: 'Comment', PropertyName: 'customFlagComment', Value: this.state.customFlagComment, Type: 'textarea', Options: []}
    ]
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    return(
      <div className="MinerView-container">
        <TabHeader
          logo={
            <img className="tab-view-image" src={require("../../images/MinerDetailsIcon.png")}/>
          }
          title="Miner Details"
          subtitle="Detailed miner info, user comments, repairs & more"
          tabs={minerView}
          active="Flag"
          onClick={this.onClick}
        />
        <div className="MinerView-content">
          <ViewBody
            data={this.props.flagStore.flags}
            columns={columns}
            list
            createtext="Create custom flag"
            onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
            itemClickInfo={{name: 'flag', property: 'ID'}}
            onCreate={e => this.setState({modalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.modalOpen}
          center
          onClose={() => this.setState({ modalOpen: false })}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create custom flag"
            fields={addCommentField}
            addButton={true}
            buttonText="Create"
            onAddClick={this.createCustomFlag}
            onInputChange={e => onInputChange(e, this)}
            action="create_custom_flag"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  flagStore: props.flagReducer
})

export default connect(mapStateToProps, {
  getMinerFlagsByMACAction,
  postCustomMinerFlagsAction
})(MinerFlagView)

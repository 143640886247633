import {
  rootRoute,
  adminRoute,
}
from '../constants/routes';


const service = () => {
  const getAllUsers = (token, pageSize, pageNumber, orderBy, acending, searchValue) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${adminRoute}/system/users?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getCustomersName = (token) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${adminRoute}/database`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getRegions = (token) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${adminRoute}/regions`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const addDatabase = (token, body) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/database`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const addUsersToDatabase = (token, database, body) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/databases/${database}/users`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const createRegion = (token, database, body) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/databases/${database}/region`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const addUsersToRegion = (token, database, region_id, body) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/databases/${database}/region/${region_id}/users`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ...data,
          msg: "successful",
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const addToRegionEmailList = (token, database, region_id, body) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/databases/${database}/region/${region_id}/email`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ...data,
          msg: "successful",
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const createNewUser = (token, body) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/customer`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const addNewMinerType = (token, body, pageSize, pageNumber, orderBy, acending, searchValue) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/type?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const adjustUserPrivileges = (token, body) => {
    var requestOptions = {
      method: 'PUT',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/privileges/customer`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "Successfully changed user privileges",
          data: data.success.data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const adjustSystemPrivileges = (token, body) => {
    var requestOptions = {
      method: 'PUT',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/system/privileges`, requestOptions)
    .then((data) => {
        return {
          ok: true,
          msg: 'Successfully updated user privileges',
        }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
      key_error: "",
    }));
  }
  const deleteUser = (token, username) => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    }
    return fetch(`${adminRoute}/user/${username}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const deleteDatabase = (token, database) => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    }
    return fetch(`${adminRoute}/database/${database}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const adjustPerfromanceDrop = (token, database, body) => {
    return fetch(`${adminRoute}/databases/${database}/performance`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getRegionInfo = (token, database, region_id) => {
    return fetch(`${adminRoute}/databases/${database}/region/${region_id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ...data,
          msg: "successful",
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getDatabaseInfo = (token, database) => {
    return fetch(`${adminRoute}/databases/${database}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data.success.data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const deleteMinerType = (token, id) => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    }
    return fetch(`${adminRoute}/minertype/${id}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const editMinerType = (token, id, body, pageSize, pageNumber, orderBy, acending, searchValue) => {
    var requestOptions = {
      method: 'PATCH',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/minertype/${id}?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const updateDatabaseTier = (token, customer, tier) => {
    var requestOptions = {
      method: 'PATCH',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    return fetch(`${adminRoute}/tier/${tier}/${customer}`, requestOptions)
    .then((data) => {
        return {
          ok: true,
          msg: 'Successfully updated user privileges',
        }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      key_error: "",
    }));
  }
  const getAllScanners = (token, pageSize, pageNumber, orderBy, acending, searchValue) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${adminRoute}/scanners?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const getScannerInfo = (token, ip) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${adminRoute}/scanners/${ip}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const deployScanner = (token, id_no) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${adminRoute}/scanners/${id_no}/deploy`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: `Successfully updated ${id_no}`,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
    }));
  }
  const postScanner = (token, body, pageSize, pageNumber, orderBy, acending, searchValue) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/scanners?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&acending=${acending}&searchValue=${searchValue}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const rebootScanner = (token, ip) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    return fetch(`${adminRoute}/scanners/${ip}/reboot`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: JSON.stringify(data.error.reason),
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
    }));
  }
  const patchScanner = (token, ip, body) => {
    var requestOptions = {
      method: 'PATCH',
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return fetch(`${adminRoute}/scanners/${ip}`, requestOptions)
    .then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          api_version: data.api_version,
          data: data.success.data
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  return {
    getAllUsers,
    getCustomersName,
    addDatabase,
    createNewUser,
    addNewMinerType,
    adjustUserPrivileges,
    adjustSystemPrivileges,
    deleteUser,
    deleteDatabase,
    deleteMinerType,
    editMinerType,
    updateDatabaseTier,
    getAllScanners,
    postScanner,
    rebootScanner,
    patchScanner,
    deployScanner,
    getDatabaseInfo,
    addUsersToDatabase,
    getScannerInfo,
    adjustPerfromanceDrop,
    getRegionInfo,
    createRegion,
    addUsersToRegion,
    getRegions,
    addToRegionEmailList
  }
}

export default service()

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getHistoryAction } from './redux/actions/getHistoryAction'
import { replaceHistoryAction } from './redux/actions/replaceHistoryAction'
import { clearMessagePannelAction } from './redux/actions/clearMessagePannelAction'
import { closeResponsePanelAction } from './redux/actions/closeResponsePanelAction'
import { exitAction } from './redux/actions/exitAction'
import { Route, BrowserRouter, Router, Switch } from 'react-router-dom'
import logo from './logo.svg';
import './App.css';
import './media.css'
import { store } from './redux/store'
import Login from './Login';
import LoggedIn from './LoggedIn';
import LoginView from './Views/LoginView';
import MinerView from './Views/MinerView'
import AccessDeniedView from './Views/AccessDeniedView'
import NotFoundView from './Views/NotFoundView'
import MessagePanel from './Components/Messagepanel';
import ResponsePanel from './Components/ResponsePanel'
import storage from 'redux-persist/lib/storage'
import { Prompt } from 'react-router'
import { dragElement } from './helper/functions'
//const history = syncHistoryWithStore(createBrowserHistory(), store)

export class App extends Component {
  componentDidMount(){
    const element = document.getElementById("response-panel")
    if(element){
      dragElement(element)
    }
  }
  clearMsg = () => {
    this.props.clearMessagePannelAction()
  }
  closePanel = () => {
    this.props.closeResponsePanelAction()
  }
  render() {
      return (
          <div className="App">
            <Router history={this.props.history}>
              <div>
                <Switch>
                  <Route exact path="/" component={LoggedIn} />
                  <Route exact path="/login" component={Login} />
                  <Route path="/changepassword" component={LoggedIn} />
                  <Route path="/client" component={LoggedIn} />
                  <Route path="/admin" component={LoggedIn} />
                  <Route path="/denied" component={AccessDeniedView} />
                  <Route render={(props) => (
                    <NotFoundView
                      noAccess={[]}
                    />
                  )} />
                </Switch>
              </div>
            </Router>
            <MessagePanel
              clearMsg={this.clearMsg}
            />
            <ResponsePanel
              closePanel={this.closePanel}
            />
          </div>
      )
    }
};

const mapStateTopProps = (store) => ({
  store,
  historyStore: store.historyReducer
});
export default connect(mapStateTopProps, {
  getHistoryAction,
  replaceHistoryAction,
  exitAction,
  clearMessagePannelAction,
  closeResponsePanelAction
})(App);

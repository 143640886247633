import {
  getMinerConstant,
  clearLocationsConstant,
  updateSingleMinerLocation,
  restoreMinersConstant,
  markMinersDeletedConstant,
  getMinerHistoryConstant,
  editSerialNumberConstant
} from '../constants/actions';
const defaultState = {
  ok: false,
  msg: '',
  api_version: 1.1,
  miner: [],
  history: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case getMinerHistoryConstant:
      return {
          api_version: action.payload.api_version,
          ok: action.payload.ok,
          msg: action.payload.msg,
          miner: state.miner,
          history: action.payload.data
      }
    case editSerialNumberConstant:
    case getMinerConstant:
      if(action.payload.ok){
        const newState = {
          api_version: action.payload.api_version,
          ok: action.payload.ok,
          msg: action.payload.msg,
          miner: action.payload.data,
          history: state.history
        }
        return newState;
      }
      return state
    case updateSingleMinerLocation:
      let updateLocationMiner = JSON.parse(JSON.stringify(state.miner[0]))
      updateLocationMiner.Location = action.payload
      return {
        ok: state.ok,
        msg: state.msg,
        miner: [updateLocationMiner],
        history: state.history
      }
    case markMinersDeletedConstant:
      if(action.payload.ok && state.miner.length > 0){
        let newMacList1 = action.payload.macs.filter((item) => item == state.miner[0].MAC_Address)
        if(newMacList1.length > 0){
          let deleteMiner = JSON.parse(JSON.stringify(state.miner[0]))
          deleteMiner.Deleted = 1
          return {
            ok: true,
            msg: action.payload.msg,
            api_version: action.payload.api_version,
            miner: [deleteMiner],
            history: state.history
          }
        }
      }
      return state
    case restoreMinersConstant:
      if(action.payload.ok && state.miner.length > 0){
        let newMacList = action.payload.macs.filter((item) => item == state.miner[0].MAC_Address)
        if(newMacList.length > 0){
          let restoreMiner = JSON.parse(JSON.stringify(state.miner[0]))
          restoreMiner.Deleted = 0
          return {
            ok: true,
            msg: action.payload.msg,
            api_version: action.payload.api_version,
            miner: [restoreMiner],
            history: state.history
          }
        }
      }
      return state
    case clearLocationsConstant:
      if(action.payload.ok && !action.payload.list){
        let miner = JSON.parse(JSON.stringify(state.miner[0]))
        miner.Location = 'None'
        return {
          ok: state.ok,
          msg: state.msg,
          miner: [miner],
          history: state.history
        }
      }
      return state;
    default:
        return state;
  }
}

import { sendCommandConstant } from '../constants/actions'
const defaultState = {
  ok: false,
  msg: '',
  blocked: 0,
  api_version: 1.1,
}
export default (state = defaultState, action) => {
  switch (action.type) {
    case sendCommandConstant:
      return action.payload;
    default:
      return state;
  }
}
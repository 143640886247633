import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import './style.css'
import { onTabHeaderClick, onInputChange, filterCheck } from '../../functions'
import { maintenanceView } from '../../helper/tabs'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { getMaintenanceLogsAction } from '../../redux/actions/getMaintenanceLogsAction'
import { clearMaintenanceAction } from '../../redux/actions/clearMaintenanceAction'
import { updateClientAction } from '../../redux/actions/updateClientAction'

class MaintenanceLogsView extends React.Component{
  constructor(props){
    super(props)
    this.state={
      searchValue: ''
    }
  }
  componentDidMount = () => {
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
    }
    const element = document.getElementById("maintenanceLink")
    if (element) {
      element.classList.add("itemSelected")
    }
    this.props.getMaintenanceLogsAction(
      this.props.token,
      this.props.match.params.name,
      this.props.maintenancePageStore.pageSize,
      this.props.maintenancePageStore.pageNumber,
      this.props.maintenancePageStore.orderBy,
      this.props.maintenancePageStore.acending,
      this.state.searchValue,
      this.props.match.params.region_id)
  }
  componentDidUpdate(){
    if(this.props.clientStore.currentClient !== this.props.match.params.name){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
    if(this.props.clientStore.currentRegion !== this.props.match.params.region_id){
      this.props.updateClientAction(this.props.match.params.name, this.props.match.params.region_id)
      this.componentDidMount()
    }
  }
  onPageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber === parseInt(this.props.maintenanceStore.current_pagee)) return
    const { pageSize, orderBy, acending } = this.props.maintenancePageStore;
    if (pageNumber !== 0 && pageNumber <= this.props.maintenanceStore.max_page) {
      this.props.getMaintenanceLogsAction(this.props.token, this.props.match.params.name, pageSize, pageNumber, orderBy, acending, this.state.searchValue, this.props.match.params.region_id);
    }
    const store = {
      MAC_Address: this.props.match.params.miner,
      client: this.props.maintenancePageStore.client,
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'maintenenceList'
    }
    this.props.updatePageInfoAction(store);
  }
  onSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.maintenancePageStore;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getMaintenanceLogsAction(this.props.token, this.props.match.params.name, pageSize, pageNumber, type, acending, this.state.searchValue, this.props.match.params.region_id);
    const store = {
      client: this.props.maintenancePageStore.client,
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'maintenenceList'
    }
    this.props.updatePageInfoAction(store);
  }
  onChangePageSize = (e) => {
    const { orderBy, acending } = this.props.maintenancePageStore;
    const value = parseInt(e.target.value)
    let pageNum = this.props.maintenancePageStore.pageNumber;
    if(value*parseInt(pageNum) > parseInt(this.props.maintenanceStore.max_page)*parseInt(this.props.maintenanceStore.page_size)){
      pageNum = 1;
    }
    if(value > parseInt(this.props.maintenanceStore.max_page)*parseInt(this.props.maintenanceStore.page_size)){
      pageNum = 1;
    }
    this.props.getMaintenanceLogsAction(this.props.token, this.props.match.params.name, value, pageNum, orderBy, acending, this.state.searchValue, this.props.match.params.region_id);
    const store = {
      MAC_Address: this.props.match.params.miner,
      pageSize: value? value: 0,
      pageNumber: pageNum,
      client: this.props.match.params.name,
      type: this.props.maintenancePageStore.type,
      orderBy: this.props.maintenancePageStore.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.maintenancePageStore;
    this.props.getMaintenanceLogsAction(this.props.token, this.props.match.params.name, pageSize, 1, orderBy, acending, e.target.value, this.props.match.params.region_id);
    this.setState({
      searchValue: e.target.value
    })
    const store = {
      MAC_Address: this.props.match.params.miner,
      pageSize: pageSize,
      pageNumber: 1,
      client: this.props.match.params.name,
      type: this.props.maintenancePageStore.type,
      orderBy: this.props.maintenancePageStore.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  render(){
    const columns = [
      {field: "Location", value: "Location"},
      {field: "Serial_Number", value: "Serial Number"},
      {field: "Username", value: "User"},
      {field: "Description", value: "Description"},
      {field: 'logItemString', value: 'Items'},
      {field: "Log_Date", value: "Date"},
      {field: "Other_Info", value: "Other Info"},
    ]
    console.log(this.props.maintenanceStore.logs)
    return(
      <div className="MaintenanceLogsView-container">
          <TabHeader
            logo={<i className="fa fa-wrench fa-5x"/>}
            title="Inventory & Maintenance"
            subtitle="Manage your inventory, and look over logs"
            tabs={maintenanceView}
            active="Maintenance Logs"
            onClick={this.onClick}
          />
          <ViewBody
            data={this.props.maintenanceStore.logs}
            columns={columns}
            list
            pager
            sorting
            search
            searchValue={this.state.searchValue}
            onSearchChange={this.onSearch}
            acending={this.props.maintenancePageStore.acending}
            sortedBy={this.props.maintenancePageStore.orderBy}
            onSort={this.onSort}
            totalPages={this.props.maintenanceStore.max_page}
            currentPage={parseInt(this.props.maintenanceStore.current_page) - 1}
            onPageChange={this.onPageClick}
          />
      </div>
    )
  }
}


const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  maintenanceStore: props.maintenanceReducer,
  maintenancePageStore: props.pageReducer.maintenenceList,
  clientStore: props.clientReducer
})

export default connect(mapStateToProps, {
  getMaintenanceLogsAction,
  clearMaintenanceAction,
  updatePageInfoAction,
  updateClientAction
})(MaintenanceLogsView)

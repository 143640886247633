import React from 'react';
import './style.css'

const CustomerInformationCard = ({type, name, totalMiners, offlineMiners, totalHashrate, customerName, house}) => {
  return(
    <div className="adc_customerInformation-container">
      <p>{type} Info</p>
      <table className='table table-bordered table-striped'>
        <tr>
          <td>Customer</td>
          <td>{customerName}</td>
        </tr>
        <tr>
          <td>{type}</td>
          <td>{house?(`${house} / ${name}`):(`${name}`)}</td>
        </tr>
        <tr>
          <td>Miners</td>
          <td>{totalMiners-offlineMiners} / {totalMiners}</td>
        </tr>
        <tr>
          <td>Current Hashrate</td>
          <td>{totalHashrate}</td>
        </tr>
      </table>
    </div>
  )
}

export default CustomerInformationCard

import { postToEmailListConstant } from '../constants/actions';
import service from '../services/alertService';
import { setErrorMessageAction } from './setErrorMessageAction'

export const postToEmailListAction = (token, body, customer) => {
  return async(dispatch) =>{
    const result = await service.postToEmailList(token, body, customer)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: postToEmailListConstant,
  payload: result
})

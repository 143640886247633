import React from 'react';
import PropTypes from 'prop-types';
const ConfigureTypeForm = ({typeName, onInputChange, onFormSubmit}) => {
  return(
    <div className="form-style-5">
      <form action="">
        <fieldset>
          <legend><span className="number">5</span>Miner Type</legend>
          <label htmlFor="typeName">House Name</label>
          <select className="form-control form-control-sm" name="typeName" defaultValue={typeName} onInput={e => onInputChange(e)}>
            <option key={1}>AntminerS9</option>
            <option key={2}>AntminerS17</option>
            <option key={3}>AntminerS17+</option>
            <option key={4}>AntminerS17 pro</option>
            <option key={5}>WhatsminerM10</option>
            <option key={6}>WhatsminerM20</option>
            <option key={7}>WhatsminerM21</option>
            <option key={8}>InnosiliconT2</option>
            <option key={9}>InnosiliconT3</option>
            <option key={10}>InnosiliconA9</option>
          </select>
        </fieldset>
        <input type="button" value="submit" onClick={e => onFormSubmit(e)}/>
      </form>
    </div>
  )
}

ConfigureTypeForm.propTypes = {
  typeName:PropTypes.string.isRequired,
  onInputChange:PropTypes.func.isRequired,
  onFormSubmit:PropTypes.func.isRequired,
}
export default ConfigureTypeForm;


/*

  <form action="" className="add-froms form from-horizontal">
    <div className="form-group">
        <label htmlFor="houseName">House Name</label>
        <input type="text" id="houseName" name="houseName" value={houseName} className="form-control" onInput={e => onInputChange(e)}/>
    </div>
    <div className="from-group">
        <label htmlFor="subnetName">Subnet Name</label>
        <select className="form-control form-control-sm" name="subnetName" value={subnetName} onInput={e => onInputChange(e)}>
          {createOption(subnets)}
        </select>
    </div>
    <div className="form-group">
        <input type="button" id="password" value="submit" className="btn btn-submit" onClick={e => onFormSubmit(e)}/>
    </div>
  </form>
  */

import { getTuningProfilesConstant } from '../../constants/actions'
import service from '../../services/tuningService'




export const getTuningProfilesAction = (token, customer) => {
    return async(dispatch) => {
        const result = await service.getTuningProfiles(token, customer)
        dispatch(success(result))
    }
}
const success = (result) =>({
  type: getTuningProfilesConstant,
  payload: result
})

import { getFlagCommentsByIdConstant } from '../constants/actions'
import service from '../services/flagService'



export const getFlagCommentsByIdAction = (id, token, customer) => {
  return async(dispatch) => {
    const result = await service.getFlagCommentsById(id, token, customer)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: getFlagCommentsByIdConstant,
  payload: result
})

import {
  getPoolsConstant,
  postPoolsConstant,
  putPoolConstant,
  deletePoolConstant,
  postProfileConstant,
  addToProfileConstant,
  removeFromProfileConstant,
  deleteProfileConstant,
  getProfilesConstant,
  getProfileByIdConstant,
  putScannerToProfileConstant,
  putMinerTypeToProfileConstant,
  deleteScannerFromProfileConstant,
  deleteMinerTypeFromProfileConstant,
  deleteProfileMinerConnectionsConstant,
  editProfilePoolOrderConstant
} from '../constants/actions';

const defaultState = {
  ok: false,
  api_version: 1.1,
  msg: '',
  pools: [],
  profiles: []
}

export default (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case postPoolsConstant:
    case putPoolConstant:
    case getPoolsConstant:
      if(action.payload.ok){
        newState.ok = action.payload.ok
        newState.msg = action.payload.msg
        newState.pools = action.payload.pools
      }
      return newState
    case deletePoolConstant:
        if(action.payload.ok){
          return action.payload;
        }
        else{
          const newState = {
            ok: action.payload.ok,
            msg: action.payload.msg,
            pools: state.pools,
            profiles: state.profiles
          }
          return newState;
        }
      case postProfileConstant:
      case addToProfileConstant:
      case removeFromProfileConstant:
      case deleteProfileConstant:
      case getProfilesConstant:
      case getProfileByIdConstant:
      case putScannerToProfileConstant:
      case putMinerTypeToProfileConstant:
      case deleteScannerFromProfileConstant:
      case deleteProfileMinerConnectionsConstant:
      case deleteMinerTypeFromProfileConstant:
      case editProfilePoolOrderConstant:
        if(action.payload.ok){
          newState.profiles = action.payload.data;
          newState.api_version = action.payload.api_version;
          newState.ok = true
        }
        return newState
    default:
      return newState
  }
}

import clientService from '../services/clientService';
import { getPodsConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getPodsAction = (token, houseID, client, region_id) => {
  return async(dispatch) => {
    try{
      const result = await clientService.getPods(token, houseID, client, region_id);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(getPodsSuccess(result))
    }
    catch(err){
      console.log(err)
    }
  }
}
const getPodsSuccess = (pods) => ({
  type: getPodsConstant,
  payload: pods
})

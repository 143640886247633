import { toggleCustomerPannelConstant } from '../constants/actions'


const defaultState = {
  api_version: 1.1,
  hashrate: true,
  online: true,
  total: true,
  favorite: true,
  high_performance: true,
  low_performance: true,
  offline: true,
  idle:true,
  sleep: true,
  none_type: true,
  maintenance: true,
  deleted: true,
  performance: true,
  boards: true,
  detected_24: true,
  pending_24: true,
  checked_24: true,
  resolved_24: true,
  detected_total: true,
  pending_total: true,
  checked_total: true,
  resolved_total: true,
}


export default(state = defaultState, action) => {
  switch (action.type) {
    case toggleCustomerPannelConstant:
      let newState = JSON.parse(JSON.stringify(state))
      newState[action.payload.type] = action.payload.value
      return newState
    default:
      return state
  }
}

import { alterStatusConstant } from '../constants/actions';
import service from '../services/minerService'
import { setErrorMessageAction } from './setErrorMessageAction';
export const adjustMinersStatusAction = (token, body, client, status) => {
  return async(dispatch) =>{
    const result = await service.alterMinersStatus(token, body, client, status);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success({ok: result.ok, macs: body.macs, status}))
  }
}

const success = (result) => ({
  type: alterStatusConstant,
  payload: result
})

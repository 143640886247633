import {
    rootRoute
} from '../constants/routes'

const handleRequest = (url, options, paged=false) => {
    return fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
            if(paged){
                return {
                    ok: true,
                    msg: "successful",
                    data: data.success.data,
                    max_page: data.success.max_page,
                    current_page: data.success.current_page,
                    page_size: data.success.page_size,
                    total_data: data.success.totalData
                }
            }
            return {
                ok: true,
                msg: "successful",
                data: data.success.data
            }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }

const service = () => {
    const getTuningProfiles = (token, customer) => {
        var requestOptions = {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            } 
          }
        const url = `${rootRoute}/tuning/profiles/${customer}`
        return handleRequest(url, requestOptions)
    }
    const getTuningAttributes = (token) => {
        var requestOptions = {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            } 
          }
        const url = `${rootRoute}/tuning/attributes`
        return handleRequest(url, requestOptions)
    }
    
    const postTuningProfiles = (body, token, customer) => {
        var requestOptions = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          }
        const url = `${rootRoute}/tuning/profiles/${customer}`
        return handleRequest(url, requestOptions)
    }
    const deleteTuningProfiles = (body, token, customer) => {
      var requestOptions = {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      const url = `${rootRoute}/tuning/profiles/${customer}`
      return handleRequest(url, requestOptions)
  }
    const getTuningProfilesByID = (token, id, customer) => {
      var requestOptions = {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          } 
        }
      const url = `${rootRoute}/tuning/profiles/${id}/${customer}`
      console.log(url)
      return handleRequest(url, requestOptions)
    }
    const postTuningProfilesFirmwareConnections = (body, token, mode, customer) => {
      var requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
        const url = `${rootRoute}/tuning/profiles/firmwares/${customer}?mode=${mode}`
      return handleRequest(url, requestOptions)
    }
    const deleteTuningProfilesFirmwaresConnections = (body, token, customer) => {
      var requestOptions = {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
        const url = `${rootRoute}/tuning/profiles/firmwares/${customer}`
      return handleRequest(url, requestOptions)
    }
    const postTuningProfilesAttributesConnetions = (body, token, mode, customer) => {
      var requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      const url = `${rootRoute}/tuning/profiles/attributes/${customer}?mode=${mode}`
      return handleRequest(url, requestOptions)
    }
    const deleteTuningProfilesAttributesConnetions = (body, token, customer) => {
      var requestOptions = {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      const url = `${rootRoute}/tuning/profiles/attributes/${customer}`
      return handleRequest(url, requestOptions)
    }
    const postTuningProfilesMinerTypesConnections = (body, token, mode, customer) => {
      var requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      const url = `${rootRoute}/tuning/profiles/minertypes/${customer}?mode=${mode}`
      return handleRequest(url, requestOptions)
    }
    const deleteTuningProfilesMinerTypesConnections = (body, token, customer) => {
      var requestOptions = {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      const url = `${rootRoute}/tuning/profiles/minertypes/${customer}`
      return handleRequest(url, requestOptions)
    }
    return {
        getTuningProfiles,
        getTuningAttributes,
        postTuningProfiles,
        getTuningProfilesByID,
        postTuningProfilesFirmwareConnections,
        postTuningProfilesAttributesConnetions,
        postTuningProfilesMinerTypesConnections,
        deleteTuningProfiles,
        deleteTuningProfilesFirmwaresConnections,
        deleteTuningProfilesAttributesConnetions,
        deleteTuningProfilesMinerTypesConnections,
    }
}
export default service()
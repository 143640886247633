import { getFavMinersConstant, postFavMinersConstant, deleteFavMinersConstant} from '../constants/actions';
const defaultState = {
  ok: true,
  msg: '',
  data: [],
  api_version: 1.1,
}

export default (state = defaultState, action) => {
  switch (action.type){
    case getFavMinersConstant:
      if(!action.payload.ok){
        let s1 = JSON.parse(JSON.stringify(state));
        s1.msg = action.payload.msg;
        s1.ok = action.payload.ok;
        return s1;
      }
      else{
        let s1 = {
          ok: action.payload.ok,
          msg: "successful",
          data: action.payload.data
        }
        return s1;
      }
      break;
    case postFavMinersConstant:
      if(!action.payload.ok){
        let s1 = JSON.parse(JSON.stringify(state));
        s1.msg = action.payload.msg;
        s1.ok = action.payload.ok;
        return s1;
      }
      else{
        let s1 = {
          ok: action.payload.ok,
          msg: "successful",
          data: action.payload.data
        }
        return s1;
      }
      break;
    case deleteFavMinersConstant:
      if(action.payload.ok){
        return action.payload;
      }
      else{
        const s3 = {
          ok: action.payload.ok,
          msg: action.payload.msg,
          data: state.data
        }
        return s3;
      }
    break;
    default:
      return state;
  }
}

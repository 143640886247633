import { getHouseSubnetConstant } from '../constants/actions'
import service from '../services/houseSubnetConService';
import { setErrorMessageAction } from './setErrorMessageAction';

export const getHouseSubnetConAction = (token, client, houseid) => {
  return async(dispatch) => {
    const result = await service.getConnections(token, client, houseid);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(getConnectionsSuccess(result))
  }
}

const getConnectionsSuccess = (connections) => ({
  type: getHouseSubnetConstant,
  payload: connections
})

import {
    getTuningAttributesConstant,
    getTuningProfilesConstant,
    postTuningProfilesConstant,
    getTuningProfilesByIDConstant,
    postTuningProfilesFirmwareConnectionsConstant,
    postTuningProfilesAttributesConnetionsConstant,
    postTuningProfilesMinerTypesConnectionsConstant,
    postTuningProfilesSubnetsConnectionsConstant,
    postTuningProfilesPodConnectionsConstant,
    deleteTuningProfilesConstant,
    deleteTuningProfilesAttributesConnetionsConstant,
    deleteTuningProfilesFirmwaresConnectionsConstant,
    deleteTuningProfilesMinerTypesConnectionsConstant
} from '../constants/actions'


const defaultState = {
    ok: false,
    profiles: [],
    profileAttributes: [],
    attributes: [],
    profileMinertypes: [],
    profileScanners: [],
    profilePods: [],
    profileFirmwares: []
}


export default (state=defaultState, action) => {
    switch(action.type){
        case postTuningProfilesFirmwareConnectionsConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    profileFirmwares: action.payload.data
                }
            }
        case postTuningProfilesAttributesConnetionsConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    profileAttributes: action.payload.data
                }
            }
        case postTuningProfilesMinerTypesConnectionsConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    profileMinertypes: action.payload.data
                }
            }
        case postTuningProfilesSubnetsConnectionsConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    profileScanners: action.payload.data
                }
            }
        case postTuningProfilesPodConnectionsConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    profilePods: action.payload.data
                }
            }
        case deleteTuningProfilesConstant:
        case getTuningProfilesConstant:
        case postTuningProfilesConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    profiles: action.payload.data
                }
            }
        case getTuningAttributesConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    attributes: action.payload.data
                }
            }
        case deleteTuningProfilesAttributesConnetionsConstant:
        case deleteTuningProfilesFirmwaresConnectionsConstant:
        case deleteTuningProfilesMinerTypesConnectionsConstant:
        case getTuningProfilesByIDConstant:
            console.log(action)
            if(action.payload.ok && action.payload.data.length > 0){
                return {
                    ...state,
                    profiles:   action.payload.data,
                    profileAttributes: action.payload.data[0].Attributes,
                    profileMinertypes: action.payload.data[0].Miner_Types,
                    profileFirmwares:  action.payload.data[0].Firmwares,
                    profileScanners:   action.payload.data[0].Subnets,
                    profilePods:       action.payload.data[0].Pods
                }
            }
        default: 
            return state
    }
}
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { persistStore, persistReducer, autoRehydrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  key: 'userReducer',
  storage: storage,
  whitelist: [
    'userReducer',
    'clientReducer',
    'historyReducer',
    'customerPannelReducer',
    'heatmapPersistReducer'
  ] // which reducer we want to store
};

const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk);

const store = createStore(pReducer, middleware);
const persistor = persistStore(store);
export { persistor, store };

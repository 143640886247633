import React from 'react';
import './style.css';

const ListPageSizes = ({currentPageSize, onPageSizeChange, pageSize, dark}) => {
  return(
    <div className={dark?("change-page-size-container-dark"):("change-page-size-container")}>
      <select value={pageSize} className={dark?("change-page-size-dark"):("change-page-size")} onChange={(e) => onPageSizeChange(e)}>
        <option value={5}>Show 5</option>
        <option value={25}>Show 25</option>
        <option value={50}>Show 50</option>
        <option value={75}>Show 75</option>
        <option value={100}>Show 100</option>
        <option value={200}>Show 200</option>
        <option value={400}>Show 400</option>
        <option value={9999999}>Show All</option>
      </select>
      <strong>{currentPageSize} Items</strong>
    </div>
  )
}

export default ListPageSizes

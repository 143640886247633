import { getMinerTypePowerConstant } from '../constants/actions'
import typeService from '../services/typeService'
import { setErrorMessageAction } from './setErrorMessageAction';

export const getMinerTypePowerAction = (token, type) => {
  return async(dispatch) => {
    const result = await typeService.getMinerTypePower(token, type)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(success(result))
  }
}


const success = (result) => ({
  type: getMinerTypePowerConstant,
  payload: result
})

import {
  getFavMinersRoute,
  getFavItemsRoute,
  deleteFavMinersRoute,
  deleteFavItemRoute,
  postFavItemsRoute,
  postFavMinersRoute
} from '../constants/routes';

const favoriteService = () => {
  const getFavMiners = (token) => {
    return fetch(getFavMinersRoute, {
      method:'GET',
      headers: {
        'Accept' : 'application/json',
        'Authorization' : `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => {
      return{
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
      }
    });
  }
  const getFavItems = (token) => {
    return fetch(getFavItemsRoute, {
      method:'GET',
      headers: {
        'Accept' : 'application/json',
        'Authorization' : `Bearer ${token}`
      },
    }).then((res) => res.json()).then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: "successful",
          data: data,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          data: [],
          key_error: data.error.key_error,
        }
      }
    }).catch(error => ({
      ok: false,
      msg: "failed to fetch data from server",
      data: [],
    }));
  }
  const postFavMiners = (token, miners) => {
      return fetch(postFavMinersRoute, {
        method:'POST',
        headers: {
          'Content-Type':'application/json',
          'Authorization' : `Bearer ${token}`
        },
        body:JSON.stringify(miners)
      }).then((res) => res.json()).then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: data.success.msg,
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      }).catch(error => ({
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
      }));
  }
  const postFavItems = (token, items) => {
      return fetch(postFavItemsRoute, {
        method:'POST',
        headers: {
          'Content-Type':'application/json',
          'Authorization' : `Bearer ${token}`
        },
        body:JSON.stringify(items)
      }).then((res) => res.json()).then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: data.success.msg,
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      }).catch(error => ({
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
      }));
  }
  const deleteFavMiners = (token, miners) => {
      return fetch(deleteFavMinersRoute, {
        method:'DELETE',
        headers: {
          'Content-Type':'application/json',
          'Authorization' : `Bearer ${token}`
        },
        body:JSON.stringify(miners)
      }).then((res) => res.json()).then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: data.success.msg,
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      }).catch(error => ({
        ok: false,
        msg: "failed to fetch data from server",
        data: [],
      }));
  }
  const deleteFavItems = (token, id) => {
      return fetch(`${deleteFavItemRoute}/${id}`, {
        method:'DELETE',
        headers: {
          'Content-Type':'application/json',
          'Authorization' : `Bearer ${token}`
        },
      }).then((res) => res.json()).then((data) => {
        if(!data.hasOwnProperty("error")){
          return {
            ok: true,
            msg: data.success.msg,
            data: data.success.data,
            api_version: data.api_version,
          }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            key_error: data.error.key_error,
          }
        }
      }).catch(error => ({
        ok: false,
        msg: "failed to fetch data from server",
      }));
  }
  return {
    getFavMiners,
    getFavItems,
    postFavMiners,
    postFavItems,
    deleteFavMiners,
    deleteFavItems
  }
}

export default favoriteService();

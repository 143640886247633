import React from 'react';
import './style.css';
import Section from '../Section'
import ConfigSectionTable from '../ConfigSectionTable'
const ConfigConnectionSection = ({ connections, onAdd, onMinimize, showBody, removeItem, showAdd, houses, scanners, privilegesLevel, houseConnectionName, subnetConnectionName, onSave, onCancel, onInputChange, deletePrivileges, insertPrivileges }) => {
  return(
    <Section
      icon={<i className="fa fa-link"/>}
      name="Connection Section"
      onMinimize={onMinimize}
      showBody={showBody}
      body={
        <div className="adc_configConnectionSection-outerDiv">
          <ConfigSectionTable
            columns={{
              House_Name: "Building",
              Subnet_Name: "Scanner"
            }}
            items={connections}
            onDelete={removeItem}
            privilegeLevel={privilegesLevel}
            deletePrivileges={deletePrivileges}
            deleteType="Connection"
          />
          {showAdd?(
            <div className="adc_configConnectionSection-outerDiv">
              <div className="adc_configConnectionSection-addItemsDiv">
                <div>
                  <span>Pick Building</span>
                  <select name="houseConnectionName" className="adc_configBuildingSection-input" onChange={e => onInputChange(e)}>
                    {houses.map((item, key) => (
                      <option key={key}>{item.Name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <span>Pick Scanner</span>
                  <select name="subnetConnectionName" className="adc_configBuildingSection-input" onChange={e => onInputChange(e)}>
                    {scanners.map((item, key) => (
                      <option key={key}>{item.Name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="adc_configConnectionSection-addItemsSaveDiv">
                <button type="button" className="adc_deviceMapping-button btn-danger" onClick={e => onCancel(e)}>
                  Cancel
                </button>
                <button type="button" className="adc_deviceMapping-button btn-success" onClick={e => onSave(e)}>
                  Save
                </button>
              </div>
            </div>
          ):(
            privilegesLevel >= insertPrivileges?(
              <div>
                <button type="button" className="adc_deviceMapping-button" onClick={e => onAdd(e)}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            ):(<></>)
          )}
        </div>
      }
    />
  )
}
export default ConfigConnectionSection

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import NewPowerCutOverview from '../../Components/NewPowerCutOverview'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { adminView } from '../../helper/tabs'
import { patchPowerCutOrderStatusAction } from '../../redux/actions/patchPowerCutOrderStatusAction'
import { getPowerCutOrderByOrderIDAction } from '../../redux/actions/getPowerCutOrderByOrderIDAction'
import { getPowerCutOrderDatabasesByOrderIDAction } from '../../redux/actions/getPowerCutOrderDatabasesByOrderIDAction'
import { getPowerCutOrderActionsByOrderIDAction } from '../../redux/actions/getPowerCutOrderActionsByOrderIDAction'
import { getPowerCutOrderMinersByOrderIDAction } from '../../redux/actions/getPowerCutOrderMinersByOrderIDAction'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction'
class PowerCutOrderDetailView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            editPowerCutOrderModalOpen: false,
            editPowerCutStatusOptions: {
                "Pending_OFF": {text: 'Pending ON', value: 'Pending_ON'},
                "OFF":         {text: 'Pending ON', value: 'Pending_ON'},
                "Pending_ON":  {text: 'Closed',     value: 'Closed'},
                "ON":          {text: 'Closed',     value: 'Closed'},
                "Closed":      {text: '',           value: ''},
            },
            editPowerCutOrderStatus: '',
            filterStatus: [],
            filterStatusPick: ["ON", "OFF"],
            filterDatabases: []
        }
    }
    componentDidMount = () => {
        const {  result } = this.props.userStore
        const { databases, statusList, pageSize, pageNumber, orderBy, acending } = this.props.powerCutMinersPage
        const statusString    = this.convertfilterListToString(statusList, 'statusList')
        const databasesString = this.convertfilterListToString(databases, 'databases')
        this.props.getPowerCutOrderByOrderIDAction(result, this.props.match.params.powerOrderId);
        this.props.getPowerCutOrderDatabasesByOrderIDAction(result, this.props.match.params.powerOrderId)
        this.props.getPowerCutOrderActionsByOrderIDAction(result, this.props.match.params.powerOrderId)
        this.props.getPowerCutOrderMinersByOrderIDAction(result, this.props.match.params.powerOrderId, pageNumber, pageSize, orderBy, acending, statusString, databasesString)
    }
    onClick = (tab) => {
        onTabHeaderClick(tab, this.props.history, '/admin/')
    }
    convertfilterListToString = (list, name) => {
        let string = ''
        for(var i in list){
            string += `&${name}[]=${list[i]}`
        }
        return string
    }
    editPowerCutOrder = (orderId) =>{
        const order = this.props.powerCutOrderStore.powerCutOrders.data.filter((item) => item.ID == orderId)
        if(order.length > 0){
            this.setState({
                editPowerCutOrderItem: order[0],
                editPowerCutOrderID: orderId,
                editPowerCutOrderModalOpen: true
            })
        }
        else{
            alert("Order ID not found")
        }
    }
    onInputChange = (e, obj) => {
        if(e.hasOwnProperty("target")){
            switch (e.target.name) {
                case 'newOrderDatabaseName':
                    this.props.getHousesAction(this.props.userStore.result, e.target.value, 'All')
                    this.props.getAllPodsAction(this.props.userStore.result, e.target.value, 'All')
                    break;
                default:
                    break;
            }
            obj.setState({[e.target.name]: e.target.value})
        }
        else{
            this.setState({
                newOrderDatabasePods: e
            })
        }    
    }
    onCloseEdit = () =>{
        this.setState({
            editPowerCutOrderModalOpen: false,
            editPowerCutOrderID: '',
            editPowerCutOrderStatus: '',
            editPowerCutOrderItem: {
                Current_Power: '',
                Current_Power_Unit: '',
                Cut_Order: '',
                Cut_Order_Unit: '',
                Date: '',
                Description: '',
                ID: '',
                Status: '',
                Username: '',
            }
        })
    }
    onPowerCutOrderEdit = () => {
        const { editPowerCutOrderStatus } = this.state
        const item = this.props.powerCutOrderStore.powerCutOrders.data[0]
        this.props.patchPowerCutOrderStatusAction(this.props.userStore.result, item.ID, editPowerCutOrderStatus, 1, 1, 'ID', true, '', true)
        this.onCloseEdit()
    }
    onBack = () => {
        this.props.history.goBack()
    }
    onPowerCutOrderMinersSort = (type) => {
        let { databases, statusList, pageSize, pageNumber, orderBy, acending } = this.props.powerCutMinersPage
        const statusString    = this.convertfilterListToString(statusList, 'statusList')
        const databasesString = this.convertfilterListToString(databases, 'databases')
        if (orderBy === type) {
          acending = !acending;
        }
        else {
          acending = true;
        }
        this.props.getPowerCutOrderMinersByOrderIDAction(this.props.userStore.result, this.props.match.params.powerOrderId, pageNumber, pageSize, type, acending, statusString, databasesString);
        const store = {
          databases,
          statusList,
          pageSize,
          pageNumber,
          orderBy: type,
          acending,
          type: 'powerCutOrderMiners',
        }
        this.props.updatePageInfoAction(store);
      }
    onPowerCutOrderMinersPageClick = (pageNumber) => {
        const { databases, statusList, pageSize, orderBy, acending } = this.props.powerCutMinersPage
        const statusString    = this.convertfilterListToString(statusList, 'statusList')
        const databasesString = this.convertfilterListToString(databases, 'databases')
        pageNumber = parseInt(pageNumber) + 1
        if(pageNumber === parseInt(this.props.powerCutOrderStore.powerCutOrderMiners.current_page)) return
        if (pageNumber !== 0 && pageNumber <= this.props.powerCutOrderStore.powerCutOrderMiners.max_page) {
            this.props.getPowerCutOrderMinersByOrderIDAction(this.props.userStore.result, this.props.match.params.powerOrderId, pageNumber, pageSize, orderBy, acending, statusString, databasesString);
        }
        const store = {
            databases,
            statusList,
            pageSize,
            pageNumber,
            orderBy,
            acending,
            type: 'powerCutOrderMiners'
        }
        this.props.updatePageInfoAction(store);
    }
    onMultiChange = (item, property) => {
        const list = item.map((item) => item.value)
        const { databases, statusList, pageSize, orderBy, acending, pageNumber } = this.props.powerCutMinersPage
        let store = {
            pageSize,
            orderBy,
            acending,
            pageNumber,
            type: 'powerCutOrderMiners'
        }
        switch (property) {
            case 'filterStatus':
                store.statusList  = list
                store.databases   = databases
                const statusString    = this.convertfilterListToString(list, 'statusList')
                const databasesString = this.convertfilterListToString(databases, 'databases')
                this.props.getPowerCutOrderMinersByOrderIDAction(this.props.userStore.result, this.props.match.params.powerOrderId, pageNumber, pageSize, orderBy, acending, statusString, databasesString);
                break;
            case 'filterDatabases':
                store.statusList  = statusList
                store.databases   = list
                const statusString2    = this.convertfilterListToString(statusList, 'statusList')
                const databasesString2 = this.convertfilterListToString(list, 'databases')
                this.props.getPowerCutOrderMinersByOrderIDAction(this.props.userStore.result, this.props.match.params.powerOrderId, pageNumber, pageSize, orderBy, acending, statusString2, databasesString2);
            break;
        }
        this.setState({
            [property]: item
        })
        this.props.updatePageInfoAction(store);
    }
    clearFilter = () => {
        const { pageSize, orderBy, acending, pageNumber } = this.props.powerCutMinersPage
        const newStatusList = []
        const newDatabases  = []
        const statusString    = this.convertfilterListToString(newStatusList, 'statusList')
        const databasesString = this.convertfilterListToString(newDatabases, 'databases')
        this.props.getPowerCutOrderMinersByOrderIDAction(this.props.userStore.result, this.props.match.params.powerOrderId, pageNumber, pageSize, orderBy, acending, statusString, databasesString);
        this.setState({
            filterStatus: [],
            filterDatabases: []
        })
        const store = {
            statusList: newStatusList,
            databases: newDatabases,
            pageSize,
            pageNumber,
            orderBy,
            acending,
            type: 'powerCutOrderMiners'
        }
        this.props.updatePageInfoAction(store);
    }
    render(){
        const {  editPowerCutOrderModalOpen  } = this.state
        const modalStyle = {
            modal: {
                width: '100%',
                background: '#141619'
            }
        }
        const pannelData = this.props.powerCutOrderStore.powerCutOrders.data.length > 0 ? [
            {Name: "Status", Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Status},
            {Name: "Cut Order", Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Cut_Order},
            {Name: "Order Unit", Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Cut_Order_Unit},
            {Name: "Description", Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Description},
            {Name: "Username", Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Username},
            {Name: "Date", Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Date}
        ]: [
            {Name: "Status", Value: ""},
            {Name: "Cut Order", Value: ""},
            {Name: "Order Unit", Value: ""},
            {Name: "Description", Value: ""},
            {Name: "Username", Value: ""},
            {Name: "Date", Value: ""},
        ]
        const pannelColumns = [
            {field: 'Name', Value: 'Name'},
            {field: 'Value', Value: 'Value'}]
        let editCutOrderStatusPick = this.props.powerCutOrderStore.powerCutOrders.data.length > 0 ? [this.state.editPowerCutStatusOptions[this.props.powerCutOrderStore.powerCutOrders.data[0].Status]] : [{text: '', value: ''} ]
        const editPowerCutOrderColumns =  this.props.powerCutOrderStore.powerCutOrders.data.length > 0 ? [
            {Name: "ID", PropertyName: 'ID', Type: 'text-disabled', Value: this.props.powerCutOrderStore.powerCutOrders.data[0].ID, Options: []},
            {Name: "Status", PropertyName: 'editPowerCutOrderStatus', Type: 'select', Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Status, Options: [{text: this.props.powerCutOrderStore.powerCutOrders.data[0].Status, value: this.props.powerCutOrderStore.powerCutOrders.data[0].Status}].concat(editCutOrderStatusPick)},
            {Name: "Cut Order", PropertyName: 'Order', Type: 'text-disabled', Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Cut_Order, Options: []},
            {Name: "Order Unit", PropertyName: 'Order_Unit', Type: 'text-disabled', Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Cut_Order_Unit, Options: []},
            {Name: "Description", PropertyName: 'Description', Type: 'text-disabled', Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Description, Options: []},
            {Name: "Username", PropertyName: 'Username', Type: 'text-disabled', Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Username, Options: []},
            {Name: "Date", PropertyName: 'Date', Type: 'text-disabled', Value: this.props.powerCutOrderStore.powerCutOrders.data[0].Date, Options: []},
        ]: [
            {Name: "ID", PropertyName: 'ID', Type: 'text-disabled', Value: '', Options: []},
            {Name: "Status", PropertyName: 'editPowerCutOrderStatus', Type: 'select', Value: '', Options: []},
            {Name: "Cut Order", PropertyName: 'Order', Type: 'text-disabled', Value: '', Options: []},
            {Name: "Order Unit", PropertyName: 'Order_Unit', Type: 'text-disabled', Value: '', Options: []},
            {Name: "Description", PropertyName: 'Description', Type: 'text-disabled', Value: '', Options: []},
            {Name: "Username", PropertyName: 'Username', Type: 'text-disabled', Value: '', Options: []},
            {Name: "Date", PropertyName: 'Date', Type: 'text-disabled', Value: '', Options: []},
        ]
        const orderDatabaseColumns = [
            {field: 'ID', value: 'ID'},
            {field: 'Cut_Order', value: 'Cut Order'},
            {field: 'Count', value: 'Count'},
            {field: 'Database_Name', value: 'Database Name'},
            {field: 'Cut_Order_Unit', value: 'Unit'},
        ]
        const orderActionsColumns = [
            {field: 'ID', value: "ID"},
            {field: 'Action', value: "Action"},
            {field: 'Count', value: "Count"},
            {field: 'Username', value: "Username"},
            {field: 'Timestamp', value: "Timestamp"},
        ]
        const orderMinersColumns = [
            {field: 'ID', value: "ID"},
            {field: 'Status', value: "Status"},
            {field: 'Database_Name', value: "Database"},
            {field: 'MAC_Address', value: "MAC"},
            {field: 'Location', value: "Location"},
            {field: 'IP_Address', value: "IP"},
        ]
        const filterList = [
            {Name: "Status", PropertyName: 'filterStatus', Value: this.state.filterStatus, Type: 'multi-select',  Options: this.state.filterStatusPick.map((item) => ({ label: item, value: item }))},
            {Name: "Database", PropertyName: 'filterDatabases', Value: this.state.filterDatabases, Type: 'multi-select',  Options: this.props.powerCutOrderStore.powerCutOrderDatabases.map((item) => ({ label: item.Database_Name, value: item.Database_Name }))},
        ]
        return (
            <div className="adminView-container">
                <TabHeader
                    logo={
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
                        <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
                        </svg>
                    }
                    title="Server Admin"
                    subtitle="Manage scanner, databases & more"
                    tabs={adminView}
                    active="Power Cut"
                    onClick={this.onClick}
                />
                <div className="AdminView-content">
                    {this.props.powerCutOrderStore.powerCutOrders.data.length > 0 && this.props.powerCutOrderStore.powerCutOrders.data[0].Status == 'Closed' ? (
                        <ViewBody
                            data={pannelData}
                            columns={pannelColumns}
                            pannel
                            onBack={this.onBack}
                        />
                    ): (
                        <ViewBody
                            data={pannelData}
                            columns={pannelColumns}
                            pannel
                            createtext="Edit status"
                            onCreate={e => this.setState({editPowerCutOrderModalOpen: true})}
                            onBack={this.onBack}
                        />
                    )}
                    <ViewBody
                        data={this.props.powerCutOrderStore.powerCutOrderMiners.data}
                        columns={orderMinersColumns}
                        list
                        pager
                        sorting
                        acending={this.props.powerCutMinersPage.acending}
                        sortedBy={this.props.powerCutMinersPage.orderBy}
                        onSort={this.onPowerCutOrderMinersSort}
                        totalPages={this.props.powerCutOrderStore.powerCutOrderMiners.max_page}
                        currentPage={parseInt(this.props.powerCutOrderStore.powerCutOrderMiners.current_page) - 1}
                        onPageChange={this.onPowerCutOrderMinersPageClick}
                        filterList={filterList}
                        clearFilter={this.clearFilter}
                        filterListOnInputChange={this.onMultiChange}
                        totalData={this.props.powerCutOrderStore.powerCutOrderMiners.total_data}
                    />
                    <ViewBody
                        data={this.props.powerCutOrderStore.powerCutOrderDatabases}
                        columns={orderDatabaseColumns}
                        list
                    />
                    <ViewBody
                        data={this.props.powerCutOrderStore.powerCutOrderActions}
                        columns={orderActionsColumns}
                        list
                    />
                </div>
                <Modal
                    open={editPowerCutOrderModalOpen}
                    center
                    onClose={this.onCloseEdit}
                    styles={modalStyle}
                >
                    <FormfieldSection
                        title="Edit Order Status"
                        fields={editPowerCutOrderColumns}
                        addButton={true}
                        buttonText="Edit"
                        onAddClick={this.onPowerCutOrderEdit}
                        onInputChange={e => this.onInputChange(e, this)}
                    />
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (props) => ({
    userStore: props.userReducer,
    adminStore: props.adminReducer,
    powerCutOrderStore: props.powerCutOrderReducer,
    powerCutMinersPage: props.pageReducer.powerCutOrderMiners
  })

export default connect(mapStateToProps, {
    patchPowerCutOrderStatusAction,
    getPowerCutOrderByOrderIDAction,
    getPowerCutOrderDatabasesByOrderIDAction,
    getPowerCutOrderActionsByOrderIDAction,
    getPowerCutOrderMinersByOrderIDAction,
    updatePageInfoAction
})(PowerCutOrderDetailView)
import React from 'react'
import { connect } from 'react-redux'
import D3LineComponent from '../../Components/D3LineComponent';
import { getHashrateAction } from '../../redux/actions/getHashrateAction';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './style.css';


class D3LineGraph extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      time: "min",
      currentGraphValue: 2
    }
  }
  componentDidMount = () => {
  }
  handleChange = (event, value) => {
    switch (value) {
      case 0:
          alert("day graph is not available at the moment")
          break
          /*
          this.props.getHashrateAction(this.props.userStore.result, this.props.name, "day");
          this.setState({
            currentGraphValue: value,
            time: 'day'
          });
          */
      case 1:
          alert("hour graph is not available at the moment")
          break
          /*
            this.props.getHashrateAction(this.props.userStore.result, this.props.name, "hour");
            this.setState({
              currentGraphValue: value,
              time: 'hour'
            });
          */
      case 2:
          this.props.getHashrateAction(this.props.userStore.result, this.props.name, "min");
          this.setState({
            currentGraphValue: value,
            time: 'min'
          });
        break
      default:
    }
  }
  render() {
    const data = this.props.hashrateStore.hashrate;
    const { currentGraphValue } = this.state;
    data.forEach(d => {
      let date = new Date(d.Date);
      d.Date = date.getTime();
    });
    return (
        <div className="graphContainer">
          <div className="buttonWrapper">
            <Tabs
              value={currentGraphValue}
              onChange={this.handleChange}
              aria-label='Configure View'
              indicatorColor="primary"
              textColor="primary"
              centered
              >
                <Tab label='Day'/>
                <Tab label='Hour'/>
                <Tab label='Min'/>
            </Tabs>
          </div>
          <D3LineComponent
            data={data}
            time={this.state.time}
            />
        </div>
    )
  }
}
const mapStateTopProps = (props) => ({
  userStore: props.userReducer,
  hashrateStore: props.hashrateReducer,
})
export default connect(mapStateTopProps,{getHashrateAction})(D3LineGraph)

import React from 'react'
import './style.css'

const TableItem = ({ ctrlBoard, hashmonOne, hashmonTwo, hashmonThree, hashmonTotal, psu, regionName }) => (
  <tr>
    <td>{regionName}</td>
    <td>{ctrlBoard}</td>
    <td>{psu}</td>
    <td>{hashmonOne}</td>
    <td>{hashmonTwo}</td>
    <td>{hashmonThree}</td>
    <td>{hashmonTotal}</td>
  </tr>
)


const FlagCheckoutTable = ({ checkoutData }) => (
  <table
    className="flagCheckoutTable"
  >
    <thead>
      <tr>
        <th>Region</th>
        <th>Control Boards</th>
        <th>PSUs</th>
        <th>Hashboards x1</th>
        <th>Hashboards x2</th>
        <th>Hashboards x3</th>
        <th>Hashboards Total</th>
      </tr>
    </thead>
    <tbody>
      {checkoutData.map((item) => (
        <TableItem
            ctrlBoard={item['CTRL Board x1']}
            hashmonOne={item['Hashboard x1']}
            hashmonTwo={item['Hashboard x2']}
            hashmonThree={item['Hashboard x3']}
            hashmonTotal={item['Total_Hashboards']}
            psu={item['PSU x1']}
            regionName={item['Region_Name']}
        />
      ))}
    </tbody>

  </table>
)

export default FlagCheckoutTable

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'
import './style.css';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle,
} from 'reactstrap';
import { PieChart } from 'react-chartkick';
import 'chart.js';

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
const HouseListItem = ({houseId, houseName, clientName, favItems, addHouseToFav, pods, minersOffline, totalMiners, zeroHashMiners, overHeatMiners, performance, invalidTypes }) => {
  let fav = false;
  let ID = null;
  if(favItems !== undefined){
    if(favItems.data !== undefined){
      if(favItems.data.houses !== undefined){
        for(var i = 0; i < favItems.data.houses.length;i++){
          if(favItems.data.houses[i].Linkname === `${houseName} - ${clientName}`)
          {
            fav = true;
            ID = favItems.data.houses[i].ID;
          }
        }
      }
    }
  }

  let star ='';
  if(fav === true){
    star = 'yellow';
  }
  if(fav === false){
    star = 'black';
  }
  const linkName = `/client/${clientName}/house/${houseId}`
  return(
  <div className="houseList-container">
    <Card>
      <div className="title-div">
        <div className="title-Name">
          <i className='fa fa-star' id='houseListFav' style={{color: star}} onClick={e => addHouseToFav(houseName, clientName, linkName,fav, ID)}></i>
          <NavLink to={linkName} className=''>
            <CardTitle>
            {houseName + ' '}
            </CardTitle>
          </NavLink>
        </div>
          <p>PODS</p>
      </div>
      <div className="house-card-body-div">
        {totalMiners != 0? (
          <PieChart
            data={[
              ["Online",(totalMiners-minersOffline)],
              ["Offline", minersOffline]]}
            width={'50%'}
            height={'100%'}
            colors={['green','black']}
            radius='4'
            paddingAngle="1"
            totalValue={totalMiners}
            lineWidth="35"
            viewBoxSize={[15, 15]}
            center={[7,7]}
              />
        ): (
          <PieChart
            data={[
              ["Online",(totalMiners-minersOffline)],
              ["Offline", minersOffline]]}
            width={'50%'}
            height={'100%'}
            colors={['green','black']}
            radius='4'
            paddingAngle="1"
            totalValue={totalMiners}
            lineWidth="35"
            viewBoxSize={[15, 15]}
            center={[7,7]}
              />
        )}
        <div>
          {pods && pods.length != 0? (
            pods.map((item, key) => {
              const newLink = `${linkName}/pod/${item.ID}`
              return (
                <NavLink to={newLink} key={key}>
                  <CardText>{item.Name}</CardText>
                </NavLink>
              )
            })
          ): <p>No pods</p>}
        </div>
      </div>
      {performance?(
        <div>
          {invalidTypes == 0?(
            <CardText>Performance: {`${round(performance)}%`}</CardText>):(
              <CardText>Performance Unavailable, {invalidTypes} invalid types</CardText>
            )}
        </div>
      ):(<></>)}
    </Card>
  </div>
  )
}

HouseListItem.propTypes = {
  houseId: PropTypes.number.isRequired,
  houseName: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
}
export default HouseListItem;

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { getRegionInfoAction } from '../../redux/actions/getRegionInfoAction'
import { getAllUsersAction } from '../../redux/actions/getAllUsersAction'
import { addUsersToRegionAction } from '../../redux/actions/addUsersToRegionAction'
import { addToRegionEmailListAction } from '../../redux/actions/addToRegionEmailListAction'
import { adminView } from '../../helper/tabs'
import './style.css'


class RegionView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      addModalOpen: false,
      addEmailModalOpen: false,
      users: [],
      emails: [],
      level: ''
    }
  }
  componentDidMount = () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
    this.props.getAllUsersAction(this.props.userStore.result, 999999, 1, 'Username', false,'')
    this.props.getRegionInfoAction(this.props.userStore.result, this.props.match.params.id, this.props.match.params.region_id)
  }
  componentWillUnmount = () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
  }
  onClick = (tab) => {
    this.props.history.push(`/admin/${tab}`)
  }
  onBack = () => {
    this.props.history.goBack()
  }
  onAddUsersInput = (e) => {
    if(e.hasOwnProperty("target")){
      this.setState({
        [e.target.name]: e.target.value
      })
    }
    else{
      this.setState({
        users: e
      })
    }
  }
  onAddEmail = (e) => {
    let { emails } = this.state
    if(emails.length > 0){
      emails = emails.map((item) => item.value)
      this.props.addToRegionEmailListAction(this.props.userStore.result, this.props.match.params.id, this.props.match.params.region_id, { List: emails  })
      this.setState({ emails: [], addEmailModalOpen: false })
    }
  }
  onAddUsers = (action) => {
    const { users, level } = this.state;
    if(level === ''){
      alert("You need to pick a user group")
      return
    }
    if(users.length == 0){
      alert("You need to pick users")
      return
    }
    this.props.addUsersToRegionAction(this.props.userStore.result, this.props.match.params.id, this.props.match.params.region_id, {Data: users.map((item) => ({Username: item.value, Level: level}))})
    if(action === 'add_users_another'){
      this.setState({
        users: [],
        level: ''
      })
    }
    else{
      this.setState({
        users: [],
        level: '',
        addModalOpen: false
      })
    }
  }
  render(){
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#222d32'
       }
     }
    const currentUsers = this.props.regionStore.Users.map((item) => item.Username)
    const currentEmails = this.props.regionStore.Email_List.map((item) => item.Username)
    const userPick = this.props.adminStore.users.data.filter((item) => currentUsers.indexOf(item.Username) == -1)
    const emailPick = this.props.adminStore.users.data.filter((item) => currentEmails.indexOf(item.Username) == -1)
    const usersColumns = [{field: "Username", value: "Username"}, {field: "Level", value: "Level"}]
    const emailListColumns = [{field: 'Username', value: "Username"}, {field: "Email", value: "Email"}]
    const pannelData = [
     {Name: 'Database Name', Value: this.props.regionStore.Database_Name},
     {Name: 'Region Name', Value: this.props.regionStore.Region_Name},
    ]
    const pannelColumns = [
      {field: 'Name', Value: 'Name'},
      {field: 'Value', Value: 'Value'}]
    const addUserColumns = [
     {Name: "Users", PropertyName: 'users', Type: 'multi-select', Value: this.state.users,
     Options: userPick.map((item) => ({label: item.Username, value: item.Username}))},
     {Name: "Level", PropertyName: 'level', Type: "select", Value: this.state.level,
       Options:
         [{text: "----------", value: ''},
         {text: "Select Users", value: 0},
         {text:"Insert/Update Users", value: 1},
         {text:"Administrator", value: 2}
       ]}]
     const addEmailColumns = [
      {Name: "Emails", PropertyName: 'emails', Type: 'multi-select', Value: this.state.emails,
      Options: emailPick.map((item) => ({label: `${item.Username} -> ${item.Email}`, value: item.ID}))}]
    return(
      <div className="adminView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Server Admin"
          subtitle="Manage scanner, databases & more"
          tabs={adminView}
          active="Databases"
          onClick={this.onClick}
        />
        <div className="AdminView-content">
          <ViewBody
            data={pannelData}
            columns={pannelColumns}
            pannel
            onBack={this.onBack}
          />
          <ViewBody
            data={this.props.regionStore.Users}
            columns={usersColumns}
            createtext="Add user"
            list
            onCreate={e => this.setState({addModalOpen: true})}
          />
          <ViewBody
            data={this.props.regionStore.Email_List}
            columns={emailListColumns}
            createtext="Add email"
            list
            onCreate={e => this.setState({addEmailModalOpen: true})}
          />
        </div>
        <Modal
          open={this.state.addModalOpen}
          center
          onClose={() => this.setState({addModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add users"
            fields={addUserColumns}
            addButton={true}
            extraButton={true}
            extraButtonText="Add Another"
            buttonText="Add"
            onAddClick={this.onAddUsers}
            onInputChange={this.onAddUsersInput}
            action="add_users"
            extraAction="add_users_another"
          />
        </Modal>
        <Modal
          open={this.state.addEmailModalOpen}
          center
          onClose={() => this.setState({addEmailModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Add to emailist"
            fields={addEmailColumns}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAddEmail}
            onInputChange={e => this.setState({ emails: e })}
            action="add_email"
          />
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = (props) => ({
  userStore: props.userReducer,
  regionStore: props.regionReducer,
  adminStore: props.adminReducer
})
export default connect(mapStateToProps, {
  getRegionInfoAction,
  getAllUsersAction,
  addUsersToRegionAction,
  addToRegionEmailListAction
})(RegionView)

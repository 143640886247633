import { getPowerCutOrderActionsByOrderIDConstant } from '../constants/actions'
import service from '../services/powerCutOrderService'



export const getPowerCutOrderActionsByOrderIDAction = (token, orderId) => {
  return async(dispatch) => {
    const result = await service.getPowerCutOrderActionsByOrderID(token, orderId)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: getPowerCutOrderActionsByOrderIDConstant,
  payload: result
})

import { postFlagCommentConstant } from '../constants/actions'
import service from '../services/flagService'



export const postFlagCommentAction = (id, body, token, customer) => {
  return async(dispatch) => {
    const result = await service.postFlagComment(id, body, token, customer)
    dispatch(success(result))
  }
}

const success = (result) =>({
  type: postFlagCommentConstant,
  payload: result
})

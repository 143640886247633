import {
  getMaintenanceConstant,
  getMaintenanceByMACConstant,
  postMaintenanceConstant,
  clearMaintenanceConstant
} from '../constants/actions';

const defaultState = {
  ok: false,
  max_page: 1,
  current_page: 1,
  page_size: 25,
  msg: '',
  api_version: 1.1,
  logs: []
}
export default(state=defaultState, action) => {
  switch (action.type) {
    case getMaintenanceConstant:
    case postMaintenanceConstant:
    case getMaintenanceByMACConstant:
      if(action.payload.ok){
        return action.payload;
      }
      return{
        ok: false,
        max_page: state.max_page,
        current_page: state.current_page,
        page_size: state.page_size,
        msg: action.payload.msg,
        logs: state.logs
      }
    case clearMaintenanceConstant:
      return {
          ok: false,
          max_page: 1,
          current_page: 1,
          page_size: 25,
          msg: '',
          logs: []
      }
    default:
      return state;
  }
}

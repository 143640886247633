import poolService from '../services/poolService';
import { postPoolsConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const postPoolAction = (token, client, body) => {
  return async(dispatch) => {
    try{
      const result = await poolService.postPool(token, client, body);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(postPoolsSuccess(result))
    }
    catch(err){
      console.log(err)
    }
  }
}
const postPoolsSuccess = (pools) => ({
  type: postPoolsConstant,
  payload: pools
})

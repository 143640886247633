import React from 'react';
import './style.css';


const DeviceMappingItem = ({ mac, minerType, types, rackNum, shelfNum, minerNum, onItemChange, onInput, inputNumber, removeItem }) => {
  return(
    <div className="adc_deviceMappingItem-outerDiv">
      <input type="text" name="MAC_Address" value={mac} className="adc_deviceMapping-input-item" onChange={e => onInput(e, inputNumber)}/>
      <select value={minerType} name="minerType" className="adc_deviceMapping-input-item" onChange={e => onInput(e, inputNumber)}>
        <option></option>
        {types.map((item, key) => (
          <option key={key}>{item.Name}</option>
        ))}
      </select>
      <input type="number" name="rackNum" className="adc_deviceMapping-input-item" value={rackNum} onChange={e => onInput(e, inputNumber)}/>
      <input type="number" name="shelfNum" className="adc_deviceMapping-input-item" value={shelfNum} onChange={e => onInput(e, inputNumber)}/>
      <input type="number" name="minerNum" className="adc_deviceMapping-input-item" value={minerNum} onChange={e => onInput(e, inputNumber)}/>
      <button type="button" className="adc_deviceMapping-button" onClick={e => removeItem(inputNumber)}>
        <i className="fas fa-times"></i>
      </button>
    </div>
  )
}

export default DeviceMappingItem

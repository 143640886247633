import React from 'react';
import './styles.css';
import InfoPannel from '../InfoPannel'


const AverageReportCard = ({ title, data, columns }) => {
  return(
    <div className="AverageReportCard-container">
      <h3 className="client_view_title">{title}</h3>
      <InfoPannel
        data={data}
        columns={columns}
      />
    </div>
  )
}

export default AverageReportCard

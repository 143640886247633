import { postFavItemsConstant } from '../constants/actions';
import favoriteService from '../services/favoriteService'
import { setErrorMessageAction } from './setErrorMessageAction';

export const postFavItemsAction = (body, token) => {
  return async(dispatch) => {
    try{
      const result = await favoriteService.postFavItems(body, token);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(postFavItemsSuccess(result));
    }
    catch(err){
      console.log(err);
    }
  }
}

const postFavItemsSuccess = (result) =>({
  type: postFavItemsConstant,
  payload: result
})

import logService from '../services/logService';
import { getMinersByUserLog } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';

export const getMinersByUserLogAction = (token, client, time) => {
  return async(dispatch) => {
    const result = await logService.getMinsersByUserLog(token, client, time);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(getMinersByUserLogsSuccess(result))
  }
}
const getMinersByUserLogsSuccess = (miners) => ({
  type: getMinersByUserLog,
  payload: miners
})

import {
  getRegionInfoConstant,
  addUsersToRegionConstant,
  addToRegionEmailListConstant,
  getRegionOverviewConstant
} from '../constants/actions'
const defaultState = {
  ok: false,
  api_version: 1.1,
  Database_Name: '',
  Region_Name: '',
  Users: [],
  Email_List: [],
  regionsData: []
}

export default(state=defaultState, action) => {
  switch (action.type) {
    case getRegionOverviewConstant:
      if(action.payload.ok){
        return {
          ...state,
          regionsData: action.payload.data
        }
      }
      return state
    case addToRegionEmailListConstant:
    case addUsersToRegionConstant:
    case getRegionInfoConstant:
      if (action.payload.ok) {
        return {
          ...state,
          ...action.payload
        }
      }
      return state
    default:
      return state
  }
}

import {
    rootRoute
} from '../constants/routes'

const handleRequest = (url, options, paged=false) => {
    return fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        if(!data.hasOwnProperty("error")){
            if(paged){
                return {
                    ok: true,
                    msg: "successful",
                    data: data.success.data,
                    max_page: data.success.max_page,
                    current_page: data.success.current_page,
                    page_size: data.success.page_size,
                    total_data: data.success.totalData
                }
            }
            return {
                ok: true,
                msg: "successful",
                data: data.success.data
            }
        }
        else{
          return {
            ok: false,
            msg: data.error.reason,
            data: [],
            key_error: data.error.key_error,
          }
        }
      })
    .catch(error => ({
      ok: false,
      msg: "failed to send request to server",
      settings: []
    }))
  }


const service = () => {
    const getMinersByConfigProfile = (id, token, customer) => {
        var requestOptions = {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            } 
          }
        const url = `${rootRoute}/miner/profile/${id}/${customer}`
        return handleRequest(url, requestOptions)
    }
    const deleteProfileMinerConnections = (body, token, customer) => {
        var requestOptions = {
            method: 'DELETE',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          }
        const url = `${rootRoute}/miner/profile/${customer}`
        return handleRequest(url, requestOptions)
    }
    const postProfileMinerConnections = (body, token, customer) => {
      var requestOptions = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
    const url = `${rootRoute}/miner/profile/${customer}`
    return handleRequest(url, requestOptions)
    }
    return {
        getMinersByConfigProfile,
        deleteProfileMinerConnections,
        postProfileMinerConnections
    }
}


export default service()
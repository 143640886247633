import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
function changeLabel(label){
  let newLabel = `00${label}`
  if(newLabel.length !== 3){
    return newLabel.substring(newLabel.length-3, newLabel.length);
  }
  return newLabel
}
const MinerDetails = ({ MAC_Address, Total_Speed, Avg_Speed, Front_Fan, Back_Fan, Asic_One, Asic_Two, Asic_Three, Speed_One, Speed_Two, Speed_Three, Heat_One, Heat_Two, Heat_Three, Max_Heat, Power_Consumption, Last_Update, Location, Hash_Readable, IP_Address, Label, Deleted }) => {
  let date, time;
  if (Last_Update !== undefined) {
    date = Last_Update.split('T');
    time = date[1].split('.');
  }
  else {
    date = ['', ''];
    time = ['', ''];
  }
  return (
    <div className='flexContainerMinerDetails'>
        {Deleted === 1?(
          <h1 style={{color: 'red'}}>Miner is marked deleted</h1>
        ):(<></>)}
        <table className="table-bordered" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr style={Deleted ==0?({backgroundColor: 'white'}):({backgroundColor: 'red'})}>
            <th style={Deleted ==0?({backgroundColor: 'white'}):({backgroundColor: 'red'})} className="inventory-th">MAC: {MAC_Address}</th>
            <th style={Deleted==0?({backgroundColor: 'white'}):({backgroundColor: 'red'})} className="inventory-th">IP:
              <a href={`http://${IP_Address}`} target="_blank"> {IP_Address}</a>
            </th>
            <td style={Deleted==0?({backgroundColor: 'white'}):({backgroundColor: 'red'})} className="inventory-th">Location: {Location}</td>
            <td style={Deleted==0?({backgroundColor: 'white'}):({backgroundColor: 'red'})} className="inventory-th">Boards</td>
            <td style={Deleted==0?({backgroundColor: 'white'}):({backgroundColor: 'red'})} className="inventory-th">Asics</td>
            <td style={Deleted==0?({backgroundColor: 'white'}):({backgroundColor: 'red'})}  className="inventory-th">Speed</td>
            <td style={Deleted==0?({backgroundColor: 'white'}):({backgroundColor: 'red'})} className="inventory-th">Heat</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="inventory-td">Hashrate:</td>
            <td colspan="2" className="inventory-td">{Hash_Readable}</td>
            <td className="inventory-td">Board 1</td>
            <td className="inventory-td">{Asic_One}</td>
            <td className="inventory-td">{Speed_One}</td>
            <td className="inventory-td">{Heat_One}°C</td>
          </tr>
          {Label?(
            <>
            <tr>
              <td className="inventory-td">Label:</td>
              <td colspan="2" className="inventory-td">
              {
                Label.length < 3?(changeLabel(Label)):(Label)
              }
              </td>
              <td className="inventory-td">Board 2</td>
              <td className="inventory-td">{Asic_Two}</td>
              <td className="inventory-td">{Speed_Two}</td>
              <td className="inventory-td">{Heat_Two}°C</td>
            </tr>
            <tr>
              <td className="inventory-td">Front fan:</td><td colspan="2" className="inventory-td">{Front_Fan} rpm</td>
              <td className="inventory-td">Board 3</td>
              <td className="inventory-td">{Asic_Three}</td>
              <td className="inventory-td">{Speed_Three}</td>
              <td className="inventory-td">{Heat_Three}°C</td>
            </tr>
            <tr>
              <td className="inventory-td">Back fan:</td>
              <td colspan="2" className="inventory-td">{Back_Fan} rpm</td>
              <td className="inventory-td">Last update:</td>
              <td className="inventory-td" colspan="2">{time[0]}</td>
              <td className="inventory-td">{date[0]}</td>
            </tr>
            </>
          ):(
            <>
              <tr>
                <td className="inventory-td">Front fan:</td>
                <td colspan="2" className="inventory-td">{Front_Fan} rpm</td>
                <td className="inventory-td">Board 2</td>
                <td className="inventory-td">{Asic_Two}</td>
                <td className="inventory-td">{Speed_Two}</td>
                <td className="inventory-td">{Heat_Two}°C</td>
              </tr>
              <tr>
                <td className="inventory-td">Back fan:</td>
                <td colspan="2" className="inventory-td">{Back_Fan} rpm</td>
                <td className="inventory-td">Board 3</td>
                <td className="inventory-td">{Asic_Three}</td>
                <td className="inventory-td">{Speed_Three}</td>
                <td className="inventory-td">{Heat_Three}°C</td>
              </tr>
              <tr>
                <td className="inventory-td" colspan="2">Last update:</td>
                <td className="inventory-td" colspan="2">{time[0]}</td>
                <td className="inventory-td" colspan="3">{date[0]}</td>
              </tr>
            </>
          )}
        </tbody>
        </table>
    </div>
  )
}

/*
<table className="table-bordered" cellPadding="0" cellSpacing="0" border="0">
  <thead>
    <tr>
      <td className="inventory-th">Boards</td>
      <td className="inventory-th">Asics</td>
      <td className="inventory-th">Speed</td>
      <td className="inventory-th">Heat</td>
    </tr>
  </thead>
  <tbody>
    <tr><td className="inventory-td">Board 1</td><td className="inventory-td">{Asic_One}</td><td className="inventory-td">{Speed_One}</td><td className="inventory-td">{Heat_One}°C</td></tr>
    <tr><td className="inventory-td">Board 2</td><td className="inventory-td">{Asic_Two}</td><td className="inventory-td">{Speed_Two}</td><td className="inventory-td">{Heat_Two}°C</td></tr>
    <tr><td className="inventory-td">Board 3</td><td className="inventory-td">{Asic_Three}</td><td className="inventory-td">{Speed_Three}</td><td className="inventory-td">{Heat_Three}°C</td></tr>
    <tr>
      <td className="inventory-td">Last update:</td>
      <td className="inventory-td" colspan="2">{time[0]}</td>
      <td className="inventory-td">{date[0]}</td>
    </tr
  </tbody>
  */

/* <div className="minerButtons">
        <button className='minerButton' onClick={e => onCommandClick(MAC_Address, "config")}>Configure</button>
        <button className='minerButton' onClick={e => onCommandClick(MAC_Address, "restart")}>Restart</button>
      </div> */
export default MinerDetails;

MinerDetails.propTypes = {
  MAC_Address: PropTypes.string.isRequired,
  Total_Speed: PropTypes.number.isRequired,
  Avg_Speed: PropTypes.number.isRequired,
  Front_Fan: PropTypes.string.isRequired,
  Back_Fan: PropTypes.string.isRequired,
  Asic_One: PropTypes.string.isRequired,
  Asic_Two: PropTypes.string.isRequired,
  Asic_Three: PropTypes.string.isRequired,
  Heat_One: PropTypes.string.isRequired,
  Heat_Two: PropTypes.string.isRequired,
  Heat_Three: PropTypes.string.isRequired,
  Max_Heat: PropTypes.string.isRequired,
  Power_Consumption: PropTypes.number.isRequired,
  Last_Update: PropTypes.string.isRequired,
  Location: PropTypes.string.isRequired,
  Hash_Readable: PropTypes.string.isRequired
}

/*
<table className="minerDetailsTable" cellPadding="0" cellSpacing="0" border="0">
  <thead>
    <tr>
      <th className="minerDetailsHeader">MAC: {MAC_Address}</th>
      <th className="minerDetailsHeader">IP:
        <a href={`http://${IP_Address}`} target="_blank"> {IP_Address}</a>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr><td className="minerDetailsRowChild">Hashrate:</td><td className="minerDetailsRowChild">{Hash_Readable}</td></tr>
    {Label?(
      <tr><td className="minerDetailsRowChild">Label:</td><td className="minerDetailsRowChild">
      {
        Label.length < 3?(changeLabel(Label)):(Label)
      }
      </td></tr>
    ):(<></>)}
    <tr><td className="minerDetailsRowChild">Location:</td><td className="minerDetailsRowChild">{Location}</td></tr>
    <tr><td className="minerDetailsRowChild">Front fan:</td><td className="minerDetailsRowChild">{Front_Fan} rpm</td></tr>
    <tr><td className="minerDetailsRowChild">Back fan:</td><td className="minerDetailsRowChild">{Back_Fan} rpm</td></tr>
  </tbody>
</table>
<table className="minerDetailsTable minerDetailsTable2" cellPadding="0" cellSpacing="0" border="0">
  <thead>
    <tr><th className="minerDetailsHeader">Boards</th><th className="minerDetailsHeader">Asics</th><th className="minerDetailsHeader">Speed</th><th className="minerDetailsHeader">Heat</th></tr>
  </thead>

  <tbody>
    <tr><td className="minerDetailsRowChild">Board 1</td><td className="minerDetailsRowChild">{Asic_One}</td><td className="minerDetailsRowChild">{Speed_One}</td><td className="minerDetailsRowChild">{Heat_One}°C</td></tr>
    <tr><td className="minerDetailsRowChild">Board 2</td><td className="minerDetailsRowChild">{Asic_Two}</td><td className="minerDetailsRowChild">{Speed_Two}</td><td className="minerDetailsRowChild">{Heat_Two}°C</td></tr>
    <tr><td className="minerDetailsRowChild">Board 3</td><td className="minerDetailsRowChild">{Asic_Three}</td><td className="minerDetailsRowChild">{Speed_Three}</td><td className="minerDetailsRowChild">{Heat_Three}°C</td></tr>
    <tr><td className="minerDetailsRowChild lastUpdated">Last update:</td><td className="minerDetailsRowChild lastUpdated"> </td><td className="minerDetailsRowChild lastUpdated">{time[0]}</td><td className="minerDetailsRowChild lastUpdated">{date[0]}</td></tr>
  </tbody>
</table>
<div className="minerButtons">
  <button className='minerButton' onClick={e => onCommandClick(MAC_Address, "config")}>Configure</button>
  <button className='minerButton' onClick={e => onCommandClick(MAC_Address, "restart")}>Restart</button>
  <button className='minerButton' onClick={e => onCommandClick(MAC_Address, "update")}>Update</button>
</div>
*/



 /* <tr><td className="minerDetailsRowChild">Board</td><td className="minerDetailsRowChild">Asics</td><td className="minerDetailsRowChild">Speed</td><td className="minerDetailsRowChild">Heat</td></tr>
        <tr><td className="minerDetailsRowChild">1</td><td className="minerDetailsRowChild">{Asic_One}</td><td className="minerDetailsRowChild">{Speed_One}</td><td className="minerDetailsRowChild">{Heat_One}</td></tr>
        <tr><td className="minerDetailsRowChild">2</td><td className="minerDetailsRowChild">{Asic_Two}</td><td className="minerDetailsRowChild">{Speed_Two}</td><td className="minerDetailsRowChild">{Heat_Two}</td></tr>
        <tr><td className="minerDetailsRowChild">3</td><td className="minerDetailsRowChild">{Asic_Three}</td><td className="minerDetailsRowChild">{Speed_Three}</td><td className="minerDetailsRowChild">{Heat_Three}</td></tr> */

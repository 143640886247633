import { editMinerTypeConstant } from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const editMinerTypeAction = (token, id, body, pageSize, pageNumber, orderBy, acending, searchValue) => {
  return async(dispatch) => {
    const result = await service.editMinerType(token, id, body, pageSize, pageNumber, orderBy, acending, searchValue);
    //alert("getting all alerts returned")
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: editMinerTypeConstant,
  payload: result
})

import { postAuthCodeConstant } from '../constants/actions'
import service from '../services/userService'
import jwt from 'jwt-simple';
import { setErrorMessageAction } from './setErrorMessageAction'

export const postAuthCodeAction = (token, authCode) => {
  return async (dispatch) => {
    try {
      console.log(token)
      const result = await service.postAuthCode(token, authCode)
      if(!result.mf_verified){
          dispatch(setErrorMessageAction("Invalid authentication code"))
      }
      dispatch(success(result))
    }
    catch(err) {
      console.log(err)
    }
  }
}

const success = (result) => ({
  type: postAuthCodeConstant,
  payload: result
})

import clientService from '../services/clientService';
import { postCustomerScannerConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const postCustomerScannerAction = (token, client, region_id, body) => {
  return async (dispatch) => {
    try{
      const result = await clientService.postCustomerScanner(token, client, region_id, body);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg));
      }
      dispatch(onSuccess(result));
    }
    catch(err){
      console.log(err)
    }
  }
}

const onSuccess = ( subnets ) => ({
  type: postCustomerScannerConstant,
  payload: subnets
})

import React from 'react';
import './styles.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


const SearchInput = ({ value, onChange }) => {
  return(
    <div className="ViewBody-search-container">
      <div>
        <input type="search" value={value} onChange={e => onChange(e)} placeholder="Search"/>
        <FontAwesomeIcon icon={faSearch} size="lg"/>
      </div>
    </div>
  )
}

export default SearchInput

import { postTypesContant } from '../constants/actions';
import typeService from '../services/typeService'
import { setErrorMessageAction } from './setErrorMessageAction';

export const postTypeAction = (body, token, client) => {
  return async(dispatch) => {
    const result = await typeService.postTypes(body, token, client);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }

    dispatch(success(result))
  }
}
const success = (result) => ({
  type: postTypesContant,
  payload: result
})

import React from 'react'
import './style.css'
import ListPageSizes from '../../Components/ListPageSizes'
import Pager from '../Pager'

const SystemLogsSection = ({ items, onPageClick, pageSize, currentPage, maxPage, currentPageSize, changePageSize, showBody, onMinimize, status }) => {
  return(
    <div className="adc_systemLogSection-outerContainer">
      <div className="adc_systemLogSectionHeaderDiv">
        <div className="adc_systemLogSectionHeader">
          <i className="fa fa-history"/>
          <span>System Logs</span>
        </div>
        <div className="adc_minerCommandsButtonDiv">
          <button type="button" className="btn btn-box-tool"  onClick={e => onMinimize()}>
            {showBody?(<i className="fa fa-minus"/>):
              (<i className="fa fa-plus"/>)}
          </button>
        </div>
      </div>
          {showBody?(
            <div className="adc_systemLogSection-innerDiv">
              <ListPageSizes
                pageSize={pageSize}
                currentPageSize={currentPageSize}
                onPageSizeChange={changePageSize}
              />
              <table
                className="table table-bordered table-striped dataTable no-footer"
                cellSpacing="0"
                cellPadding='0'
                border='0'
                width="100%">
                  <tbody>
                  {status == 0?(
                    <tr><td className="adc_minerView-loadTd adc_commentTable-empty"><label>Loading data</label><div className="adc_minerView-loader"></div></td></tr>
                  ):(
                    status == 404?(
                      <tr>
                        <td valign="top" className="adc_commentTable-empty">
                          No data available for this type of miner
                        </td>
                      </tr>
                    ):(
                      status == 408?(
                        <tr>
                          <td valign="top" className="adc_commentTable-empty">
                            Miner request timed out
                          </td>
                        </tr>
                      ):(
                        items.length === 0?(
                          <tr>
                            <td valign="top" className="adc_commentTable-empty">
                              No data available
                            </td>
                          </tr>
                        ):(
                          items.map((item) => (
                            <tr>
                              <td>{item}</td>
                            </tr>
                          ))
                        )
                      )
                    )
                  )}
                  </tbody>
                </table>
                <Pager
                  onPageClick={onPageClick}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  maxPage={maxPage}
                  currentPageSize={currentPageSize}
                  onPageSizeChange={changePageSize}
                />
            </div>
          ):(<></>)}
    </div>
  )
}
export default SystemLogsSection

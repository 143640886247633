import { getAllAlertsConstant } from '../constants/actions'
import service from '../services/alertService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const getAllAlertsAction = (token, pageSize, pageNumber, orderBy, acending) => {
  return async(dispatch) => {
    //alert("getting all alerts")
    const result = await service.getAllAlerts(token, pageSize, pageNumber, orderBy, acending);
    //alert("getting all alerts returned")
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: getAllAlertsConstant,
  payload: result
})

import  {
  getRackShelfsConstant,
  editRackShelfConstant,
  postRackShelfsConstant,
  deleteRackShelfConstant,
  getRackProfilesConstant,
  getRackProfileByIDConstant,
  postRackProfileConstant,
  deleteRackProfileConstant,
  editRackProfileConstant,
  addRackProfileShelfsConnectionConstant,
  getRacksByPodIDConstant,
  postRacskToPodConstant,
  removeRackFromPodConstant,
  deleteRackProfileShelfsConnectionConstant
} from '../constants/actions'


const defaultState = {
  ok: false,
  shelfs: [],
  racks: [],
  profiles: [],
  currentPod: {
    Name: '',
    Building: '',
    Region_ID: '',
    ID: '',
  },
  currentProfile: {
    ID: '',
    Name: '',
    Use_Shelf: '',
    Left_To_Right: '',
    Bottom_To_Top: '',
    Shelfs: []
  }
}


export default(state = defaultState, action) => {
  switch (action.type) {
    case getRackShelfsConstant:
    case editRackShelfConstant:
    case postRackShelfsConstant:
    case deleteRackShelfConstant:
        if(action.payload.ok){
          return{
            ...state,
            shelfs: action.payload.data
          }
        }
        return state
    case postRackProfileConstant:
    case deleteRackProfileConstant:
    case editRackProfileConstant:
    case getRackProfilesConstant:
        if(action.payload.ok){
          return{
            ...state,
            profiles: action.payload.data
          }
        }
        return state
    case addRackProfileShelfsConnectionConstant:
    case deleteRackProfileShelfsConnectionConstant:
    case getRackProfileByIDConstant:
        if(action.payload.ok){
          return{
            ...state,
            currentProfile: action.payload.data
          }
        }
        return state
    case postRacskToPodConstant:
    case removeRackFromPodConstant:
    case getRacksByPodIDConstant:
        if(action.payload.ok){
          return{
            ...state,
            racks: action.payload.data.racks,
            currentPod: action.payload.data.currentPod
          }
        }
        return state
    default:
      return state
  }
}

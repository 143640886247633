import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import './style.css'

const createLink = (postfix, database, region) =>{
  if(postfix === ''){
    return `/client/${database}/region/${region}`
  }
  else{
    return `/client/${database}/region/${region}/${postfix}`
  }
}

const RegionsContainer = ({ regions }) => {
  const pathSplit = window.location.pathname.split('/region/')
  const postFixSplit = pathSplit.length > 1? pathSplit[1].split('/'): []
  let postFix = ''
  if(postFixSplit.length === 2){
    postFix = postFixSplit[1]
  }
  if(postFixSplit.length === 3){
    if(postFixSplit[1] === 'miners'){
        postFix = `${postFixSplit[1]}/${postFixSplit[2]}`
    }
    else{
        postFix = postFixSplit[1]
    }
  }
  return(
    <div className="regionscontainer-outercontainer">
      <strong>Regions</strong>
      <div className="regionscontainer-innerContainer">
          {regions.map((item, key) => (
            <div className="regionscontainer-item" key={key}>
              <NavLink to={createLink(postFix, item.Database_Name, item.Region_ID)}>
                <span>{item.Name}</span>
                <FontAwesomeIcon icon={faGlobe} size="lg"/>
              </NavLink>
            </div>
          ))}
      </div>
    </div>
  )
}

export default RegionsContainer

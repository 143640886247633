import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { getScannerInfoAction } from '../../redux/actions/getScannerInfoAction'
import { getAllUsersAction } from '../../redux/actions/getAllUsersAction'
import { getCustomersNameAction } from '../../redux/actions/getCustomersNameAction'
import { clearCurrentClientAction } from '../../redux/actions/clearCurrentClientAction'
import { patchScannerAction } from '../../redux/actions/patchScannerAction'
import { adminView } from '../../helper/tabs'
import './style.css'


class ScannersDetailsView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      editModalOpen: false,
      editScanner: '',
    }
  }
  componentDidMount = () => {
    this.props.clearCurrentClientAction()
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
    this.props.getScannerInfoAction(this.props.userStore.result, this.props.match.params.ip)
    this.props.getAllUsersAction(this.props.userStore.result, 999999, 1, 'Username', false,'')
    this.props.getCustomersNameAction(this.props.userStore.result)
  }
  componentWillUnmount = () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history, '/admin/')
  }
  onBack = () => {
    this.props.history.goBack()
  }
  checkDifferentFields = (oldObj, newObj) => {
    let difference = []
    for(var i in newObj){
      if(newObj.hasOwnProperty(i)){
        if(oldObj.hasOwnProperty(i) && oldObj[i] !== newObj[i]){
            difference.push(i)
        }
      }
    }
    return difference
  }
  editScanner = () => {
    const ipRegex = /\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\b/
    const { editScanner } = this.state
    const difference = this.checkDifferentFields(editScanner, this.props.scannerInfo)
    if(difference.length == 0){
      alert("No changes where made")
      return
    }
    let postObj = {}
    for(let i in difference){
      if(editScanner[difference[i]] == '' || editScanner[difference[i]] == 0){
        alert(`${difference[i]} cannot be empty/0`)
        return
      }
      switch (difference[i]) {
        case 'Scan_IP':
        case 'IP_Address':
          if(!ipRegex.exec(editScanner[difference[i]])){
            alert(`${difference[i]} is not a valid ip address`)
            return
          }
          break;
        case "Threads_Threshold":
        case "Threads":
          if(parseInt(editScanner.Threads) > parseInt(editScanner.Threads_Threshold)){
            alert("Threads cannot be larger then threads threshold")
            return
          }
          if(parseInt(editScanner[difference[i]]) < 1){
            alert(`${difference[i]} must be atleast 1`)
            return
          }
        case "Scan_Time_Target":
          if(parseInt(editScanner.Scan_Time_Target) < 60){
            alert("Time target must me atleast 60 seconds")
            return
          }
      }
      postObj[difference[i]] = editScanner[difference[i]]
    }
    this.props.patchScannerAction(this.props.userStore.result, this.props.match.params.ip, postObj)
    this.setState({ editScanner: '', editModalOpen: false })
  }
  onEditInputChange = (e) => {
    let { editScanner } = this.state
    editScanner[e.target.name] = e.target.value
    this.setState({editScanner})
  }
  render(){
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    const pannelData = [
     {Name: 'Name', Value: this.props.scannerInfo.Name},
     {Name: 'Region', Value: this.props.scannerInfo.Region_Name},
     {Name: 'IP Address', Value: this.props.match.params.ip},
     {Name: 'Id No / MAC', Value: this.props.scannerInfo.Id_No},
     {Name: 'Last Scan', Value: this.props.scannerInfo.Last_Scan},
     {Name: 'Scan Range', Value: this.props.scannerInfo.Scan_Subnet},
     {Name: 'Time Target', Value: this.props.scannerInfo.Scan_Time_Target},
     {Name: 'Status', Value: this.props.scannerInfo.Scanner_Agent_Status},
     {Name: 'Version', Value: this.props.scannerInfo.Scanner_Agent_Version},
     {Name: 'Current Threads', Value: this.props.scannerInfo.Threads},
     {Name: 'Threads Threshold', Value: this.props.scannerInfo.Threads_Threshold},
     {Name: 'Meraki Network', Value: this.props.scannerInfo.Meraki_Network},
     {Name: 'Meraki Vlan', Value: this.props.scannerInfo.Meraki_Vlan}
    ]
    const editScannerColumns = [
      {Name: "Database Name", PropertyName: 'Database_Name', Value: this.state.editScanner.Database_Name, Type: "select",
        Options: [{text: "----------", value: ''}].concat(this.props.adminStore.clients.map((item) => ({text: item.client, value: item.client})))},
      {Name: "IP Address", PropertyName: 'IP_Address', Value: this.state.editScanner.IP_Address, Type: "text", Options: []},
      {Name: "Id No / MAC", PropertyName: 'Id_No', Value: this.state.editScanner.Id_No, Type: "text", Options: []},
      {Name: "Scanner Name", PropertyName: 'Name', Value: this.state.editScanner.Name, Type: "text", Options: []},
      {Name: "Scan Start IP", PropertyName: 'Scan_IP', Value: this.state.editScanner.Scan_IP, Type: "text", Options: []},
      {Name: "Scan Range", PropertyName: 'Scan_Range', Value: this.state.editScanner.Scan_Range, Type: "select",
      Options: [{text: "----------", value: ''},
        {text: "21", value: "21"},
        {text:"22", value: "22"},
        {text:"23", value: "23"},
        {text:"24", value: "24"}
      ]},
      {Name: "Meraki Network", PropertyName: 'Meraki_Network', Value: this.state.editScanner.Meraki_Network, Type: "text", Options: []},
      {Name: "Meraki Vlan", PropertyName: 'Meraki_Vlan', Value: this.state.editScanner.Meraki_Vlan, Type: "text", Options: []},
      {Name: "Time between scans (s)", PropertyName: 'Scan_Time_Target', Value: this.state.editScanner.Scan_Time_Target, Type: "int", Options: []},
      {Name: "Starting Threads", PropertyName: 'Threads', Value: this.state.editScanner.Threads, Type: "int", Options: []},
      {Name: "Threads Threshold", PropertyName: 'Threads_Threshold', Value: this.state.editScanner.Threads_Threshold, Type: "int", Options: []}
    ]
    const pannelColumns = [
      {field: 'Name', Value: 'Name'},
      {field: 'Value', Value: 'Value'}]
    return(
      <div className="adminView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Server Admin"
          subtitle="Manage scanner, databases & more"
          tabs={adminView}
          active="Scanners"
          onClick={this.onClick}
        />
        <div className="AdminView-content">
          <ViewBody
            data={pannelData}
            columns={pannelColumns}
            createtext="Edit scanner"
            onCreate={e => this.setState({
              editModalOpen: true,
              editScanner: JSON.parse(JSON.stringify(this.props.scannerInfo))
            })}
            pannel
            onBack={this.onBack}
          />
        </div>
        <Modal
          open={this.state.editModalOpen}
          center
          onClose={() => this.setState({editModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Edit scanner"
            fields={editScannerColumns}
            addButton={true}
            buttonText="Edit"
            onAddClick={this.editScanner}
            onInputChange={this.onEditInputChange}
            action="edit_scanner"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  userStore: props.userReducer,
  scannerInfo: props.scannerDetailsReducer,
  adminStore: props.adminReducer
})

export default connect(mapStateToProps, {
  getScannerInfoAction,
  getAllUsersAction,
  getCustomersNameAction,
  patchScannerAction,
  clearCurrentClientAction
})(ScannersDetailsView)

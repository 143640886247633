import { mapMinerConstant } from '../constants/actions'
const defaultState = {
  ok: false,
  body: {}
}

export default( state = defaultState, action) => {
  switch (action.type) {
    case mapMinerConstant:
      return action.payload
    default:
      return state;
  }
}

import React from 'react';
import './style.css'
import { NavLink } from 'react-router-dom'
import { format_value } from './functions'
const CustomersStatusTable = ({ data, reducerStatus }) => {
  return(
    <div className="customersStatusTable-div">
      <table className="customersStatusTable" cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th className="customersStatus-th">Customer</th>
            <th className="customersStatus-th">Online Miners</th>
            <th className="customersStatus-th">Offline Miners</th>
            <th className="customersStatus-th">Idle Miners</th>
            <th className="customersStatus-th">Total Hashrate</th>
            <th className="customersStatus-th">Estimate Power</th>
            <th className="customersStatus-th">Performance</th>
          </tr>
        </thead>
        {data.length == 0?(
          !reducerStatus ?(
            <tbody>
              <tr>
                <td rowSpan="2" colSpan="9" className="adc_customerStatusTable-empty-td">
                  <div className="adc_customerStatusTable-empty">
                    <label>Loading data</label>
                    <div className="adc_minerView-loader"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          ):(
            <tbody>
              <tr>
                <td rowSpan="2" colSpan="9" className="adc_customerStatusTable-empty-td">
                  <div className="adc_customerStatusTable-empty">
                    <label>No data available</label>
                  </div>
                </td>
              </tr>
            </tbody>
          )
        ):(
          <tbody>
          {data?(data.map((item, key) =>(
            <tr key={key}>
              <td className="customersStatus-td">
                <NavLink to={`/client/${item.customer}/region/All`}>
                  {item.customer}
                </NavLink>
              </td>
              <td className="customersStatus-td">
              {format_value(item.onlineMiners)}
              </td>
              {item.offlineMiners > 0?(
                <td className="customersStatus-td red-color">
                  {format_value(item.offlineMiners)}
                </td>
              ):(
                <td className="customersStatus-td">
                  {format_value(item.offlineMiners)}
                </td>
              )}
              <td className="customersStatus-td">
              {format_value(item.zeroHash)}
              </td>
              <td className="customersStatus-td">
              {format_value(item.totalHashrate)}
              </td>
              <td className="customersStatus-td">
              {format_value(item.estPower)}
              </td>
              <td className="customersStatus-td">
              {format_value(item.performance)}
              </td>
            </tr>
          ))):(<></>)}
          </tbody>
        )}
      </table>
    </div>
  )
}
export default CustomersStatusTable
/*


  */

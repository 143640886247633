import React from 'react'
import Highcharts from 'highcharts';
import './style.css'

class HighcartPie extends React.Component{
  constructor(props) {
        super(props);
        this.state = {
            series: [{
                name: 'Miners',
                data: props.items
            }]
        }
    }
  componentDidMount() {
    const { series } = this.state
    this.highChartsRender(series);
  }
  componentDidUpdate(){
    this.highChartsRender([{name: "Miners", data: this.props.items}]);
  }
  highChartsRender = (items) => {
    Highcharts.chart({
        chart: {
          type: 'pie',
          renderTo: 'atmospheric-composition',
          backgroundColor: '#0e1012'
        },
        credits: {
          enabled: false
        },
        title: {
          verticalAlign: 'top',
          text: 'Miner Status',
          style: {
            fontSize: '15px',
            fontWeight: 'bold',
            color: 'white'
          }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.value}</b>'
        },
        plotOptions: {
          pie: {
            dataLabels: {
                enabled: false
            },
          }
        },
        series: items
    });
  }
    render() {
     	return (
          <div id="atmospheric-composition" className="adc_highchartPie-div">
          </div>
     	);
 	}
}

export default HighcartPie

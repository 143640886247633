import { sendCommandRoute } from '../constants/routes';

const commandService = (  ) => {
  const sendCommand = (token, client, body, command) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
    return fetch(`${sendCommandRoute}/${command}/${client}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      if(!data.hasOwnProperty("error")){
        return {
          ok: true,
          msg: data.success.msg,
          blocked: data.success.blocked,
          blockedList: data.success.blockedList,
          api_version: data.api_version,
        }
      }
      else{
        return {
          ok: false,
          msg: data.error.reason,
          blocked: 0,
          blockedList: [],
          key_error: data.error.key_error,
        }
      }
    })
    .catch(error => ({
      ok: false,
      msg: "failed to send command to server",
    }))
  }
  return {
    sendCommand
  }
}

export default commandService()

import {
  updateDatabaseTierConstant
} from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'

export const updateDatabaseTierAction = (token, customer, tier) => {
  return async(dispatch) => {
    const result = await service.updateDatabaseTier(token, customer, tier)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result, customer, tier))
  }
}
const success = (result, customer, tier) => ({
  type: updateDatabaseTierConstant,
  payload: { result, customer, tier }
})

import { rebootScannerConstant } from '../constants/actions'
import service from '../services/adminService'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'

export const rebootScannerAction = (token, ip) => {
  return async(dispatch) => {
    const result = await service.rebootScanner(token, ip);
    //alert("getting all alerts returned")
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    else{
      dispatch(setSuccessMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: rebootScannerConstant,
  payload: result
})

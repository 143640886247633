import clientService from '../services/clientService';
import { getMinersByPodConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
export const getMinersByPodAction = (token, client, Pod_ID, pageSize, pageNumber, orderBy, acending, filter) => {
  return async (dispatch) => {
    try{
      const result = await clientService.getMinersByPod(token, client, Pod_ID, pageSize, pageNumber, orderBy, acending, filter);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(getMinersSuccess(result));
    }
    catch(err){
      console.log(err)
    }
  }
}

const getMinersSuccess = ( miners ) => ({
  type: getMinersByPodConstant,
  payload: miners
})

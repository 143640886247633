import { setUserDataConstant } from '../constants/actions'

export const setUserDataAction = (data) => {
  return async(dispatch) => {
    dispatch(success(data))
  }
}

const success = (data) => ({
  type: setUserDataConstant,
  payload: data
})

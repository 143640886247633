import {
    getPowerCutOrdersConstant,
    postPowerCutOrderConstant,
    patchPowerCutOrderStatusConstant,
    getPowerCutOrderByOrderIDConstant,
    getPowerCutOrderDatabasesByOrderIDConstant,
    getPowerCutOrderMinersByOrderIDConstant,
    getPowerCutOrderActionsByOrderIDConstant,
    setPowerCutMinersActionIdConstant
} from '../constants/actions'
const defaultState = {
    ok: false,
    powerCutOrders:         {
        data: [],
        current_page: 1,
        max_page: 1,
        page_size: 25,
        total_data: 0
      },
    powerCutOrderDatabases: [],
    powerCutOrderActions:   [],
    powerCutOrderMiners:    {
        data: [],
        current_page: 1,
        max_page: 1,
        page_size: 25,
        total_data: 0
    },
    get_miners_actionId: null
}



export default (state = defaultState, action) => {
    switch(action.type){
        case setPowerCutMinersActionIdConstant:
            return{
                ...state,
                get_miners_actionId: action.payload
            }
        case getPowerCutOrderMinersByOrderIDConstant:
            if(state.get_miners_actionId !== action.actionId) return state
            const powerCutOrderMiners = {
                data: action.payload.data,
                current_page: action.payload.current_page,
                max_page: action.payload.max_page,
                page_size: action.payload.page_size,
                total_data: action.payload.total_data,
            }
            if(action.payload.ok){
                return {
                    ...state,
                    powerCutOrderMiners: powerCutOrderMiners
                }
            }
            return state
        case getPowerCutOrderActionsByOrderIDConstant:
            if(action.payload.ok){
                return {
                    ...state,
                    powerCutOrderActions: action.payload.data
                }
            }
            return state
        case getPowerCutOrderByOrderIDConstant:
            if(action.payload.ok){
                const powerCutOrder = {
                    data: action.payload.data,
                    current_page: state.powerCutOrders.current_page,
                    max_page: state.powerCutOrders.max_page,
                    page_size: state.powerCutOrders.page_size,
                }
                return {
                    ...state,
                    powerCutOrders: powerCutOrder
                }
            }
            return state
        case getPowerCutOrderDatabasesByOrderIDConstant: 
            if(action.payload.ok){
                return {
                    ...state,
                    powerCutOrderDatabases: action.payload.data
                }
            }
            return state
        case getPowerCutOrdersConstant:
        case postPowerCutOrderConstant:
        case patchPowerCutOrderStatusConstant:
            if(action.payload.ok){
                const powerCutOrders = {
                    data: action.payload.data,
                    current_page: action.payload.current_page,
                    max_page: action.payload.max_page,
                    page_size: action.payload.page_size,
                    total_data: action.payload.total_data,
                }
                return{
                    ...state,
                    powerCutOrders: powerCutOrders
                }
            }
            return state
        default:
            return state
    }
}
import {
  getAllUsersConstant,
  getCustomersNamesConstant,
  postNewUserConstant,
  createNewDatabaseConstant,
  createNewUserConstant,
  createNewMinerTypeconstant,
  deleteUserConstant,
  deleteDatabaseConstant,
  adjustSystemPrivilegesConstant,
  renameCustomersConstant,
  updateDatabaseTierConstant,
  getAllScannersConstant,
  postScannerConstant,
  getRegionsConstant
 } from '../constants/actions'
const defaultState = {
  ok: false,
  api_version: 1.1,
  msg: '',
  clients: [],
  regions: [],
  minerTypes: [],
  users: {
    data: [],
    current_page: 1,
    max_page: 1
  },
  scanners: {
    data: [],
    current_page: 1,
    max_page: 1
  }
}
export default(state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case getRegionsConstant:
      if(action.payload.ok){
        newState.ok = true
        newState.regions = action.payload.data
        newState.api_version = action.payload.api_version
      }
      return newState
      break;
    case getCustomersNamesConstant:
      if(action.payload.ok){
        newState.ok = true
        newState.clients = action.payload.data
        newState.api_version = action.payload.api_version
      }
      return newState
      break;
    case getAllUsersConstant:
      if(action.payload.ok){
        newState.ok = true
        newState.users = action.payload.data
        newState.api_version = action.payload.api_version
      }
      return newState
      break;
    case createNewDatabaseConstant:
      if(action.payload.result.ok){
        newState.ok = true
        newState.clients = action.payload.result.data
        newState.api_version = action.payload.result.api_version
      }
      return newState;
      break;
    case createNewUserConstant:
      if(action.payload.result.ok){
        newState.ok = true
        newState.users.data.push({Username: action.payload.user, Level: parseInt(action.payload.level), Email: action.payload.email})
        if(action.payload.level >= 2){
          let usersInClients = JSON.parse(JSON.stringify(newState.clients))
          for(var i in newState.clients){
            newState.clients[i].users = newState.clients[i].users.concat({Username: action.payload.user, Level: parseInt(action.payload.level)})
          }
        }
        newState.api_version = action.payload.result.api_version
      }
      return newState
    case adjustSystemPrivilegesConstant:
      if(action.payload.result.ok){
        let user = newState.users.data.filter((item) => item.Username === action.payload.user)
        let tmpUsers = newState.users.data.filter((item) => item.Username !== action.payload.user)
        tmpUsers.push({Username: action.payload.user, Level: parseInt(action.payload.level), Email: user[0].Email})
        newState.users.data = tmpUsers
        newState.api_version = action.payload.result.api_version
      }
      return newState
    case deleteUserConstant:
      if(action.payload.result.ok){
        newState.ok = true
        newState.users.data = newState.users.data.filter((item) => item.Username !== action.payload.username)
        for(var i in newState.clients){
          newState.clients[i].users = newState.clients[i].users.filter((e) =>  e.Username !== action.payload.username)
        }
        newState.api_version = action.payload.result.api_version
      }
      return newState
    case deleteDatabaseConstant:
      if(action.payload.result.ok){
        newState.ok = true
        newState.clients = newState.clients.filter((item) => item.client !== action.payload.database)
        newState.api_version = action.payload.result.api_version
      }
      return newState
    case renameCustomersConstant:
      if(action.payload.ok){
        newState.ok = true
        newState.clients = action.payload.data
        newState.api_version = action.payload.api_version
      }
      return newState
    case updateDatabaseTierConstant:
      if(action.payload.result.ok){
        for(var i in newState.clients){
          if(newState.clients[i].client === action.payload.customer){
            newState.clients[i].tier = action.payload.tier
          }
        }
      }
      return newState
    case getAllScannersConstant:
    case postScannerConstant:
      if(action.payload.ok){
        newState.ok = true;
        newState.scanners = action.payload.data
        newState.api_version = action.payload.api_version
      }
      return newState
    case createNewMinerTypeconstant:
    default:
      return state
  }
}

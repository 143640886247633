import React from 'react'
import './style.css'
import VerificationInput from "react-verification-input";

const LoginAuthenticator = ({ token, username, displayQR, code, onChange, submit, onBack, onPaste }) => {
  return(
    <div className="LoginAuthenticator-container">
      <button className="LoginAuthenticator-back-button" onClick={e => onBack()}>
        <span className="LoginAuthenticator-back-button-span">Go back</span>
      </button>
      {displayQR?(
        <>
          <span className="LoginAuthenticator-header-span">Scan the QR code on your authenticator app</span>
          <img src={`https://${window.location.hostname}/api/mf_qr_code?token=${token}`} />
        </>
      ):(<></>)}
      <span className="LoginAuthenticator-header-span">{username} <br/> Enter Authenticator Code</span>
      <VerificationInput
        removeDefaultStyles
        classNames={{
         container: "container",
         character: "character",
         characterInactive: "character--inactive",
         characterSelected: "character--selected",
        }}
        value={code}
        onChange={onChange}
        autoFocus={true}
        id="2f-auth-input"
      />
      <button className="LoginAuthenticator-paste-button" onClick={ e => onPaste()}>Paste</button>
    </div>
  )
}
//<img src={`https://${window.location.hostname}/api/mf_qr_code?token=${token}`} />
export default LoginAuthenticator

import React from 'react';
import './style.css';
import { connect } from 'react-redux'

const MessagePanel = ({messageStore, clearMsg}) => {
  return (
    <>
      {messageStore.successMessage !== ''?(
        <div className="message-panel-message alert alert-success">
          <span className="message-pannel-span">
            <i className="fa fa-check"/>
            <span>{messageStore.successMessage}</span>
            <span className="exit-div" onClick={() => clearMsg()}>
              <i className="fa fa-window-close" aria-hidden="true" />
            </span>
          </span>
        </div>
      ): (
        <></>
      )}
      {messageStore.errorMessage !== ''?(
          <div className="message-panel-message alert alert-danger message-panel-blinker" role="alert">
            <span className="message-pannel-span">
              <i className="fa fa-exclamation-triangle"/>
              <span>{messageStore.errorMessage}</span>
              <span className="exit-div" onClick={() => clearMsg()}>
                <i className="fa fa-window-close" aria-hidden="true"/>
              </span>
            </span>
          </div>
      ): (
        <></>
      )}
    </>
  )
}

const mapStateToProps = (props) => ({
  messageStore: props.messageReducer
})
export default connect(mapStateToProps)(MessagePanel);

/*

<div className="errorDiv">
  <div className="exit-div"><i class="fa fa-window-close" aria-hidden="true" onClick={() => clearMsg()}></i></div>
  <h6>{messageStore.errorMessage }</h6>
</div>
*/

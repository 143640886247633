import React from 'react'
import './style.css'
import ListPageSizes from '../../Components/ListPageSizes'
import Pager from '../Pager'

const ErrorLogSection = ({ items, onPageClick, pageSize, currentPage, maxPage, currentPageSize, changePageSize, showBody, onMinimize, status }) => {
  return(
    <div className="adc_systemLogSection-outerContainer">
      <div className="adc_systemLogSectionHeaderDiv">
        <div className="adc_systemLogSectionHeader">
          <i className="fa fa-history"/>
          <span>Error Logs</span>
        </div>
        <div className="adc_minerCommandsButtonDiv">
          <button type="button" className="btn btn-box-tool"  onClick={e => onMinimize()}>
            {showBody?(<i className="fa fa-minus"/>):
              (<i className="fa fa-plus"/>)}
          </button>
        </div>
      </div>
      {showBody?(
        <div className="adc_systemLogSection-innerDiv">
          <ListPageSizes
            pageSize={pageSize}
            currentPageSize={currentPageSize}
            onPageSizeChange={changePageSize}
          />
          <table
            className="table table-bordered table-striped dataTable no-footer"
            cellSpacing="0"
            cellPadding='0'
            border='0'
            width="100%">
              {status == 0?(
              <tbody>
                <tr>
                  <td valign="top" className="adc_minerView-loadTd adc_commentTable-empty">
                    <label>Loading data</label><div className="adc_minerView-loader"></div>
                  </td>
                </tr>
              </tbody>
              ):(
                status == 404?(
                <tbody>
                  <tr>
                    <td valign="top" className="adc_commentTable-empty">
                      No data available for this type of miner
                    </td>
                  </tr>
                </tbody>
                ):(
                  status == 408?(
                    <tbody>
                      <tr>
                        <td valign="top" className="adc_commentTable-empty">
                          Miner request timed out
                        </td>
                      </tr>
                    </tbody>
                  ):(
                      items.length === 0?(
                        <tbody>
                          <tr>
                            <td valign="top" className="adc_commentTable-empty">
                              No data available
                            </td>
                          </tr>
                        </tbody>
                      ):(
                        <>
                          <thead>
                            <tr>
                            {Object.keys(items[0]).map((item)=>(
                                  <th>{item}</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                          {items.map((item) => (
                            <tr>
                              {Object.keys(item).map((item2)=>(
                                    <td>{item[item2]}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                        </>
                      )
                  )
                )
              )}
            </table>
          <Pager
            onPageClick={onPageClick}
            pageSize={pageSize}
            currentPage={currentPage}
            maxPage={maxPage}
            currentPageSize={currentPageSize}
            onPageSizeChange={changePageSize}
          />
        </div>
      ):(<></>)}
    </div>
  )
}
export default ErrorLogSection

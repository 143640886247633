import appReducer from './appReducer'
import { exitActionConstant } from '../constants/actions'
import storage from 'redux-persist/lib/storage'
export default(state, action) => {
  if(action.type === exitActionConstant){
    state = undefined
    storage.removeItem('persist:userReducer')
  }
  return appReducer(state, action);
}

import { mapMinerConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
import { setSuccessMessageAction } from './setSuccessMessageAction';
import service from '../services/clientService'
export const mapMinersAction = (token, client, body) => {
  return async (dispatch) => {
    try{
      const result = await service.mapMiners(token, client, body);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      else{
        dispatch(setSuccessMessageAction("Successfully mapped"))
      }
      dispatch(mapSuccess(result));
    }
    catch(e){
      console.log(e)
    }
  }
}

const mapSuccess = (payload) => ({
  type: mapMinerConstant,
  payload: payload
})

import { getMinerFlagRecoveryLogByFlagIDConstant } from '../constants/actions'
import service from '../services/flagService'
import { setErrorMessageAction } from './setErrorMessageAction'
import { setSuccessMessageAction } from './setSuccessMessageAction'



export const getMinerFlagRecoveryLogByFlagIDAction = (id, token, customer) => {
  return async(dispatch) => {
    const result = await service.getMinerFlagRecoveryLogByFlagID(id, token, customer)
    dispatch(success(result))
  }
}



const success = (result) =>({
  type: getMinerFlagRecoveryLogByFlagIDConstant,
  payload: result
})

import React from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import MapMinerSection from '../../Components/MapMinerSection'
import FormfieldSection from '../../Components/FormfieldSection'
import CommandButtons from '../../Components/CommandButtons'
import MinerHistoryGraph from '../../Components/MinerHistoryGraph'
import PickProfileModal from '../../Components/PickProfileModal'
import { onTabHeaderClick, onInputChange, formatBytes } from '../../functions'
import { formatTime } from '../../helper/functions'
import { minerView, minerViewTier0 } from '../../helper/tabs'
import { getMinerAction } from '../../redux/actions/getMinerAction';
import { getHousesAction } from '../../redux/actions/getHousesAction'
import { getPodsLimitedAction } from '../../redux/actions/getPodsLimitedAction'
import { mapMinersAction } from '../../redux/actions/mapMinersAction';
import { updateMinerLocationAction } from '../../redux/actions/updateMinerLocationAction'
import { getMinerHistoryAction } from '../../redux/actions/getMinerHistoryAction'
import { clearLocationsAction } from '../../redux/actions/clearLocationsAction'
import { sendCommandAction } from '../../redux/actions/sendCommandAction';
import { markMinersDeletedAction } from '../../redux/actions/markMinersDeletedAction'
import { restoreMinersAction } from '../../redux/actions/restoreMinersAction'
import { updateResponsePropertyAction } from '../../redux/actions/updateResponsePropertyAction'
import { editSerialNumberAction } from '../../redux/actions/editSerialNumberAction'
import { getProfilesAction } from '../../redux/actions/getProfilesAction'
import './style.css'
import Modal from 'react-responsive-modal'

class MinerInfoView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      houseID: '',
      houseName: '',
      podID: '',
      podName: '',
      rackNum: '',
      shelfNum: '',
      minerNum: '',
      socketId: '',
      serialNumber: '',
      currentSerialNumber: '',
      editSerialNumberModalOpen: '',
      profileModalOpen: false,
      profileSelected: -1
    }
  }
  componentDidMount = async() => {
    let rackNum   = ''
    let houseName = ''
    let podName = ''
    let shelfNum  = ''
    let minerNum  = ''
    let podID = ''
    let houseID = ''
    let serialNumber = ''
    let currentSerialNumber = ''
    this.props.getHousesAction(this.props.token, this.props.match.params.name, this.props.match.params.region_id);
    this.props.getProfilesAction(this.props.token, this.props.match.params.name)
    this.props.getMinerHistoryAction(this.props.token, this.props.match.params.name, this.props.match.params.miner)
    await this.props.getMinerAction(this.props.token, this.props.match.params.name, this.props.match.params.miner)
    if(this.props.minerStore.miner.length > 0){
      const miner = this.props.minerStore.miner[0]
      if(miner.Location !== "None"){
        let split = miner.Location.split('-');
        rackNum = Number(split[2])
        shelfNum = Number(split[3])
        minerNum = Number(split[4])
        houseID = miner.House_ID
        podID = miner.Pod_ID
        houseName = miner.House_Name
        podName = miner.Pod_Name
        serialNumber = miner.Serial_Number
        currentSerialNumber = miner.Serial_Number
        this.props.getPodsLimitedAction(this.props.token, this.props.minerStore.miner[0].House_Name, this.props.match.params.name);
      }
    }
    this.setState({
      rackNum,
      shelfNum,
      minerNum,
      houseID,
      podID,
      houseName,
      podName,
      serialNumber,
      currentSerialNumber
    })
  }
  onSelectItemChange = (e) => {
    let split = e.target.value.split('/')
    switch (e.target.name) {
      case 'houseName':
          this.props.getPodsLimitedAction(this.props.token, split[1], this.props.match.params.name);
          if(split[0] == 0){
            this.setState({houseID: '', podID: ''})
          }
          else{
            this.setState({houseID: split[0], houseName: split[1]})
          }
        break;
      case 'podName':
          this.setState({podID: split[0], podName: split[1]})
        break
      default:
        break
    }
  }
  onMap = async () => {
    const {
      houseID,
      podID,
      houseName,
      podName,
      rackNum,
      shelfNum,
      minerNum
    } = this.state
    if(rackNum == '')
    if(houseID == ''){
      alert("pick a house")
      return
    }
    if(podID == ''){
      alert("pick a pod")
      return
    }
    if(rackNum == ''){
      alert("pick a rack")
      return
    }
    if(shelfNum == ''){
      alert("pick a shelf")
      return
    }
    if(minerNum == ''){
      alert("pick a miner location")
      return
    }
    if(rackNum < 1 || shelfNum < 1 || minerNum < 1){
      alert("items cannot be less then 1")
      return
    }
    const fullLocation = `${houseName}-${podName}-${rackNum}-${shelfNum}-${minerNum}`
    const items = [{
      MAC_Address: this.props.match.params.miner,
      Location: `${rackNum}-${shelfNum}-${minerNum}`,
      rackNum: rackNum,
      shelfNum: shelfNum,
      minerNum: minerNum
    }]
    await this.props.mapMinersAction(this.props.token, this.props.match.params.name, {Pod_ID: podID, House_ID: houseID, items: items})
    if(this.props.mapMiner.ok){
      this.props.updateMinerLocationAction(fullLocation)
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onCommandClick = (type, socketId) => {
    switch (type) {
      case 'restart':
      case 'update':
        this.props.sendCommandAction(this.props.token, this.props.match.params.name, { Macs: [this.props.match.params.miner] , CallerID: socketId.id }, type)
        this.props.updateResponsePropertyAction({property: 'open', value: true})
        this.props.updateResponsePropertyAction({property: 'total', value: this.props.responseStore.total + 1})
        this.props.updateResponsePropertyAction({property: 'pending', value: this.props.responseStore.pending + 1})
        break
      case 'config':
        this.setState({
          profileModalOpen: true,
          socketId: socketId.id
        })
        break
      case 'restore':
        this.props.restoreMinersAction(this.props.token, [this.props.match.params.miner], this.props.match.params.name)
        break
      case 'delete':
          this.props.markMinersDeletedAction(this.props.token, {macs: [this.props.match.params.miner], delete_type: 1 }, this.props.match.params.name)
        break
      case 'clearLocation':
        this.props.clearLocationsAction(this.props.token, this.props.match.params.name, {macs:[this.props.match.params.miner]}, false)
      case 'editSerialNumber':
        this.setState({
          editSerialNumberModalOpen: true
        })
        default:

    }
  }
  onEditSerialNumber = () => {
    const { serialNumber, currentSerialNumber } = this.state
    if(serialNumber == ''){
      alert("Serial number cannot be empty")
      this.setState({
        serialNumber: currentSerialNumber
      })
      return
    }
    const body = {
      serialNumber
    }
    this.props.editSerialNumberAction(this.props.token, this.props.match.params.name, this.props.match.params.miner, body)
    // /api/miner/:mac/serial/:customer 
    // Need to create the action
    // Action returns the miner detail information 
    this.setState({
      editSerialNumberModalOpen: false
    })
  }
  onProfileSelect = (e) => {
    this.setState({
      profileSelected: e.value
    })
  }
  onProfileSubmit = () => {
    const { profileSelected, socketId } = this.state
    if(!window.confirm(`Are you sure you want to configure the following miner ?`)){
      this.setState({
        profileModalOpen: false,
        profileSelected: -1
      })
      return
    }
    let id = this.props.poolStore.profiles[profileSelected]
    if(id){
      id = id.ID
    }
    this.props.sendCommandAction(this.props.userStore.result, this.props.match.params.name, { Macs: [this.props.match.params.miner], Data: id, CallerID: socketId }, 'config')
    this.props.updateResponsePropertyAction({property: 'open', value: true})
    this.props.updateResponsePropertyAction({property: 'total', value: this.props.responseStore.total + 1})
    this.props.updateResponsePropertyAction({property: 'pending', value: 1})
    this.setState({
      profileModalOpen: false,
      profileSelected: -1
    })
  }
  render(){
    const {
      houseName,
      houseID,
      podName,
      rackNum,
      shelfNum,
      minerNum,
      podID
    } = this.state
    const { userData } = this.props.userStore
    const userLevel = userData.level? userData.level: 0
    const databaseTier = userData.databases.length > 0? userData.databases[0].Tier: 0
    let date, time;
    if (this.props.minerStore.miner.length > 0 && this.props.minerStore.miner[0].Last_Update !== undefined) {
      date = this.props.minerStore.miner[0].Last_Update.split('T');
      time = date[1].split('.');
    }
    else {
      date = ['', ''];
      time = ['', ''];
    }
    const modalStyles = {
      modal: {
        width: '400px',
        background: '#141619'
      }
    };
    const pannelColumns = [
      {field: 'Name', value:'Name'},
      {field: 'Value', value: 'Value'}]
    const miner = this.props.minerStore.miner[0]
    const pannelData = this.props.minerStore.miner.length > 0?[
      {Name: 'Location', Value: miner.Location},
      {Name: 'Status', Value: miner.Deleted == 1? 'MARKED DELETED':miner.Status, Dynamic_Coloring: true},
      {Name: 'Worker Name', Value: miner.Worker_Name},
      {Name: 'Miner Type', Value: miner.Miner_Type},
      {Name: 'IP address', Value: miner.IP_Address, is_link: true},
      {Name: 'MAC address', Value: miner.MAC_Address},
      {Name: 'Current speed', Value: formatBytes(miner.Total_Speed)},
      {Name: 'Average speed', Value: formatBytes(miner.Avg_Speed)},
      {Name: 'Speed Per board',
        Value: `${formatBytes(miner.Speed_One)} / ${formatBytes(miner.Speed_Two)} / ${formatBytes(miner.Speed_Three)}`},
      {Name: 'Asic per board',
      Value: `${this.props.minerStore.miner[0].Asic_One} / ${this.props.minerStore.miner[0].Asic_Two} / ${miner.Asic_Three}`},
      {Name: 'Heat per board', Value: `${miner.Heat_One}˚ / ${miner.Heat_Two}˚ / ${miner.Heat_Three}˚`},
      {Name: 'HW error per board', Value: `${miner.HW_Error_One} / ${miner.HW_Error_Two} / ${miner.HW_Error_Three}`},
      {Name: 'Freq per board', Value: `${miner.Freq_One} / ${miner.Freq_Two} / ${miner.Freq_Three}`},
      {Name: 'Target hr per board', Value: `${miner.Theo_HR_One} / ${miner.Theo_HR_Two} / ${miner.Theo_HR_Three}`},
      {Name: 'Inlet temp per board', Value: `${miner.Inlet_Temp_One} / ${miner.Inlet_Temp_Two} / ${miner.Inlet_Temp_Three}`},
      {Name: 'RPM per fan', Value: `${miner.Fan_One} / ${miner.Fan_Two} / ${miner.Fan_Three} / ${miner.Fan_Four}`},
      {Name: 'Pool1', Value: miner.Active_Pool},
      {Name: 'Pool2', Value: miner.Pool_URL_Two},
      {Name: 'Pool3', Value: miner.Pool_URL_Three},
      {Name: 'Uptime', Value: formatTime(miner.Uptime)},
      {Name: "Serial Number", Value: miner.Serial_Number},
      {Name: 'Last Seen', Value: `${date[0]} - ${time[0]}`}
    ]: []
    const houses = [{Name: '----------', ID: 0}].concat(this.props.houseStore.houses.map((item) => ({Name: item.Name, ID: item.ID})))
    const pods = [{Name: '----------', ID: 0}].concat(this.props.podStore.pods.map((item) => ({Name: item.Name.split('-')[1], ID: item.ID})))
    const deleted = miner?miner.Deleted: 0
    const editSerialNumberFields = [
      {Name: 'Serial Number', PropertyName: 'serialNumber', Value: this.state.serialNumber, Type: 'text', Options: []}
    ]
    return(
      <div className="MinerView-container">
        <TabHeader
          logo={
            <img className="tab-view-image" src={require("../../images/MinerDetailsIcon.png")}/>
          }
          title="Miner Details"
          subtitle="Detailed miner info, user comments, repairs & more"
          tabs={userLevel === 0 && databaseTier == 0? minerViewTier0: minerView}
          active="Info"
          onClick={this.onClick}
        />
        <div className="MinerView-content">
          {userLevel === 0 && databaseTier == 0?(<></>):(
            <CommandButtons
              onClick={this.onCommandClick}
              deleted={deleted}
            />
          )}
          <ViewBody
            data={pannelData}
            columns={pannelColumns}
            pannel
          />
          {this.props.minerStore.history.length > 0?(
            <div className="miner-history-container">
              <MinerHistoryGraph
                title="Miner history - Last Week"
                data={this.props.minerStore.history}
              />
            </div>
          ):(<></>)}
          <MapMinerSection
            buildings={houses}
            defaultBuilding={houseID}
            pods={pods}
            defaultPod={podID}
            submit={this.onMap}
            rackNum={rackNum}
            shelfNum={shelfNum}
            minerNum={minerNum}
            mac={this.props.match.params.miner}
            onInputChange={this.onSelectItemChange}
            defaultBuildingName={houseName}
            defaultPodName={podName}
            onMapItemInput={e => onInputChange(e, this)}
          />
          <Modal
            open={this.state.editSerialNumberModalOpen}
            center
            onClose={() => this.setState({ editSerialNumberModalOpen: false })}
            styles={modalStyles}
          >
            <FormfieldSection
              title="Edit Serial Number"
              fields={editSerialNumberFields}
              addButton={true}
              buttonText="Edit"
              onAddClick={this.onEditSerialNumber}
              onInputChange={e => onInputChange(e, this)}
              action="post_flag"
            />
          </Modal>
          <PickProfileModal
            profiles={this.props.poolStore.profiles}
            open={this.state.profileModalOpen}
            indexSelected={this.state.profileSelected}
            onInputChange={this.onProfileSelect}
            onClose={e => this.setState({
              profileModalOpen: false,
              profileSelected: -1
            })}
            onSubmit={this.onProfileSubmit}
          />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (props) => ({
  token: props.userReducer.result,
  userStore: props.userReducer,
  minerStore: props.minerReducer,
  houseStore: props.houseReducer,
  podStore: props.podsReducer,
  mapMiner: props.mapMinersReducer,
  responseStore: props.responseReducer,
  poolStore: props.poolReducer
})

export default connect(mapStateToProps, {
  getMinerAction,
  getHousesAction,
  getPodsLimitedAction,
  mapMinersAction,
  updateMinerLocationAction,
  clearLocationsAction,
  markMinersDeletedAction,
  sendCommandAction,
  restoreMinersAction,
  getMinerHistoryAction,
  updateResponsePropertyAction,
  editSerialNumberAction,
  getProfilesAction
})(MinerInfoView)

import {
  updateHeatmapPersistConstant
} from '../constants/actions'


const defaultState = {
  method: 'custom',
  type: 'Heat',
  rack: 'All',
  perRow: 1,
  building: {
    default: {name: 'All', id: "All"}
  },
  pod: {
    default: {name: 'All', id: "All"}
  }
}

export default(state = defaultState, action) => {
  switch (action.type) {
    case updateHeatmapPersistConstant:
      return{
        ...state,
        [action.payload.property]: action.payload.data
      }
    default:
      return state
  }
}

import { deleteHouseSubConstant } from '../constants/actions'
import service from '../services/houseSubnetConService';
import { setErrorMessageAction } from './setErrorMessageAction';


export const deleteHouseSubnetConAction = (body, token, customer) => {
  return async (dispatch) => {
    try{
      const result = await service.deleteConnection(body, token, customer);
      if(!result.ok){
        dispatch(setErrorMessageAction(result.msg))
      }
      dispatch(deleteSuccess(result))
    }
    catch(err){
      console.log(err)
    }
  }
}


const deleteSuccess = (result) => ({
  type: deleteHouseSubConstant,
  payload: result
})

import React, {useContext} from 'react'
import { connect } from 'react-redux'
import TabHeader from '../../Components/TabHeader'
import ViewBody from '../../Components/ViewBody'
import { Modal } from "react-responsive-modal";
import FormfieldSection from '../../Components/FormfieldSection'
import { onTabHeaderClick, onInputChange } from '../../functions'
import { adminView } from '../../helper/tabs'
import { getAllScannersAction } from '../../redux/actions/getAllScannersAction'
import { postScannerAction } from '../../redux/actions/postScannerAction'
import { rebootScannerAction } from '../../redux/actions/rebootScannerAction'
import { updatePageInfoAction } from '../../redux/actions/updatePageInfoAction';
import { deployScannerAction } from '../../redux/actions/deployScannerAction'
import { clearCurrentClientAction } from '../../redux/actions/clearCurrentClientAction'
import { getCustomersNameAction } from '../../redux/actions/getCustomersNameAction'
import { getRegionsAction } from '../../redux/actions/getRegionsAction'
import { SocketContext } from '../../context/socket'
import './style.css'


class ScannersView extends React.Component {
  static contextType = SocketContext
  constructor(props){
    super(props)
    this.state = {
      addModalOpen: false,
      scannerSearchString: '',
      createScannerModalOpen: false,
      database: '',
      ip_address: '',
      name: '',
      scan_ip: '',
      scan_range: '',
      scan_time_Target: 60,
      meraki_network: '',
      meraki_vlan: '',
      threads: 1,
      threads_threshold: 25,
      region: ''
    }
  }
  componentDidMount = () => {
    this.props.clearCurrentClientAction()
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
    this.props.getCustomersNameAction(this.props.userStore.result)
    this.props.getRegionsAction(this.props.userStore.result)
    this.props.getAllScannersAction(
      this.props.userStore.result,
      this.props.scannerPage.pageSize,
      this.props.scannerPage.pageNumber,
      this.props.scannerPage.orderBy,
      this.props.scannerPage.acending,
      this.state.scannerSearchString)
  }
  componentWillUnmount = () => {
    const hamburger = document.getElementById('toggleHamburger-span')
    if(hamburger){
      hamburger.classList.toggle("displayNone")
    }
  }
  onClick = (tab) => {
    onTabHeaderClick(tab, this.props.history)
  }
  onScannerSearch = (e) => {
    const { orderBy, acending, pageSize } = this.props.scannerPage;
    this.props.getAllScannersAction(this.props.userStore.result, pageSize, 1, orderBy, acending, e.target.value);
    this.setState({
      scannerSearchString: e.target.value
    })
    const store = {
      pageSize: pageSize,
      pageNumber: 1,
      type: 'scanners',
      orderBy: this.props.scannerPage.orderBy,
      acending: acending
    }
    this.props.updatePageInfoAction(store)
  }
  onScannerSort = (type) => {
    let { acending, orderBy, pageSize, pageNumber } = this.props.scannerPage;
    if (orderBy === type) {
      acending = !acending;
    }
    else {
      acending = true;
    }
    this.props.getAllScannersAction(this.props.userStore.result, pageSize, pageNumber, type, acending, this.state.scannerSearchString);
    const store = {
      pageSize,
      pageNumber,
      orderBy: type,
      acending,
      type: 'scanners',
    }
    this.props.updatePageInfoAction(store);
  }
  upgradeScanner = (id_no) => {
    if(window.confirm(`Are you sure you want to deploy upgrade: ${id_no} ?`)){
      let  socket = this.context
      if(socket && socket.disconnected){
        console.log("Reconnecting")
        socket.connect(process.env.REACT_APP_SIO_SERVER_URL, {
          query: { token: this.props.userStore.result },
          path:'/sio',
          reconnection: true,
          reconnectionDelay: 5000,
        })
        alert("Socket disconnect exectuing a reconnect")
      }
      socket.emit("upgradeScanner", id_no)
    }
  }
  rebootScanner = (id_no) => {
    if(window.confirm(`Are you sure you want to reboot scanner: ${id_no} ?`)){
      let  socket = this.context
      if(socket && socket.disconnected){
        console.log("Reconnecting")
        socket.connect(process.env.REACT_APP_SIO_SERVER_URL, {
          query: { token: this.props.userStore.result },
          path:'/sio',
          reconnection: true,
          reconnectionDelay: 5000,
        })
        alert("Socket disconnect exectuing a reconnect")
      }
      socket.emit("rebootScanner", id_no)
    }
  }
  onAddScanner = () => {
    const nameRegex = /[ `!@#$%^&*() +=\[\]{};':"\\|,<>\/?~]/
    const { database, ip_address, name, scan_ip, scan_range, scan_time_Target, threads, threads_threshold, meraki_vlan, meraki_network, region } = this.state
    if(database === ''){
      alert("Pick a database")
      return
    }
    if(ip_address === ''){
      alert("Enter a ip address")
      return
    }
    if(name === ''){
      alert("Enter a scanner name")
      return
    }
    if(nameRegex.test(name)){
      alert("Scanner Name is invalid")
      return
    }
    if(scan_ip === ''){
      alert("Enter a start IP for scan")
      return
    }
    if(scan_range === ''){
      alert("Pick a scan range")
      return
    }
    if(scan_time_Target < 60){
      alert("Time between scans cannot be less the 60 seconds")
      return
    }
    if(scan_time_Target > 600){
      alert("Time between scans cannot be more then 10 minutes")
      return
    }
    if(threads < 1){
      alert("Threads need to be atleast 1")
      return
    }
    if(threads > 50){
      alert("Threads cannot be more the 50")
      return
    }
    if(threads_threshold < 1){
      alert("Threads threadshold need to be atleast 1")
      return
    }
    if(threads_threshold > 50){
      alert("Threads threshold cannot be more the 50")
      return
    }
    if(threads > threads_threshold){
      alert("Threads cannot be more than threads threshold")
      return
    }
    this.props.postScannerAction(this.props.userStore.result, {
      IP_Address: ip_address,
      Name: name,
      Scan_Range: scan_range,
      Scan_IP: scan_ip,
      Database_Name: database,
      Threads: threads,
      Threads_Threshold: threads_threshold,
      Scan_Time_Target: scan_time_Target,
      Meraki_Vlan: meraki_vlan,
      Meraki_Network: meraki_network,
      Region_ID: region
    },
    this.props.scannerPage.pageSize,
    this.props.scannerPage.pageNumber,
    this.props.scannerPage.orderBy,
    this.props.scannerPage.acending,
    this.state.scannerSearchString)
    this.setState({
      createScannerModalOpen: false,
      database: '',
      ip_address: '',
      name: '',
      scan_ip: '',
      scan_range: '',
      scan_time_Target: 60,
      threads: 1,
      threads_threshold: 25
    })
  }
  onScannerPageClick = (pageNumber) => {
    pageNumber = parseInt(pageNumber) + 1
    if(pageNumber === parseInt(this.props.adminStore.scanners.current_page)) return
    const { pageSize, orderBy, acending } = this.props.scannerPage;
    if (pageNumber !== 0 && pageNumber <= this.props.adminStore.scanners.max_page) {
      this.props.getAllScannersAction(this.props.userStore.result, pageSize, pageNumber, orderBy, acending, this.state.scannerSearchString);
    }
    const store = {
      pageSize,
      pageNumber,
      orderBy,
      acending,
      type: 'scanners'
    }
    this.props.updatePageInfoAction(store);
  }
  render(){
    const modalStyle = {
       modal: {
         width: '100%',
         background: '#141619'
       }
     };
    const regions = this.props.adminStore.regions.filter((item) => item.Database_Name === this.state.database)
    const addScannerField = [
      {Name: "Database Name", PropertyName: 'database', Value: this.state.database, Type: "select",
        Options: [{text: "----------", value: ''}].concat(this.props.adminStore.clients.map((item) => ({text: item.client, value: item.client})))},
      {Name: "Region", PropertyName: 'region', Value: this.state.region, Type: 'select', Options: [{text: "----------", value: ''}].concat(regions.map((item) => ({text: item.Name, value: item.ID})))},
      {Name: "IP Address", PropertyName: 'ip_address', Value: this.state.ip_address, Type: "text", Options: []},
      {Name: "Scanner Name", PropertyName: 'name', Value: this.state.name, Type: "text", Options: []},
      {Name: "Scan Start IP", PropertyName: 'scan_ip', Value: this.state.scan_ip, Type: "text", Options: []},
      {Name: "Scan Range", PropertyName: 'scan_range', Value: this.state.scan_range, Type: "select",
      Options: [{text: "----------", value: ''},
        {text: "20", value: "20"},
        {text: "21", value: "21"},
        {text:"22", value: "22"},
        {text:"23", value: "23"},
        {text:"24", value: "24"}
      ]},
      {Name: "Meraki Network", PropertyName: 'meraki_network', Value: this.state.meraki_network, Type: "text", Options: []},
      {Name: "Meraki Vlan", PropertyName: 'meraki_vlan', Value: this.state.meraki_vlan, Type: "text", Options: []},
      {Name: "(S) Between scans", PropertyName: 'scan_time_Target', Value: this.state.scan_time_Target, Type: "int", Options: []},
      {Name: "Starting Threads", PropertyName: 'threads', Value: this.state.threads, Type: "int", Options: []},
      {Name: "Threads Threshold", PropertyName: 'threads_threshold', Value: this.state.threads_threshold, Type: "int", Options: []}
    ]
    const scannersColumns = [
      {field: 'Database_Name', value: 'Database'},
      {field: 'Region_Name', value: 'Region'},
      {field: 'IP_Address', value: 'IP Address'},
      {field: 'Name', value: 'Name'},
      {field: 'Last_Scan', value: 'Last Seen'},
      {field: 'Scanner_Agent_Version', value: 'Version'}]
      const scannersButtons = [
        {class: 'ViewBody-button-edit', onClick: this.rebootScanner, type: 'Reboot', extractKey: 'Id_No'},
        {class: 'ViewBody-button-edit', onClick: this.upgradeScanner, type: 'Upgrade_Scanner_Agent', extractKey: 'Id_No'}]
    return(
      <div className="adminView-container">
        <TabHeader
          logo={
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" className="css-12im2tg">
              <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39Z"></path>
            </svg>
          }
          title="Server Admin"
          subtitle="Manage scanner, databases & more"
          tabs={adminView}
          active="Scanners"
          onClick={this.onClick}
        />
        <div className="AdminView-content">
          <ViewBody
            data={this.props.adminStore.scanners.data}
            columns={scannersColumns}
            buttons={scannersButtons}
            createtext="New scanner"
            list
            onCreate={e => this.setState({createScannerModalOpen: true})}
            pager
            sorting
            search
            searchValue={this.state.scannerSearchString}
            onSearchChange={this.onScannerSearch}
            acending={this.props.scannerPage.acending}
            sortedBy={this.props.scannerPage.orderBy}
            onSort={this.onScannerSort}
            totalPages={this.props.adminStore.scanners.max_page}
            currentPage={parseInt(this.props.adminStore.scanners.current_page) - 1}
            onPageChange={this.onScannerPageClick}
            onItemClick={(name, string) => this.props.history.push(`${name}/${string}`)}
            itemClickInfo={{name: 'scanners', property: 'IP_Address'}}
          />
        </div>
        <Modal
          open={this.state.createScannerModalOpen}
          center
          onClose={() => this.setState({createScannerModalOpen: false})}
          styles={modalStyle}
        >
          <FormfieldSection
            title="Create new scanner"
            fields={addScannerField}
            addButton={true}
            buttonText="Add"
            onAddClick={this.onAddScanner}
            onInputChange={e => onInputChange(e, this)}
            action="add_scanner"
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (props) => ({
  userStore: props.userReducer,
  adminStore: props.adminReducer,
  scannerPage: props.pageReducer.scanners,
})

export default connect(mapStateToProps, {
  getAllScannersAction,
  postScannerAction,
  rebootScannerAction,
  updatePageInfoAction,
  deployScannerAction,
  getCustomersNameAction,
  clearCurrentClientAction,
  getRegionsAction
})(ScannersView)

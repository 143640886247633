import React from 'react';
import './style.css';
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
class LeftBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      client: '',
      logLink: '',
      poolLink: '',
      mapLink: '',
      clientLink: '',
      userLogLink: ''
    }
  }
  componentDidMount(){
    const link = `/client/${this.props.client}`
    this.setState({client: this.props.client, logLink: `${link}/log`, poolLink: `${link}/configure`, mapLink: `${link}/map`, clientLink: link, userLogLink: `${link}/userLog`})
  }
  render() {
    const { logLink, poolLink, mapLink, clientLink, client, userLogLink } = this.state;

    /*
      possible to move this into componentDidMount?, only issue that i see is that because React
      renders one time before the componentDidMount we will get one render were level in not correct
    */
    let level = this.props.clientStore.clients.map((item) => {
      if(item.client === client){
        return item.level
      }
    })
    level = level[0];
    return (
      <div className="left leftbar" id="leftbar">
        <NavLink to={clientLink} className="leftbarLink">{client}</NavLink>
        <NavLink to={logLink} className="leftbarLink">Log View</NavLink>
        <NavLink to={poolLink} className="leftbarLink">Configure</NavLink>
        <NavLink to={mapLink} className="leftbarLink">Map Miners</NavLink>
        {level === 2 ?(
          <NavLink to={userLogLink} className="leftbarLink">User Log</NavLink>
        ): <></>}
      </div>
    );
  }
}

LeftBar.propTypes = {
  client: PropTypes.string.isRequired
}

const mapStateToPops = (props) => ({
  clientStore: props.clientReducer
})
export default connect(mapStateToPops)(LeftBar);

/*

<div className="left leftbar" id="leftbar">
    <NavLink to={logLink}>Log</NavLink><br/>
    <NavLink to={poolLink}>Pool</NavLink>
    <NavLink to={mapLink}>Map Miners</NavLink>
    <NavLink to={changePassword}>Change Password</NavLink>
</div>

*/

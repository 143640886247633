import { putScannerToProfileConstant } from '../constants/actions'
import service from '../services/poolService'
import { setErrorMessageAction } from './setErrorMessageAction'


export const addScannerToProfileAction = (token, client, id, body) => {
  return async(dispatch) =>{
    const result = await service.addScannerToProfile(token, client, id, body)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result))
  }
}

const success = (result) => ({
  type: putScannerToProfileConstant,
  payload: result
})

import { fetchMoreMinersConstant } from '../constants/actions'

export const fetchMoreMinersAction = (interval) => {
  return dispatch => {
    dispatch({
      type: fetchMoreMinersConstant,
      payload: interval
    })
  }
}

import {
  getOverviewMinersByTypeConstant,
  setID_getOverviewMinersByTypeConstant
} from '../constants/actions'
import { setErrorMessageAction } from './setErrorMessageAction'
import service from '../services/minerService'

export const getOverviewMinersByTypeAction = (token, client, type, pageSize, pageNumber, orderBy, acending, filterString, region_id) => {
  return async (dispatch) => {
  const actionId = Math.random() * (100000000 - 1) + 1;
  dispatch({type: setID_getOverviewMinersByTypeConstant, payload: actionId})
    const result = await service.getOverviewMinersByType(token, client, type, pageSize, pageNumber, orderBy, acending, filterString, region_id)
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg))
    }
    dispatch(success(result, actionId))
  }
}
const success = (result, actionId) => ({
  type: getOverviewMinersByTypeConstant,
  payload: result,
  actionId
})

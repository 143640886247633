export const formatBytes = (bytes, decimals = 2)  => {
  if (bytes === 0) return '0 H';
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  let sizes = ['H', 'KH', 'MH', 'GH', 'TH', 'PH', 'EH', 'ZH', 'YH'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const onInputChange = (e, obj) => {
  obj.setState({[e.target.name]: e.target.value})
}

export const onTabHeaderClick = (tab, history, extra = '') => {
    const route = tab.split(" ").join('').toLowerCase()
    history.push(`${extra}${route}`)
}

export const filterCheck = (array, item, property) => {
  for(var i in array){
    if(array[i][property] === item[property])return true
  }
  return false
}

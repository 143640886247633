import logService from '../services/logService';
import { getUserLogsConstant } from '../constants/actions';
import { setErrorMessageAction } from './setErrorMessageAction';
//import { setSuccessMessageAction } from './setSuccessMessageAction';

export const getUserLogsByTimeAction = (token, client, pageSize, pageNumber, orderBy, acending, searchString, region_id) => {
  return async(dispatch) => {
    const result = await logService.getUserLogs(token, client, pageSize, pageNumber, orderBy, acending, searchString, region_id);
    if(!result.ok){
      dispatch(setErrorMessageAction(result.msg));
    }
    dispatch(getUserLogsSuccess(result))
  }
}

const getUserLogsSuccess = (logs) => ({
  type: getUserLogsConstant,
  payload: logs
})

import {
  getDatabaseInfoConstant,
  addUsersToDatabaseConstant,
  adjustUserPrivilegesConstant,
  adjustPerfromanceDropConstant,
  createNewRegionConstant
} from '../constants/actions'

const defaultState = {
  ok: false,
  api_version: 1.1,
  Database_Name: '',
  Tier: '',
  Users: [],
  Regions: [],
  Version: ''
}

export default(state=defaultState, action) => {
  switch (action.type) {
    case createNewRegionConstant:
    case adjustUserPrivilegesConstant:
    case addUsersToDatabaseConstant:
    case adjustPerfromanceDropConstant:
    case getDatabaseInfoConstant:
      if(action.payload.ok){
        return action.payload.data
      }
      return state
    default:
      return state
  }
}
